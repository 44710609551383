import React, { useEffect, useState } from 'react';
import { UpdateSidebar } from "../../UtilityFunctions";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import { useSelector } from "react-redux";
import kernelApi from "../../API/kernelApi";
import { RootState } from "../../redux/store";


// import button 
import Button from "../../views/buttons/Button";
import { useLocation, useNavigate } from 'react-router';
import { sha512 } from "js-sha512";

interface stateType {
    app_id: number;
}

const AppDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { app_id } = location?.state as stateType || -1;


    const { current_property } = useSelector((state: RootState) => state.properties);
    const { admin_id, company_id, auth_token, full_name } = useSelector((state: RootState) => state.auth);
    const [appDetails, setAppDetails] = useState<any | null>(null);

    UpdateSidebar("Apps", appDetails?.selectedApp?.app_name);

    const getAppDetails = async () => {
        try {
            const res = await kernelApi.get(`/app-details/${current_property.hotel_id}/${app_id}`);

            if (res.data.status === 1) {
                const apps = { selectedApp: res.data.selected_app, otherApps: res.data.other_apps }
                setAppDetails(apps);

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (app_id > -1) {
            getAppDetails();
        } else {
            navigate("/app-list");
        }
    }, [current_property]);

    const getIntoApp = async () => {
        try {
            const res = await kernelApi.get(`/app-details/${current_property.hotel_id}/${app_id}`);

            if (res.data.status === 1) {
                appLogin(res.data?.selected_app.app_url);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const appLogin = async (url: string) => {
        if (url) {
            try {
                let comp_hash = sha512(company_id.toString());
                console.log("url : ", url);
                window.open(`${url}/${window.btoa(company_id)}/${comp_hash}/${window.btoa(current_property.hotel_id)}/${window.btoa(admin_id)}/${auth_token}/${window.btoa(full_name)}`);

            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <>
            <div className="property__breadcrumbs">
                <Breadcrumbs />
                <div className="app__details__back__btn__wrapper">
                    <Button
                        className="back__btn"
                        icon="bi bi-arrow-left"
                        handleClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                </div>
            </div>

            <div className="hr--line"></div>


            <div className="app__details__header mb-4">{appDetails?.selectedApp?.app_name}</div>
            <div className="app__header__action__wrapper mb-5">
                <div className="app__details__action__box">
                    <div className="app__details__box">
                        <div className="app__details__icon app--bg--teal--blue--color">
                            <div className="app__image__box">
                                <img src={appDetails?.selectedApp.app_image} />
                            </div>
                        </div>
                        <div className="app__details__text__content ms-3">
                            {/* <div className="app__details__name">{appDetails?.selectedApp.app_name}</div> */}
                            <div className="app__details__label__from__bookingjini">Bookingjini</div>
                            <div className="app__details__label__ratings">{appDetails?.selectedApp.app_rating}
                                <div className="star__rating__wrapper ms-2">
                                    {Array(5).fill(0).map((_, idx) => {
                                        const star = idx + 1;
                                        const rating = appDetails?.selectedApp.app_rating || 5;
                                        return (
                                            <i key={idx} className={`bi bi-star${star <= rating ? "-fill" : (star - rating < 1 ? "-half" : "")}`}></i>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app__action__box">
                        {appDetails?.selectedApp.is_subscribed === 1 && appDetails?.selectedApp.app_url !== "#" ?
                            <div className="addded__app__wrapper me-3" onClick={() => getIntoApp()}>
                                <Button>Open App</Button>
                            </div> :
                            appDetails?.selectedApp.is_subscribed === 1 && appDetails?.selectedApp.app_url === "#" ?
                                <div className="add__app__wrapper me-3">
                                    <Button><i className="bi bi-plus me-2"></i> Added</Button>
                                </div> :
                                <div className="add__app__wrapper me-3">
                                    <Button><i className="bi bi-plus me-2"></i> Add App</Button>
                                </div>
                        }

                        <div className="contact__support__wrapper">
                            <Button><i className="bi bi-question-circle me-2"></i>Contact Support</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hr--line"></div>

            <div className="app__screen__slider__wrapper mb-5 pt-3">
                <div className="app__screen__slider__box">
                    {appDetails?.selectedApp.app_screenshots.map((appScreens: any) => {
                        return (
                            <>
                                <div className="app__screen__slider me-3">
                                    <img src={appScreens} alt="App Slider Image 1" />
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className="hr--line"></div>

            <div className="app__details__overview__wrapper my-4">
                <div className="app__details__overview__left me-4">
                    <div className="standard-page--label app--heading--text">Overview</div>
                    <div className="app__details__overview my-3">
                        {/* <div className="app__details__overview__heading mb-3">Proactively Reach Out​ </div> */}
                        <div className="app__details__description mb-3">{appDetails?.selectedApp.app_description}</div>
                    </div>
                </div>
                <div className="app__details__overview__right">
                    <div className="standard-page--label app--heading--text">Details</div>
                    <div className="app__details__table__wrapper my-4">
                        <div className="app__details__table">
                            <div className="app__details__text__wrap">
                                <div className="app__details__text">Subscribed</div>
                                <div className="app__details__number">{appDetails?.selectedApp.subscribers}</div>
                            </div>

                            <div className="hr--line"></div>

                            <div className="app__details__text__wrap">
                                <div className="app__details__text">Version</div>
                                <div className="app__details__number">{appDetails?.selectedApp.app_version}</div>
                            </div>

                            <div className="hr--line"></div>

                            <div className="app__details__text__wrap">
                                <div className="app__details__text">Developer</div>
                                <div className="app__details__number">{appDetails?.selectedApp.developed_by}</div>
                            </div>

                            <div className="hr--line"></div>

                            <div className="app__details__text__wrap">
                                <div className="app__details__text">Website</div>
                                <div className="app__details__number website--label--text">{appDetails?.selectedApp.developer_website}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Popular App */}
            <div className="app__list__label__navigation mt-5 mb-4">
                <div className="standard-page--label app--heading--text">Other Apps</div>
            </div>

            <div className="app__list__latest__app__section mb-5">
                {appDetails?.otherApps.map((appDetailsData: any) => {
                    return (
                        <>
                            <div className="latest__app__card">
                                <div className="latest__app__content__section me-0 mb-3">
                                    <div className="latest__app__icon app--bg--blue--color ">
                                        <div className="app__image__box">
                                            <img src={appDetailsData?.app_image} />
                                        </div>
                                    </div>
                                    <div className="latest__app__text__content ms-3">
                                        <div className="latest__app__name">{appDetailsData?.app_name}</div>
                                        <div className="app__label__from__bookingjini">Bookingjini</div>
                                        <div className="app__label__ratings">{appDetailsData?.app_rating}
                                            <div className="star__rating__wrapper ms-2">
                                                {Array(5).fill(0).map((_, idx) => {
                                                    const star = idx + 1;
                                                    const rating = appDetailsData?.app_rating || 5;
                                                    return (
                                                        <i key={idx} className={`bi bi-star${star <= rating ? "-fill" : (star - rating < 1 ? "-half" : "")}`}></i>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>


            {/*<div className="latest__app__card me-3">
                <div className="latest__app__content__section">
                    <div className="latest__app__icon app--bg--teal--blue--color">
                        <i className="bi bi-fingerprint"></i>
                    </div>
                    <div className="latest__app__text__content ms-3">
                        <div className="latest__app__name">Jini Talk</div>
                        <div className="app__label__from__bookingjini">Bookingjini</div>
                        <div className="app__label__ratings">4.4
                            <div className="star__rating__wrapper ms-2">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-half"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <div className="latest__app__card me-3">
                <div className="latest__app__content__section">
                    <div className="latest__app__icon app--bg--pulp--orange--color">
                        <i className="bi bi-boxes"></i>
                    </div>
                    <div className="latest__app__text__content ms-3">
                        <div className="latest__app__name">CRS</div>
                        <div className="app__label__from__bookingjini">Bookingjini</div>
                        <div className="app__label__ratings">4.4
                            <div className="star__rating__wrapper ms-2">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-half"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="latest__app__card me-3">
                <div className="latest__app__content__section">
                    <div className="latest__app__icon app--bg--blue--color">
                        <i className="bi bi-binoculars"></i>
                    </div>
                    <div className="latest__app__text__content ms-3">
                        <div className="latest__app__name">Rate Shopper</div>
                        <div className="app__label__from__bookingjini">Bookingjini</div>
                        <div className="app__label__ratings">4.4
                            <div className="star__rating__wrapper ms-2">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-half"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="latest__app__card">
                <div className="latest__app__content__section">
                    <div className="latest__app__icon app--bg--teal--blue--color">
                        <i className="bi bi bi-intersect"></i>
                    </div>
                    <div className="latest__app__text__content ms-3">
                        <div className="latest__app__name">Optinz</div>
                        <div className="app__label__from__bookingjini">Bookingjini</div>
                        <div className="app__label__ratings">4.4
                            <div className="star__rating__wrapper ms-2">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-half"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

export default AppDetails;