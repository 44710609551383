import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import kernelApi from '../../API/kernelApi';
import { CurrentProperty } from '../../redux/actions/PropertiesAction';
import { SubscriptionDaysLeft } from "../../redux/actions/LoginAction";
import { Capitalize, getImage } from '../../UtilityFunctions';
import endPoints from "../../API/endPoints";

export interface IPropertyCard {
    property: any
}

const PropertyCard: React.FC<IPropertyCard> = ({ property }) => {

    const dispatch = useDispatch();
    const { isPlanSubscribed } = endPoints.DASHBOARD;


    const SelectProperty = async () => {
        dispatch(CurrentProperty(property));
        try {
            const response = await kernelApi.get(
                `${isPlanSubscribed}/${property.hotel_id}`
            );
            if (response.data.status === 1) {
                dispatch(SubscriptionDaysLeft(response.data.days_left))
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="div__property__wrap">
                <Link to="/">
                    <div className="div__property__list" onClick={() => { SelectProperty() }}>
                        <div className="property--image">
                            <img src={getImage(property.exterior_image)} alt="" />
                        </div>
                        <div className="peoperty__name__wrapper">
                            <div className="property--name">{Capitalize(property.hotel_name)}</div>
                            <div className="property--address">{`${property.city_name}, ${property.state_name}`}</div>
                        </div>
                        <div className="select__property">
                            <i className="gg-arrow-right"></i>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default PropertyCard;
