import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Logout, UpdateSidebar } from "../../UtilityFunctions";

import { ProgressBar } from "react-step-progress-bar";

import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import kernelApi from "../../API/kernelApi";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const PromotionType = () => {


  const dispatch = useDispatch();

  UpdateSidebar("Promotion");

  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [countDetails, setCountDetails] = useState<any>();
  const [progressDetails, setProgressDetails] = useState<any>();

  

  return (
    <>
      <div className="promotions__breadcrumbs">
        <Breadcrumbs />
      </div>
      <div className="hr--line"></div>

      <div className="promotions__left__panel">
        <div className="promotions-wrapper">
          <div className="promotions__manage__row">
            <Link to="basic-promotion">
              <div className="promotions__card">
                <div className="promotions__card__container">
                  <div className="promotions__indicator__wrapper">
                    <div className="promotions--setup--icon">
                      <i className="bi bi-box promotions--setup--icon--style"></i>
                    </div>
                  </div>
                  <div className="basic--details--label">Basic</div>
                  <div
                    className="promotions--progress "
                    style={{
                      background: `${progressDetails?.basic_details_bgcolor}`,
                    }}
                  >
                    {/* <ProgressBar
                      percent={progressDetails?.basic_details}
                      filledBackground={progressDetails?.basic_details_color}
                    /> */}
                  </div>
                </div>
              </div>
            </Link>

            {/* Rate Plans */}

            {/* <Link to="#">
              <div className="promotions__card">
                <div className="promotions__card__container">
                  <div className="promotions__indicator__wrapper">
                    <div className="promotions--setup--icon">
                      <i className="bi bi-clock promotions--setup--icon--style"></i>
                    </div>
                    {countDetails?.rate_plans_count > -1 && (
                      <div className="promotions--setup--notification">
                        {countDetails?.rate_plans_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Early Bird</div>
                  <div
                    className="promotions--progress"
                    style={{
                      background: `${progressDetails?.rate_plan_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.rate_plan}
                      filledBackground={progressDetails?.rate_plan_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#">
              <div className="promotions__card">
                <div className="promotions__card__container">
                  <div className="promotions__indicator__wrapper">
                    <div className="promotions--setup--icon">
                      <i className="bi bi-alarm promotions--setup--icon--style"></i>
                    </div>
                    {countDetails?.room_type_count > -1 && (
                      <div className="promotions--setup--notification">
                        {countDetails?.room_type_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Last Minute</div>
                  <div
                    className="promotions--progress"
                    style={{
                      background: `${progressDetails?.roomtypes_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.roomtypes}
                      filledBackground={progressDetails?.roomtypes_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#">
              <div className="promotions__card">
                <div className="promotions__card__container">
                  <div className="promotions__indicator__wrapper">
                    <div className="promotions--setup--icon">
                      <i className="bi bi-files promotions--setup--icon--style"></i>
                    </div>
                    {countDetails?.floors_count > -1 && (
                      <div className="promotions--setup--notification">
                        {countDetails?.floors_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Same Day</div>
                  <div
                    className="promotions--progress"
                    style={{
                      background: `${progressDetails?.floors_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.floors}
                      filledBackground={progressDetails?.floors_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#">
              <div className="promotions__card">
                <div className="promotions__card__container">
                  <div className="promotions__indicator__wrapper">
                    <div className="promotions--setup--icon">
                      <i className="bi bi-moon-stars promotions--setup--icon--style"></i>
                    </div>
                    {countDetails?.rooms_count > -1 && (
                      <div className="promotions--setup--notification">
                        {countDetails?.rooms_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Multi Night</div>
                  <div
                    className="promotions--progress"
                    style={{
                      background: `${progressDetails?.rooms_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.rooms}
                      filledBackground={progressDetails?.rooms_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#">
              <div className="promotions__card">
                <div className="promotions__card__container">
                  <div className="promotions__indicator__wrapper">
                    <div className="promotions--setup--icon">
                      <i className="bi bi-calendar3 promotions--setup--icon--style"></i>
                    </div>
                    {countDetails?.facility_count > -1 && (
                      <div className="promotions--setup--notification">
                        {countDetails?.facility_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Long Stay</div>
                  <div
                    className="promotions--progress"
                    style={{
                      background: `${progressDetails?.amenities_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.amenities}
                      filledBackground={progressDetails?.amenities_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#">
              <div className="promotions__card">
                <div className="promotions__card__container">
                  <div className="promotions__indicator__wrapper">
                    <div className="promotions--setup--icon">
                      <i className="bi bi-calendar2-check promotions--setup--icon--style"></i>
                    </div>
                    {countDetails?.property_images_count > -1 && (
                      <div className="promotions--setup--notification">
                        {countDetails?.property_images_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Day-Of-Week</div>
                  <div
                    className="promotions--progress"
                    style={{
                      background: `${progressDetails?.images_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.images}
                      filledBackground={progressDetails?.images_color}
                    />
                  </div>
                </div>
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionType;
