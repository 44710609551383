import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";

import { CATCH_MESSAGE, Logout, UpdateSidebar } from "../../UtilityFunctions";

// import buttons
import AddButton from "../../views/buttons/Button";
import SaveButton from "../../views/buttons/Button";

// import sliding pane
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../views/buttons/Button";
import endPoints from "../../API/endPoints";

const PropertyAmenities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  UpdateSidebar("Property Setup", "Amenities");

  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // endPoints Imports
  const {
    getAllPropertyAmenities,
    getHotelAmenities,
    removePropertyAmenities,
    updatePropertyAmenities
  } = endPoints.PROPERTY_AMENITIES;

  const [openSlidePanel, setOpenSlidePanel] = useState(false);

  const [allAmenities, setAllAmenities] = useState<any[]>([]);
  const [amenityCategories, setAmenityCategories] = useState<any>({});

  const [selectedAmenities, setSelectedAmenities] = useState<any[]>([]);
  const [updatedAmenities, setUpdatedAmenities] = useState<any[]>([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [access, setAccess] = useState(true);

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "HOTEL";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    getAllAmenities();
  }, [current_property]);

  const getAllAmenities = async () => {
    try {
      let response = await kernelApi.get(`${getAllPropertyAmenities}`);
      if (response.data.status === 1) {
        setAllAmenities(response.data.all_amenities_category);

        let amenity_categories: any = {};
        response.data.all_amenities_category.forEach((amenity: any) => {
          if (amenity.category_name in amenity_categories) {
            amenity_categories[amenity.category_name].push(amenity.id);
          } else {
            amenity_categories[amenity.category_name] = [amenity.id];
          }
        });
        setAmenityCategories(amenity_categories);
        getSelectedAmenities();
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  const getSelectedAmenities = async () => {
    try {
      let response = await kernelApi.get(
        `${getHotelAmenities}` + current_property.hotel_id,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (response.data.status === 1) {
        if (response.data.amenities.facility) {
          let amenities = response.data.amenities.facility
            .split(",")
            .map(Number);
          setSelectedAmenities(amenities);
          setUpdatedAmenities(amenities);
        } else {
          setSelectedAmenities([]);
          setUpdatedAmenities([]);
        }
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const removeAmenity = async (amenity: any) => {
    try {
      let response = await kernelApi.post(`${removePropertyAmenities}`, {
        hotel_id: current_property.hotel_id,
        property_amenities_ids: amenity.id,
      });
      if (response.data.status === 1) {
        getSelectedAmenities();
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const savePropertyAmenities = async () => {
    try {
      let response = await kernelApi.post(`${updatePropertyAmenities}`, {
        hotel_id: current_property.hotel_id,
        property_amenities_ids: updatedAmenities.join(","),
      });

      if (response.data.status === 1) {
        getSelectedAmenities();
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
      setOpenSlidePanel(false);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const toggleAmenity = (amenity: any) => {
    if (!updatedAmenities.includes(amenity.id)) {
      setUpdatedAmenities([...updatedAmenities, amenity.id]);
    } else {
      let amenities = [...updatedAmenities];
      let index = amenities.indexOf(amenity.id);
      if (index > -1) {
        amenities.splice(index, 1);
      }
      setUpdatedAmenities(amenities);
    }
  };

  const addAmenities = (category: string) => {
    setOpenSlidePanel(true);
    setCurrentCategory(category);
  };

  return (
    <>
      <div className="property__setup__amenities__wrapper ps-0 ms-0 pe-0 me-0">
        <div className="property__setup__amenities">
          <div className="property__breadcrumbs">
            <Breadcrumbs />
            <div className="manage__property__action__right">
              <Button
                className="back__btn"
                icon="bi bi-arrow-left"
                handleClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="hr--line"></div>
          <div className="accordion" id="accordionRoomTypes">
            {Object.keys(amenityCategories).map(
              (category: any, index: number) => {
                return (
                  <div
                    className={`accordion-item ${index !== 0 ? "accordion-item-margin-top" : ""
                      }`}
                    key={index}
                  >
                    <div className="accordion-header" id="accordionHeading">
                      <div
                        className="accordion-button accordion__custom__heading"
                        data-bs-toggle={`${selectedAmenities.filter((amenity) =>
                          amenityCategories[category].includes(amenity)
                        ).length
                          ? "collapse"
                          : ""
                          }`}
                        data-bs-target={`#collapse${index}`}
                        aria-controls="collapse"
                        aria-expanded="false"
                      >
                        {category}
                        <span className="amenities__list__item__number">
                          {`${selectedAmenities.filter((amenity) =>
                            amenityCategories[category].includes(amenity)
                          ).length
                            }
                          ${selectedAmenities.filter((amenity) =>
                              amenityCategories[category].includes(amenity)
                            ).length === 1
                              ? "Amenity"
                              : "Amenities"
                            } Selected`}
                        </span>
                        <span className="set--margin">
                          <AddButton
                            icon="bi bi-plus-lg"
                            className="custom__btn__style"
                            handleClick={() => addAmenities(category)}
                          >
                            Add
                          </AddButton>
                        </span>
                      </div>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${index === 0 &&
                        selectedAmenities.filter((amenity) =>
                          amenityCategories[category].includes(amenity)
                        ).length
                        ? "show"
                        : ""
                        }`}
                      aria-labelledby="accordionHeading"
                      data-bs-parent="#accordionRoomTypes"
                    >
                      <div className="accordion-body">
                        <div className="property__setup__amenties__container">
                          {allAmenities
                            .filter(
                              (item: any) =>
                                amenityCategories[category].includes(item.id) &&
                                selectedAmenities.includes(item.id)
                            )
                            .map((amenity: any, id: number) => {
                              return (
                                <div
                                  key={id}
                                  className="amenities__list__items"
                                >
                                  {amenity.label}{" "}
                                  <i
                                    className="bi bi-x"
                                    onClick={() => removeAmenity(amenity)}
                                  ></i>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/*------------ Sliding page for Amenities --------------*/}
      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={openSlidePanel}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlidePanel(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i onClick={() => setOpenSlidePanel(false)} className="bi bi-x"></i>
          </div>
          <div className="standard-page--label">Manage Property Amenities</div>
        </div>

        <div className="height-64px"></div>

        <div className="property__setup__amenities__details">
          <div className="amenities__list__wrapper">
            <div className="accordion" id="accordionRoomAmenities">
              {currentCategory && (
                <div className="accordion-item">
                  <div className="accordion-header" id="accordionHeadingFirst">
                    <button
                      className="accordion-button accordion__custom__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseAmenities"
                      aria-expanded="true"
                      aria-controls="collapse"
                    >
                      {currentCategory}
                      <span className="amenities__list__item__number">
                        {`${updatedAmenities.filter((amenity) =>
                          amenityCategories[currentCategory].includes(amenity)
                        ).length
                          }
                        ${updatedAmenities.filter((amenity) =>
                            amenityCategories[currentCategory].includes(amenity)
                          ).length === 1
                            ? "Amenity"
                            : "Amenities"
                          } Selected`}
                      </span>
                    </button>
                  </div>
                  <div
                    id="collapseAmenities"
                    className="accordion-collapse collapse show"
                    aria-labelledby="accordionHeadingFirst"
                    data-bs-parent="#accordionRoomAmenities"
                  >
                    <div className="accordion-body">
                      <div className="amenities__categories__wrapper">
                        {allAmenities
                          .filter((item: any) =>
                            amenityCategories[currentCategory].includes(item.id)
                          )
                          .map((amenity: any, id: number) => {
                            return (
                              <div
                                className="amenities__categories__list"
                                key={id}
                              >
                                <div
                                  className="amenities__name"
                                  onClick={() => toggleAmenity(amenity)}
                                >
                                  {amenity.label}
                                </div>
                                <div className="amenities__checkbox">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                      onChange={() => toggleAmenity(amenity)}
                                      checked={updatedAmenities.includes(
                                        amenity.id
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper">
            <SaveButton
              className="bi bi-arrow-right"
              handleClick={savePropertyAmenities}
              isAccessible={access}
            >
              Save
            </SaveButton>
          </div>
        </div>
      </SlidingPane>
    </>
  );
};

export default PropertyAmenities;
