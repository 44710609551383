import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import kernelApi from "../../API/kernelApi";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { UpdateSidebar } from "../../UtilityFunctions";
import Button from "../../views/buttons/Button";
import Loader from "../../views/loader/Loader";
import { toast } from 'react-toastify';
import endPoints from "../../API/endPoints";
import InputSelectField from "../../views/inputtextfield/InputSelectField";

const UpgradeSubscription = () => {
  const navigate = useNavigate();
  UpdateSidebar("Manage Subscription", "Upgrade Subscriptions");

  const { current_property } = useSelector((state: RootState) => state.properties);
  const { subscription_customer_id, admin_id, chargebee_subscription_id } = useSelector((state: RootState) => state.auth);
  const { chargebeeEnvironment } = useSelector((state: RootState) => state.userAcess);
  const { getCheckoutUrlForUpgrade, getCheckoutUrlForZohoUpgrade, getTiersPlan, getZohoPlans, getPlans } = endPoints.MANAGESUBSCRIPTION;

  const planData = ["Half Yearly", "Yearly"];
  const [activeMenuTab, setActiveMenuTab] = useState<any>("Half Yearly");
  const [isLoading, setIsLoading] = useState(false);
  const [upgradablePlans, setUpgradablePlans] = useState<any[]>([]);
  const [chargebeSubId, setChargebeSubId] = useState("");
  const [allPlans, setAllPlans] = useState<any>([]);
  const [selected, setSelected] = useState<any>();
  const [selectedValue, setSelectedValue] = useState("");
  const [tiersData, setTiersData] = useState<any>([]);


  useEffect(() => {
    // getSelectedPlan();
    // getUpgradablePlans();
  }, []);

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  const openSubscriptionPopup = {
    cbInstance: window.Chargebee.init({
      site: "hotelkite-test"
      // site: "bookingjini-test"
    })
  }

  const selectPlan = async (planId: string, plan_code: string) => {
    if (chargebeeEnvironment === "HOTELKITEWEB") {
      CallChargebeeApi(planId);
    } else {
      CallBookingjiniApi(plan_code);
    }
  }

  const CallChargebeeApi = async (planId: string) => {
    openSubscriptionPopup.cbInstance.openCheckout({
      async hostedPage() {
        return kernelApi.post(getCheckoutUrlForUpgrade, {
          plan_price_id: planId,
          hotel_id: current_property.hotel_id,
          customer_id: subscription_customer_id,
          app_name: chargebeeEnvironment
        }).then((response) => response.data)
      },
    })
  }

  const CallBookingjiniApi = async (plan_code: string) => {
    try {
      const response = await kernelApi.post(getCheckoutUrlForZohoUpgrade, {
        plan_code: plan_code,
        hotel_id: current_property.hotel_id,
        app_name: chargebeeEnvironment,
      });

      if (response.data.status === 1) {
        window.location.replace(response.data.url);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // changes
  // try {
  //   const response = await kernelApi.post("/get-checkout-url", {
  //     plan_price_id: planId,
  //     hotel_id: current_property.hotel_id,
  //     customer_id: subscription_customer_id,
  //     // app_name: "BOOKINGJINIWEB"
  //     app_name: "LOCALHOST"
  //   });

  //   if (response.data.status === 1) {
  //     window.location.replace(response.data.url);
  //   } else {
  //     toast.error(response.data.message);
  //   }
  // } catch (error) {
  // }



  // openSubscriptionPopup.cbInstance.openCheckout({
  //   async hostedPage() {
  //     return kernelApi.post(getCheckoutUrlForUpgrade, {
  //       new_plan_price_id: planId,
  //       hotel_id: current_property.hotel_id,
  //       // customer_id: subscription_customer_id,
  //       customer_id: current_property.hotel_id,
  //       app_name: chargebeeEnvironment,
  //       current_subscription_id: chargebee_subscription_id,
  //       tier: selected
  //     }).then((response) => response.data)
  //   },
  // })
  // changes

  // const getUpgradablePlans = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await kernelApi.post(getAllUpgradePlans, {
  //       "hotel_id": current_property.hotel_id,
  //       "admin_id": admin_id
  //     });
  //     setIsLoading(false);
  //     if (response.data.status === 1) {
  //       setUpgradablePlans(response.data.upgradable_plans);
  //       setChargebeSubId(response.data.chargebee_subscription_id);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // }

  useEffect(() => {
    getallPlans();
  }, [activeMenuTab, selected]);

  useEffect(() => {
    getTiers();
  }, []);

  const getTiers = async () => {
    try {
      let response = await kernelApi.get(getTiersPlan);
      if (response.data.status === 1) {
        setTiersData(response.data.tiers);
        setSelected(response.data.tiers[0]?.id)
        setSelectedValue(response.data.tiers?.value);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getallPlans = async () => {
    if (selected) {
      setIsLoading(true);
      try {
        const res = await kernelApi.get(`${chargebeeEnvironment === "HOTELKITEWEB" ? getPlans : getZohoPlans}`, {
          params: {
            "paying_term": activeMenuTab.toUpperCase(),
            "tier": selected
          }
        }
        );
        setIsLoading(false);
        if (res.data.status === 1) {
          setAllPlans(res.data.plans);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  }

  return (
    <>
      <div className="manage__subscription__header__wrapper">
        <div className="property__breadcrumbs">
          <Breadcrumbs />
        </div>
        <div className="manage__subscription__back__btn">
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>
      <div className="manage--subscription--header">
        <div>Select a plan</div>
      </div>
      {isLoading ? <div className="plan__card__loader"><Loader /></div>
        :
        // <div className="plan__card__parent">
        //   {upgradablePlans?.map((item: any, index: number) => {
        //     return (
        //       <div key={index} className="plan__card__wrapper mb-4">
        //         <div
        //           className="plan__card__box me-4" key={index}>
        //           <div className="plan__label">{item.name}</div>
        //           {item?.description?.map((detail: any, id: number) => {
        //             return (
        //               <div key={id} className="active__plan__name mb-3"><i className="bi bi-check me-1"></i> {detail}</div>
        //             )
        //           })}
        //           <div className="price__and__plan__choose__wrapper">
        //             <div className="price__value mb-3">{`${item.prices[0].price} ${item.prices[0].currencyCode}`} <span>/{item.prices[0].periodUnit}</span></div>
        //             <div className="subscription__btn">
        //               <Button className="sub__btn" handleClick={() => selectPlan(item.prices[0].id)}>Upgrade</Button>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     )
        //   })}
        // </div>
        <div className="plan__card__parent">
          <div className="top__subscription__header__wrapper">
            <div className="top__subscription__dropdown">
              <InputSelectField
                label={"Rooms"}
                value={selected}
                // options={tiersData}

                values={tiersData?.map((tier: any) => {
                  return tier.id;
                })}
                options={tiersData?.map((tier: any) => {
                  return tier.value;
                })}


                handleChange={(e) => {
                  setSelected(e.target.value);
                }}
              />
            </div>

            <div className="monthly__yearley__wrapper mb-4">
              <ul className="nav nav-tabs" id="monthlyYearlyPrice" role="tablist">
                {planData.map((tab) => {
                  return (
                    <li
                      className="nav-item"
                      role="presentation"
                      key={tab}
                      onClick={() => switchMenuTab(tab)}
                    >
                      <a
                        className={`nav-link nav__link__width ${tab === activeMenuTab ? "active" : ""
                          }`}
                        id={`${tab}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab}`}
                        aria-controls={`#${tab}`}
                        role="tab"
                        aria-selected="true"
                      >
                        {tab}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="price__feature__plan__wrappers">
            {allPlans?.map((item: any, index: number) => {
              return (
                <div key={index} className="feature__plan__box card me-3">
                  <div className="plan__name mb-3">{item.name}</div>
                  {item?.description?.map((detail: any, id: number) => {
                    return (
                      <div key={id} className="active__plan__name mb-3"><i className="bi bi-check me-1"></i> {detail}</div>
                    )
                  })}
                  <div className="price__and__plan__choose__wrapper">
                    <div className="feature__plan__price mb-3">{`${item.prices[0]?.price} ${item.prices[0]?.currencyCode}`} <span>/{item.prices[0]?.periodUnit}</span></div>
                    <div className="select__plan__btn">
                      <Button handleClick={() => selectPlan(item.prices[0].id, item.code)}>Select</Button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      }

    </>
  );
};

export default UpgradeSubscription;
