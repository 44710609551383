import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { store } from "../../redux/store";
import { useDispatch } from "react-redux";
import { NewPropertyImages } from "../../redux/actions/AddPropertyAction";

import { ProgressBar } from "react-step-progress-bar";

import ImageUploadArea from "../../components/property/propertyimageupload/ImageUploadArea";
import ImageViewArea from "../../components/property/propertyimageupload/ImageViewArea";
import GetHelp from "../../components/gethelp/GetHelp";
import NextButton from "../../views/buttons/NextButton";
import BackButton from "../../views/buttons/Button";
import SkipButton from "../../views/buttons/SkipButton";
import { toast } from "react-toastify";
import { Logout } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

const PropertyImages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState<any[]>(
    store.getState().add_property.new_property_images
  );

  //Endpoints Import
  const { addNewPropertyPropOverview, addNewPropertyPropAmenities } = endPoints.ADDNEWPROPERTY
  // --------------------------------

  const SaveImages = (event: any) => {
    let Images = [...images];
    for (let file = 0; file < event.target.files.length && file < 8; file++) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        // file size is less than 1 MB
        Images.push(image);
      } else {
        toast("Image size should be less than 1MB", {
          hideProgressBar: true,
          type: "error"
        });
      }
    }
    setImages(Images.slice(0, 7));
  };

  // const blobToBase64 = async (blob: any) => {
  //   var reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = function () {
  //     let base64String = reader.result;
  //     return base64String;
  //   }
  // }

  const UploadImage = () => {
    document.getElementById("image-input")?.click();
  };

  const RemoveImage = (index: number) => {
    let img = [...images];
    img.splice(index, 1);
    setImages(img);
  };

  const SubmitImages = () => {
    if (images.length > 0) {
      dispatch(NewPropertyImages(images));
      setTimeout(() => {
        navigate(addNewPropertyPropOverview);
      }, 500);
    } else {
      toast("Please select atleast one image", {
        hideProgressBar: true,
        type: "error"
      });
    }
  };

  const SkipHandler = () => {
    dispatch(NewPropertyImages([]));
    setTimeout(() => {
      navigate(addNewPropertyPropOverview);
    }, 500);
  };

  const BackButtonClick = () => {
    navigate(addNewPropertyPropAmenities);
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <div className="div__input__wrapper__left div__left__side__box">
          <div className="logo__wrapper"></div>
          <div className="div__user__testimonials__wrapper">
            <div className="select__property__wrapper">
              <div className="select__property__text">
                Time to add some great images of your property
              </div>
              <div className="select__property__sub__text">
                This details will be seen by guests when they search for a place
                to stay.
              </div>
            </div>
          </div>
          <div className="progress-bar-wrapper">
            <div className="progress-bar-text">5/5</div>
            <div className="progress-bar-status">
              <ProgressBar percent={100} filledBackground="#ffffff" />
            </div>
          </div>
        </div>
        <div className="div__input__wrapper__right div__right__side__box">
          <BackButton
            className="logout__btn"
            handleClick={() => {
              Logout();
            }}
          >
            logout
          </BackButton>
          <div className="div__inputs">
            <div className="div__amenities__container">
              <div className="div--head--label">
                You can select upto 7 Images
              </div>
              <div className="div--helper--text">Select below</div>

              <div className="div_image_upload">
                {images.length === 0 ? (
                  <ImageUploadArea upload={UploadImage}></ImageUploadArea>
                ) : (
                  <ImageViewArea
                    images={images}
                    RemoveImage={RemoveImage}
                    upload={UploadImage}
                  ></ImageViewArea>
                )}
              </div>
              <input
                id="image-input"
                name="image-input"
                type="file"
                accept="image/png, image/jpeg"
                className="d-none"
                multiple
                onChange={SaveImages}
              />
            </div>
            <div className="__btn__wrapper mt-5">
              <BackButton className="back__btn" handleClick={BackButtonClick}>
                Back
              </BackButton>
              <NextButton handleClick={SubmitImages} className="resize_btn" />
              <div className="skip-button">
                <SkipButton handleClick={SkipHandler} />
              </div>
            </div>
            <GetHelp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyImages;
