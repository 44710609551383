import React, { useState, useEffect } from "react";
import LeftSideNav from '../../components/onboarding/LeftSidenav'
import RightSideContent from '../../components/onboarding/RightSideContent'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from "react-router-dom";

// import lottie
import Lottie from "lottie-react-web";
import Confetti from "../../assets/lottie/confetti.json";
import SucessCone from "../../assets/svg/sucess_cone.svg";
import Button from "../../views/buttons/Button";
import kernelApi from "../../API/kernelApi";
import Loader from "../../views/loader/Loader";
import endPoints from "../../API/endPoints";

const RenewSubscriptionSuccess = () => {
    const navigate = useNavigate();
    const { current_property } = useSelector((state: RootState) => state.properties);
    const [planDetails, setPlanDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [trigger, setTrigger] = useState(false);
    const { mySubscribedPlan } = endPoints.MANAGESUBSCRIPTION;

    useEffect(() => {
        setTimeout(() => {
            getSelectedPlanDetails();
        }, 10000)
    }, [trigger]);

    const getSelectedPlanDetails = async () => {
        setIsLoading(true);
        try {
            const res = await kernelApi.get(`${mySubscribedPlan}/${current_property.hotel_id}`);
            if (res.data.status === 1) {
                setPlanDetails(res.data.plan_details[0]);
                setIsLoading(false);
            } else {
                setTrigger(!trigger);
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <div className="div-page-wrapper">
                <div className="div__wrapper">
                    <LeftSideNav>
                        <>
                            <div className="div__user__testimonials__wrapper">
                                <div className="select__property__wrapper">
                                    <div className="select__property__text">
                                        Select Plan
                                    </div>
                                    <div className="select__property__sub__text">
                                        Please select a plan to start using Bookingjini
                                    </div>
                                </div>
                            </div>
                        </>
                    </LeftSideNav>

                    <RightSideContent>
                        <>{isLoading ? <div className="plan__details__loader"><Loader /></div> :
                            <div className="choose__plan__success">
                                <div className="plan__text__content me-5">
                                    <div className="confetti__wrapper">
                                        <Lottie
                                            options={{
                                                animationData: Confetti,
                                                loop: false,
                                            }}
                                        />
                                    </div>
                                    <div><img src={SucessCone} alt="Confetti Cone Image" /></div>
                                    <div className="property__name__success mb-3">Subscribed to <span className="plan__name">{planDetails?.name}</span></div>
                                    <div className="choose__plan__property__success__btn mb-3">
                                        <Button handleClick={() => { navigate("/") }}>Go to Dashboard<i className="ms-2 bi bi-arrow-right"></i></Button>
                                    </div>
                                </div>
                                <div className="plan__details__section">
                                    <div className="plan__details__card">
                                        <div className="plan__name mb-3">{planDetails?.name}</div>
                                        {planDetails?.description?.map((feature: string, index: number) => {
                                            return (
                                                <div className="active__plan__name mb-3" key={index}><i className="bi bi-check me-1"></i> {feature}</div>
                                            )
                                        })}
                                        <div className="plan__price mb-3">{planDetails?.prices[0].currencyCode} {planDetails?.prices[0].price}<span> /{planDetails?.prices[0].periodUnit}</span></div>
                                    </div>
                                </div>
                            </div>
                        }
                        </>
                    </RightSideContent>
                </div>
            </div>
        </>
    )
}

export default RenewSubscriptionSuccess