import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

// Google Analytics Page Tracking
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-240157394-2"; // Google Analytics Tracking ID
ReactGA.initialize(TRACKING_ID);

const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(
        function () {
            const path = location.pathname + location.search;
            ReactGA.set({ page: path });
            ReactGA.pageview(path); // Record a pageview for the given page
        },
        [location],
    );
    return <div></div>;
};

export default RouteChangeTracker;