import React, { useState, useEffect } from "react";
import axios from "axios";
// import Nivo Charts
import { ResponsiveBar } from "@nivo/bar";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Logout } from "../../UtilityFunctions";
import kernelApi from "../../API/kernelApi";
import endPoints from "../../API/endPoints";

const RoomTypesComparison = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const barRoomTypeCompChartData = ["Today", "MTD", "YTD"];
  const [activeMenuTab, setActiveMenuTab] = useState<any>("Today");
  const [todayBarChartData, setTodayBarChartData] = useState<any>();
  const [mtdBarChartData, setMTDBarChartData] = useState<any>();
  const [ytdBarChartData, setYTDBarChartData] = useState<any>();

  const { roomtypeWiseBookedRoomNights } = endPoints.DASHBOARDDATA;

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  useEffect(() => {
    getTodayBarChartData();
    getMTDBarChartData();
    getYTDBarChartData();
  }, [current_property]);

  const getTodayBarChartData = async () => {
    try {
      const res = await kernelApi.get(
        `${roomtypeWiseBookedRoomNights}/${current_property.hotel_id}/TODAY/null/null`
        // `https://kernel.bookingjini.com/roomtype-wise-booked-room-nights/1993/TODAY/null/null`
      );

      if (res.data.status === 1) {
        setTodayBarChartData(res.data);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const getMTDBarChartData = async () => {
    try {
      const res = await kernelApi.get(
        `${roomtypeWiseBookedRoomNights}/${current_property.hotel_id}/MTD/null/null`
        // `https://kernel.bookingjini.com/roomtype-wise-booked-room-nights/1993/MTD/null/null`
      );

      if (res.data.status === 1) {
        setMTDBarChartData(res.data);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const getYTDBarChartData = async () => {
    try {
      const res = await kernelApi.get(
        `${roomtypeWiseBookedRoomNights}/${current_property.hotel_id}/YTD/null/null`
        // `https://kernel.bookingjini.com/roomtype-wise-booked-room-nights/1993/YTD/null/null`
      );

      if (res.data.status === 1) {
        setYTDBarChartData(res.data);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <>
      <div className="dashboard__standard__header pt-1 mb-4 mt-4">
        <div className="dashboard__standard__label">Room Types Comparison</div>
        <div className="dashboard__standard__action__btns">
          <ul className="nav nav-tabs" id="duesTab" role="tablist">
            {barRoomTypeCompChartData.map((tab) => {
              return (
                <li
                  className="nav-item"
                  role="presentation"
                  key={tab}
                  onClick={() => switchMenuTab(tab)}
                >
                  <a
                    className={`nav-link ${
                      tab === activeMenuTab ? "active" : ""
                    }`}
                    id={`${tab}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab}`}
                    aria-controls={`#${tab}`}
                    role="tab"
                    aria-selected="true"
                  >
                    {tab}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {activeMenuTab === "Today" ? (
        <div className="dashborad__standard__chart__wrapper">
          <div className="bar__chart__wrapper">
            {todayBarChartData?.roomtypeBarChartData?.length > 0 ? (
              <ResponsiveBar
                data={todayBarChartData?.roomtypeBarChartData}
                indexBy="label"
                margin={{ top: 10, right: 10, bottom: 40, left: 40 }}
                padding={0.45}
                colors="#706fd3"
                animate={true}
                // motionStiffness={90}
                // motionDamping={15}
                enableLabel={false}
                borderRadius={6}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Room Types",
                  legendPosition: "middle",
                  legendOffset: 30,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Room Nights",
                  legendPosition: "middle",
                  legendOffset: -35,
                }}
                tooltip={(iteam) => {
                  return (
                    <div className="dashboard-tooltip-wrapper">
                      <div
                        className="me-2 dashboard-tooltip-color"
                        style={{
                          backgroundColor: `${iteam.color}`,
                        }}
                      ></div>
                      <span className="me-2 dashboard-tooltip-label">
                        {iteam.data.label} :
                      </span>
                      <span className="dashboard-tooltip-value">
                        {" "}
                        {iteam.data.value}
                      </span>
                    </div>
                  );
                }}
              />
            ) : (
              <div className="pie-nodata-container">
                <div className="no-data-label-container">
                  <div className="icon-container">
                    <i className="bi bi-x-circle mb-3"></i>
                  </div>

                  <div className="no-data-label">
                    No Data on Room Types Comparison
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : activeMenuTab === "MTD" ? (
        <div className="dashborad__standard__chart__wrapper">
          <div className="bar__chart__wrapper">
            {mtdBarChartData?.roomtypeBarChartData?.length > 0 ? (
              <ResponsiveBar
                data={mtdBarChartData?.roomtypeBarChartData}
                indexBy="label"
                margin={{ top: 10, right: 10, bottom: 40, left: 40 }}
                padding={0.45}
                colors="#706fd3"
                animate={true}
                // motionStiffness={90}
                // motionDamping={15}
                enableLabel={false}
                borderRadius={6}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Room Types",
                  legendPosition: "middle",
                  legendOffset: 30,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Room Nights",
                  legendPosition: "middle",
                  legendOffset: -35,
                }}
                tooltip={(iteam) => {
                  return (
                    <div className="dashboard-tooltip-wrapper">
                      <div
                        className="me-2 dashboard-tooltip-color"
                        style={{
                          backgroundColor: `${iteam.color}`,
                        }}
                      ></div>
                      <span className="me-2 dashboard-tooltip-label">
                        {iteam.data.label} :
                      </span>
                      <span className="dashboard-tooltip-value">
                        {" "}
                        {iteam.data.value}
                      </span>
                    </div>
                  );
                }}
              />
            ) : (
              <div className="pie-nodata-container">
                <div className="no-data-label-container">
                  <div className="icon-container">
                    <i className="bi bi-x-circle mb-3"></i>
                  </div>

                  <div className="no-data-label">
                    No Data on Room Types Comparison
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="dashborad__standard__chart__wrapper">
          <div className="bar__chart__wrapper">
            {ytdBarChartData?.roomtypeBarChartData?.length > 0 ? (
              <ResponsiveBar
                data={ytdBarChartData?.roomtypeBarChartData}
                indexBy="label"
                margin={{ top: 10, right: 10, bottom: 40, left: 40 }}
                padding={0.45}
                colors="#706fd3"
                animate={true}
                // motionStiffness={90}
                // motionDamping={15}
                enableLabel={false}
                borderRadius={6}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Room Types",
                  legendPosition: "middle",
                  legendOffset: 30,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Room Nights",
                  legendPosition: "middle",
                  legendOffset: -35,
                }}
                tooltip={(iteam) => {
                  return (
                    <div className="dashboard-tooltip-wrapper">
                      <div
                        className="me-2 dashboard-tooltip-color"
                        style={{
                          backgroundColor: `${iteam.color}`,
                        }}
                      ></div>
                      <span className="me-2 dashboard-tooltip-label">
                        {iteam.data.label} :
                      </span>
                      <span className="dashboard-tooltip-value">
                        {" "}
                        {iteam.data.value}
                      </span>
                    </div>
                  );
                }}
              />
            ) : (
              <div className="pie-nodata-container">
                <div className="no-data-label-container">
                  <div className="icon-container">
                    <i className="bi bi-x-circle mb-3"></i>
                  </div>

                  <div className="no-data-label">
                    No Data on Room Types Comparison
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RoomTypesComparison;
