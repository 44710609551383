import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// import select feild
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../views/inputtextfield/InputMultiSelectField";

import InventoryBtn from "../../views/buttons/Button";

import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";
import { Code } from "@mui/icons-material";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

interface IEditAcces {
  onClose: () => void;
  user_id: any;
}

const EditAccess: React.FC<IEditAcces> = ({ onClose, user_id }) => {
  const { company_id, admin_id, auth_token, email, password } = useSelector(
    (state: RootState) => state.auth
  );
  const [accessData, setAccessData] = useState<any>([]);
  const [viewPayload, setViewPayload] = useState<any>([]);
  const [updatePayload, setUpdatePayload] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState<any[]>([]);

  const { getUserAccess, getAccessFunctionality, userAccessUpdationOrCreation } = endPoints.MANAGEUSER;

  useEffect(() => {
    setFilteredResults(accessData);
  }, [accessData]);

  let hotels = ["heritance", "syeman"];
  const Edit_Access = async () => {
    try {
      let response = await kernelApi.get(`${getUserAccess}/${user_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      let userCode: any[] = [];
      if (response.data.status === 1) {
        userCode = response.data.functions;
      }
      let res = await kernelApi.get(getAccessFunctionality, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      let userDescription = res.data.data;
      if (response.data.status === 1) {
        // userDescription.forEach((des: any) => {
        //   userCode.forEach((userData: any) => {
        //     if (des.code !== userData.code) {
        //       des["access_value"] = 2;
        //     } else {
        //       des["access_value"] = userData.access_value;
        //     }
        //     // if (!userData.access_value) {
        //     //   des["access_value"] = 2;
        //     // }
        //     // } else {
        //     //   des["access_value"] = 2;
        //     // }
        //   });
        // });

        userDescription.forEach((data: any, id: any) => {
          let index = userCode.findIndex((value: any) => {
            return value.code === data.code;
          });
          if (index > -1) {
            userDescription[id]["access_value"] = userCode[index].access_value;
          } else {
            userDescription[id]["access_value"] = 2;
          }
        });
      } else {
        userDescription.forEach((des: any) => {
          des["access_value"] = 2;
        });
      }
      setAccessData(userDescription);
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const viewApp = (index: number, value: any, code: any) => {
    let copy = [...filteredResults];
    let payload = [...viewPayload];

    copy[index].access_value = value === 1 ? 2 : 1;

    const idx = payload.findIndex((object) => object === code);
    if (idx === -1) {
      payload.push(code);
    } else {
      payload = payload.filter((desc_code: any) => {
        return desc_code !== code;
      });
    }

    setViewPayload(payload);
    setFilteredResults(copy);
  };

  useEffect(() => {
    Edit_Access();
  }, []);

  const updateEditAccess = async () => {
    let payload: any[] = [];
    filteredResults?.forEach((desc) => {
      if (viewPayload.includes(desc.code)) {
        payload.push({ code: desc.code, access_value: desc.access_value });
      }
    });

    onClose();
    if (payload.length > 0) {
      try {
        let response = await kernelApi.post(
          userAccessUpdationOrCreation,
          {
            company_id: company_id,
            user_id: user_id,
            function_access: payload,
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (response.data.status === 1) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } else {
      toast("Atleast change one Access", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = accessData.filter((item: any) => {
        return item.description.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(accessData);
    }
    setSearchInput(keyword);
  };

  return (
    <>
      <div className="edit__manage__access__wrapper">
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i className="bi bi-x" onClick={onClose}></i>
          </div>
          <div className="standard-page--label">Manage Access</div>
        </div>

        <div className="height-64px"></div>
        <div className="access__wrapper my-3">
          <div className="input__search__wrapper">
            <div className="search__input">
              <input
                type="text"
                value={searchInput}
                placeholder="Search Permissions"
                onChange={(e) => setSearchInput(e.target.value)}
              ></input>
            </div>
            <div className="search_btn manage__access__btn">
              <InventoryBtn
                handleClick={() => {
                  searchItems(searchInput);
                }}
              >
                <i className="bi bi-search"></i>
              </InventoryBtn>
            </div>
          </div>
          {filteredResults &&
            filteredResults?.length > 0 &&
            filteredResults?.map((data: any, id: any) => {
              return (
                <div className="access__card__wrapper mb-4" key={id}>
                  <div className="apps_label">{data.description}</div>
                  <div className="view-check-wrapper">
                    <div className="view-check me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        //  name="view"
                        // value={data.access_value}
                        id={`setDefault${id}`}
                        checked={data.access_value === 2}
                        onChange={(e) =>
                          viewApp(id, data.access_value, data.code)
                        }
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        view
                      </label>
                    </div>
                    <div className="view-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        //   name="create"
                        // value={data.access_value}
                        checked={data.access_value === 1}
                        onChange={(e) =>
                          viewApp(id, data.access_value, data.code)
                        }
                        id="setDefault"
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Create and Update
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-1">
          <InventoryBtn handleClick={updateEditAccess}>Save</InventoryBtn>
        </div>
      </div>
    </>
  );
};

export default EditAccess;
