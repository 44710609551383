import React from 'react'
import { toast, ToastContainer } from "react-toastify";

function Toast(msg, type) {
    if (type) {
        toast(msg, {
            hideProgressBar: true,
            type: type
        });
    }
    else {
        toast(msg, {
            hideProgressBar: true,
            type: 'success'
        });
    }
}

export default Toast