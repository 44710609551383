import React, { useState, useEffect } from "react";

import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';

import kernelApi from '../../API/kernelApi';

import Button from '../../views/buttons/Button';
import LeftSideNav from "../../components/onboarding/LeftSidenav";

import { ProgressBar } from "react-step-progress-bar";
import Loader from "../../views/loader/Loader";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import { toast } from "react-toastify";
import endPoints from "../../API/endPoints";

const ChoosePlan = () => {

    const { subscription_customer_id } = useSelector((state: RootState) => state.auth);
    const { current_property, property_data } = useSelector((state: RootState) => state.properties);
    const { chargebeeEnvironment } = useSelector((state: RootState) => state.userAcess);

    const { getCheckoutUrlForChargebee, getCheckoutUrl, getTiersPlan, getZohoPlans, getPlans } = endPoints.MANAGESUBSCRIPTION;


    const planData = ["Half Yearly", "Yearly"];
    const [activeMenuTab, setActiveMenuTab] = useState<any>("Half Yearly");
    const [allPlans, setAllPlans] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState("");
    const [tiersData, setTiersData] = useState<any>([]);

    const switchMenuTab = (tab: string) => {
        setActiveMenuTab(tab);
    };

    const openSubscriptionPopup = {
        cbInstance: window.Chargebee.init({
            site: "hotelkite-test"
            // site: "bookingjini-test"
        })
    }

    // const selectPlan = async (planId: number) => {
    //     try {
    //         const response = await beApi.post("/update-subscription-plan", {
    //             hotel_id: current_property?.hotel_id,
    //             plan_id: planId
    //         });

    //         if (response.data.status === 1) {
    //             getHotelsbyCompanyId(current_property.hotel_id);
    //         } else {
    //             toast(response.data.message, {
    //   hideProgressBar: true,
    //   type: "error"
    // });
    //         }

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const selectPlan = async (planId: string, plan_code: string) => {
        if (chargebeeEnvironment === "HOTELKITEWEB") {
            CallChargebeeApi(planId);
        } else {
            CallBookingjiniApi(plan_code);
        }
        // try {
        //     const response = await kernelApi.post("/get-checkout-url", {
        //         plan_price_id: planId,
        //         hotel_id: current_property.hotel_id,
        //         customer_id: subscription_customer_id,
        //         // app_name: "BOOKINGJINIWEB"
        //         app_name: "LOCALHOST"
        //     });

        //     if (response.data.status === 1) {
        //         window.location.replace(response.data.url);
        //     } else {
        //         toast(response.data.message, {
        //             hideProgressBar: true,
        //             type: "error"
        //         });
        //     }

        // } catch (error) {
        //     console.log(error);
        // }

        // openSubscriptionPopup.cbInstance.openCheckout({
        //     async hostedPage() {
        //         return kernelApi.post("/get-checkout-url", {
        //             plan_price_id: planId,
        //             hotel_id: current_property.hotel_id,
        //             customer_id: subscription_customer_id,
        //             app_name: chargebeeEnvironment
        //         }).then((response) => response.data)
        //     },
        // })
    }

    const CallChargebeeApi = async (planId: string) => {
        openSubscriptionPopup.cbInstance.openCheckout({
            async hostedPage() {
                return kernelApi.post(getCheckoutUrlForChargebee, {
                    plan_price_id: planId,
                    hotel_id: current_property.hotel_id,
                    customer_id: subscription_customer_id,
                    app_name: chargebeeEnvironment
                }).then((response) => response.data)
            },
        })
    }

    const CallBookingjiniApi = async (plan_code: string) => {
        try {
            const response = await kernelApi.post(getCheckoutUrl, {
                plan_code: plan_code,
                hotel_id: current_property.hotel_id,
                app_name: chargebeeEnvironment,
            });

            if (response.data.status === 1) {
                window.location.replace(response.data.url);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // const getHotelsbyCompanyId = async (hotelId: string) => {
    //     try {
    //         let comp_hash = sha512(company_id.toString());
    //         let response = await kernelApi.get(`/hotel_admin/get_all_hotels_by_company/${comp_hash}/${company_id}`,
    //             {
    //                 headers: {
    //                     Authorization: "Bearer " + auth_token,
    //                 }
    //             });

    //         if (response.data.status === 1) {
    //             const property = response.data.data.filter((hotel: any) => hotel.hotel_id === hotelId)[0];
    //             if (property) {
    //                 dispatch(CurrentProperty(property));
    //                 setTimeout(() => {
    //                     navigate(redirect ? redirect : "/");
    //                 }, 200);
    //             }
    //             dispatch(AllProperties(response.data));
    //         } else {
    //             dispatch(AllProperties(null));
    //             dispatch(CurrentProperty(null));
    //         }
    //     } catch (error: any) {
    //         if (error?.response?.status === 400) {
    //             Logout();
    //         }
    //     }
    // };

    useEffect(() => {
        if (selected !== "") {
            getallPlans();
        }
    }, [activeMenuTab, selected]);


    useEffect(() => {
        getTiers();
    }, []);

    const getTiers = async () => {
        try {
            let response = await kernelApi.get(getTiersPlan);
            if (response.data.status === 1) {
                setTiersData(response.data.tiers);
                setSelected(response.data.tiers[0]?.id)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getallPlans = async () => {
        setIsLoading(true);
        try {
            const res = await kernelApi.get(`${chargebeeEnvironment === "HOTELKITEWEB" ? getPlans : getZohoPlans}`, {
                params: {
                    "paying_term": activeMenuTab.toUpperCase(),
                    "tier": selected
                }
            }
            );

            if (res.data.status === 1) {
                setAllPlans(res.data.plans);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    return (
        <>
            <div className="div-page-wrapper">
                <div className="div__wrapper">
                    <LeftSideNav>
                        <>
                            <div className="div__user__testimonials__wrapper">
                                <div className="screen__progress__wrapper">
                                    <div className="progress__status__wrapper">
                                        <div className="status__icon__wrapper bg-white">
                                            <i className="bi bi-check progress--icon--check"></i>
                                        </div>
                                        <div className="status__label">Create {property_data.data.length === 1 ? "First" : ""} Property</div>
                                    </div>

                                    <div className="progress__status__wrapper mt-5">
                                        <div className="status__icon__wrapper">
                                            <i className="bi bi-play-fill progress--icon--play"></i>
                                        </div>
                                        <div className="status__label">Select Subscription Plan</div>
                                    </div>

                                    <div className="progress__status__wrapper mt-5">
                                        <div className="status__icon__wrapper">
                                        </div>
                                        <div className="status__label">Create Room Type</div>
                                    </div>
                                    <div className="progress__sub__list__wrapper">
                                        <div className="progress__sub__listitems">
                                            Enter Room Deails
                                        </div>
                                        <div className="progress__sub__listitems">
                                            Define Occupancy
                                        </div>
                                        <div className="progress__sub__listitems">
                                            Add Rates
                                        </div>
                                        <div className="progress__sub__listitems">
                                            Upload Images
                                        </div>
                                    </div>
                                    <div className="progress__status__wrapper mt-5">
                                        <div className="status__icon__wrapper"></div>
                                        <div className="status__label">Create Floors</div>
                                    </div>
                                    <div className="progress__status__wrapper mt-5">
                                        <div className="status__icon__wrapper"></div>
                                        <div className="status__label">Add Rooms</div>
                                    </div>
                                </div>
                            </div>

                            <div className="progress-bar-wrapper">
                                <div className="progress-bar-text">4/4</div>
                                <div className="progress-bar-status">
                                    <ProgressBar percent={100} filledBackground="#ffffff" />
                                </div>
                            </div>
                        </>
                        {/* <div className="div__user__testimonials__wrapper">
                            <div className="select__property__wrapper">
                                <div className="select__property__text">Select Plan</div>
                                <div className="select__property__sub__text">Please select a plan to start using Bookingjini</div>
                            </div>
                        </div> */}
                    </LeftSideNav>

                    <div className="div__input__wrapper__right div__right__side__box price--wrapper--padding--left">
                        <CurrentPropertyLabel />

                        <div className="top__subscription__header__wrapper">
                            <div className="top__subscription__dropdown">
                                <InputSelectField
                                    label={"Rooms"}
                                    value={selected}
                                    // options={tiersData}

                                    values={tiersData?.map((tier: any) => {
                                        return tier.id;
                                    })}
                                    options={tiersData?.map((tier: any) => {
                                        return tier.value;
                                    })}


                                    handleChange={(e) => {
                                        setSelected(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="monthly__yearley__wrapper mb-4">
                                <ul className="nav nav-tabs" id="monthlyYearlyPrice" role="tablist">
                                    {planData.map((tab) => {
                                        return (
                                            <li
                                                className="nav-item"
                                                role="presentation"
                                                key={tab}
                                                onClick={() => switchMenuTab(tab)}
                                            >
                                                <a
                                                    className={`nav-link nav__link__width ${tab === activeMenuTab ? "active" : ""
                                                        }`}
                                                    id={`${tab}-tab`}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#${tab}`}
                                                    aria-controls={`#${tab}`}
                                                    role="tab"
                                                    aria-selected="true"
                                                >
                                                    {tab}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>


                        {isLoading ? <div className="price__feature__plan__wrappers plan__loader__center__align"><Loader /></div>
                            :
                            <div className="price__feature__plan__wrappers">
                                {allPlans?.map((item: any, index: number) => {
                                    return (
                                        <div key={index} className="feature__plan__box card me-3">
                                            <div className="plan__name mb-3">{item.name}</div>
                                            {item?.description?.map((detail: any, id: number) => {
                                                return (
                                                    <div key={id} className="active__plan__name mb-3"><i className="bi bi-check me-1"></i> {detail}</div>
                                                )
                                            })}
                                            <div className="price__and__plan__choose__wrapper">
                                                <div className="feature__plan__price mb-3">{`${item.prices[0]?.price} ${item.prices[0]?.currencyCode}`} <span>/{item.prices[0]?.periodUnit}</span></div>
                                                <div className="select__plan__btn">
                                                    <Button handleClick={() => selectPlan(item.prices[0].id, item.code)}>Select</Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChoosePlan;