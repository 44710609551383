
import { SET_CURRENT_PAYMENT_GATEWAY_DETAILS, SHOW_SETUP_PAYMENT_SLIDER, HIDE_SETUP_PAYMENT_SLIDER, SET_NEW_PAYMENT_MODE_ADDED, SHOW_ADD_PAYMENT_SLIDER, HIDE_ADD_PAYMENT_SLIDER } from "../actionTypes"


export const SetCurrentPaymentGatewayDetails = (data: object) => {
    return {
        type: SET_CURRENT_PAYMENT_GATEWAY_DETAILS,
        payload: data
    }
}



export const ShowSetupPaymentSlider = () => {
    return {
        type: SHOW_SETUP_PAYMENT_SLIDER,
    }
}

export const HideSetupPaymentSlider = () => {
    return {
        type: HIDE_SETUP_PAYMENT_SLIDER,
    }
}

// -------------------------
export const OpenAddPaymentSlider = () => {
    return {
        type: SHOW_ADD_PAYMENT_SLIDER,
    }
}

export const HideAddPaymentSlider = () => {
    return {
        type: HIDE_ADD_PAYMENT_SLIDER,
    }
}



export const SetUpNewPaymodeMode = (value: string) => {
    return {
        type: SET_NEW_PAYMENT_MODE_ADDED,
        payload: value
    }
}
