import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import NextButton from "../../views/buttons/NextButton";

// import lottie
import Lottie from "lottie-react-web";
import Congratulations from "../../assets/lottie/congratulations.json";
import Confetti from "../../assets/lottie/confetti.json";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";

const FloorCreationSuccess = () => {
  const navigate = useNavigate();

  const { property_data } = useSelector((state: RootState) => state.properties);

  const OnNextClick = () => {
    navigate("/add-rooms");
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <LeftSideNav>
          <>
            <div className="div__user__testimonials__wrapper">
              <div className="screen__progress__wrapper">
                <div className="progress__status__wrapper">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Create {property_data.data.length === 1 ? "First" : ""} Property</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Select Subscription Plan</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Create Room Type</div>
                </div>
                <div className="progress__sub__list__wrapper"></div>
                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Create Floors</div>
                </div>
                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper">
                    <i className="bi bi-play-fill progress--icon--play"></i>
                  </div>

                  <div className="status__label">Add Rooms</div>
                </div>
              </div>
            </div>
          </>
        </LeftSideNav>

        <div className="div__input__wrapper__right div__right__side__box justify-content-center ps-0">
          <CurrentPropertyLabel />
          <div className="congratulations__message__wrapper room__type__progress__width">
            <div className="lottie__wrapper">
              <Lottie
                options={{
                  animationData: Congratulations,
                }}
              />
            </div>
            <div className="congratulation--text">Awesome!!</div>
            <div className="congratulation--sub--text mb-4">
              You have successfully added your floor
            </div>
            <div className="__btn__wrapper justify-content-center mt-4">
              <NextButton handleClick={OnNextClick} />
            </div>
          </div>
          <div className="confetti__wrapper">
            <Lottie
              options={{
                animationData: Confetti,
                loop: false,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorCreationSuccess;
