import React from "react";

export interface IImageView {
  images: any[];
  RemoveImage: (index: number) => void;
  upload: () => void;
}

const ImageViewArea: React.FC<IImageView> = ({ images, RemoveImage, upload }) => {

  const GetImgFileSrc = (image: any, index: number) => {
    try {
      return URL.createObjectURL(image);
    } catch (error) {
      RemoveImage(index);
    }
  }

  return (
    <section className="big-image-area">
      <div className="image-drop-area-big">
        <img
          className="property__images"
          src={GetImgFileSrc(images[0], 0)}
          // src={images[0]}
          alt="1st img"
        />
        <div className="overlay"></div>
        <div className="remove-button" onClick={() => RemoveImage(0)}>
          <i className="bi bi-x-square"></i>
        </div>
      </div>
      <section className="small-image-area">
        <div
          className="image-drop-area-small"
          onClick={images.length > 1 ? () => { } : upload}>
          {images.length > 1 ? (
            <>
              <img
                className="property__images__small"
                src={GetImgFileSrc(images[1], 1)}
                // src={images[1]}
                alt="2nd img"
              />
              <div className="overlay"></div>
              <div className="remove-button" onClick={() => RemoveImage(1)}>
                <i className="bi bi-x-square"></i>
              </div>
            </>
          ) : (
            <div className="no_image-icon">
              <i className="bi bi-images"></i>
            </div>
          )}
        </div>
        <div
          className="image-drop-area-small"
          onClick={images.length > 2 ? () => { } : upload}>
          {images.length > 2 ? (
            <>
              <img
                className="property__images__small"
                src={GetImgFileSrc(images[2], 2)}
                // src={images[2]}
                alt="3rd img"
              />
              <div className="overlay"></div>
              <div className="remove-button" onClick={() => RemoveImage(2)}>
                <i className="bi bi-x-square"></i>
              </div>
            </>
          ) : (
            <div className="no_image-icon">
              <i className="bi bi-images"></i>
            </div>
          )}
        </div>
        <div
          className="image-drop-area-small"
          onClick={images.length > 3 ? () => { } : upload}>
          {images.length > 3 ? (
            <>
              <img
                className="property__images__small"
                src={GetImgFileSrc(images[3], 3)}
                // src={images[3]}
                alt="4th img"
              />
              <div className="overlay"></div>
              <div className="remove-button" onClick={() => RemoveImage(3)}>
                <i className="bi bi-x-square"></i>
              </div>
            </>
          ) : (
            <div className="no_image-icon">
              <i className="bi bi-images"></i>
            </div>
          )}
        </div>
        <div
          className="image-drop-area-small"
          onClick={images.length > 4 ? () => { } : upload}>
          {images.length > 4 ? (
            <>
              <img
                className="property__images__small"
                src={GetImgFileSrc(images[4], 4)}
                // src={images[4]}
                alt="5th img"
              />
              <div className="overlay"></div>
              <div className="remove-button" onClick={() => RemoveImage(4)}>
                <i className="bi bi-x-square"></i>
              </div>
            </>
          ) : (
            <div className="no_image-icon">
              <i className="bi bi-images"></i>
            </div>
          )}
        </div>
        <div
          className="image-drop-area-small"
          onClick={images.length > 5 ? () => { } : upload}>
          {images.length > 5 ? (
            <>
              <img
                className="property__images__small"
                src={GetImgFileSrc(images[5], 5)}
                // src={images[5]}
                alt="6th img"
              />
              <div className="overlay"></div>
              <div className="remove-button" onClick={() => RemoveImage(5)}>
                <i className="bi bi-x-square"></i>
              </div>
            </>
          ) : (
            <div className="no_image-icon">
              <i className="bi bi-images"></i>
            </div>
          )}
        </div>
        <div
          className="image-drop-area-small"
          onClick={images.length > 6 ? () => { } : upload}>
          {images.length > 6 ? (
            <>
              <img
                className="property__images__small"
                src={GetImgFileSrc(images[6], 6)}
                // src={images[6]}
                alt="7th img"
              />
              <div className="overlay"></div>
              <div className="remove-button" onClick={() => RemoveImage(6)}>
                <i className="bi bi-x-square"></i>
              </div>
            </>
          ) : (
            <div className="no_image-icon">
              <i className="bi bi-images"></i>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};

export default ImageViewArea;
