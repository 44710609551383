import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import Toast from "../../components/toaster/Toast";
import kernelApi from '../../API/kernelApi';
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { UpdateSidebar } from '../../UtilityFunctions';
import Button from "../../views/buttons/Button";
import { nanoid } from '@reduxjs/toolkit';
import Loader from '../../views/loader/Loader';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { CATCH_MESSAGE } from '../../UtilityFunctions';
import endPoints from '../../API/endPoints';


function LocaleInfo() {
    const navigate = useNavigate();

    UpdateSidebar("Property Setup", "Locale Info");

    // endPoints Imports
    const {
        getLocaleDetails
    } = endPoints.PROPERTY_LOCALE_INFO;

    const [typeSlab, setTypeSlab] = useState(false)

    const [getData, setGetData] = useState<any>(null)

    const [taxSlabArr, setTaxSlabArr] = useState([])

    const [dataFetchStatus, setDataFetchStatus] = useState<any>('unset')

    const [defaultMsg, setDefaultMsg] = useState({ msg: 'Unable to fetch locale information' })

    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );

    const fetchLocaleDetails = async () => {
        try {
            const Response = await kernelApi.get(`${getLocaleDetails}${current_property.hotel_id}`)
            if (Response.data.status === 1) {
                setDataFetchStatus(1)
                setGetData(Response.data.states)
                setDefaultMsg({ msg: Response.data.message })
            }
            else {
                setDataFetchStatus(0)
                setDefaultMsg({ msg: Response.data.message })
                Toast(Response.data.message, 'error')
            }
        }
        catch {
            setDataFetchStatus(-1)
            Toast(CATCH_MESSAGE, 'error')
        }
    }

    useEffect(() => { fetchLocaleDetails() }, [])

    useEffect(() => {
        if (!getData) return
        if (getData['tax_value'] && getData['tax_value'].length > 0) {
            setTaxSlabArr(getData['tax_value'])
            if (getData['tax_type'] === "slab") setTypeSlab(true)
        }
    }, [getData])

    const TAXSLAB = taxSlabArr.map((slab, ind) => {

        const { start_range, end_range, value } = slab

        return (
            <div className="taxSlabvalContainer" key={nanoid()}>
                <span className="tax-slab">
                    <p>Slab {ind + 1}</p>
                    <div className='tax-slab-info-container'>
                        <span className='tax-slab-info'>
                            <p>Start Price</p>
                            <div>{start_range}</div>
                        </span>
                        <span className='tax-slab-info'>
                            <p>End Price</p>
                            <div>{end_range}</div>
                        </span>
                        <span className='tax-slab-info'>
                            <p>Tax Percentage</p>
                            <div>{value}%</div>
                        </span>
                    </div>
                </span>
            </div>
        )
    })




    return (
        <>
            <div className="property__breadcrumbs">
                <Breadcrumbs />
                <div className="manage__property__action__right">
                    <Button
                        className="back__btn"
                        icon="bi bi-arrow-left"
                        handleClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                </div>
            </div>
            <div className="hr--line"></div>

            {
                dataFetchStatus === 'unset' ?
                    <div className="loadercontainer">
                        <Loader />
                    </div>
                    :
                    getData ?
                        <>

                            {/* ------------------------------ */}
                            <div className="propertySetupLocaleInfoWrapper">
                                <span className="country">
                                    <p>Country</p>
                                    <div>{getData ? getData['country_name'] : ''}</div>
                                </span>
                                <span className="currency">
                                    <p>Currency</p>
                                    <div>{getData ? getData['currency_code'] : ''}</div>
                                </span>
                                <span className="state">
                                    <p>State</p>
                                    <div>{getData ? getData['state_name'] : ''}</div>
                                </span>
                                <span className="timezone">
                                    <p>Timezone</p>
                                    <div>Central Day light time ({getData ? getData['time_zone'] : ''})</div>
                                </span>
                                <span className="taxtype">
                                    <p>Tax Type</p>
                                    <div>{getData ? getData['tax_type'] : ''}</div>
                                </span>
                                {
                                    typeSlab ? <></>
                                        :
                                        <span className="taxs-%">
                                            <p>Tax Percentage</p>
                                            <div>{getData['tax_value']['value']}%</div>
                                        </span>
                                }
                            </div>

                            {
                                typeSlab ?
                                    TAXSLAB
                                    : <></>
                            }
                        </>
                        :
                        <div className="loadercontainer">
                            <div style={{ color: "#a1a1a1" }}>{defaultMsg.msg}</div>
                        </div>
            }

            <div style={{ width: "100%", height: "20px" }}></div>
        </>
    )
}

// Alternate test case is reqd for tax type flat

export default LocaleInfo