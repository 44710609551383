import { USER_ACCESS_DATA, USER_ADMIN_ACCESS, USER_FULL_NAME_ACCESS } from "../actionTypes";

const URL = window.location.origin;

const initialState = {
  accessData: [],
  adminAcess: 0,
  chargebeeEnvironment: URL.includes("localhost")
    ? "LOCALHOST"
    : URL.includes("hotelkite")
      ? "HOTELKITEWEB"
      : "BOOKINGJINIWEB",
  userFullName: ""
};

const UserAcessReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_ACCESS_DATA:
      return {
        ...state,
        accessData: action.payload,
      };
    case USER_ADMIN_ACCESS:
      return {
        ...state,
        adminAcess: action.payload,
      };
    case USER_FULL_NAME_ACCESS:
      return {
        ...state,
        userFullName: action.payload.userFullName,
      };

    default:
      return state;
  }
};

export default UserAcessReducer;
