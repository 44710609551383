import {
  ADD_ROOM_TYPE_BASIC_DETAILS,
  ADD_ROOM_TYPE_EP_PRICE_UPDATE,
  ADD_ROOM_TYPE_IMAGES,
  ADD_ROOM_TYPE_MEAL_PLAN,
  ADD_ROOM_TYPE_OCCUPANCY,
  ADD_ROOM_TYPE_PRICE,
  ADD_ROOM_TYPE_RESET,
} from "../actionTypes";
import { getImage } from "../../UtilityFunctions";

const initialState = {
  basic_details: {
    room_type_name: null,
    number_of_rooms: null,
    room_type_shortcut: null,
  },
  occupancy: {
    max_occupancy: 0,
    max_adult: 0,
    max_child: 0,
    base_adult: 0,
    base_child: 0,
  },
  price: {
    max_room_price: null,
    min_room_price: null,
    // extra_adult_price: null,
    // extra_child_price: null,
  },
  meal_plan: {
    ep_price: null,
    cp_price: null,
    map_price: null,
    ap_price: null,
  },
  images: [],
  img_placeholder: getImage("default_image/no_img.png"),
};

const AddRoomTypeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_ROOM_TYPE_BASIC_DETAILS:
      return {
        ...state,
        basic_details: action.payload,
      };
    case ADD_ROOM_TYPE_OCCUPANCY:
      return {
        ...state,
        occupancy: action.payload,
      };
    case ADD_ROOM_TYPE_PRICE:
      return {
        ...state,
        price: action.payload,
      };
    case ADD_ROOM_TYPE_EP_PRICE_UPDATE:
      return {
        ...state,
        meal_plan: {
          ...state.meal_plan,
          ep_price: action.payload,
        },
      };
    case ADD_ROOM_TYPE_MEAL_PLAN:
      return {
        ...state,
        meal_plan: action.payload,
      };
    case ADD_ROOM_TYPE_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    case ADD_ROOM_TYPE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default AddRoomTypeReducer;
