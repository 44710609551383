import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import cmApi from "../../../API/cmApi";
import endPoints from "../../../API/endPoints";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { OTAManageAction } from "../../../redux/actions/ManageChannell";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import { RootState } from "../../../redux/store";
import {
  CATCH_MESSAGE,
  Logout,
  UpdateSidebar,
} from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import Card from "../../../views/cardwrapper/Card";
import Loader from "../../../views/loader/Loader";
import OtaBasicSetup from "./OtaBasicSetup";
import RateSync from "./RateSync/RateSync";
import RoomSync from "./RoomSync/RoomSync";

const OtaManage = () => {
  const { ota } = useSelector((state: RootState) => state.manage_channels);

  const { allChannels } = useSelector(
    (state: RootState) => state.manage_channels
  );

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  //Endpoints Import
  const { specificOTAdetailsApi, cmUpdateOTAdetailsToggle } =
    endPoints.MANAGECHANNEL;
  // --------------------------------

  UpdateSidebar("Manage Channels", `${ota.ota_name}`);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [syncStatus, setSyncStatus] = useState<number>();
  const [currentOta, setCurrentOta] = useState<any>();
  const [active, setActive] = useState<boolean>(false);
  const [tabTarget, setTabTarget] = useState<any>("basic-details");
  const OtaMangeTabs = [
    {
      id: "basic-details-tabs",
      target: "basic-details",
      label: "Basic Details",
    },
    {
      id: "room-mapping-tab",
      target: "room-mapping",
      label: "Room Mapping",
    },
    {
      id: "rate-mapping-tab",
      target: "rate-mapping",
      label: "Rate Mapping",
    },
  ];

  useEffect(() => {
    const otaData = allChannels?.filter((itm) => {
      return itm?.ota_id === ota?.ota_id;
    });
    setSyncStatus(otaData[0]?.sync_status);
  }, [allChannels]);

  useEffect(() => {
    if (ota?.ota_id) {
      getAllDetailsofSpecificOta();
    }
  }, [current_property]);

  // Get All Details of Specific Ota
  const getAllDetailsofSpecificOta = async () => {
    setLoader(true);

    // changes
    setActive(false);
    // changes

    try {
      let specificOtaRes = await cmApi.get(
        // `/cm_ota_hotel_details/${current_property.hotel_id}/${ota?.ota_id}`
        `${specificOTAdetailsApi}/${current_property.hotel_id}/${ota?.ota_id}`
      );

      if (specificOtaRes.data.status === 1) {
        setCurrentOta(specificOtaRes.data.data[0]);
        // changes
        if (specificOtaRes.data.data[0].is_connected === 1) {
          setActive(true);
        }
        // changes

        dispatch(OTAManageAction(specificOtaRes.data.data[0]));
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Active and  Inactive Channellist
  const activeInactiveChannel = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: any
  ) => {
    let activeInactiveStatus = e.target.checked;

    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to ${active ? "connect" : "disconnect"} this ?`,
        "DELETE",
        async () => {
          setActive(!active);
          try {
            let activeInactiveRes = await cmApi.get(
              `${cmUpdateOTAdetailsToggle}/${current_property.hotel_id
              }/${item}/${activeInactiveStatus ? 1 : 0}`,
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );

            if (activeInactiveRes.data.status === 1) {
              toast.success(`${activeInactiveRes.data.message}`);
              getAllDetailsofSpecificOta();
            } else {
              toast.error(`${activeInactiveRes.data.message}`);
              getAllDetailsofSpecificOta();
            }
          } catch (error: any) {
            setLoader(false);
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
              Logout();
            }
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };

  return loader ? (
    <div className="loader-setup">
      <Loader />
    </div>
  ) : (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <div className="active-inactive-ota">
            <div className="form-check form-switch custom-switch check--box--width">
              {/* <div
                className={`connection-status ${
                  currentOta
                    ? currentOta?.is_connected === 1
                      ? "connected"
                      : "disconnected"
                    : "connected"
                }`}
              > */}

              {/* changes */}
              <div
                className={`connection-status ${currentOta ? currentOta.is_connected === 1 ? "connected" : "disconnected" : "connected"
                  }`}
              >
                {/* changes */}


                <div className="connection-circle">
                  <i className="bi bi-circle-fill"></i>
                </div>

                {/* {currentOta?.is_connected === 1 ? (
                  <div>Connected</div>
                ) : (
                  <div>Disconnected</div>
                )} */}
                {/* changes */}
                {currentOta ? currentOta.is_connected === 1 ? (
                  <div>Connected</div>
                ) : (
                  <div>Disconnected</div>
                ) : (
                  <div>Connected</div>
                )}
                {/* changes */}

              </div>
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                onChange={(e) => {
                  activeInactiveChannel(e, ota.ota_id);
                }}
                // checked={currentOta?.is_connected === 1}
                // changes
                checked={currentOta ? currentOta.is_connected === 1 : true}
              // changes
              />
            </div>
          </div>

          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {/* <div className="manage__property__setup__left__panel">
        <div className="property-setup-wrapper">
          <div className="property__manage__row">
            <Link to="ota-basic-setup">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-brush property--setup--icon--style"></i>
                    </div>
                    <div className="property--setup--notification">1</div>
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Basic Setup
                  </div>
                </div>
              </div>
            </Link>

            <Link to="room-sync">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-x-diamond property--setup--icon--style"></i>
                    </div>
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Room Mapping
                  </div>
                </div>
              </div>
            </Link>

            <Link to="rate-sync">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-x-diamond property--setup--icon--style"></i>
                    </div>
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Rate Mapping
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div> */}

      {/* Tab Style */}

      <Card className="ota-manage-card">
        {(ota?.is_connected === 0 && ota.is_added === 0) ||
          (ota?.is_connected === 1 && ota.is_added === 1) ? (
          <div className="edit__room__type__nav__tabs custom-margin-padding">
            <div className="edit__room__type__nav__wrapper">
              <ul
                className="nav nav-tabs mb-3"
                id="editRoomType"
                role="tablist"
              >
                {OtaMangeTabs.map(
                  (tab: typeof OtaMangeTabs[0], index: number) => {
                    return (
                      <li className="nav-item" role="presentation" key={index}>
                        <button
                          id={tab.id}
                          data-bs-toggle="tab"
                          data-bs-target={`#${tab.target}`}
                          type="button"
                          role="tab"
                          aria-controls={tab.target}
                          aria-selected="true"
                          className={`nav-link tab--link--text ${index === 0 ? "active" : ""
                            }`}
                          onClick={() => {
                            setTabTarget(tab.target);
                          }}
                        >
                          {tab.label}
                        </button>
                      </li>
                    );
                  }
                )}
              </ul>
              <div className="tab-content" id="editRoomTypeContent">
                {/* Tab section for basic-details */}
                <div
                  className="tab-pane fade show active"
                  id="basic-details"
                  role="tabpanel"
                  aria-labelledby="basic-details-tabs"
                >
                  {tabTarget === "basic-details" && <OtaBasicSetup />}
                </div>

                {/* Tab section for room-mapping */}
                <div
                  id="room-mapping"
                  role="tabpanel"
                  className="tab-pane fade"
                  aria-labelledby="room-mapping-tab"
                >
                  {tabTarget === "room-mapping" && <RoomSync />}
                </div>

                {/* Tab section for room type rate-mapping */}
                <div
                  className="tab-pane fade"
                  id="rate-mapping"
                  role="tabpanel"
                  aria-labelledby="rate-mapping-tab"
                >
                  {tabTarget === "rate-mapping" && <RateSync />}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="not_connected_message">
            {currentOta?.disconnected_message}
          </div>
        )}
      </Card>
    </>
  );
};

export default OtaManage;
