import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import airBnb from "../../../API/airBnb";
import cmApi from "../../../API/cmApi";
import endPoints from "../../../API/endPoints";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import CustomBreadcrumbs from "../../../components/breadcrumbs/CustomBreadcrumbs";
import { OTAManageAction } from "../../../redux/actions/ManageChannell";
import { RootState } from "../../../redux/store";
import {
  CATCH_MESSAGE,
  getImage,
  Logout,
  UpdateSidebar,
} from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import FetchButton from "../../../views/buttons/FetchButton";
import { CreateRipple } from "../../../views/buttons/NextButton";
import Card from "../../../views/cardwrapper/Card";
import InputSelectField from "../../../views/inputtextfield/InputSelectField";
import InputTextField from "../../../views/inputtextfield/InputTextField";
import Loader from "../../../views/loader/Loader";

const OtaBasicSetup = () => {
  const { ota } = useSelector((state: RootState) => state.manage_channels);


  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  UpdateSidebar("Manage Channels", `${ota.ota_name}`);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [syncBtnloader, setSyncBtnLoader] = useState<boolean>(false);
  const [savebtnloader, setSaveBtnLoader] = useState<boolean>(false);
  const [hotelCode, setHotelCode] = useState<any>("");
  const [authParameter, setAuthParameter] = useState<any>("");
  const [syncBtnEnabled, setSyncBtnEnabled] = useState<boolean>(true);
  const [ota_Id, setOtaId] = useState<any>(ota?.ota_id ?? "");
  const [lastSyncDays, setLastSyncDays] = useState<any>();
  const [totalMisMatchFound, setTotalMisMatchFound] = useState<any>();
  const [misMatchRateSyncs, setMisMatchRateSyncs] = useState<any>();
  const [misMatchRoomSyncs, setMisMatchRoomSyncs] = useState<any>();
  const [syncMisMatchShow, setSyncMisMatchShow] = useState<boolean>(false);
  const [syncAgainMessage, setSyncAgainMessage] = useState<string>("");
  const [currentOta, setCurrentOta] = useState<any>();
  const [fetchedAuthParameter, setFetchedAuthParameter] = useState("");
  const [scope, setScope] = useState<string>("property_management");

  //Endpoints Import
  const {
    specificOTAdetailsApi,
    syncHistoryDataApi,
    addOTAdetails,
    setSync,
    otaDetailsSync,
    updateOTAdetails,
    cmUpdateOTAdetails,
  } = endPoints.MANAGECHANNEL;
  // --------------------------------

  useEffect(() => {
    if (ota.ota_id) {
      setOtaId(ota.ota_id);
      getSyncHistoryData();
      getAllDetailsofSpecificOta();
    }
  }, [ota]);

  // const commissionChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setCommission(e.target.value);
  //   if (Number(e.target.value) > 100) {
  //     setCommissionHelperText("Value cannot be greater than 100");
  //   } else {
  //     setCommissionHelperText("");
  //     setCommission(e.target.value);
  //   }
  // };

  // Get All Details of Specific Ota

  const getAllDetailsofSpecificOta = async () => {
    try {
      let specificOtaRes = await cmApi.get(
        `${specificOTAdetailsApi}/${current_property?.hotel_id}/${ota?.ota_id}`
      );

      if (specificOtaRes.data.status === 1) {
        const parameter = specificOtaRes.data.data[0].auth_parameter;
        const paramsSplitted = parameter.split(`"`);
        setFetchedAuthParameter(paramsSplitted[3]);

        setCurrentOta(specificOtaRes.data.data[0]);
        setHotelCode(specificOtaRes.data.data[0].ota_hotel_code);
        setOtaId(specificOtaRes.data.data[0].ota_id);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  //Get Sync History
  const getSyncHistoryData = async () => {
    try {
      let SyncRespone = await cmApi.get(
        `${syncHistoryDataApi}/${current_property?.hotel_id}/${ota?.ota_id}`
      );

      setSyncAgainMessage(SyncRespone.data.fetch_again_message);
      if (SyncRespone.data.ota_room_data_last_sync.status === 1) {
        setLastSyncDays(SyncRespone.data.ota_room_data_last_sync.last_sync);
      } else {
        setLastSyncDays(SyncRespone.data.ota_room_data_last_sync.message);
      }
      if (SyncRespone.data.hasOwnProperty("total_count")) {
        setTotalMisMatchFound(SyncRespone.data.total_count);
      } else {
        setTotalMisMatchFound(0);
      }
      if (SyncRespone.data.rate_log.status === 1) {
        setMisMatchRateSyncs(SyncRespone.data.rate_log);
      } else {
        setMisMatchRateSyncs(0);
      }
      if (SyncRespone.data.room_log.status === 1) {
        setMisMatchRoomSyncs(SyncRespone.data.room_log);
      } else {
        setMisMatchRoomSyncs(0);
      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
    }
  };

  const UpdateHotelCodeandCommission = async () => {
    setSaveBtnLoader(true);
    setSyncBtnEnabled(false);
    try {
      const updateresponse = await cmApi.post(
        `${ota.ota_name.toLowerCase() === "airbnb"
          ? `${updateOTAdetails}/${ota?.ota_id}`
          : `${cmUpdateOTAdetails}/${ota?.ota_id}`
        }`,
        {
          ota_hotel_code: hotelCode,
          commision: " ",
          ota_name: ota?.ota_name,
          auth_parameter:
            ota?.ota_name === "Goibibo" ? authParameter : "noAuth",
          hotel_id: current_property?.hotel_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (updateresponse.data.status === 1) {
        toast.success(updateresponse.data.message);
        setOtaId(updateresponse.data.ota_id);
        setHotelCode(updateresponse.data.ota_hotel_code);
        getAllDetailsofSpecificOta();
        setSaveBtnLoader(false);
        setSyncBtnEnabled(true);
      } else {
        toast.error(updateresponse.data.message);
        getAllDetailsofSpecificOta();
        setSaveBtnLoader(false);
        // setSyncBtnEnabled(true);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setSaveBtnLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Add Ota Hotel Code Details
  const AddHotelCodeandCommission = async () => {
    setSaveBtnLoader(true);
    try {
      const addresponse = await cmApi.post(
        addOTAdetails,
        {
          ota_hotel_code: hotelCode,
          commision: " ",
          ota_name: ota?.ota_name,
          auth_parameter: ota?.ota_name === "Goibibo" ? authParameter : "noAuth",
          hotel_id: current_property?.hotel_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (addresponse.data.status === 1) {
        toast.success(addresponse.data.message);
        setHotelCode(addresponse.data.ota_hotel_code);
        setOtaId(addresponse.data.ota_id);
        setSaveBtnLoader(false);
        setSyncBtnEnabled(true);
        // getAllDetailsofSpecificOta();
      } else {
        toast.error(addresponse.data.message);
        setSaveBtnLoader(false);
        setSyncBtnEnabled(false);
      }
    } catch (error: any) {
      setSaveBtnLoader(false);
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // OTA Sync Handling
  const syncHandler = async () => {
    setSyncBtnLoader(true);
    if (ota.ota_name.toLowerCase() === "airbnb") {
      try {
        const syncresponse = await cmApi.get(
          `${setSync}/${current_property?.hotel_id}`,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (syncresponse.data.status === 1) {
          toast.success(syncresponse.data.message);
          getSyncHistoryData();
          setSyncBtnLoader(false);
        } else {
          toast.error(syncresponse.data.message);
          setSyncBtnLoader(false);
        }
      } catch (err) {
        toast.error(CATCH_MESSAGE);
        setSyncBtnLoader(false);
      }
    } else {
      setSyncBtnLoader(true);
      try {
        const syncresponse = await cmApi.get(
          `${otaDetailsSync}/${ota?.ota_id}`,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (syncresponse.data.status === 1) {
          toast.success(syncresponse.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          getSyncHistoryData();
          setSyncBtnLoader(false);
        } else {
          toast(syncresponse.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          setSyncBtnLoader(false);
        }
      } catch (err) {
        toast.error(CATCH_MESSAGE);
        setSyncBtnLoader(false);
      }
    }
  };

  const saveHandler = () => {
    if (hotelCode) {
      if (ota.hasOwnProperty("ota_id")) {
        UpdateHotelCodeandCommission();
      } else {
        AddHotelCodeandCommission();
      }
    } else {
      toast.error("Please Fill All The Details");
    }
  };

  return (
    <>
      {/* <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            { label: ota.ota_name, href: "/manage-channels/ota-manage" },
            { label: "Basic Setup" },
          ]}
        />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div> */}
      {/* {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        
      )} */}

      <>
        <div className="d-flex ota-basic-setup">
          <div className="ota-basic-setup-child">
            {ota.ota_name.toLowerCase() === "airbnb" ? (
              <div></div>
            ) : (
              <Card>
                <div>
                  <InputTextField
                    label={"OTA Hotel Code"}
                    value={hotelCode}
                    handleChange={(e) => {
                      setHotelCode(e.target.value);
                    }}
                  />
                  {/* <InputTextField
                        label={"Commission"}
                        type="number"
                        value={commission}
                        handleChange={(e) => {
                          commissionChangeHandler(e);
                        }}
                        helperText={commissionHelperText}
                      /> */}
                  {ota.ota_name === "Goibibo" && (
                    <div style={{ marginTop: "15px" }}>
                      <InputTextField
                        label={"Auth Parameter"}
                        value={
                          authParameter
                            ? authParameter
                            : fetchedAuthParameter
                              ? fetchedAuthParameter
                              : ""
                        }
                        handleChange={(e) => {
                          setFetchedAuthParameter("");
                          setAuthParameter(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Card>
            )}

            <div className="ota-bsic-setup-btn-wrapper">
              {ota.ota_name.toLowerCase() === "airbnb" ? (
                <div className="ota-bsic-setup-airbnb-authorize">
                  <InputSelectField
                    label={"Scope"}
                    value={scope}
                    values={["property_management", "operations_management"]}
                    options={["Everything", "Operations"]}
                    handleChange={(e) => {
                      setScope(e.target.value);
                    }}
                  />
                  <div
                    className="authorize-btn"
                  // onClick={() => navigate("/manage-channels")}
                  >
                    <a
                      href={airBnb(scope)}
                      target="_blank"
                      className="airbnb-link"
                    >
                      Authorize
                    </a>
                  </div>
                </div>
              ) : (
                <div className="save-btn">
                  <Button
                    handleClick={() => saveHandler()}
                    loader={savebtnloader}
                  >
                    Save
                  </Button>
                </div>
              )}

              {ota_Id && (
                <div className="ota-bsic-setup-sync-btn">
                  {/* <div style={{ width: "20%" }}> */}
                  <FetchButton
                    className="fetch__btn"
                    icon="bi bi-arrow-repeat"
                    handleClick={() => {
                      syncHandler();
                    }}
                    loader={syncBtnloader}
                  >
                    Fetch
                  </FetchButton>
                  {/* </div> */}
                  <div className="sync-btn-side-message-wrapper">
                    <div className="sync-again-message">{syncAgainMessage}</div>
                    <div className="last-sync-message">{lastSyncDays}</div>
                  </div>
                </div>
              )}
            </div>

            {ota_Id && (
              <div className="sync-mismatch-checking-section">
                <Card>
                  <div className="sync-mismatch-btn-wrapper">
                    <div>
                      <Button
                        handleClick={() => {
                          getSyncHistoryData();
                          setSyncMisMatchShow(true);
                        }}
                      >
                        Check For Mismatch
                      </Button>
                    </div>

                    {syncMisMatchShow && (
                      <div className="sync-mismatch-total">
                        {totalMisMatchFound}
                      </div>
                    )}
                  </div>
                  {syncMisMatchShow && misMatchRoomSyncs ? (
                    <>
                      <div className="sync-mismatch-labels">
                        Unmapped OTA Room Type {`(${misMatchRoomSyncs.count})`}
                      </div>
                      <div className="sync-mismatch-room-name-wrapper">
                        {Array.isArray(misMatchRoomSyncs.data) &&
                          misMatchRoomSyncs?.data?.map(
                            (iteam: any, idx: any) => {
                              return (
                                <div
                                  className="sync-mismatch-name-small-card"
                                  key={idx}
                                >
                                  {`${iteam.ota_room_type_name} (${iteam.ota_room_type_id})`}
                                </div>
                              );
                            }
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {syncMisMatchShow && misMatchRateSyncs ? (
                    <>
                      <div className="sync-mismatch-labels">
                        Unmapped OTA Rate Plan {`(${misMatchRateSyncs.count})`}
                      </div>
                      <div className="sync-mismatch-room-name-wrapper">
                        {Array.isArray(misMatchRateSyncs.data) &&
                          misMatchRateSyncs?.data?.map(
                            (iteam: any, idx: any) => {
                              return (
                                <div
                                  className="sync-mismatch-name-small-card"
                                  key={idx}
                                >
                                  {`${iteam.ota_room_type_name}:-${iteam.ota_rate_type_name}(${iteam.ota_rate_type_id})`}
                                </div>
                              );
                            }
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Card>
              </div>
            )}
          </div>
          <div className="ota-basic-setup-child">
            <Card className="mb-5">
              <div className="imp-note-label">Important Notes</div>
              <div className="imp-note-text">
                {ota?.integration_manual_url ? (
                  <iframe
                    src={ota?.integration_manual_url}
                    width="100%"
                    height="500"
                    frameBorder="0"
                    aria-readonly
                  ></iframe>
                ) : (
                  <div className="empty__room__div mt-5">
                    <i className="bi bi-x-circle mb-3"></i>
                    <div>No Document Found</div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </>
    </>
  );
};

export default OtaBasicSetup;
