import React from "react";

export interface IImageUpload {
  upload: () => void;
}

const ImageUploadArea: React.FC<IImageUpload> = ({ upload }) => {
  return (
    <>
      <div className="image_drag_area" onClick={upload}>
        <i className="bi bi-images"></i>
        <div className="text-area">Drag your photos or click here</div>
      </div>
    </>
  );
};

export default ImageUploadArea;
