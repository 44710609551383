import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Button from "../../views/buttons/Button";
import { Logout } from "../../UtilityFunctions";

const CurrentPropertyLabel = () => {
  const navigate = useNavigate();

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  useEffect(() => {
    if (!current_property) {
      navigate("/select-property");
    }
  }, []);

  return (
    <div className="right__log__out__wrap">
      <div className="property__name__text__wrapper">
        <i className="bi bi-building hotel__icon me-1"></i>
        {current_property
          ? current_property.hotel_name + " (" + current_property.hotel_id + ")"
          : navigate("/select-property")}
      </div>
      <Button
        className="logout__btn"
        handleClick={() => {
          Logout();
        }}
      >
        Logout
      </Button>
    </div>
  );
};

export default CurrentPropertyLabel;
