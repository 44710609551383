import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// import select feild
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../views/inputtextfield/InputMultiSelectField";
import InputPasswordField from "../../views/inputtextfield/InputPasswordField";
import {
  CATCH_MESSAGE,
  validateEmail,
  validatePassword,
} from "../../UtilityFunctions";

import InventoryBtn from "../../views/buttons/Button";

import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";
import endPoints from "../../API/endPoints";

interface IEdituser {
  //  onClose: () => void;
  user_id: any;
  selectedHotels: any;
  onClose: () => void;
  getAllUsers: () => void;
}
const EditUsers: React.FC<IEdituser> = ({
  user_id,
  selectedHotels,
  onClose,
  getAllUsers,
}) => {
  const { company_id, admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const [hotelAndIds, setHotelAndIds] = useState<any>([]);
  const [allFetchedHotels, setAllFetchedHotels] = useState<any>([]);
  const [selectedHotel, setSelectedHotel] = useState<any[]>([]);
  const [selectedUserRole, setSelectedUserRole] = useState<any>("");
  const [firstName, setFirstName] = useState<any>();
  const [emailAddress, setEmailAddress] = useState<any>("");
  const [userPassword, setUserPassword] = useState<any>("");
  const [passwordHelperText, setpasswordHelperText] = useState<any>();
  const [emailHelperText, setEmailHelperText] = useState<any>();

  const { manageUser, getHotelList, manageUserAdd } = endPoints.MANAGEUSER;

  let hotels = ["heritance", "syeman"];
  const editUserData = async () => {
    try {
      let response = await kernelApi.get(`${manageUser}/${user_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      let userData = response.data.data;
      let fullName = userData.first_name + " " + userData.last_name;
      setFirstName(fullName);
      setEmailAddress(userData.username);
      if (userData.role_id === 2) {
        setSelectedUserRole("Hotel Manager");
      }
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const getHotels = async () => {
    try {
      let all_hotels: string[] = [];
      let all_hotel_ids: any[] = [];
      let response = await kernelApi.get(
        `${getHotelList}/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      response?.data?.data?.map((hotel: any) => {
        all_hotels.push(hotel.hotel_name);
        all_hotel_ids.push(hotel.hotel_id);
      });
      let data = response.data.data;
      setAllFetchedHotels(response.data.data);
      setHotelAndIds({
        all_hotels: all_hotels,
        hotel_ids: all_hotel_ids,
      });
      let arr: any[] = [];
      response?.data?.data?.forEach((source: any) => {
        if (selectedHotels.includes(source.hotel_id)) {
          arr.push(source.hotel_name);
        }
      });

      setSelectedHotel(arr);
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    editUserData();
    getHotels();
  }, []);

  // selected hotels values
  const isAllSelected =
    hotelAndIds?.all_hotels?.length > 0 &&
    selectedHotel?.length === hotelAndIds?.all_hotels?.length;

  const handleSelectedHotel = (e: any) => {
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedHotel(
        selectedHotel.length === hotelAndIds.all_hotels.length
          ? []
          : hotelAndIds.all_hotels
      );
      return;
    }
    setSelectedHotel(value);
  };

  const handleUserRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    let role = e.target.value;
    setSelectedUserRole(role);
  };

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    let first = e.target.value;
    setFirstName(first);
  };
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email_address = e.target.value;
    if (validateEmail(email_address)) {
      setEmailHelperText("");
    } else {
      setEmailHelperText("Please Enter a valid Email ");
    }
    setEmailAddress(email_address);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    let user_password = e.target.value;
    if (validatePassword(e.target.value)) {
      setpasswordHelperText("");
    } else {
      setpasswordHelperText(
        "Password Should Contain one Upper Case and one Special Character"
      );
    }
    setUserPassword(user_password);
  };

  const editUserDetails = async () => {
    onClose();
    let arr: any[] = [];
    allFetchedHotels.forEach((hotel: any) => {
      if (selectedHotel.includes(hotel.hotel_name)) {
        arr.push(hotel.hotel_id);
      }
    });
    let response = await kernelApi.post(
      `${manageUserAdd}/${user_id}`,
      {
        company_id: company_id,
        full_name: firstName,
        username: emailAddress,
        password: userPassword,
        role_id: "2",
        hotel_id: arr,
      },
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );
    getAllUsers();
    if (response.data.status === 1) {
      toast(response.data.message, {
        hideProgressBar: true,
        type: "success",
      });
    } else {
      toast(response.data.message, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };
  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit User</div>
      </div>

      <div className="height-64px"></div>
      <div className="inventory__update__wrapper my-4 pt-2">
        <div className="inventory__choose__channel__source__select mb-3">
          <InputSelectField
            label="Select User Type"
            value={selectedUserRole}
            options={["Hotel Manager"]}
            handleChange={handleUserRole}
          />
        </div>
        <div className="inventory__number__wrapper mt-1 pt-3 mb-2">
          <div className="__default--input--field">
            <InputTextField
              label="Name"
              value={firstName}
              //   value={syncDuration}
              handleChange={handleFirstName}
            />
          </div>
        </div>
        <div className="inventory__number__wrapper mb-2">
          <div className="__default--input--field">
            <InputTextField
              label="Email Address"
              value={emailAddress}
              handleChange={handleEmail}
              helperText={emailHelperText}
            />
          </div>
        </div>
        <div className="inventory__number__wrapper mb-2">
          <div className="__default--input--field">
            <InputPasswordField
              label="Password"
              value={userPassword}
              helperText={passwordHelperText}
              handleChange={handlePassword}
            />
          </div>
        </div>
        {/* <div className="inventory__choose__channel__source__select"> */}
        <div className="status__dropdown__wrapper dropdown-manage-users me-3 custom__width mb-2">
          {/* <InputMultiSelectField
            label=""
            value={hotels}
            // handleChange={(evnt) => sourceOnchange(evnt)}
            options={hotels}
            isAllSelected={hotels}
          /> */}
          {hotelAndIds && hotelAndIds?.all_hotels?.length > 0 ? (
            <InputMultiSelectField
              label="select Hotels"
              value={selectedHotel}
              handleChange={(evnt) => handleSelectedHotel(evnt)}
              options={hotelAndIds.all_hotels}
              isAllSelected={isAllSelected}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {/* </div> */}

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-1">
          <InventoryBtn handleClick={editUserDetails}>Save</InventoryBtn>
        </div>
      </div>
    </>
  );
};

export default EditUsers;
