import React, { useEffect, useState } from "react";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import {
  CATCH_MESSAGE,
  UpdateSidebar,
  validatePositiveInt,
} from "../../UtilityFunctions";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";

import Ripples from "react-ripples";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

// import button
import Button from "../../views/buttons/Button";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useLocation, useNavigate } from "react-router";
import CheckinGuestDetails from "./CheckinGuestDetails";
import {
  SaveBillingDetails,
  SaveBookingRoomDetails,
} from "../../redux/actions/BookingsAction";
import { toast } from "react-toastify";
import gemsApi from "../../API/gemsApi";
import cmApi from "../../API/cmApi";
import endPoints from "../../API/endPoints";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";

interface IStateProp {
  allocated_rooms: string[];
}

const CheckIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { allocated_rooms } = (location?.state as IStateProp) ?? [];

  UpdateSidebar("Bookings", "Check-in");

  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { current_booking, booking_info, billing_details, room_details } =
    useSelector((state: RootState) => state.bookings);

  const CheckinSteps = ["Details", "Billing", "Guest Info"];
  const CollectionTypes = ["Collect Now", "Add to Room"];

  const [currentStep, setCurrentStep] = useState(0);

  const {
    billingDetailsByIdGet,
    roomDetailsByBookingIdGet,
    fontOfficeCheckInApi,
    bookingAllocateRoom,
  } = endPoints.BOOKINGS ?? {};

  const [openGuestDetailsSlider, setopenGuestDetailsSlider] = useState(false);
  const [openSelectRoomSlider, setopenSelectRoomSlider] = useState(false);
  const [checkInBtnLoader, setCheckinBtnLoader] = useState<boolean>(false);

  const [comingFrom, setComingFrom] = useState(booking_info?.coming_from);
  const [goingTo, setGoingTo] = useState(booking_info?.going_to);
  const [visitPurpose, setVisitPurpose] = useState(
    booking_info?.selected_visit_purpose
  );

  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState<string>("");
  const [currentRoomInfo, setCurrentRoomInfo] = useState<any>();

  const [collectType, setCollectType] = useState(1);
  const [amount, setAmount] = useState("");
  const [payMode, setPayMode] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [isGuestSliderClicked, setIsSliderClicked] = useState(0);

  const [guestDetails, setGuestDetails] = useState<any[]>([]);
  const [roomTypeWiseData, setRoomTypeWiseData] = useState<any[]>([]);

  useEffect(() => {
    if (current_booking) {
      assignRoomTypes();
      getBillingDetailsById(current_booking.booking_details.booking_id);
      getRoomDetailsByBookingId(current_booking.booking_details.booking_id);
    } else {
      // navigate("/bookings");
    }
  }, []);

  useEffect(() => {
    let current_room =
      room_details?.room_details.filter(
        (room: any) => room.room_type_id.toString() === selectedRoomTypeId
      )[0] || null;
    setCurrentRoomInfo(current_room);
  }, [selectedRoomTypeId, currentStep]);

  useEffect(() => {
    if (room_details) {
      let room_type_data: any[] = [];

      room_details?.room_details.map((room: any) => {
        room_type_data.push({
          plan: room.plan,
          seq_no: room.seq_no,
          room_rate: room.room_rate,
          assigned_room_no: room.room_no,
          assigned_room_id: room.room_id,
          room_type_id: room.room_type_id,
          room_type_name: room.room_type_name,
          available_rooms: room.available_rooms,
        });
      });

      setRoomTypeWiseData(room_type_data);

      let guest_details: any = {};

      room_details?.room_details.map((room: any) => {
        let guests: any[] = [];
        guests.push({
          name: room?.name || "",
          mobile: room?.mobile || "",
          address: "",
          email: "",
          validMail: true,
          validMobile: true,
          doc: room?.doc || "",
          photo: room?.photo || "",
          age: room?.age || "",
          gender: room?.gender || "",
        });

        room?.other_guests.forEach((gst: any) => {
          guests.push({
            age: gst.age || "",
            name: gst.name || "",
            gender: gst.gender || "",
            mobile: gst.mobile || "",
            address: "",
            email: "",
            validMail: true,
            validMobile: true,
            doc: gst.doc || "",
            photo: gst.new_photo_url || "",
          });
        });

        guest_details[room.seq_no] = guests;
      });

      setGuestDetails(guest_details);
    }
  }, [currentStep]);

  const assignRoomTypes = () => {
    let ids: string[] = [];
    let labels: string[] = [];
    current_booking.room_details.forEach((roomType: any) => {
      ids.push(roomType.room_type_id.toString());
      labels.push(roomType.room_type + " X " + roomType.no_of_rooms);
    });
    setSelectedRoomTypeId(ids[0]);
  };

  const getBillingDetailsById = async (id: string) => {
    try {
      const response = await gemsApi.post(`${billingDetailsByIdGet}`, {
        user_id: admin_id,
        booking_id: id,
        hotel_id: current_property.hotel_id,
      });

      if (response.data.status) {
        dispatch(SaveBillingDetails(response.data));
      }
    } catch (error) {
      toast("Billing details not found", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const getRoomDetailsByBookingId = async (id: string) => {
    try {
      const response = await gemsApi.post(`${roomDetailsByBookingIdGet}`, {
        user_id: admin_id,
        booking_id: id,
        hotel_id: current_property.hotel_id,
      });

      if (response.data.status === 1) {
        dispatch(SaveBookingRoomDetails(response.data));
      }
    } catch (error) {
      toast("Room details not found", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if ((validatePositiveInt(val) && val.length < 9) || val === "") {
      setAmount(val);
    }
  };

  const onReferenceNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if ((validatePositiveInt(val) && val.length < 25) || val === "") {
      setReferenceNumber(val);
    }
  };

  const onAddRoomSliderClick = (seqNo: number) => {
    let current_room =
      room_details?.room_details.filter(
        (room: any) => room.seq_no === seqNo
      )[0] || null;
    setCurrentRoomInfo(current_room);
    setopenSelectRoomSlider(true);
  };

  const onAddGuestSliderClick = (seqNo: number) => {
    let current_room =
      room_details?.room_details.filter(
        (room: any) => room.seq_no === seqNo
      )[0] || null;
    setCurrentRoomInfo(current_room);
    setopenGuestDetailsSlider(true);
  };

  const onSelectRoomClick = (room: any) => {
    let room_type_data = [...roomTypeWiseData];

    let index = room_type_data.findIndex(
      (room_type: any) => room_type.seq_no === currentRoomInfo.seq_no
    );
    room_type_data[index].assigned_room_no = room.room_no;
    room_type_data[index].assigned_room_id = room.room_id;

    setRoomTypeWiseData(room_type_data);
    setopenSelectRoomSlider(false);
  };

  const submitCheckIn = async () => {
    setCheckinBtnLoader(true);

    const pm_guests = Object.values(guestDetails);

    const hasGuestInfo = pm_guests.every(
      (guests: any) => guests[0].name && guests[0].mobile
      //  && guests[0].age && guests[0].email
    );
    const hasRoomInfo = roomTypeWiseData.every(
      (room: any) => room.assigned_room_id !== ""
    );

    if (hasGuestInfo && hasRoomInfo) {
      try {
        let rooms: any[] = [];

        roomTypeWiseData.forEach((rmType: any) => {
          let other_guests: any[] = [];

          guestDetails[rmType.seq_no]?.forEach((guest: any, index: number) => {
            if (index > 0) {
              other_guests.push({
                name: guest.name,
                age: guest.age,
                mobile: guest.mobile,
                doc: guest.doc.split(",")[1] || "",
                email: guest.email,
                gender: guest.gender,
              });
            }
          });

          const primaryGuest = guestDetails[rmType.seq_no][0];
          rooms.push({
            room_type_id: rmType.room_type_id,
            room_type_name: rmType.room_type_name,
            room_rate: rmType.room_rate,
            plan: rmType.plan,
            seq_no: rmType.seq_no,
            assigned_room_id: rmType.assigned_room_id,
            assigned_room_no: rmType.assigned_room_no,
            other_guests: other_guests,
            name: primaryGuest.name,
            age: primaryGuest.age,
            doc: primaryGuest.doc.split(",")[1] || "",
            email: primaryGuest.email,
            gender: primaryGuest.gender,
            mobile: primaryGuest.mobile,
            address: primaryGuest.address,
            photo: primaryGuest.photo.split(",")[1] || "",
          });
        });

        let payload = {
          booking_id: current_booking?.booking_details?.booking_id,
          hotel_id: current_property.hotel_id,
          user_id: admin_id,
          visit_purpose: visitPurpose,
          coming_from: comingFrom,
          going_to: goingTo,
          payment_option: collectType,
          paid_amount: amount,
          payment_mode: payMode,
          ref_number: referenceNumber,
          checkin_mode: "",
          rooms,
        };

        const response = await gemsApi.post(`${fontOfficeCheckInApi}`, payload);

        if (response.data.status === 1) {
          toast.success(response.data.message);
          setCheckinBtnLoader(false);

          submitCheckinCRS();
        } else {
          toast.error(response.data.message);
          setCheckinBtnLoader(false);
        }
      } catch (error) {
        setCheckinBtnLoader(false);
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Please enter the room and guest details");
    }
  };

  const submitCheckinCRS = async () => {
    try {
      let payload = {
        hotel_id: current_property.hotel_id,
        booking_id: current_booking.booking_details.booking_id,
        checkin_date: current_booking.booking_details.check_in,
        checkout_date: current_booking.booking_details.check_out,
        room_no: roomTypeWiseData.map((room: any) => room.assigned_room_no),
      };

      const response = await cmApi.post(`${bookingAllocateRoom}`, payload, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });

      if (response.data.status === 1) {
        navigate("/bookings/gems/frontoffice-view");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        {/* <Breadcrumbs /> */}
        <CustomBreadcrumbs
          content={[
            { label: "Bookings", href: "/bookings/gems/frontoffice-view" },
            { label: `Check-in` },
          ]}
        />
        <div className="manage__property__action__right">
          <div className="check__in__booking__id">
            ID : {current_booking?.booking_details?.booking_id}
          </div>
        </div>
      </div>

      <div className="hr--line"></div>

      <div className="bookings__guest__check__in__wrapper">
        <div className="guest__booking__details__stepper__left mb-5 sm-r">
          <div className="standard__card__wrapper">
            <div className="new__room__type__progress__stepper">
              {CheckinSteps.map((step, index) => {
                return (
                  <div
                    key={index}
                    className="label__progress__wrapper"
                    onClick={() => {
                      if (currentStep >= index) setCurrentStep(index);
                    }}
                  >
                    <div
                      className={`progress__status status--${
                        currentStep >= index ? "in--progress" : "pending"
                      }`}
                    >
                      {index + 1}
                    </div>
                    <div className="progress__status__label__text">{step}</div>
                  </div>
                );
              })}
            </div>

            {/* Details Content Tab */}
            {currentStep === 0 && (
              <div className="guest__details__tab__section">
                <div className="standard-page--label my-4">
                  Optional Details
                </div>
                <div className="comming__from__wrapper mb-3">
                  <InputTextField
                    label="Coming From"
                    value={comingFrom}
                    handleChange={(e) => setComingFrom(e.target.value)}
                  />
                </div>

                <div className="going__to__wrapper mb-2">
                  <InputTextField
                    label="Going to"
                    value={goingTo}
                    handleChange={(e) => setGoingTo(e.target.value)}
                  />
                </div>

                <div className="standard-page--label mt-0 mb-4">
                  Purpose of Visit
                </div>
                <div className="select__input__label">
                  {booking_info?.visit_purpose.map((purpose: any) => {
                    return (
                      <div
                        key={purpose.id}
                        onClick={() => setVisitPurpose(purpose.id)}
                        className={`me-4 mb-3 select__input__div${
                          purpose.id === visitPurpose ? "__active" : ""
                        }`}
                      >
                        {purpose.id === visitPurpose && (
                          <i className="bi bi-check-lg icon--check"></i>
                        )}
                        {purpose.value}
                      </div>
                    );
                  })}
                </div>

                <div className="check__in__next__btn my-3">
                  <Button
                    handleClick={() => {
                      setCurrentStep(currentStep + 1);
                    }}
                  >
                    Next <i className="bi bi-arrow-right"></i>
                  </Button>
                </div>
              </div>
            )}

            {/* Billing Content Tab */}
            {currentStep === 1 && (
              <div className="guest__billing__tab__sections">
                <div className="standard-page--label my-4">Room Details</div>

                {current_booking?.room_details?.map((room: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className="booking__room__detail__wrapper mb-4"
                    >
                      <div className="booking__room__image">
                        <img src={room.room_image} alt={room.room_type} />
                      </div>
                      <div className="booking__room__details">
                        <div className="room__type__meal__plan mb-3">
                          <div className="room__type__name">
                            <i className="bi bi-door-open me-2"></i>
                            {room.room_type}
                          </div>
                          {/* <div className="room__type__meal__plan">
                            <i className="bi bi-cup-straw"></i> {room.meal_plan}
                          </div> */}
                        </div>
                        <div className="room__type__meal__plan mb-3">
                          <div className="room__type__name">
                            <i className="bi bi-cup-straw me-2"></i>{" "}
                            {room.meal_plan}
                          </div>
                        </div>

                        <div className="booking__room__type__rate mb-3">
                          <i className="bi bi-cash-stack me-2"></i>{" "}
                          {room.room_rate}
                        </div>
                        <div className="booking__room__nights mb-3">
                          <i className="bi bi-question-circle me-2"></i> Rooms x{" "}
                          {room.no_of_rooms}
                        </div>
                        <div className="booking__room__type__total__amount">
                          Total - {room.room_rate * room.no_of_rooms}{" "}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="standard-page--label mb-3">Price Breakup</div>

                <div className="best__price__wrapper mb-3">
                  <div className="best__price__label">Base Price</div>
                  <div className="best__price">
                    {billing_details?.billing_details.base_amount ?? ""}
                  </div>
                </div>

                <div className="best__price__wrapper mb-3">
                  <div className="best__price__label">GST</div>
                  <div className="best__price">
                    {billing_details?.billing_details.tax_amount ?? ""}
                  </div>
                </div>

                <div className="hr--line"></div>

                <div className="final__price__breakup mb-3">
                  <div className="total__label">Total</div>
                  <div className="total__price">
                    {billing_details?.billing_details.total_receivable ?? ""}
                  </div>
                </div>

                <div className="final__price__breakup mb-3">
                  <div className="total__label">Advance</div>
                  <div className="total__price">
                    {billing_details?.billing_details.amount_paid ?? ""}
                  </div>
                </div>

                <div className="final__price__breakup mb-3">
                  <div className="total__label">Balance</div>
                  <div className="total__price">
                    {billing_details?.billing_details.balance ?? ""}
                  </div>
                </div>

                <div className="price__collection__wrapper mt-2 mb-4">
                  {CollectionTypes.map((type, index) => {
                    return (
                      <div key={index} className="form-check me-3">
                        <input
                          type="checkbox"
                          id={`collectNowCheck${index}`}
                          checked={collectType === index + 1}
                          onChange={() => setCollectType(index + 1)}
                          className="form-check-input me-2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`collectNowCheck${index}`}
                        >
                          {type}
                        </label>
                      </div>
                    );
                  })}
                </div>

                {collectType === 1 && (
                  <>
                    <div className="add__to__room__amount">
                      <InputTextField
                        label="Amount"
                        value={amount}
                        handleChange={onAmountChange}
                      />
                    </div>

                    <div className="standard-page--label mb-4">Select Mode</div>

                    <div className="select__input__label mb-4">
                      {billing_details?.payment_modes.map((mode: any) => {
                        return (
                          <div
                            key={mode.id}
                            onClick={() => setPayMode(mode.id)}
                            className={`me-4 mb-3 select__input__div${
                              payMode === mode.id ? "__active" : ""
                            }`}
                          >
                            {payMode === mode.id && (
                              <i className="bi bi-check-lg icon--check"></i>
                            )}
                            {mode.value}
                          </div>
                        );
                      })}
                    </div>

                    <div className="reference__number__wrapperr">
                      <InputTextField
                        value={referenceNumber}
                        label="Enter Reference Number"
                        handleChange={onReferenceNumChange}
                      />
                    </div>
                  </>
                )}

                <div className="check__in__next__btn my-3">
                  <Button
                    handleClick={() => {
                      if (
                        collectType === 2 ||
                        (amount && payMode && referenceNumber)
                      ) {
                        setCurrentStep(currentStep + 1);
                      } else {
                        toast("Please fill all the fields", {
                          hideProgressBar: true,
                          type: "error",
                        });
                      }
                    }}
                  >
                    Next <i className="bi bi-arrow-right"></i>
                  </Button>
                </div>
              </div>
            )}

            {/* Guest Info Tab */}
            {currentStep === 2 && (
              <div className="guest__info__tab__sections">
                <div className="room__number__wrapper my-4">
                  {roomTypeWiseData?.map((room: any, idx: number) => {
                    const guest1 = guestDetails[room.seq_no][0];
                    const isGuest =
                      guest1.name &&
                      // guest1.email &&
                      // guest1.age &&
                      guest1.mobile;
                    // &&
                    // guest1.validMail &&
                    // guest1.validMobile &&
                    // guest1.gender;
                    return (
                      <div
                        key={room.seq_no}
                        className={`room__wrapper mb-3 ${
                          idx % 2 !== 0 ? "" : "me-4"
                        }`}
                      >
                        <div className="room__number__meal__plan__wrapper mb-4">
                          <div className="room__number">
                            Room {room.seq_no} ({room.room_type_name})
                          </div>
                          {/* <div className="room__meal__plan">
                            <i className="bi bi-cup-straw me-1"></i>
                            {room.plan}
                          </div> */}
                        </div>
                        <div className="room__number__meal__plan__wrapper mb-4">
                          <div className="room__meal__plan">
                            <i className="bi bi-cup-straw me-1"></i>
                            {room.plan}
                          </div>
                        </div>

                        <div className="add__room__guest__wrapper">
                          <div
                            className="add__room me-3 assigned__room__guest"
                            onClick={() => onAddRoomSliderClick(room.seq_no)}
                          >
                            {!room.assigned_room_id ? (
                              <Button className="blue-button">
                                <i className="bi bi-plus"></i> <span>Room</span>
                              </Button>
                            ) : (
                              <Button>
                                <i className="bi bi-check"></i>{" "}
                                <span>{room.assigned_room_no}</span>
                              </Button>
                            )}
                          </div>
                          <div
                            className="add__guest assigned__room__guest"
                            onClick={() => onAddGuestSliderClick(room.seq_no)}
                          >
                            {isGuest && isGuestSliderClicked !== 0 ? (
                              <Button>
                                <i className="bi bi-check"></i>{" "}
                                <span>Guest</span>
                              </Button>
                            ) : (
                              <Button className="red-button">
                                <i className="bi bi-hourglass"></i>{" "}
                                <span>Guest</span>
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="check__in__next__btn mt-3">
                  {checkInBtnLoader ? (
                    <Button loader={checkInBtnLoader}>Processing</Button>
                  ) : (
                    <Button handleClick={submitCheckIn}>
                      Check-in <i className="bi bi-check"></i>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="guest__booking__info__stepper__right">
          <div className="guest__booking__info__card">
            <div className="guest__name__wrapper">
              <div className="guest__name">
                {current_booking?.booking_details?.guest_info}
              </div>
              <div className="booking__channel__icon">
                <img
                  className="booking__source__image"
                  src={current_booking?.booking_details?.channel_logo}
                  alt=""
                />
              </div>
            </div>

            <div className="guest__booking__date mt-2">
              Booking Date - {current_booking?.booking_details?.booking_date}
            </div>
            <div className="guest__email__contact__details mt-2">
              <div className="guest__number">
                <i className="bi bi-telephone"></i>{" "}
                {current_booking?.booking_details?.contact_number}
              </div>
              <div className="guest__email__id">
                <i className="bi bi-envelope"></i>{" "}
                {current_booking?.booking_details?.email}
              </div>
            </div>

            <div className="checkin__checkout__wrapper mt-3">
              <div className="checkinout__content">
                <div className="check__inout__details check-in-border">
                  <div className="check__inout__icon check-in-color">
                    <i className="bi bi-arrow-down-right-circle"></i>
                  </div>
                  <div className="check__inout__date">
                    <div className="check__inout check-in-color">
                      {current_booking?.check_in}
                    </div>
                    <div className="check__inout__label">Check-in</div>
                  </div>
                </div>
              </div>

              <div className="number__of__nights__wrapper">
                <div className="night__icon">
                  <i className="bi bi-moon-fill"></i>
                </div>
                <div className="night__number">
                  {current_booking?.no_of_nights}
                </div>
              </div>

              <div className="checkinout__content">
                <div className="check__inout__details check-out-border">
                  <div className="check__inout__icon check-out-color">
                    <i className="bi bi-arrow-up-left-circle"></i>
                  </div>
                  <div className="check__inout__date">
                    <div className="check__inout check-out-color">
                      {current_booking?.check_out}
                    </div>
                    <div className="check__inout__label">Check-out</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openGuestDetailsSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenGuestDetailsSlider(false)}
      >
        <CheckinGuestDetails
          roomSeqNo={currentRoomInfo?.seq_no}
          guestDetails={guestDetails}
          setGuestDetails={setGuestDetails}
          onClose={() => setopenGuestDetailsSlider(false)}
          isSliderSaveCliked={(val) => {
            if (val) {
              setIsSliderClicked(isGuestSliderClicked + 1);
            }
          }}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openSelectRoomSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenSelectRoomSlider(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setopenSelectRoomSlider(false)}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">Select Room</div>
          <div className="side__slider__sub__heading me-3">
            ID : {current_booking?.booking_details?.booking_id}
          </div>
        </div>
        <div className="height-64px"></div>

        <div className="select__roon__floor__wrapper">
          <div className="floor__wise__room__select">
            {/* <div className="floor__number mb-4">Floor 1</div> */}

            <div className="floor__number__wrapper mb-3">
              {currentRoomInfo?.available_rooms.map((room: any) => {
                if (room.room_id) {
                  return (
                    <div key={room.room_id} className="mb-3 me-4">
                      {allocated_rooms.includes(room.room_no) ||
                      roomTypeWiseData?.some(
                        (data: any) => data.assigned_room_no === room.room_no
                      ) ? (
                        <Ripples>
                          <div className="floor__number__box">
                            <div className="floor__number__gray">
                              {room.room_no}
                            </div>
                          </div>
                        </Ripples>
                      ) : (
                        <Ripples>
                          <div
                            className="floor__number__box"
                            onClick={() => onSelectRoomClick(room)}
                          >
                            <div className="floor__number">{room.room_no}</div>
                          </div>
                        </Ripples>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
      </SlidingPane>
    </>
  );
};

export default CheckIn;
