import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";
import startOfDay from "date-fns/startOfDay";
import { useNavigate } from "react-router";
import { OTAManageAction } from "../../redux/actions/ManageChannell";

// import select field
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../views/inputtextfield/InputMultiSelectField";
import InputPasswordField from "../../views/inputtextfield/InputPasswordField";
import InventoryBtn from "../../views/buttons/Button";

import CustomDateRangePicker from "../daterangepicker/DateRangePicker";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";
import { CATCH_MESSAGE } from "../../UtilityFunctions";

import Toast from "../toaster/Toast";

interface IEditSeasonalPlan {
  onClose: () => void;
  specificPlan: any;
}

const EditSeasonalPlan: React.FC<IEditSeasonalPlan> = ({
  onClose,
  specificPlan,
}) => {
  // Data From Redux
  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [planName, setPlanName] = useState<any>("");
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [allotas, setAllotas] = useState<any>([]);
  const [selectedOta, setSelectedOta] = useState<any>([]);
  const [saveLoader, setSaveLoader] = useState<any>(false);
  const [dateRangeButton, setDateRangeButton] = useState<any>(true);
  const [res, setRes] = useState<any>();

  const handlePlanName = (e: React.ChangeEvent<HTMLInputElement>) => {
    let first = e.target.value;
    setPlanName(first);
  };

  // get Initial data for edit option
  const intialData = () => {
    setPlanName(specificPlan?.plan_name);
    // setStartDate(moment.utc(specificPlan?.from_date));
    // setEndDate(moment.utc(specificPlan?.to_date));
  };

  useEffect(() => {
    intialData();
  }, []);

  // Handeler function to save the Edited Seasonal Plan
  const edit_plan = async () => {
    if (planName) {
      try {
        setSaveLoader(true);
        let res = await kernelApi.post(
          `/edit-seasonal-plan/${specificPlan.seasonal_plan_id}`,
          {
            hotel_id: current_property.hotel_id,
            plan_name: planName,
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (res.data.status === 1) {
          setSaveLoader(false);
          onClose();
          toast(`${res.data.message}`, {
            hideProgressBar: true,
            type: "success",
          });
        }
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit PLan</div>
      </div>

      <div className="height-64px"></div>
      <div className="seasonal__update__wrapper my-4 pt-2">
        {/* ----------------------------------------- */}
        <div className="inventory__number__wrapper mt-1 pt-3 mb-2">
          <div className="__default--input--field">
            <InputTextField
              label="Plan Name"
              value={planName}
              handleChange={handlePlanName}
            />
          </div>
        </div>
      </div>
      {/* ----------------------------------------- */}
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-1">
          <InventoryBtn handleClick={edit_plan} loader={saveLoader}>
            Save
          </InventoryBtn>
        </div>
      </div>
    </>
  );
};

export default EditSeasonalPlan;
