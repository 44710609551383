import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import moment from "moment";

import { CATCH_MESSAGE, getImage, UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import cmApi from "../../API/cmApi";

// import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

// import button
import Button from "../../views/buttons/Button";

import CalendarSkeleton from "../../views/loader/CalendarSkeleton";

import beApi from "../../API/beApi";
import gemsApi from "../../API/gemsApi";

import { toast } from "react-toastify";
import {
  SaveBooking,
  SaveBookingCRS,
  SaveBookingDetailedInfo,
  SaveStayDetails,
} from "../../redux/actions/BookingsAction";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import ModifyBooking from "./ModifyBooking";
import CancelBooking from "./CancelBooking";
import NewBookings from "./NewBookings";

import { Tooltip } from "@mui/material";
import InvoiceDownload from "./InvoiceDownload";
import endPoints from "../../API/endPoints";
import FetchBooking from "./FetchBookings";

const CRSView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  UpdateSidebar("Bookings", "CRS View");
  const {
    isgemsSubscribed,
    allSources,
    bookingsDataGet,
    roomTypesDataGet,
    bookingDetailsCRSGet,
    stayDetailsByBookingIdGetV2,
    stayDetailsByBookingIdGet,
    bookingDetailsGet,
  } = endPoints.BOOKINGS;

  const { getFloors } = endPoints.PROPERTY_FLOORS;

  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { booking_details } = useSelector((state: RootState) => state.bookings);

  const [currentMonth, setCurrentMonth] = useState(moment().format("MMM"));
  const [currentYear, setCurrentYear] = useState(
    parseInt(moment().format("YYYY"))
  );
  const [firstDate, setFirstDate] = useState(moment().format("YYYY-MM-DD"));

  const [allSource, setAllSource] = useState<any[]>([]);

  const [allFloors, setAllFloors] = useState<{
    ids: string[];
    labels: string[];
  }>({ ids: [], labels: [] });

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [allRooms, setAllRooms] = useState<any>([]);

  const [inventoryData, setInventoryData] = useState<any>();
  const [bookingsData, setBookingsData] = useState<any>([]);
  const [selectedRoomTypeIndex, setSelectedRoomTypeIndex] = useState(-1);
  const [showRooms, setShowRooms] = useState(false);
  const [showBookings, setShowBookings] = useState(true);
  const [allocatedRooms, setAllocatedRooms] = useState<any[]>([]);
  const [currentRoom, setCurrentRoom] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isGems, setIsGems] = useState<boolean>(false);

  const [openBookingDetailsSlider, setopenBookingDetailsSlider] =
    useState(false);
  const [openModifyBookingSlider, setopenModifyBookingSlider] = useState(false);
  const [openCancelBookingSlider, setopenCancelBookingSlider] = useState(false);
  const [openInvoiceDownloadSlider, setopenInvoiceDownloadSlider] =
    useState(false);

  // const [activeDueTab, setActiveDueTab] = useState("due");

  const [openSlideNewBooking, setOpenSlideNewBooking] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const [openSlideFetchBooking, setOpenSlideFetchBooking] = useState(false);

  const numOfColumns = 30;
  const blockHeight = 50;
  const leftWidth = 160;
  // const leftWidth = 97;
  const [gap, setGap] = useState(55);
  const color = "#808080";
  const [transform, setTransform] = useState(137);

  // const roomStatus = [
  //   { label: "Vacant & Clean", class: "vacant--clean" },
  //   { label: "Vacant & Dirty", class: "vacant--dirty" },
  //   { label: "Occupied", class: "occupied" },
  //   { label: "Out of Service", class: "out--of--service" },
  // ]

  useEffect(() => {
    isGemsSubscribed();
  }, [current_property.hotel_id]);
  // Api To Check Is Gems Subscribed Or Note
  const isGemsSubscribed = async () => {
    try {
      let gemsRes = await gemsApi.get(
        `${isgemsSubscribed}/${current_property.hotel_id}`
      );

      if (gemsRes?.data?.status === 1) {
        gemsRes?.data?.message.toLowerCase() === "subscribed" &&
          setIsGems(true);
      } else {
        setIsGems(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [refreshDataNewBooking, setRefreshDataNewBooking] = useState(true);

  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const [access, setAccess] = useState(true);

  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData]);

  useEffect(() => {
    let width =
      (document.getElementById("calendar-header-div")?.clientWidth || 1600) -
      (leftWidth + 4);
    setGap(width / numOfColumns);
  }, [current_property, gap.toFixed(0)]);

  useEffect(() => {
    getRoomTypesData(selectedRoomTypeIndex);
  }, [firstDate]);

  useEffect(() => {
    getAllFloors();
    getAllSources();
  }, [current_property]);

  // render bookings data on selected room type change
  useEffect(() => {
    setShowBookings(bookingsData.length);
    setTimeout(() => {
      setShowBookings(true);
    }, 100);
  }, [selectedRoomTypeIndex, bookingsData, current_property]);

  useEffect(() => {
    if (selectedRoomTypeIndex > -1 && inventoryData?.rooms.length > 0) {
      getBookingsData(inventoryData.rooms[selectedRoomTypeIndex].room_type_id);
    }
  }, [
    firstDate,
    selectedRoomTypeIndex,
    current_property,
    refreshDataNewBooking,
    refreshTrigger,
  ]);

  // API call to get all floors
  const getAllFloors = async () => {
    try {
      const response = await gemsApi.post(`${getFloors}`, {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
      });

      let ids: any[] = ["-1"];
      let labels: any[] = ["All Floors"];

      response.data.allFloors.forEach((floor: any) => {
        if (floor.planned_for_room) {
          ids.push(floor.floor_no);
          labels.push(floor.floor_name);
        }
      });

      setAllFloors({ ids, labels });
    } catch (error) {
      console.log(error);
    }
  };

  // API call for getting all the source
  const getAllSources = async () => {
    let res = await beApi.get(`${allSources}/${current_property.hotel_id}`);
    if (res.data.status === 1) {
      let sources = res.data.data;
      setAllSource(sources);
      // sources.unshift("All Sources");
      let otaname: string[] = [];
      let otaid: number[] = [];
      sources.forEach((source: any) => {
        otaname.push(source.ota_name);
        otaid.push(source.ota_id);
      });
    }
  };

  const getBookingsData = async (
    roomTypeId: string,
    floor_no?: number,
    sources?: string[]
  ) => {
    setIsLoading(true);
    try {
      let payload: any = {
        hotel_id: current_property.hotel_id,
        from_date: firstDate,
        to_date: moment(firstDate).add(numOfColumns, "d").format("YYYY-MM-DD"),
        room_type_id: roomTypeId,
      };

      if (floor_no && floor_no.toString() !== "-1") {
        let all_ota_ids: string[] = [];
        allSource.forEach((source: any) => {
          all_ota_ids.push(source.ota_id);
        });

        payload.search_fields = {
          floor_no: [floor_no],
          source: [all_ota_ids],
        };
      }

      if (sources && sources.length > 0) {
        payload.search_fields = {
          floors: allFloors.ids
            .filter((floor: string) => floor !== "-1")
            .map(Number),
          source: sources,
        };
      }

      let pld: any = {
        hotel_id: current_property.hotel_id,
        // from_date: '2022-03-25',
        // to_date: '2022-04-20'
        from_date: firstDate,
        to_date: moment(firstDate).add(numOfColumns, "d").format("YYYY-MM-DD"),
        room_type_id: roomTypeId,
      };

      const response = await cmApi.post(`${bookingsDataGet}`, pld, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (response.data.status === 1) {
        setBookingsData(response.data.data || []);
        setAllRooms(response.data.resource || []);
        setAllocatedRooms(response.data.alocated_resources || []);
      } else {
        setBookingsData([]);
        setAllRooms([]);
      }
    } catch (error) {
      setBookingsData([]);
      setAllRooms([]);
      console.log(error);
    }
    setIsLoading(false);
  };

  const getRoomTypesData = async (roomTypeIndex: number) => {
    setIsLoading(true);
    try {
      const response = await cmApi.get(
        `${roomTypesDataGet}/${current_property.hotel_id}/${firstDate}/${moment(
          firstDate
        )
          .add(numOfColumns, "d")
          .format("YYYY-MM-DD")}/0`
      );
      if (response.data.status === 1) {
        setInventoryData(response.data.data);
        let room_type_id =
          response.data.data.rooms[roomTypeIndex]?.room_type_id || 0;
        if (room_type_id) {
          getBookingsData(room_type_id);
        }
      } else {
        setInventoryData([]);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getBookingDetailsCRS = async (booking: any) => {
    try {
      const response = await beApi.post(
        `${bookingDetailsCRSGet}`,
        {
          allocation: booking.allocation,
          booking_id: booking.booking_id,
          booking_source: booking.booking_source,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(SaveBookingCRS(response.data.data));
        setopenBookingDetailsSlider(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  const getBookingDetails = async (bookingId: string) => {
    try {
      const response = await gemsApi.post(`${bookingDetailsGet}`, {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
        booking_id: bookingId,
      });

      if (response.data.status === 1) {
        dispatch(SaveBooking(response.data));
        getBookingDetailsById(bookingId);
        setTimeout(() => {
          navigate("/bookings/check-in", {
            state: { allocated_rooms: allocatedRooms },
          });
        }, 250);
      }
    } catch (error) {
      toast.error("Booking details not found");
    }
  };

  const getBookingDetailsById = async (booking_id: string) => {
    try {
      const response = await gemsApi.post(`${stayDetailsByBookingIdGetV2}`, {
        user_id: admin_id,
        booking_id: booking_id,
        hotel_id: current_property.hotel_id,
      });

      if (response.data.status) {
        dispatch(SaveBookingDetailedInfo(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStayDetailsByBookingId = async (booking: any) => {
    try {
      let payload = {
        hotel_id: current_property.hotel_id,
        booking_id: booking.booking_id,
        user_id: admin_id,
        room_id: booking.resource_id,
      };

      const response = await gemsApi.post(
        `${stayDetailsByBookingIdGet}`,
        payload
      );

      if (response.data.status === 1) {
        dispatch(SaveStayDetails(response.data));
        setTimeout(() => {
          navigate("/bookings/stay-details", {
            state: { room_id: booking.resource_id },
          });
        }, 300);
      }
    } catch (error) {
      toast("Could not fetch stay details", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const getPosition = (book: any) => {
    let lastDate = moment(firstDate)
      .add(numOfColumns, "d")
      .format("YYYY-MM-DD");

    let first_date = document.getElementById(firstDate);

    let last_date = document.getElementById(lastDate);

    let str_date = document.getElementById(book.check_in_dis);

    let end_date = document.getElementById(book.check_out_dis);

    let room = document.getElementById(book.resource);

    let x0 = document
      .getElementById("calendar-header")
      ?.getBoundingClientRect().left;

    let x1 = str_date
      ? str_date.getBoundingClientRect().left
      : (first_date?.getBoundingClientRect().left || leftWidth) - gap;

    let x2 = end_date
      ? end_date.getBoundingClientRect().left
      : (last_date?.getBoundingClientRect().left || 2500) + gap;

    let y0 = document
      .getElementById("calendar-body")
      ?.getBoundingClientRect().top;

    let y1 = room?.getBoundingClientRect().top;

    if (x0 && x1 && x2 && y0 && y1 && (str_date || end_date)) {
      return {
        x: x1 - x0 + 20,
        y: y1 - y0 + 5 - selectedRoomTypeIndex * blockHeight,
        width: x2 - x1,
      };
    } else {
      return {
        x: 0,
        y: 0,
        width: 0,
      };
    }
  };

  const onMonthSelect = (Month: string, monthIndex: number) => {
    setCurrentMonth(Month);
    let month = (monthIndex + 1).toString();
    setFirstDate(
      currentYear.toString() + (month.length === 1 ? "-0" : "-") + month + "-01"
    );
  };

  const onYearChange = (change: number) => {
    let year = currentYear + change;
    if (year <= moment().year()) {
      setCurrentYear(year);
      setFirstDate(
        year.toString() +
        "-" +
        moment().month(currentMonth).format("MM") +
        "-01"
      );
    }
  };

  const getPrevDate = () => {
    let date = moment(firstDate)
      .subtract(numOfColumns, "d")
      .format("YYYY-MM-DD");
    setFirstDate(date);
    setCurrentYear(moment(date).year());
    setCurrentMonth(moment(date).format("MMM"));
  };

  const getNextDate = () => {
    let date = moment(firstDate).add(numOfColumns, "d").format("YYYY-MM-DD");
    setFirstDate(date);
    setCurrentYear(moment(date).year());
    setCurrentMonth(moment(date).format("MMM"));
  };

  const getCurrentDate = () => {
    setFirstDate(moment().format("YYYY-MM-DD"));
    setCurrentYear(moment().year());
    setCurrentMonth(moment().format("MMM"));
  };

  const onDateChange = (date: any) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    setFirstDate(date_str);
    setCurrentYear(moment(date_str).year());
    setCurrentMonth(moment(date_str).format("MMM"));
  };

  const toggleViewRooms = (index: number) => {
    if (selectedRoomTypeIndex === index) {
      setSelectedRoomTypeIndex(-1);
      setShowRooms(false);
    } else {
      setSelectedRoomTypeIndex(index);
      setShowRooms(true);
    }
  };

  const onModifyBookingClick = () => {
    setopenBookingDetailsSlider(false);
    setopenModifyBookingSlider(true);
  };

  const onCheckinClick = () => {
    setopenBookingDetailsSlider(false);
    getBookingDetails(booking_details?.bookingid);
    getBookingDetailsById(booking_details?.bookingid);
  };

  const onCancelBookingClick = () => {
    setopenBookingDetailsSlider(false);
    setopenCancelBookingSlider(true);
  };

  const onBookingClick = (booking: any) => {
    const allocationStatus = booking.allocation;
    setCurrentRoom(booking.resource);
    if (allocationStatus === 0) {
      getBookingDetailsCRS(booking);
    } else if (allocationStatus === 1) {
      getStayDetailsByBookingId(booking);
    } else if (allocationStatus === 4) {
      setopenInvoiceDownloadSlider(true);
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__bookings__action__right">
          <div className="status__icon__wrapper booking__color">
            <i className="bi bi-circle-fill me-1"></i> Booking
          </div>
          <div className="status__icon__wrapper checkedin__color">
            <i className="bi bi-circle-fill me-1"></i> Checked-in
          </div>
          <div className="status__icon__wrapper checkedout__color">
            <i className="bi bi-circle-fill me-1"></i> Checked-out
          </div>

          {/* <div className="fetch__booking__btn__wrapper">
            <Button
              className="fetch_bookings"
              icon="bi bi-download"
              handleClick={() => setOpenSlideFetchBooking(true)}
              isAccessible={access}
            >
              Fetch
            </Button>
          </div> */}

          <div className="new__booking__btn__wrapper">
            <Button
              className="new__bookings"
              icon="bi bi-plus-lg"
              handleClick={() => setOpenSlideNewBooking(true)}
              isAccessible={access}
            >
              New
            </Button>
          </div>
          <div className="calendar__view__wrapper">
            <div className="calendar__view__tab">
              <ul className="nav nav-tabs" id="mealPlanTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="crs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="crs"
                    aria-controls="crs"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-calendar2-range"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() =>
                      navigate(
                        `/bookings/${isGems ? "gems/frontoffice-view" : "frontoffice-view"
                        }`
                      )
                    }
                    className="nav-link"
                    id="pms-tab"
                    data-bs-toggle="tab"
                    data-bs-target="pms"
                    aria-controls="pms"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-calendar-week"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() => navigate("/bookings/list-view")}
                    className="nav-link"
                    id="list-tab"
                    data-bs-toggle="tab"
                    data-bs-target="list"
                    aria-controls="list"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-grid-3x3-gap"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="hr--line"></div>

      <div id="frontoffice-view-wrapper">
        <div className="calendar__month__wrapper">
          <div className="calendar__year__wrapper">
            <span className="left__arrow" onClick={() => onYearChange(-1)}>
              <i className="bi bi-chevron-left"></i>
            </span>
            <span className="year__label mx-1">{currentYear}</span>
            <span className="right__arrow" onClick={() => onYearChange(1)}>
              <i className="bi bi-chevron-right"></i>
            </span>
          </div>
          <div className="calendar__month__list">
            <div className="month__wrapper">
              {moment.monthsShort().map((month, i) => {
                return (
                  <div
                    key={i}
                    className={`month__item ${month === currentMonth ? "active--month" : ""
                      }`}
                    onClick={() => onMonthSelect(month, i)}
                  >
                    {month}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="calendar__filter__wrapper">
          <div className="search__right__wrapper">
            <div className="change__calendar__filter">
              <span onClick={getPrevDate} className="left__arrow">
                <i className="bi bi-chevron-left"></i>
              </span>

              <div className="booking__crs__material__input">
                <div className="inventory__date__select__btn mui__input__fieldset__legends__unset">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      open={openDatePicker}
                      onOpen={() => setOpenDatePicker(true)}
                      onClose={() => setOpenDatePicker(false)}
                      value={moment(firstDate)}
                      onChange={onDateChange}
                      inputFormat="dd MMM yyyy"
                      disableMaskedInput
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onClick={() => setOpenDatePicker(true)}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <span onClick={getNextDate} className="right__arrow">
                <i className="bi bi-chevron-right"></i>
              </span>

              <span
                onClick={getCurrentDate}
                className="text__label__bookings mx-1"
              >
                Today
              </span>
            </div>
          </div>
        </div>

        <div className="calendar__card__wrapper">
          <div className="calendar__main__content mb-5">
            {isLoading && <CalendarSkeleton rows={1} cols={10} />}
            <div
              className="calendar__layout__wrapper"
              style={{ opacity: isLoading ? 0 : 1 }}
            >
              <div className="calendar__header__panel" id="calendar-header-div">
                <svg
                  id="calendar-header"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="120"
                >
                  <g transform="translate(0,0)">
                    <g transform="translate(0,30)">
                      <text
                        className="calendar__label__text"
                        transform="translate(17,25)"
                      >
                        ROOMS
                      </text>
                    </g>
                  </g>

                  {/* <g transform="translate(-33, 0)"> */}
                  <g transform="translate(-63, 0)">
                    <g
                      id="current-month"
                      transform={`translate(${leftWidth}, 0)`}
                    >
                      <line
                        x1="0"
                        x2="0"
                        y1="0"
                        y2="40"
                        stroke={color}
                        strokeWidth="0.5"
                      ></line>
                      <text
                        className="month__year__label"
                        transform="translate(15, 25)"
                      >
                        {moment(firstDate).format("MMM yyyy")}
                      </text>
                      <rect height="40px" opacity="0"></rect>
                    </g>
                  </g>

                  {/* <g transform="translate(-33,0)"> */}
                  <g transform="translate(-63,0)">
                    {inventoryData?.count?.map((inv: any, idx: number) => {
                      let date = moment(firstDate)
                        .add(idx, "days")
                        .format("YYYY-MM-DD");
                      let today = moment().format("YYYY-MM-DD");
                      return (
                        <React.Fragment key={idx}>
                          <g
                            id={date}
                            // transform={`translate(${leftWidth + (idx + 0.5) * gap - 20
                            transform={`translate(${leftWidth + (idx + 0.5) * gap - 17 + 2.0 * idx + 1
                              },45)`}
                            className="upperValues"
                          >
                            <rect
                              height="40"
                              // width="40"
                              width="35"
                              rx="2"
                              ry="2"
                              fill={date === today ? "#223189" : "#F5F6F8"}
                            ></rect>
                            <text
                              className={
                                date === today
                                  ? "calendar__grid__text active__color"
                                  : "calendar__grid__text"
                              }
                              // x="20"
                              x="18"
                              y="18"
                            >
                              {moment(date).format("ddd")}
                            </text>
                            <text
                              className={
                                date === today
                                  ? "calendar__grid__text calendar__day active__color"
                                  : "calendar__grid__text calendar__day"
                              }
                              // x="20"
                              x="18"
                              y="36"
                            >
                              {moment(date).format("DD")}
                            </text>
                            {/* <g transform={`translate(${idx * 5},48)`}> */}
                            <g transform={`translate(${0},48)`}>
                              <rect
                                className="calendar__grid__row_inventory"
                                height="20"
                                // width="40"
                                width="36"
                                rx="2"
                                ry="2"
                              ></rect>
                              <text
                                className="availability__inventory"
                                // transform="translate(20,15)"
                                transform="translate(18,15)"
                              >
                                {inv < 10 ? `0${inv}` : inv}
                              </text>
                            </g>
                          </g>

                          <line
                            x1={leftWidth + gap * idx + 2.0 * idx}
                            x2={leftWidth + gap * idx + 2.0 * idx}
                            y1="40"
                            y2="120"
                            strokeWidth="0.5"
                            stroke={color}
                          ></line>
                        </React.Fragment>
                      );
                    })}

                    <line
                      x1="0"
                      x2="110%"
                      y1="120"
                      y2="120"
                      strokeWidth="0.5"
                      stroke={color}
                    ></line>
                    <line
                      x1={leftWidth}
                      x2="110%"
                      y1="40"
                      y2="40"
                      strokeWidth="0.5"
                      stroke={color}
                    ></line>
                    <line
                      x1={leftWidth}
                      x2={leftWidth}
                      y1="40"
                      y2="120"
                      strokeWidth="0.5"
                      stroke={color}
                      style={{ display: "none" }}
                    ></line>
                  </g>
                </svg>
              </div>

              <div className="calendar__room__types__section">
                <svg
                  id="calendar-body"
                  height={
                    (inventoryData?.rooms?.length +
                      (showRooms ? allRooms?.length : 0)) *
                    blockHeight || 500
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                >
                  <g transform="translate(0, 0)">
                    {inventoryData?.rooms?.map((rmtype: any, i: number) => {
                      const RoomTypeName =
                        rmtype.room_type_shortcode || rmtype.room_type;
                      return (
                        <g
                          key={i}
                          transform={`translate(0,${i <= selectedRoomTypeIndex || !showRooms
                            ? "0"
                            : allRooms.length * blockHeight
                            })`}
                        >
                          <rect
                            className="calendar__grid__row"
                            y={i * blockHeight}
                            height={blockHeight}
                            width="100%"
                          ></rect>

                          <g onClick={() => toggleViewRooms(i)}>
                            <rect
                              className="room__type__bg"
                              y={i * blockHeight}
                              height={blockHeight}
                              width={leftWidth}
                            ></rect>
                            {/* Material Ui Tool Tip */}
                            <Tooltip
                              title={rmtype.room_type}
                              placement="top"
                              arrow
                            >
                              <text
                                className="room__typename__bg"
                                y={i * blockHeight}
                                transform={`translate(17,${blockHeight / 2 + 5
                                  })`}
                              >
                                {RoomTypeName.length > 5
                                  ? RoomTypeName.substring(0, 4) + "..."
                                  : RoomTypeName}
                              </text>
                            </Tooltip>
                            <g
                              style={{ cursor: "pointer" }}
                              // transform={`translate(120,${22 + i * blockHeight
                              transform={`translate(90,${22 + i * blockHeight
                                })`}
                            >
                              <path
                                className={`${showRooms && selectedRoomTypeIndex === i
                                  ? "rotate-180"
                                  : ""
                                  }`}
                                d="m -10,5 5,-5 5,5 z"
                              ></path>
                            </g>
                          </g>

                          <line
                            x1="0"
                            x2="100%"
                            y1={(i + 1) * blockHeight}
                            y2={(i + 1) * blockHeight}
                            stroke={color}
                            strokeWidth="0.5"
                          ></line>

                          {rmtype?.inv_data?.map((inv: any, j: number) => {
                            return (
                              <React.Fragment key={j}>
                                <g
                                  key={j}
                                  className="room_values"
                                  // transform={`translate(${leftWidth + (j + 0.5) * gap - 20
                                  transform={`translate(${leftWidth +
                                    (j + 0.5) * gap -
                                    40 +
                                    j * 2 -
                                    10 -
                                    30
                                    },${15 + blockHeight * i})`}
                                >
                                  <rect
                                    className={
                                      inv.block_status === 1
                                        ? "calendar__grid__row_inventory display__blocked__inventory"
                                        : "calendar__grid__row_inventory"
                                    }
                                    rx="2"
                                    ry="2"
                                    // width="40"
                                    width="36"
                                    height="20"
                                  ></rect>
                                  <text
                                    className={
                                      inv.block_status === 1
                                        ? "availability__inventory blocked__inventory__value"
                                        : "availability__inventory"
                                    }
                                    // transform="translate(20,15)"
                                    transform="translate(18,15)"
                                  >
                                    {inv.no_of_rooms < 10
                                      ? `0${inv.no_of_rooms}`
                                      : inv.no_of_rooms}
                                  </text>
                                </g>
                                {/* <g transform={`translate(${leftWidth},0)`}> */}
                                {/* <g transform={`translate(${j > 0 ? 137 + 5 : 137},0)`}> */}
                                {/* <g transform={`translate(${transform + (j * 5) - 10 - 30},0)`}> */}
                                <g
                                  transform={`translate(${transform + j * 2 - 10 - 30
                                    },0)`}
                                >
                                  <line
                                    x1={j * gap}
                                    x2={j * gap}
                                    y1={i * blockHeight}
                                    y2={(i + 1) * blockHeight}
                                    stroke={color}
                                    strokeWidth="0.5"
                                  ></line>
                                </g>
                              </React.Fragment>
                            );
                          })}
                        </g>
                      );
                    })}
                  </g>

                  {showRooms &&
                    allRooms.map((room: any, index: number) => {
                      return (
                        <g
                          key={index}
                          transform={`translate(0, ${selectedRoomTypeIndex * blockHeight
                            })`}
                        >
                          {/* ----- */}
                          <g>
                            {showBookings &&
                              bookingsData?.map((booking: any, idx: number) => {
                                let X = getPosition(booking).x;
                                let Y = getPosition(booking).y;
                                let Width = getPosition(booking).width;

                                let check_in_date = moment(
                                  booking.check_in_dis
                                );
                                let check_out_date = moment(
                                  booking.check_out_dis
                                );
                                let date_diff = check_out_date.diff(
                                  check_in_date,
                                  "days"
                                );

                                if (
                                  booking.resource === room.toString() &&
                                  Y &&
                                  Width &&
                                  booking.room_type_id ===
                                  inventoryData?.rooms[selectedRoomTypeIndex]
                                    .room_type_id
                                ) {
                                  return (
                                    <g
                                      key={idx}
                                      onClick={() => onBookingClick(booking)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <g
                                        transform={`translate(${X}, ${Y + 10})`}
                                      // transform={`translate(${X + 15}, ${Y + 10})`}
                                      >
                                        <rect
                                          width={Width}
                                          height="20"
                                          rx="5"
                                          ry="5"
                                          fill="#ffffff"
                                        ></rect>
                                        <rect
                                          width={Width - 4}
                                          height="20"
                                          rx="5"
                                          ry="5"
                                          fill={`#${booking.allocation
                                            ? booking.allocation !== 4
                                              ? "566BE1"
                                              : "B7B7B7"
                                            : "F7921B"
                                            }`}
                                        ></rect>

                                        {date_diff === 1 && (
                                          <g transform="translate(2, 0)">
                                            <g transform="translate(0, 15)">
                                              <text className="display__booking__id" fill="#ffffff">
                                                {booking.booking_id.slice(-4)}
                                              </text>
                                            </g>
                                          </g>
                                        )}

                                        {date_diff === 2 && (
                                          <g transform="translate(2, 0)">
                                            <g transform="translate(0, 15)">
                                              <text className="display__booking__id" fill="#ffffff">
                                                {booking.booking_id.slice(-9)}
                                              </text>
                                            </g>
                                          </g>
                                        )}

                                        {date_diff === 3 && (
                                          <g transform="translate(2, 0)">
                                            <g transform="translate(0, 15)">
                                              <text className="display__booking__id" fill="#ffffff">
                                                {booking.booking_id.slice(-15)}
                                              </text>
                                            </g>
                                          </g>
                                        )}

                                        {date_diff >= 4 && (
                                          <g transform="translate(2, 0)">
                                            <g transform="translate(0, 15)">
                                              <text className="display__booking__id" fill="#ffffff">
                                                {booking.booking_id}
                                              </text>
                                            </g>
                                          </g>
                                        )}
                                      </g>
                                    </g>
                                  );
                                }
                              })}
                          </g>
                          {/* ----- */}
                          <g
                            id={room}
                            transform={`translate(0, ${(index + 1) * blockHeight
                              })`}
                          >
                            <rect
                              x="0"
                              y="0"
                              // width={leftWidth}
                              width={96}
                              height={blockHeight}
                              fill="#ffffff"
                            ></rect>

                            <g transform="translate(0, 0)">
                              <g>
                                {/* <g transform="translate(18, 13)"> */}
                                <g transform="translate(12, 13)">
                                  <circle
                                    cx="3"
                                    cy="14"
                                    r="7"
                                    fill="#2AB930"
                                  ></circle>
                                </g>
                                {/* <text transform="translate(36, 32)"> */}
                                <text transform="translate(24, 32)">
                                  {room}
                                </text>
                              </g>
                              <rect
                                x="0"
                                y="0"
                                opacity="0"
                                height={blockHeight}
                                // width="135"
                                width="96"
                              ></rect>
                              <line
                                x1="0"
                                x2="100%"
                                y1={blockHeight}
                                y2={blockHeight}
                                stroke={color}
                                strokeWidth="0.5"
                              ></line>
                              <line
                                // x1={leftWidth}
                                // x2={leftWidth}
                                x1={97}
                                x2={97}
                                y1="0"
                                y2={blockHeight}
                                stroke={color}
                                strokeWidth="0.5"
                              ></line>
                            </g>
                          </g>
                        </g>
                      );
                    })}
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={openSlideNewBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideNewBooking(false)}
      >
        <NewBookings
          onClose={() => setOpenSlideNewBooking(false)}
          refreshData={() => setRefreshDataNewBooking(!refreshDataNewBooking)}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openBookingDetailsSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenBookingDetailsSlider(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setopenBookingDetailsSlider(false)}
              className="bi bi-x"
            ></i>
          </div>
          {booking_details && (
            <>
              <div className="standard-page--label">Booking Detail</div>
              <div className="side__slider__sub__heading me-3">
                ID : {booking_details.bookingid}
              </div>
            </>
          )}
        </div>
        <div className="height-64px"></div>
        {booking_details ? (
          <div className="booking__details__guest__wrapper">
            <div className="guest__detail__card">
              <div className="guest__name__wrapper">
                <div className="guest__name">{booking_details?.guest_name}</div>
                <div className="booking__channel__icon">
                  <img
                    className="booking__source__image"
                    src={booking_details?.channel_logo}
                    alt="Booking Source Icon"
                  />
                </div>
              </div>

              <div className="guest__booking__date mt-2">
                Booking Date - {booking_details?.booking_date}
              </div>
              <div className="guest__email__contact__details mt-2">
                <div className="guest__number">
                  <i className="bi bi-telephone"></i> {booking_details?.mobile}
                </div>
                <div className="guest__email__id">
                  <i className="bi bi-envelope"></i> {booking_details?.email_id}
                </div>
              </div>

              <div className="checkin__checkout__wrapper mt-3">
                <div className="checkinout__content">
                  <div className="check__inout__details check-in-border">
                    <div className="check__inout__icon check-in-color">
                      <i className="bi bi-arrow-down-right-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-in-color">
                        {booking_details?.checkin_at}
                      </div>
                      <div className="check__inout__label">Check-in</div>
                    </div>
                  </div>
                </div>

                <div className="number__of__nights__wrapper">
                  <div className="night__icon">
                    <i className="bi bi-moon-fill"></i>
                  </div>
                  <div className="night__number">{booking_details?.nights}</div>
                </div>

                <div className="checkinout__content">
                  <div className="check__inout__details check-out-border">
                    <div className="check__inout__icon check-out-color">
                      <i className="bi bi-arrow-up-left-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-out-color">
                        {booking_details?.checkout_at}
                      </div>
                      <div className="check__inout__label">Check-out</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="standard-page--label my-3">Room Details</div>

            {booking_details?.room_data?.map((room: any, i: number) => {
              return (
                <div key={i} className="booking__room__detail__wrapper mb-4">
                  <div className="booking__room__image">
                    <img src={getImage(room.room_image)} alt={room.room_type} />
                  </div>
                  <div className="booking__room__details">
                    <div className="room__type__meal__plan mb-3">
                      <div className="room__type__name">
                        <i className="bi bi-door-open me-2"></i>
                        {room.room_type}
                      </div>
                      <div className="room__type__meal__plan">
                        <i className="bi bi-cup-straw"></i> {room.plan_type}
                      </div>
                    </div>
                    <div className="booking__room__type__rate mb-3">
                      <i className="bi bi-people me-2"></i>{" "}
                      <div className="adult__child__wrapper">
                        <div className="adult__wrapper me-2">
                          {room.adult} Adult
                        </div>
                        <div className="child__wrrapper">
                          {room.child} Child
                        </div>
                      </div>
                    </div>
                    <div className="booking__room__nights mb-3">
                      <i className="bi bi-question-circle me-2"></i> Rooms x{" "}
                      {room.no_of_rooms}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="booking__details__price__wrapper">
              <div className="standard-page--label my-3">Price Breakup</div>
              <div className="booking__details__price__card">
                <div className="booking__detaila__price__input__wrapper me-3">
                  <div>
                    <div className="price__input__label mb-2">Total Price</div>
                    <div className="price__input">
                      <div className="price__label">
                        {booking_details?.currency_name}
                      </div>
                      <div className="price__amount">
                        {booking_details?.price ?? " "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="booking__detaila__price__input__wrapper">
                  <div>
                    <div className="price__input__label mb-2">Tax</div>
                    <div className="price__input">
                      <div className="price__label">{booking_details?.tax}</div>
                      <div className="price__amount">
                        {booking_details?.tax_amount ?? " "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>

            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper booking__btn__alignment">
                {booking_details?.is_modify !== 0 && (
                  <Button
                    handleClick={onModifyBookingClick}
                    className="modify__bookinhg__btn"
                    isAccessible={access}
                  >
                    Modify Booking
                  </Button>
                )}
                {booking_details?.is_checkin !== 0 && (
                  <Button
                    handleClick={onCheckinClick}
                    className="checkin__bookinhg__btn"
                    isAccessible={access}
                  >
                    Check-in
                  </Button>
                )}
                {booking_details?.is_cancel !== 0 && (
                  <Button
                    handleClick={onCancelBookingClick}
                    className="cancel__bookinhg__btn"
                    isAccessible={access}
                  >
                    Cancel booking
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>Booking details not found</div>
          </div>
        )}
      </SlidingPane>

      {/* Modify Booking Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openModifyBookingSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenModifyBookingSlider(false);
          setopenBookingDetailsSlider(true);
        }}
      >
        {booking_details ? (
          <ModifyBooking
            onClose={() => {
              setopenModifyBookingSlider(false);
              setopenBookingDetailsSlider(true);
            }}
            trigger={refreshTrigger}
            setTrigger={setRefreshTrigger}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openCancelBookingSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenCancelBookingSlider(false);
          setopenBookingDetailsSlider(true);
        }}
      >
        {booking_details ? (
          <CancelBooking
            onClose={() => {
              setopenCancelBookingSlider(false);
              setopenBookingDetailsSlider(true);
            }}
            sliderState={setopenCancelBookingSlider}
            trigger={refreshTrigger}
            setTrigger={setRefreshTrigger}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openInvoiceDownloadSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenInvoiceDownloadSlider(false)}
      >
        {booking_details ? (
          <InvoiceDownload
            roomNo={currentRoom}
            onClose={() => setopenInvoiceDownloadSlider(false)}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openSlideFetchBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideFetchBooking(false)}
      >
        <FetchBooking onClose={() => setOpenSlideFetchBooking(false)} />
      </SlidingPane>
    </>
  );
};

export default CRSView;
