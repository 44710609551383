import React, { useState, useEffect } from "react";
import axios from "axios";
import DataLoader from "../../views/loader/DataLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const FrontOfficeData = () => {
    // Data From Redux
    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );
    const { today_data } = useSelector((state: RootState) => state.dashBoardData);
    const frontOfficeData = ["Today", "Tomorrow"];
    const [activeMenuTab, setActiveMenuTab] = useState<any>("Today");
    const [loadingTodayData, setLoadingTodayData] = useState(true);
    const [loadingTomorrowData, setLoadingTomorrowData] = useState(true);

    const switchMenuTab = (tab: string) => {
        setActiveMenuTab(tab);
    };

    const [checkInOut, setcheckInOut] = useState<any>();

    // Destructuring the Today Data

    const { checkins, checkouts } = today_data ?? {};
    const { tomorrow_checkins, tomorrow_checkouts } = today_data ?? {};

    useEffect(() => {
        // if (activeMenuTab === "Today") {
        //     // getCheckinCheckout();
        // }
        if (checkins >= 0) {
            setLoadingTodayData(false);
        } else {
            setLoadingTodayData(true);
        }
        if (checkouts >= 0) {
            setLoadingTodayData(false);
        } else {
            setLoadingTodayData(true);
        }

        if (tomorrow_checkins >= 0) {
            setLoadingTomorrowData(false);
        } else {
            setLoadingTomorrowData(true);
        }
        if (tomorrow_checkouts >= 0) {
            setLoadingTomorrowData(false);
        } else {
            setLoadingTomorrowData(true);
        }
    }, [today_data]);

    // const getCheckinCheckout = async () => {
    //     setLoadingTodayData(true);
    //     try {
    //         const res = await axios.get(`https://kernel.bookingjini.com/hotelier-today-overview/${current_property.hotel_id}`);

    //         if (res.data.status === 1) {
    //             setcheckInOut(res.data);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     setLoadingTodayData(false);
    // }

    return (
        <>
            <div className="dashboard__standard__header mb-4">
                <div className="front__office__data__toggler">
                    <div className="dashboard__standard__label">Front Office</div>
                    <div className="dashboard__standard__action__btns">
                        <ul className="nav nav-tabs" id="duesTab" role="tablist">
                            {frontOfficeData.map((tab) => {
                                return (
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                        key={tab}
                                        onClick={() => switchMenuTab(tab)}
                                    >
                                        <a
                                            className={`nav-link ${tab === activeMenuTab ? "active" : ""
                                                }`}
                                            id={`${tab}-tab`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#${tab}`}
                                            aria-controls={`#${tab}`}
                                            role="tab"
                                            aria-selected="true"
                                        >
                                            {tab}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            {activeMenuTab === "Today" ? (
                <div className="dashboard__frontoffice__card mb-4">
                    <div className="dashboard__frontoffice__box me-3">
                        <div className="pb-3">
                            {!loadingTodayData ? (
                                <div className="front__office__data">{checkins || 0}</div>
                            ) : (
                                <DataLoader />
                            )}
                        </div>
                        <div className="front__ofice__checkin__wrapper">
                            <div className="front__ofice__label">Check-in</div>
                            <div className="front__ofice__icon checkin--color">
                                <i className="bi bi-arrow-down-right-circle me-2"></i>
                            </div>
                        </div>
                    </div>

                    <div className="dashboard__frontoffice__box">
                        <div className="pb-3">
                            {!loadingTodayData ? (
                                <div className="front__office__data">{checkouts || 0} </div>
                            ) : (
                                <DataLoader />
                            )}
                        </div>
                        <div className="front__ofice__checkin__wrapper">
                            <div className="front__ofice__label">Check-out</div>
                            <div className="front__ofice__icon checkout--color">
                                <i className="bi bi-arrow-up-left-circle me-2"></i>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="dashboard__frontoffice__card mb-4">
                    <div className="dashboard__frontoffice__box me-3">
                        <div className="pb-3">
                            {!loadingTomorrowData ? (
                                <div className="front__office__data">{tomorrow_checkins || 0} </div>
                            ) : (
                                <DataLoader />
                            )}
                        </div>
                        <div className="front__ofice__checkin__wrapper">
                            <div className="front__ofice__label">Check-in</div>
                            <div className="front__ofice__icon checkin--color">
                                <i className="bi bi-arrow-down-right-circle me-2"></i>
                            </div>
                        </div>
                    </div>

                    <div className="dashboard__frontoffice__box">
                        <div className="pb-3">
                            {!loadingTomorrowData ? (
                                <div className="front__office__data">{tomorrow_checkouts || 0} </div>
                            ) : (
                                <DataLoader />
                            )}
                        </div>
                        <div className="front__ofice__checkin__wrapper">
                            <div className="front__ofice__label">Check-out</div>
                            <div className="front__ofice__icon checkout--color">
                                <i className="bi bi-arrow-up-left-circle me-2"></i>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FrontOfficeData;
