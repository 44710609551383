import React, { useEffect, useState } from "react";
import InputTextField from "../../views/inputtextfield/InputTextField";
import Button from "../../views/buttons/Button";
import ChannelLogo from "../../assets/img/Expedia.svg";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import cmApi from "../../API/cmApi";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage, Logout } from "../../UtilityFunctions";
import Loader from "../../views/loader/Loader";
import {
  AllChannels,
  OTAManageAction,
} from "../../redux/actions/ManageChannell";
import { Link, useNavigate } from "react-router-dom";
import airBnb from "../../API/airBnb";
import endPoints from "../../API/endPoints";
export interface IAddChannelSliderProps {
  onClose: () => void;

  allActiveChannels: any[];
  getAllActiveChannels: () => void;
}

const AddChannelSlider: React.FC<IAddChannelSliderProps> = ({
  onClose,
  getAllActiveChannels,
  allActiveChannels,

  // getAllChannels,
}) => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  // const { allChannels } = useSelector(
  //   (state: RootState) => state.manage_channels
  // );

  const [loader, setLoader] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>();
  // const [searchInput, setSearchInput] = useState<any>("");
  // const [filteredResults, setFilteredResults] = useState<any[]>([]);
  // const [checkedItem, setCheckedItem] = useState<any[]>([]);
  const [allOtaChannel, setAllOtaChannels] = useState<any[]>([]);
  // const [hotelCode, setHotelCode] = useState<any>("");
  // const [authParameter, setAuthParameter] = useState<any>("");
  // const [commission, setCommission] = useState<any>();
  // const [commissionHelperText, setCommissionHelperText] = useState<any>("");
  const [allActiveChannelNames, setAllActiveChannelsNames] = useState<any[]>(
    []
  );

  //Endpoints Import
  const { getAllAvailableOTAs } = endPoints.MANAGECHANNEL
  // --------------------------------

  useEffect(() => {
    getAllOtaChannels();
  }, []);

  useEffect(() => {
    const names = allActiveChannels.map((itm) => {
      return itm.ota_name.toLowerCase();
    });
    setAllActiveChannelsNames(names);
  }, [allActiveChannels]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Hanadle Checked Sections
  // const checkedSection = (e: React.ChangeEvent<HTMLInputElement>, itm: any) => {
  //   if (checkedItem) {
  //     let checkeds = [...checkedItem];
  //     if (
  //       checkeds.some((item: any) => {
  //         return item.ota_name === itm.ota_name;
  //       })
  //     ) {
  //       checkeds = checkeds.filter(
  //         (name: any) => name.ota_name !== itm.ota_name
  //       );
  //     } else {
  //       checkeds.push(itm);
  //     }
  //     setCheckedItem(checkeds);
  //   }
  // };

  // Search Item
  // const searchItems = (searchValue: any) => {
  //   const keyword = searchValue;
  //   if (keyword !== "") {
  //     const filteredData = allChannel.filter((item: any) => {
  //       return item.ota_name.toLowerCase().startsWith(keyword.toLowerCase());
  //     });
  //     setFilteredResults(filteredData);
  //   } else {
  //     setFilteredResults(allChannel);
  //   }
  //   setSearchInput(keyword);
  // };

  //Fetch all ota channels either added or notadded
  const getAllOtaChannels = async () => {
    setLoader(true);
    try {
      let allOtaChannels = await cmApi.get(
        `${getAllAvailableOTAs}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (allOtaChannels?.data?.status === 1) {
        setLoader(false);
        setAllOtaChannels(allOtaChannels?.data?.ota);
      } else {
        setLoader(false);
        setAllOtaChannels([]);
      }
    } catch (error: any) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Commission Change Handler
  // const commissionChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setCommission(e.target.value);
  //   if (Number(e.target.value) > 100) {
  //     setCommissionHelperText("Value cannot be greater than 100");
  //   } else {
  //     setCommissionHelperText("");
  //     setCommission(e.target.value);
  //   }
  // };


  return (
    <div>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Channel</div>
      </div>
      <div className="height-64px"></div>
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <div className="slider-content-wrapper">
          <div className="">
            {allOtaChannel?.length > 0 &&
              allOtaChannel?.map((item: any, idx: any) => {
                if (
                  item.ota_name !== "Makemytrip" &&
                  item.ota_name !== "BookingEngine"
                ) {
                  return (
                    <div
                      className="inventory__update__wrapper"
                      style={{ margin: "0", padding: "0" }}
                    >
                      <div
                        className={`add-channel__wrapper inventory__sync__channel__wrapper mb-4 ${isAdding === item?.id && "adding"
                          }`}
                        key={item?.id}
                      >
                        {/* Logo */}
                        <div
                          className="channel__wrapper"
                          style={{ border: "none" }}
                        >
                          <div className="channel__image__content">
                            <img
                              src={item?.logo}
                              alt={item?.ota_name}
                              style={{ width: "100px", height: "50px" }}
                            />
                          </div>
                        </div>

                        {/* Channel Status */}
                        {item?.is_added === 1 ? (
                          <div className="channel__select__checkbox">
                            <div className=" addchnl-form-check">
                              <div className="form-check">
                                <div className="add-channel-wrapper">
                                  <div className="add-channel-status">
                                    Added
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="channel__select__checkbox">
                            <div className=" addchnl-form-check">
                              <div className="form-check">
                                <div className="add-channel-button">
                                  <Button
                                    handleClick={() => {
                                      navigate("/manage-channels/ota-manage");
                                      dispatch(OTAManageAction(item));
                                    }}
                                  >
                                    Add
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      )}

      <div className="side__slider__panel__btn">
        {/* <Button>Save</Button> */}
        <Button className="cancel__btn " handleClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddChannelSlider;
