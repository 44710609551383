import React from 'react'

export interface IRightSideContent {
    children?: JSX.Element;
}

const RightSideContent: React.FC<IRightSideContent> = ({ children }) => {
    return (
        <div className="div__input__wrapper__right div__right__side__box">
            <div className="div__inputs">
                {children}
            </div>
        </div>
    )
}

export default RightSideContent