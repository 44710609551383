import React, { useState, useEffect } from "react";

// import sliding pane
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import Button from "../../views/buttons/Button";
import NextButton from "../../views/buttons/NextButton";
import { Logout, validatePositiveInt } from "../../UtilityFunctions";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";

import ImageViewArea from "../../components/property/propertyimageupload/ImageViewArea";
import ImageUploadArea from "../../components/property/propertyimageupload/ImageUploadArea";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import endPoints from "../../API/endPoints";

interface IAddRoomTypeSliderProps {
  isOpen: boolean;
  onClose: () => void;
  refresh: () => void;
  amenities: any[];
  amenityCategories: string[];
}

const AddNewRoomTypeSlider: React.FC<IAddRoomTypeSliderProps> = ({
  isOpen,
  onClose,
  amenities,
  amenityCategories,
  refresh,
}) => {
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { auth_token } = useSelector((state: RootState) => state.auth);

  const [tab, setTab] = useState(0);
  const steps = [
    "Room Details",
    "Amenities",
    "Occupancy",
    "Add Rates",
    "Room Images",
  ];

  const { masterRatePlanAll } = endPoints.PROPERTY_RATE_PLAN;

  const [roomTypeName, setRoomTypeName] = useState("");
  const [numOfRooms, setNumOfRooms] = useState("");
  const [shrtCode, setshrtCode] = useState("");
  const [shrtRoom, setshrtRoom] = useState("");
  const [shrtCodeHelperText, setshrtCodeHelperText] = useState("");
  const [shrtRoomHelperText, setshrtRoomHelperText] = useState("");
  const [roomtypeDescription, setRoomtypeDescription] = useState("");

  const [images, setImages] = useState<any>([]);

  const [maxAdult, setMaxAdult] = useState(0);
  const [maxChild, setMaxChild] = useState(0);
  const [baseChild, setBaseChild] = useState(0);
  const [baseAdult, setBaseAdult] = useState(0);
  const [maxOccupancy, setMaxOccupancy] = useState(0);

  const [selectedAmenities, setSelectedAmenities] = useState<any[]>([]);

  const [maxRoomPrice, setMaxRoomPrice] = useState("");
  const [minRoomPrice, setMinRoomPrice] = useState("");
  const [extraChildPrice, setExtraChildPrice] = useState("");
  const [extraAdultPrice, setExtraAdultPrice] = useState("");

  const [epPrice, setEpPrice] = useState("");
  const [cpPrice, setCpPrice] = useState("");
  const [mapPrice, setMapPrice] = useState("");
  const [apPrice, setApPrice] = useState("");
  const [mealplanId, setMealPlanId] = useState<any[]>([]);
  const [otherPlan, setOtherPlan] = useState("");
  const [othersUpdate, setOthersUpdate] = useState([""]);
  const [disableMealPlan, setDisableMealPlan] = useState(true);
  const [enableOthers, setEnableOthers] = useState(false);
  const [allRatePlans, setAllRatePlans] = useState<any[]>([]);
  const [otherRatePlans, setotherRatePlans] = useState<any[]>([]);
  // const [enableOthers, setEnableOthers] = useState(false);
  // toggleOtherRatePlans

  useEffect(() => {
    getMasterPlans();
    // getAllMasterPlan();
  }, []);

  const onChangeNumOfRooms = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setNumOfRooms(e.target.value);
    }
  };

  const shortCodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target ?? {};
    if (value.length > 4) {
      setshrtCodeHelperText("Short Code Length Could Not Be Greater Than 4");
    } else {
      setshrtCodeHelperText("");
      setshrtCode(value);
    }
  };

  const shortRoomChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target ?? {};
    if (value.includes(",") || value.includes("(") || value.includes(")")) {
      setshrtRoomHelperText(`Room Name Should Not Contain "," or "( )"`);
    } else {
      if (value && value.trim().length < 4) {
        setshrtRoomHelperText(
          "Room Type Name Could Not Be less Than 4 character"
        );
      } else {
        setshrtRoomHelperText("");
      }
    }
    setRoomTypeName(value);
  };

  const onChangeMaxRoomPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setMaxRoomPrice(e.target.value);
    }
  };

  const onChangeMinRoomPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setMinRoomPrice(e.target.value);
    }
  };

  const onChangeExtraAdultPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setExtraAdultPrice(e.target.value);
    }
  };

  const onChangeExtraChildPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setExtraChildPrice(e.target.value);
    }
  };

  // const onPlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setPlan(e.target.value);
  // };

  const onOtherPlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherPlan(e.target.value);
  };

  const onChangeEpPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setEpPrice(e.target.value);
    }
  };

  const onChangeCpPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setCpPrice(e.target.value);
    }
  };

  const onChangeMapPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setMapPrice(e.target.value);
    }
  };

  const onChangeApPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (validatePositiveInt(val) || val === "") {
      setApPrice(e.target.value);
    }
  };

  const mealPlanSelectionHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    type?: string
  ) => {
    const planIds: any[] = [...mealplanId];
    const { id, name, value } = e.target ?? {};

    planIds.includes(id)
      ? planIds.splice(planIds.indexOf(id), 1)
      : planIds.push(id);

    setMealPlanId(planIds);
  };

  const decrement = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (state > 0) {
      setState(state - 1);
    }
  };

  const increment = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setState(state + 1);
  };

  const uploadImages = () => {
    document.getElementById("image-input")?.click();
  };

  const saveImages = (event: any) => {
    let Images = [...images];
    for (let file = 0; file < event.target.files.length && file < 8; file++) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        // file size is less than 1 MB
        Images.push(image);
      } else {
        toast("Image size should be less than 1 MB", {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    setImages(Images.slice(0, 8));
  };

  const removeImage = (index: number) => {
    let img = [...images];
    img.splice(index, 1);
    setImages(img);
  };

  const onSelectAmenities = (amenity: any) => {
    if (!selectedAmenities.includes(amenity.id)) {
      setSelectedAmenities([...selectedAmenities, amenity.id]);
    } else {
      let amenities = [...selectedAmenities];
      let index = amenities.indexOf(amenity.id);
      if (index > -1) {
        amenities.splice(index, 1);
      }
      setSelectedAmenities(amenities);
    }
  };

  const next = () => {
    setTab((tab + 1) % 5);
  };

  const onNextBasicDetails = () => {
    if (
      roomTypeName.length !== 0 &&
      !shrtRoomHelperText &&
      numOfRooms &&
      shrtCode &&
      roomtypeDescription.length !== 0
    ) {
      next();
    } else if (shrtRoomHelperText) {
      return;
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const onNextAmenities = () => {
    next();
  };

  const onNextOccupancy = () => {
    if (!maxOccupancy) {
      toast("Maximum occupancy cannot be zero", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (!baseAdult) {
      toast("Base adult cannot be zero", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (!maxAdult) {
      toast("Max adult cannot be zero", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseAdult > maxOccupancy) {
      toast("Base adult cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (maxAdult > maxOccupancy) {
      toast("Max adult cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseChild > maxOccupancy) {
      toast("Base child cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (maxChild > maxOccupancy) {
      toast("Max child cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseAdult > maxAdult) {
      toast("Base adult cannot be greater than max adult", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseChild > maxChild) {
      toast("Base child cannot be greater than max child", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (maxOccupancy > maxAdult + maxChild) {
      toast(
        "Maximum occupancy cannot be greater than the sum of max adult and max child",
        {
          hideProgressBar: true,
          type: "error",
        }
      );
    } else {
      next();
      // getMealPlans();
    }
  };

  const onNextRates = () => {
    if (
      maxRoomPrice &&
      // extraAdultPrice &&
      // extraChildPrice &&
      (disableMealPlan || (epPrice && cpPrice && mapPrice && apPrice)) &&
      mealplanId.length > 0
    ) {
      next();
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const { createNewRoomTypeApi } = endPoints.PROPERTY_ROOM_TYPE;

  const createNewRoomType = async () => {
    let payload = new FormData();

    payload.append("hotel_id", current_property.hotel_id);
    payload.append("room_type", roomTypeName);
    payload.append("total_rooms", numOfRooms);
    payload.append("room_type_shortcode", shrtCode);
    payload.append("description", roomtypeDescription);
    payload.append("max_occupancy", maxOccupancy.toString());
    payload.append("max_adult", maxAdult.toString());
    payload.append("max_child", maxChild.toString());
    payload.append("base_adult", baseAdult.toString());
    payload.append("base_child", baseChild.toString());
    payload.append("max_room_price", maxRoomPrice);
    payload.append("min_room_price", minRoomPrice);
    payload.append("room_type_amenities_ids", selectedAmenities.join(","));
    payload.append("rate_plan_info", mealplanId.toString());
    payload.append("new_room_status", "1");
    images.forEach((image: any) => {
      payload.append("images[]", image);
    });

    try {
      let response = await kernelApi.post(`${createNewRoomTypeApi}`, payload);
      if (response.data.status === 1) {
        refresh();
        document.getElementById("close")?.click();
        toast("You have successfully added the data!", {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get All Rate Plan
  // const getAllMasterPlan = async () => {
  //   try {
  //     let allMasterPlanRes = await kernelApi.get(`/master-rate-plan-details`, {
  //       headers: {
  //         Authorization: "Bearer " + auth_token,
  //       },
  //     });
  //     if (allMasterPlanRes?.data?.status === 1) {
  //       const allPlans = allMasterPlanRes?.data?.data.map((iteam: any) => {
  //         return iteam.m_rate_plan;
  //       });
  //       setAllRatePlans(allPlans);
  //       // setLoader(false);
  //     }
  //   } catch (error: any) {
  //     toast.error("Something Went Wrong");
  //     // setLoader(false);
  //     if (error.response.status === 400) {
  //       Logout();
  //     }
  //   }
  // };

  // const getMealPlans = async () => {
  //   let response = await kernelApi.get(
  //     "/master_rate_plan/all/" + current_property.hotel_id,
  //     {
  //       headers: {
  //         Authorization: "Bearer " + auth_token,
  //       },
  //     }
  //   );

  // };

  // useEffect(() => {
  //   if (tab === 3) {
  //     getMealPlans();
  //   }
  // }, []);

  const addOthersPlan = () => {
    let x = [...othersUpdate];
    x.push("");

    setOthersUpdate(x);
  };

  // Get All Rate Plans
  const getMasterPlans = async () => {
    try {
      let getMasterPlansres = await kernelApi.get(
        `${masterRatePlanAll}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (getMasterPlansres?.data?.status === 1) {
        const allPlans = getMasterPlansres?.data?.data.filter((iteam: any) => {
          return iteam.plan_type !== "OTHERS";
        });
        setAllRatePlans(allPlans);

        const othrPln = getMasterPlansres?.data?.data.filter((iteam: any) => {
          return iteam.plan_type === "OTHERS";
        });
        setotherRatePlans(othrPln);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SlidingPane
        hideHeader
        width="60%"
        from="right"
        isOpen={isOpen}
        onRequestClose={onClose}
        className="sliding-pane-custom"
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i id="close" onClick={onClose} className="bi bi-x"></i>
          </div>
          <div className="standard-page--label">Add new room type</div>
        </div>

        <div className="height-64px"></div>

        <div className="new__room__type__progress__stepper">
          {steps.map((step, index) => {
            return (
              <div className="label__progress__wrapper" key={index}>
                {index < tab ? (
                  <div
                    className="progress__status status--completed"
                    onClick={() => setTab(index)}
                  >
                    <i className="bi bi-check2"></i>
                  </div>
                ) : (
                  <div
                    className={`progress__status status--${index === tab ? "in--progress" : "pending"
                      }`}
                  >
                    {index + 1}
                  </div>
                )}
                <div className="progress__status__label__text">{step}</div>
              </div>
            );
          })}
        </div>

        {/* Room type basic details tab */}
        {tab === 0 && (
          <>
            {/* <form>
              <input type="text" tabIndex={1} />
              <input type="text" tabIndex={2} />
              <button tabIndex={3} >button</button>
              <input type="text" tabIndex={-1} />
            </form> */}

            <div className="property__setup__room__details mb-0 pb-0">
              <div
                className="__default--input--field"
                style={{ marginRight: "0" }}
              >
                <InputTextField
                  label="Room Type Name"
                  value={roomTypeName}
                  handleChange={shortRoomChangeHandler}
                  helperText={shrtRoomHelperText}
                />
              </div>
            </div>

            <div
              className="tab-content__add-room__details mt-0 pt-0"
              style={{
                gap: "0",
                paddingRight: "16px",
                justifyContent: "space-between",
              }}
            >
              <div className="col-3 no_of_add-rooms_inputfield">
                <InputTextField
                  label="Number of Rooms"
                  value={numOfRooms}
                  handleChange={onChangeNumOfRooms}
                />
              </div>
              <div className="col-9 add-room-shortcode">
                <InputTextField
                  label="Room Type Shortcode"
                  value={shrtCode}
                  handleChange={shortCodeChangeHandler}
                  helperText={shrtCodeHelperText}
                />
              </div>
            </div>

            {/* Roomtype description section   */}
            <div className="property__setup__room__details mb-0 pb-0">
              <div
                className="__default--input--field"
                style={{ marginRight: "0" }}
              >
                <div className="manage__add__roomtype__description__wrapper pb-0">
                  <div className="description__label">
                    Room Type Description
                  </div>
                  <div id="editor">
                    <CKEditor
                      editor={ClassicEditor}
                      data={roomtypeDescription}
                      // data={currentRoomTypeDetails?.description}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setRoomtypeDescription(data);
                        // setCurrentRoomTypeDetails({
                        //   ...currentRoomTypeDetails,
                        //   description: data,
                        // });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>

            <div className="side__slider__panel__btn">
              <NextButton
                className={
                  !shrtRoomHelperText ? "bi bi-arrow-right" : "inactiveBtn"
                }
                handleClick={onNextBasicDetails}
              >
                Next
              </NextButton>
            </div>
          </>
        )}

        {/* Room type amenities tab */}
        {tab === 1 && (
          <>
            <div className="property__setup__amenities__details">
              <div className="amenities__list__wrapper">
                <div className="accordion" id="accordionRoomAmenities">
                  {Object.keys(amenityCategories).map(
                    (category: any, index: number) => {
                      return (
                        <div className="accordion-item" key={index}>
                          <div
                            className="accordion-header"
                            id={`accordionHeading${index}`}
                          >
                            <button
                              type="button"
                              aria-expanded="true"
                              data-bs-toggle="collapse"
                              aria-controls={`#collapse${index}`}
                              data-bs-target={`#collapse${index}`}
                              className="accordion-button collapsed accordion__custom__heading"
                            >
                              {category}
                              <span className="amenities__list__item__number">
                                {`${selectedAmenities.filter((amenity) =>
                                  amenityCategories[category].includes(
                                    amenity
                                  )
                                ).length
                                  }${selectedAmenities.filter((amenity) =>
                                    amenityCategories[category].includes(
                                      amenity
                                    )
                                  ).length === 1
                                    ? " Amenity"
                                    : " Amenities"
                                  } Selected`}
                              </span>
                            </button>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionRoomAmenities"
                            aria-labelledby={`accordionHeading${index}`}
                          >
                            <div className="accordion-body">
                              {amenities
                                .filter(
                                  (amenity: any) =>
                                    amenity.category_name === category
                                )
                                .map((amenity: any, id: number) => {
                                  return (
                                    <div
                                      className="amenities__categories__wrapper my-2"
                                      key={id}
                                    >
                                      <div className="amenities__categories__list">
                                        <div
                                          className="amenities__name"
                                          onClick={() =>
                                            onSelectAmenities(amenity)
                                          }
                                        >
                                          {amenity.label}
                                        </div>
                                        <div className="amenities__checkbox">
                                          <div className="form-check">
                                            <input
                                              type="checkbox"
                                              id={`checkbox${id}`}
                                              className="form-check-input"
                                              onChange={() =>
                                                onSelectAmenities(amenity)
                                              }
                                              checked={selectedAmenities.includes(
                                                amenity.id
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <NextButton
                className="bi bi-arrow-right"
                handleClick={onNextAmenities}
              >
                Next
              </NextButton>
            </div>
          </>
        )}

        {/* Room type occupancy tab */}
        {tab === 2 && (
          <>
            <div className="property__setup__room__type__ocuupancy">
              <div className="standard-page--label my-3">
                Maximum Number of Guests
              </div>
              <div className="increment__decrement__btn mb-4">
                <div
                  className="decrement__btn"
                  onClick={() => decrement(maxOccupancy, setMaxOccupancy)}
                >
                  <i className="bi bi-dash changing--icon"></i>
                </div>
                <div className="changing__value">{maxOccupancy}</div>
                <div
                  className="increment__btn"
                  onClick={() => increment(maxOccupancy, setMaxOccupancy)}
                >
                  <i className="bi bi-plus-lg changing--icon"></i>
                </div>
              </div>
              <div className="max__occupancy__wrapper">
                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Base Adult</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => decrement(baseAdult, setBaseAdult)}
                    >
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">{baseAdult}</div>
                    <div
                      className="increment__btn"
                      onClick={() => increment(baseAdult, setBaseAdult)}
                    >
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>

                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Max Adult</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => decrement(maxAdult, setMaxAdult)}
                    >
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">{maxAdult}</div>
                    <div
                      className="increment__btn"
                      onClick={() => increment(maxAdult, setMaxAdult)}
                    >
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>

                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Base Child</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => decrement(baseChild, setBaseChild)}
                    >
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">{baseChild}</div>
                    <div
                      className="increment__btn"
                      onClick={() => increment(baseChild, setBaseChild)}
                    >
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>

                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Max Child</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => decrement(maxChild, setMaxChild)}
                    >
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">{maxChild}</div>
                    <div
                      className="increment__btn"
                      onClick={() => increment(maxChild, setMaxChild)}
                    >
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <NextButton
                className="bi bi-arrow-right"
                handleClick={onNextOccupancy}
              >
                Next
              </NextButton>
            </div>
          </>
        )}

        {/* Room type rates tab */}
        {tab === 3 && (
          <>
            <div className="property__setup__room__types__rates">
              <div className="room__rates__input__wrapper">
                <div className="row mx-0">
                  <div className="col-md-6 ps-0">
                    <div className="room__rates__input">
                      <InputTextField
                        label="Minimum Room Price"
                        value={minRoomPrice}
                        handleChange={onChangeMinRoomPrice}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="room__rates__input">
                      <InputTextField
                        label="Maximum Room Price"
                        value={maxRoomPrice}
                        handleChange={onChangeMaxRoomPrice}
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-4 pe-0">
                    <div className="room__rates__input">
                      <InputTextField
                        label="Extra Child"
                        value={extraChildPrice}
                        handleChange={onChangeExtraChildPrice}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="add__room__type__meal__plan__wrapper">
              <div className="meal__plan__room__type">
                <div className="select_meal_label">Select Meal Plans</div>
              </div>

              <div className="select__input__label my-4">
                {allRatePlans.length > 0 &&
                  allRatePlans?.map((plans: any, idx: any) => {
                    return (
                      <div
                        className="me-4 days__label"
                        key={plans.rate_plan_id}
                      >
                        <input
                          className="form-check-input me-3"
                          type="checkbox"
                          name={plans.plan_type}
                          id={plans.rate_plan_id}
                          onChange={(e) => mealPlanSelectionHandler(e)}
                          checked={mealplanId.includes(
                            plans.rate_plan_id.toString()
                          )}
                        />
                        {plans.plan_type}
                      </div>
                    );
                  })}
                {otherRatePlans.length > 0 && (
                  <div className="me-4 days__label">
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      id="weekdayCheck"
                      checked={enableOthers}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEnableOthers(!enableOthers);
                        if (enableOthers) {
                          const planIds = [...mealplanId];
                          otherRatePlans.forEach((rate: any) => {
                            if (
                              planIds.includes(rate.rate_plan_id.toString())
                            ) {
                              planIds.splice(
                                planIds.indexOf(rate.rate_plan_id),
                                1
                              );
                            }
                          });
                          setMealPlanId(planIds);
                        }
                      }}
                    />
                    Others
                  </div>
                )}
              </div>

              {enableOthers && (
                <>
                  <div className="meal__plan__room__type">
                    <div className="select_meal_label">Other Meal Plans</div>
                  </div>

                  <div className="select__input__label my-4">
                    {otherRatePlans.length > 0 &&
                      otherRatePlans?.map((plans: any, idx: any) => (
                        <div
                          className="me-4 days__label"
                          key={plans.rate_plan_id}
                        >
                          <input
                            className="form-check-input me-3"
                            type="checkbox"
                            id={plans.rate_plan_id}
                            name={plans.plan_name}
                            onChange={(e) =>
                              mealPlanSelectionHandler(e, "others")
                            }
                            checked={mealplanId.includes(
                              plans.rate_plan_id.toString()
                            )}
                          />
                          {plans.plan_name}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <NextButton
                className="bi bi-arrow-right"
                handleClick={onNextRates}
              >
                Next
              </NextButton>
            </div>
          </>
        )}

        {/* Room type images tab */}
        {tab === 4 && (
          <>
            <div className="property__setup__room__images__wrapper">
              <div className="div__new__roomtype__image__upload">
                {images.length > 0 ? (
                  <ImageViewArea
                    upload={uploadImages}
                    images={images}
                    RemoveImage={removeImage}
                  />
                ) : (
                  <ImageUploadArea upload={uploadImages} />
                )}
              </div>
              <input
                id="image-input"
                name="image-input"
                type="file"
                accept="image/png, image/jpeg"
                className="d-none"
                onChange={saveImages}
                multiple
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <Button handleClick={createNewRoomType}>Submit</Button>
            </div>
          </>
        )}
      </SlidingPane>
    </>
  );
};

export default AddNewRoomTypeSlider;
