import React from "react";

export interface ISkipButton {
  handleClick?: () => void;
}

const SkipButton: React.FC<ISkipButton> = ({ handleClick }) => {
  return (
    <div className="skip-area" onClick={handleClick}>
      <u>Skip</u>
    </div>
  );
};

export default SkipButton;
