import React, { useState } from 'react';
// import user image
import LeftSideNav from "../../components/onboarding/LeftSidenav";
import InputTextField from "../../views/inputtextfield/InputTextField";
import StandardButton from "../../views/buttons/Button";
import { useLocation } from 'react-router';
import { validateEmail, validateMobile } from '../../UtilityFunctions';
import kernelApi from "../../API/kernelApi";
import { browserName } from "react-device-detect";
import { toast } from 'react-toastify';
import Loader from '../../views/loader/Loader';
import { useDispatch } from 'react-redux';
import { LoginSuccess, SaveSignupInfo } from '../../redux/actions/LoginAction';
import { useNavigate } from 'react-router-dom';
import endPoints from '../../API/endPoints';

interface stateType {
    email: string,
    name: string
}


const SignUpForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { email, name } = location?.state as stateType || { email: "", name: "" };
    const [userEmail, setUserEmail] = useState(email);
    const [userName, setUserName] = useState(name);
    const [emailHelperText, setEmailHelperText] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [subDomainName, setSubDomainName] = useState("");
    const [acceptTerms, setAcceptterms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        getExtranetAdminAuth
    } = endPoints.LOGIN_AUTH;

    const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(e.target.value);
        if (
            validateEmail(e.target.value) ||
            validateMobile(e.target.value) ||
            !e.target.value
        ) {
            setEmailHelperText("");
        } else {
            setEmailHelperText("Invalid email or mobile");
        }
    };

    const SignUpSubmit = async () => {
        setIsLoading(true)
        if (userEmail && userName && companyName && subDomainName && acceptTerms && !emailHelperText) {
            try {
                const payload = {
                    company_name: companyName,
                    subdomain_name: subDomainName + ".bookingjini.com",
                    user_name: userEmail,
                    creation_mode: "Google",
                    name: userName
                }

                const response = await kernelApi.post(`/company-create-from-extranet`, payload);
                if (response.data.status === 1) {
                    dispatch(SaveSignupInfo(response.data.subscription_customer_id))
                    LoginWithGoogle();
                } else {
                    setIsLoading(false)
                    toast(response.data.message, {
                        hideProgressBar: true,
                        type: "error"
                    });
                }

            } catch (error) {
                setIsLoading(false)
                console.log(error);
            }
        }
        else {
            setIsLoading(false)
            toast("Please fill all the fields", {
                hideProgressBar: true,
                type: "error"
            });
        }
    }

    const LoginWithGoogle = async () => {
        try {
            const response = await kernelApi.post(`${getExtranetAdminAuth}`, {
                email: userEmail,
                name: userName,
                creation_mode: "Google",
                browser: browserName
            });

            if (response.data.status === 1) {
                setTimeout(() => {
                    dispatch(LoginSuccess(response.data))
                    navigate("/select-property");
                }, 2000);
            } else {
                toast(response.data.message, {
                    hideProgressBar: true,
                    type: "error"
                });
                setIsLoading(false)
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }

    const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            SignUpSubmit();
        }
    };

    return (
        <>
            {isLoading ?
                <div className="div-page-wrapper">
                    <div className="div__wrapper">
                        <LeftSideNav>
                            <div className="div__user__testimonials__wrapper">
                            </div>
                        </LeftSideNav>

                        <div className="div__input__wrapper__right div__right__side__box">
                            <div className="div__inputs max--width--380px">
                                <div className="div--head--label">Creating your account..</div>
                                <div className="div--helper--text mb-3">Please wait while we are creating your account</div>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="div-page-wrapper">
                    <div className="div__wrapper">
                        <LeftSideNav>
                            <div className="div__user__testimonials__wrapper">
                                <div className="select__property__wrapper">
                                    <div className="select__property__text">Welcome {`${userName}`}</div>
                                    <div className="select__property__sub__text">We need few more details to complete your signup</div>
                                </div>
                            </div>
                        </LeftSideNav>

                        <div className="div__input__wrapper__right div__right__side__box">
                            <div className="div__inputs max--width--380px">
                                <div className="div--head--label">One last Step</div>
                                <div className="div--helper--text mb-3">
                                    Complete the final signup step
                                </div>

                                <div className="div__inputs__wrapp">
                                    <div className="div--email--input">
                                        <InputTextField
                                            value={userName}
                                            label="Full Name"
                                            onKeyPress={onKeyEnter}
                                            handleChange={(e) => { setUserName(e.target.value) }}
                                        />
                                    </div>

                                    <div className="div--email--input">
                                        <InputTextField
                                            value={userEmail}
                                            helperText={emailHelperText}
                                            label="Email Address"
                                            handleChange={EmailOnChange}
                                            onKeyPress={onKeyEnter}
                                            handleFocus={(e) => {
                                                if (!e.target.value) setEmailHelperText("");
                                            }}
                                        />
                                    </div>
                                    <div className="div--email--input">
                                        <InputTextField
                                            value={companyName}
                                            label="Company/Legal Name"
                                            onKeyPress={onKeyEnter}
                                            handleChange={(e) => { setCompanyName(e.target.value) }}
                                        />
                                    </div>

                                    <div className="div--email--input">
                                        <div className="signup__subdomain__wrapper">
                                            <div className="subdomain__input__wrapper">
                                                <InputTextField
                                                    label="Subdomain"
                                                    value={subDomainName}
                                                    onKeyPress={onKeyEnter}
                                                    handleChange={(e) => { setSubDomainName(e.target.value) }}
                                                />
                                            </div>
                                            <div className="signup__subdomain__label"><span>.bookingjini.com</span></div>
                                        </div>
                                    </div>

                                    <div className="terms__policy__wrapper mb-4">
                                        <div className="terms__policy__checkbox me-2" onClick={() => setAcceptterms(!acceptTerms)}>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" checked={acceptTerms} />
                                            </div>
                                        </div>
                                        <div className="terms__condition__label">
                                            <text onClick={() => setAcceptterms(!acceptTerms)}> I accept the </text>
                                            <span>
                                                <a className="dev--helper--signin-sign-up" href="https://bookingjini.com/terms-conditions" target="_blank"> Terms and Conditions </a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="__btn__wrapper text-end">
                                        <StandardButton className="login--btn" handleClick={SignUpSubmit}>Signup</StandardButton>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SignUpForm;