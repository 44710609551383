import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import beApi from "../../../../API/beApi";
import endPoints from "../../../../API/endPoints";
import { RootState } from "../../../../redux/store";
import { CATCH_MESSAGE } from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import Card from "../../../../views/cardwrapper/Card";
import ColorPicker from "../../../../views/colorpicker/ColorPicker";
import Loader from "../../../../views/loader/Loader";

const ThemeSettings = () => {
  // Data From Redux
  const { admin_id, auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [backgroundClr, setBackgroundClr] = useState<any>("");
  const [textClr, setTextClr] = useState<any>("");
  const [themeClr, setThemeClr] = useState<any>("");
  const [access, setAccess] = useState(true);

  //Endpoints Import
  const { themeSetup, themeSetupUpdate } = endPoints.MANAGECHANNEL
  // --------------------------------

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  // Theme Data Get
  const getThemeData = async () => {
    setLoader(true);
    try {
      const themeDataRes = await beApi.get(`${themeSetup}/${company_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (themeDataRes.data.status === 1) {
        setBackgroundClr(themeDataRes.data.data.be_header_color);
        setTextClr(themeDataRes.data.data.be_header_color_text);
        setThemeClr(themeDataRes.data.data.be_theme_color);
        setLoader(false);
      } else {
        toast(`${themeDataRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    getThemeData();
  }, []);

  // Theme Data Post
  const themeSaveHandler = async () => {
    setLoader(true);
    try {
      let postRes = await beApi.post(
        themeSetupUpdate,
        {
          be_theme_color: themeClr,
          be_header_color: backgroundClr,
          be_header_color_text: textClr,
          company_id: company_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (postRes.data.status === 1) {
        toast(postRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getThemeData();
        setLoader(false);
      } else {
        toast(`${postRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  return (
    <>
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="col-6" style={{ width: "49%" }}>
              <label htmlFor="header" className="clr-label">
                Header
              </label>
              <Card className="d-flex flex-row ">
                <div className="col-6 ">
                  <div className="clr-titel">Background</div>
                  <ColorPicker
                    icon="bi bi-pencil-square"
                    id="cp-1"
                    className="clr-pickr"
                    value={backgroundClr}
                    handleChange={(e) => {
                      setBackgroundClr(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6 ">
                  <div className="clr-titel">Text</div>
                  <ColorPicker
                    icon="bi bi-pencil-square"
                    id="cp-2"
                    className="clr-pickr"
                    value={textClr}
                    handleChange={(e) => {
                      setTextClr(e.target.value);
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-6" style={{ width: "49%" }}>
              <label htmlFor="header" className="clr-label">
                Theme
              </label>
              <Card className="d-flex flex-row">
                <div className="col-12 ">
                  <div className="clr-titel">Color</div>
                  <ColorPicker
                    icon="bi bi-pencil-square"
                    id="cp-3"
                    className="clr-pickr"
                    value={themeClr}
                    handleChange={(e) => {
                      setThemeClr(e.target.value);
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
          <div className="save-btn mt-4 pt-2">
            <Button
              handleClick={() => {
                themeSaveHandler();
              }}
              loader={loader}
              isAccessible={access}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ThemeSettings;
