import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import endPoints from "../../../../API/endPoints";
import kernelApi from "../../../../API/kernelApi";
import { RootState } from "../../../../redux/store";
import {
  CATCH_MESSAGE,
  validatePositiveInt,
} from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
export interface IEditAddonChargesProps {
  onClose: () => void;
  getAdditionalCharges: () => void;
  editId: any;
}

const EditAddonCharges: React.FC<IEditAddonChargesProps> = ({
  onClose,
  getAdditionalCharges,
  editId,
}) => {
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { selectCharges, updateCharges } = endPoints.MANAGECHANNEL;
  const [loader, setLoader] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [percentage, setPercentage] = useState<string>("");
  const [percentageHelperText, setPercentageHelperText] = useState<string>("");
  const [textPercentage, setTextPercentage] = useState<string>("");
  const [textpercentageHelperText, setTextPercentageHelperText] =
    useState<string>("");

  useEffect(() => {
    getSpecificAddonDetails();
  }, []);

  // Api To Get Specific ADDON Details
  const getSpecificAddonDetails = async () => {
    try {
      let addonSpecificDetails = await kernelApi.get(
        `${selectCharges}/${editId}`
      );

      if (addonSpecificDetails.data.status === 1) {
        setName(addonSpecificDetails.data.Data.add_on_charges_name);
        setPercentage(addonSpecificDetails.data.Data.add_on_charges_percentage);
        setTextPercentage(addonSpecificDetails.data.Data.add_on_tax_percentage);
      } else {
        setName("");
        setPercentage("");
        setTextPercentage("");
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };
  //   Api To Update Addon
  const updateAddonSaveHanlder = async () => {
    setLoader(true);

    try {
      let updateRes = await kernelApi.post(`${updateCharges}/${editId}`, {
        hotel_id: current_property.hotel_id ?? "",
        add_on_charges_name: name ?? " ",
        add_on_charges_percentage: percentage,
        add_on_tax_percentage: textPercentage,
      });

      if (updateRes.data.status === 1) {
        toast.success(updateRes.data.message);
        setLoader(false);
        getAdditionalCharges();
        onClose();
      } else {
        toast.error(updateRes.data.message);
        setLoader(false);
        getAdditionalCharges();
        onClose();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };
  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit Additional Charges</div>
      </div>
      <div className="height-64px"></div>
      <div className="slider-content-wrapper">
        <div className="mb-1">
          <InputTextField
            label={"Name"}
            value={name}
            handleChange={(e) => {
              setName(e.target.value);
            }}
            placeholder={"Enter Name"}
          />
        </div>

        <div className="mb-1">
          <InputTextField
            label={"Percentage"}
            value={percentage}
            helperText={percentageHelperText}
            adoreMentIcon={"bi bi-percent"}
            type={"number"}
            placeholder={"Type Number"}
            handleChange={(e) => {
              const { value } = e.target ?? {};
              if (validatePositiveInt(value) && parseInt(value) <= 100) {
                setPercentageHelperText("");
                setPercentage(value);
              } else {
                setPercentageHelperText("Value Should be Between 0 to 100");
                setPercentage(value);
              }
            }}
          />
        </div>
        <div className="mb-1">
          <InputTextField
            label={"Tax Percentage"}
            value={textPercentage}
            type={"number"}
            helperText={textpercentageHelperText}
            placeholder={"Type Number"}
            adoreMentIcon={"bi bi-percent"}
            handleChange={(e) => {
              const { value } = e.target ?? {};
              if (validatePositiveInt(value) && parseInt(value) <= 100) {
                setTextPercentageHelperText("");
                setTextPercentage(value);
              } else {
                setTextPercentageHelperText("Value Should be Between 0 to 100");
                setTextPercentage(value);
              }
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <Button
          handleClick={() => {
            updateAddonSaveHanlder();
          }}
          loader={loader}
        >
          Save
        </Button>
        <Button className="cancel__btn " handleClick={onClose}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default EditAddonCharges;
