import React, { useEffect, useState } from "react";
import moment from "moment";

import Button from "../../views/buttons/Button";

// import date picker
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

import beApi from "../../API/beApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import cmApi from "../../API/cmApi";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";
import kernelApi from "../../API/kernelApi";

interface IUnblockUpdateSlider {
  ratesInfo: any[];
  roomTypeId: string;
  ratePlans: any[];
  loaderBE: boolean;
  setLoaderBE: React.Dispatch<React.SetStateAction<boolean>>;
  loaderCM: boolean;
  setLoaderCM: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  blockableOTAs: any[];
  refreshRates: () => void;
  allRoomTypes: { ids: string[]; labels: string[] };
}

const BlockUpdate: React.FC<IUnblockUpdateSlider> = ({
  ratesInfo,
  refreshRates,
  roomTypeId,
  ratePlans,
  blockableOTAs,
  loaderBE,
  loaderCM,
  setLoaderBE,
  setLoaderCM,
  allRoomTypes,
  onClose,
}) => {
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { ratePlansOfRoom } = endPoints.RATE;

  const [ratePlanSpecficRoom, setRatePlanSpecficRoom] = useState<any[]>([]);
  const UpdateTypes = ["Range", "Individual"];

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [singleDate, setSingleDate] = useState<any>(null);
  const [singleDates, setSingleDates] = useState<any[]>([]);

  const [activeType, setActiveType] = useState(UpdateTypes[0]);
  const [activeMealPlans, setActiveMealPlans] = useState<any[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
  const {
    rateUnblockSpecificDate,
    rateUnblockRangeDateBe,
    rateUnblockRangeDateCm,
  } = endPoints.RATE;

  const [errorBE, setErrorBE] = useState<any>();
  const [errorsOTA, setErrorsOTA] = useState<any[]>([]);

  useEffect(() => {
    getRatePlansOfRoomTypes();
  }, []);

  const getRatePlansOfRoomTypes = async () => {
    try {
      let ratePlanRes = await kernelApi.get(
        `${ratePlansOfRoom}/${current_property.hotel_id}/${roomTypeId}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (ratePlanRes?.data?.status === 1) {
        setRatePlanSpecficRoom(ratePlanRes?.data?.data);
      } else {
        setRatePlanSpecficRoom([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  const onDateChange = (date: any) => {
    let dateList = [...singleDates];
    let dateValue = moment(date).format("YYYY-MM-DD");

    if (dateValue !== "Invalid date" && !dateList.includes(dateValue)) {
      dateList.push(dateValue);
    }
    setSingleDate(moment(date));
    setSingleDates(dateList);
  };

  const removeDate = (date: any) => {
    let dateList = [...singleDates];
    let dateValue = moment(date).format("YYYY-MM-DD");
    dateList.splice(dateList.indexOf(dateValue), 1);
    setSingleDates(dateList);
  };

  const onTypeChange = (type: string) => {
    setActiveType(type);
    setSelectedChannels([]);
    setActiveMealPlans([]);
    setErrorBE(null);
    setErrorsOTA([]);
  };

  const refresh = () => {
    document.getElementById("refresh-button")?.click();
  };

  const onSelectMealPlans = (planId: any) => {
    let mealPlans = [...activeMealPlans];
    if (mealPlans.includes(planId)) {
      mealPlans.splice(mealPlans.indexOf(planId), 1);
    } else {
      mealPlans.push(planId);
    }
    setActiveMealPlans(mealPlans);
  };

  const onSelectChannel = (id: string) => {
    let channels = [...selectedChannels];
    if (channels.includes(id.toString())) {
      channels = channels.filter((channel) => channel !== id.toString());
    } else {
      channels.push(id.toString());
    }
    setSelectedChannels(channels);
  };

  const onSelectAllChannels = (e: any) => {
    let channels: any[] = [];
    if (e.target.checked) {
      ratesInfo.forEach((ota) => {
        if (
          blockableOTAs.some(
            (data: any) => data.ota_id.toString() === ota.ota_id
          ) ||
          ota.ota_id === "-1"
        ) {
          channels.push(ota.ota_id.toString());
        }
      });
    }
    setSelectedChannels(channels);
  };

  const singleDateUnblockBE = async () => {
    setLoaderBE(true);
    let blockInv: any[] = [];
    singleDates.forEach((date) => {
      activeMealPlans.forEach((mealPlanId: string) => {
        blockInv.push({
          date: date,
          block_status: 0,
          rate_plan_id: mealPlanId,
          plan_name: ratePlans.filter(
            (rate: any) => rate.rate_plan_id === mealPlanId
          )[0].plan_type,
        });
      });
    });

    try {
      const response = await beApi.post(
        `${rateUnblockSpecificDate}`,
        {
          hotel_id: current_property.hotel_id,
          admin_id: admin_id,
          room_type_id: roomTypeId,
          ota_id: [-1],
          inv: blockInv,
          room_type: allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      setErrorBE(response.data);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    setLoaderBE(false);
    if (!loaderCM) {
      refresh();
    }
  };

  const singleDateUnblockOTA = async () => {
    setLoaderCM(true);
    let blockOTAInv: any[] = [];
    selectedChannels.forEach((channelId: string) => {
      if (channelId !== "-1") {
        let planInv: any[] = [];
        singleDates.forEach((date: string) => {
          activeMealPlans.forEach((planId: string) => {
            planInv.push({
              date: date,
              block_status: 0,
              rate_plan_id: planId,
              plan_type: ratePlans.filter(
                (rate: any) => rate.rate_plan_id === planId
              )[0].plan_type,
            });
          });
        });

        blockOTAInv.push({
          ota_id: parseInt(channelId),
          channel_name: ratesInfo.filter(
            (ota: any) => ota.ota_id.toString() === channelId
          )[0].ota_name,
          rate: planInv,
        });
      }
    });

    try {
      const response = await cmApi.post(
        `${rateUnblockSpecificDate}`,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: roomTypeId,
          room_type: allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
          rate: blockOTAInv,
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      let errors = [...errorsOTA];
      response.data.forEach((res: any) => {
        errors.push(res);
      });
      setErrorsOTA(errors);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    setLoaderCM(false);
    if (!loaderBE) {
      refresh();
    }
  };

  const dateRangeUnblockBE = async () => {
    setLoaderBE(true);
    try {
      const response = await beApi.post(
        `${rateUnblockRangeDateBe}`,
        {
          hotel_id: current_property.hotel_id,
          rate_plan_id: activeMealPlans,
          date_from: moment(startDate).format("DD-MM-YYYY"),
          date_to: moment(endDate).format("DD-MM-YYYY"),
          room_type_id: roomTypeId,
          admin_id: admin_id,
          block_status: 0,
          ota_id: [-1],
          los: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      setErrorBE(response.data);
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
    setLoaderBE(false);
    if (!loaderCM) {
      refresh();
    }
  };

  const dateRangeUnblockOTA = async () => {
    setLoaderCM(true);
    try {
      let rate_plan_names: string[] = [];
      ratePlans.forEach((plan: any) => {
        if (activeMealPlans.includes(plan.rate_plan_id)) {
          rate_plan_names.push(plan.plan_type);
        }
      });

      const response = await cmApi.post(
        `${rateUnblockRangeDateCm}`,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: roomTypeId,
          rate_plan_id: activeMealPlans,
          rate_plan_type: rate_plan_names,
          date_from: moment(startDate).format("DD-MM-YYYY"),
          date_to: moment(endDate).format("DD-MM-YYYY"),
          los: 1,
          block_status: 0,
          admin_id: admin_id,
          ota_id: selectedChannels
            .filter((channel: string) => channel !== "-1")
            .map(Number),
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      let errors = [...errorsOTA];
      response.data.forEach((res: any) => {
        errors.push(res);
      });
      setErrorsOTA(errors);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    setLoaderCM(false);
    if (!loaderBE) {
      refresh();
    }
  };

  const onUnblockUpdate = () => {
    setErrorBE(null);
    setErrorsOTA([]);
    if (
      activeMealPlans.length > 0 &&
      selectedChannels.length > 0 &&
      ((activeType === "Individual" && singleDates.length > 0) ||
        (activeType === "Range" && startDate && endDate))
    ) {
      let channels = [...selectedChannels];
      if (channels.includes("-1")) {
        activeType === "Range" ? dateRangeUnblockBE() : singleDateUnblockBE();
      }
      if (channels.filter((channel) => channel !== "-1").length > 0) {
        activeType === "Range" ? dateRangeUnblockOTA() : singleDateUnblockOTA();
      }
    } else {
      toast.error("Please fill all the fields");
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">
          Unblock{" "}
          {roomTypeId
            ? "(" +
            allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)] +
            ")"
            : ""}
        </div>
      </div>
      <div className="height-64px"></div>

      <div className="rates__update__wrapper">
        <div className="standard-page--label mb-4">Date Type</div>
        <div className="select__input__label">
          {UpdateTypes.map((type: string, i: number) => (
            <div
              className={`me-4 select__input__div${activeType === type ? "__active" : ""
                }`}
              key={i}
              onClick={() => onTypeChange(type)}
            >
              {activeType === type && (
                <i className="bi bi-check-lg icon--check"></i>
              )}
              {type}
            </div>
          ))}
        </div>
        {activeType === "Range" ? (
          <div
            className="inventory__select__date__wrapper my-4"
            onClick={() => setFocusedInput("startDate")}
          >
            <div className="date__picker__icon">
              <i className="bi bi-calendar"></i>
            </div>
            <div className="date__label">
              {!startDate && !endDate
                ? "Select Date Range"
                : (startDate ? startDate.format("DD MMM, YYYY") : "") +
                " - " +
                (endDate ? endDate.format("DD MMM, YYYY") : "")}
            </div>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              preventPastDates={true}
              focusedInput={focusedInput}
              setFocusedInput={setFocusedInput}
            />
          </div>
        ) : (
          <div className="my-4">
            <div className="individual__rate__update">
              <div className="individual__rate__updates__input mui__input__fieldset__legends__unset">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={singleDate}
                    minDate={new Date()}
                    onChange={onDateChange}
                    inputFormat="dd MMM yyyy"
                    toolbarPlaceholder="Select Date"
                    InputAdornmentProps={{ position: "start" }}
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={() => setOpen(true)}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="individual__dates__wrapper">
              {singleDates?.map((date: any) => {
                return (
                  <div className="individual_dates" key={date}>
                    {moment(date).format("DD MMM YYYY")}
                    <i
                      className="bi bi-x icon--close"
                      onClick={() => removeDate(date)}
                    ></i>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="standard-page--label mb-4">Select Meal Plan</div>
        <div className="select__input__label">
          {ratePlanSpecficRoom?.map((plan: any, i: number) => (
            <div
              key={i}
              onClick={() => onSelectMealPlans(plan.rate_plan_id)}
              className={`me-4 mb-3 select__input__div${activeMealPlans.includes(plan.rate_plan_id) ? "__active" : ""
                } `}
            >
              {activeMealPlans.includes(plan.rate_plan_id) && (
                <i className="bi bi-check-lg icon--check"></i>
              )}
              {plan.plan_type}
            </div>
          ))}
        </div>

        <div className="rates__channel__wrapper mt-3 pt-2">
          <div className="d-flex">
            <div className="standard-page--label mb-4 pt-3">
              Select Channels
            </div>
            <div className="input__position pt-3">
              <input
                type="checkbox"
                id="allChannels"
                onChange={onSelectAllChannels}
                className="form-check-input custom-size me-3"
                checked={selectedChannels.length === blockableOTAs.length + 1}
              />
            </div>
          </div>

          {ratesInfo.map((ota: any, i: number) => {
            if (ota.ota_id === -1) {
              let Status = errorBE?.status === 0 ? true : false;
              let ErrorLength = errorBE ? true : false;
              return (
                <React.Fragment key={i}>
                  {Status && (
                    <div className="ota_error_message">
                      {errorBE?.response_msg}
                    </div>
                  )}
                  <div
                    onClick={() =>
                      document.getElementById(`beCheck${i}`)?.click()
                    }
                    className={`channel__wrapper ${Status
                        ? "error--border"
                        : selectedChannels.includes("-1") && ErrorLength
                          ? "success--border"
                          : ""
                      } mb-4`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="channel__image__content">
                        <img src={ota.logo} alt="" />
                      </div>
                      {!Status &&
                        ErrorLength &&
                        selectedChannels.includes("-1") && (
                          <div className="success__checkmark">
                            <i className="bi bi-check"></i>
                          </div>
                        )}
                    </div>
                    <div className="channel__select__checkbox">
                      <div className="form-check">
                        <input
                          className="form-check-input unclickable"
                          type="checkbox"
                          id={`beCheck${i}`}
                          onChange={() => onSelectChannel(ota.ota_id)}
                          checked={selectedChannels.includes(
                            ota.ota_id.toString()
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            } else if (
              blockableOTAs?.some((data: any) => data.ota_id === ota.ota_id)
            ) {
              let Status = errorsOTA?.some(
                (error: any) =>
                  error.ota_name?.toLowerCase() ===
                  ota.ota_name?.toLowerCase() && error.status === 0
              );
              let ErrorLength = errorsOTA.length ? true : false;
              return (
                <React.Fragment key={i}>
                  {Status && (
                    <div className="ota_error_message">
                      {
                        errorsOTA.filter(
                          (error: any) =>
                            error.ota_name?.toLowerCase() ===
                            ota.ota_name?.toLowerCase()
                        )[0]?.response_msg
                      }
                    </div>
                  )}
                  <div
                    onClick={() =>
                      document.getElementById(`selectChannels${i}`)?.click()
                    }
                    className={`channel__wrapper ${Status
                        ? "error--border"
                        : selectedChannels?.includes(ota.ota_id.toString()) &&
                          ErrorLength
                          ? "success--border"
                          : ""
                      } mb-4`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="channel__image__content">
                        <img src={ota.logo} alt="" />
                      </div>
                      {!Status &&
                        ErrorLength &&
                        selectedChannels.includes(ota.ota_id.toString()) && (
                          <div className="success__checkmark">
                            <i className="bi bi-check"></i>
                          </div>
                        )}
                    </div>
                    <div className="channel__select__checkbox">
                      <div className="form-check">
                        <input
                          className="form-check-input unclickable"
                          type="checkbox"
                          id={`selectChannels${i}`}
                          onChange={() => onSelectChannel(ota.ota_id)}
                          checked={selectedChannels.includes(
                            ota.ota_id.toString()
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>

      <div id="refresh-button" className="d-none" onClick={refreshRates}></div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-3">
          <Button className="cancel__btn me-3" handleClick={onClose}>
            Cancel
          </Button>
          {!loaderBE && !loaderCM ? (
            <Button handleClick={onUnblockUpdate}>Save</Button>
          ) : (
            <>
              <Button>
                Save{" "}
                <span
                  className="spinner-border spinner-border-sm pd-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              </Button>
              <div className="full--page--overlay"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlockUpdate;
