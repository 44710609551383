import { SAVE_PROPERTY_NEW_EMAILS } from "../actionTypes"

const initialState: any = []

const SavePropertyNewMailReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_PROPERTY_NEW_EMAILS:
            return [...action.payload]
        default: return state
    }
}

export default SavePropertyNewMailReducer;