import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import {
  CATCH_MESSAGE,
  Logout,
  UpdateSidebar,
} from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useNavigate } from "react-router-dom";
import kernelApi from "../../../../API/kernelApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Loader from "../../../../views/loader/Loader";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import endPoints from "../../../../API/endPoints";

const NearestPlaces = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  UpdateSidebar("Manage Channels", " Booking Engine");
  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [nearestTouristPlace, setnearestTouristPlace] = useState("");
  const [impTouristPlace, setimpTouristPlace] = useState("");
  const [access, setAccess] = useState(true);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  //Endpoints Import
  const { getHotelOtherInfoApi, otherInfoUpdate } = endPoints.MANAGECHANNEL
  // --------------------------------

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const navigate = useNavigate();

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  const getHotelInformation = async () => {
    setLoader(true);
    try {
      let locationRes = await kernelApi.get(
        `${getHotelOtherInfoApi}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (locationRes.data.status === 1) {
        setnearestTouristPlace(
          locationRes.data.otherInfo.nearest_tourist_places
        );
        setimpTouristPlace(locationRes.data.otherInfo.tour_info);
        setLoader(false);
      } else {
        toast(`${locationRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  useEffect(() => {
    getHotelInformation();
  }, []);

  const nearestPlaceSaveHandler = async () => {
    setLoader(true);
    try {
      let saveRes = await kernelApi.post(
        otherInfoUpdate,
        {
          hotel_id: current_property.hotel_id,
          airport_name: [],
          distance_from_air: [],
          rail_station_name: [],
          distance_from_rail: [],
          bus_station_name: [],
          distance_from_bus: [],
          nearest_tourist_places: nearestTouristPlace ?? "",
          tour_info: impTouristPlace ?? "",
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (saveRes.data.status === 1) {
        toast(saveRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getHotelInformation();
        setLoader(false);
      } else {
        toast(`${saveRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <>
      <div className="property__breadcrumbs mb-4 nearest__places__header__wrapper">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Places" },
          ]}
        />
        <div className="places__back__btn">
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>

      <div className="hr--line"></div>

      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <div className="nearest__places__content__wrapper">
          <div className="nearest__places__card">
            <ul
              className="nav nav-tabs mb-3"
              id="editNearbyPlaces"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text active"
                  id="nearest-tourist-place-tabs"
                  data-bs-toggle="tab"
                  data-bs-target="#nearest-tourist-place"
                  type="button"
                  role="tab"
                  aria-controls="nearest-tourist-place"
                  aria-selected="true"
                >
                  Nearest Tourist Place
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text"
                  id="important-tourist-place-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#important-tourist-place"
                  type="button"
                  role="tab"
                  aria-controls="important-tourist-place"
                  aria-selected="false"
                >
                  Important Tourist Place
                </button>
              </li>
            </ul>

            <div className="tab-content mt-4" id="editNearbyPlacesContent">
              <div
                className="tab-pane fade show active"
                id="nearest-tourist-place"
                role="tabpanel"
                aria-labelledby="nearest-tourist-place-tabs"
              >
                <div className="nearest__places__tab">
                  <CKEditor
                    editor={ClassicEditor}
                    data={nearestTouristPlace}
                    config={{
                      removePlugins: [
                        "EasyImage",
                        "ImageUpload",
                        "MediaEmbed",
                        "Table",
                      ],
                    }}
                    onChange={(event: string, editor: any) => {
                      const data = editor.getData();
                      setnearestTouristPlace(data);
                      setCharCount({
                        ...charCount,
                        terms_cond: removeTags(data).length,
                      });
                    }}
                  />
                </div>
              </div>

              <div
                className="tab-pane fade show"
                id="important-tourist-place"
                role="tabpanel"
                aria-labelledby="important-tourist-place-tabs"
              >
                <div className="nearest__places__tab">
                  <CKEditor
                    editor={ClassicEditor}
                    data={impTouristPlace}
                    config={{
                      removePlugins: [
                        "EasyImage",
                        "ImageUpload",
                        "MediaEmbed",
                        "Table",
                      ],
                    }}
                    onChange={(event: string, editor: any) => {
                      const data = editor.getData();
                      setimpTouristPlace(data);
                      setCharCount({
                        ...charCount,
                        terms_cond: removeTags(data).length,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="save__btn__nearest__place pt-2 mt-4 mb-3">
            <Button
              className="save__btn"
              // icon="bi bi-arrow-left"
              handleClick={() => {
                nearestPlaceSaveHandler();
              }}
              loader={loader}
              isAccessible={access}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default NearestPlaces;
