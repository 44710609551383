import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import { store } from "../../redux/store";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { NewPropertySubType } from "../../redux/actions/AddPropertyAction";

import Loader from "../../views/loader/Loader";
import GetHelp from "../../components/gethelp/GetHelp";
import NextButton from "../../views/buttons/NextButton";
import BackButton from "../../views/buttons/Button";

import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import Button from "../../views/buttons/Button";
import { Logout } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

const ChoosePropertySubType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const propertyType = useSelector(
    (state: RootState) => state.add_property.new_property_type
  );
  const [propertySubType, setPropertySubType] = useState(
    store.getState().add_property.new_property_subtype
  );
  const [isLoading, setIsLoading] = useState(true);

  const [allPropertySubTypes, setAllPropertySubTypes] = useState<any[]>([]);
  const [numOfPages, setNumOfPages] = useState(1);
  const [slidePage, setSlidePage] = useState(0);

  const size = 4;

  //Endpoints Import
  const { getAllPropertiesSubtypes, addNewPropertyPropDetails, addNewPropertyType } = endPoints.ADDNEWPROPERTY
  // --------------------------------

  useEffect(() => {
    if (propertyType) {
      GetPropertySubTypeList();
    } else {
      navigate("/add-new-property");
    }
  }, []);

  const GetPropertySubTypeList = async () => {
    let response = await kernelApi.get(
      getAllPropertiesSubtypes + propertyType
    );

    if (response.data.status === 1) {
      let filteredData = response.data.all_property_sub_types.filter(
        (item: any) => item.is_status === 1
      );
      setAllPropertySubTypes(filteredData);
      setNumOfPages(Math.ceil(filteredData.length / size));
    }
    setIsLoading(false);
  };

  const OnSubTypeSelect = (subtype: string) => {
    setPropertySubType(subtype);
  };

  const LeftClick = () => {
    if (slidePage > 0) {
      setSlidePage(slidePage - 1);
    }
  };

  const RightClick = () => {
    if (slidePage < numOfPages - 1) {
      setSlidePage(slidePage + 1);
    }
  };

  const SubmitPropertySubType = () => {
    if (propertySubType) {
      dispatch(NewPropertySubType(propertySubType));
      navigate(addNewPropertyPropDetails);
    } else {
      toast("Please select the property subtype", {
        hideProgressBar: true,
        type: "error"
      });
    }
  };
  const BackButtonClick = () => {
    navigate(addNewPropertyType);
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <div className="div__input__wrapper__left div__left__side__box">
          <div className="logo__wrapper"></div>
          <div className="div__user__testimonials__wrapper">
            <div className="select__property__wrapper">
              <div className="select__property__text">
                Which of these best describes your place?
              </div>
              <div className="select__property__sub__text">
                You can save your progress and continue at any time
              </div>
            </div>
          </div>

          <div className="progress-bar-wrapper">
            <div className="progress-bar-text">1/5</div>
            <div className="progress-bar-status">
              <ProgressBar percent={20} filledBackground="#ffffff" />
            </div>
          </div>
        </div>

        <div className="div__input__wrapper__right div__right__side__box">
          <div className="div__inputs">
            <div className="div--head--label">Select Property Sub Type</div>
            <div className="div--helper--text">
              Now it’s time to setup up your first property
            </div>

            {numOfPages > 1 && (
              <div className="arrow-width">
                <div className="arrow-main-box">
                  <div className="arrow-wrapper">
                    <div className="arrow-left" onClick={LeftClick}>
                      <i className="gg-arrow-left"></i>
                    </div>
                    <div className="arrow-right" onClick={RightClick}>
                      <i className="gg-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isLoading ? (
              <Loader className="mt-3" />
            ) : (
              <>
                <div className="select__property__type">
                  {allPropertySubTypes
                    .slice(slidePage * size, size * (slidePage + 1))
                    .map((item, index) => {
                      return (
                        <div
                          className={`select__indivisual__property ${propertySubType === item.property_subtype_id
                            ? " active__selected__property"
                            : ""
                            }`}
                          onClick={() =>
                            OnSubTypeSelect(item.property_subtype_id)
                          }
                          key={index}
                        >
                          <div className="property__type__name">
                            {item.label_name}
                          </div>
                          <div className="property__type__description">
                            {item.description}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="__btn__wrapper mt-4">
                  <BackButton
                    className="logout__btn"
                    handleClick={() => {
                      Logout();
                    }}
                  >
                    logout
                  </BackButton>
                  <Button className="back__btn" handleClick={BackButtonClick}>
                    back
                  </Button>
                  <NextButton handleClick={SubmitPropertySubType} />
                </div>
              </>
            )}
            <GetHelp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePropertySubType;
