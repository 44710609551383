import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import { Logout, UpdateSidebar } from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import { CreateRipple } from "../../../../views/buttons/NextButton";
import ThemeSettings from "./ThemeSettings";
import Logo from "./Logo";
import VosaSetting from "./VosaSetting";
import ManageURLsSettings from "./ManageURLsSettings";
import OtherSettings from "./OtherSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import kernelApi from "../../../../API/kernelApi";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import Banner from "./Banner";

const BasicSetup = () => {
  const { be_url } = useSelector(
    (state: RootState) => state.beUrl
  );



  const EditRoomTypeTabs = [
    {
      id: "thm-setng-tabs",
      target: "thm-setng",
      label: "Theme",
    },
    {
      id: "logo-tab",
      target: "logo-upload",
      label: "Logo",
    },
    {
      id: "banner-tab",
      target: "banners-upload",
      label: "Banner",
    },
    {
      id: "mng-urls-tab",
      target: "mng-urls",
      label: "URL"
    },
    {
      id: "other-setting-tab",
      target: "other-setting",
      label: "Other",
    },
  ];



  UpdateSidebar("Manage Channels", "Booking Engine");

  const navigate = useNavigate();

  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs content={[{ label: "Manage Channels", href: "/manage-channels" }, { label: "Booking Engine", href: "/manage-channels/booking-engine" }, { label: "Basic Setup" }]} />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <div className="profile-manage-wrapper">
            <a href={be_url} target='_blank'>
              <div className="preview-btn" onClick={CreateRipple}>
                <i className="bi bi-play hotel__building__icon"></i>
                <div className="hotel--name--header">Preview BE</div>
              </div>
            </a>
          </div>

          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {/* Setting Tabs */}

      <div className="manage__channels__basic__setup__wrappers">
        <div className="basic__setup__tabs">
          <ul className="nav nav-tabs mb-3" id="editRoomType" role="tablist">
            {EditRoomTypeTabs.map(
              (tab: typeof EditRoomTypeTabs[0], index: number) => {
                return (
                  <li className="nav-item" role="presentation" key={index}>
                    <button
                      className={`nav-link tab--link--text ${index === 0 ? "active" : ""
                        }`}
                      id={tab.id}
                      data-bs-toggle="tab"
                      data-bs-target={`#${tab.target}`}
                      type="button"
                      role="tab"
                      aria-controls={tab.target}
                      aria-selected="true"
                    >
                      {tab.label}
                    </button>
                  </li>
                );
              }
            )}
          </ul>

          <div className="tab-content mt-4" id="editNearbyPlacesContent">
            <div
              className="tab-pane fade show active"
              id="thm-setng"
              role="tabpanel"
              aria-labelledby="thm-setng-tabs"
            >
              <ThemeSettings />
            </div>

            <div
              id="logo-upload"
              role="tabpanel"
              className="tab-pane fade"
              aria-labelledby="logo-tab"
            >
              <Logo />
            </div>

            <div
              id="banners-upload"
              role="tabpanel"
              className="tab-pane fade"
              aria-labelledby="banners-tab"
            >
              <Banner />
            </div>


            <div
              id="mng-urls"
              role="tabpanel"
              className="tab-pane fade"
              aria-labelledby="mng-urls-tab"
            >
              <ManageURLsSettings />
            </div>
            <div
              id="other-setting"
              role="tabpanel"
              className="tab-pane fade +"
              aria-labelledby="other-setting-tab"
            >
              <OtherSettings />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default BasicSetup;
