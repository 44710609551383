import React from 'react';
import Button from "../../../views/buttons/Button";


export interface IDisplayDerivedPlanSlider {
    onClose: () => void;
    derivedPlanData: any[];

}

const DerivedPlanSlider: React.FC<IDisplayDerivedPlanSlider> = ({
    onClose,
    derivedPlanData
}) => {

    return (
        <>
            <div className="derived__plan__formula__wrapper">
                <div className="side__slider__header__label__wrapper">
                    <div className="side__slider__close" >
                        <i className="bi bi-x" onClick={onClose}></i>
                    </div>
                    <div className="standard-page--label">Derived Plan Formula</div>
                </div>
                <div className="height-64px"></div>

                <div className="derive__plan__table__wrapper">
                    <table className="table table-borderless">
                        <thead>
                            <tr className="listview__table__heading">
                                <th><div className="listview__table__items">Sl.no.</div></th>
                                <th><div className="listview__table__items">Bar Price</div></th>
                                <th><div className="listview__table__items">Multiple Occupency</div></th>
                                <th><div className="listview__table__items">Extra Adult</div></th>
                                <th><div className="listview__table__items">Extra Child</div></th>
                                <th><div className="listview__table__items">Derived from (room type (plan type))</div></th>
                                <th><div className="listview__table__items">Date of Creation</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {derivedPlanData.length > 0 && derivedPlanData.map((items: any, i: any) => {
                                return (
                                    <tr className="listview__table__data" key={i}>
                                        <td>
                                            <div className="serial-number">{i + 1}</div>
                                        </td>
                                        {items.select_type === 'amount' && <td>{items.bar_price}</td>}
                                        {items.select_type !== 'amount' && <td>{items.bar_price}%</td>}
                                        {items.select_type === 'amount' && <td>{items.multiple_occupancy}</td>}
                                        {items.select_type !== 'amount' && <td>{items.multiple_occupancy}%</td>}
                                        {items.extra_adult_select_type === 'amount' && <td>{items.extra_adult_amount}</td>}
                                        {items.extra_adult_select_type !== 'amount' && <td>{items.extra_adult_amount}%</td>}
                                        {items.extra_child_select_type === 'amount' && <td>{items.extra_child_amount}</td>}
                                        {items.extra_child_select_type !== 'amount' && <td>{items.extra_child_amount}%</td>}
                                        <td>Derived from {items.room_type}({items.plan_type})</td>
                                        <td>{items.created_at}</td>
                                    </tr>)
                            })}


                        </tbody>
                    </table>
                </div>

                <div
                    style={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "white",
                    }}
                ></div>

                <div className="side__slider__panel__btn">
                    <div className="button__wrapper ">
                        <Button handleClick={onClose}>Close</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DerivedPlanSlider;