import React from "react";

export interface IAmenityCard {
  icon: string;
  label: string;
  aminitesAccepted: () => void;
  active?: boolean;
}

const AmenityCard: React.FC<IAmenityCard> = ({
  icon,
  label,
  aminitesAccepted,
  active,
}) => {
  return (
    <>
      <div
        className={`div__amenity__wrap center__align__amenities ${
          active ? "active_class" : ""
        }`}
        onClick={aminitesAccepted}>
        <div>
          <i className={`${icon} bootstrap-icon`}></i>
        </div>
        <div className="aminity__font">{label}</div>
      </div>
    </>
  );
};

export default AmenityCard;
