import {
  LOGIN_SUCCESS,
  LOGIN_CREDENTAILS,
  SAVE_SUBSCRPTION_PLAN,
  SAVE_SIGNUP_INFO,
  GET_CLIENT_SECTION_DATA,
  GET_CHARGEBEE_SUBSCRIPTION_ID,
  GET_SUBSCRIPTION_DAYS_LEFT,
  SINGLE_SIGN_ON
} from "../actionTypes";

export const LoginSuccess = (auth: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload: auth,
  };
};

export const LoginCredentails = (auth: any) => {
  return {
    type: LOGIN_CREDENTAILS,
    payload: auth,
  };
};

export const SaveSignupInfo = (data: any) => {
  return {
    type: SAVE_SIGNUP_INFO,
    payload: data,
  };
};


export const SaveClientSectionData = (data: any) => {
  return {
    type: GET_CLIENT_SECTION_DATA,
    payload: data,
  }
};

export const ChargebeeSubscriptionId = (data: any) => {
  return {
    type: GET_CHARGEBEE_SUBSCRIPTION_ID,
    payload: data,
  }
}

export const SubscriptionDaysLeft = (data: any) => {
  return {
    type: GET_SUBSCRIPTION_DAYS_LEFT,
    payload: data,
  }
}

export const handleSingleSignOn = (data: any) => {
  return {
    type: SINGLE_SIGN_ON,
    payload: data
  }
}
