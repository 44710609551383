import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import { RootState } from "../../redux/store";
import Button from "../../views/buttons/Button";
import InputPasswordField from "../../views/inputtextfield/InputPasswordField";

export interface IpasswordChange {
  onClose: () => void;
}

const PasswordChangeSlider: React.FC<IpasswordChange> = ({ onClose }) => {
  const { company_id, auth_token} = useSelector(
    (state: RootState) => state.auth
  );
  const [password, setPassword] = useState({
    curPassword: "",
    newPassword: "",
    conPassword: "",
  });

  const submitPassword = async () => {
    if (password.curPassword && password.newPassword && password.conPassword) {
      let res = await kernelApi.post(
        `/admin/check_password_admin`,
        {
          password: password.curPassword,
          company: company_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );
      if (res.data.status === 1) {
        if (password.newPassword === password.conPassword) {
          let response = await kernelApi.post(
            `/admin/change_password`,
            {
              password: password.newPassword,
              company_id: company_id,
            },
            {
              headers: {
                Authorization: `Bearer ${auth_token}`,
              },
            }
          );
          if (response.data.status === 1) {
            toast.success(response.data.message);
          }
        }
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
    } else {
      toast.error("Kindly fill all the password fields");
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Change Password</div>
      </div>
      <div className="height-64px"></div>

      <div className="p-3">
        <InputPasswordField
          label="Current Password"
          value={password.curPassword}
          handleChange={(e) => {
            setPassword({ ...password, curPassword: e.target.value });
          }}
        />
        <InputPasswordField
          label="New Password"
          value={password.newPassword}
          handleChange={(e) => {
            setPassword({ ...password, newPassword: e.target.value });
          }}
        />
        <InputPasswordField
          label="Confirm New Password"
          value={password.conPassword}
          handleChange={(e) => {
            setPassword({ ...password, conPassword: e.target.value });
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-3">
          <Button handleClick={submitPassword}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default PasswordChangeSlider;
