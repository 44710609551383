import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import {
  browsePDF,
  Capitalize,
  CATCH_MESSAGE,
  getImage,
  UpdateSidebar,
} from "../../UtilityFunctions";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// import button
import Button from "../../views/buttons/Button";
// import InputTextField from '../../views/inputtextfield/InputTextField';
import { toast } from "react-toastify";
import gemsApi from "../../API/gemsApi";
import moment from "moment";
import cmApi from "../../API/cmApi";
import StayDetailsAddBills from "./StayDetailsAddBills";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import endPoints from "../../API/endPoints";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";

// import Ripples from "react-ripples";

// import input field
// import InputTextField from "../../views/inputtextfield/InputTextField";
// import InputSelectField from "../../views/inputtextfield/InputSelectField";

interface StayDetailsProps {
  room_id: string;
}

const StayDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { room_id } = location?.state as StayDetailsProps;

  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { stay_details } = useSelector((state: RootState) => state.bookings);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  UpdateSidebar("Bookings", "Stay Details");

  const dispatch = useDispatch();

  const BILL_TYPES = ["Dues", "Collections", "Payment"];

  // const [openChangeRoomSlider, setopenChangeRoomSlider] = useState(false);
  // const [openExtendStaySlider, setopenExtendStaySlider] = useState(false);
  // const [openUpgradeRoomSlider, setopenUpgradeRoomSlider] = useState(false);
  // const [openUpgradeMealPlanSlider, setopenUpgradeMealPlanSlider] = useState(false);

  const { checkOutInFontoffice, checkOutCRS, billDownload } =
    endPoints.BOOKINGS;
  const [guestIndex, setguestIndex] = useState(0);
  const [billType, setbillType] = useState(BILL_TYPES[0]);
  const [openViewDoc, setopenViewDoc] = useState(false);
  const [openViewImage, setopenViewImage] = useState(false);
  const [openAddBillSlider, setopenAddBillSlider] = useState(false);

  useEffect(() => {
    if (!room_id) navigate("/bookings/frontoffice-view");
  }, []);

  const submitCheckout = async () => {
    if (stay_details?.billing_data?.summary?.balance === 0) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure to checkout?`,
          "DELETE",
          async () => {
            try {
              let payload = {
                user_id: admin_id,
                hotel_id: current_property.hotel_id,
                booking_id: stay_details?.booking_data?.booking_id,
                room_id: room_id,
              };

              const response = await gemsApi.post(
                `${checkOutInFontoffice}`,
                payload
              );

              if (response.data.status === 1) {
                toast.success(response.data.messagea);
                submitCheckoutCRS();
              } else {
                toast.error(response.data.messagea);
              }
            } catch (error) {
              toast.error(CATCH_MESSAGE);
            }
            dispatch(CancelConfirmationPrompt());
          },
          ["No", "Yes"]
        )
      );
    } else {
      toast.error("Billing Clearance Pending Can't be Checkedout");
    }
  };

  const submitCheckoutCRS = async () => {
    try {
      let payload = {
        booking_id: stay_details?.booking_data?.booking_id,
        checkin_date: moment(stay_details?.booking_data?.check_in).format(
          "DD-MM-YYYY"
        ),
        checkout_date: moment(stay_details?.booking_data?.check_out).format(
          "DD-MM-YYYY"
        ),
        room_no: stay_details?.room_data?.room_no,
      };

      const response = await cmApi.post(`${checkOutCRS}`, payload, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });

      if (response.data.status === 1) {
        setTimeout(() => {
          navigate("/bookings/gems/frontoffice-view");
        }, 300);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  const downloadBill = async () => {
    try {
      let payload = {
        hotel_id: current_property.hotel_id,
        booking_id: stay_details?.booking_data?.booking_id,
        room_id: room_id,
        room_no: stay_details?.room_data?.room_no,
      };

      const response = await gemsApi.post(`${billDownload}`, payload);

      if (response.data.status === 1) {
        const booking_id = stay_details?.booking_data?.booking_id;
        const room_no = stay_details?.room_data?.room_no;
        const fileName = `bill_${room_no}_${booking_id}.pdf`;

        browsePDF(response.data.content, fileName);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to download bill");
    }
  };

  const onPrevArrowClick = () => {
    setopenViewDoc(false);
    setopenViewImage(false);
    if (guestIndex > 0) {
      setguestIndex(guestIndex - 1);
    }
  };

  const onNextArrowClick = () => {
    setopenViewDoc(false);
    setopenViewImage(false);
    if (guestIndex < stay_details?.guest_data.length - 1) {
      setguestIndex(guestIndex + 1);
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <div className="stay__screen__header__action__wrapper">
          <CustomBreadcrumbs
            content={[
              { label: "Bookings", href: "/bookings/gems/frontoffice-view" },
              { label: `Room - ${stay_details?.room_data?.room_no}` },
            ]}
          />
          <div className="stay__header__action__box">
            {/* <div className="change__room__wrapper me-3" onClick={() => setopenChangeRoomSlider(true)}>
                            <Button>Change Room</Button>
                        </div>
                        <div className="extend__stay__wrapper me-3" onClick={() => setopenExtendStaySlider(true)}>
                            <Button>Extend Stay</Button>
                        </div> */}
            {/* <div className="upgrade__room__wrapper me-3" onClick={() => setopenUpgradeRoomSlider(true)}> */}
            {/* <div className="upgrade__room__wrapper me-3" onClick={() => setopenUpgradeMealPlanSlider(true)}>
                            <InputSelectField
                                label="Upgrade"
                                options={["Meal", "Room Type"]}
                                value={""}
                                handleChange={() => { }}
                            />
                        </div> */}
            <div className="early__room__wrapper">
              <Button handleClick={submitCheckout}>
                {moment(stay_details?.booking_data?.check_out).isAfter(moment())
                  ? "Early"
                  : ""}{" "}
                Checkout
              </Button>
              <Button
                className="back__btn_stay_details"
                icon="bi bi-arrow-left"
                handleClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="hr--line"></div>

      <div className="room__stay__guest__details__wrapper">
        <div className="room__stay__guest__details__left__box sm-r">
          <div className="room__stay__checkin__checkout">
            <div className="check__in__out__date my-4">
              <div className="checkin__checkout__wrapper">
                <div className="checkinout__content">
                  <div className="check__inout__details check-in-border">
                    <div className="check__inout__icon check-in-color">
                      <i className="bi bi-arrow-down-right-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-in-color">
                        {stay_details?.booking_data?.check_in}
                      </div>
                      <div className="check__inout__label">Check-in</div>
                    </div>
                  </div>
                </div>

                <div className="number__of__nights__wrapper">
                  <div className="night__icon">
                    <i className="bi bi-moon-fill"></i>
                  </div>
                  <div className="night__number">
                    {stay_details?.booking_data?.no_of_nights}
                  </div>
                </div>

                <div className="checkinout__content">
                  <div className="check__inout__details check-out-border">
                    <div className="check__inout__icon check-out-color">
                      <i className="bi bi-arrow-up-left-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-out-color">
                        {stay_details?.booking_data?.check_out}
                      </div>
                      <div className="check__inout__label">Check-out</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="guest__details__navigation mb-3">
            <div className="standard-page--label my-3">Guest Details</div>
            {stay_details?.guest_data?.length > 1 && (
              <div className="navigation__arrow">
                <div
                  className={`left__arrow me-3 ${
                    guestIndex > 0 ? "active__nav" : ""
                  }`}
                  onClick={onPrevArrowClick}
                >
                  <i className="bi bi-arrow-left"></i>
                </div>
                <div
                  className={`right__arrow ${
                    guestIndex < stay_details?.guest_data.length - 1
                      ? "active__nav"
                      : ""
                  }`}
                  onClick={onNextArrowClick}
                >
                  <i className="bi bi-arrow-right"></i>
                </div>
              </div>
            )}
          </div>

          <div className="guest__details__card">
            <div className="guest__name">
              {stay_details?.guest_data[guestIndex]?.name}
            </div>
            <div className="guest__email__contact__details mb-2 mt-2">
              <div className="guest__number">
                <i className="bi bi-telephone me-2"></i>{" "}
                {stay_details?.guest_data[guestIndex]?.mobile}
              </div>
              <div className="guest__email__id">
                <i className="bi bi-envelope me-2"></i>{" "}
                {stay_details?.guest_data[guestIndex]?.email}
              </div>
            </div>

            <div className="guest__gender__age__wrapper mb-3">
              <div className="gender">
                <i className="bi bi-gender-male me-2"></i>
                {Capitalize(stay_details?.guest_data[guestIndex]?.gender)}
              </div>
              <div className="age">
                <i className="bi bi-balloon me-2"></i>
                {stay_details?.guest_data[guestIndex]?.age}
              </div>
            </div>

            <div className="guest__document__and__image__wrapper">
              <div
                className="upload__document me-3"
                onClick={() => setopenViewDoc(true)}
              >
                <i className="bi bi-file-earmark-arrow-up me-2"></i> View ID
                Proof
              </div>
              <div
                className="upload__image me-3"
                onClick={() => setopenViewImage(true)}
              >
                <i className="bi bi-file-earmark-arrow-up me-2"></i> View Image
              </div>
            </div>
          </div>

          <div className="standard-page--label my-4">Room Details</div>

          <div className="allocated__guest__room__wrapper">
            <div className="guest__room__image">
              <img
                src={stay_details?.room_data?.room_image}
                alt={stay_details?.room_data?.room_type_name}
              />
              <div className="room__number">
                {stay_details?.room_data?.room_no}
              </div>
            </div>

            <div className="room__detail__wrapper">
              <div className="room__name__with__meal__plan">
                <div className="room__name">
                  <i className="bi bi-door-open me-2"></i>
                  {stay_details?.room_data?.room_type_name}
                </div>
                <div className="mela__plan__name">
                  <i className="bi bi-cup-straw me-1"></i>
                  {stay_details?.room_data?.plan_type}
                </div>
              </div>

              <div className="room__price">
                <i className="bi bi-cash-stack me-2"></i>{" "}
                {stay_details?.room_data?.room_rate}
              </div>
            </div>
          </div>

          <div className="standard-page--label my-4">Final Price Breakup</div>

          <div className="guest__price__breakup mb-5">
            <div className="price__wrapper mb-3">
              <div className="price__label">Total</div>
              <div className="price">
                {stay_details?.billing_data?.summary?.transaction_amount}
              </div>
            </div>

            <div className="price__wrapper mb-3">
              <div className="price__label">GST</div>
              <div className="price">
                {stay_details?.billing_data?.summary?.tax_amount}
              </div>
            </div>

            <div className="price__wrapper mb-3">
              <div className="price__label">Discount</div>
              <div className="price">
                {stay_details?.billing_data?.summary?.discount_amount}
              </div>
            </div>

            <div className="hr--line"></div>

            <div className="price__wrapper mb-3">
              <div className="price__label price__bold__text">Receivable</div>
              <div className="price">
                {stay_details?.billing_data?.summary?.receivable}
              </div>
            </div>

            <div className="price__wrapper">
              <div className="price__label price__bold__text">Received</div>
              <div className="price">
                {stay_details?.billing_data?.summary?.received}
              </div>
            </div>
          </div>
        </div>

        <div className="room__stay__guest__details__right__box">
          <div className="guest__room__billing__details my-4">
            <div className="guest__billing__header__action">
              <div className="standard-page--label">Latest Bills</div>
              <div className="bill__print__btn__wrapper">
                <div
                  className="share__bill__wrapper me-3"
                  onClick={downloadBill}
                >
                  <Button>
                    <i className="bi bi-receipt me-2"></i>Download Bill
                  </Button>
                </div>
                <div
                  className="add__bill_wrapper"
                  onClick={() => setopenAddBillSlider(true)}
                >
                  <Button>
                    <i className="bi bi-plus me-2"></i> Add Bill
                  </Button>
                </div>
              </div>
            </div>

            <div className="bill__due__collection__wrapper mt-2">
              <div className="latest__bill__tab my-4">
                <ul className="nav nav-tabs" id="duesTab" role="tablist">
                  {BILL_TYPES.map((type) => {
                    return (
                      <li
                        key={type}
                        className="nav-item"
                        role="presentation"
                        onClick={() => setbillType(type)}
                      >
                        <a
                          className={`nav-link${
                            type === billType ? " active" : ""
                          }`}
                        >
                          {type}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="bill__price__breakup__wrapper">
                {billType === "Dues" &&
                  stay_details?.billing_data?.expenses?.map(
                    (due: any, idx: number) => {
                      return (
                        <React.Fragment key={due.id}>
                          <div className="bill__price__breakup">
                            <div className="bill__price__details">
                              <div className="bill__price__date mb-1">
                                {due.transaction_date}
                              </div>
                              <div className="bill__room__type__details">
                                {due.remark}
                              </div>
                            </div>

                            <div className="bill__price">{due.amount}</div>
                          </div>

                          {idx !==
                            stay_details?.billing_data?.expenses.length - 1 && (
                            <div className="hr--line"></div>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}

                {billType === "Collections" &&
                  stay_details?.billing_data?.collections?.map(
                    (collection: any, idx: number) => {
                      return (
                        <React.Fragment key={collection.id}>
                          <div className="bill__price__breakup">
                            <div className="bill__price__details">
                              <div className="bill__price__date mb-1">
                                {collection.transaction_date}
                              </div>
                              <div className="bill__room__type__details">
                                {collection.remark}
                              </div>
                            </div>

                            <div className="bill__price">
                              {collection.amount}
                            </div>
                          </div>

                          {idx !==
                            stay_details?.billing_data?.collections.length -
                              1 && <div className="hr--line"></div>}
                        </React.Fragment>
                      );
                    }
                  )}

                {billType === "Payment" &&
                  stay_details?.billing_data?.payments?.map(
                    (payments: any, idx: number) => {
                      return (
                        <React.Fragment key={payments.id}>
                          <div className="bill__price__breakup">
                            <div className="bill__price__details">
                              <div className="bill__price__date mb-1">
                                {payments.transaction_date}
                              </div>
                              <div className="bill__room__type__details">
                                {payments.remark}
                              </div>
                            </div>

                            <div className="bill__price">{payments.amount}</div>
                          </div>

                          {idx !==
                            stay_details?.billing_data?.payments.length - 1 && (
                            <div className="hr--line"></div>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
          <div className="guest__room__billing__details my-4">
            <div className={`stay_details_balance`}>
              <div>Balance:</div>
              <div
                className={`${
                  Math.sign(stay_details?.billing_data?.summary?.balance) === -1
                    ? "negative"
                    : Math.sign(
                        stay_details?.billing_data?.summary?.balance
                      ) === 0
                    ? "neutral"
                    : "positive"
                }`}
              >
                {stay_details?.billing_data?.summary?.balance}
              </div>
            </div>
          </div>
        </div>
      </div>

      {stay_details?.guest_data[guestIndex]?.doc ? (
        <SlidingPane
          hideHeader
          from="right"
          width="45%"
          isOpen={openViewDoc}
          closeIcon={<i className="bi bi-x-lg"></i>}
          onRequestClose={() => setopenViewDoc(false)}
        >
          <div className="side__slider__header__label__wrapper">
            <div className="side__slider__close">
              <i onClick={() => setopenViewDoc(false)} className="bi bi-x"></i>
            </div>
            <div className="standard-page--label">View ID Proof</div>
          </div>

          <div className="height-64px"></div>
          <div className="view__doc__div">
            <img
              src={getImage(stay_details?.guest_data[guestIndex]?.doc)}
              alt=""
            />
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          >
            {" "}
          </div>

          <div className="side__slider__panel__btn">
            <div className="__btn__wrapper justify-content-between w-100">
              <Button
                className="primary-btn delete__btn__wrapper"
                handleClick={() => setopenViewDoc(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </SlidingPane>
      ) : (
        <></>
      )}

      {stay_details?.guest_data[guestIndex]?.photo ? (
        <SlidingPane
          hideHeader
          from="right"
          width="45%"
          isOpen={openViewImage}
          closeIcon={<i className="bi bi-x-lg"></i>}
          onRequestClose={() => setopenViewImage(false)}
        >
          <div className="side__slider__header__label__wrapper">
            <div className="side__slider__close">
              <i
                onClick={() => setopenViewImage(false)}
                className="bi bi-x"
              ></i>
            </div>
            <div className="standard-page--label">View Image</div>
          </div>

          <div className="height-64px"></div>

          <div className="view__image__div">
            {/* <img src={`https://cdn.pixabay.com/photo/2013/07/18/20/26/sea-164989_1280.jpg`} alt="" /> */}
            <img
              src={getImage(stay_details?.guest_data[guestIndex]?.photo)}
              alt=""
            />
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          >
            {" "}
          </div>

          <div className="side__slider__panel__btn">
            <div className="__btn__wrapper justify-content-between w-100">
              <Button
                className="primary-btn delete__btn__wrapper"
                handleClick={() => setopenViewImage(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </SlidingPane>
      ) : (
        <></>
      )}

      {/* Slider for add bill details */}
      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openAddBillSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenAddBillSlider(false)}
      >
        <StayDetailsAddBills
          room_id={room_id}
          onClose={() => setopenAddBillSlider(false)}
          billTyp={billType}
          balance={stay_details?.billing_data?.summary?.balance}
        />
      </SlidingPane>

      {/* Change Room Slider */}
      {/* <SlidingPane
                hideHeader
                from="right"
                width="45%"
                isOpen={openChangeRoomSlider}
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setopenChangeRoomSlider(false)}
            >
                <div className="side__slider__header__label__wrapper">
                    <div className="side__slider__close">
                        <i
                            onClick={() => setopenChangeRoomSlider(false)}
                            className="bi bi-x"
                        ></i>
                    </div>
                    <div className="standard-page--label">Change Room</div>
                </div>
                <div className="height-64px"></div>

                <div className="guest__change__room__request__wrapper">
                    <div className="select__roon__floor__wrapper">
                        <div className="floor__wise__room__select">
                            <div className="floor__number mb-4">Floor 1</div>

                            <div className="floor__number__wrapper mb-3">
                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">102</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">103</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">210</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">211</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">212</div>
                                    </Ripples>
                                </div>
                            </div>

                            <div className="floor__number mb-4">Floor 2</div>

                            <div className="floor__number__wrapper mb-3">
                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">222</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">333</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number">444</div>
                                    </Ripples>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SlidingPane> */}

      {/* Extend stay */}
      {/* <SlidingPane
                hideHeader
                from="right"
                width="45%"
                isOpen={openExtendStaySlider}
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setopenExtendStaySlider(false)}
            >
                <div className="side__slider__header__label__wrapper">
                    <div className="side__slider__close">
                        <i
                            onClick={() => setopenExtendStaySlider(false)}
                            className="bi bi-x"
                        ></i>
                    </div>
                    <div className="standard-page--label">Extend Stay</div>
                </div>
                <div className="height-64px"></div>

                <div className="guest__extend__stay__slider__wrapper">
                    <div className="check__in__out__date my-4">
                        <div className="modify">Current Check-in/out Date</div>
                        <div className="checkin__checkout__wrapper mt-3">
                            <div className="checkinout__content">
                                <div className="check__inout__details check-in-border">
                                    <div className="check__inout__icon check-in-color">
                                        <i className="bi bi-arrow-down-right-circle"></i>
                                    </div>
                                    <div className="check__inout__date">
                                        <div className="check__inout check-in-color">20 Oct 2022</div>
                                        <div className="check__inout__label">Check-in</div>
                                    </div>
                                </div>
                            </div>

                            <div className="number__of__nights__wrapper">
                                <div className="night__icon"><i className="bi bi-moon-fill"></i></div>
                                <div className="night__number">2</div>
                            </div>

                            <div className="checkinout__content">
                                <div className="check__inout__details check-out-border">
                                    <div className="check__inout__icon check-out-color">
                                        <i className="bi bi-arrow-up-left-circle"></i>
                                    </div>
                                    <div className="check__inout__date">
                                        <div className="check__inout check-out-color">20 Oct 2022</div>
                                        <div className="check__inout__label">Check-out</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="standard-page--label my-4">Choose New Check-out Date</div>

                    <div className="choose__new__checkin__checkout__wrapper mb-3">
                        <div className="date__range__picker__icon"><i className="bi bi-calendar"></i></div>
                        <div className="date__range__label">02 Aug 22</div>
                    </div>

                    <div className="standard-page--label mb-4">You are going to extend the stay for 2 Nights</div>

                    <div className="guest__details__price__breakup__extend__stay mb-4">
                        <div className="standard-page--label mb-3">Price Breakup  [Deluxe Room]</div>
                        <div className="extend__stay__price mb-3">
                            <div className="extend__room__label">Room Rate</div>
                            <div className="extend__room__price">12000</div>
                        </div>

                        <div className="extend__stay__price">
                            <div className="extend__room__label">GST</div>
                            <div className="extend__room__price">1400</div>
                        </div>

                        <div className="hr--line"></div>

                        <div className="extend__stay__price">
                            <div className="extend__room__label">Total</div>
                            <div className="extend__room__price">2333</div>
                        </div>
                    </div>

                    <div className="final__selling__rate__wrapper mt-4">
                        <InputTextField
                            label="Final Selling Rate"
                            value={"4444"}
                            handleChange={() => { }}
                        />
                    </div>

                    <div className="including__tax__wrapper">
                        <div className="standard-page--label">Including Tax</div>
                        <div className="form-check form-switch custom-switch check--box--width">
                            <input
                                className="form-check-input"
                                type="checkbox"
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "white",
                        }}></div>
                    <div className="side__slider__panel__btn">
                        <div className="__btn__wrapper justify-content-between w-100">
                            <Button className="primary-btn">
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </SlidingPane> */}

      {/* Upgrade room slider */}
      {/* <SlidingPane
                hideHeader
                from="right"
                width="45%"
                isOpen={openUpgradeRoomSlider}
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setopenUpgradeRoomSlider(false)}
            >
                <div className="side__slider__header__label__wrapper">
                    <div className="side__slider__close">
                        <i
                            onClick={() => setopenUpgradeRoomSlider(false)}
                            className="bi bi-x"
                        ></i>
                    </div>
                    <div className="standard-page--label">Upgrade Room</div>
                </div>

                <div className="height-64px"></div>

                <div className="upgrade__room__slider__wrapper">
                    <div className="standard-page--label my-4">Current Room Details</div>
                    <div className="current__selcted__room__detail__wrapper">
                        <div className="guest__room__image">
                            <img src={RoomImage} alt="Deluxe Room type image" />
                            <div className="room__number">202</div>
                        </div>

                        <div className="room__detail__wrapper">
                            <div className="room__name__with__meal__plan">
                                <div className="room__name"><i className="bi bi-door-open me-2"></i>Semi Deluxe Room</div>
                                <div className="mela__plan__name"><i className="bi bi-cup-straw me-1"></i>EP</div>
                            </div>

                            <div className="room__price"><i className="bi bi-cash-stack me-2"></i> 12000</div>
                        </div>
                    </div>

                    <div className="standard-page--label my-4">Select New Room Type</div>

                    <div className="select__input__label">
                        <div className="me-4 mb-3 select__input__div__active">
                            <i className="bi bi-check-lg icon--check"></i>
                            Small Room
                        </div>
                        <div className="me-4 mb-3 select__input__div">Deluxe Room</div>
                        <div className="me-4 mb-3 select__input__div">Maharani Room</div>
                    </div>

                    <div className="standard-page--label my-4">Select New Meal Plan</div>

                    <div className="select__input__label">
                        <div className="me-4 mb-3 select__input__div__active">
                            <i className="bi bi-check-lg icon--check"></i>
                            EP
                        </div>
                        <div className="me-4 mb-3 select__input__div">CP</div>
                        <div className="me-4 mb-3 select__input__div">MAP</div>
                        <div className="me-4 mb-3 select__input__div">AP</div>
                    </div>

                    <div className="standard-page--label my-4">Choose Room</div>

                    <div className="upgrade__room__floor__selection">
                        <div className="floor__wise__room__select">
                            <div className="floor__number mb-4">Floor 1</div>

                            <div className="floor__number__wrapper mb-3">
                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number default__floor__state">102</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number default__floor__state">103</div>
                                    </Ripples>
                                </div>

                                <div className="floor__number__box mb-3 me-4">
                                    <Ripples>
                                        <div className="floor__number  active__floor__selected">210</div>
                                    </Ripples>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="upgrade__room__rate__wrappers my-4">
                        <div className="upgrade__room__rate__label">Room Rate</div>
                        <div className="upgrade__room__rate">12000</div>
                    </div>

                    <div className="final__selling__rate__wrapper pt-4">
                        <InputTextField
                            label="Final Selling Rate"
                            value={"4444"}
                            handleChange={() => { }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "white",
                    }}>
                </div>
                <div className="side__slider__panel__btn">
                    <div className="__btn__wrapper justify-content-between w-100">
                        <Button className="primary-btn">
                            Save
                        </Button>
                    </div>
                </div>
            </SlidingPane> */}

      {/* Upgrade Meal Plan */}
      {/* <SlidingPane
                hideHeader
                from="right"
                width="45%"
                isOpen={openUpgradeMealPlanSlider}
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setopenUpgradeMealPlanSlider(false)}
            >
                <div className="side__slider__header__label__wrapper">
                    <div className="side__slider__close">
                        <i
                            onClick={() => setopenUpgradeMealPlanSlider(false)}
                            className="bi bi-x"
                        ></i>
                    </div>
                    <div className="standard-page--label">Upgrade Meal Plan</div>
                </div>

                <div className="height-64px"></div>

                <div className="guest__upgrade__meal__plan__slider">
                    <div className="guest__current__meal__plan my-4">
                        <div className="standard-page--label mb-2">Current Room Type and Meal Plan</div>
                        <div className="room__type__meal__plan">
                            <div className="room__type me-3"><i className="bi bi-door-open me-1"></i> Deluxe Room</div>
                            <div className="meal__plan"><i className="bi bi-cup-straw"></i> CP</div>
                        </div>
                    </div>

                    <div className="upgrdae__meal__plan__bookind__details">
                        <div className="meal__plan__cards me-3">
                            <div className="meal__plans__label mb-2">Booked Nights</div>
                            <div className="meal__plan__status__wrapper">
                                <div className="status__number">4</div>
                                <div className="status__icon"><i className="bi bi-moon-fill"></i></div>
                            </div>
                        </div>

                        <div className="meal__plan__cards me-3">
                            <div className="meal__plans__label mb-2">Completed Nights</div>
                            <div className="meal__plan__status__wrapper">
                                <div className="status__number">1</div>
                                <div className="status__icon"><i className="bi bi-check"></i></div>
                            </div>
                        </div>

                        <div className="meal__plan__cards">
                            <div className="meal__plans__label mb-2">Upgradable Nights</div>
                            <div className="meal__plan__status__wrapper">
                                <div className="status__number">3</div>
                                <div className="status__icon"><i className="bi bi-arrow-up"></i></div>
                            </div>
                        </div>
                    </div>

                    <div className="standard-page--label my-4">Upgradeable Plans</div>

                    <div className="select__input__label mb-3">
                        <div className="me-4 mb-3 select__input__div__active">
                            <i className="bi bi-check-lg icon--check"></i>
                            CP
                        </div>
                        <div className="me-4 mb-3 select__input__div">MAP</div>
                        <div className="me-4 mb-3 select__input__div">AP</div>
                    </div>

                    <div className="additional__amount__wrapper mb-3">
                        <InputTextField
                            label="Additional Amount"
                            value={"222"}
                            handleChange={() => { }}
                        />
                    </div>

                    <div className="additional__amount__wrapper mb-3">
                        <InputTextField
                            label="Tax Amount"
                            value={"10"}
                            handleChange={() => { }}
                        />
                    </div>

                    <div className="upgrade__meal__plan__wrappers mb-4">
                        <div className="upgrade__meal__plan__label">Total</div>
                        <div className="upgrade__meal__plan">12000</div>
                    </div>

                    <div className="upgrade__meal__plan__remark__wrapper pt-3">
                        <InputTextField
                            label="Remarks"
                            value={""}
                            multiline
                            rows={1}
                        />
                    </div>
                </div>

                <div
                    style={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "white",
                    }}>
                </div>
                <div className="side__slider__panel__btn">
                    <div className="__btn__wrapper justify-content-between w-100">
                        <Button className="primary-btn">
                            Save
                        </Button>
                    </div>
                </div>
            </SlidingPane> */}
    </>
  );
};

export default StayDetails;
