import React, { useState, useEffect } from 'react';
import LeftSideNav from '../../components/onboarding/LeftSidenav';
import kernelApi from "../../API/kernelApi";
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSuccess, SaveSignupInfo, SaveClientSectionData } from '../../redux/actions/LoginAction';
import { SaveUserFullName } from '../../redux/actions/UserAcessAction';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import endPoints from '../../API/endPoints';
import { browserName } from 'react-device-detect';
import InputTextField from '../../views/inputtextfield/InputTextField';
import { validateFullName, validateMobile } from '../../UtilityFunctions';
import StandardButton from "../../views/buttons/Button";
import gemsApi from '../../API/gemsApi';
import { toast } from "react-toastify";
import Countdown from 'react-countdown';

const SignUpValidation = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    window.handleCredentialResponse = handleCredentialResponse;

    const { client_section }: any = useSelector((state: RootState) => state.auth);
    const { chargebeeEnvironment } = useSelector((state: RootState) => state.userAcess);

    const [userName, setUserName] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [fullNameHelperText, setFullNameHelperText] = useState("");
    const [mobileHelperText, setMobileHelperText] = useState("");
    const [otpHelperText, setOTPHelperText] = useState("");
    const [otpValidation, setOTPValidation] = useState(false);
    const [otpNumber, setOTPNumber] = useState("");
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [showResendOtp, setShowResendOtp] = useState(false);
    const [showOTPTimer, setShowOTPTimer] = useState(false);

    const MobileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserMobile(e.target.value);
        if (
            validateMobile(e.target.value) ||
            !e.target.value
        ) {
            setMobileHelperText("");
        } else {
            setMobileHelperText("Invalid mobile number");
        }
    };

    const FullNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value);
        if (
            validateFullName(e.target.value) ||
            !e.target.value
        ) {
            setFullNameHelperText("");
        } else {
            setFullNameHelperText("Please enter a valid name");
        }
    }

    const {
        getExtranetAdminAuth
    } = endPoints.LOGIN_AUTH;

    function handleCredentialResponse(response: any) {
        const responsePayload: any = jwt_decode(response.credential);
        LoginWithGoogle(responsePayload);
    }

    const LoginWithGoogle = async (payload: any) => {
        try {
            const response = await kernelApi.post(`${getExtranetAdminAuth}`, {
                email: payload.email,
                name: payload.name,
                creation_mode: "Google",
                browser: browserName,
            });

            if (response.data.status === 1) {
                dispatch(LoginSuccess(response.data));
                dispatch(SaveSignupInfo(response.data.subscription_customer_id))
            } else {
                navigate("/signup-form", {
                    state: { email: payload.email, name: payload.name },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const SignUpValidation = async () => {
        if (userName && userMobile) {
            if (userMobile.length === 10) {
                try {
                    let payload = {
                        full_name: userName,
                        mobile_no: userMobile
                    }
                    const response = await gemsApi.post("registerExtranetUser", payload);
                    if (response.data.status === 1) {
                        setOTPValidation(true);
                        dispatch(SaveUserFullName({ userFullName: userName }));
                        setIsReadOnly(true);
                        toast.success(response.data.message);
                        setTimeout(() => {
                            setShowResendOtp(true);
                        }, 10000);
                    }

                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            if (!userName) {
                setFullNameHelperText("Please enter a valid name");
            }
            if (!userMobile) {
                setMobileHelperText("Invalid mobile number");
            }
        }
    }

    const SignUpProceed = async () => {
        if (userMobile && otpNumber) {
            try {
                let payload = {
                    mobile_no: userMobile,
                    otp: otpNumber
                }
                const response = await gemsApi.post("validateExtranetUserOTP", payload);
                if (response.data.status === 1) {
                    navigate("/signup");
                } else if (response.data.status === 3) {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
            }
            setOTPHelperText("");
        } else {
            setOTPHelperText("Please enter a valid OTP");
        }
    }

    const onKeyEnterSignup = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            SignUpValidation();
        }
    };

    const onKeyEnterOTP = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            SignUpProceed();
        }
    };

    const [countDownTimer, setCountDownTimer] = useState<any>();

    useEffect(() => {
        let google_signup_script = document.createElement("script");
        google_signup_script.setAttribute("src", "https://accounts.google.com/gsi/client");
        google_signup_script.async = true;
        google_signup_script.defer = true;
        document.head.appendChild(google_signup_script);
        getClientSectionData();
    }, [])

    const getClientSectionData = async () => {
        try {
            let response = await kernelApi.get((`testimonials/${chargebeeEnvironment}`));
            if (response.data.status === 1) {
                dispatch(SaveClientSectionData({ imageUrl: response?.data?.testimonial?.image_url, userTestimonial: response?.data?.testimonial?.testimonial, userName: response?.data?.testimonial?.name, userDesignation: response?.data?.testimonial?.designation }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const ResendOtp = async () => {
        setCountDownTimer(Date.now() + 30000)
        try {
            let payload = {
                full_name: userName,
                mobile_no: userMobile
            }
            const response = await gemsApi.post("resendExtranetUserOTP", payload);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setShowOTPTimer(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Renderer callback with condition
    const renderer = ({ seconds, completed }) => {
        const counterStyle = {
            color: "#223189",
            fontSize: "1rem",
            fontWeight: 600,
            marginLeft: "0.5rem"
        }

        if (completed) {
            return (<>{setShowOTPTimer(false)}</>)
        } else {
            return (
                <span style={counterStyle}>
                    {seconds} sec.
                </span>
            );
        }
    };

    return (
        <>
            <div className="div-page-wrapper">
                <div className="div__wrapper">
                    <LeftSideNav>
                        <div className="div__user__testimonials__wrapper">
                            <div
                                className="user__testimonial_image"
                                style={{ backgroundImage: `url(${client_section?.imageUrl})` }}
                            >
                                <div className="user__testimonial__message__wrapper">
                                    <div className="testimonial__icon">
                                        <i
                                            className="bi bi-chat-square-quote"
                                            style={{ fontSize: 18 }}
                                        ></i>
                                    </div>
                                    <div className="user__testimonial__text">
                                        "{client_section?.userTestimonial}”
                                    </div>
                                    <div className="user__name">{client_section?.userName}</div>
                                    <div className="user__designation">{client_section?.userDesignation}</div>
                                </div>
                            </div>
                        </div>
                    </LeftSideNav>

                    <div className="div__input__wrapper__right div__right__side__box">
                        <div className="div__inputs max--width--380px">
                            <div className="div--head--label">Signup</div>
                            <Link to="/login">
                                <div className="div--helper--text mb-3">
                                    Already have an account yet? <span className="dev--helper--signin-sign-up ps-2"> Login</span>
                                </div>
                            </Link>

                            {/* Signin with google section */}
                            <div>
                                <div id="g_id_onload" data-client_id="1098328552594-m11e5mhckb9d28fp6vddifeeb9rv24li.apps.googleusercontent.com" data-callback="handleCredentialResponse"></div>
                                {/* <div id="g_id_onload" data-client_id="1098328552594-m11e5mhckb9d28fp6vddifeeb9rv24li.apps.googleusercontent.com" data-login_uri="http://localhost:3000/select-property" data-auto_prompt="false"></div> */}
                                <div className="g_id_signin" style={{ maxWidth: "380px" }} data-text="signup_with" data-width="380" data-type="standard" data-size="large" data-theme="filled_blue" data-shape="rectangular" data-logo_alignment="left"></div>
                            </div>

                            <div className="or__wrapper">
                                <div className="hr--line"></div>
                                <div className="or--text">Or</div>
                            </div>

                            <div className="div__inputs__wrapp">
                                <div className="div--email--input">
                                    <InputTextField
                                        value={userName}
                                        readonly={isReadOnly}
                                        helperText={fullNameHelperText}
                                        label="Full Name"
                                        handleChange={FullNameOnChange}
                                        onKeyPress={onKeyEnterSignup}
                                    // handleChange={(e) => { setUserName(e.target.value) }}
                                    />
                                </div>

                                <div className="div--email--input div--mobile--input">
                                    <InputTextField
                                        value={userMobile}
                                        readonly={isReadOnly}
                                        helperText={mobileHelperText}
                                        label="Mobile Number"
                                        handleChange={MobileOnChange}
                                        onKeyPress={onKeyEnterSignup}
                                    />
                                    <div className="send__otp__btn ms-3">
                                        {isReadOnly
                                            ? <StandardButton className="otp--btn disable--send--otp">Send OTP</StandardButton>
                                            : <StandardButton className="otp--btn" handleClick={() => SignUpValidation()}>Send OTP</StandardButton>
                                        }
                                    </div>
                                </div>

                                {otpValidation &&
                                    <>
                                        <div className="div--email--input otp__enter__input">
                                            <InputTextField
                                                value={otpNumber}
                                                label="Enter OTP"
                                                onKeyPress={onKeyEnterOTP}
                                                helperText={otpHelperText}
                                                handleChange={(e) => { setOTPNumber(e.target.value) }}
                                            />
                                        </div>

                                        {showResendOtp ? <div className="div--helper--text dont__receive__otp">
                                            Don't receive OTP?
                                            {!showOTPTimer ?
                                                <span className="dev--helper--signin-sign-up ps-2" onClick={() => ResendOtp()}> Request again</span>
                                                : <Countdown date={countDownTimer} renderer={renderer} />
                                            }
                                        </div> : ""}

                                        <div className="__btn__wrapper text-end mt-1">
                                            <StandardButton className="login--btn" handleClick={() => SignUpProceed()}>Proceed</StandardButton>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUpValidation;