import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import InventoryBtn from "../../views/buttons/Button";
import Button from "../../views/buttons/Button";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";

import { toast } from "react-toastify";

import Bookingjini from "../../assets/svg/bookingjini.svg";

import cmApi from "../../API/cmApi";
import beApi from "../../API/beApi";

//import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import Toast from "../../components/toaster/Toast";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

interface IUblockInventory {
  onClose: () => void;
  selectRoomType: string;
  selectRoomType_id: string;
  allChannel: any[];
  getInventory: () => void;
  cmUnblockLoader: any;
  beUnblockLoader: any;
  setUnblockLoader_cm: (initialState: any) => void;
  setUnblockLoader_be: (initialState: any) => void;
  api_data: any;
}

const UnblockInventory: React.FC<IUblockInventory> = ({
  onClose,
  selectRoomType,
  selectRoomType_id,
  allChannel,
  getInventory,
  cmUnblockLoader,
  beUnblockLoader,
  setUnblockLoader_cm,
  setUnblockLoader_be,
  api_data,
}) => {
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(null);
  const [selectedOta, setSelectedOta] = useState<any>([]);
  const [bulkButton, setBulkButton] = useState<any>(true);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [singleDate, setSingleDate] = useState<any>([]);
  const [errorMessage_cm, setErrorMessage_cm] = useState<any>([]);
  const [errorMessage_be, setErrorMessage_be] = useState<any>([]);
  const [allotas, setAllotas] = useState<any>([]);

  const [be_loader, setBe_loader] = useState<any>(false);
  const [cm_loader, setCm_loader] = useState<any>(false);

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  //Endpoints Import
  const {
    cmUnblockDateRange,
    cmUnblockSpecificDates,
    beUnblockDateRange,
    beUnblockSpecificDates,
  } = endPoints.INVENTORY;
  // --------------------------------

  const getAllotas = () => {
    let newArr: any[] = [{ id: -1, name: "Bookingjini" }];
    allChannel.forEach((channel) => {
      newArr.push({ id: channel.ota_id, name: channel.ota_name });
    });
    setAllotas(newArr);
  };
  useEffect(() => {
    getAllotas();
  }, []);

  const refresh = () => {
    document.getElementById("refresh-button")?.click();
  };

  const onDateChange = (date: any) => {
    let datesArray = [...singleDate];
    var index = datesArray.findIndex(
      (x) => x.date == moment(date).format("DD-MM-YYYY")
    );
    index === -1 &&
      datesArray.push({ date: moment(date).format("DD-MM-YYYY") });
    setFromDate(moment(date));
    setSingleDate(datesArray);
  };
  const RemoveDate = (date: any) => {
    let removeDate = [...singleDate];
    setSingleDate(removeDate.filter((Date: any) => Date.date !== date.date));
  };
  const handleOtaCode = (e: any, ota_id: any, ota_name: any) => {
    let newArray = [...selectedOta];
    if (e.target.checked) {
      newArray.push({ id: ota_id, name: ota_name });
    } else {
      newArray = newArray.filter((selected_otacode) => {
        return selected_otacode.id !== ota_id;
      });
    }

    setSelectedOta(newArray);
  };
  // for all channels select
  const selectAllChannels = (e: any) => {
    let newArray: any[] = [{ id: -1, name: "Bookingjini" }];
    allChannel.forEach((channel) => {
      if (e.target.checked) {
        newArray.push({ id: channel.ota_id, name: channel.ota_name });
      } else {
        newArray = [];
      }
    });
    setSelectedOta(newArray);
  };

  const cm_Daterange = async (removeBookingEngine: any) => {
    let id: any = [];
    removeBookingEngine?.map((ota: any, i: any) => {
      id.push(ota.id);
    });

    try {
      let res = await cmApi.post(
        cmUnblockDateRange,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: selectRoomType_id,
          date_from: moment(startDate).format("DD-MM-YYYY"),
          date_to: moment(endDate).format("DD-MM-YYYY"),
          los: 1,
          block_status: 0,
          admin_id: admin_id,
          ota_id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      setUnblockLoader_cm(false);
      if (!beUnblockLoader) {
        refresh();
      }
      // setCm_loader(false);
      setErrorMessage_cm(res.data);
    } catch (error) {
      setUnblockLoader_cm(false);
      toast.error("Something went wrong");
    }
  };

  const cm_Specificdate = async (removeBookingEngine) => {
    let data: any = [];
    // let removeBookingEngine = selectedOta.filter((item: any) => item.id !== -1);
    removeBookingEngine?.map((OTA) => {
      let recent_date: any = [];
      singleDate?.map((date: any, i: any) => {
        recent_date.push({ date: date.date, block_status: 0 });
      });

      data.push({
        ota_id: OTA.id,
        channel_name: OTA.name,
        inv: recent_date,
      });
    });

    try {
      let res = await cmApi.post(
        cmUnblockSpecificDates,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: selectRoomType_id,
          room_type: selectRoomType,
          inv: data,
          admin_id: admin_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      // setCm_loader(false);
      setUnblockLoader_cm(false);
      if (!beUnblockLoader) {
        refresh();
      }
      setErrorMessage_cm(res.data);
    } catch {
      setUnblockLoader_cm(false);
      Toast(CATCH_MESSAGE, "error");
    }
  };

  const be_Daterange = async () => {
    try {
      let res = await beApi.post(
        beUnblockDateRange,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: selectRoomType_id,
          date_from: moment(startDate).format("DD-MM-YYYY"),
          date_to: moment(endDate).format("DD-MM-YYYY"),
          los: 1,
          block_status: 0,
          admin_id: admin_id,
          ota_id: [-1],
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      setUnblockLoader_be(false);
      if (!cmUnblockLoader) {
        refresh();
      }
      // setBe_loader(false);
      setErrorMessage_be(res.data);
      setErrorMessage_cm([...errorMessage_cm, res.data]);
      // if (res.data.status === 1) {
      //   toast.success(res.data.message);
      // } else {
      //   toast.error(res.data.message);
      // }
    } catch (error) {
      setUnblockLoader_be(false);
      toast.error("Something went wrong");
    }
  };

  const be_Specificdate = async () => {
    try {
      let res = await beApi.post(
        beUnblockSpecificDates,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: selectRoomType_id,
          inv: singleDate,
          admin_id: admin_id,
          ota_id: [-1],
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      //  setBe_loader(false);
      setUnblockLoader_be(false);
      if (!cmUnblockLoader) {
        refresh();
      }

      setErrorMessage_be(res.data);
    } catch {
      setUnblockLoader_be(false);
      Toast(CATCH_MESSAGE, "error");
    }
  };

  const unBlockInventoryUpdate = () => {
    if (
      selectedOta.length > 0 &&
      ((!bulkButton && singleDate.length > 0) ||
        (bulkButton && startDate && endDate))
    ) {
      if (bulkButton) {
        if (selectedOta.some((ota: any) => ota.id === -1)) {
          //  setBe_loader(true);
          setUnblockLoader_be(true);
          be_Daterange();
        }

        let removeBookingEngine = selectedOta.filter(
          (item: any) => item.id !== -1
        );
        if (removeBookingEngine.length > 0) {
          // setCm_loader(true);
          setUnblockLoader_cm(true);
          cm_Daterange(removeBookingEngine);
        }
      } else {
        if (selectedOta.some((ota: any) => ota.id === -1)) {
          //  setBe_loader(true);
          setUnblockLoader_be(true);
          be_Specificdate();
        }
        let removeBookingEngine = selectedOta.filter(
          (item: any) => item.id !== -1
        );
        if (removeBookingEngine.length > 0) {
          //  setCm_loader(true);
          setUnblockLoader_cm(true);
          cm_Specificdate(removeBookingEngine);
        }
        //  getInventory();
        //  setSelectedOta([]);
      }
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const daterange_mode = () => {
    setBulkButton(!bulkButton);
    setSingleDate([]);
    setErrorMessage_be([]);
    setErrorMessage_cm([]);
    setSelectedOta([]);
  };

  const individualMode = () => {
    setBulkButton(!bulkButton);
    setSingleDate([]);
    setErrorMessage_be([]);
    setErrorMessage_cm([]);
    setSelectedOta([]);
  };

  useEffect(() => {
    if (!errorMessage_cm.length || !errorMessage_cm) return;
    setErrorMessage_cm([...errorMessage_cm, errorMessage_be]);
  }, [errorMessage_be]);

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Unblock Inventory</div>
      </div>
      <div className="height-64px"></div>
      <div className="inventory__update__wrapper">
        <div className="standard-page--label mb-4 ">Unblock Type</div>
        <div className="div__select__inputs__wrapper">
          <div
            className={
              bulkButton
                ? "me-4 select__input__div__active"
                : "me-4 select__input__div"
            }
            onClick={() => daterange_mode()}
          >
            {bulkButton ? <i className="bi bi-check-lg"></i> : null}
            Date Range
          </div>
          <div
            className={
              !bulkButton
                ? "me-4 select__input__div__active"
                : "me-4 select__input__div"
            }
            onClick={() => individualMode()}
          >
            {!bulkButton ? <i className="bi bi-check-lg"></i> : null}Individual
          </div>
        </div>

        {bulkButton ? (
          <div
            className="inventory__select__date__wrapper my-4"
            onClick={() => setFocusedInput("startDate")}
          >
            <div className="date__picker__icon">
              <i className="bi bi-calendar"></i>
            </div>
            <div className="date__label">
              {!startDate && !endDate
                ? "Select Dates"
                : (startDate ? startDate.format("DD MMM, YYYY") : "") +
                  " - " +
                  (endDate ? endDate.format("DD MMM, YYYY") : "")}
            </div>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              focusedInput={focusedInput}
              preventPastDates={true}
              setFocusedInput={setFocusedInput}
            />
          </div>
        ) : (
          <div className="my-4 mui__input__fieldset__legends__unset">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={fromDate}
                onChange={onDateChange}
                minDate={new Date()}
                inputFormat="dd MMM yyyy"
                InputAdornmentProps={{ position: "start" }}
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        )}

        {singleDate &&
          singleDate?.map((date: any) => {
            return (
              <div className="individual_dates">
                {date.date}{" "}
                <i
                  className="bi bi-x icon--close"
                  onClick={() => RemoveDate(date)}
                ></i>
              </div>
            );
          })}

        <div className="inventory__sync__channel__wrapper mt-3 pt-2">
          <div className="select__all__checkbox__wrapper mb-4">
            <div className="standard-page--label">Select Channels</div>
            <div className="select__all__checkbox">
              <input
                className="form-check-input custom-size me-3"
                type="checkbox"
                id="flexCheckDefault"
                onChange={(e) => {
                  selectAllChannels(e);
                }}
                checked={selectedOta.length === allotas.length}
              />
            </div>
          </div>

          <div className="mb-4">
            {errorMessage_be.status === 0 && (
              <div className="ota_error_message">{errorMessage_be.message}</div>
            )}
            {/* <div
              className={`channel__wrapper  ${errorMessage_be.status === 0
                ? "error--border"
                : errorMessage_be.status === 1 &&
                  selectedOta.some(
                    (source: any) => source.name === "Bookingjini"
                  )
                  ? "success--border"
                  : ""
                }`}
              onClick={() =>
                document.getElementById(`flexCheckDefault${-1}`)?.click()
              }
            >
              <div className="d-flex justify-content-center align-items-center">
                <div className="channel__image__content">
                  <img src={api_data?.ota_icon_path} />
                </div>
                {errorMessage_be?.status === 1 && (
                  <div className="success__checkmark">
                    <i className="bi bi-check"></i>
                  </div>
                )}
              </div>
              <div className="channel__select__checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input unclickable"
                    type="checkbox"
                    id={`flexCheckDefault${-1}`}
                    onChange={(e) => {
                      handleOtaCode(e, -1, "Bookingjini");
                    }}
                    checked={selectedOta.some((ota: any) => ota.id === -1)}
                  />
                </div>
              </div>
            </div> */}
          </div>

          {allChannel?.map((channel, id) => (
            <div className="mb-4">
              {errorMessage_cm?.some(
                (ota: any) =>
                  ota.ota_name?.toLowerCase() ===
                    channel.ota_name?.toLowerCase() && ota.status === 0
              ) &&
                errorMessage_cm
                  .filter(
                    (task: any) =>
                      task.ota_name?.toLowerCase() ===
                      channel.ota_name?.toLowerCase()
                  )
                  ?.map((filtered: any) => (
                    <div className="ota_error_message">
                      {filtered?.response_msg}
                    </div>
                  ))}
              <div
                className={`channel__wrapper  ${
                  errorMessage_cm?.some(
                    (ota: any) =>
                      ota.ota_name?.toLowerCase() ===
                        channel.ota_name?.toLowerCase() && ota.status === 0
                  )
                    ? "error--border"
                    : errorMessage_cm?.some(
                        (ota: any) =>
                          ota.ota_name?.toLowerCase() ===
                            channel.ota_name?.toLowerCase() &&
                          ota.status === 1 &&
                          selectedOta.some(
                            (source: any) => source.name === channel.ota_name
                          )
                      )
                    ? "success--border"
                    : ""
                }`}
                onClick={() =>
                  document.getElementById(`flexCheckDefault${id}`)?.click()
                }
              >
                <div className="d-flex justify-content-center align-items-center">
                  <div className="channel__image__content">
                    <img src={channel.ota_logo_path} />
                  </div>
                  {errorMessage_cm?.some(
                    (ota: any) =>
                      ota.ota_name?.toLowerCase() ===
                        channel.ota_name?.toLowerCase() &&
                      ota.status === 1 &&
                      selectedOta?.some(
                        (source: any) => source.name === channel.ota_name
                      )
                  ) && (
                    <div className="success__checkmark">
                      <i className="bi bi-check"></i>
                    </div>
                  )}
                </div>
                <div className="channel__select__checkbox">
                  <div className="form-check">
                    <input
                      className="form-check-input unclickable"
                      type="checkbox"
                      id={`flexCheckDefault${id}`}
                      onChange={(e) => {
                        handleOtaCode(e, channel.ota_id, channel.ota_name);
                      }}
                      // checked={selectedOta.includes(channel.ota_id)}
                      checked={selectedOta.some(
                        (ota: any) => ota.id === channel.ota_id
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="refresh-button" className="d-none" onClick={getInventory}></div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      {!cmUnblockLoader && !beUnblockLoader ? (
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3" handleClick={onClose}>
              Cancel
            </Button>
            <InventoryBtn handleClick={unBlockInventoryUpdate}>
              Save
            </InventoryBtn>
          </div>
        </div>
      ) : (
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3">Cancel</Button>
            <InventoryBtn>
              Save{" "}
              <span
                className="spinner-border spinner-border-sm pd-4"
                role="status"
                aria-hidden="true"
              ></span>
            </InventoryBtn>
          </div>
        </div>
      )}
    </>
  );
};

export default UnblockInventory;
