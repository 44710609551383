import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Button from "../../../../views/buttons/Button";
// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import kernelApi from "../../../../API/kernelApi";
import { RootState } from "../../../../redux/store";
import beApi from "../../../../API/beApi";
import Loader from "../../../../views/loader/Loader";
import {
  CATCH_MESSAGE,
  Logout,
  UpdateSidebar,
} from "../../../../UtilityFunctions";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import endPoints from "../../../../API/endPoints";

const NotificationPopup = () => {
  UpdateSidebar("Manage Channels", "Booking Engine");
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [enable, setEnable] = useState<boolean>(true);
  const [notification, setNotification] = useState("");
  const [access, setAccess] = useState(true);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  //Endpoints Import
  const { getNotificationsApi, notificationPost } = endPoints.MANAGECHANNEL
  // --------------------------------

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const navigate = useNavigate();

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  // Get Notification Details
  const getNotificationInformation = async () => {
    setLoader(true);
    try {
      let notificationRes = await beApi.get(
        `${getNotificationsApi}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (notificationRes?.data?.status === 1) {
        setNotification(notificationRes?.data?.be_notifications?.content_html);

        if (notificationRes?.data?.be_notifications?.is_active === 0) {
          setEnable(false);
        } else if (notificationRes?.data?.be_notifications?.is_active === 1) {
          setEnable(true);
        }

        setLoader(false);
      } else {
        toast(`${notificationRes?.data?.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };
  useEffect(() => {
    getNotificationInformation();
  }, []);

  // Post Notification Details

  const notificationSaveHandler = async () => {
    setLoader(true);
    try {
      let saveRes = await beApi.post(
        notificationPost,
        {
          hotel_id: current_property.hotel_id ?? "",
          content_html: notification ?? "",
          is_active: enable ? 1 : 0,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (saveRes.data.status === 1) {
        toast(saveRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getNotificationInformation();
        setLoader(false);
      } else {
        toast(`${saveRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <div>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Notification Popup" },
          ]}
        />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <div>Enable Popup</div>

          <div
            className="property__setup__manage__floors"
            style={{ paddingTop: "0rem" }}
          >
            <div className="manage__floor__card">
              <div className="manage__floor ms-0">
                <div className="room__type__name__details p-0">
                  <div className="toogle__switch">
                    <div className="form-check form-switch custom-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        // id={`flexSwitchCheckDefault${index}`}
                        checked={enable}
                        onChange={() => {
                          setEnable(!enable);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <>
          <div className="property__setup__terms__policy__wrapper">
            <div className="manage__terms__policy__wrapper">
              <div className="manage__terms__policy__content">
                <div className="tab-content" id="manageTermsPolicyContent">
                  <div
                    className="tab-pane fade show active"
                    id="terms-condtions"
                    role="tabpanel"
                    aria-labelledby="terms-condtions-tabs"
                  >
                    <div className="manage__terms__poliy__wrapper pb-0">
                      <div id="editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={notification}
                          config={{
                            removePlugins: [
                              "EasyImage",
                              "ImageUpload",
                              "MediaEmbed",
                              "Table",
                            ],
                          }}
                          onChange={(event: string, editor: any) => {
                            const data = editor.getData();
                            setNotification(data);
                            setCharCount({
                              ...charCount,
                              terms_cond: removeTags(data).length,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="character__count__number">
                      <div className="character__count__label">
                        Used <span>{charCount.terms_cond}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="save-btn">
            <Button
              loader={loader}
              handleClick={() => {
                notificationSaveHandler();
              }}
              isAccessible={access}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationPopup;
