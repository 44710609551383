import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import { useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { NewPropertyDetails } from "../../redux/actions/AddPropertyAction";

// import input components
import NextButton from "../../views/buttons/NextButton";
import InputTextField from "../../views/inputtextfield/InputTextField";
import BackButton from "../../views/buttons/Button";

import GetHelp from "../../components/gethelp/GetHelp";
import {
  Logout,
  validateEmail,
  validateLandline,
  validateMobile,
} from "../../UtilityFunctions";
import { toast } from "react-toastify";
import endPoints from "../../API/endPoints";

const PropertyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [propertyBasicInfo, setPropertyBasicInfo] = useState(
    store.getState().add_property.new_property_details
  );

  const [propertyMobileError, setPropertyMobileError] = useState("");
  const [propertyEmailError, setPropertyEmailError] = useState("");
  const [propertyLandlineError, setPropertyLandlineError] = useState("");

  //Endpoints Import
  const { addNewPropertyPropAddress, addNewPropertyPropSubType } = endPoints.ADDNEWPROPERTY
  // --------------------------------

  const NameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPropertyBasicInfo({ ...propertyBasicInfo, name: e.target.value });
  };

  const MobileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPropertyBasicInfo({ ...propertyBasicInfo, mobile: e.target.value });

    if (!validateMobile(e.target.value) && e.target.value) {
      setPropertyMobileError("Please enter valid mobile number");
    } else {
      setPropertyMobileError("");
    }
  };

  const LandlineOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPropertyBasicInfo({ ...propertyBasicInfo, landline: e.target.value });
    if (!validateLandline(e.target.value) && e.target.value) {
      setPropertyLandlineError("Please enter valid landline number");
    } else {
      setPropertyLandlineError("");
    }
  };

  const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPropertyBasicInfo({ ...propertyBasicInfo, email: e.target.value });
    if (!validateEmail(e.target.value) && e.target.value) {
      setPropertyEmailError("Invalid email address");
    } else {
      setPropertyEmailError("");
    }
  };

  const OnEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      SubmitPropertyDetails();
    }
  };

  const SubmitPropertyDetails = () => {
    let name = propertyBasicInfo.name;
    let mobile = propertyBasicInfo.mobile;
    let email = propertyBasicInfo.email;
    let landline = propertyBasicInfo.landline;

    if (
      name &&
      email &&
      mobile &&
      !propertyMobileError &&
      !propertyEmailError &&
      !propertyLandlineError
    ) {
      let data = { name, email, mobile, landline };
      dispatch(NewPropertyDetails(data));
      setTimeout(() => {
        navigate(addNewPropertyPropAddress);
      }, 600);
    } else {
      toast("Please fill the mandatory fields", {
        hideProgressBar: true,
        type: "error"
      });
    }
  };
  const BackButtonClick = () => {
    navigate(addNewPropertyPropSubType);
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <div className="div__input__wrapper__left div__left__side__box">
          <div className="logo__wrapper"></div>
          <div className="div__user__testimonials__wrapper">
            <div className="select__property__wrapper">
              <div className="select__property__text">
                We need some details for this property
              </div>
              <div className="select__property__sub__text">
                This details will be seen by guests when they search for a place
                to stay.
              </div>
            </div>
          </div>

          <div className="progress-bar-wrapper">
            <div className="progress-bar-text">2/5</div>
            <div className="progress-bar-status">
              <ProgressBar percent={40} filledBackground="#ffffff" />
            </div>
          </div>
        </div>

        <div className="div__input__wrapper__right div__right__side__box">
          <div className="div__inputs">
            <div className="div--head--label">Property Details</div>
            <div className="div--helper--text">Fill the form below</div>

            <div className="property_details_input__wrapper standard--margin--top">
              <div className="__default--input--field">
                <InputTextField
                  label="Property Name"
                  value={propertyBasicInfo.name}
                  handleChange={NameOnChange}
                  onKeyPress={OnEnterKeyPress}
                />
              </div>
              <div className="__default--input--field">
                <InputTextField
                  label="Property Mobile Number"
                  value={propertyBasicInfo.mobile}
                  helperText={propertyMobileError}
                  handleChange={MobileOnChange}
                  onKeyPress={OnEnterKeyPress}
                />
              </div>
            </div>

            <div className="property_details_input__wrapper">
              <div className="__default--input--field">
                <InputTextField
                  label="Property Email Address"
                  value={propertyBasicInfo.email}
                  helperText={propertyEmailError}
                  handleChange={EmailOnChange}
                  onKeyPress={OnEnterKeyPress}
                />
              </div>
            </div>

            <div className="property_details_input__wrapper">
              <div className="__default--input--field">
                <InputTextField
                  label="Property Landline Number (Optional)"
                  value={propertyBasicInfo.landline}
                  helperText={propertyLandlineError}
                  handleChange={LandlineOnChange}
                  onKeyPress={OnEnterKeyPress}
                />
              </div>
            </div>

            <div className="__btn__wrapper mt-4">
              <BackButton
                className="logout__btn"
                handleClick={() => {
                  Logout();
                }}
              >
                logout
              </BackButton>
              <BackButton className="back__btn" handleClick={BackButtonClick}>
                Back
              </BackButton>
              <NextButton handleClick={SubmitPropertyDetails} />
            </div>
            <GetHelp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
