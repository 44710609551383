import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import moment from "moment";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, validatePositiveInt } from "../../UtilityFunctions";
import Button from "../../views/buttons/Button";
import InputTextField from "../../views/inputtextfield/InputTextField";

import { TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";

import beApi from "../../API/beApi";
import cmApi from "../../API/cmApi";
import endPoints from "../../API/endPoints";
import kernelApi from "../../API/kernelApi";

interface IBulkUpdateSlider {
  ratesInfo: any[];
  ratePlans: any[];
  roomTypeId: string;
  baseAdult: number;
  loaderBE: boolean;
  setLoaderBE: React.Dispatch<React.SetStateAction<boolean>>;
  loaderCM: boolean;
  setLoaderCM: React.Dispatch<React.SetStateAction<boolean>>;
  refreshRates: () => void;
  allRoomTypes: { ids: string[]; labels: string[] };
  onClose: () => void;
}

const BulkUpdate: React.FC<IBulkUpdateSlider> = ({
  ratesInfo,
  ratePlans,
  roomTypeId,
  allRoomTypes,
  baseAdult,
  loaderBE,
  loaderCM,
  setLoaderBE,
  setLoaderCM,
  refreshRates,
  onClose,
}) => {
  const { auth_token, admin_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { ratePlansOfRoom } = endPoints.RATE;

  const UpdateTypes = ["Range", "Individual"];
  const [activeType, setActiveType] = useState(UpdateTypes[0]);

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [openDatepicker, setOpenDatePicker] = useState(false);

  const [singleDate, setSingleDate] = useState<any>(null);
  const [singleDates, setSingleDates] = useState<any[]>([]);

  const [activeDayType, setActiveDayType] = useState<string>("Custom");
  const [activeDays, setActiveDays] = useState<string[]>(moment.weekdays());
  const [activeRatePlan, setActiveRatePlan] = useState<string>("");

  const [selectedChannels, setSelectedChannels] = useState<any[]>([]);

  const [barPrice, setBarPrice] = useState<string>("");
  const [los, setLos] = useState<string>("");
  const [extraAdultPrice, setExtraAdultPrice] = useState<string>("");
  const [extraChildPrice, setExtraChildPrice] = useState<string>("");
  // const [multiOccupancyInput, setMultiOccupancyInput] = useState<string>("");
  const [multiOccupancyRates, setMultiOccupancyRates] = useState<string[]>([]);

  const [errorBE, setErrorBE] = useState<any>();
  const [errorOTAs, setErrorOTAs] = useState<any[]>([]);
  const [ratePlanSpecficRoom, setRatePlanSpecficRoom] = useState<any[]>([]);

  const daysPlan = ["Weekdays", "Weekend", "Custom"];

  const { updateDateRange, updateSingleDate } = endPoints.RATE;
  useEffect(() => {
    getRatePlansOfRoomTypes();
  }, []);

  const onTypeChange = (type: string) => {
    setActiveType(type);

    setBarPrice("");
    setLos("");
    setActiveRatePlan("");
    setExtraAdultPrice("");
    setExtraChildPrice("");
    // setMultiOccupancyInput("");
    setMultiOccupancyRates([]);
    setSelectedChannels([]);
    setActiveDayType("Custom");
    setActiveDays(moment.weekdays());
  };

  useEffect(() => {
    getRatePlansOfRoomTypes();
  }, []);

  const toggleWeekDays = (day: string) => {
    if (activeDayType === "Custom") {
      let weekDays = [...activeDays];
      if (weekDays.includes(day)) {
        weekDays = weekDays.filter((d) => d !== day);
      } else {
        weekDays.push(day);
      }
      setActiveDays(weekDays);
    }
  };

  const selectDaysType = (dayType: string) => {
    setActiveDayType(dayType);
    if (dayType === "Weekend") {
      setActiveDays(["Saturday", "Sunday"]);
    } else if (dayType === "Weekdays") {
      setActiveDays(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);
    } else {
      setActiveDays(moment.weekdays());
    }
  };

  const onDateChange = (date: any) => {
    let dateList = [...singleDates];
    let dateValue = moment(date).format("YYYY-MM-DD");

    if (dateValue !== "Invalid date" && !dateList.includes(dateValue)) {
      dateList.push(dateValue);
    }
    setSingleDate(moment(date));
    setSingleDates(dateList);
  };

  const removeDate = (date: any) => {
    let dateList = [...singleDates];
    let dateValue = moment(date).format("YYYY-MM-DD");
    dateList.splice(dateList.indexOf(dateValue), 1);
    setSingleDates(dateList);
  };

  // const getOccupancyFromBaseAdult = (base_adult: number) => {
  //   let occupancy = base_adult - 1;
  //   if (occupancy === 1) {
  //     return "(Single)";
  //   } else if (occupancy === 2) {
  //     return "(Double)";
  //   } else if (occupancy === 3) {
  //     return "(Triple)";
  //   } else if (occupancy === 4) {
  //     return "(Quadruple)";
  //   } else {
  //     return ""
  //   }
  // }

  const refresh = () => {
    document.getElementById("refresh-button")?.click();
  };

  const onSelectChannel = (id: string) => {
    let channels = [...selectedChannels];
    if (channels.includes(id.toString())) {
      channels = channels.filter((channel) => channel !== id.toString());
    } else {
      channels.push(id.toString());
    }
    setSelectedChannels(channels);
  };

  const onSelectAllChannels = (e: any) => {
    let channels: any[] = [];
    if (e.target.checked) {
      ratesInfo.forEach((ota) => {
        channels.push(ota.ota_id.toString());
      });
    }
    setSelectedChannels(channels);
  };

  // on Enter key press function for the multiple occupancy input
  // const onKeyEnterMultiOccupancy = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter") {
  //     if (multiOccupancyInput && multiOccupancyRates.length < baseAdult - 1) {
  //       setMultiOccupancyRates([...multiOccupancyRates, multiOccupancyInput]);
  //     }
  //     setMultiOccupancyInput("");
  //   } else if (multiOccupancyRates.length >= baseAdult - 1) {
  //     e.preventDefault();
  //   }
  // };

  const onLosInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (validatePositiveInt(value) || !value) {
      if (parseInt(value) > 30) {
        setLos("30");
      } else {
        setLos(value);
      }
    }
  };

  const onOccupancyPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let value = e.target.value;
    if (validatePositiveInt(value) || !value) {
      let prices = [...multiOccupancyRates];
      prices[index] = value || "";
      setMultiOccupancyRates(prices);
    }
  };

  const onRangeUpdateBE = async () => {
    setLoaderBE(true);
    let daysStatus: any = {};
    moment.weekdays().forEach((day: string) => {
      let shortDay = day.substring(0, 3);
      daysStatus[shortDay] = activeDays.includes(day) ? 1 : 0;
    });

    try {
      const response = await beApi.post(
        `${updateDateRange}`,
        {
          admin_id: admin_id,
          hotel_id: current_property.hotel_id,
          room_type_id: roomTypeId,
          rate_plan_id: activeRatePlan,
          from_date: moment(startDate).format("DD-MM-YYYY"),
          to_date: moment(endDate).format("DD-MM-YYYY"),
          multiple_occupancy: multiOccupancyRates,
          bar_price: barPrice,
          block_status: 0,
          extra_adult_price: extraAdultPrice,
          extra_child_price: extraChildPrice,
          los: los,
          multiple_days: daysStatus,
          ota_id: [-1],
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      setErrorBE(response.data);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    setLoaderBE(false);
    if (!loaderCM) {
      refresh();
    }
  };

  const onRangeUpdateCM = async () => {
    setLoaderCM(true);
    let daysStatus: any = {};
    moment.weekdays().forEach((day: string) => {
      let shortDay = day.substring(0, 3);
      daysStatus[shortDay] = activeDays.includes(day) ? 1 : 0;
    });

    try {
      const response = await cmApi.post(
        `${updateDateRange}`,
        {
          admin_id: admin_id,
          hotel_id: current_property.hotel_id,
          room_type_id: roomTypeId,
          rate_plan_id: activeRatePlan,
          from_date: moment(startDate).format("DD-MM-YYYY"),
          to_date: moment(endDate).format("DD-MM-YYYY"),
          multiple_occupancy: multiOccupancyRates,
          bar_price: barPrice,
          block_status: 0,
          extra_adult_price: extraAdultPrice,
          extra_child_price: extraChildPrice,
          los: los,
          multiple_days: daysStatus,
          ota_id: selectedChannels
            .filter((channel) => channel !== "-1")
            .map(Number),
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      let errors = [...errorOTAs];
      response.data.forEach((res: any) => {
        errors.push(res);
      });
      setErrorOTAs(errors);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    setLoaderCM(false);
    if (!loaderBE) {
      refresh();
    }
  };

  const onSingleUpdateBE = async () => {
    setLoaderBE(true);
    try {
      let DateRates: any[] = [];

      singleDates.forEach((date: string) => {
        DateRates.push({
          bar_price: parseInt(barPrice),
          date: date,
          extra_adult_price: parseInt(extraAdultPrice),
          extra_child_price: parseInt(extraChildPrice),
          los: parseInt(los),
          multiple_occupancy: multiOccupancyRates,
        });
      });

      let payload = {
        hotel_id: current_property.hotel_id,
        admin_id: admin_id,
        ota_id: ["-1"],
        rates: [
          {
            room_type_id: roomTypeId,
            room_type:
              allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
            plan_type: ratePlans.filter(
              (rp: any) =>
                rp.rate_plan_id.toString() === activeRatePlan.toString()
            )[0].plan_type,
            rate_plan_id: activeRatePlan,
            rates: DateRates,
          },
        ],
      };

      const response = await beApi.post(`${updateSingleDate}`, payload, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      setErrorBE(response.data);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    setLoaderBE(false);
    if (!loaderCM) {
      refresh();
    }
  };

  const onSingleUpdateCM = async () => {
    setLoaderCM(true);
    try {
      let DateRates: any[] = [];

      singleDates.forEach((date: string) => {
        DateRates.push({
          bar_price: parseInt(barPrice),
          date: date,
          extra_adult_price: parseInt(extraAdultPrice),
          extra_child_price: parseInt(extraChildPrice),
          los: parseInt(los),
          multiple_occupancy: multiOccupancyRates,
        });
      });

      let payload = {
        hotel_id: current_property.hotel_id,
        admin_id: admin_id,
        ota_id: selectedChannels
          .filter((channel) => channel !== "-1")
          .map(Number),
        rates: [
          {
            room_type_id: roomTypeId,
            room_type:
              allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
            plan_type: ratePlans.filter(
              (rp: any) =>
                rp.rate_plan_id.toString() === activeRatePlan.toString()
            )[0].plan_type,
            rate_plan_id: activeRatePlan,
            rates: DateRates,
          },
        ],
      };

      const response = await cmApi.post(`${updateSingleDate}`, payload, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      let errors = [...errorOTAs];
      response.data.forEach((res: any) => {
        errors.push(res);
      });
      setErrorOTAs(errors);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    setLoaderCM(false);
    if (!loaderBE) {
      refresh();
    }
  };

  const submitBulkUpdate = () => {
    setErrorOTAs([]);
    setErrorBE(null);
    if (
      activeType === "Range" &&
      startDate &&
      endDate &&
      activeRatePlan &&
      barPrice &&
      extraAdultPrice &&
      extraChildPrice &&
      los &&
      activeDays.length > 0 &&
      selectedChannels.length > 0
    ) {
      let channels = [...selectedChannels];
      if (channels.includes("-1")) {
        onRangeUpdateBE();
      }
      if (channels.filter((channel) => channel !== "-1").length > 0) {
        onRangeUpdateCM();
      }
    } else if (
      activeType === "Individual" &&
      singleDates.length > 0 &&
      activeRatePlan &&
      barPrice &&
      extraAdultPrice &&
      extraChildPrice &&
      los &&
      selectedChannels.length > 0
    ) {
      let channels = [...selectedChannels];
      if (channels.includes("-1")) {
        onSingleUpdateBE();
      }
      if (channels.filter((channel) => channel !== "-1").length > 0) {
        onSingleUpdateCM();
      }
    } else {
      toast("Please select all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const getRatePlansOfRoomTypes = async () => {
    try {
      let ratePlanRes = await kernelApi.get(
        `${ratePlansOfRoom}/${current_property.hotel_id}/${roomTypeId}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (ratePlanRes?.data?.status === 1) {
        setRatePlanSpecficRoom(ratePlanRes?.data?.data);
      } else {
        setRatePlanSpecficRoom([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">
          Update{" "}
          {roomTypeId
            ? "(" +
            allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)] +
            ")"
            : ""}
        </div>
      </div>
      <div className="height-64px"></div>
      <div className="rates__update__wrapper">
        <div className="standard-page--label mb-3">Date Type</div>
        <div className="select__input__label mb-4">
          {UpdateTypes.map((type: string, i: number) => (
            <div
              className={`me-4 select__input__div${activeType === type ? "__active" : ""
                }`}
              key={i}
              onClick={() => onTypeChange(type)}
            >
              {activeType === type && (
                <i className="bi bi-check-lg icon--check"></i>
              )}
              {type}
            </div>
          ))}
        </div>

        <div className="standard-page--label mb-3">Select Meal Plan</div>
        <div className="select__input__label">
          {ratePlanSpecficRoom?.map((plan: any, i: number) => (
            <div
              key={i}
              onClick={() => setActiveRatePlan(plan.rate_plan_id)}
              className={`me-4 mb-3 select__input__div${activeRatePlan === plan.rate_plan_id ? "__active" : ""
                }  `}
            >
              {activeRatePlan === plan.rate_plan_id && (
                <i className="bi bi-check-lg icon--check"></i>
              )}
              {plan.plan_type}
            </div>
          ))}
        </div>

        {activeType === "Range" ? (
          <div
            className="inventory__select__date__wrapper my-4"
            onClick={() => setFocusedInput("startDate")}
          >
            <div className="date__picker__icon">
              <i className="bi bi-calendar"></i>
            </div>
            <div className="date__label">
              {!startDate && !endDate
                ? "Select Date Range"
                : (startDate ? startDate.format("DD MMM, YYYY") : "") +
                " - " +
                (endDate ? endDate.format("DD MMM, YYYY") : "")}
            </div>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              focusedInput={focusedInput}
              setFocusedInput={setFocusedInput}
              preventPastDates={true}
            />
          </div>
        ) : (
          <div className="my-4">
            <div className="individual__rate__update">
              <div className="individual__rate__updates__input mui__input__fieldset__legends__unset">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    open={openDatepicker}
                    onOpen={() => setOpenDatePicker(true)}
                    onClose={() => setOpenDatePicker(false)}
                    value={singleDate}
                    onChange={onDateChange}
                    minDate={new Date()}
                    inputFormat="dd MMM yyyy"
                    toolbarPlaceholder="Select Date"
                    InputAdornmentProps={{ position: "start" }}
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={() => setOpenDatePicker(true)}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="individual__dates__wrapper">
              {singleDates?.map((date: any) => {
                return (
                  <div className="individual_dates" key={date}>
                    {moment(date).format("DD MMM YYYY")}
                    <i
                      className="bi bi-x icon--close"
                      onClick={() => removeDate(date)}
                    ></i>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {activeType === "Range" && (
          <>
            <div className="select__input__label pt-4">
              {daysPlan.map((type: string, i: number) => (
                <div
                  className={`me-4 select__input__div${activeDayType === type ? "__active" : ""
                    }`}
                  key={i}
                  onClick={() => selectDaysType(type)}
                >
                  {activeDayType === type && (
                    <i className="bi bi-check-lg icon--check"></i>
                  )}
                  {type}
                </div>
              ))}
            </div>

            <div className="select__input__label my-4">
              {moment.weekdays(true).map((day: string, i: number) => (
                <div className="me-4 days__label" key={i} onClick={() => toggleWeekDays(day)}>
                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    id={`weekdayCheck${i}`}
                    // onChange={() => toggleWeekDays(day)}
                    checked={activeDays.includes(day)}
                  />
                  {moment().day(day).format("ddd")}
                </div>
              ))}
            </div>
          </>
        )}

        <div className="occupancy__inputs__wrapper mt-5">
          <div className="default--input--field">
            <InputTextField
              label="BAR price"
              value={barPrice}
              handleChange={(e) => {
                if (validatePositiveInt(e.target.value) || !e.target.value)
                  setBarPrice(e.target.value);
              }}
            />
          </div>
          <div className="default--input--field">
            <InputTextField
              label="Length of stay (Max 30 Days)"
              value={los}
              handleChange={onLosInputChange}
            />
          </div>
        </div>

        <div className="occupancy__inputs__wrapper">
          <div className="default--input--field">
            <InputTextField
              label="Extra Adult Price"
              value={extraAdultPrice}
              handleChange={(e) => {
                if (validatePositiveInt(e.target.value) || !e.target.value)
                  setExtraAdultPrice(e.target.value);
              }}
            />
          </div>
          <div className="default--input--field">
            <InputTextField
              label="Extra Child Price"
              value={extraChildPrice}
              handleChange={(e) => {
                if (validatePositiveInt(e.target.value) || !e.target.value)
                  setExtraChildPrice(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="occupancy__inputs__wrapper">
          {baseAdult > 1 && (
            <div className="default--input--field">
              <InputTextField
                label="Single Occupancy Price"
                value={multiOccupancyRates[0] || ""}
                handleChange={(e) => onOccupancyPriceChange(e, 0)}
              />
            </div>
          )}

          {baseAdult > 2 && (
            <div className="default--input--field">
              <InputTextField
                label="Double Occupancy Price"
                value={multiOccupancyRates[1] || ""}
                handleChange={(e) => onOccupancyPriceChange(e, 1)}
              />
            </div>
          )}

          {baseAdult > 3 && (
            <div className="default--input--field">
              <InputTextField
                label="Triple Occupancy Price"
                value={multiOccupancyRates[2] || ""}
                handleChange={(e) => onOccupancyPriceChange(e, 2)}
              />
            </div>
          )}

          {baseAdult > 4 && (
            <div className="default--input--field">
              <InputTextField
                label="Quadruple Occupancy Price"
                value={multiOccupancyRates[3] || ""}
                handleChange={(e) => onOccupancyPriceChange(e, 3)}
              />
            </div>
          )}
        </div>

        <div className="rates__channel__wrapper mt-3 pt-2">
          <div className="d-flex">
            <div className="standard-page--label mb-4">Select Channels</div>
            <div className="input__position">
              <input
                type="checkbox"
                id="channelCheckbox"
                onChange={onSelectAllChannels}
                className="form-check-input custom-size me-3"
                checked={selectedChannels.length === ratesInfo.length}
              />
            </div>
          </div>

          {ratesInfo.map((ota: any, i: number) => {
            if (ota?.ota_id?.toString() === "-1") {
              let Status = errorBE?.status === 0 ? true : false;
              let ErrorLength = errorBE ? true : false;
              return (
                <React.Fragment key={i}>
                  {Status && (
                    <div className="ota_error_message">
                      {errorBE?.response_msg}
                    </div>
                  )}
                  <div
                    onClick={() =>
                      document.getElementById(`beCheckbox${i}`)?.click()
                    }
                    className={`channel__wrapper ${Status
                        ? "error--border"
                        : selectedChannels.includes("-1") && ErrorLength
                          ? "success--border"
                          : ""
                      } mb-4`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="channel__image__content">
                        <img src={ota.logo} alt="" />
                      </div>
                      {!Status &&
                        ErrorLength &&
                        selectedChannels.includes("-1") && (
                          <div className="success__checkmark">
                            <i className="bi bi-check"></i>
                          </div>
                        )}
                    </div>
                    <div className="channel__select__checkbox">
                      <div className="form-check">
                        <input
                          className="form-check-input unclickable"
                          type="checkbox"
                          id={`beCheckbox${i}`}
                          onChange={() => onSelectChannel(ota.ota_id)}
                          checked={selectedChannels.includes(
                            ota?.ota_id?.toString()
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            } else {
              let ErrorLength = errorOTAs.length ? true : false;
              let Status = ErrorLength
                ? errorOTAs.some((error: any) => {
                  return Array.isArray(error)
                    ? false
                    : error?.ota_name?.toLowerCase() ===
                    ota?.ota_name?.toLowerCase() && error.status === 0;
                })
                : false;
              return (
                <React.Fragment key={i}>
                  {Status && (
                    <div className="ota_error_message">
                      {
                        errorOTAs.filter((error: any) => {
                          return Array.isArray(error)
                            ? false
                            : error?.ota_name?.toLowerCase() ===
                            ota?.ota_name?.toLowerCase();
                        })[0]?.response_msg
                      }
                    </div>
                  )}
                  <div
                    onClick={() =>
                      document.getElementById(`channelCheck${i}`)?.click()
                    }
                    className={`channel__wrapper ${Status
                        ? "error--border"
                        : selectedChannels.includes(ota.ota_id.toString()) &&
                          ErrorLength
                          ? "success--border"
                          : ""
                      } mb-4`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="channel__image__content">
                        <img src={ota.logo} alt="" />
                      </div>
                      {!Status &&
                        ErrorLength &&
                        selectedChannels.includes(ota.ota_id.toString()) && (
                          <div className="success__checkmark">
                            <i className="bi bi-check"></i>
                          </div>
                        )}
                    </div>
                    <div className="channel__select__checkbox">
                      <div className="form-check">
                        <input
                          className="form-check-input unclickable"
                          type="checkbox"
                          id={`channelCheck${i}`}
                          onChange={() => onSelectChannel(ota.ota_id)}
                          checked={selectedChannels.includes(
                            ota.ota_id.toString()
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>

      <div id="refresh-button" className="d-none" onClick={refreshRates}></div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-3">
          <Button className="cancel__btn me-3" handleClick={onClose}>
            Cancel
          </Button>
          {!loaderBE && !loaderCM ? (
            <Button handleClick={submitBulkUpdate}>Save</Button>
          ) : (
            <>
              <Button>
                Save{" "}
                <span
                  className="spinner-border spinner-border-sm pd-4"
                  aria-hidden="true"
                  role="status"
                ></span>
              </Button>
              <div className="full--page--overlay"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BulkUpdate;
