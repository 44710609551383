import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomDateRangePicker from "../../../../components/daterangepicker/DateRangePicker";
import { RootState } from "../../../../redux/store";
import { CATCH_MESSAGE, Logout } from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import NextButton from "../../../../views/buttons/NextButton";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
import moment from "moment";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import kernelApi from "../../../../API/kernelApi";
import beApi from "../../../../API/beApi";
import endPoints from "../../../../API/endPoints";

export interface IAddNewLinkProps {
  onClose: () => void;
}

const AddNewLink: React.FC<IAddNewLinkProps> = ({ onClose }) => {
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { auth_token } = useSelector((state: RootState) => state.auth);

  // Importing endpoints variable
  const {
    allRoomTypeEndpoint,
    ratePlanByRoomTypeAPI,
    bePaymentGatewayDetails,
    addQuickPayment,
  } = endPoints.MANAGECHANNEL;

  const [tab, setTab] = useState(0);
  const steps = ["Booking Details", "Guest Details", "Payment Gateway"];
  const [noOfRooms, setNoOfRooms] = useState(0);
  const [guestName, setGuestName] = useState("");
  const [mobileNo, setMobileNo] = useState<any>();
  const [emailId, setEmailId] = useState("");
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedRoomType, setSelectedRoomType] = useState<any>("");
  const [bookingAmount, setBookingAmount] = useState<any>("");
  const [comment, setComment] = useState<any>("");
  const [blockProperty, setBlockProperty] = useState<any>(true);
  const [allRoomType, setAllRoomType] = useState<any>([""]);
  const [ratePlansById, setRatePlansById] = useState<any>([]);
  const [ratePlanSelected, setRatePlanSelected] = useState<any>([]);
  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState<any>([]);
  // const [allRatePlans, setAllRatePlans] = useState<any>("");
  // const [paymentGateName, setPaymentGateName] = useState<any>("");
  const [paymentGateValue, setPaymentGateValue] = useState<any>([]);
  const [selectedPaymentGate, setSelectedPaymentGate] = useState<any>([]);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [emailHelperText, setEmailHelperText] = useState<any>("");
  const [maxNoOfRooms, setMaxNoOfRooms] = useState<any>("X");
  const [NoOfRoomsApiRes, setNoOfRoomsApiRes] = useState<any>([]);

  // Handler function to retrieve all the room types
  const allRoomTypeApiCall = async () => {
    try {
      const res = await kernelApi.get(
        `${allRoomTypeEndpoint}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (res.data.status === 1) {
        let allRoomTypeArr: any = [];
        let noOfRoomsApiResArr: any = [];
        let response = res.data.data;
        for (let i = 0; i < response.length; i++) {
          let { room_type, room_type_id } = response[i];
          let { total_rooms } = response[i];
          let roomTypeStr = `${room_type}(${room_type_id})`;
          let obj = {};
          obj[room_type_id] = total_rooms;
          noOfRoomsApiResArr.push(obj);
          allRoomTypeArr.push(`${room_type}(${room_type_id})`);
        }
        setAllRoomType(allRoomTypeArr);
        setNoOfRoomsApiRes(noOfRoomsApiResArr);
      }
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // Handler function to retrieve Payment gateway details
  // https://be.bookingjini.com/bookingjini-pay-gate-details --> Payment gateway details
  const paymentGateWayDetailsApiCall = async () => {
    try {
      const res = await beApi.get(`${bePaymentGatewayDetails}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (res.data.status === 1) {
        setPaymentGateValue(res.data.data);
      } else {
        setPaymentGateValue([]);
      }
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // Handler function to retrieve all associated rate plans
  // https://kernel.bookingjini.com/master_hotel_rate_plan/room_rate_plan/1953 --> Hotel Rate Plan options
  // const allRatePlansApiCall = async () => {
  //   try {
  //     const res = await kernelApi.get(
  //       `/master_hotel_rate_plan/room_rate_plan/${current_property.hotel_id}`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + auth_token,
  //         },
  //       }
  //     );
  //   } catch {
  //     toast(CATCH_MESSAGE, {
  //       hideProgressBar: true,
  //       type: "error",
  //     });
  //   }
  // };

  // https://kernel.bookingjini.com/master_hotel_rate_plan/room_rate_plan_by_room_type/1953/6216
  // Handler function to retrieve master rate plan by specific room type
  const ratePlanByRoomType = async (roomType: any) => {
    let reBrackets = /\((.*?)\)/g;
    let listOfValues: any = [];
    let found: any;
    while ((found = reBrackets.exec(roomType))) {
      listOfValues.push(found[1]);
    }
    setSelectedRoomTypeId(listOfValues.slice(-1));
    let roomTypeId = listOfValues.slice(-1);
    let roomTypeIdValue = listOfValues[0];
    let length = NoOfRoomsApiRes.length;
    for (let i = 0; i < length; i++) {
      if (NoOfRoomsApiRes[i][roomTypeIdValue]) {
        setMaxNoOfRooms(NoOfRoomsApiRes[i][roomTypeIdValue]);
        break;
      } else {
        setMaxNoOfRooms(0);
      }
    }
    try {
      const res = await kernelApi.get(
        `${ratePlanByRoomTypeAPI}/${current_property.hotel_id}/${roomTypeId}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (res.data.status === 1) {
        setRatePlansById(res.data.data);
      } else {
        setRatePlansById([]);
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error(error.response.message);
        Logout();
      } else {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    allRoomTypeApiCall();
    paymentGateWayDetailsApiCall();
  }, []);

  const decrement = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (maxNoOfRooms === "X") {
      toast(`Please select hotel room type first`, {
        hideProgressBar: true,
        type: "error",
      });
    } else if (state > 0) {
      setState(state - 1);
    }
  };

  const increment = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (maxNoOfRooms === "X") {
      toast(`Please select hotel room type first`, {
        hideProgressBar: true,
        type: "error",
      });
    } else if (state < maxNoOfRooms) {
      setState(state + 1);
    } else {
      toast(`No more room available`, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const next = (tab: any) => {
    setTab((tab + 1) % 5);
  };

  const onNextMove = (tab: any) => {
    // next(tab);
    if (tab === 0) {
      if (
        startDate &&
        endDate &&
        noOfRooms &&
        selectedRoomTypeId.length &&
        ratePlanSelected.length &&
        bookingAmount &&
        comment
      ) {
        next(tab);
      } else {
        toast(`Please Fill all the required field!`, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } else if (tab === 1) {
      if (guestName && mobileNo && emailId && !emailHelperText) {
        next(tab);
      } else if (emailHelperText) {
        toast(`Enter a valid email ID!`, {
          hideProgressBar: true,
          type: "error",
        });
      } else {
        toast(`Please Fill all the required field!`, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  const guestNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target ?? {};
    // if (value.includes(",") || value.includes("(") || value.includes(")")) {
    //   setshrtRoomHelperText(`Room Name Should Not Contain "," or "( )"`);
    // } else {
    //   if (value && value.trim().length < 4) {
    //     setshrtRoomHelperText(
    //       "Room Type Name Could Not Be less Than 4 character"
    //     );
    //   } else {
    //     setshrtRoomHelperText("");
    //   }
    // }
    setGuestName(value);
  };

  const mobileNoChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target ?? {};
    let num = parseInt(value);
    setMobileNo(num);
  };

  // email validator
  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const emailIdChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target ?? {};
    setEmailId(value);
    let validity = validateEmail(value);
    if (validity) {
      setEmailHelperText("");
    } else {
      setEmailHelperText("kindly fill a valid mail Id!");
    }
  };

  const block_mode = () => {
    setBlockProperty(!blockProperty);
  };

  const unblock_mode = () => {
    setBlockProperty(!blockProperty);
  };

  // let reBrackets = /\((.*?)\)/g;
  // let listOfValues: any = [];
  // let found: any;
  // while ((found = reBrackets.exec(selectedRoomType))) {
  //   listOfValues.push(found[1]);
  // }
  // let roomTypeId = listOfValues.slice(-1);

  // Payload construction for creating new payment link
  let payload = {
    hotel_id: current_property.hotel_id,
    pg_name: selectedPaymentGate[0]?.paymentgateway_value,
    name: guestName,
    email: emailId,
    phone: mobileNo,
    amount: bookingAmount,
    comment: comment,
    no_of_rooms: noOfRooms,
    room_type: selectedRoomTypeId[0],
    rate_plan: ratePlanSelected[0],
    check_in: moment(startDate).format("YYYY-MM-DD"),
    check_out: moment(endDate).format("YYYY-MM-DD"),
  };

  // Handler function to Add New Link
  const addNewLink = async () => {
    try {
      setSaveLoader(true);
      let res = await beApi.post(`${addQuickPayment}`, payload, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (res.data.status === 1) {
        setSaveLoader(false);
        onClose();
        toast(res.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        setSaveLoader(false);
        toast(res.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      setSaveLoader(false);
      onClose();
      if (error.response.status === 401) {
        toast.error(error.response.message);
        Logout();
      } else {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Create Quick Payment Link</div>
      </div>
      <div className="height-64px"></div>

      <div className="new__room__type__progress__stepper">
        {steps.map((step, index) => {
          return (
            <div className="label__progress__wrapper" key={index}>
              {index < tab ? (
                <div
                  className="progress__status status--completed"
                  onClick={() => setTab(index)}
                >
                  <i className="bi bi-check2"></i>
                </div>
              ) : (
                <div
                  className={`progress__status status--${index === tab ? "in--progress" : "pending"
                    }`}
                >
                  {index + 1}
                </div>
              )}
              <div className="progress__status__label__text">{step}</div>
            </div>
          );
        })}
      </div>

      {/* Room type basic details tab */}
      {tab === 0 && (
        <>
          {/* <form>
              <input type="text" tabIndex={1} />
              <input type="text" tabIndex={2} />
              <button tabIndex={3} >button</button>
              <input type="text" tabIndex={-1} />
            </form> */}
          <div className="date__range__selector__details">
            <div className="col-12 add-room-shortcode">
              <div className="standard-page--label mb-3 pb-1">
                Choose Check-in/out Date
              </div>
              <div
                className="quick__payment__select__date__wrapper my-0"
                onClick={() => setFocusedInput("startDate")}
              >
                <div className="date__picker__icon">
                  <i className="bi bi-calendar"></i>
                </div>

                <div className="date__label">
                  {!startDate && !endDate
                    ? "Select Date Range"
                    : (startDate
                      ? moment(startDate).format("DD MMM YYYY")
                      : "") +
                    " - " +
                    (endDate ? moment(endDate).format("DD MMM YYYY") : "")}
                </div>
                <CustomDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  focusedInput={focusedInput}
                  preventPastDates={true}
                  setFocusedInput={setFocusedInput}
                />
              </div>
              {/* <InputTextField
                label="Room Type Shortcode"
                value="Kailash"
                // value={shrtCode}
                // handleChange={shortCodeChangeHandler}
                // helperText={shrtCodeHelperText}
              /> */}
            </div>
          </div>

          {/* <div className="property__setup__room__details mb-0 mt-4 pt-0 pb-0">
            <InputSelectField
              label={"Select Hotel Room Type"}
              value={selectedRoomType}
              options={allRoomType}
              handleChange={(e) => {
                setSelectedRoomType(e.target.value);
                ratePlanByRoomType(e.target.value);
                setRatePlanSelected([]);
              }}
            />
          </div> */}

          {/* Select Label Field */}
          {/* {ratePlansById.length > 0 ? (
            <div className="property__setup__room__details mb-0 mt-0 pt-0 pb-0">
              <div className="div__select__inputs__room_type__wrapper mt-4">
                {ratePlansById.map((obj: any) =>
                  obj.plan_type !== "OTHERS" ? (
                    <div
                      className={
                        ratePlanSelected.includes(obj?.rate_plan_id)
                          ? "me-4 select__input__div__active"
                          : "me-4 select__input__div"
                      }
                      onClick={() => setRatePlanSelected([obj?.rate_plan_id])}
                    >
                      {obj.plan_type}
                    </div>
                  ) : (
                    <div
                      className={
                        ratePlanSelected.includes(obj?.rate_plan_id)
                          ? "me-4 select__input__div__active"
                          : "me-4 select__input__div"
                      }
                      onClick={() => setRatePlanSelected([obj?.rate_plan_id])}
                    >
                      {`OTHERS (${obj.plan_name.slice(0, 3)}...)`}
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null} */}

          {/* Date Range & no of Rooms */}
          <div
            className="quick_payment_link_room_type__no_of_rooms mt-0 pt-0"
            style={{
              gap: "0",
              paddingRight: "16px",
              justifyContent: "space-between",
            }}
          >
            <div className="col-9 add-room-shortcode">
              <div className="standard-page--label mb-3 pb-1">
                Select Hotel Room Type
              </div>
              {/*<div
                className="quick__payment__select__date__wrapper my-0"
                onClick={() => setFocusedInput("startDate")}
              >
                <div className="date__picker__icon">
                  <i className="bi bi-calendar"></i>
                </div>

                <div className="date__label">
                  {!startDate && !endDate
                    ? "Select Date Range"
                    : (startDate
                        ? moment(startDate).format("DD MMM YYYY")
                        : "") +
                      " - " +
                      (endDate ? moment(endDate).format("DD MMM YYYY") : "")}
                </div>
                <CustomDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  focusedInput={focusedInput}
                  preventPastDates={true}
                  setFocusedInput={setFocusedInput}
                />
              </div> */}

              <InputSelectField
                label={"Select Hotel Room Type"}
                value={selectedRoomType}
                options={allRoomType}
                handleChange={(e) => {
                  setSelectedRoomType(e.target.value);
                  ratePlanByRoomType(e.target.value);
                  setRatePlanSelected([]);
                }}
              />

              {/* <InputTextField
                label="Room Type Shortcode"
                value="Kailash"
                // value={shrtCode}
                // handleChange={shortCodeChangeHandler}
                // helperText={shrtCodeHelperText}
              /> */}
            </div>

            <div className="col-3 no_of_add-rooms_inputfield">
              <div className="standard-page--label mb-3">Room(s)</div>
              <div className="increment__decrement__btn mb-0">
                <div
                  className="decrement__btn"
                  onClick={() => decrement(noOfRooms, setNoOfRooms)}
                >
                  <i className="bi bi-dash changing--icon"></i>
                </div>
                <div className="changing__value">{noOfRooms}</div>
                <div
                  className="increment__btn"
                  onClick={() => increment(noOfRooms, setNoOfRooms)}
                >
                  <i className="bi bi-plus-lg changing--icon"></i>
                </div>
              </div>
            </div>
          </div>

          {/* Select Label Field */}
          <div className="rate__plan__options">
            {ratePlansById.length > 0 ? (
              // <div className="property__setup__room__details mb-0 mt-0 pt-0 pb-0">
              <div className="div__select__inputs__room_type__wrapper mt-0">
                {ratePlansById.map((obj: any) =>
                  obj.plan_type !== "OTHERS" ? (
                    <div
                      className={
                        ratePlanSelected.includes(obj?.rate_plan_id)
                          ? "me-4 select__input__div__active"
                          : "me-4 select__input__div"
                      }
                      onClick={() => setRatePlanSelected([obj?.rate_plan_id])}
                    >
                      {obj.plan_type}
                    </div>
                  ) : (
                    <div
                      className={
                        ratePlanSelected.includes(obj?.rate_plan_id)
                          ? "me-4 select__input__div__active"
                          : "me-4 select__input__div"
                      }
                      onClick={() => setRatePlanSelected([obj?.rate_plan_id])}
                    >
                      {`OTHERS (${obj.plan_name.slice(0, 3)}...)`}
                    </div>
                  )
                )}
              </div>
            ) : // </div>
              null}
          </div>

          <div className="property__setup__room__details mb-0 mt-0 pb-0">
            <div
              className="__default--input--field"
              style={{ marginRight: "0" }}
            >
              <InputTextField
                label="Booking Amount"
                // value="Kailash"
                value={bookingAmount}
                handleChange={(e) => {
                  setBookingAmount(e.target.value);
                }}
              // helperText={shrtRoomHelperText}
              />
            </div>
          </div>

          <div className="property__setup__room__details mb-0 mt-0 pb-0 pt-0">
            <InputTextField
              label="Comments"
              value={comment}
              handleChange={(e) => setComment(e.target.value)}
              multiline
            />
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          ></div>

          <div className="side__slider__panel__btn">
            <NextButton
              className={
                // !shrtRoomHelperText ?
                "bi bi-arrow-right"
                // : "inactiveBtn"
              }
              handleClick={() => onNextMove(tab)}
            >
              Next
            </NextButton>
          </div>
        </>
      )}

      {/* Room type amenities tab */}
      {tab === 1 && (
        <>
          <div className="property__setup__room__details mb-0 pb-0">
            <div
              className="__default--input--field"
              style={{ marginRight: "0" }}
            >
              <InputTextField
                label="Guest Name"
                value={guestName}
                handleChange={guestNameChangeHandler}
              // helperText={shrtRoomHelperText}
              />
            </div>
          </div>

          <div className="property__setup__room__details mb-0 pb-0 mt-0 pt-0">
            <div
              className="__default--input--field"
              style={{ marginRight: "0" }}
            >
              <InputTextField
                label="Mobile No."
                // type="number"
                value={mobileNo}
                handleChange={mobileNoChangeHandler}
              // helperText={shrtRoomHelperText}
              />
            </div>
          </div>

          <div className="property__setup__room__details mb-0 pb-0 mt-0 pt-0">
            <div
              className="__default--input--field"
              style={{ marginRight: "0" }}
            >
              <InputTextField
                label="Email Id"
                value={emailId}
                handleChange={emailIdChangeHandler}
                helperText={emailHelperText}
              // helperText={shrtRoomHelperText}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          ></div>
          <div className="side__slider__panel__btn">
            <NextButton
              className="bi bi-arrow-right"
              handleClick={() => onNextMove(tab)}
            >
              Next
            </NextButton>
          </div>
        </>
      )}

      {/* Room type occupancy tab */}
      {tab === 2 && (
        <>
          <div className="property__setup__room__details mb-0 pb-0">
            <div
              className="__default--input--field"
              style={{ marginRight: "0" }}
            >
              {paymentGateValue?.map((obj: any) => {
                return (
                  <div
                    className={
                      selectedPaymentGate.includes(obj)
                        ? "div__select__payment_gateway__wrapper__active"
                        : "div__select__payment_gateway__wrapper"
                    }
                    // className="div__select__payment_gateway__wrapper__active"
                    onClick={() => {
                      let arr: any = [];
                      arr.push(obj);
                      setSelectedPaymentGate(arr);
                    }}
                  >
                    <img
                      src={obj.logo}
                      alt={obj.paymentgateway_name}
                      className="payment__gate__logo"
                    />
                  </div>
                );
              })}
              {/* <InputTextField
                label="Guest Name"
                value={guestName}
                handleChange={guestNameChangeHandler}
                // helperText={shrtRoomHelperText}
              /> */}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          ></div>
          <div className="side__slider__panel__btn">
            <Button handleClick={addNewLink} loader={saveLoader}>
              Send
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default AddNewLink;
