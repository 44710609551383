import React, { useEffect } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import SelectProperty from "../pages/SelectProperty";
import DefaultLayout from "../layout/DefaultLayout";
import Error from "../pages/Error";

// import all the add-new-property pages
import PropertyType from "../pages/AddNewProperty/PropertyType";
import PropertySubType from "../pages/AddNewProperty/PropertySubType";
import PropertyDetails from "../pages/AddNewProperty/PropertyDetails";
import PropertyMapAddress from "../pages/AddNewProperty/PropertyMapAddress";
import PropertyAmenities from "../pages/AddNewProperty/PropertyAmenities";
import PropertyImages from "../pages/AddNewProperty/PropertyImages";
import PropertyOverview from "../pages/AddNewProperty/PropertyOverview";
import AddPropertySuccess from "../pages/AddNewProperty/AddPropertySuccess";

// import all the add room type pages
import EnterRoomTypeDetails from "../pages/AddRoomType/RoomTypeDetails";
import RoomTypeOccupancy from "../pages/AddRoomType/RoomTypeOccupancy";
import RoomTypeImages from "../pages/AddRoomType/RoomTypeImages";
import RoomTypePrice from "../pages/AddRoomType/RoomTypePrice";
import RoomTypeSuccess from "../pages/AddRoomType/RoomTypeSuccess";

// import all the floors
import CreateFloors from "../pages/AddFloors/CreateFloors";
import ServiceableFloors from "../pages/AddFloors/ServiceableFloors";
import FloorCreationSuccess from "../pages/AddFloors/FloorCreationSuccess";

// import all the add room pages
import AddRoomDetails from "../pages/AddRoom/AddRoomDetails";
import AddRoomsSuccess from "../pages/AddRoom/AddRoomsSuccess";
import PropertySetup from "../pages/PropertySetup/PropertySetup";

// import all the property setup pages
import BasicDetails from "../pages/PropertySetup/PropertyBasicDetails";
import PropertyRooms from "../pages/PropertySetup/PropertyRooms";
import PropertyRoomTypes from "../pages/PropertySetup/PropertyRoomTypes";
import PopertyAmenities from "../pages/PropertySetup/PropertyAmenities";
import PropertyImagesSetup from "../pages/PropertySetup/PropertyImages";
import PropertyFloors from "../pages/PropertySetup/PropertyFloors";
import PropertyTermsPolicy from "../pages/PropertySetup/PropertyTermsPolicy";
import PropertyFinancialDetails from "../pages/PropertySetup/PropertyFinancialDetails";
import PropertyCancellationRules from "../pages/PropertySetup/PropertyCancellationRules";
import PropertySeasonalPlan from "../pages/PropertySetup/PropertySeasonalPLan";
import SetupSeasonalPLan from "../pages/PropertySetup/SetupSeasonalPlan";
// import PropertyPaidServices from "../pages/PropertySetup/PropertyPaidServices";

// import all the app screen
import AppList from "../pages/Apps/AppList";
import AppDetails from "../pages/Apps/AppDetails";

// import inventory page
import Inventory from "../pages/Inventory/Inventory";

// import rates page
import Rate from "../pages/Rates/Rate";

// import bookings pages
import FrontofficeView from "../pages/Bookings/FrontofficeViewGems";
import CrsView from "../pages/Bookings/CrsView";
import ListView from "../pages/Bookings/ListView";
import CheckIn from "../pages/Bookings/CheckIn";
import StayDetails from "../pages/Bookings/StayDetails";
// import CheckOut from "../pages/Bookings/CheckOutOld";

//import manage users
import ManageUsers from "../pages/ManageUsers/ManageUsers";

// import Manage Channel Pages
import ManageChannel from "../pages/ManageChannel/ManageChannel";
import NearestLocation from "../pages/ManageChannel/BookingEngine/Location/NearestLocation";
import NearestPlaces from "../pages/ManageChannel/BookingEngine/Places/NearestPlaces";
import PackageList from "../pages/ManageChannel/BookingEngine/PackageList/PackageList";
import BookingEngine from "../pages/ManageChannel/BookingEngine/BookingEngine";
import BasicSetup from "../pages/ManageChannel/BookingEngine/BasicSetup/BasicSetup";
import Paidservice from "../pages/ManageChannel/BookingEngine/PaidServices/Paidservice";
import NotificationPopup from "../pages/ManageChannel/BookingEngine/NotificatrionPopUp/NotificationPopup";
import PromotionSlider from "../pages/ManageChannel/BookingEngine/PromotionalSlider/PromotionSlider";
import Coupon from "../pages/ManageChannel/BookingEngine/Coupon/Coupon";
import ManageSubscription from "../pages/ManageSubscription/ManageSubscription";
import UpgradeSubscription from "../pages/ManageSubscription/UpgradeSubscription";
import RatePlan from "../pages/PropertySetup/RatePlans/RatePlan";
import ChoosePlan from "../pages/ChoosePlan/ChoosePlan";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import kernelApi from "../API/kernelApi";
import {
  UserAcessDetails,
  UserAdminAcess,
} from "../redux/actions/UserAcessAction";
import OtaManage from "../pages/ManageChannel/OtherOTA/OtaManage";
import OtaBasicSetup from "../pages/ManageChannel/OtherOTA/OtaBasicSetup";
import RateSync from "../pages/ManageChannel/OtherOTA/RateSync/RateSync";
import RoomSync from "../pages/ManageChannel/OtherOTA/RoomSync/RoomSync";
import Mart from "../pages/Mart/Mart";
import ChoosePlanSuccess from "../pages/ChoosePlan/ChoosePlanSuccess";
import AirBnbCodeGet from "../pages/ManageChannel/OtherOTA/AirBnbCodeGet";
import RenewSubscription from "../pages/ChoosePlan/RenewSubscription";
import RenewSubscriptionSuccess from "../pages/ChoosePlan/RenewSubscriptionSuccess";
import PaymentOptions from "../pages/ManageChannel/BookingEngine/PaymentOptions/PaymentOptions";
import { Logout } from "../UtilityFunctions";
import LocaleInfo from "../pages/PropertySetup/LocaleInfo";
import DynamicPricingSetup from "../pages/PropertySetup/DynamicPricing/DynamicPricingSetup";
import UnpaidBookings from "../pages/ManageChannel/BookingEngine/UnpaidBookings/UnpaidBookings";
import QuickPaymentLink from "../pages/ManageChannel/BookingEngine/QuickPaymentLink/QuickPaymentLink";

import PromotionTypes from "../pages/Promotions/PromotionTypes";
import BasicPromotion from "../pages/Promotions/BasicPromotion/BasicPromotion";
import InactiveBasicPromotion from "../pages/Promotions/BasicPromotion/InactiveBasicPromotion";
import RoomRatePlan from "../pages/PropertySetup/RoomRatePlan/RoomRatePlan";
import Loginwithoutcredential from "../pages/Loginwithoutcredential";
import FrontofficeViewGems from "../pages/Bookings/FrontofficeViewGems";
import FrontOfficeViewNoGems from "../pages/Bookings/FrontOfficeViewNoGems";
import NoFrontOfficeView from "../pages/Bookings/NoFrontOfficeView";
import AdditionalCharges from "../pages/ManageChannel/BookingEngine/AdditionalCharges/AdditionalCharges";

const AppRoutes = () => {
  const { admin_id, company_id, single_sign_on_status } = useSelector(
    (state: RootState) => state.auth
  );
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // verifyAuthToken();
    getUserAcess(admin_id);
  }, [location]);

  // User Access Api Call
  const getUserAcess = async (userId: any) => {
    try {
      const userAccessReq = await kernelApi.get(`get-user-access/${userId}`);

      if (userAccessReq.data.status === 911) {
        dispatch(UserAdminAcess(userAccessReq.data.status));
      } else if (userAccessReq.data.status === 1) {
        dispatch(UserAcessDetails(userAccessReq.data.functions));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyAuthToken = async () => {
    try {
      const response = await kernelApi.get(
        `verify-token/${company_id}/${admin_id}`
      );

      if (response.data.status !== 1) {
        Logout();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Routes>
      {single_sign_on_status !== 0 && (
        <Route
          path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:subscription_customer_id"
          element={<Loginwithoutcredential />}
        />
      )}

      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Dashboard />} />
        {/* <Route path="inventory" element={<Outlet />}>
          <Route index element={<Inventory />} />
        </Route> */}
        <Route path="inventory" element={<Outlet />}>
          <Route index element={<Inventory />} />
        </Route>

        <Route path="rates" element={<Outlet />}>
          <Route index element={<Rate />} />
        </Route>
        {/* <Route path="rates" element={<Outlet />}>
          <Route index element={<Rates2 />} />
        </Route> */}

        <Route path="manage-users" element={<Outlet />}>
          <Route index element={<ManageUsers />} />
        </Route>
        <Route path="manage-subscription" element={<Outlet />}>
          <Route index element={<ManageSubscription />}></Route>
          <Route
            path="upgrade-subscription"
            element={<UpgradeSubscription />}
          />
        </Route>
        <Route path="bookings" element={<Outlet />}>
          <Route index element={<Navigate to="crs-view" />} />
          <Route
            path="gems/frontoffice-view"
            element={<FrontofficeViewGems />}
          />
          <Route path="frontoffice-view" element={<NoFrontOfficeView />} />
          <Route path="list-view" element={<ListView />} />
          <Route path="crs-view" element={<CrsView />} />
          <Route path="check-in" element={<CheckIn />} />
          <Route path="stay-details" element={<StayDetails />} />
          {/* <Route path="check-out" element={<CheckOut />} /> */}
        </Route>
        <Route path="guests" element={<h2>Content coming soon</h2>} />
        <Route path="campaigns" element={<h2>Content coming soon</h2>} />
        <Route path="analytics" element={<h2>Content coming soon</h2>} />
        <Route path="manage-channels" element={<Outlet />}>
          <Route index element={<ManageChannel />} />
          <Route path="booking-engine" element={<Outlet />}>
            <Route index element={<BookingEngine />} />
            <Route path="basic-setup" element={<BasicSetup />} />
            <Route path="nearest-location" element={<NearestLocation />} />
            <Route path="nearest-places" element={<NearestPlaces />} />
            <Route path="package-list" element={<PackageList />} />
            <Route path="basic-setup" element={<BasicSetup />} />
            <Route path="paid-service" element={<Paidservice />} />
            <Route path="notification-popup" element={<NotificationPopup />} />
            <Route path="promotional-slider" element={<PromotionSlider />} />
            <Route path="coupon" element={<Coupon />} />
            <Route path="payment-options" element={<PaymentOptions />} />
            <Route path="unpaid-bookings" element={<UnpaidBookings />} />
            <Route path="quick-payment-link" element={<QuickPaymentLink />} />
            <Route path="additional-charges" element={<AdditionalCharges />} />
          </Route>
          <Route path="ota-manage" element={<Outlet />}>
            <Route index element={<OtaManage />} />
            <Route path="ota-basic-setup" element={<OtaBasicSetup />} />
            <Route path="rate-sync" element={<RateSync />} />
            <Route path="room-sync" element={<RoomSync />} />
          </Route>
        </Route>
        <Route path="apps" element={<Outlet />}>
          <Route index element={<AppList />} />
          <Route path="app-details" element={<AppDetails />} />
        </Route>
        <Route path="property-setup" element={<Outlet />}>
          <Route index element={<PropertySetup />} />
          <Route path="basic-details" element={<BasicDetails />} />
          <Route path="room-types" element={<PropertyRoomTypes />} />
          <Route path="floors" element={<PropertyFloors />} />
          <Route path="rooms" element={<PropertyRooms />} />
          <Route path="amenities" element={<PopertyAmenities />} />
          <Route path="images" element={<PropertyImagesSetup />} />
          <Route path="terms-and-policy" element={<PropertyTermsPolicy />} />
          <Route
            path="financial-details"
            element={<PropertyFinancialDetails />}
          />
          <Route
            path="cancellation-rules"
            element={<PropertyCancellationRules />}
          />
          <Route path="rate-plan" element={<RatePlan />} />
          <Route path="locale-info" element={<LocaleInfo />} />
          <Route path="seasonal-plan" element={<Outlet />}>
            <Route index element={<PropertySeasonalPlan />} />
            <Route
              path="setup-seasonal-plan/:roomTypeId/:seasonalPlanId"
              element={<SetupSeasonalPLan />}
            />
          </Route>
          <Route path="dynamic-pricing" element={<DynamicPricingSetup />} />
          <Route path="room-rate-plan" element={<RoomRatePlan />} />
        </Route>

        <Route path="mart" element={<Outlet />}>
          <Route index element={<Mart />} />
        </Route>

        <Route path="promotion" element={<Outlet />}>
          <Route index element={<PromotionTypes />} />
          <Route path="basic-promotion" element={<BasicPromotion />} />
          <Route
            path="inactive-basic-promotion"
            element={<InactiveBasicPromotion />}
          />
        </Route>
      </Route>
      <Route path="/choose-plan" element={<Outlet />}>
        <Route index element={<ChoosePlan />} />
        <Route path="success" element={<ChoosePlanSuccess />}></Route>
        <Route path="renew-subscription" element={<RenewSubscription />} />
        <Route path="renew-success" element={<RenewSubscriptionSuccess />} />
      </Route>
      <Route path="/airbnb-code" element={<AirBnbCodeGet />} />
      <Route path="/login" element={<Navigate to={"/select-property"} />} />
      <Route path="/signup" element={<Navigate to={"/select-property"} />} />
      <Route path="/select-property" element={<SelectProperty />} />
      <Route path="/add-new-property" element={<Outlet />}>
        <Route index element={<Navigate to={"property-type"} />} />
        <Route path="property-type" element={<PropertyType />} />
        <Route path="property-subtype" element={<PropertySubType />} />
        <Route path="property-details" element={<PropertyDetails />} />
        <Route path="property-address" element={<PropertyMapAddress />} />
        <Route path="property-amenities" element={<PropertyAmenities />} />
        <Route path="property-images" element={<PropertyImages />} />
        <Route path="property-overview" element={<PropertyOverview />} />
        <Route path="success" element={<AddPropertySuccess />} />
      </Route>
      <Route path="/add-room-type" element={<Outlet />}>
        <Route index element={<Navigate to={"enter-room-details"} />} />
        <Route path="enter-room-details" element={<EnterRoomTypeDetails />} />
        <Route path="room-type-occupancy" element={<RoomTypeOccupancy />} />
        <Route path="room-type-price" element={<RoomTypePrice />} />
        <Route path="room-type-images" element={<RoomTypeImages />} />
        <Route path="success" element={<RoomTypeSuccess />} />
      </Route>
      <Route path="/add-floors" element={<Outlet />}>
        <Route index element={<Navigate to={"create-floors"} />} />
        <Route path="create-floors" element={<CreateFloors />} />
        <Route path="serviceable-floors" element={<ServiceableFloors />} />
        <Route path="success" element={<FloorCreationSuccess />} />
      </Route>
      <Route path="/add-rooms" element={<Outlet />}>
        <Route index element={<AddRoomDetails />} />
        <Route path="success" element={<AddRoomsSuccess />} />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default AppRoutes;
