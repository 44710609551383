import React from 'react';

const AppContent: React.FC = ({ children }) => {
    return (
        <>
            <section>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 background--color">
                                <div className="height-75px"></div>
                                <div className="main__content__wrapper">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AppContent
