import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { ProgressBar } from "react-step-progress-bar";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import { CATCH_MESSAGE, validatePositiveInt } from "../../UtilityFunctions";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";
import Button from "../../views/buttons/Button";
import NextButton from "../../views/buttons/NextButton";

import Loader from "../../views/loader/Loader";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import gemsApi from "../../API/gemsApi";

const AddRoomDetails = () => {
  const navigate = useNavigate();

  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { current_property, property_data } = useSelector((state: RootState) => state.properties);

  const [allFloors, setAllFloors] = useState<any[]>([]);
  const [allRoomTypes, setAllRoomTypes] = useState<any[]>([]);
  const [allRoomNumbers, setAllRoomNumbers] = useState<any[]>([]);

  const [selectedFloor, setSelectedFloor] = useState<any>();
  const [selectedRoomType, setSelectedRoomType] = useState<any>();
  const [rooms, setRooms] = useState<string[]>([]);
  const [roomTypesCount, setRoomTypesCount] = useState<any>();


  const [roomInput, setRoomInput] = useState("");
  const [roomInputError, setRoomInputError] = useState("");

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    getAllRooms();
    getAllFloors();
    getAllRoomTypes();
  }, [current_property]);

  const OnFloorSelect = (floor: any) => {
    setSelectedFloor(floor);
  };

  const OnRoomTypeSelect = (roomType: any) => {
    setRooms([]);
    setRoomInput("");
    setRoomInputError("");
    setSelectedRoomType(roomType);
  };

  const OnRoomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let room_type =
      allRoomTypes.filter(
        (room: any) => room.room_type_id === selectedRoomType.room_type_id
      )[0] || null;
    let val = e.target.value.trim();

    if (rooms.includes(val) || allRoomNumbers.includes(val)) {
      setRoomInputError("Room already added");
    } else if (room_type && room_type.total_rooms <= (roomTypesCount[room_type.room_type] || 0) + rooms.length) {
      setRoomInputError("Maximum number of rooms added for this room type");
    } else {
      setRoomInputError("");
    }

    if (validatePositiveInt(val) || !val) {
      setRoomInput(val);
    }
  };

  const RemoveRoom = (room: string) => {
    setRooms(rooms.filter((Room) => Room !== room));
  };

  const OnRoomEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (roomInput && !roomInputError) {
        if (!rooms.includes(roomInput) && !allRoomNumbers.includes(roomInput)) {
          setRooms([...rooms, roomInput]);
          setRoomInput("");
        }
      }
    }
  };

  const SubmitRooms = () => {
    if (selectedFloor && selectedRoomType && rooms.length > 0) {
      CreateRooms();
    } else {
      toast.error("Please fill all the details!");
    }
  };

  // API call to get all rooms data
  const getAllRooms = async () => {
    try {
      let response = await gemsApi.post("/getRooms2", {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
      });
      if (response.data.status === 1) {
        setAllRoomNumbers(response.data.allRooms);

        // calculate the total number of rooms for each room type
        let room_types_count: any = {};

        response.data.allFloors.forEach((floor: any) => {
          floor.rooms.forEach((room: any) => {
            if (room_types_count[room.room_type]) {
              room_types_count[room.room_type]++;
            } else {
              room_types_count[room.room_type] = 1;
            }
          });
        });

        setRoomTypesCount(room_types_count);
      } else {
        setRoomTypesCount(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // API call to get all floors of the current property
  const getAllFloors = async () => {
    try {
      let payload = {
        user_id: admin_id,
        hotel_id: current_property.hotel_id,
      };

      let res = await gemsApi.post("/getFloors", payload);
      if (res.data.status === 1) {
        let floors = res.data.allFloors.filter((floor: any) => {
          return floor.planned_for_room === true;
        });
        if (floors.length > 0) {
          setAllFloors(floors);
          setSelectedFloor(floors[0]);
        } else {
          // navigate("/add-floors")
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // API call to get all room types of the current property
  const getAllRoomTypes = async () => {
    try {
      const response = await kernelApi.get(
        "/hotel_master_room_type/all/" + current_property.hotel_id
      );

      if (response.data.status === 1) {
        setAllRoomTypes(response.data.data);

        if (response.data.data.length > 0) setSelectedRoomType(response.data.data[0])
      } else {
        // navigate("/add-room-type");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // API call for room creation
  const CreateRooms = async () => {
    try {
      let payload = {
        user_id: admin_id,
        hotel_id: current_property.hotel_id,
        room_type_id: selectedRoomType.room_type_id,
        floor_no: selectedFloor.floor_no,
        rooms: rooms,
      };

      let res = await gemsApi.post("/addRoom", payload);

      if (res.data.status === 1) {
        setShowNextButton(true);
        setSelectedFloor("");
        setSelectedRoomType("");
        setRoomInputError("");
        setRoomInput("");
        setRooms([]);
        getAllRooms();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(CATCH_MESSAGE);
    }
  };

  const OnNextClick = () => {
    navigate("/add-rooms/success");
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <LeftSideNav>
          <>
            <div className="div__user__testimonials__wrapper">
              <div className="screen__progress__wrapper">
                <div className="progress__status__wrapper">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">
                    Create {property_data.data.length === 1 ? "First" : ""}{" "}
                    Property
                  </div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Select Subscription Plan</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Create Room Type</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Create Floors</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper">
                    <i className="bi bi-play-fill progress--icon--play"></i>
                  </div>
                  <div className="status__label">Add Rooms</div>
                </div>
              </div>
            </div>

            <div className="progress-bar-wrapper">
              <div className="progress-bar-text">1/2</div>
              <div className="progress-bar-status">
                <ProgressBar percent={50} filledBackground="#FFFFFF" />
              </div>
            </div>
          </>
        </LeftSideNav>
        <RightSideContent>
          <>
            <CurrentPropertyLabel />
            <div className="div--head--label">Add Rooms</div>
            <div className="div--helper--text">
              Add your room number and select add button
            </div>

            <div className="add__room__wrapper_box">
              <div className="standard-page--label my-3 pt-2">
                Please select the floor
              </div>
              <div className="div__select__inputs__wrapper">
                {allFloors.length > 0 ? (
                  allFloors.map((floor: any, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => OnFloorSelect(floor)}
                        className={`select__input__div${floor === selectedFloor ? "__active" : ""
                          }`}
                      >
                        {floor === selectedFloor && (
                          <span>
                            <i className="bi bi-check2 icon--check"></i>
                          </span>
                        )}
                        {`${floor.floor_name} ${floor.floor_name === "Ground" ? "Floor" : ""
                          }`}
                      </div>
                    );
                  })
                ) : (
                  <Loader />
                )}
              </div>

              <div className="standard-page--label my-3 pt-2">
                Select Room Type
              </div>
              <div className="div__select__inputs__wrapper">
                {allRoomTypes.length > 0 ? (
                  allRoomTypes.map((roomType: any, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => OnRoomTypeSelect(roomType)}
                        className={`select__input__div${selectedRoomType === roomType ? "__active" : ""
                          }`}
                      >
                        {selectedRoomType === roomType && (
                          <span>
                            <i className="bi bi-check2 icon--check"></i>
                          </span>
                        )}
                        {roomType.room_type}
                      </div>
                    );
                  })
                ) : (
                  <Loader />
                )}
              </div>

              <div className="div__select__inputs__wrapper custom__wrapper__outlinedinput my-4 pt-2">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="add-rooms-input">Add Rooms</InputLabel>
                  <OutlinedInput
                    id="add-rooms-input"
                    label="Add Rooms"
                    style={{ backgroundColor: "white", maxWidth: "650px" }}
                    value={roomInput}
                    onChange={OnRoomInputChange}
                    onKeyPress={OnRoomEnter}
                    multiline
                    startAdornment={
                      rooms.length > 0 && (
                        <div className="rooms__input__container">
                          {rooms.map((room, index) => {
                            return (
                              <div key={index} className="room__number__div">
                                {room}
                                <i
                                  className="bi bi-x icon--close"
                                  onClick={() => RemoveRoom(room)}
                                ></i>
                              </div>
                            );
                          })}
                        </div>
                      )
                    }
                  />
                  <FormHelperText className="text-danger">
                    {roomInputError || ""}
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="__btn__wrapper mt-3">
                <Button handleClick={SubmitRooms}>Add Rooms</Button>
                {showNextButton && <NextButton handleClick={OnNextClick} />}
              </div>
            </div>
          </>
        </RightSideContent>
      </div>
    </div>
  );
};

export default AddRoomDetails;
