import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Button from "../../../../views/buttons/Button";
import Loader from "../../../../views/loader/Loader";
import SlidingPane from "react-sliding-pane";
import AddRoomSyncSlider from "./AddRoomSyncSlider";
import EditRoomSyncSlider from "./EditRoomSyncSlider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import cmApi from "../../../../API/cmApi";
import {
  Capitalize,
  CATCH_MESSAGE,
  Logout,
} from "../../../../UtilityFunctions";
import { toast } from "react-toastify";
import kernelApi from "../../../../API/kernelApi";
import {
  ShowConfirmationPrompt,
  CancelConfirmationPrompt,
} from "../../../../redux/actions/PromptAction";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import endPoints from "../../../../API/endPoints";

const RoomSync = () => {
  // Data From Redux
  const { ota } = useSelector((state: RootState) => state.manage_channels);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [savebtnloader, setSaveBtnLoader] = useState<boolean>(false);
  const [roomSyncData, setRoomSyncData] = useState<any[]>([]);
  const [allhotelRoomO, setAllHotelRoom] = React.useState<any[]>([]);
  const [otaRoomType, setOtaRoomType] = React.useState<any[]>([]);
  const [otaRoomTypeOptn, setOtaRoomTypeOptn] = React.useState<any[]>([]);
  const [otaRoomTypeOptnId, setOtaRoomTypeOptnId] = React.useState<any[]>([]);
  const [otaRoomTypeSelected, setOtaRoomTypeSelected] = useState<any[]>([]);
  const [nodataMessage, setNoDataMessage] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Endpoints Import
  const { getRoomMappingDataApi, syncOTAroomType, deleteRoomSyncApi } =
    endPoints.MANAGECHANNEL;
  // --------------------------------

  useEffect(() => {
    if (ota?.ota_id) {
      getRoomMappingData();
    }
  }, [current_property, ota]);

  // To get Room Mapping Data in A new APi
  const getRoomMappingData = async () => {
    try {
      let roomMappingResponse = await cmApi.get(
        `${getRoomMappingDataApi}/${current_property.hotel_id}/${ota?.ota_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (roomMappingResponse?.data?.status === 1) {
        setAllHotelRoom(roomMappingResponse?.data?.hotel_room_types?.data);
        setOtaRoomType(roomMappingResponse?.data?.data?.ota_room_types);

        const selectedOtaArray: any = [];
        const selectedOtaRoomType =
          roomMappingResponse?.data?.hotel_room_types?.data?.forEach(
            (syncData) => {
              if (syncData) {
                syncData.sync_data.forEach((element) => {
                  selectedOtaArray.push({
                    room_type_id: element.room_type_id,
                    ota_room_type: element.ota_room_type,
                    ota_room_type_name: element.ota_room_type_name,
                  });
                });
              }
            }
          );

        setOtaRoomTypeSelected(selectedOtaArray);
        const arroptn = ["Not Mapped"];
        roomMappingResponse?.data?.data?.ota_room_types.map((item) => {
          arroptn.push(`${item.ota_room_type_name} (${item.ota_room_type_id})`);
        });

        setOtaRoomTypeOptn(arroptn);

        const arrId = [0];
        roomMappingResponse?.data?.data?.ota_room_types.map((item) => {
          arrId.push(item.ota_room_type_id);
        });

        setOtaRoomTypeOptnId(arrId);

        const syncDataArray: any = [];
        const syncedDataDetails =
          roomMappingResponse?.data?.hotel_room_types?.data?.forEach(
            (syncData) => {
              if (syncData) {
                syncData.sync_data.forEach((element) => {
                  syncDataArray.push(element);
                });
              }
            }
          );

        setRoomSyncData(syncDataArray);
      } else {
        setNoDataMessage(roomMappingResponse.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  // OTA Room Type Selection Handler
  const OtaRoomTypeSelectionHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    roomId: any
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (otaRoomTypeSelected.map((room) => room.room_type_id).includes(roomId)) {
      const index = otaRoomTypeSelected.findIndex(
        (item) => item.room_type_id === roomId
      );
      otaRoomTypeSelected.splice(index, 1);

      const selected = [
        ...otaRoomTypeSelected,
        {
          room_type_id: roomId,
          ota_room_type: e.target.value,
          ota_room_type_name: otaRoomType?.filter(
            (room: any) => room?.ota_room_type_id === e.target.value
          )[0]?.ota_room_type_name,
        },
      ];
      setOtaRoomTypeSelected(selected);
    } else {
      const selected = [
        ...otaRoomTypeSelected,
        {
          room_type_id: roomId,
          ota_room_type: e.target.value,
          ota_room_type_name:
            otaRoomType?.filter(
              (room: any) => room?.ota_room_type_id === e.target.value
            )[0]?.ota_room_type_name ?? "",
        },
      ];
      setOtaRoomTypeSelected(selected);
    }
  };

  // Save Sync OTA Room Type

  const saveSyncRoomOTA = async () => {
    var otaRoomType = otaRoomTypeSelected?.map((item) => item.ota_room_type);
    var isDuplicate =
      Array.isArray(otaRoomType) &&
      otaRoomType?.some((item, idx) => {
        return otaRoomType?.indexOf(item) != idx;
      });

    if (isDuplicate) {
      toast.error("Duplicate OTA Room Type");
    } else {
      // setLoader(true);
      setSaveBtnLoader(true);
      try {
        const response = await cmApi.post(
          syncOTAroomType,
          {
            hotel_id: current_property?.hotel_id,
            ota_type_id: ota?.ota_id,
            room_type_details: otaRoomTypeSelected,
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );

        if (response.data.status === 1) {
          toast.success(response.data.message);
          getRoomMappingData();
          // setLoader(false);
          setSaveBtnLoader(false);
        } else {
          toast.error(response.data.message);
          // setLoader(false);
          setSaveBtnLoader(false);
        }
      } catch (err) {
        toast.error(CATCH_MESSAGE);
        // setLoader(false);
        setSaveBtnLoader(false);
      }
    }
  };

  //
  const handleEntailmentRequest = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Delete Room Sync
  const deleteRoomSync = (id: number) => {
    let planId = roomSyncData?.filter((item) => item?.room_type_id === id)[0]
      ?.id;

    dispatch(
      ShowConfirmationPrompt(
        "Are you sure remove this mapping ?",
        "DELETE",

        async () => {
          // setLoader(true);

          if (planId) {
            try {
              const deleteresponse = await cmApi.delete(
                `${deleteRoomSyncApi}/${planId}`,
                {
                  headers: {
                    Authorization: "Bearer " + auth_token,
                  },
                }
              );

              if (deleteresponse.data.status === 1) {
                toast.success(deleteresponse.data.message);
                getRoomMappingData();
                // setLoader(false);
                setSaveBtnLoader(false);
              } else {
                toast.error(deleteresponse.data.message);
                // setLoader(false);
                setSaveBtnLoader(false);
                getRoomMappingData();
              }
            } catch (err: any) {
              toast.error(CATCH_MESSAGE);
              // setLoader(false);
              setSaveBtnLoader(false);
              if (err.response.status === 400) {
                Logout();
              }
            }
          } else {
            getRoomMappingData();
          }

          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };

  return (
    <>
      {/* <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            { label: ota.ota_name, href: "/manage-channels/ota-manage" },
            { label: "Room Mapping" },
          ]}
        />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div> */}

      {loader ? (
        <div>
          <Loader className="d-flex justify-content-center align-items-center" />
        </div>
      ) : allhotelRoomO && allhotelRoomO?.length > 0 ? (
        <div className="ota-view-table-wrapper">
          <table className="table table-borderless">
            <thead>
              <tr className="ota__table__heading">
                <th>Sl.No</th>
                <th>Hotel Room Type</th>
                <th>
                  {ota.ota_name.toLowerCase() === "airbnb"
                    ? `${ota.ota_name} Listing`
                    : `${ota.ota_name} Room Type `}{" "}
                </th>
                <th className="ota__table__heading__right">Remove Mapping</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={8}>
                    <div>
                      <Loader className="d-flex justify-content-center align-items-center" />
                    </div>
                  </td>
                </tr>
              ) : (
                allhotelRoomO.map((item: any, idx: any) => (
                  <>
                    <tr className="ota__table__data" key={item?.room_type_id}>
                      <td>
                        {otaRoomTypeSelected
                          ?.map((room) => room.room_type_id)
                          .includes(item?.room_type_id) ? (
                          <div className="serial-number-mapped">{idx + 1}</div>
                        ) : (
                          <div className="serial-number">{idx + 1}</div>
                        )}
                      </td>
                      <td>
                        <div className="room-type">{`${Capitalize(
                          item?.room_type
                        )}(${item?.room_type_id})`}</div>
                      </td>
                      <td className="ota-room-type">
                        <InputSelectField
                          label={"OTA Room Type"}
                          value={
                            otaRoomTypeSelected?.filter((i: any) => {
                              return i?.room_type_id === item?.room_type_id;
                            })[0]?.ota_room_type ?? 0
                          }
                          options={otaRoomTypeOptn}
                          values={otaRoomTypeOptnId}
                          handleChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            OtaRoomTypeSelectionHandler(e, item?.room_type_id);
                          }}
                          disabledOptions={[0]}
                        />
                      </td>
                      <td>
                        <div className="d-flex">
                          {otaRoomTypeSelected
                            ?.map((room) => room.room_type_id)
                            .includes(item?.room_type_id) ? (
                            <div
                              className="customer-delete-button"
                              onClick={(e) => {
                                handleEntailmentRequest(e);
                                deleteRoomSync(item?.room_type_id);
                              }}
                            >
                              Remove Mapping
                            </div>
                          ) : (
                            <div className="customer-delete-button-disabled">
                              Remove Mapping
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                ))
              )}
            </tbody>
          </table>
          <div className="save-btn">
            <Button
              handleClick={() => saveSyncRoomOTA()}
              loader={savebtnloader}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>{`${nodataMessage ? nodataMessage : "Ota is not Added"}`}</div>
        </div>
      )}
    </>
  );
};

export default RoomSync;
