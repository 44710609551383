import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputtextfield/InputTextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import InputMultiSelectField from "../../../views/inputtextfield/InputMultiSelectField";
import beApi from "../../../API/beApi";
import cmApi from "../../../API/cmApi";
import endPoints from "../../../API/endPoints";


export interface IAddBasicPromotionProps {
    onClose: () => void;
    allChannels: any[];
    refreshPromotions:()=> void;
}

const AddBasicPromotion: React.FC<IAddBasicPromotionProps> = ({
    onClose,
    allChannels,
    refreshPromotions
}) => {

    // Data From Redux
    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );

    const { getRoomTypeRatePlans,insertBasicPromotion } = endPoints.MANAGEBASICPROMOTION;


    // States
    const [loader, setLoader] = useState<boolean>(false);

    const [openDatepickerStayStartDate, setOpenDatepickerStayStartDate] = useState(false);
    const [openDatepickerStayEndDate, setOpenDatepickerStayEndDate] = useState(false);

    const [openDatepickerBookingStartDate, setOpenDatepickerBookingStartDate] = useState(false);
    const [openDatepickerBookingEndDate, setOpenDatepickerBookingEndDate] = useState(false);


    const [openDatepickerBlackoutDate, setOpenDatepickerBlackoutDate] = useState(false);



    const [stayStartDate, setStayStartDate] = useState<any>(moment());
    const [stayEndDate, setStayEndDate] = useState<any>(moment().add(1, 'd'));

    const [bookingStartDate, setBookingStartDate] = useState<any>(moment());
    const [bookingEndDate, setBookingEndDate] = useState<any>(moment().add(1, 'd'));


    const [promotionName, setPromotionName] = useState<string>('');
    const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
    const [selectedPromotionValueType, setPromotionValueType] = useState<number>(0);
    const [discountValue, setdDiscountValue] = useState<string>('');
    const [selectedPromotionAvailableFor, setPromotionAvailableFor] = useState<number>(0);

    const [disableStayEndDate, setDisableStayEndDate] = useState(false);
    const [disableBookingEndDate, setDisableBookingEndDate] = useState(false);

    const [enableBlackoutDates, setEnableBlackoutDates] = useState(0);

    const [singleDate, setSingleDate] = useState<any>(null);
    const [singleDates, setSingleDates] = useState<any[]>([]);

    const [selectedAllRoomRatePlans, setSelectedAllRoomRatePlans] = useState<any>([]);


    useEffect(() => {
        fetchRoomTypeRatePlans();
    }, [current_property]);

    const fetchRoomTypeRatePlans = async () => {
        try {
            let selected_all_room_rate_plans: any = [];

            let room_type_rate_plans_data = await beApi.get(`${getRoomTypeRatePlans}/${current_property.hotel_id}`);

            if (room_type_rate_plans_data.data.status === 1) {
                let hotel_room_data: any = room_type_rate_plans_data.data


                if (hotel_room_data && hotel_room_data.data) {
                    for (let i = 0; i < hotel_room_data.data.length; i++) {
                        selected_all_room_rate_plans.push({ room_type_id: hotel_room_data.data[i].room_type_id, room_type: hotel_room_data.data[i].room_type, selected_rate_plans: [] });
                        for (let j = 0; j < hotel_room_data.data[i].rate_plans.length; j++) {
                            selected_all_room_rate_plans[i].selected_rate_plans.push(hotel_room_data.data[i].rate_plans[j].rate_plan_id)
                        }

                    }
                }

                setSelectedAllRoomRatePlans(selected_all_room_rate_plans);
            }
        }
        catch (error: any) {
            toast.error(CATCH_MESSAGE);
        }
    }



    const onSelectingBlackoutDate = (date: any) => {
        let dateList = [...singleDates];
        let dateValue = moment(date).format("YYYY-MM-DD");

        if (dateValue !== "Invalid date" && !dateList.includes(dateValue)) {
            dateList.push(dateValue);
        }
        setSingleDate(moment(date));
        setSingleDates(dateList);
    }


    const isAllSelected =
        allChannels.length > 0 &&
        selectedChannels.length === allChannels.length;

    const sourceOnchange = (e: any) => {
        const value = e.target.value;

        let all_channels_names:any = [];
        for(let channel_value of allChannels){
            all_channels_names.push(channel_value.ota_name)
        }

        if (value[value.length - 1] === "all") {
            setSelectedChannels(
                selectedChannels.length === allChannels.length ? [] : all_channels_names
            );
            return;
        }
        setSelectedChannels(value);
    };


    const removeDate = (date: any) => {
        let dateList = [...singleDates];
        let dateValue = moment(date).format("DD-MM-YYYY");
        dateList.splice(dateList.indexOf(dateValue), 1);
        setSingleDates(dateList);
    };


    const addBasicPromotion = async () => {


        if (!promotionName) {
            toast.error("Promotion name not provided !");
            return;
        }


        if (selectedChannels.length == 0) {
            toast.error("Please select Channel !");
            return;
        }

        if (!discountValue) {
            toast.error("Discount value not provided !");
            return;
        }

        if (promotionName && discountValue && selectedChannels.length > 0) {
            let stay_end_date = '';
            let bkg_start_date = '';
            let bkg_end_date = '';
            let black_out_dates: any = [];
            let selected_room_rate_plans = [];
            let selected_channels: any = [];


            if (selectedPromotionAvailableFor == 1) {
                bkg_start_date = moment(bookingStartDate).format("DD-MM-YYYY");

                if (disableBookingEndDate == false) {
                    bkg_end_date = moment(bookingEndDate).format("DD-MM-YYYY");
                }
            }

            if (disableStayEndDate == false) {
                stay_end_date = moment(stayEndDate).format("DD-MM-YYYY");
            }

            if (enableBlackoutDates == 1) {
                for(let dates_value of singleDates){
                    black_out_dates.push(moment(dates_value).format("DD-MM-YYYY"));
                }
            }

            selected_room_rate_plans = selectedAllRoomRatePlans;

            for (let channel_value of selectedChannels) {
                for (let channel_data of allChannels) {
                    if (channel_value === channel_data.ota_name) {
                        selected_channels.push(channel_data.ota_id)
                    }
                }
            }


            try {

                setLoader(true);
                let post_promotion_data = await cmApi.post(insertBasicPromotion,
                    {
                        hotel_id: current_property.hotel_id,
                        promotion_name: promotionName,
                        ota: selected_channels,
                        offer_type: selectedPromotionValueType,
                        discount: discountValue,
                        applicable_for: selectedPromotionAvailableFor,
                        stay_start_date: moment(stayStartDate).format("DD-MM-YYYY"),
                        stay_end_date: stay_end_date,
                        booking_start_date: bkg_start_date,
                        booking_end_date: bkg_end_date,
                        blackout_option: enableBlackoutDates,
                        blackout_dates: black_out_dates,
                        applicable_for_room_rateplan: 1,
                        selected_room_rateplan: selected_room_rate_plans,

                    })

                if (post_promotion_data.data.status === 1) {
                    setLoader(false);

                    toast.success(post_promotion_data.data.message);
                    refreshPromotions();
                    onClose();
                }
                else {
                    setLoader(false);

                    toast.error(post_promotion_data.data.message);
                    refreshPromotions();
                    onClose();
                }
            }
            catch (error: any) {
                toast.error(CATCH_MESSAGE);
            }

        }
    }



    return (
        <div className="add__promotion_wrapper">
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Add Basic Promotion</div>
            </div>
            <div className="height-64px"></div>


            <div className="slider-content-wrapper">
                <div className="mb-1">
                    <InputTextField
                        label={"Promotion Name"}
                        value={promotionName}
                        handleChange={(e: any) => { setPromotionName(e.target.value) }}
                    />
                </div>

                <div className="select__channels mb-3">
                    <InputMultiSelectField
                        label={"Select Channels"}
                        value={selectedChannels}
                        handleChange={(evnt) => sourceOnchange(evnt)}
                        options={allChannels.map(
                            (data: any) => {
                                return data.ota_name;
                            }
                        )}
                        isAllSelected={isAllSelected}
                    />
                </div>

                <div className="standard-page--label mb-3">Select Offer Type</div>
                <div className="select__input__label mb-4">

                    <div className={`me-4 select__input__div${selectedPromotionValueType === 0 ? '__active' : ''}`} onClick={() => setPromotionValueType(0)}>{selectedPromotionValueType === 0 && <i className="bi bi-check-lg icon--check"></i>}Percentage</div>

                    <div className={`me-4 select__input__div${selectedPromotionValueType === 1 ? '__active' : ''}`} onClick={() => setPromotionValueType(1)}>{selectedPromotionValueType === 1 && <i className="bi bi-check-lg icon--check"></i>}Fixed</div>

                </div>

                <div>
                    <InputTextField
                        label={selectedPromotionValueType === 0 ? 'Discount in %' : 'Discount'}
                        value={discountValue}
                        handleChange={(e: any) => setdDiscountValue(e.target.value)}
                    />
                </div>

                <div className="standard-page--label mb-3">This promotion will be available for ?</div>
                <div className="select__input__label mb-4">

                    <div className={`me-4 select__input__div${selectedPromotionAvailableFor === 0 ? '__active' : ''}`} onClick={() => setPromotionAvailableFor(0)}>{selectedPromotionAvailableFor === 0 && <i className="bi bi-check-lg icon--check"></i>}Stay Date</div>

                    <div className={`me-4 select__input__div${selectedPromotionAvailableFor === 1 ? '__active' : ''}`} onClick={() => setPromotionAvailableFor(1)}>{selectedPromotionAvailableFor === 1 && <i className="bi bi-check-lg icon--check"></i>}Booking & Stay Date</div>

                </div>

                <div className="date__wrapper mb-4">
                    <div className="date__label_left">
                        <div className="standard-page--label">Stay Date</div>
                    </div>
                    <div className="date__label__right">
                        <div className="standard-page--label me-2">No End Date</div>
                        <div className="toogle__switch">
                            <div className="form-check form-switch custom-switch-add-promotion">
                                <input type="checkbox"
                                    className="form-check-input"
                                    checked={disableStayEndDate}
                                    onChange={() => setDisableStayEndDate(!disableStayEndDate)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="datepicker__wrapper mb-5">
                    <div className="date__picker__display">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start date"
                                open={openDatepickerStayStartDate}
                                onOpen={() => setOpenDatepickerStayStartDate(true)}
                                onClose={() => setOpenDatepickerStayStartDate(false)}
                                value={stayStartDate}
                                onChange={(date: any) => { setStayStartDate(moment(date)); setStayEndDate(moment(date).add(1, 'd')) }}
                                minDate={new Date()}
                                inputFormat="dd MMM yyyy"
                                disableMaskedInput
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onClick={() => setOpenDatepickerStayStartDate(true)}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>

                    {disableStayEndDate === false && <div className="date__picker__display">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="End Date"
                                open={openDatepickerStayEndDate}
                                onOpen={() => setOpenDatepickerStayEndDate(true)}
                                onClose={() => setOpenDatepickerStayEndDate(false)}
                                value={stayEndDate}
                                onChange={(date: any) => setStayEndDate(moment(date))}
                                minDate={new Date(moment(stayEndDate).format("YYYY-MM-DD"))}
                                inputFormat="dd MMM yyyy"
                                disableMaskedInput
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onClick={() => setOpenDatepickerStayEndDate(true)}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>}



                </div>


                {selectedPromotionAvailableFor === 1 && <>
                    <div className="date__wrapper mb-4">
                        <div className="date__label_left">
                            <div className="standard-page--label">Booking Date</div>
                        </div>
                        <div className="date__label__right">
                            <div className="standard-page--label me-2">No End Date</div>
                            <div className="toogle__switch">
                                <div className="form-check form-switch custom-switch-add-promotion">
                                    <input type="checkbox"
                                        className="form-check-input"
                                        checked={disableBookingEndDate}
                                        onChange={() => setDisableBookingEndDate(!disableBookingEndDate)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="datepicker__wrapper mb-5">
                        <div className="date__picker__display">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start date"
                                    open={openDatepickerBookingStartDate}
                                    onOpen={() => setOpenDatepickerBookingStartDate(true)}
                                    onClose={() => setOpenDatepickerBookingStartDate(false)}
                                    value={bookingStartDate}
                                    onChange={(date: any) => { setBookingStartDate(moment(date)); setBookingEndDate(moment(date).add(1, 'd')) }}
                                    minDate={new Date()}
                                    inputFormat="dd MMM yyyy"
                                    disableMaskedInput
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onClick={() => setOpenDatepickerBookingStartDate(true)}
                                            onKeyDown={(e) => e.preventDefault()}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>

                        {disableBookingEndDate === false && <div className="date__picker__display">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="End Date"
                                    open={openDatepickerBookingEndDate}
                                    onOpen={() => setOpenDatepickerBookingEndDate(true)}
                                    onClose={() => setOpenDatepickerBookingEndDate(false)}
                                    value={bookingEndDate}
                                    onChange={(date: any) => setBookingEndDate(moment(date))}
                                    minDate={new Date(moment(bookingEndDate).format("YYYY-MM-DD"))}
                                    inputFormat="dd MMM yyyy"
                                    disableMaskedInput
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onClick={() => setOpenDatepickerBookingEndDate(true)}
                                            onKeyDown={(e) => e.preventDefault()}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>}
                    </div>
                </>
                }


                <div className="blackout__wrapper mb-5">
                    <div className="blackout__question mb-4">Do you want to blackout the offer for specific stay date ?</div>
                    <div className="blackout__checkbox_wrapper">
                        <div className="blackout__options__label me-4">
                            <input type="checkbox" className="form-check-input custom-size me-3"
                                checked={enableBlackoutDates === 1}
                                onChange={() => setEnableBlackoutDates(1)}
                            /> Yes
                        </div>
                        <div className="blackout__options__label">
                            <input type="checkbox" className="form-check-input custom-size me-3"
                                checked={enableBlackoutDates === 0}
                                onChange={() => setEnableBlackoutDates(0)}
                            /> No
                        </div>
                    </div>
                </div>

                {enableBlackoutDates === 1 && <div className="mb-3">
                    <div className="blackout_dates_value_container">
                        <div className="blackout_dates_value_container__input mui__input__fieldset__legends__unset">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Choose Blackout Dates"
                                    open={openDatepickerBlackoutDate}
                                    onOpen={() => setOpenDatepickerBlackoutDate(true)}
                                    onClose={() => setOpenDatepickerBlackoutDate(false)}
                                    value={singleDate}
                                    onChange={onSelectingBlackoutDate}
                                    minDate={new Date()}
                                    inputFormat="dd MMM yyyy"
                                    toolbarPlaceholder="Select Date"
                                    InputAdornmentProps={{ position: "start" }}
                                    disableMaskedInput
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onClick={() => setOpenDatepickerBlackoutDate(true)}
                                            onKeyDown={(e) => e.preventDefault()}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="blackout__dates__list">
                        {singleDates?.map((date: any) => {
                            return (
                                <div className="individual_dates" key={date}>
                                    {moment(date).format("DD MMM YYYY")}
                                    <i
                                        className="bi bi-x icon--close"
                                        onClick={() => removeDate(date)}
                                    ></i>
                                </div>
                            );
                        })}
                    </div>
                </div>}

                <div className="blackout__wrapper mb-3">
                    <div className="blackout__question mb-4">Wil the promotion be applicable for all the room rate plan ?</div>
                    <div className="blackout__checkbox_wrapper">
                        <div className="blackout__options__label">
                            <input type="checkbox" className="form-check-input custom-size me-3" checked readOnly /> Yes
                        </div>
                    </div>
                </div>

            </div>
            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>



            <div className="side__slider__panel__btn">
                <div className="button__wrapper ">
                    <Button
                        className="me-3"
                        handleClick={addBasicPromotion}
                        loader={loader}
                    >
                        Submit
                    </Button>
                    <Button className="cancel__btn" handleClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </div>


        </div>
    );
};

export default AddBasicPromotion;
