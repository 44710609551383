import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { useNavigate } from "react-router-dom";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";
import InputSelectField from "../../views/inputtextfield/InputSelectField";

import Button from "../../views/buttons/Button";
import SaveButton from "../../views/buttons/Button";

import GoogleMapReact from "google-map-react";

// import sliding pane
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import kernelApi from "../../API/kernelApi";
import endPoints from "../../API/endPoints";

import {
  CATCH_MESSAGE,
  Convert12Hto24H,
  Convert24Hto12H,
  Format12HrTime,
  Logout,
  UpdateSidebar,
  validateEmail,
  validateLandline,
  validateMobile,
  validatePinCode,
  validUrl,
} from "../../UtilityFunctions";

import { toast } from "react-toastify";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import InputTextField_Add from "../../views/inputtextfield/InputTextFeild_AddBtn";
// import AddPropertyMobileSlider from "./PropertyBasicSetupAddMobileSlider";
// import AddPropertyMailSlider from "./PropertyBasicSetupAddMailSlider";
// import { GetPropertyMailData } from "../../redux/actions/AddPropertyMails";
// import { GetPropertyMobileData } from "../../redux/actions/AddPropertyMobiles";

export interface IMarker {
  text?: string;
  lat?: number | string;
  lng?: number | string;
}

const PropertyBasicDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // endPoints Imports
  const {
    hotelkiteCountryDetails,
    bookingjiniCountryDetails,
    getAllHotelsById,
    updateNewProperty,
    getStatesByCountryId,
    updatePropertyAddress
  } = endPoints.PROPERTY_BASIC_DETAILS;

  UpdateSidebar("Property Setup", "Basic Details");

  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    landline: "",
    description: "",
    whatsappnumber: "",
    facebook_link: "",
    twitter_link: "",
    instagram_link: "",
    linkedin_link: "",
    tripadviser_link: "",
    holidayiq_link: "",
  });

  const INVALID_URL = "Please enter a valid URL";

  const [propertyDetails, setPropertyDetails] = useState<any>();
  const [openSlidePanelAddress, setOpenSlidePanelAddress] = useState(false);

  const [checkInTimeStatus, setCheckInTimeStatus] = useState("");
  const [checkOutTimeStatus, setCheckOutTimeStatus] = useState("");

  const [enableTime, setEnableTime] = useState(true);

  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");

  const [mobileError, setMobileError] = useState("");
  const [whatsappError, setWhatsappError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [landlineError, setLandlineError] = useState("");
  const [pinError, setPinError] = useState("");
  const [loader, setLoader] = useState<boolean>(false);

  const [allCountries, setAllCountries] = useState<any[]>([]);
  const [allStates, setAllStates] = useState<any[]>([]);

  const [socialMediaError, setSocialMediaError] = useState({
    facebookError: false,
    twitterError: false,
    linkedinError: false,
    instargramError: false,
    tripadError: false,
    holidayiqError: false,
  });

  const [countryId, setCountryId] = useState<any[]>([]);
  const [addressForm, setAddressForm] = useState({
    street: "",
    flat: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    lat: 0,
    lng: 0,
  });

  // const [email, setEmail] = useState('')
  // const [number, setNumber] = useState('')
  // -----------------------------------------
  const [email, setEmail] = useState<any>([])
  const [number, setNumber] = useState<any>([])
  const [emailInput, setEmailInput] = useState('')
  const [numberInput, setNumberInput] = useState('')

  const [emailValues, setEmailValues] = useState<any>([])
  const [numberValues, setNumberValues] = useState<any>([])

  useEffect(() => {
    if (!basicDetails.email && !basicDetails.mobile) return
    setEmail(basicDetails?.email)
    setNumber(basicDetails?.mobile)
    setNumberValues(basicDetails?.mobile)
    setEmailValues(basicDetails?.email)
  }, [basicDetails])
  // -----------------------------------------

  const { auth_token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    getAllhotelDetails();
  }, [current_property]);

  // google map marker component
  const Marker: React.FC<IMarker> = () => {
    return <div className={`google__map__marker__details`}></div>;
  };

  const getcountry = () => {
    let countryName: any = [];
    countryId.map((item) => {
      countryName.push(item.country_name);
    });
    return countryName;
  };

  const LandlineOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({ ...basicDetails, landline: e.target.value });
    if (!validateLandline(e.target.value) && e.target.value) {
      setLandlineError("Please enter valid landline number");
    } else {
      setLandlineError("");
    }
  };

  const onWhatsAppNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({ ...basicDetails, whatsappnumber: e.target.value });
    if (!validateMobile(e.target.value) && e.target.value) {
      setWhatsappError("Please enter valid whatsapp number");
    } else {
      setWhatsappError("");
    }
  };

  const facebooklinkOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetails,
      facebook_link: e.target.value,
    });

    if (!validUrl(e.target.value) && e.target.value) {
      setSocialMediaError({
        ...socialMediaError,
        facebookError: true,
      });
    } else {
      setSocialMediaError({
        ...socialMediaError,
        facebookError: false,
      });
    }
  };

  const twitterlinkOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetails,
      twitter_link: e.target.value,
    });
    if (!validUrl(e.target.value) && e.target.value) {
      setSocialMediaError({
        ...socialMediaError,
        twitterError: true,
      });
    } else {
      setSocialMediaError({
        ...socialMediaError,
        twitterError: false,
      });
    }
  };

  const linkedinOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetails,
      linkedin_link: e.target.value,
    });
    if (!validUrl(e.target.value) && e.target.value) {
      setSocialMediaError({
        ...socialMediaError,
        linkedinError: true,
      });
    } else {
      setSocialMediaError({
        ...socialMediaError,
        linkedinError: false,
      });
    }
  };
  const instagramOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetails,
      instagram_link: e.target.value,
    });
    if (!validUrl(e.target.value) && e.target.value) {
      setSocialMediaError({
        ...socialMediaError,
        instargramError: true,
      });
    } else {
      setSocialMediaError({
        ...socialMediaError,
        instargramError: false,
      });
    }
  };
  const tripadOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetails,
      tripadviser_link: e.target.value,
    });
    if (!validUrl(e.target.value) && e.target.value) {
      setSocialMediaError({
        ...socialMediaError,
        twitterError: true,
      });
    } else {
      setSocialMediaError({
        ...socialMediaError,
        twitterError: false,
      });
    }
  };
  const holidayiqOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetails,
      holidayiq_link: e.target.value,
    });
    if (!validUrl(e.target.value) && e.target.value) {
      setSocialMediaError({
        ...socialMediaError,
        twitterError: true,
      });
    } else {
      setSocialMediaError({
        ...socialMediaError,
        twitterError: false,
      });
    }
  };

  // API call to get all country details
  const getAllCountries = async () => {
    try {
      const url = window.location.origin.includes("hotelkite")
        ? `${hotelkiteCountryDetails}`
        : `${bookingjiniCountryDetails}`;
      const response = await kernelApi.get(url);
      if (response.data.status === 1) {
        setCountryId(response.data.all_countries);
        setAllCountries(response.data.all_countries);
        getcountry();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // API call to get all states details from country id
  const getStatesFromCountryId = async (country: string) => {
    try {
      const countryID =
        allCountries.find((item: any) => item.country_name === country)
          .country_id || 0;
      if (countryID) {
        const response = await kernelApi.get(
          `${getStatesByCountryId}${countryID}`,
          {
            headers: {
              Authorization: `Bearer ${auth_token}`,
            },
          }
        );

        if (response.data.status === 1) {
          setAllStates(response.data.states);
        } else {
          setAllStates([]);
        }
      }
    } catch (error: any) {
      setAllStates([]);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Handler function to open sliding panel and getting all state name from country id
  const openSlidePanelFunction = (country: string) => {
    setOpenSlidePanelAddress(true);
    getStatesFromCountryId(country);
  };

  // Executing getStateFromCountryId function on manually secting country while updating Map address
  const countryState = (e: any) => {
    setAddressForm({
      ...addressForm,
      country: e.target.value,
    });
    getStatesFromCountryId(e.target.value);
  };

  // API call to get hotel details by id
  const getAllhotelDetails = async () => {
    setLoader(true);
    try {
      let response = await kernelApi.get(
        `${getAllHotelsById}${current_property.hotel_id}`
      );

      if (response.data.status === 1) {
        let data = response.data.data;
        if (data.round_clock_check_in_out !== 1) {
          setEnableTime(false);
        }

        setCheckInTime(Convert24Hto12H(data.check_in).slice(0, 5));
        setCheckOutTime(Convert24Hto12H(data.check_out).slice(0, 5));
        setCheckInTimeStatus(Convert24Hto12H(data.check_in).substring(6, 8));
        setCheckOutTimeStatus(Convert24Hto12H(data.check_out).substring(6, 8));

        setPropertyDetails(data);
        setBasicDetails({
          name: data.hotel_name,
          mobile: data.mobile,
          email: data.email_id,
          landline: data.land_line,
          description: data.hotel_description,
          whatsappnumber: data.whatsapp_no,
          facebook_link: data.facebook_link,
          twitter_link: data.twitter_link,
          instagram_link: data.instagram_link,
          linkedin_link: data.linked_in_link,
          tripadviser_link: data.tripadvisor_link,
          holidayiq_link: data.holiday_iq_link,
        });
        setAddressForm({
          street: data.hotel_address,
          flat: data.flat,
          city: data.city_name,
          state: data.state_name,
          country: data.country_name,
          pin: data.pin,
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
        });
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  // Post API call for submit updated property details
  const submitPropertyDetails = async () => {
    setLoader(true);
    try {
      let response = await kernelApi.post(
        `${updateNewProperty}${current_property.hotel_id}`,
        {
          company_id: current_property.company_id,
          admin_id: admin_id,
          hotel_name: basicDetails.name,

          mobile: number
          // mobileNewData.length > 0 ? mobileNewData : number
          // [8658008077],
          // phoneNumArr.length > 0 ? phoneNumArr :
          //   typeof (basicDetails.mobile) !== 'object' ? [basicDetails.mobile] : [...basicDetails.mobile]
          ,
          email_id: email
          // mailNewData.length > 0 ? mailNewData : email
          // ['namrata.swain@56elements.co.in', 'someother@gmail.com', 'dxr@gmail.com', 'moremail@gmail.com'],
          // emailArr.length > 0 ? emailArr :
          //   typeof (basicDetails.email) === 'string' ? [basicDetails.email] : [...basicDetails.email]
          ,
          // mobile: basicDetails.mobile,
          // email_id: basicDetails.email,

          land_line: basicDetails.landline,
          hotel_description: basicDetails.description,
          check_in: Convert12Hto24H(checkInTime + ":" + checkInTimeStatus),
          check_out: Convert12Hto24H(checkOutTime + ":" + checkOutTimeStatus),
          round_clock_check_in_out: enableTime,
          whatsapp_no: basicDetails.whatsappnumber,
          facebook_link: basicDetails.facebook_link,
          twitter_link: basicDetails.twitter_link,
          instagram_link: basicDetails.instagram_link,
          linked_in_link: basicDetails.linkedin_link,
          tripadvisor_link: basicDetails.tripadviser_link,
          holiday_iq_link: basicDetails.holidayiq_link,
        }
      );
      if (response.data.status === 1) {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getAllhotelDetails();
        setLoader(false);
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
        getAllhotelDetails();
        setLoader(false);
      }
    }
    catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  const updatePropertyDetails = () => {
    if (
      basicDetails.name &&
      basicDetails.mobile &&
      // basicDetails.email &&
      // mobileError.length === 0 &&
      email.length &&
      number.length &&
      // mobileError.length === 0 &&
      landlineError.length === 0 &&
      whatsappError.length === 0 &&
      emailError.length === 0 &&
      ((checkInTime &&
        checkOutTime &&
        checkInTimeStatus &&
        checkOutTimeStatus) ||
        enableTime)
    ) {
      submitPropertyDetails();
      // setPhoneNumArr([])
      // setEmailArr([])
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const Toggle247Time = () => {
    setEnableTime(!enableTime);
  };

  // get location details of device's current location
  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      let GeoCoder = new google.maps.Geocoder();
      let LatLng = new google.maps.LatLng(latitude, longitude);
      GeoCoder.geocode({ location: LatLng }, function (results: any, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            let address = results[0];
            getPropertyAddressDetails(address, latitude, longitude);
          } else {
            //   "Geocode was not successful for the following reason: " + status
            // );
          }
        }
      });
    });
  };

  // gets city, country, state, pin from address_components returned by google maps api
  const getPropertyAddressDetails = (
    address: any,
    latitude: number,
    longitude: number
  ) => {
    let components = address.address_components;

    let flat: any[] = [];
    let street: any[] = [];
    let country;
    let state;
    let city;
    let pin;
    for (let i = 0; i < components.length; i++) {
      if (components[i].types[0] === "country") {
        country = components[i].long_name;
      } else if (components[i].types[0] === "postal_code") {
        pin = components[i].long_name;
      } else if (components[i].types[0] === "administrative_area_level_1") {
        state = components[i].long_name;
      } else if (components[i].types[0] === "locality") {
        city = components[i].long_name;
      } else if (
        components[i].types.includes("sublocality") ||
        components[i].types.includes("plus_code")
      ) {
        street.push(components[i].long_name);
      } else if (
        components[i].types.includes("premise") ||
        components[i].types.includes("neighborhood")
      ) {
        flat.push(components[i].long_name);
      }
    }
    setAddressForm({
      country: country,
      state: state,
      city: city,
      street: street.join(", "),
      pin: pin,
      flat: flat.join(", "),
      lat: latitude,
      lng: longitude,
    });
  };

  // get location details from address string
  // const getLocationFromAddress = async (address: string) => {
  //   let GeoCoder = new google.maps.Geocoder();
  //   GeoCoder.geocode({ address: address }, function (results, status) {
  //     if (status === google.maps.GeocoderStatus.OK && results) {
  //       let LatLng = results[0].geometry.location;
  //       let latitude = LatLng.toJSON().lat;
  //       let longitude = LatLng.toJSON().lng;
  //       GeoCoder.geocode({ location: LatLng }, function (results: any, status) {
  //         if (status === google.maps.GeocoderStatus.OK) {
  //           if (results[0]) {
  //             let address = results[0];
  //             getPropertyAddressDetails(address, latitude, longitude);
  //           }
  //         } else {
  //             "Geocode was not successful for the following reason: " + status
  //           );
  //         }
  //       });
  //       // setMapCenter({ lat: latitude, lng: longitude });
  //       // setMapZoom(18);
  //     } else {
  //       alert("Geocode was not successful for the following reason: " + status);
  //     }
  //   });
  // };

  const pinOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressForm({ ...addressForm, pin: e.target.value });
    if (!validatePinCode(e.target.value) && e.target.value) {
      setPinError("Please enter a valid pin code");
    } else {
      setPinError("");
    }
  };

  // Post API call to update address
  const updateAddress = async () => {
    setLoader(true);
    if (
      addressForm.street &&
      addressForm.city &&
      addressForm.country &&
      addressForm.state &&
      addressForm.city &&
      addressForm.pin
    ) {
      try {
        let response = await kernelApi.post(
          `${updatePropertyAddress}${current_property.hotel_id}`,
          {
            hotel_address: addressForm.street + "||" + addressForm.flat,
            hotel_geo_location: addressForm.lat + "," + addressForm.lng,
            city_name: addressForm.city,
            state_name: addressForm.state,
            country_id: countryId.filter((country: any) => {
              return addressForm.country === country.country_name;
            })[0].country_id,
            pin: addressForm.pin,
          }
        );
        if (response.data.status === 1) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          getAllhotelDetails();
          setLoader(false);
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          getAllhotelDetails();
          setLoader(false);
        }
        setOpenSlidePanelAddress(false);
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const checkInOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckInTime(e.target.value.slice(0, 5));
  };

  const checkOutOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckOutTime(e.target.value.slice(0, 5));
  };

  // console.log({ openMobileSlider, openMailSlider })

  const removeMob = (ind) => {
    const copyMailArr = [...number]
    const newarr = copyMailArr.filter((mail, mailInd) => {
      if (mailInd !== ind) return mail
    })
    setNumber(newarr)
    setNumberValues(newarr)
  }

  const removeMail = (ind) => {
    const copyMailArr = [...email]
    const newarr = copyMailArr.filter((mail, mailInd) => {
      if (mailInd !== ind) return mail
    })
    setEmail(newarr)
    setEmailValues(newarr)
  }

  // console.log({ emailValues })

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>
      <div className="basic-details-wrapper">
        <div className="standard__card__wrrapper">
          <div className="basic__details__card mt-3">
            <div className="basic--details--inputs">
              <div className="__default--input--field min__width__unset__basic__details">
                <InputTextField
                  label="Property name"
                  value={basicDetails?.name}
                  handleChange={(e) =>
                    setBasicDetails({
                      ...basicDetails,
                      name: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="basic--details--inputs">
              <div className="__default--input--field min__width__unset__basic__details">
                <InputTextField
                  label="Property Landline Number"
                  value={basicDetails?.landline}
                  handleChange={LandlineOnChange}
                  helperText={landlineError}
                />
              </div>
            </div>

            <div className="basic--details--inputs">
              <div className="__default--input--field min__width__unset__basic__details">
                <InputTextField
                  label="Property Description"
                  value={basicDetails?.description}
                  handleChange={(e) =>
                    setBasicDetails({
                      ...basicDetails,
                      description: e.target.value,
                    })
                  }
                  multiline
                  rows={3}
                />
              </div>
            </div>

            <div className="wrapper__24x7__CheckinCheckout">
              <div className="standard-page--label">Check-in/out Time</div>

              <div className="form-check form-switch custom-switch check--box--width">
                <label
                  className="standard-page--label pos--24x7"
                  htmlFor="flexSwitchCheckDefault"
                >
                  24x7
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  onChange={Toggle247Time}
                  checked={enableTime}
                />
              </div>
            </div>

            {!enableTime && (
              <div className="in-out-time-container d-flex mt-4">
                <div className="in__out__time me-3">
                  <div className="div__inputs__wrapp">
                    <div className="div--email--input">
                      <InputTextField
                        label="Check-In"
                        value={Format12HrTime(checkInTime)}
                        handleChange={checkInOnChange}
                      />
                    </div>
                  </div>

                  <div
                    className={`day__night__symbol ms-3 ${checkInTimeStatus === "inactive__status"
                      ? "inactive__status"
                      : ""
                      }`}
                  >
                    <div
                      className={`time__div ${checkInTimeStatus === "AM" ? "custom--font" : ""
                        }`}
                      onClick={() => setCheckInTimeStatus("AM")}
                    >
                      AM
                    </div>
                    <div
                      className={`time__div ${checkInTimeStatus === "PM" ? "custom--font" : ""
                        }`}
                      onClick={() => setCheckInTimeStatus("PM")}
                    >
                      PM
                    </div>
                  </div>
                </div>

                <div className="in__out__time">
                  <div className="div__inputs__wrapp">
                    <div className="div--email--input">
                      <InputTextField
                        label="Check-Out"
                        value={Format12HrTime(checkOutTime)}
                        handleChange={checkOutOnChange}
                      />
                    </div>
                  </div>

                  <div
                    className={`day__night__symbol ms-3 ${checkInTimeStatus === "inactive__status"
                      ? "inactive__status"
                      : ""
                      }`}
                  >
                    <div
                      className={`time__div ${checkOutTimeStatus === "AM" ? "custom--font" : ""
                        }`}
                      onClick={() => setCheckOutTimeStatus("AM")}
                    >
                      AM
                    </div>
                    <div
                      className={`time__div ${checkOutTimeStatus === "PM" ? "custom--font" : ""
                        }`}
                      onClick={() => setCheckOutTimeStatus("PM")}
                    >
                      PM
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="basic__details__map__card mt-3">
            <div className="map__whatsapp__wrapper">
              <div className="map--container">
                <div className="map__component__wrapper">
                  {addressForm.lat || addressForm.lng ? (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyBceMBt5GputUmynEHqVn0L8rRByrIFU5M",
                      }}
                      options={{
                        mapTypeId: "roadmap",
                        fullscreenControl: false,
                      }}
                      center={{
                        lat: addressForm.lat,
                        lng: addressForm.lng,
                      }}
                      zoom={16}
                    >
                      <Marker lat={addressForm.lat} lng={addressForm.lng} />
                    </GoogleMapReact>
                  ) : null}
                </div>
                <div className="location__address__wrapper">
                  <div className="location--address mt-2">
                    <div className="location__address__text">
                      <i className="bi bi-geo-alt-fill"></i>
                      {propertyDetails && (
                        <div className="address--details">
                          {`${propertyDetails?.hotel_address}, ${propertyDetails?.city_name}, ${propertyDetails?.state_name}`}
                        </div>
                      )}
                    </div>
                    <div
                      className="edit--btn"
                      onClick={() =>
                        openSlidePanelFunction(propertyDetails?.country_name)
                      }
                    >
                      <i className="bi bi-pencil"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="whatsapp__wrrapper mt-3">
                <div className="standard-page--label my-3 pb-2">
                  Whatsapp number
                </div>
                <div className="__default--input--field min__width__unset__basic__details mt-3">
                  <InputTextField
                    label="Whatsapp number"
                    value={basicDetails?.whatsappnumber}
                    handleChange={onWhatsAppNumberChange}
                    helperText={whatsappError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="socialmedia_wrapper">
        <div className="card_wrapper_links">
          <div className="social_label mb-4">Social Media Links</div>
          <div className="fb_twitter_wrapper mb-4">
            <div className="social__media__wrapper">
              <div className="social-media-input input__margin__right">
                <div className="fb_logo">
                  <i className="bi bi-facebook"></i>
                </div>
                <div className="facebook_link">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={basicDetails?.facebook_link}
                    onChange={facebooklinkOnchange}
                  ></input>
                </div>
              </div>

              <p className="social__media__errors">
                {socialMediaError.facebookError ? INVALID_URL : ""}
              </p>
            </div>

            <div className="social__media__wrapper">
              <div className="social-media-input">
                <div className="fb_logo">
                  <i className="bi bi-twitter"></i>
                </div>
                <div className="facebook_link">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={basicDetails?.twitter_link}
                    onChange={twitterlinkOnchange}
                  ></input>
                </div>
              </div>
              <p className="social__media__errors">
                {socialMediaError.twitterError ? INVALID_URL : ""}
              </p>
            </div>
          </div>
          <div className="fb_twitter_wrapper mb-4">
            <div className="social__media__wrapper">
              <div className="social-media-input input__margin__right">
                <div className="fb_logo">
                  <i className="bi bi-linkedin"></i>
                </div>
                <div className="facebook_link">
                  {" "}
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={basicDetails?.linkedin_link}
                    onChange={linkedinOnchange}
                  ></input>
                </div>
              </div>
              <p className="social__media__errors">
                {socialMediaError.linkedinError ? INVALID_URL : ""}
              </p>
            </div>
            <div className="social__media__wrapper">
              <div className="social-media-input">
                <div className="fb_logo">
                  <i className="bi bi-instagram"></i>
                </div>
                <div className="facebook_link">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={basicDetails?.instagram_link}
                    onChange={instagramOnchange}
                  ></input>
                </div>
              </div>
              <p className="social__media__errors">
                {socialMediaError.instargramError ? INVALID_URL : ""}
              </p>
            </div>
          </div>
          <div className="meta_label mb-4">Meta Search Links</div>
          <div className="fb_twitter_wrapper mb-1">
            <div className="social__media__wrapper">
              <div className="social-media-input input__margin__right">
                <div className="tripadvisor__logo__wrapper">
                  <div className="tripadvisor__logo"></div>
                </div>
                <div className="facebook_link">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={basicDetails?.tripadviser_link}
                    onChange={tripadOnchange}
                  ></input>
                </div>
              </div>
              <p className="social__media__errors">
                {socialMediaError.tripadError ? INVALID_URL : ""}
              </p>
            </div>
            <div className="social__media__wrapper">
              <div className="social-media-input">
                <div className="holidayiq__logo__wrapper">
                  <div className="holidayiq_logo"></div>
                </div>
                <div className="facebook_link">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={basicDetails?.holidayiq_link}
                    onChange={holidayiqOnchange}
                  ></input>
                </div>
              </div>
              <p className="social__media__errors">
                {socialMediaError.holidayiqError ? INVALID_URL : ""}
              </p>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <div className="emailAndMobileInput">
            <div style={{ marginBottom: '1.5rem' }}>Contacts</div>

            <div className="__default--input--field min__width__unset__basic__details">
              <InputTextField_Add
                runTheFunc={() => {
                  setEmail(prev => [...prev, emailInput])
                  setEmailInput('')
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setEmail(prev => [...prev, emailInput])
                    setEmailInput('')
                  }
                }}
                label="Property Email Address"
                value={emailInput.length ? emailInput :
                  emailValues.length ?
                    emailValues.length > 1 ? `${emailValues.length} Mails` : emailValues
                    : ''}
                handleChange={(e) => {
                  setEmailInput(e.target.value)
                  if (!validateEmail(e.target.value) && e.target.value) {
                    setEmailError("Please enter valid mobile number");
                  } else {
                    setEmailError("");
                  }
                }}
                helperText={emailError}
                handleFocus={() => setEmailValues([])}
              />
            </div>
            <div className="arrayDataHolder">
              {
                email.map((mail, ind) => {
                  return (
                    <div className="mail contact" key={ind}>
                      {mail}
                      <i className="bi bi-x-lg" onClick={() => removeMail(ind)}></i>
                    </div>
                  )
                })
              }
            </div>

            <div className="__default--input--field min__width__unset__basic__details">
              <InputTextField_Add
                runTheFunc={() => {
                  setNumber(prev => [...prev, numberInput])
                  setNumberInput('')
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setNumber(prev => [...prev, numberInput])
                    setNumberInput('')
                  }
                }}
                label="Property Mobile Number"
                value={numberInput.length ? numberInput :
                  numberValues.length ?
                    numberValues.length > 1 ? `${numberValues.length} Numbers` : numberValues
                    : ''}
                handleChange={(e) => {
                  setNumberInput(e.target.value)
                  if (!validateMobile(e.target.value) && e.target.value) {
                    setMobileError("Please enter valid mobile number");
                  } else {
                    setMobileError("");
                  }
                }}
                helperText={mobileError}
                handleFocus={() => setNumberValues([])}
              />
            </div>
            <div className="arrayDataHolder">
              {
                number.map((mob, ind) => {
                  return (
                    <div className="mob contact" key={ind}>
                      {mob}
                      <i className="bi bi-x-lg" onClick={() => removeMob(ind)}></i>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/* --------------------------------------------------- */}

        </div>
      </div>

      <div className="__btn__wrapper mt-4">
        <Button
          className="cancel__btn me-3"
          handleClick={() => navigate("/property-setup")}
        >
          Cancel
        </Button>
        <Button
          className="save__btn"
          handleClick={updatePropertyDetails}
          loader={loader}
        >
          Save
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
        }}
      ></div>

      {/*-------------- sliding page for edit address---------------- */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openSlidePanelAddress}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlidePanelAddress(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setOpenSlidePanelAddress(false)}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">Edit Address</div>
        </div>
        <div className="height-64px"></div>

        <div className="edit__address__form__container">
          <div
            className="edit--location--address mt-2"
            onClick={getCurrentLocation}
          >
            <i className="bi bi-geo-alt-fill"></i>
            <div className="current--address--div ms-2">
              Use current Location
            </div>
          </div>
          {/*----- Form for address */}
          <div className="address-form-container edit-form-container">
            <div className="div__inputs__wrapp">
              <div className="div--email--input">
                <InputTextField
                  label="Street Address"
                  value={addressForm.street}
                  handleChange={(e) =>
                    setAddressForm({ ...addressForm, street: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="div__inputs__wrapp">
              <div className="div--email--input">
                <InputTextField
                  label="Flat, Suite, etc. (optional)"
                  value={addressForm.flat}
                  handleChange={(e) =>
                    setAddressForm({ ...addressForm, flat: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="div__inputs__wrapp">
              <div className="div__input__align__horizontal">
                <div className="div--email--input me-3">
                  <InputTextField
                    label="City"
                    value={addressForm.city}
                    handleChange={(e) =>
                      setAddressForm({ ...addressForm, city: e.target.value })
                    }
                  />
                </div>

                <div className="div--email--input">
                  <InputTextField
                    label="Post Code"
                    value={addressForm.pin}
                    helperText={pinError}
                    handleChange={pinOnChange}
                  />
                </div>

                {/*
                  handleChange={(e, value) =>
                    countryState;                  
                    }
                    const countryState = (e, value) => {
                      setAddressForm({
                        ...addressForm,
                        country: e.target.value,
                      });
                      getStatesFromCountryId(value);
                    }
                  
                */}
              </div>
            </div>

            <div className="div__inputs__wrapp">
              <div className="div__input__align__horizontal">
                <div className="div--email--input me-3">
                  <InputSelectField
                    label="Choose Country"
                    value={addressForm.country}
                    handleChange={(e) => countryState(e)}
                    options={getcountry()}
                  />
                </div>

                <div className="div--email--input">
                  <InputSelectField
                    label="Choose State"
                    value={addressForm.state}
                    handleChange={(e) =>
                      setAddressForm({
                        ...addressForm,
                        state: e.target.value,
                      })
                    }
                    options={allStates.map((state) => state.state_name)}
                  />
                  {/* {allStates[0] ? (
                    <InputSelectField
                      label="Choose State"
                      value={addressForm.state}
                      handleChange={(e) =>
                        setAddressForm({
                          ...addressForm,
                          state: e.target.value,
                        })
                      }
                      options={allStates.map((state) => state.state_name)}
                    />
                  ) : (
                    <InputTextField
                      label="State"
                      value={addressForm.state}
                      handleChange={(e) =>
                        setAddressForm({
                          ...addressForm,
                          state: e.target.value,
                        })
                      }
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
        <div className="side__slider__panel__btn">
          <div className="__next__btn__wrapper">
            <SaveButton
              handleClick={updateAddress}
              className="save__btn"
              loader={loader}
            >
              Save
            </SaveButton>
          </div>
        </div>
      </SlidingPane>
    </>
  );
};

export default PropertyBasicDetails;
