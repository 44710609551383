import Button from "../../../../views/buttons/Button";
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import InputTextField from '../../../../views/inputtextfield/InputTextField';
import { HideSetupPaymentSlider, SetUpNewPaymodeMode } from "../../../../redux/actions/SetGatewayDetailsAction";
import beApi from "../../../../API/beApi";
import Toast from "../../../../components/toaster/Toast"
import Loader from "../../../../views/loader/Loader";
import { CATCH_MESSAGE } from "../../../../UtilityFunctions";


export interface ISetupPaymentProps {
  onClose: () => void;
}


const SetupPayment: React.FC<ISetupPaymentProps>
  = ({
    onClose,
  }) => {

    const dispatch = useDispatch()

    const [CRED, setCRED] = useState({ key: "", salt: "" })

    const [dataFetched, setDataFetched] = useState<any>('unset')

    const [defaultMsg, setDefaultMsg] = useState({ msg: 'No Payment Gateway Selected' })

    const [IsActive, setIsActive] = useState(false)

    const [Credentials, setCredentials] = useState<any>(null)

    const [btnLoader, setBtnLoader] = useState(false)

    const [key, setKey] = useState('')

    const [salt, setSalt] = useState('')

    const GatewayDetails = useSelector((store: RootState) => store.gateWay.data)

    const { current_property } = useSelector(
      (state: RootState) => state.properties
    );

    const { admin_id } = useSelector(
      (state: RootState) => state.auth
    );

    const { HotelId, ProiderName, Logo, ModeId } = GatewayDetails


    // ----------------------------
    const fetchSelectedPaymentMethodDetails = async () => {
      try {
        const Response = await beApi.get(
          `select-paymentgateway-setup/${current_property.hotel_id}/${ModeId}`
        )
        if (Response.data.status === 1) {
          setDataFetched(1)
          setCRED(
            !Response.data.Data.credentials || Response.data.Data.credentials == 'null' ? { key: "", salt: "" }
              : JSON.parse(Response.data.Data.credentials)
          )
          setIsActive(Response.data.Data.is_active == 0 ? false : true)
        }
        else {
          setDataFetched(0)
          setDefaultMsg({ msg: Response.data.message })
          Toast(Response.data.message, 'error')
        }
      }
      catch (error) {
        setDataFetched(-1)
        setDefaultMsg({ msg: 'Unable to fetch payment gateway details' })
        Toast(CATCH_MESSAGE, "error");
      }
    }

    useEffect(() => { fetchSelectedPaymentMethodDetails() }, [HotelId, ProiderName])

    const Title_case = (value) => {
      const lettersArr = value.split('')
      lettersArr[0] = lettersArr[0].toUpperCase()
      const TitleCasedVal = lettersArr.join("")
      return TitleCasedVal
    }


    // -------------------------
    const inactivePaymentGatewayOption = async () => {
      try {
        const response = await beApi.get(
          `delect-paymentgateway/${current_property.hotel_id}/${ProiderName}/0`
        )

        setIsActive(false)
        if (response.data.status === 0) {
          dispatch(SetUpNewPaymodeMode(nanoid()))
          Toast(response.data.message)
          setTimeout(() => {
            dispatch(HideSetupPaymentSlider())
          }, 500);
        }
        else Toast(response.data.message, 'error')
      }
      catch (error) {
        Toast(CATCH_MESSAGE, "error");
      }
    }

    const activePaymentGatewayOption = async () => {
      try {
        const response = await beApi.get(
          `delect-paymentgateway/${current_property.hotel_id}/${ProiderName}/1`
        )
        setIsActive(true)

        if (response.data.status === 1) {
          dispatch(SetUpNewPaymodeMode(nanoid()))
          Toast(response.data.message)
          setTimeout(() => {
            dispatch(HideSetupPaymentSlider())
          }, 500);
        }
        else Toast(response.data.message, "error")
      }
      catch (error) {
        Toast(CATCH_MESSAGE, "error");
      }
    }

    useEffect(() => {
      if (!Credentials) return
      setBtnLoader(true)
      if (CRED.key !== Credentials.key || CRED.salt !== Credentials.salt) {
        if (Credentials.key === "" && Credentials.salt === "") {
          setBtnLoader(false)
          dispatch(HideSetupPaymentSlider())
          Toast('Key and Salt are empty', 'error')
        }
        else if (Credentials.salt === "") {
          setBtnLoader(false)
          dispatch(HideSetupPaymentSlider())
          Toast('Salt is empty', 'error')
        }
        else if (Credentials.key === "") {
          setBtnLoader(false)
          dispatch(HideSetupPaymentSlider())
          Toast('Key is empty', 'error')
        }

        else {
          const UpdateGatewayInfo = async () => {
            try {
              const response = await beApi.post(
                `update-paymentgateway-setup/${ModeId}`,
                {
                  "hotel_id": current_property.hotel_id,
                  "provider_name": `${ProiderName}`,
                  "credentials": Credentials,
                  "user_id": admin_id
                }
              )

              if (response.data.status === 1) {
                dispatch(SetUpNewPaymodeMode(nanoid()))
                setBtnLoader(false)
                dispatch(HideSetupPaymentSlider())
                Toast(response.data.message)
              }
              else {
                setBtnLoader(false)
                dispatch(HideSetupPaymentSlider())
                Toast(response.data.message, "error")
              }
            }
            catch (error) {
              Toast(CATCH_MESSAGE, "error");
            }
          }
          UpdateGatewayInfo()
        }
      }
      else {
        setBtnLoader(false)
        dispatch(HideSetupPaymentSlider())
      }
    }, [Credentials])

    useEffect(() => {
      setKey(CRED?.key)
      setSalt(CRED?.salt)
    }, [CRED])




    return (
      <div style={{ position: 'relative' }}>

        {/* -----------HEADER----------- */}


        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => {
                onClose();
              }}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">{`${Title_case(ProiderName)}`} Setup</div>
        </div>


        {
          dataFetched === 1 ?
            <>
              <div className="payment_options_wrapper " style={{ position: 'absolute', top: "90px" }}>
                {/* ---------------------- */}
                <div className="payment__option__cards mb-4 __Slider__payment__option">
                  <div className="payment_option_content ms-0 __Slider__payment_option_content __Slider__payment_setup_content">
                    <div className="paymentSetupTopsect">
                      <div className="payment_medium_image_container __Slider__payment_medium_image_container">
                        <img src={Logo} alt={ProiderName} />
                      </div>

                      <div className="form-check form-switch custom-switch check--box--width toggleBtnBox" >
                        <label
                          className="standard-page--label pos--24x7"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          {IsActive ? "Active" : "Inactive"}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          onChange={() => IsActive ?
                            inactivePaymentGatewayOption()
                            :
                            activePaymentGatewayOption()
                          }
                          checked={IsActive}
                        />
                      </div>
                    </div>

                    {/* ----------INPUTS---------- */}
                    <div className="paymentSetupBottomsect">
                      <div className="div--email--input">
                        <InputTextField
                          label={`Api Key`}
                          value={`${key}`}
                          handleChange={(e) => {
                            setKey(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="paymentSetupBottomsect">
                      <div className="div--email--input">
                        <InputTextField
                          label={`Api Salt`}
                          value={`${salt}`}
                          handleChange={(e) => {
                            setSalt(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    {/* -------------------- */}

                  </div>
                </div>
              </div>
            </>
            :
            dataFetched === 'unset' ? <div className="loadercontainer">
              <Loader className='loaderClass' />
            </div> : <div className="loadercontainer">
              <div style={{ color: "#a1a1a1" }}>{defaultMsg.msg}</div>
            </div>
        }


        {/* -----------FOOTER----------- */}
        <div className="sliderFooter">
          <div className="payment_button __Slider__payment_button footerBtn">
            <Button className="add__btn"
              loader={btnLoader}
              handleClick={() => {
                setCredentials({ key, salt })
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }

export default SetupPayment