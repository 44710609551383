import { UPDATED_ROOM_NOS } from "../actionTypes";

const initialState = ''

const UpdateInventoryNosReducer = (state = initialState, action: any) => {

    if (action.type === UPDATED_ROOM_NOS) return action.payload
    else return state
};

export default UpdateInventoryNosReducer;
