import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AllProperties } from "../redux/actions/PropertiesAction";

import Loader from "../views/loader/Loader";
import PropertyCard from "../components/property/PropertyCard";

import { sha512 } from "js-sha512";
import kernelApi from "../API/kernelApi";
import { Logout } from "../UtilityFunctions";
import GetHelp from "../components/gethelp/GetHelp";
import endPoints from "../API/endPoints";
import Button from "../views/buttons/Button";
import { handleSingleSignOn } from "../redux/actions/LoginAction";

const SelectProperty = () => {
  const dispatch = useDispatch();

  //Endpoints Import
  const { getAllHotelsByCompanies } = endPoints.ADDNEWPROPERTY
  // --------------------------------


  const { auth_token, company_id, full_name } = useSelector(
    (state: RootState) => state.auth
  );
  const properties = useSelector(
    (state: RootState) => state.properties.property_data
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (company_id) {
      dispatch(handleSingleSignOn(1));
      GetHotelsbyCompanyId();
    }
  }, []);

  const GetHotelsbyCompanyId: () => void = async () => {
    setIsLoading(true);
    try {
      let comp_hash = sha512(company_id.toString());
      let response = await kernelApi.get(
        `${getAllHotelsByCompanies}/${comp_hash}/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(AllProperties(response.data));
      } else {
        dispatch(AllProperties(null));
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <div className="div__input__wrapper__left div__left__side__box">
          <div className="logo__wrapper"></div>
          <div className="div__user__testimonials__wrapper">
            <div className="select__property__wrapper">
              <div className="select__property__text">Select Property</div>
              <div className="select__property__sub__text">
                Choose the property you want to manage
              </div>
            </div>
          </div>
        </div>

        <div className="div__input__wrapper__right div__right__side__box">
          <div className="right__log__out__wrap">
            <div className="username__name__text__wrapper">
              <i className="bi bi-person-fill hotel__icon me-1"></i>
              {full_name}
            </div>
            <Button
              className="logout__btn"
              handleClick={() => {
                Logout();
              }}
            >
              Logout
            </Button>
          </div>
          <div className="div__inputs">
            <div className="div--head--label">
              {properties?.data?.length ? `You are managing ${properties?.data?.length} ${properties?.data?.length === 1 ? " Property" : " Properties"}` : `Please wait!. We are fetching your properties.`}
              {/* You are managing {properties?.data?.length || 0}
              {properties?.data?.length === 1 ? " Property" : " Properties"} */}
            </div>
            <div className="div--helper--text">Select below</div>

            {isLoading ? (
              <Loader />
            ) : (
              <div className="max__height__propert__list">
                {!properties?.data && (
                  <Link to="/add-new-property">
                    <div className="div__property__wrap">
                      <div className="div__property__list">
                        <div className="add__new__property">
                          <i className="bi bi-plus-lg-circle-dotted"></i>
                        </div>
                        <div className="peoperty__name__wrapper">
                          <div className="property--name">Add New Property</div>
                        </div>
                        <div className="select__property">
                          <i className="gg-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
                {properties?.data?.map((property: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <PropertyCard property={property} />
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            <GetHelp />
          </div>
        </div>
      </div>
    </div >
  );
};

export default SelectProperty;
