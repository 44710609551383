import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../views/buttons/Button";
import Loader from "../../views/loader/Loader";
import { CATCH_MESSAGE, Logout, UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import SlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import AddSeasonalPlan from "../../components/seasonalPlan/AddSeasonalPlan";
// import { nanoid } from "@reduxjs/toolkit";
import EditSeasonalPlan from "../../components/seasonalPlan/EditSeasonalPlan";
import ApplySeasonalPlan from "../../components/seasonalPlan/ApplySeasonalPlan";

const PropertySeasonalPlan = () => {
  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const navigate = useNavigate();
  UpdateSidebar("Property Setup", "Seasonal Plan");

  // useSate declaration
  const [loader, setLoader] = useState<any>(true);
  const [allSeasonalPlans, setAllSeasonalPlans] = useState<any[]>([]);
  const [allLatestSeasonalPlans, setAllLatestSeasonalPlans] = useState<any[]>(
    []
  );
  const [specificPlan, setSpecificPlan] = useState<any[]>([]);
  const [noDataMsg, setNoDataMsg] = useState<string>("");
  const [addPlan, setAddPlan] = useState(false);
  const [editPlan, setEditPlan] = useState(false);
  const [applyPlan, setApplyPlan] = useState(false);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [allActiveChannels, setAllActiveChannels] = useState<any[]>([]);
  const [imgsArr, setImgsArr] = useState([]);
  const [active, setActive] = useState<boolean>(false);
  // const [loader, setLoader] = useState<boolean>(false);
  const [showChannels, setShowChannels] = useState<any[]>([]);
  const [allRoomTypes, setAllRoomTypes] = useState<{
    ids: string[];
    labels: string[];
  }>({ ids: [], labels: [] });
  const [allRoomTypeDetails, setAllRoomTypeDetails] = useState<any[]>([]);
  const [roomTypeId, setRoomTypeId] = useState<any[]>([]);
  const [seasonalPlanId, setSeasonalPlanId] = useState<any[]>([]);
  const [baseAdult, setBaseAdult] = useState<number>(0);

  // Static variable declaration
  const noPLanAvailable = {
    message: "No seasonal plan available",
  };
  const pageSize = [10, 15, 20, 25, 30];

  useEffect(() => {
    if (allLatestSeasonalPlans?.length > 0) {
      setNoOfPages(Math.ceil(allLatestSeasonalPlans?.length / selectPageSize));
    }
  }, [selectPageSize, allLatestSeasonalPlans]);

  useEffect(() => {
    if (addPlan === false || editPlan === false) {
      latestResponse();
    }
  }, [addPlan, editPlan]);

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };
  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };
  const Capitalize = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // API call to retrieve new allSeasonalPlan
  const latestResponse = async () => {
    try {
      let res = await kernelApi.get(
        `/room-type-status/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: auth_token,
          },
        }
      );
      if (res?.data.status === 1) {
        if (res.data.Data && res.data.Data.length > 0) {
          setLoader(false);
          setAllLatestSeasonalPlans(res.data.Data ? res.data.Data : []);
        } else {
          setLoader(false);
          setNoDataMsg(noPLanAvailable.message);
        }
      } else {
        setLoader(false);
        setNoDataMsg(noPLanAvailable.message);
      }
    } catch (error) {
      console.log(error);
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    latestResponse();
  }, []);

  // Handler function to setup a plan
  const to__setup = (roomTypeId: any, seasonalPlanId: any) => {
    setRoomTypeId(roomTypeId);
    setSeasonalPlanId(seasonalPlanId);
    let path = `setup-seasonal-plan/${roomTypeId}/${seasonalPlanId}`;
    navigate(path);
  };

  // Handler function to edit a plan
  const edit__plan = (data: any) => {
    setEditPlan(true);
    setSpecificPlan(data);
  };

  // Handler function to apply plan
  const apply__plan = (data: any) => {
    setApplyPlan(true);
    setSpecificPlan(data);
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <div className="add__floor__btn_container">
            <Button
              icon="bi bi-plus"
              className="new__bookings"
              handleClick={() => setAddPlan(true)}
            >
              Add Plan
            </Button>
          </div>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {loader ? (
        <div className="ineventory--loader">
          <Loader />
        </div>
      ) : (
        <div>
          {allLatestSeasonalPlans?.length > 0 ? (
            <>
              <div className="list-view-table-wrapper mb-4">
                <table className="table table-borderless">
                  <thead>
                    <tr className="listview__table__heading">
                      <th>S.No</th>
                      <th>Plan Name</th>
                      <th>Room Type Setup</th>
                      <th>Manage</th>
                      <th>Apply</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allLatestSeasonalPlans &&
                      allLatestSeasonalPlans?.length > 0 &&
                      allLatestSeasonalPlans
                        ?.slice(
                          selectPageSize * (currentPageNo - 1),
                          selectPageSize * currentPageNo
                        )
                        ?.map((data: any, id: any) => {
                          const { seasonal_plan_id } = data;
                          return (
                            <tr className="listview__table__data">
                              <td>
                                <div className="serial_no">{id + 1}</div>
                              </td>
                              <td>
                                <div className="plan-details">
                                  {Capitalize(data?.plan_name)}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex seasonal__btn">
                                  {data?.room_type.map((obj: any) => {
                                    return obj.is_complete ? (
                                      <div
                                        className="roomtype-details-success-button"
                                        onClick={() =>
                                          to__setup(
                                            obj?.room_type_id,
                                            seasonal_plan_id
                                          )
                                        }
                                      >
                                        {`${obj?.room_type_shortcode} `}
                                        <i className="bi bi-check-circle-fill"></i>
                                      </div>
                                    ) : (
                                      <div
                                        className="roomtype-details-button"
                                        onClick={() =>
                                          to__setup(
                                            obj?.room_type_id,
                                            seasonal_plan_id
                                          )
                                        }
                                      >
                                        {`${obj?.room_type_shortcode} `}
                                        <i className="bi bi-exclamation-circle"></i>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex seasonal__btn">
                                  {/* ---------------- */}
                                  <div
                                    className="seasonal-edit-button"
                                    onClick={() => edit__plan(data)}
                                  >
                                    Edit
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex seasonal__btn">
                                  {/* ---------------- */}
                                  <div
                                    className="seasonal-apply-button"
                                    onClick={() => apply__plan(data)}
                                  >
                                    Apply
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>

              {/* Pagination section */}
              <div className="list--view--pagination">
                <div className="pagination__left">
                  <div className="pagination__dropdown">
                    <InputSelectField
                      label="Select"
                      value={selectPageSize}
                      options={pageSize}
                      handleChange={(e) => {
                        setSelectPageSize(e.target.value);
                        setNoOfPages(
                          Math.ceil(allLatestSeasonalPlans?.length / e.target.value)
                        );
                        setCurrentPageNo(1);
                      }}
                    />
                  </div>
                  <ul>
                    {Array(noOfPages)
                      .fill(0)
                      .map((p, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() => setCurrentPageNo(i + 1)}
                            className={`${i + 1 === currentPageNo
                                ? "active__page"
                                : "showing__page__number"
                              }`}
                          >
                            {i + 1}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="pagination__right">
                  <div
                    className="arrow__container me-3"
                    onClick={leftArrowClick}
                  >
                    <i className="bi bi-chevron-left font--weight"></i>
                  </div>
                  <div className="arrow__container" onClick={rightArrowClick}>
                    <i className="bi bi-chevron-right font--weight"></i>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="pie-nodata-container">
              <div className="no-data-label-container">
                <div className="icon-container">
                  <i className="bi bi-x-circle mb-3"></i>
                </div>
                <div className="no-data-label">No Seasonal Plan Avilable.</div>
              </div>
            </div>
          )}

          {/* add seasonal plan */}
          <SlidingPane
            hideHeader
            from="right"
            width="44%"
            isOpen={addPlan}
            className="sliding-pane-custom"
            closeIcon={<i className="bi bi-x-lg"></i>}
            onRequestClose={() => setAddPlan(false)}
          >
            <AddSeasonalPlan onClose={() => setAddPlan(false)} />
          </SlidingPane>

          {/* apply seasonal plan */}
          <SlidingPane
            hideHeader
            from="right"
            width="44%"
            isOpen={applyPlan}
            className="sliding-pane-custom"
            closeIcon={<i className="bi bi-x-lg"></i>}
            onRequestClose={() => setApplyPlan(false)}
          >
            <ApplySeasonalPlan
              onClose={() => setApplyPlan(false)}
              specificPlan={specificPlan}
            />
          </SlidingPane>

          {/* Edit Seasonal Plan */}
          <SlidingPane
            hideHeader
            from="right"
            width="44%"
            isOpen={editPlan}
            className="sliding-pane-custom"
            closeIcon={<i className="bi bi-x-lg"></i>}
            onRequestClose={() => setEditPlan(false)}
          >
            <EditSeasonalPlan
              onClose={() => setEditPlan(false)}
              specificPlan={specificPlan}
            />
          </SlidingPane>
        </div>
      )}
    </>
  );
};

export default PropertySeasonalPlan;
