import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import beApi from "../../../../API/beApi";
import endPoints from "../../../../API/endPoints";
import { RootState } from "../../../../redux/store";
import {
  CATCH_MESSAGE,
  Logout,
  validatePositiveInt,
} from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import Card from "../../../../views/cardwrapper/Card";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
import Loader from "../../../../views/loader/Loader";

const OtherSettings = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // States
  const [bkngdays, setBkngDays] = useState<string>("");
  const [partilAmnt, setPartilAmnt] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [access, setAccess] = useState(true);
  const [bookngDaysHelperText, setBookngDaysHelperText] = useState<string>("");
  const [partialPercentageHelperText, setpartialPercentageHelperText] =
    useState<string>("");


  const [setupID, setSetupID] = useState<any>(null);

  const [childAge, setChildAge] = useState<any>("");
  const [infantAge, setInfantAge] = useState<any>("");


  //Endpoints Import
  const { getOtherSettings, otherSettingsUpdate } = endPoints.MANAGECHANNEL
  // --------------------------------

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  // Data Fetching
  const othrSetngData = async () => {
    setLoader(true);
    try {
      const othrStngDataRes = await beApi.get(
        `${getOtherSettings}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (othrStngDataRes.data.status === 1) {
        setBkngDays(othrStngDataRes?.data?.data?.advance_booking_days);
        setPartilAmnt(othrStngDataRes?.data?.data?.partial_pay_amt);
        // setLoader(false);
      } else {
        toast(`${othrStngDataRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      // setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  useEffect(() => {
    othrSetngData();
  }, []);


  useEffect(()=>{
    fetchInfantChildSetupdetails();
  },[current_property])

  // Data Posting
  const saveHandler = () => {
    if (!bookngDaysHelperText && !partialPercentageHelperText) {
      otherSetingSaveHandler();
    } else {
      toast("Please Enter Valid Numbers", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const otherSetingSaveHandler = async () => {
    setLoader(true);
    try {
      let otherStngReq = await beApi.post(otherSettingsUpdate, {
        partial_pay_amt: partilAmnt,
        advance_booking_days: bkngdays,
        hotel_id: current_property.hotel_id,
      });

      if (otherStngReq.data.status === 1) {
        saveChildInfantSetupData();
        // toast(otherStngReq.data.message, {
        //   hideProgressBar: true,
        //   type: "success",
        // });
        // othrSetngData();
        // setLoader(false);
      } 
      // else {
        // toast(`${otherStngReq.data.message}`, {
        //   hideProgressBar: true,
        //   type: "error",
        // });
      // }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      // setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Partial Percentage Amount Handler
  const paritalAmountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (validatePositiveInt(value) && parseInt(value) <= 90) {
      setpartialPercentageHelperText("");
      setPartilAmnt(value);
    } else {
      setpartialPercentageHelperText("Value Should be Between 0 to 90");
      setPartilAmnt(value);
    }
  };

  
  const fetchInfantChildSetupdetails = async () =>{
    try{

      let get_data = await beApi.get(`select-child-setup/${current_property.hotel_id}`)

      if(get_data.data.status === 1){
        setSetupID(get_data.data.data.id)
        setChildAge(get_data.data.data.children);
        setInfantAge(get_data.data.data.infant);
        setLoader(false);
      }
      else{
        setSetupID(null)
        setChildAge('');
        setInfantAge('')
        setLoader(false);
      }
    }
    catch(error:any){
      console.log(error);
    }
  }


  
  const saveChildInfantSetupData = async ()=>{
    if(setupID){
      try{
        let update_data = await beApi.post(`update-child-setup/${setupID}`,
        {
            hotel_id:current_property.hotel_id,
            infant:infantAge,
            children:childAge
        })

        if(update_data.data.status === 1){

          toast(update_data.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          
          othrSetngData();
          fetchInfantChildSetupdetails();
          setLoader(false);
        }
        else {
          toast(`${update_data.data.message}`, {
            hideProgressBar: true,
            type: "error",
          })
        }

      }
      catch(error:any){
        console.log(error);
      }
    }

    else{
      try{
        let insert_data = await beApi.post(`insert-child-setup`,
        {
            hotel_id:current_property.hotel_id,
            infant:infantAge,
            children:childAge
        })

        if(insert_data.data.status === 1){
          
          toast(insert_data.data.message, {
            hideProgressBar: true,
            type: "success",
          });

          othrSetngData();
          fetchInfantChildSetupdetails();
          setLoader(false);
        }
        else {
          toast(`${insert_data.data.message}`, {
            hideProgressBar: true,
            type: "error",
          })
        }

      }
      catch(error:any){
        console.log(error);
      }
    }
  }



  return (
    <>
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <div style={{ width: "50%" }}>
          <Card>
            <div>
              <InputTextField
                label={"Advance Booking Days"}
                value={bkngdays.toString()}
                helperText={bookngDaysHelperText}
                handleChange={(e) => {
                  setBkngDays(e.target.value);
                  if (validatePositiveInt(e.target.value)) {
                    setBookngDaysHelperText("");
                  } else {
                    setBookngDaysHelperText("Please Enter A Positive Integer");
                  }
                }}
                type={"number"}
                placeholder={"Type Number"}
              />
            </div>
            <div>
              <InputTextField
                label={"Partial Amount (%)"}
                value={partilAmnt.toString()}
                type={"number"}
                helperText={partialPercentageHelperText}
                placeholder={"Type Number"}
                handleChange={(e) => {
                  paritalAmountHandler(e);
                }}
              />
            </div>


              <div>
                <InputTextField
                  label={"Child Age"}
                  value={childAge}
                  handleChange={(e) => {
                    setChildAge(e.target.value);
                  }}
                />
              </div>

              <div>
                <InputTextField
                  label={"Infant Age"}
                  value={infantAge}
                  handleChange={(e) => {
                    setInfantAge(e.target.value);
                  }}
                />
              </div>



          </Card>
          <div className="save-btn mt-4 pt-2">
            <Button
              handleClick={() => {
                saveHandler();
              }}
              loader={loader}
              isAccessible={access}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default OtherSettings;
