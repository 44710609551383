import React, { useState, useEffect } from "react";
import CustomBreadcrumbs from "../../../components/breadcrumbs/CustomBreadcrumbs";
import SlidingPane from "react-sliding-pane";
import DerivedPlanSlider from "./DerivedPlanSlider";
import DerivedPlanRoomType from "./DerivedPlanRoomType";
import AddDerivedPlanRoomType from "./AddDerivedPlanRoomType";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";
import { toast } from "react-toastify";
import kernelApi from "../../../API/kernelApi";
import { useDispatch } from "react-redux";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import Loader from "../../../views/loader/Loader";
import Button from "../../../views/buttons/Button";
import { useNavigate } from "react-router";

const RoomRatePlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, setLoader] = useState<any>(true);

  const [derivedPlanSlider, setDerivedPlanSlider] = useState(false);
  const [derivedPlanRoomTypeSlider, setDerivedPlanRoomTypeSlider] =
    useState(false);

  const [addDerivedPlanRoomTypeSlider, setAddDerivedPlanRoomTypeSlider] =
    useState(false);

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { auth_token } = useSelector((state: RootState) => state.auth);

  const [allMasterRatePlans, setAllMasterRatePlans] = useState<any[]>([]);
  const [planIdStatus, setPlanIdStatus] = useState<any>(false);
  const [planId, setPlanId] = useState<any>();

  const [roomRatePlan, setRoomRatePlan] = useState<any>();
  const [roomType, setRoomType] = useState<any>();
  const [ratePlan, setRatePlan] = useState<any>();
  const [roomTypeName, setRoomTypeName] = useState<any>();
  const [planTypeName, setPlanTypeName] = useState<any>();
  const [roomRatePlanId, setRoomRatePlanId] = useState<any>();
  const [maxPeople, setMaxPeople] = useState();

  const [masterPlanData, setMasterPlanData] = useState<any[]>([]);
  const [checkStatus, setCheckStatus] = useState<any>(false);

  const [derivedPlanData, setDerivedPlanData] = useState<any[]>([]);

  const [derivedPlanEditData, setDerivedPlanEditData] = useState<any[]>([]);

  useEffect(() => {
    fetchAllMasterRatePlans();
  }, [current_property]);

  const fetchAllMasterRatePlans = async () => {
    setLoader(true);
    setAllMasterRatePlans([]);
    try {
      let get_all_master_rate_plans = await kernelApi.get(
        `master_hotel_rate_plan/all/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      if (get_all_master_rate_plans.data.status === 1) {
        setAllMasterRatePlans(get_all_master_rate_plans.data.data);

        for (var i = 0; i < get_all_master_rate_plans.data.data.length; i++) {
          if (get_all_master_rate_plans.data.data[i].master_plan_status === 1) {
            setPlanIdStatus(true);
            setPlanId(get_all_master_rate_plans.data.data[i].room_rate_plan_id);
          }
        }

        setRoomRatePlanId(
          get_all_master_rate_plans.data.data.room_rate_plan_id
        );
        setLoader(false);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const selectDetails = (
    e: any,
    room_rate_plan_id: any,
    room_type_id: any,
    rate_plan_id: any,
    room_type: any,
    plan_type: any,
    max_people: any
  ) => {
    setRoomRatePlan(room_rate_plan_id);
    setRoomType(room_type_id);
    setRatePlan(rate_plan_id);
    setRoomTypeName(room_type);
    setPlanTypeName(plan_type);

    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to change the plan ?`,
        "DELETE",
        async () => {
          try {
            masterPlanSelection(
              e,
              room_rate_plan_id,
              room_type_id,
              rate_plan_id,
              max_people
            );
          } catch (error: any) {
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
              Logout();
            }
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No, cancel it!", "Yes, I am sure!"]
      )
    );

    const masterPlanSelection = async (
      event: any,
      room_rate_plan_id: any,
      room_type_id: any,
      rate_plan_id: any,
      max_people: any
    ) => {
      if (event.target.value === "Derived") {
        let derive_plan_get_plan_details = await kernelApi.get(
          `/derive-plan/get-plan-details/${current_property.hotel_id}/${room_type_id}`
        );

        if (derive_plan_get_plan_details.data.status === 1) {
          setMasterPlanData(derive_plan_get_plan_details.data.data);

          for (
            let i = 0;
            i < derive_plan_get_plan_details.data.data.length;
            i++
          ) {
            if (
              derive_plan_get_plan_details.data.data[i].plan_type === "EP" &&
              derive_plan_get_plan_details.data.data.room_type_id ===
              room_type_id
            ) {
              setCheckStatus(false);
            }
          }
          setMaxPeople(max_people);
          setAddDerivedPlanRoomTypeSlider(true);
        } else {
          toast.error(derive_plan_get_plan_details.data.message);
        }
      } else if (event.target.value === "Normal") {
        let make_normal = await kernelApi.get(
          `/derive-plan/make-normal/${current_property.hotel_id}/${room_rate_plan_id}/${room_type_id}/${rate_plan_id}`
        );

        if (make_normal.data.status === 1) {
          toast.success(make_normal.data.message);
        } else {
          toast.error(make_normal.data.message);
        }
      } else {
        if (event.target.value === "UMP") {
          var master_status = 1;
        } else {
          var master_status = 2;
        }

        let update_derived_plan = await kernelApi.get(
          `/derive-plan/update-derived-plan/${room_rate_plan_id}/${master_status}`
        );

        if (update_derived_plan.data.status === 1) {
          toast.success(update_derived_plan.data.message);
        } else {
          toast.error(update_derived_plan.data.message);
        }
      }
    };
  };

  const viewDetails = async (room_type_id: any, rate_plan_id: any) => {
    let get_derived_rate_plan = await kernelApi.get(
      `/derive-plan/get_derived_rate_plan/${current_property.hotel_id}/${room_type_id}/${rate_plan_id}`
    );

    if (get_derived_rate_plan.data.status === 1) {
      setDerivedPlanData(get_derived_rate_plan.data.data);
      setDerivedPlanSlider(true);
    } else {
      toast.error(get_derived_rate_plan.data.message);
    }
  };

  const derivedPlanEdit = async (
    room_type,
    plan_type,
    room_rate_plan_id,
    room_type_id,
    rate_plan_id,
    max_people
  ) => {
    setDerivedPlanRoomTypeSlider(true);
    setRoomTypeName(room_type);
    setPlanTypeName(plan_type);
    setRoomRatePlanId(room_rate_plan_id);
    setRoomType(room_type_id);
    setRatePlan(rate_plan_id);
    setMaxPeople(max_people);

    setDerivedPlanEditData([]);

    let get_derived_plan_edit_data = await kernelApi.get(
      `/derive-plan/get_derived_rate_plan_edit/${current_property.hotel_id}/${room_type_id}/${rate_plan_id}`
    );

    if (get_derived_plan_edit_data.data.status === 1) {
      setDerivedPlanEditData(get_derived_plan_edit_data.data.data);
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Property Setup", href: "/property-setup" },
            {
              label: " Derived Rate Plan ",
            },
          ]}
        />
        <div className="manage__property__action__right">
          {/* <Button
                        icon="bi bi-plus-lg"
                    // handleClick={() => setAddDynamicSlider(true)}
                    // isAccessible={access}
                    >
                        Create Plan
                    </Button> */}
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      <div className="list-view-table-wrapper_rateplan mb-4">
        {loader ? (
          <Loader className="ineventory--loader" />
        ) : (
          <table className="table table-borderless_rateplan">
            <thead>
              <tr className="listview__table__heading">
                <th>Sl.No</th>
                <th>Room Type</th>
                <th>Plan Type</th>
                <th>Action</th>
                <th className="text-center">View</th>
                <th className="text-center">Edit</th>
              </tr>
            </thead>
            <tbody>
              {allMasterRatePlans.length > 0 &&
                allMasterRatePlans.map((items: any, i: any) => {
                  return (
                    <tr className="listview__table__data" key={i}>
                      <td>
                        <div className="serial-number">{i + 1}</div>
                      </td>
                      <td>
                        <div className="customer-details plan-type">
                          {items.room_type}
                        </div>
                      </td>
                      <td>
                        <div className="customer-details">
                          {items.plan_type}({items.plan_name})
                        </div>
                      </td>
                      <td>
                        <div className="customer-details mui__input__fieldset__legends__unset">
                          <select
                            className="form-select customer-details dynamic__pricing__room__rate__plan"
                            onChange={(e: any) => {
                              selectDetails(
                                e,
                                items.room_rate_plan_id,
                                items.room_type_id,
                                items.rate_plan_id,
                                items.room_type,
                                items.plan_type,
                                items.max_people
                              );
                            }}
                          >
                            {(planId === items.room_rate_plan_id ||
                              !planIdStatus) &&
                              items.plan_type === "EP" && (
                                <option
                                  value="UMP"
                                  selected={
                                    items.master_plan_status === 1 &&
                                    items.plan_type === "EP"
                                  }
                                >
                                  UMP
                                </option>
                              )}
                            {items.plan_type === "EP" && (
                              <option
                                value="MP"
                                selected={
                                  items.master_plan_status === 2 &&
                                  items.plan_type === "EP"
                                }
                              >
                                MP
                              </option>
                            )}

                            <option
                              value="Derived"
                              selected={
                                (items.master_plan_status === 3 &&
                                  items.plan_type !== "EP") ||
                                (items.master_plan_status === 3 &&
                                  items.plan_type === "EP")
                              }
                            >
                              Derived
                            </option>

                            <option
                              selected={
                                (items.master_plan_status === 0 &&
                                  items.plan_type !== "EP") ||
                                (items.master_plan_status === 0 &&
                                  items.plan_type === "EP")
                              }
                              value="Normal"
                            >
                              Normal
                            </option>
                          </select>
                        </div>
                      </td>
                      <td>
                        {items.master_plan_status === 3 && (
                          <div
                            className="room-rateplan-view-button"
                            onClick={() => {
                              viewDetails(
                                items.room_type_id,
                                items.rate_plan_id
                              );
                            }}
                          >
                            View
                          </div>
                        )}
                      </td>
                      <td>
                        {items.master_plan_status === 3 && (
                          <div>
                            <div
                              className="customer-view-button"
                              onClick={() => {
                                derivedPlanEdit(
                                  items.room_type,
                                  items.plan_type,
                                  items.room_rate_plan_id,
                                  items.room_type_id,
                                  items.rate_plan_id,
                                  items.max_people
                                );
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>

      {/* Derived plan formula slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="60%"
        isOpen={derivedPlanSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setDerivedPlanSlider(false);
        }}
      >
        <DerivedPlanSlider
          onClose={() => {
            setDerivedPlanSlider(false);
          }}
          derivedPlanData={derivedPlanData}
        />
      </SlidingPane>

      {/* Derived plan for a room type */}
      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={derivedPlanRoomTypeSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setDerivedPlanRoomTypeSlider(false);
        }}
      >
        <DerivedPlanRoomType
          onClose={() => {
            setDerivedPlanRoomTypeSlider(false);
          }}
          roomTypeName={roomTypeName}
          planTypeName={planTypeName}
          derivedPlanEditData={derivedPlanEditData}
          maxPeople={maxPeople}
          hotelId={current_property.hotel_id}
          roomType={roomType}
          ratePlan={ratePlan}
          roomRatePlan={roomRatePlan}
          roomRatePlanId={roomRatePlanId}
          refreshRatePlans={fetchAllMasterRatePlans}
        />
      </SlidingPane>

      {/* Add Derived plan for a room type */}
      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={addDerivedPlanRoomTypeSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setAddDerivedPlanRoomTypeSlider(false);
        }}
      >
        <AddDerivedPlanRoomType
          onClose={() => {
            setAddDerivedPlanRoomTypeSlider(false);
          }}
          roomTypeName={roomTypeName}
          planTypeName={planTypeName}
          masterPlanData={masterPlanData}
          checkStatus={checkStatus}
          maxPeople={maxPeople}
          hotelId={current_property.hotel_id}
          roomType={roomType}
          ratePlan={ratePlan}
          roomRatePlan={roomRatePlan}
          refreshRatePlans={fetchAllMasterRatePlans}
        />
      </SlidingPane>
    </>
  );
};

export default RoomRatePlan;
