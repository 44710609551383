import React, { useState } from "react";

// import user image
import UserImage from "../assets/img/userImage.png";

import InputTextField from "../views/inputtextfield/InputTextField";

import { validateEmail } from "../UtilityFunctions";

import StandardButton from "../views/buttons/Button";

import GetHelp from "../components/gethelp/GetHelp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { client_section }: any = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState("");
  const [emailHelperText, setEmailHelperText] = useState(" ");

  const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value) && e.target.value) {
      setEmailHelperText("Invalid email address");
    } else {
      setEmailHelperText("");
    }
  };
  const BackButtonHandler = () => {
    navigate("/login")
  };
  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <div className="div__input__wrapper__left div__left__side__box">
          <div className="logo__wrapper"></div>
          <div className="div__user__testimonials__wrapper">
            <div
              className="user__testimonial_image"
              style={{ backgroundImage: `url(${client_section?.imageUrl})` }}>
              <div className="user__testimonial__message__wrapper">
                <div className="testimonial__icon">
                  <i
                    className="bi bi-chat-square-quote"
                    style={{ fontSize: 18 }}></i>
                </div>
                <div className="user__testimonial__text">
                  "{client_section?.userTestimonial}”
                </div>
                <div className="user__name">{client_section?.userName}</div>
                <div className="user__designation">{client_section?.userDesignation}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="div__input__wrapper__right div__right__side__box">
          <div className="div__inputs">
            <div className="div--head--label">Reset Password</div>
            <div className="div--helper--text">
              Enter your registered email address to reset
            </div>

            <div className="div__inputs__wrapp">
              <div className="div--email--input">
                <InputTextField
                  value={email}
                  helperText={emailHelperText}
                  label="Email Address"
                  handleChange={(e) => EmailOnChange(e)}
                  handleFocus={(e) => {
                    if (!e.target.value) setEmailHelperText(" ");
                  }}
                />
              </div>

              <div className="__btn__wrapper text-end">
                <StandardButton className="login--btn" handleClick={() => { }}>
                  Submit
                </StandardButton>
              </div>

              <div className="go__back__btn__wrapper mt-3">
                <div className="go__back" onClick={BackButtonHandler}>
                  Go Back
                </div>
              </div>
            </div>

            <GetHelp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
