import {
  ALL_CHANNELS,
  COUPON_ID,
  ALL_ROOM_TYPES,
  OTA_MANAGE,
} from "../actionTypes";

export const AllChannels = (allChannels: any) => {
  return {
    type: ALL_CHANNELS,
    payload: allChannels,
  };
};
export const SetCouponId = (couponId: any) => {
  return {
    type: COUPON_ID,
    payload: couponId,
  };
};
export const SetAllRoomTypes = (allRoomTypes: any) => {
  return {
    type: ALL_ROOM_TYPES,
    payload: allRoomTypes,
  };
};
export const OTAManageAction = (ota: any) => {
  return {
    type: OTA_MANAGE,
    payload: ota,
  };
};
