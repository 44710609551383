import React from 'react';
// import svg hand image
import HandImage from "../../assets/svg/download_hand.svg";
import GooglePlay from "../../assets/svg/google_play.svg";
import AppStore from "../../assets/svg/app_store.svg";

const AppDownloadScreen = () => {
    return (
        <>
            <div className="download__mobile__app__wrrapper">
                <div className="download__image__wrapper">
                    <div className="download__outer__circle">
                        <div className="download__inner__circle">
                            <img src={HandImage} alt="Download image button" />
                        </div>
                    </div>
                </div>

                <div className="download__btn__section">
                    <div className="download__text__label">Bookingjini Extranet is available only on desktop</div>
                    <div className="download__label__sub__text">Please download our Bookingjini Host App to manage your property from mobile</div>
                    <div className="download__app__wrapper">
                        <a href="https://play.google.com/store/apps/details?id=com.bookingjini.bookingjiniHost&hl=en_IN&gl=US">
                            <div className="play__store__wrapper">
                                <img src={GooglePlay} alt="Google app store link button" />
                            </div>
                        </a>

                        <a href="https://apps.apple.com/is/app/bookingjini-host/id1575370328">
                            <div className="app__store__wrapper">
                                <img src={AppStore} alt="App store link button" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppDownloadScreen;