import { ADD_NUMBER_OF_FLOORS, ADD_SERVICEABLE_FLOORS, RESET_FLOOR_SELECTION } from "../actionTypes"

export const AddNumberOfFloors = (value: number) => {
    return {
        type: ADD_NUMBER_OF_FLOORS,
        payload: value
    }
}

export const AddServiceableFloors = (value: number[]) => {
    return {
        type: ADD_SERVICEABLE_FLOORS,
        payload: value
    }
}

export const ResetFloorSelection = () => {
    return {
        type: RESET_FLOOR_SELECTION,
    }
}
