import React, { useEffect, useState } from "react";
import SaveButton from "../../../views/buttons/Button";
import moment from "moment";
//import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import Button from "../../../views/buttons/Button";
import Card from "../../../views/cardwrapper/Card";
import InputTextField from "../../../views/inputtextfield/InputTextField";
import InputMultiSelectField from "../../../views/inputtextfield/InputMultiSelectField";
import kernelApi from "../../../API/kernelApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { toast } from "react-toastify";
import { CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";
import endPoints from "../../../API/endPoints";
import { sassFalse } from "sass";

export interface IEditDynamicPricingProps {
  onClose: () => void;
  allRoomTypes: any[];
  getDynamicPricingAll: () => void;
  roomTypeId: any;
}

const EditDynamicPricing: React.FC<IEditDynamicPricingProps> = ({
  onClose,
  allRoomTypes,
  getDynamicPricingAll,
  roomTypeId,
}) => {
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { dynamicPriceDetailsOfSpecificRoom, dynamicPriceUpdate } =
    endPoints.PROPERTY_SETUP_DYNAMIC_PRICING;
  const [loader, setLoader] = useState<boolean>(false);
  const [FromDateOpen, setFromDateOpen] = useState<boolean>(false);
  const [toDateOpen, setToDateOpen] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<any>(moment());
  const [toDate, setToDate] = useState<any>(moment().add(1, "d"));
  const [xtraAdult, setXtraAdult] = useState<boolean>(false);
  const [xtraChild, setXtraChild] = useState<boolean>(false);

  const [inventoryPercentageHelperText, setInventoryPercentageHelperText] =
    useState<any>("");

  const [toDatePercentageHelperText, setToDatePercentageHelperText] =
    useState<any>("");

  const [pricePercentageHelperText, setPricePercentageHelperText] =
    useState<any>("");
  const [selectedRoom, setSelectedRoom] = useState<any[]>([]);
  const [inputNumber, setInputNumber] = useState<any[]>([
    {
      inventoryPercentage: "",
      toDatePercentage: "",
      pricePercentage: "",
    },
  ]);

  // Api To Get Purticular Dynamic Pricing Details
  const getEditDynamicPricingData = async () => {
    try {
      let editDynamicPricingResponse = await kernelApi.get(
        `${dynamicPriceDetailsOfSpecificRoom}${roomTypeId}/${current_property.hotel_id}`
      );

      if (editDynamicPricingResponse?.data?.status === 1) {
        // For From and To Date
        setFromDate(
          moment(editDynamicPricingResponse?.data?.data[0]?.from_date)
        );
        setToDate(moment(editDynamicPricingResponse?.data?.data[0]?.to_date));

        // For Extra Adult and Child Price

        editDynamicPricingResponse?.data?.data[0]?.extra_adult === 1
          ? setXtraAdult(true)
          : setXtraAdult(false);

        editDynamicPricingResponse?.data?.data[0]?.extra_child === 1
          ? setXtraChild(true)
          : setXtraChild(false);

        // For Availability Data

        const availabilityArray = editDynamicPricingResponse?.data?.data.map(
          (iteam: any) => {
            return {
              inventoryPercentage: iteam.occupancy_start,
              toDatePercentage: iteam.occupancy_end,
              pricePercentage: iteam.price,
            };
          }
        );
        setInputNumber(availabilityArray);

        // For Selected Room Types
        const room = allRoomTypes.filter(
          (iteam: any) =>
            iteam.room_type_id ===
            editDynamicPricingResponse?.data?.data[0]?.room_type_id
        );
        setSelectedRoom([room[0].room_type]);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 401) {
        toast.error(error.response.message);
        Logout();
      }
    }
  };

  useEffect(() => {
    getEditDynamicPricingData();
    // const EditPricingData = allDynamicPriceData?.filter((iteam) => {
    //   return iteam?.id === editPricingId;
    // });

    // setSelectedRoom([EditPricingData[0].room_type]);
  }, [roomTypeId]);

  // For Adding a New Card
  const addInputHandler = () => {
    let x = [...inputNumber];
    x.push({
      inventoryPercentage: "",
      toDatePercentage: "",
      pricePercentage: "",
    });
    setInputNumber(x);
  };
  // For Removing
  const deletInput = (idx: any) => {
    let x = [...inputNumber];
    x.splice(idx, 1);
    setInputNumber(x);
  };

  // For Capturing Input Data
  const inputAvialibiltyHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: any
  ) => {
    const input = [...inputNumber];
    const { name, value } = e.target;

    if (Number(value) > 0 && Number(value) <= 100) {
      input[idx][name] = value ?? "";
      setInputNumber(input);
      setPricePercentageHelperText("");
      setToDatePercentageHelperText("");
      setInventoryPercentageHelperText("");
    } else {
      input[idx][name] = "";
      setInputNumber(input);
      setInventoryPercentageHelperText("Please Enter a Value between 1 to 100");
      setToDatePercentageHelperText("Please Enter a Value between 1 to 100");
      setPricePercentageHelperText("Please Enter a Value between 1 to 100");
    }
  };

  // Room Type Selection Handler

  const sourceOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const values = e.target.value ?? [];

    // Spreading the values array beacuse it is showing error while appling any array method to it
    let selected: any[] = [...values] ?? [];

    const notSelectedIteam = selectedRoom?.filter(
      (x) => selected.indexOf(x) === -1
    );

    if (selected.includes("all")) {
      if (notSelectedIteam.length > 0 && notSelectedIteam[0] !== "all") {
        const withoutAll = selected.filter((iteam) => iteam !== "all");

        setSelectedRoom(withoutAll);
      } else {
        const rooms = allRoomTypes.map((roomType) => roomType.room_type);
        setSelectedRoom([...rooms, "all"]);
      }
    } else {
      if (notSelectedIteam.length > 0 && notSelectedIteam[0] === "all") {
        setSelectedRoom([]);
      } else {
        if (
          selected.length ===
          allRoomTypes.map((roomType) => roomType.room_type).length
        ) {
          const rooms = [...selected, "all"];

          setSelectedRoom(rooms);
        } else {
          const withoutAll = selected.filter((iteam) => iteam !== "all");

          setSelectedRoom(withoutAll);
        }
      }
    }
  };

  // Add New Pricing Information
  const addNewPriceInformation = async () => {
    const htlRoomId: any[] = [];
    allRoomTypes.forEach((room) => {
      if (selectedRoom.includes(room.room_type)) {
        htlRoomId.push(room.room_type_id);
      }
    });

    const isArrDataEmpty = inputNumber.map((obj) => {
      return Object.values(obj).every((x) => x === null || x === "");
    });

    const isInvGraterTo = inputNumber.filter(
      (obj) =>
        Number(obj.inventoryPercentage) > Number(obj.toDatePercentage) &&
        Number(obj.inventoryPercentage) > Number(obj.pricePercentage)
    );
    // const isToGraterPrice = inputNumber.filter(
    //   (obj) => Number(obj.toDatePercentage) > Number(obj.pricePercentage)
    // );

    let inv_from = inputNumber.map((item) => item.inventoryPercentage);
    let inv_to = inputNumber.map((item) => item.toDatePercentage);
    let inv_from_length = inv_from.length;
    let inv_to_length = inv_to.length;
    let max_no = inv_from[0];
    let min_no = inv_to[inv_to_length - 1];
    let allowApiCall = true;
    for (let i = 0; i < inv_from_length; i++) {
      for (let j = 0; j < inv_from_length; j++) {
        if (max_no < inv_from[i] || max_no < inv_to[j]) {
          toast.error("Starting number should be the max number");

          return (allowApiCall = false);
        } else if (min_no > inv_from[i] || min_no > inv_to[j]) {
          toast.error("Ending number should be the min number");

          return (allowApiCall = false);
        } else {
          allowApiCall = true;
        }
      }
    }
    for (let i = 1, j = 0; i < inv_from_length; i++, j++) {
      if (inv_from[i]) {
        if (inv_to[j] - inv_from[i] != 1) {
          toast.error(
            "Gap between end availability of one row and start availability of another row should be One"
          );
          return (allowApiCall = false);
        } else {
          allowApiCall = true;
        }
      }
    }
    for (let i = 0; i < inv_from_length; i++) {
      if (inv_from[i] === inv_to[i]) {
        toast.error("Start and End Shouldn't be same");
        return (allowApiCall = false);
      } else {
        allowApiCall = true;
      }
    }
    if (toDate.isBefore(fromDate) || fromDate.isSame(toDate)) {
      toast.error("To Date is not Valid");
    } else if (htlRoomId.length === 0) {
      toast.error("Please select Room Types");
    } else if (isArrDataEmpty[0]) {
      toast.error("Please Fill Availability");
    } else if (allowApiCall) {
      try {
        setLoader(true);
        const newPriceResponse = await kernelApi.post(
          `${dynamicPriceUpdate}`,
          {
            hotel_id: current_property.hotel_id,
            room_type: htlRoomId,
            date_from: fromDate.format("DD-MM-YYYY"),
            date_to: toDate.format("DD-MM-YYYY"),
            inv_from: inputNumber.map((item) => item.inventoryPercentage),
            inv_to: inputNumber.map((item) => item.toDatePercentage),
            price: inputNumber.map((item) => item.pricePercentage),
            extra_adult: xtraAdult ? 1 : 0, //If Checked 1
            extra_child: xtraChild ? 1 : 0, //If Unchecked 0
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );

        if (newPriceResponse?.data?.status === 1) {
          toast.success(newPriceResponse.data.message);
          setLoader(false);
          onClose();
          getDynamicPricingAll();
        } else {
          toast.error(newPriceResponse.data.message);
          setLoader(false);
          onClose();
          getDynamicPricingAll();
        }
      } catch (error: any) {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
        onClose();
        if (error.response.status === 401) {
          toast.error(error.response.message);
          Logout();
        }
      }
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i onClick={() => onClose()} className="bi bi-x"></i>
        </div>
        <div className="standard-page--label">Edit Dynamic Pricing</div>
      </div>
      <div className="height-64px"></div>
      <div className="add-edit-dynamicPricing-content-wrapper">
        {/* DatePickers */}
        <div className="from-to-date-picker-content-wrapper">
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={`From Date`}
                open={FromDateOpen}
                onOpen={() => setFromDateOpen(true)}
                onClose={() => setFromDateOpen(false)}
                value={fromDate}
                onChange={(date: any) => {
                  setFromDate(moment(date));
                }}
                minDate={new Date()}
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setFromDateOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To Date"
                open={toDateOpen}
                onOpen={() => setToDateOpen(true)}
                onClose={() => setToDateOpen(false)}
                value={toDate}
                onChange={(date: any) => {
                  setToDate(moment(date));
                }}
                minDate={fromDate}
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setToDateOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        {/* Extra Details Section */}
        <div className="add-edit-dynamicPricing-content-labels">
          Extra Details <span className="manadatory-field">*</span> :
        </div>
        <div className="xtra-adult-child-checkbox-wrapper">
          <div className="xtra-adult-checkbox-wrapper">
            <input
              type="checkbox"
              className="form-check-input"
              checked={xtraAdult}
              name="extraAdult"
              onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                setXtraAdult(!xtraAdult);
              }}
            />
            <div>Extra Adult</div>
          </div>
          <div className="xtra-adult-checkbox-wrapper">
            <input
              type="checkbox"
              className="form-check-input"
              checked={xtraChild}
              name="extraChild"
              onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                setXtraChild(!xtraChild);
              }}
            />
            <div>Extra Child</div>
          </div>
        </div>
        {/* Availability Section */}
        <div className="availability-add-button-wrapper">
          <div className="add-edit-dynamicPricing-content-labels">
            Availability <span className="manadatory-field">*</span> :
          </div>
          <div>
            <Button
              handleClick={() => {
                addInputHandler();
              }}
            >
              Add
            </Button>
          </div>
        </div>
        {inputNumber.map((iteam, index) => {
          return (
            <Card className="availability-cardwrapper" key={index}>
              {index !== 0 && (
                <div className="remove-card-button">
                  <span
                    className="remove-button"
                    onClick={() => {
                      deletInput(index);
                    }}
                  >
                    Remove
                  </span>
                </div>
              )}

              <div className="inventory-todate-wrapper">
                <InputTextField
                  label={"Inventory "}
                  value={iteam?.inventoryPercentage}
                  name={"inventoryPercentage"}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    inputAvialibiltyHandler(e, index);
                  }}
                  adoreMentIcon="bi bi-percent"
                  type="number"
                  helperText={inventoryPercentageHelperText}
                />
                <InputTextField
                  label={"To Date"}
                  value={iteam?.toDatePercentage}
                  name={"toDatePercentage"}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    inputAvialibiltyHandler(e, index);
                  }}
                  adoreMentIcon="bi bi-percent"
                  type="number"
                  helperText={toDatePercentageHelperText}
                />
                <InputTextField
                  label={"Price Percentage"}
                  value={iteam?.pricePercentage}
                  name={"pricePercentage"}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    inputAvialibiltyHandler(e, index);
                  }}
                  adoreMentIcon="bi bi-percent"
                  type="number"
                  helperText={pricePercentageHelperText}
                />
              </div>
              {/* <div className="price-percentage"></div> */}
            </Card>
          );
        })}

        {/* Room Type Section */}
        <div className="add-edit-dynamicPricing-content-labels">
          Room Type <span className="manadatory-field">*</span> :
        </div>
        <div className="room--type-field-wrapper">
          {/* <InputSelectField
            label={"Select Room Type"}
            value={selectedRoom}
            values={allRoomTypes.map((roomType) => roomType.room_type_id)}
            options={allRoomTypes.map((roomType) => roomType.room_type)}
            handleChange={(e) => {
              setSelectedRoom(e.target.value);
            }}
          /> */}
          <InputMultiSelectField
            label={"Select Room Type"}
            value={selectedRoom}
            options={allRoomTypes.map((roomType) => roomType.room_type)}
            handleChange={(e) => {
              sourceOnchange(e);
            }}
            isAllSelected={selectedRoom.includes("all")}
          />
        </div>
        <div className="dynamicPricing-availability-imp-note">
          *Inventory Start to End Should Be In a Decreasing Order.
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <SaveButton
          className="bi bi-arrow-right"
          handleClick={addNewPriceInformation}
          loader={loader}
        >
          Submit
        </SaveButton>
      </div>
    </>
  );
};

export default EditDynamicPricing;
