import Button from "../../../../views/buttons/Button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SlidingPane from "react-sliding-pane";
import AddPayment from "./AddPayment";
import SetupPayment from "./SetupPayment";
import beApi from "../../../../API/beApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Toast from "../../../../components/toaster/Toast"
import {
  HideAddPaymentSlider,
  HideSetupPaymentSlider,
  OpenAddPaymentSlider,
  SetCurrentPaymentGatewayDetails,
  ShowSetupPaymentSlider
} from "../../../../redux/actions/SetGatewayDetailsAction";
import Loader from "../../../../views/loader/Loader";
import { nanoid } from "@reduxjs/toolkit";
import { CATCH_MESSAGE, UpdateSidebar } from "../../../../UtilityFunctions";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";




function PaymentOptions() {

  UpdateSidebar("Manage Channels", "Booking Engine");

  const [allGateWays, setAllGateWays] = useState<any>(null)

  const [ALL_GATEWAYS_MAP, set_ALL_GATEWAYS_MAP] = useState([])

  const [defaultMsg, setDefaultMsg] = useState({ msg: 'No Payment Gateway Selected' })

  const [dataFetchedStatus, setDataFetchedStatus] = useState<any>('unset')

  const dispatch = useDispatch()

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const { auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const isOpenSlider_setup = useSelector((store: RootState) => store.gateWay.isOpenSlider_setup)

  const openAddPaymentSlider = useSelector((store: RootState) => store.gateWay.isOpenSlider_add)

  const newModeAddedOrUpdated = useSelector((store: RootState) => store.gateWay.value)

  const navigate = useNavigate();


  // ----------------------------
  const fetchSelectedPaymentMethodsDetails = async () => {
    try {
      const Response = await beApi.get(
        `select-paymentgateway/${current_property.hotel_id}`
        ,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      )
      if (Response.data.status === 1) {
        setDataFetchedStatus(1)
        setAllGateWays(Response?.data ? Response.data.Data : [])
      }
      else {
        setDataFetchedStatus(0)
        setDefaultMsg({ msg: Response.data.message })
        setAllGateWays([])
      }
    }
    catch (error) {
      setDataFetchedStatus(-1)
      setAllGateWays([])
      Toast(CATCH_MESSAGE, "error");
    }
  }

  useEffect(() => { fetchSelectedPaymentMethodsDetails() }, [newModeAddedOrUpdated])

  useEffect(() => {
    const arr = allGateWays ? allGateWays.map(gateway => {

      const { provider_name, hotel_id, credentials, payment_gateway_logos, is_active, id } = gateway

      const HotelId = hotel_id
      const ProiderName = provider_name
      const Credentials = credentials
      const Logo = payment_gateway_logos
      const IsActive = is_active == 1 ? true : false
      const ModeId = id


      const GatewayDataObj = () => {
        const dataObj = {
          HotelId, ProiderName, Credentials, Logo, IsActive, ModeId
        }
        return dataObj
      }

      return (
        <div className="payment__option__cards mb-4" key={nanoid()}>
          <div className="payment_option_content ms-0">
            <div className="payment_medium_image_container">
              <div className={is_active == 1 ? "paymentOptionStatusActive" : "paymentOptionStatusInactive"}>{is_active == 1 ? "Active" : "Inactive"}</div>
              <div className="imgBox">
                <img src={payment_gateway_logos} alt={provider_name} />
              </div>
            </div>
            <div className="payment_button">
              <Button
                className="payment_setup_btn"
                handleClick={() => {
                  dispatch(SetCurrentPaymentGatewayDetails(GatewayDataObj()))
                  dispatch(ShowSetupPaymentSlider())
                }}
              >
                Setup
              </Button>
            </div>
          </div>
        </div>
      )
    }) : []
    set_ALL_GATEWAYS_MAP(arr)
  }, [allGateWays])




  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs content={[{ label: "Manage Channels", href: "/manage-channels" }, { label: "Booking Engine", href: "/manage-channels/booking-engine" }, { label: "Payment Gateway" }]} />
        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <Button className="add__btn"
            handleClick={() => {
              // setOpenAddPaymentSlider(true);
              dispatch(OpenAddPaymentSlider())
            }}
          >
            Add
          </Button>

          <Button className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>

      <div className="hr--line"></div>

      {/* -------------------------- */}

      <div className="payment_options_wrapper">
        {/* ------------------ */}

        {/* {allGateWays ?
                    ALL_GATEWAYS_MAP
                        .length > 0 ? ALL_GATEWAYS_MAP
                        : <div className="empty__room__div">
                            <i className="bi bi-x-circle mb-3"></i>
                            <div>{defaultMsg.msg}</div>
                        </div>
                    : <Loader className='loaderClass' />} */}

        {/* <div className="paymentOptionStatusInactive">Inactive</div> */}

        {
          dataFetchedStatus === 1 ?
            ALL_GATEWAYS_MAP.length > 0 ? ALL_GATEWAYS_MAP : <div className="loadercontainer">
              <div className="empty__room__div mt-5">
                <i className="bi bi-x-circle mb-3"></i>
                <div>{defaultMsg.msg}</div>
              </div>
            </div>
            :
            dataFetchedStatus === 'unset' ? <Loader className='loaderClass' /> : <div className="loadercontainer">
              <div className="empty__room__div mt-5">
                <i className="bi bi-x-circle mb-3"></i>
                <div>{defaultMsg.msg}</div>
              </div>
            </div>
        }

        {/* ------------------ */}

        {/* Slider */}
        <SlidingPane
          hideHeader
          from="right"
          width="491px"
          isOpen={openAddPaymentSlider}
          closeIcon={<i className="bi bi-x-lg"></i>}
          onRequestClose={() => {
            // setOpenAddPaymentSlider(false);
            dispatch(HideAddPaymentSlider())
          }}
        >
          <AddPayment
            onClose={() => {
              // setOpenAddPaymentSlider(false);
              dispatch(HideAddPaymentSlider())
            }}
          />
        </SlidingPane>

        <SlidingPane
          hideHeader
          from="right"
          width="491px"
          isOpen={isOpenSlider_setup}
          closeIcon={<i className="bi bi-x-lg"></i>}
          onRequestClose={() => {
            dispatch(HideSetupPaymentSlider())
          }}
        >
          <SetupPayment
            onClose={() => {
              dispatch(HideSetupPaymentSlider())
            }}
          />
        </SlidingPane>
      </div>
    </>
  )
}

export default PaymentOptions