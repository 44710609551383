import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";
import startOfDay from "date-fns/startOfDay";

import {
  Update_cm_loader_disable,
  Update_be_loader_disable,
  Update_cm_loader,
  Update_be_loader,
} from "../../redux/actions/SidebarAction";
import Bookingjini from "../../assets/svg/bookingjini.svg";

import InventoryBtn from "../../views/buttons/Button";
import Button from "../../views/buttons/Button";

import { CATCH_MESSAGE, validatePositiveInt } from "../../UtilityFunctions";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";

import { toast } from "react-toastify";

import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";

//import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

import cmApi from "../../API/cmApi";
import beApi from "../../API/beApi";
import BulkUpdate from "../Rates/BulkUpdate";
import endPoints from "../../API/endPoints";

interface IUpdateInventorySliderProps {
  onClose: () => void;
  selectRoomType: string;
  allChannel: any[];
  room_type: string;
  inventory: any;
  getInventory: () => void;
  //  close_update_slider: (be: any, cm: any) => void;
  // close_update_slider: (e: boolean) => void;
  close_update_slider: (initialState: any) => void;
  open_update_slider: boolean;
  cmloader: any;
  beloader: any;
  setLoader_cm: (initialState: any) => void;
  setLoader_be: (initialState: any) => void;
  totalRooms: any;
  api_data: any;
}

const UpdateInventory: React.FC<IUpdateInventorySliderProps> = ({
  onClose,
  selectRoomType,
  allChannel,
  room_type,
  inventory,
  getInventory,
  close_update_slider,
  open_update_slider,
  cmloader,
  beloader,
  setLoader_cm,
  setLoader_be,
  totalRooms,
  api_data,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(null);
  const [singleDate, setSingleDate] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [selectedOta, setSelectedOta] = useState<any>([]);
  const [noOfInventory, setNoOfInventory] = useState<any>();
  const [lengthOfStay, setLengthOfStay] = useState<any>();
  const [dateRangeButton, setDateRangeButton] = useState<any>(true);
  const [invArray, setInvArray] = useState<any>([]);
  const [values, setValues] = useState<any>([startOfDay(new Date())]);
  const [errorMessage_cm, setErrorMessage_cm] = useState<any>([]);
  const [errorMessage_be, setErrorMessage_be] = useState<any>([]);
  const [allotas, setAllotas] = useState<any>([]);
  // const [apiData, setApiData] = useState<any>();

  const [be_loader, setBe_loader] = useState<any>(false);
  const [cm_loader, setCm_loader] = useState<any>(false);

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const getAllotas = () => {
    let newArr: any[] = [{ id: -1, name: "Bookingjini" }];
    allChannel.forEach((channel) => {
      newArr.push({ id: channel.ota_id, name: channel.ota_name });
    });
    setAllotas(newArr);
  };
  useEffect(() => {
    getAllotas();
  }, []);

  const refresh = () => {
    document.getElementById("refresh-button")?.click();
  };

  //Endpoints Import
  const { cmInvUpdate, beInvUpdate, cmSingleUpdate, beSingleUpdate } =
    endPoints.INVENTORY;
  // --------------------------------

  let block = 0;
  const onDateChange = (date: any) => {
    let datesArray = [...singleDate];
    let inv = [...invArray];
    var index = datesArray.findIndex(
      (x) => x.date == moment(date).format("DD-MM-YYYY")
    );
    index === -1 &&
      datesArray.push({ date: moment(date).format("DD-MM-YYYY") });
    setFromDate(moment(date));
    setSingleDate(datesArray);
    inv.push({
      date: moment(date).format("DD-MM-YYYY"),
      block_status: block,
    });
    setInvArray(inv);
  };
  const RemoveDate = (date: any) => {
    let removeDate = [...singleDate];
    let removeInv = [...invArray];
    setSingleDate(removeDate.filter((Date: any) => Date.date !== date.date));
    setInvArray(removeDate.filter((Date: any) => Date.date !== date.date));
  };

  // for one channel select
  const handleOtaCode = (e: any, ota_id: any, ota_name: any) => {
    let newArray = [...selectedOta];
    if (e.target.checked) {
      newArray.push({ id: ota_id, name: ota_name });
    } else {
      newArray = newArray.filter((selected_otacode) => {
        return selected_otacode.id !== ota_id;
      });
    }

    setSelectedOta(newArray);
  };

  // for all channels select
  const selectAllChannels = (e: any) => {
    let newArray: any[] = [{ id: -1, name: "Bookingjini" }];
    allChannel.forEach((channel) => {
      if (e.target.checked) {
        newArray.push({ id: channel.ota_id, name: channel.ota_name });
      } else {
        newArray = [];
      }
    });
    setSelectedOta(newArray);
  };

  const cm_BulkUpdate = async (removeBookingEngine: any) => {
    try {
      let res = await cmApi.post(
        cmInvUpdate,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: room_type,
          date_from: moment(startDate).format("DD-MM-YYYY"),
          date_to: moment(endDate).format("DD-MM-YYYY"),
          no_of_rooms: noOfInventory,
          los: lengthOfStay,
          block_status: 0,
          admin_id: admin_id,
          ota_id: removeBookingEngine,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      setLoader_cm(false);
      if (!beloader) {
        refresh();
      }
      // setCm_loader(false);
      dispatch(Update_cm_loader_disable());
      setErrorMessage_cm((prev) => [...prev, ...res.data]);
      // setErrorMessage_cm((prev) => [...prev, res.data]);
      // if (res.data.status === 1) {
      //   toast.success(res.data.message);
      // } else {
      //   toast.error(res.data.message);
      // }
    } catch (error) {
      setLoader_cm(false);
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const be_BulkUpdate = async () => {
    try {
      let res = await beApi.post(
        beInvUpdate,
        {
          hotel_id: current_property.hotel_id,
          room_type_id: room_type,
          date_from: moment(startDate).format("DD-MM-YYYY"),
          date_to: moment(endDate).format("DD-MM-YYYY"),
          no_of_rooms: noOfInventory,
          los: lengthOfStay,
          block_status: 0,
          admin_id: admin_id,
          ota_id: [-1],
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      // setBe_loader(false);
      setLoader_be(false);
      if (!cmloader) {
        refresh();
      }
      dispatch(Update_be_loader_disable());
      setErrorMessage_cm((prev) => [...prev, res.data]);
      // if (res.data.status === 1) {
      //   toast.success(res.data.message);
      // } else {
      //   toast.error(res.data.message);
      // }
    } catch (error) {
      setLoader_be(false);
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const cm_IndividualUpdate = async (removeBookingEngine: any) => {
    let inv = [...invArray];
    inv.forEach((element: any, ix: number) => {
      inv[ix] = {
        date: element.date,
        block_status: 0,
        los: lengthOfStay,
        no_of_rooms: noOfInventory,
      };
    });
    try {
      let res = await cmApi.post(
        cmSingleUpdate,
        {
          hotel_id: current_property.hotel_id,
          inv: {
            room_type_id: room_type,
            room_type: selectRoomType,
            inv: inv,
          },
          admin_id: admin_id,
          ota_id: removeBookingEngine,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      dispatch(Update_cm_loader_disable());
      // setCm_loader(false);
      setLoader_cm(false);
      if (!beloader) {
        refresh();
      }
      // console.log(res.data, 'setErrorMessage_cm', 'cm_IndividualUpdate')
      setErrorMessage_cm((prev) => [...prev, ...res.data]);
      // setErrorMessage_cm((prev) => [...prev, res.data]);
      // if (res.data.status === 1) {
      //   toast.success(res.data.message);
      // } else {
      //   toast.error(res.data.message);
      // }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader_cm(false);
    }
  };

  const be_IndividualUpdate = async () => {
    let inv = [...invArray];
    inv.forEach((element: any, ix: number) => {
      inv[ix] = {
        date: element.date,
        block_status: 0,
        los: lengthOfStay,
        no_of_rooms: noOfInventory,
      };
    });
    try {
      let res = await beApi.post(
        beSingleUpdate,
        {
          hotel_id: current_property.hotel_id,
          inv: {
            room_type_id: room_type,
            room_type: selectRoomType,
            inv: inv,
          },
          admin_id: admin_id,
          ota_id: [-1],
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      // setBe_loader(false);
      setLoader_be(false);
      if (!cmloader) {
        refresh();
      }
      dispatch(Update_be_loader_disable());
      setErrorMessage_be(res.data);
      setErrorMessage_cm((prev) => [...prev, res.data]);
      // if (res.data.status === 1) {
      //   toast.success(res.data.message);
      // } else {
      //   toast.error(res.data.message);
      // }
    } catch (error) {
      setLoader_be(false);
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };
  // const close_update_slider = () => {
  //    if (!be_loader && !cm_loader) {
  //       close_update_slider(false);
  //     }
  // }

  const update_Inventory = () => {
    // console.log('update_Inventory called')
    setErrorMessage_cm([]);

    // onClose();
    if (
      noOfInventory &&
      lengthOfStay &&
      selectedOta.length > 0 &&
      ((!dateRangeButton && singleDate.length > 0) ||
        (dateRangeButton && startDate && endDate))
    ) {
      if (dateRangeButton) {
        if (selectedOta.some((ota: any) => ota.id === -1)) {
          //  setBe_loader(true);
          setLoader_be(true);
          dispatch(Update_be_loader());
          be_BulkUpdate();
          // if (open_update_slider && !be_loader) {
          //   close_update_slider(false);
          // }
        }

        let removeBookingEngine = selectedOta.filter(
          (item: any) => item.id !== -1
        );
        let source_ids: any = [];
        removeBookingEngine?.map((ota: any, i: any) => {
          source_ids.push(ota.id);
        });
        if (source_ids.length > 0) {
          //  setCm_loader(true);
          setLoader_cm(true);
          // (document.getElementById("overlay") as HTMLDivElement).style.display =
          //   "block";
          dispatch(Update_cm_loader());
          cm_BulkUpdate(source_ids);
        }
      } else {
        if (selectedOta.some((ota: any) => ota.id === -1)) {
          // setBe_loader(true);
          setLoader_be(true);
          dispatch(Update_be_loader());
          be_IndividualUpdate();
        }
        let removeBookingEngine = selectedOta.filter(
          (item: any) => item.id !== -1
        );
        let source_ids: any = [];
        removeBookingEngine?.map((ota: any, i: any) => {
          source_ids.push(ota.id);
        });
        if (source_ids.length > 0) {
          //  setCm_loader(true);
          setLoader_cm(true);
          dispatch(Update_cm_loader());
          cm_IndividualUpdate(source_ids);
        }
      }
      //  getInventory();
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };


  const dateRange_mode = () => {
    setDateRangeButton(!dateRangeButton);
    setSingleDate([]);
    setInvArray([]);
    setLengthOfStay(null);
    setNoOfInventory(null);
    setSelectedOta([]);
    setErrorMessage_cm([]);
    setErrorMessage_be([]);
  };

  const individualMode = () => {
    setDateRangeButton(!dateRangeButton);
    setSingleDate([]);
    setInvArray([]);
    setLengthOfStay(null);
    setNoOfInventory(null);
    setSelectedOta([]);
    setErrorMessage_cm([]);
    setErrorMessage_be([]);
  };

  const changeInventory = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (validatePositiveInt(value) || !value) {
      if (parseInt(value) > totalRooms) {
        setNoOfInventory(totalRooms);
      } else {
        setNoOfInventory(value);
      }
    }
  };

  // console.log(errorMessage_cm, "errorMessage_cm");

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Update ({selectRoomType})</div>
      </div>

      <div className="height-64px"></div>

      <div className="inventory__update__wrapper">
        <div className="standard-page--label mb-4 ">Date Type</div>
        <div className="div__select__inputs__wrapper">
          <div
            className={
              dateRangeButton
                ? "me-4 select__input__div__active"
                : "me-4 select__input__div"
            }
            onClick={() => dateRange_mode()}
          >
            {dateRangeButton ? <i className="bi bi-check-lg"></i> : null}
            Date Range
          </div>
          <div
            className={
              !dateRangeButton
                ? "me-4 select__input__div__active"
                : "me-4 select__input__div"
            }
            onClick={() => individualMode()}
          >
            {!dateRangeButton ? <i className="bi bi-check-lg"></i> : null}
            Individual
          </div>
        </div>
        {dateRangeButton ? (
          <div
            className="inventory__select__date__wrapper my-4"
            onClick={() => setFocusedInput("startDate")}
          >
            <div className="date__picker__icon">
              <i className="bi bi-calendar"></i>
            </div>

            <div className="date__label">
              {!startDate && !endDate
                ? "Select Dates"
                : (startDate ? moment(startDate).format("DD MMM YYYY") : "") +
                " - " +
                (endDate ? moment(endDate).format("DD MMM YYYY") : "")}
            </div>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              focusedInput={focusedInput}
              preventPastDates={true}
              setFocusedInput={setFocusedInput}
            />
          </div>
        ) : (
          <div className="my-4 mui__input__fieldset__legends__unset">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={fromDate}
                onChange={onDateChange}
                minDate={new Date()}
                inputFormat="dd MMM yyyy"
                InputAdornmentProps={{ position: "start" }}
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        )}
        {singleDate &&
          singleDate?.map((date: any) => {
            return (
              <div className="individual_dates">
                {date.date}
                <i
                  className="bi bi-x icon--close"
                  onClick={() => RemoveDate(date)}
                ></i>
              </div>
            );
          })}
        <div className="inventory__number__wrapper mt-3 pt-3">
          <div className="__default--input--field">
            <InputTextField
              label="Number of Inventory"
              value={noOfInventory}
              handleChange={changeInventory}
            />
          </div>
          <div className="__default--input--field">
            <InputTextField
              label="Length of stay"
              value={lengthOfStay}
              handleChange={(e) => setLengthOfStay(e.target.value)}
            />
          </div>
        </div>

        <div className="inventory__sync__channel__wrapper my-2">
          <div className="select__all__checkbox__wrapper mb-4">
            <div className="standard-page--label">Select Channels</div>
            <div className="select__all__checkbox">
              <input
                className="form-check-input custom-size me-3"
                type="checkbox"
                id="flexCheckDefault"
                onChange={(e) => {
                  selectAllChannels(e);
                }}
                checked={selectedOta.length === allotas.length}
              />
            </div>
          </div>

          <div className="mb-4">
            {errorMessage_be && errorMessage_be.status == 0 && (
              <div className="ota_error_message">Roomtype should be sync</div>
            )}
            {/* <div
              className={`channel__wrapper  ${errorMessage_cm && errorMessage_be.status == 0
                ? "error--border"
                : errorMessage_cm &&
                  errorMessage_be.status == 1 &&
                  selectedOta.some(
                    (source: any) => source.name === "Bookingjini"
                  )
                  ? "success--border"
                  : ""
                }`}
              onClick={() =>
                document.getElementById(`flexCheckDefault${-1}`)?.click()
              }
            >
              <div className="d-flex justify-content-center align-items-center">
                <div className="channel__image__content">
                  <img src={api_data?.ota_icon_path} />
                </div>
                {errorMessage_be?.status === 1 && (
                  <div className="success__checkmark">
                    <i className="bi bi-check"></i>
                  </div>
                )}
              </div>
              <div className="channel__select__checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input unclickable"
                    // value={channel.ota_id}
                    type="checkbox"
                    id={`flexCheckDefault${-1}`}
                    onChange={(e) => {
                      handleOtaCode(e, -1, "Bookingjini");
                    }}
                    checked={selectedOta.some((ota: any) => ota.id === -1)}
                  //checked={selectedOta.includes(-1)}
                  />
                </div>
              </div>
            </div> */}
          </div>

          {allChannel?.map((channel, id) => {
            return (
              <div className="mb-4">
                {errorMessage_cm?.some(
                  (ota: any) =>
                    ota.ota_name?.toLowerCase() ===
                    channel.ota_name?.toLowerCase() && ota.status === 0
                ) &&
                  errorMessage_cm
                    .filter(
                      (task: any) =>
                        task.ota_name?.toLowerCase() ===
                        channel.ota_name?.toLowerCase()
                    )
                    ?.map((filtered: any) => (
                      <div className="ota_error_message">
                        {filtered.response_msg}
                      </div>
                    ))}

                <div
                  className={`channel__wrapper  ${errorMessage_cm?.some(
                    (ota: any) =>
                      ota?.ota_name === channel?.ota_name.toLowerCase() &&
                      ota?.status == 0
                  )
                      ? "error--border"
                      : errorMessage_cm?.some((ota: any) => {
                        // console.log(ota.ota_name.toLowerCase(), channel.ota_name.toLowerCase())
                        ota?.ota_name?.toLowerCase() ===
                          channel.ota_name?.toLowerCase() &&
                          ota.status == 1 &&
                          selectedOta.some(
                            (source: any) =>
                              source?.name?.toLowerCase() ===
                              channel?.ota_name?.toLowerCase()
                          );
                      })
                        ? "success--border"
                        : ""
                    }`}
                  key={id}
                  onClick={() =>
                    document.getElementById(`flexCheckDefault${id}`)?.click()
                  }
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="channel__image__content">
                      <img src={channel.ota_logo_path} />
                    </div>

                    {errorMessage_cm?.some(
                      (ota: any) =>
                        ota.status === 1 &&
                        ota.ota_name.toLowerCase() ===
                        channel.ota_name.toLowerCase() &&
                        selectedOta.some((source: any) => {
                          // console.log(source.name, 'source', channel.ota_name, 'ota name')
                          // console.log(selectedOta, 'selectedOta')
                          // console.log(errorMessage_cm, 'errorMessage_cm')
                          return (
                            source.name.toLowerCase() ===
                            channel.ota_name.toLowerCase()
                          );
                        })
                    ) && (
                        <div className="success__checkmark">
                          <i className="bi bi-check"></i>
                        </div>
                      )}
                  </div>
                  <div className="channel__select__checkbox">
                    <div className="form-check">
                      <input
                        className="form-check-input unclickable"
                        value={channel.ota_id}
                        type="checkbox"
                        id={`flexCheckDefault${id}`}
                        onChange={(e) => {
                          handleOtaCode(e, channel.ota_id, channel.ota_name);
                        }}
                        checked={selectedOta.some(
                          (ota: any) => ota.id === channel.ota_id
                        )}
                      // checked={selectedOta.includes(channel.ota_id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div id="refresh-button" className="d-none" onClick={getInventory}></div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      {!beloader && !cmloader ? (
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3" handleClick={onClose}>
              Cancel
            </Button>
            <InventoryBtn handleClick={update_Inventory}>Save</InventoryBtn>
          </div>
        </div>
      ) : (
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3">Cancel</Button>
            <InventoryBtn>
              Save
              <span
                className="spinner-border spinner-border-sm pd-4"
                role="status"
                aria-hidden="true"
              ></span>
            </InventoryBtn>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateInventory;
