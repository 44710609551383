import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import endPoints from "../../../../API/endPoints";
import kernelApi from "../../../../API/kernelApi";
import { RootState } from "../../../../redux/store";
import { CATCH_MESSAGE } from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
import Loader from "../../../../views/loader/Loader";

export interface IRailwayWayProps {
  setRailWayNames: React.Dispatch<React.SetStateAction<any[]>>;
  setDistanceFrmRail: React.Dispatch<React.SetStateAction<any[]>>;
  htlinformation: any;
  getLocationInformation: () => void;
}

const RailwayWay: React.FC<IRailwayWayProps> = ({
  setRailWayNames,
  setDistanceFrmRail,
  htlinformation,
  getLocationInformation,
}) => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  //   States
  const [loader, setLoader] = useState<boolean>(false);
  const [inputRow, setInputRow] = useState<any[]>([{ railway: "", dis: "" }]);
  const [railWayNames, setrailWayNames] = useState<any[]>([]);
  const [railWaydistance, setrailWaydistance] = useState<any[]>([]);
  const [access, setAccess] = useState(true);
  const { rail_station_name, distance_from_rail } = htlinformation;

  //Endpoints Import
  const { otherInfoUpdate } = endPoints.MANAGECHANNEL
  // --------------------------------

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    const arr: React.SetStateAction<any[]> = [];
    rail_station_name?.map((iteam: any, idx: any) => {
      return arr.push({
        railway: iteam,
        dis: distance_from_rail[idx],
      });
    });

    if (arr.length) {
      setInputRow(arr);
    } else {
      setInputRow([{ airPort: "", dis: "" }]);
    }
  }, [htlinformation]);

  const addInputHandler = () => {
    let x = [...inputRow];
    x.push({ railway: "", dis: "" });
    setInputRow(x);
  };
  const deletInput = (idx: any) => {
    let x = [...inputRow];
    x.splice(idx, 1);
    setInputRow(x);
  };
  const inputTextHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: any
  ) => {
    const input = [...inputRow];
    const { name, value } = e.target;
    input[idx][name] = value;
    setInputRow(input);
  };
  useEffect(() => {
    const railways = inputRow.map((iteam) => {
      return iteam.railway;
    });
    const distances = inputRow.map((iteam) => {
      return iteam.dis;
    });
    setRailWayNames(railways);
    setrailWayNames(railways);
    setDistanceFrmRail(distances);
    setrailWaydistance(distances);
  }, [inputRow]);

  //  Location Details Post
  const locationSaveHandler = async () => {
    setLoader(true);
    try {
      let saveRes = await kernelApi.post(
        otherInfoUpdate,
        {
          hotel_id: current_property.hotel_id,
          airport_name: "",
          distance_from_air: "",
          rail_station_name: railWayNames,
          distance_from_rail: railWaydistance,
          bus_station_name: "",
          distance_from_bus: "",
          nearest_tourist_places: "",
          tour_info: " ",
        },

        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (saveRes.data.status === 1) {
        getLocationInformation();
        setLoader(false);
      } else {
        toast(`${saveRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
        getLocationInformation();
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  return (
    <>
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <>
          <div className="nearest__location__content__wrapper mt-4">
            {inputRow?.map((x, idx) => {
              return (
                <div
                  className="nearest__location__card__delete__wrapper mb-4"
                  key={idx}
                >
                  <div className="nearest__location__card">
                    <div className="nearest__place__input__wrapper me-3">
                      <InputTextField
                        label="Nearest RailWay Station"
                        value={x.railway}
                        name={"railway"}
                        handleChange={(e) => {
                          inputTextHandler(e, idx);
                        }}
                      />
                    </div>

                    <div className="distance__input__wrapper">
                      <InputTextField
                        label="Distance"
                        value={x.dis}
                        name="dis"
                        handleChange={(e) => {
                          inputTextHandler(e, idx);
                        }}
                      />
                    </div>
                  </div>
                  {idx !== 0 && (
                    <div
                      className="distance__delete__wrapper ms-3"
                      onClick={() => {
                        deletInput(idx);
                      }}
                    >
                      <div className="delete__icon">
                        <i className="bi bi-trash"></i>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <div className="nearest__location__btn">
              <div className="save__btn__wrapper">
                <Button
                  className="save__btn"
                  handleClick={() => {
                    locationSaveHandler();
                  }}
                  loader={loader}
                  isAccessible={access}
                >
                  Save
                </Button>
              </div>
              <div className="add__btn__wrapper">
                <Button
                  className="add__btn"
                  icon="bi bi-plus"
                  handleClick={() => {
                    addInputHandler();
                  }}
                  isAccessible={access}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RailwayWay;
