import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { UpdateSidebar } from "../../UtilityFunctions";
import InputTextField from "../../views/inputtextfield/InputTextField";
import Button from "../../views/buttons/Button";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

// import utilityFunction
import {
  validatePositiveInt,
  validateIfscCode,
  validateSwiftCode,
  validateGstNo,
  Logout,
} from "../../UtilityFunctions";

// import react-toaster
import { toast } from "react-toastify";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import kernelApi from "../../API/kernelApi";
import Switch from "@mui/material/Switch";
import endPoints from "../../API/endPoints";

const PropertyFinancialDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  UpdateSidebar("Property Setup", "Financial Details");

  // Getting auth token from the redux store
  const { auth_token, admin_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const [accountDetails, setAccountDetails] = useState({
    account: "",
    ifsc: "",
    swift: "",
    bankname: "",
    branch: "",
    beneficiary: "",
    address: "",
    tax: "",
  });

  // endPoints Imports
  const {
    getBankAccountDetails
  } = endPoints.PROPERTY_FINANCIAL_DETAILS;

  const [isNew, setIsNew] = useState(true);
  const [ifscError, setIfscError] = useState("");
  const [swiftError, setSwiftError] = useState("");
  const [taxError, setTaxError] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [access, setAccess] = useState(true);

  // Check if the user has access to this page.

  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "HOTEL";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    getBankDetails();
  }, [current_property]);

  // GET API called for bank details
  const getBankDetails = async () => {
    try {
      let response = await kernelApi.get(
        `${getBankAccountDetails}get/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        let result = response.data.bank_details;
        if (result.is_taxable === 1) {
          setCheckBox(true);
        }
        setAccountDetails({
          account: result.bank_account_no,
          ifsc: result.ifsc_code,
          swift: result.swift_code,
          bankname: result.bank_name,
          branch: result.bank_branch,
          beneficiary: result.beneficiary_name,
          address: result.bank_address,
          tax: result.tax_in,
        });

        setIsNew(false);
      } else if (response.data.status === 0) {
        setIsNew(true);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  const accountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validatePositiveInt(e.target.value) || e.target.value === "") {
      setAccountDetails({ ...accountDetails, account: e.target.value });
    }
  };

  const ifsccodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountDetails({ ...accountDetails, ifsc: e.target.value });
    if (!validateIfscCode(e.target.value) && e.target.value) {
      setIfscError("Enter a valid IFSC code e.g:SBIN0125620");
    } else {
      setIfscError("");
    }
  };

  const swiftcodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountDetails({ ...accountDetails, swift: e.target.value });
    if (!validateSwiftCode(e.target.value) && e.target.value) {
      setSwiftError("Enter a valid swift code eg.SBININBB455");
    } else {
      setSwiftError("");
    }
  };

  const taxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountDetails({ ...accountDetails, tax: e.target.value });

    if (!validateGstNo(e.target.value) && e.target.value) {
      setTaxError("Enter a valid GST no eg.10AABCU9603R1Z2");
    } else {
      setTaxError("");
    }
  };

  // POST api called for financial details
  const updateBankDetails = async () => {
    try {
      let response = await kernelApi.post(
        `${getBankAccountDetails}${isNew ? "add" : "update"}`,
        {
          hotel_id: current_property.hotel_id,
          user_id: admin_id,
          bank_account_no: accountDetails.account,
          ifsc_code: accountDetails.ifsc,
          swift_code: accountDetails.swift,
          bank_name: accountDetails.bankname,
          beneficiary_name: accountDetails.beneficiary,
          bank_branch: accountDetails.branch,
          bank_address: accountDetails.address,
          tax_in: checkBox ? "" : accountDetails.tax,
          is_taxable: checkBox,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const submitAccountDetails = () => {
    if (
      accountDetails.account &&
      accountDetails.ifsc &&
      accountDetails.swift &&
      accountDetails.bankname &&
      accountDetails.beneficiary &&
      accountDetails.branch &&
      accountDetails.address &&
      !taxError &&
      !swiftError &&
      !ifscError
      // && (accountDetails.tax || checkBox)
    ) {
      updateBankDetails();
      getBankDetails();
    } else {
      toast("Kindly fill up all the details!", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>
      <div className="property__setup__financial__details__wrapper">
        <div className="address-form-container edit-financialform-container m-3">
          <div className="div__inputs__wrapp mt-4">
            <div className="mb-4">Bank Details</div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="div__inputs__wrapp">
                <div className="div--email--input">
                  <InputTextField
                    label="Bank Account Number"
                    value={accountDetails?.account}
                    handleChange={(e) => {
                      accountHandler(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="div__inputs__wrapp">
                <div className="div__input__align__horizontal">
                  <div className="div--email--input me-1">
                    <InputTextField
                      label="IFSC Code"
                      value={accountDetails?.ifsc}
                      helperText={ifscError}
                      handleChange={(e) => {
                        ifsccodeHandler(e);
                      }}
                    />
                  </div>

                  <div className="div--email--input ms-3">
                    <InputTextField
                      label="Swift Code"
                      value={accountDetails?.swift}
                      helperText={swiftError}
                      handleChange={(e) => {
                        swiftcodeHandler(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="div__inputs__wrapp">
                <div className="div__input__align__horizontal">
                  <div className="div--email--input me-1">
                    <InputTextField
                      label="Bank Name"
                      value={accountDetails?.bankname}
                      handleChange={(e) => {
                        setAccountDetails({
                          ...accountDetails,
                          bankname: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="div--email--input ms-3">
                    <InputTextField
                      label="Bank Branch"
                      value={accountDetails?.branch}
                      handleChange={(e) => {
                        setAccountDetails({
                          ...accountDetails,
                          branch: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="div__inputs__wrapp">
                <div className="div--email--input">
                  <InputTextField
                    label="Beneficiary Name"
                    value={accountDetails?.beneficiary}
                    handleChange={(e) => {
                      setAccountDetails({
                        ...accountDetails,
                        beneficiary: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="div__inputs__wrapp">
            <div className="div--email--input">
              <InputTextField
                label="Bank Address"
                value={accountDetails?.address}
                handleChange={(e) => {
                  setAccountDetails({
                    ...accountDetails,
                    address: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="col-md-6 mt-3 d-flex">
            <span>Does your business appear under Tax Exemption?</span>
            <span className="custom__checkbox">
              <div className="form-check form-switch custom-switch check--box--width">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checkBox}
                  id="flexCheckDefault"
                  onClick={() => {
                    setCheckBox(!checkBox);
                    setTaxError("");
                    setAccountDetails({ ...accountDetails, tax: "" });
                  }}
                />
              </div>
            </span>
            {/* <input
              className="form-check-input custom__checkbox"
              type="checkbox"
              checked={checkBox}
              id="flexCheckDefault"
              onClick={() => {
                setCheckBox(!checkBox);
                setTaxError("");
                setAccountDetails({ ...accountDetails, tax: "" });
              }}
            ></input> */}
          </div>
        </div>
        {/*!checkBox && (
          <div className="address-form-container edit-financialform-container mx-3">
            <div className="row">
              <div className="col-md-6">
                <div className="div__inputs__wrapp">
                  <div className="mb-3">Tax Details</div>
                  <div className="div--email--input">
                    <InputTextField
                      label="GST Details"
                      value={accountDetails?.tax}
                      helperText={taxError}
                      handleChange={(e) => {
                        taxHandler(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        */}

        <div className="address-form-container edit-financialform-container m-3 mt-0">
          <div className="__btn__wrapper">
            <Button
              className="cancel__btn me-3"
              handleClick={() => {
                navigate("/property-setup");
              }}
              isAccessible={access}
            >
              Cancel
            </Button>
            <Button
              className="save__btn"
              handleClick={submitAccountDetails}
              isAccessible={access}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyFinancialDetails;
