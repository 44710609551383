import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ResetFloorSelection } from "../../redux/actions/AddFloorsAction";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import BackButton from "../../views/buttons/Button";
import NextButton from "../../views/buttons/NextButton";
import AmenityCard from "../../views/datacard/AmenityCard";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";

import { GetOrdinalSuffix } from "../../UtilityFunctions";

import { toast } from "react-toastify";
import gemsApi from "../../API/gemsApi";

const ServiceableFloors = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { current_property, property_data } = useSelector((state: RootState) => state.properties);
  const { number_of_floors } = useSelector((state: RootState) => state.add_floors);

  const [slidePage, setSlidePage] = useState(0);
  const [floors, setFloors] = useState<number[]>([]);

  const PageSize = 6;
  const NumOfPages = Math.ceil((number_of_floors + 1) / PageSize);

  const FloorSelected = (idx: number) => {
    let floor = [...floors];
    if (!floor.includes(idx)) {
      floor.push(idx);
    } else {
      let index = floor.indexOf(idx);
      floor.splice(index, 1);
    }
    setFloors(floor);
  };

  const LeftClick = () => {
    if (slidePage > 0) {
      setSlidePage(slidePage - 1);
    }
  };

  const RightClick = () => {
    if (slidePage < NumOfPages - 1) {
      setSlidePage(slidePage + 1);
    }
  };

  const BackButtonClick = () => {
    navigate("/add-floors/create-floors")
  };

  const SubmitServiceableFloors = () => {
    if (floors.length > 0) {
      CreateFloors();
    } else {
      toast("Atleast 1 floor should be selected", {
        hideProgressBar: true,
        type: "error"
      })
    }
  }

  // API call to create floors
  const CreateFloors = async () => {
    // create floor plan object as per API requirement
    let floor_plan: any[] = [];
    for (let i = 0; i < number_of_floors; i++) {
      floor_plan.push({
        floor_no: i,
        planned_for_room: floors.includes(i) ? "TRUE" : "FALSE"
      });
    }

    let payload = {
      user_id: admin_id,
      hotel_id: current_property.hotel_id,
      floors: number_of_floors,
      floor_plan: floor_plan
    }

    // save floors data API call
    try {
      let response = await gemsApi.post("/saveFloors", payload);
      if (response.data.status === 1) {
        dispatch(ResetFloorSelection())
        setTimeout(() => {
          navigate("/add-floors/success", { replace: true });
        }, 100);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <LeftSideNav>
          <>
            <div className="div__user__testimonials__wrapper">
              <div className="screen__progress__wrapper">
                <div className="progress__status__wrapper">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Create {property_data.data.length === 1 ? "First" : ""} Property</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Select Subscription Plan</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Create Room Type</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper">
                    <i className="bi bi-play-fill progress--icon--play"></i>
                  </div>
                  <div className="status__label">Create Floors</div>
                </div>
                <div className="progress__sub__list__wrapper">
                  <div className="progress__sub__listitems">Create Floors</div>
                  <div className="progress__sub__listitems progress--sub--item--text">
                    &nbsp;- Select Serviceable Floors
                  </div>
                </div>
                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper"></div>
                  <div className="status__label">Add Rooms</div>
                </div>
              </div>
            </div>

            <div className="progress-bar-wrapper">
              <div className="progress-bar-text">2/2</div>
              <div className="progress-bar-status">
                <ProgressBar percent={100} filledBackground="#FFFFFF" />
              </div>
            </div>
          </>
        </LeftSideNav>
        <RightSideContent>
          <>
            <CurrentPropertyLabel />
            <div className="div--head--label">Choose Serviceable Floors</div>
            <div className="div--helper--text">Click on the box</div>

            <div className="arrow-width">
              {number_of_floors + 1 > PageSize && (
                <div className="arrow-main-box">
                  <div className="arrow-wrapper">
                    <div className="arrow-left" onClick={LeftClick}>
                      <i className="gg-arrow-left"></i>
                    </div>
                    <div className="arrow-right" onClick={RightClick}>
                      <i className="gg-arrow-right"></i>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="choose-box-card my-4 d-flex">
              {Array.from(Array(number_of_floors).keys())
                .slice(slidePage * PageSize, (slidePage + 1) * PageSize)
                .map((value) => (
                  <React.Fragment key={value}>
                    <AmenityCard
                      icon="bi bi-box"
                      label={`${value === 0 ? "Ground" : GetOrdinalSuffix(value)} Floor`}
                      aminitesAccepted={() => FloorSelected(value)}
                      active={floors?.includes(value)}
                    />
                  </React.Fragment>
                ))}
            </div>
            <div className="__btn__wrapper mt-5">
              <BackButton className="back__btn" handleClick={BackButtonClick}>
                Back
              </BackButton>
              <NextButton label="Submit" className="mx-0" handleClick={SubmitServiceableFloors} />
            </div>
          </>
        </RightSideContent>
      </div>
    </div>
  );
};

export default ServiceableFloors;
