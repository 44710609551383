import moment from "moment";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../redux/store";

import beApi from "../../API/beApi";
import cmApi from "../../API/cmApi";
import Button from "../../views/buttons/Button";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

interface IConfirmSliderProps {
  ratesInfo: any[];
  ratePlans: any[];
  roomTypeId: string;
  loaderBE: boolean;
  baseAdult: number;
  setLoaderBE: React.Dispatch<React.SetStateAction<boolean>>;
  loaderCM: boolean;
  setLoaderCM: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  updatedCellIds: string[];
  refreshRates: () => void;
  allRoomTypes: { ids: string[]; labels: string[] };
}

const ConfirmUpdateRate: React.FC<IConfirmSliderProps> = ({
  ratesInfo,
  ratePlans,
  updatedCellIds,
  baseAdult,
  roomTypeId,
  allRoomTypes,
  loaderBE,
  loaderCM,
  setLoaderBE,
  setLoaderCM,
  refreshRates,
  onClose,
}) => {
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [allCellBlocks, setAllCellBlocks] = useState<any>({});

  const [errorsCM, setErrorsCM] = useState<any[]>([]);
  const [errorsBE, setErrorsBE] = useState<any>();

  const { updateSingleDate, RatesUpdateCM } = endPoints.RATE;

  useEffect(() => {
    assignCellBlocks();
  }, []);

  const assignCellBlocks = () => {
    const cellBlocks: any = {};
    updatedCellIds.forEach((cell: string) => {
      let unqId = cell.split("|")[0] + "|" + cell.split("|")[3];
      let otaIndex = cell.split("|")[0];
      let planIndex = cell.split("|")[1];
      let date = cell.split("|")[3];
      let occupancyType = cell.split("|")[2];
      let cell_value = document.getElementById(cell)?.innerText;
      if (cellBlocks[unqId] === undefined) {
        cellBlocks[unqId] = {
          date: date,
          otaIndex: otaIndex,
          rateplanIndex: {
            [planIndex]: [{ label: occupancyType, new_value: cell_value }],
          },
          cell_id: cell,
        };
      } else {
        if (cellBlocks[unqId].rateplanIndex[planIndex] === undefined) {
          cellBlocks[unqId].rateplanIndex[planIndex] = [
            { label: occupancyType, new_value: cell_value },
          ];
        } else {
          cellBlocks[unqId].rateplanIndex[planIndex].push({
            label: occupancyType,
            new_value: cell_value,
          });
        }
        // cellBlocks[unqId].new_value = cell_value;
      }
    });
    setAllCellBlocks(cellBlocks);
  };

  const getOldValueFromId = (
    OTA: any,
    RatePlan: string,
    OccuType: string,
    Date: string
  ) => {
    if (OTA.plans) {
      let RpDetails = OTA.plans.filter(
        (rp: any) => rp.rate_plan_id.toString() === RatePlan
      )[0];

      let DateRate = RpDetails.day_rates.filter(
        (d: any) => d.rate_date === Date
      )[0];

      if (OccuType === "Single") {
        return baseAdult === 1
          ? DateRate.bar_price
          : DateRate.single_occupancy_price
            ? DateRate.single_occupancy_price
            : 0;
      } else if (OccuType === "Double") {
        return baseAdult === 2
          ? DateRate.bar_price
          : DateRate.double_occupancy_price
            ? DateRate.double_occupancy_price
            : 0;
      } else if (OccuType === "Triple") {
        return baseAdult === 3
          ? DateRate.bar_price
          : DateRate.triple_occupancy_price
            ? DateRate.triple_occupancy_price
            : 0;
      } else if (OccuType === "Quadruple") {
        return baseAdult === 4
          ? DateRate.bar_price
          : DateRate.multiple_occupancy
            ? DateRate.multiple_occupancy
            : 0;
      } else if (OccuType === "Extra Adult") {
        return DateRate.extra_adult_price || 0;
      } else if (OccuType === "Extra Child") {
        return DateRate.extra_child_price || 0;
      } else if (OccuType === "LOS") {
        return DateRate.los || 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const refresh = () => {
    document.getElementById("refresh-button")?.click();
  };

  const updateRatesBE = async () => {
    setLoaderBE(true);

    let RateData: any[] = [];

    updatedCellIds.forEach((cell: string) => {
      let otaIndex = cell.split("|")[0];
      let planId = cell.split("|")[1];
      let date = cell.split("|")[3];
      let occupancyType = cell.split("|")[2];
      let OTA = ratesInfo[parseInt(otaIndex)];

      if (OTA.ota_id === -1) {
        let VALUE = parseInt(document.getElementById(cell)?.textContent || "0");

        let RatePlan = OTA?.plans.filter(
          (rp: any) => rp.rate_plan_id.toString() === planId
        )[0];

        let DateRate = RatePlan?.day_rates.filter(
          (d: any) => d.rate_date === date
        )[0];

        let oldRates = {
          bar_price: DateRate.bar_price || 0,
          // date: DateRate.date || 0,
          date: DateRate.rate_date,
          multiple_occupancy: DateRate.multiple_occupancy
            ? DateRate.multiple_occupancy.map(Number)
            : [],
          extra_adult_price: DateRate.extra_adult_price || 0,
          extra_child_price: DateRate.extra_child_price || 0,
          los: DateRate.los || 0,
        };

        if (
          !RateData.some((r: any) => {
            return r.rate_plan_id === parseInt(RatePlan.rate_plan_id);
          })
        ) {
          if (occupancyType === "Single") {
            oldRates.multiple_occupancy[0] = VALUE;
          } else if (occupancyType === "Double") {
            oldRates.multiple_occupancy[1] = VALUE;
          } else if (occupancyType === "Triple") {
            oldRates.multiple_occupancy[2] = VALUE;
          } else if (occupancyType === "Quadruple") {
            oldRates.multiple_occupancy[3] = VALUE;
          } else if (occupancyType === "Extra Adult") {
            oldRates.extra_adult_price = VALUE;
          } else if (occupancyType === "Extra Child") {
            oldRates.extra_child_price = VALUE;
          } else if (occupancyType === "LOS") {
            oldRates.los = VALUE;
          }

          // oldRates.bar_price = oldRates.multiple_occupancy[oldRates.multiple_occupancy.length - 1];
          // oldRates.bar_price = Math.max(...oldRates.multiple_occupancy.map(Number))
          oldRates.bar_price =
            oldRates.multiple_occupancy.length !== baseAdult
              ? oldRates.bar_price
              : oldRates.multiple_occupancy.pop();

          RateData.push({
            room_type_id: roomTypeId,
            room_type:
              allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
            rate_plan_id: parseInt(planId),
            plan_type: ratePlans.filter(
              (rp: any) => rp.rate_plan_id.toString() === planId.toString()
            )[0].plan_type,
            // rate_plan: ratePlans.filter((rp: any) => rp.rate_plan_id.toString() === planId.toString())[0].plan_name,
            rates: [oldRates],
          });
        } else {
          let OtaRates = RateData.filter(
            (data: any) => data.rate_plan_id === parseInt(RatePlan.rate_plan_id)
          )[0].rates;
          let RateItem = OtaRates.filter((rate: any) => rate.date === date);

          if (RateItem.length > 0) {
            if (occupancyType === "Single") {
              RateItem[0].multiple_occupancy[0] = VALUE;
            } else if (occupancyType === "Double") {
              RateItem[0].multiple_occupancy[1] = VALUE;
            } else if (occupancyType === "Triple") {
              RateItem[0].multiple_occupancy[2] = VALUE;
            } else if (occupancyType === "Quadruple") {
              RateItem[0].multiple_occupancy[3] = VALUE;
            } else if (occupancyType === "Extra Adult") {
              RateItem[0].extra_adult_price = VALUE;
            } else if (occupancyType === "Extra Child") {
              RateItem[0].extra_child_price = VALUE;
            } else if (occupancyType === "LOS") {
              RateItem[0].los = VALUE;
            }
            // RateItem[0].bar_price = Math.max(...RateItem[0].multiple_occupancy.map(Number))
            // RateItem[0].bar_price = RateItem[0].multiple_occupancy.pop() || 0;
            // RateItem[0].bar_price = RateItem[0].multiple_occupancy[RateItem[0].multiple_occupancy.length - 1];
            RateItem[0].bar_price =
              RateItem[0].multiple_occupancy.length !== baseAdult
                ? RateItem[0].bar_price || 0
                : RateItem[0].multiple_occupancy.pop();
          } else {
            if (occupancyType === "Single") {
              oldRates.multiple_occupancy[0] = VALUE;
            } else if (occupancyType === "Double") {
              oldRates.multiple_occupancy[1] = VALUE;
            } else if (occupancyType === "Triple") {
              oldRates.multiple_occupancy[2] = VALUE;
            } else if (occupancyType === "Quadruple") {
              oldRates.multiple_occupancy[3] = VALUE;
            } else if (occupancyType === "Extra Adult") {
              oldRates.extra_adult_price = VALUE;
            } else if (occupancyType === "Extra Child") {
              oldRates.extra_child_price = VALUE;
            } else if (occupancyType === "LOS") {
              oldRates.los = VALUE;
            }
            // oldRates.bar_price = Math.max(...oldRates.multiple_occupancy.map(Number))
            // oldRates.bar_price = oldRates.multiple_occupancy.pop() || 0;
            // oldRates.bar_price = oldRates.multiple_occupancy[oldRates.multiple_occupancy.length - 1];
            oldRates.bar_price =
              oldRates.multiple_occupancy.length !== baseAdult
                ? oldRates.bar_price || 0
                : oldRates.multiple_occupancy.pop();

            OtaRates.push(oldRates);
          }
        }
      }
    });

    let payload = {
      hotel_id: current_property.hotel_id,
      admin_id: admin_id,
      rates: RateData,
      ota_id: ["-1"],
    };

    if (payload.rates.length > 0) {
      try {
        const response = await beApi.post(`${updateSingleDate}`, payload, {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        });

        setErrorsBE(response.data);

        // if (response.data.status === 1 && !loaderCM) {
        //   refresh();
        // } else {
        //   // toast.error(response.data.response_msg);
        // }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    }
    setLoaderBE(false);
    if (!loaderCM) {
      refresh();
    }
  };

  const updateRatesCM = async () => {
    setLoaderCM(true);
    let RateData: any[] = [];

    updatedCellIds.forEach((cell: string) => {
      let otaIndex = cell.split("|")[0];
      let planId = cell.split("|")[1];
      let date = cell.split("|")[3];
      let occupancyType = cell.split("|")[2];
      let OTA = ratesInfo[parseInt(otaIndex)];

      if (OTA.ota_id !== "-1") {
        let VALUE = parseInt(document.getElementById(cell)?.textContent || "0");

        let RatePlan = OTA?.plans?.filter(
          (rp: any) => rp.rate_plan_id.toString() === planId
        )[0];
        let DateRate = RatePlan?.day_rates?.filter(
          (d: any) => d.rate_date === date
        )[0];

        let oldRates = {
          bar_price: DateRate.bar_price,
          date: DateRate.rate_date,
          rate_plan_id: RatePlan.rate_plan_id,
          plan_type: ratePlans?.filter(
            (rp: any) => rp.rate_plan_id === RatePlan.rate_plan_id
          )[0].plan_type,
          block_status: 0,
          multiple_occupancy:
            typeof DateRate.multiple_occupancy === "string"
              ? []
              : DateRate.multiple_occupancy.map(Number),
          extra_adult_price: DateRate.extra_adult_price,
          extra_child_price: DateRate.extra_child_price,
          los: DateRate.los,
        };

        if (
          !RateData.some((r: any) => {
            return r.ota_id === parseInt(OTA.ota_id);
          })
        ) {
          if (occupancyType === "Single") {
            oldRates.multiple_occupancy[0] = VALUE;
          } else if (occupancyType === "Double") {
            oldRates.multiple_occupancy[1] = VALUE;
          } else if (occupancyType === "Triple") {
            oldRates.multiple_occupancy[2] = VALUE;
          } else if (occupancyType === "Quadruple") {
            oldRates.multiple_occupancy[3] = VALUE;
          } else if (occupancyType === "Extra Adult") {
            oldRates.extra_adult_price = VALUE;
          } else if (occupancyType === "Extra Child") {
            oldRates.extra_child_price = VALUE;
          } else if (occupancyType === "LOS") {
            oldRates.los = VALUE;
          }

          // oldRates.bar_price = oldRates.multiple_occupancy.pop() || 0;
          // oldRates.bar_price =  oldRates.multiple_occupancy[oldRates.multiple_occupancy.length - 1];
          oldRates.bar_price =
            oldRates.multiple_occupancy.length !== baseAdult
              ? oldRates.bar_price || 0
              : oldRates.multiple_occupancy.pop();

          RateData.push({
            ota_id: parseInt(OTA.ota_id),
            channel_name: OTA.name,
            rates: [oldRates],
          });
        } else {
          let OtaRates = RateData.filter(
            (data: any) => data.ota_id === parseInt(OTA.ota_id)
          )[0].rates;
          let RateItem = OtaRates.filter(
            (data: any) =>
              data.date === date && data.rate_plan_id.toString() === planId
          );

          if (RateItem.length > 0) {
            if (occupancyType === "Single") {
              RateItem[0].multiple_occupancy[0] = VALUE;
            } else if (occupancyType === "Double") {
              RateItem[0].multiple_occupancy[1] = VALUE;
            } else if (occupancyType === "Triple") {
              RateItem[0].multiple_occupancy[2] = VALUE;
            } else if (occupancyType === "Quadrple") {
              RateItem[0].multiple_occupancy[3] = VALUE;
            } else if (occupancyType === "Extra Adult") {
              RateItem[0].extra_adult_price = VALUE;
            } else if (occupancyType === "Extra Child") {
              RateItem[0].extra_child_price = VALUE;
            } else if (occupancyType === "LOS") {
              RateItem[0].los = VALUE;
            }
            // RateItem[0].bar_price = Math.max(...RateItem[0].multiple_occupancy.map(Number))
            // RateItem[0].bar_price = RateItem[0].multiple_occupancy.pop() || 0;
            RateItem[0].bar_price =
              RateItem[0].multiple_occupancy.length !== baseAdult
                ? RateItem[0].bar_price || 0
                : RateItem[0].multiple_occupancy.pop();
          } else {
            if (occupancyType === "Single") {
              oldRates.multiple_occupancy[0] = VALUE;
            } else if (occupancyType === "Double") {
              oldRates.multiple_occupancy[1] = VALUE;
            } else if (occupancyType === "Triple") {
              oldRates.multiple_occupancy[2] = VALUE;
            } else if (occupancyType === "Quadruple") {
              oldRates.multiple_occupancy[3] = VALUE;
            } else if (occupancyType === "Extra Adult") {
              oldRates.extra_adult_price = VALUE;
            } else if (occupancyType === "Extra Child") {
              oldRates.extra_child_price = VALUE;
            } else if (occupancyType === "LOS") {
              oldRates.los = VALUE;
            }
            // oldRates.bar_price = Math.max(...oldRates.multiple_occupancy.map(Number))
            // oldRates.bar_price = oldRates.multiple_occupancy.pop() || 0;
            // oldRates.bar_price = oldRates.multiple_occupancy[oldRates.multiple_occupancy.length - 1];
            oldRates.bar_price =
              oldRates.multiple_occupancy.length !== baseAdult
                ? oldRates.bar_price || 0
                : oldRates.multiple_occupancy.pop();

            OtaRates.push(oldRates);
          }
        }
      }
    });

    let payload = {
      hotel_id: current_property.hotel_id,
      admin_id: admin_id,
      room_type_id: roomTypeId,
      room_type: allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
      rates: RateData,
    };

    if (payload.rates.length > 0) {
      try {
        const response = await cmApi.post(`${RatesUpdateCM}`, payload, {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        });

        setErrorsCM(response.data);
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    }
    setLoaderCM(false);
    if (!loaderBE) {
      refresh();
    }
  };

  const submitUpdatedRates = () => {
    try {
      if (updatedCellIds.length > 0) {
        if (updatedCellIds.some((cell: string) => cell.split("|")[0] === "0")) {
          updateRatesBE();
        }
        if (updatedCellIds.some((cell: string) => cell.split("|")[0] !== "0")) {
          updateRatesCM();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">
          Update Confirmation{" "}
          {roomTypeId
            ? "(" +
            allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)] +
            ")"
            : ""}
        </div>
      </div>
      <div className="height-64px"></div>

      {ratesInfo.length > 0 && (
        <div className="rates__update__wrapper">
          {Object.keys(allCellBlocks).map((id: any, index: number) => {
            const OTA = ratesInfo[allCellBlocks[id].otaIndex];
            const DATE = allCellBlocks[id].date;
            if (OTA.ota_id === "-1") {
              const Errors = errorsBE ? true : false;
              const Status = errorsBE?.status;
              return (
                <React.Fragment key={index}>
                  {Errors && Status === 0 && (
                    <div className="ota__error__wrapper__rates">
                      {errorsBE.response_msg}
                    </div>
                  )}
                  <div
                    className={`confirm__update__wrapper ${Errors && Status === 0 ? "error--border" : ""
                      } ${Errors && Status === 1 ? "success--border" : ""} mb-3`}
                  >
                    <div className="date__logo__container__rates">
                      <div className="image__container">
                        <img src={OTA.logo} alt="" />
                      </div>
                      {Errors && Status === 1 && (
                        <div className="success__checkmark">
                          <i className="bi bi-check"></i>
                        </div>
                      )}
                      <div className="updated__date">
                        <b> {moment(DATE).format("DD MMM")}</b>
                        <div className="text-center">{moment(DATE).year()}</div>
                      </div>
                    </div>
                    {Object.keys(allCellBlocks[id].rateplanIndex).map(
                      (rpIndex: any, idx: number) => {
                        const RATEPLAN = ratePlans.filter(
                          (rp: any) =>
                            rp.rate_plan_id.toString() === rpIndex.toString()
                        )[0];
                        return (
                          <div
                            className="card__mealplan__update__wrapper mb-2"
                            key={idx}
                          >
                            <div className="mealplan__label">
                              {RATEPLAN.plan_type}
                            </div>
                            {allCellBlocks[id].rateplanIndex[rpIndex].map(
                              (rp: any, inx: number) => {
                                // const cellElement = document.getElementById(allCellBlocks[id].otaIndex + "|" + rpIndex + "|" + occupancy + "|" + DATE)
                                // const NEW_VALUE = cellElement?.textContent;
                                // const NEW_VALUE = allCellBlocks[id].new_value;
                                const NEW_VALUE = rp.new_value;
                                const OLD_VALUE = getOldValueFromId(
                                  OTA,
                                  rpIndex,
                                  rp.label,
                                  DATE
                                );
                                return (
                                  <div
                                    className="price__update__wrapper mb-2"
                                    key={inx}
                                  >
                                    <div className="occupancy__label">
                                      {rp.label}
                                    </div>
                                    <div className="rates old__price">
                                      {OLD_VALUE}
                                    </div>
                                    <div className="rates">{NEW_VALUE}</div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </React.Fragment>
              );
            } else {
              const Errors = errorsCM.length > 0 ? true : false;
              const ErrorOTA = errorsCM.filter(
                (err: any) => err.ota_name === OTA.name
              )[0];
              return (
                <React.Fragment key={index}>
                  {Errors && ErrorOTA?.status === 0 && (
                    <div className="ota__error__wrapper__rates">
                      {ErrorOTA.response_msg}
                    </div>
                  )}
                  <div
                    className={`confirm__update__wrapper ${Errors && ErrorOTA?.status === 0 ? "error--border" : ""
                      } ${Errors && ErrorOTA?.status === 1 ? "success--border" : ""
                      } mb-3`}
                  >
                    <div className="date__logo__container">
                      <div className="image__container">
                        <img src={OTA.logo} alt="" />
                      </div>
                      {Errors && ErrorOTA?.status === 1 && (
                        <div className="success__checkmark">
                          <i className="bi bi-check"></i>
                        </div>
                      )}
                      <div className="updated__date">
                        <b> {moment(DATE).format("DD MMM")}</b>
                        <div className="text-center">{moment(DATE).year()}</div>
                      </div>
                    </div>
                    {Object.keys(allCellBlocks[id].rateplanIndex).map(
                      (rpIndex: any, idx: number) => {
                        const RATEPLAN = ratePlans.filter(
                          (rp: any) =>
                            rp.rate_plan_id.toString() === rpIndex.toString()
                        )[0];
                        return (
                          <div
                            className="card__mealplan__update__wrapper mb-2"
                            key={idx}
                          >
                            <div className="mealplan__label">
                              {RATEPLAN.plan_type}
                            </div>
                            {allCellBlocks[id].rateplanIndex[rpIndex].map(
                              (rp: any, inx: number) => {
                                // const cellElement = document.getElementById(allCellBlocks[id].otaIndex + "|" + rpIndex + "|" + occupancy + "|" + DATE)
                                // const NEW_VALUE = cellElement?.textContent;
                                // const NEW_VALUE = allCellBlocks[id].new_value;
                                const NEW_VALUE = rp.new_value;
                                const OLD_VALUE = getOldValueFromId(
                                  OTA,
                                  rpIndex,
                                  rp.label,
                                  DATE
                                );
                                return (
                                  <div
                                    className="price__update__wrapper mb-2"
                                    key={inx}
                                  >
                                    <div className="occupancy__label">
                                      {rp.label}
                                    </div>
                                    <div className="rates old__price">
                                      {OLD_VALUE}
                                    </div>
                                    <div className="rates">{NEW_VALUE}</div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      )}

      <div id="refresh-button" className="d-none" onClick={refreshRates}></div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-3">
          <Button className="cancel__btn me-3" handleClick={onClose}>
            Cancel
          </Button>
          {!loaderBE && !loaderCM ? (
            <Button handleClick={submitUpdatedRates}>Save</Button>
          ) : (
            <>
              <Button>
                Save{" "}
                <span
                  className="spinner-border spinner-border-sm pd-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              </Button>
              <div className="full--page--overlay"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ConfirmUpdateRate);
