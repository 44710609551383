import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
// import user image
import UserImage from "../../assets/img/lisa.png";
import LeftSideNav from "../../components/onboarding/LeftSidenav";
import InputTextField from "../../views/inputtextfield/InputTextField";
import InputPasswordField from "../../views/inputtextfield/InputPasswordField";
import StandardButton from "../../views/buttons/Button";
import { useNavigate } from 'react-router';
import { validateEmail, validateMobile } from '../../UtilityFunctions';
import kernelApi from "../../API/kernelApi";
import { toast } from 'react-toastify';
import { browserName } from "react-device-detect";
import { LoginSuccess, SaveSignupInfo, SaveClientSectionData } from '../../redux/actions/LoginAction';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import endPoints from '../../API/endPoints';
import { RootState } from '../../redux/store';

const SignUp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    window.handleCredentialResponse = handleCredentialResponse;

    const { client_section }: any = useSelector((state: RootState) => state.auth);
    const { chargebeeEnvironment, userFullName } = useSelector((state: RootState) => state.userAcess);

    const {
        getExtranetAdminAuth
    } = endPoints.LOGIN_AUTH;

    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState(userFullName);
    const [companyName, setCompanyName] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [password, setPassword] = useState("");
    const [subDomainName, setSubDomainName] = useState("");
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [acceptTerms, setAcceptterms] = useState(false);

    const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(e.target.value);
        if (
            validateEmail(e.target.value) ||
            validateMobile(e.target.value) ||
            !e.target.value
        ) {
            setEmailHelperText("");
        } else {
            setEmailHelperText("Invalid email or mobile");
        }
    };

    const PasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if (e.target.value.length < 4 && e.target.value) {
            setPasswordHelperText("Invalid password");
        } else {
            setPasswordHelperText(" ");
        }
    };

    function handleCredentialResponse(response: any) {
        const responsePayload: any = jwt_decode(response.credential);
        LoginWithGoogle(responsePayload);
    }

    const LoginWithGoogle = async (payload: any) => {
        try {
            const response = await kernelApi.post(`${getExtranetAdminAuth}`, {
                email: payload.email,
                name: payload.name,
                creation_mode: "Google",
                browser: browserName,
            });

            if (response.data.status === 1) {
                dispatch(LoginSuccess(response.data));
                dispatch(SaveSignupInfo(response.data.subscription_customer_id))
            } else {
                navigate("/signup-form", {
                    state: { email: payload.email, name: payload.name },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const SignUpSubmit = async () => {
        if (userEmail && userName && companyName && subDomainName && password && acceptTerms) {
            try {
                let payload = {
                    company_name: companyName,
                    subdomain_name: subDomainName + ".bookingjini.com",
                    user_name: userEmail,
                    password: password,
                    creation_mode: "WEBSITE",
                    name: userName
                }

                const response = await kernelApi.post(`/company-create-from-extranet`, payload);
                if (response.data.status === 1) {
                    dispatch(SaveSignupInfo(response.data.subscription_customer_id))
                    LoginWithExtranet();
                } else {
                    toast(response.data.message, {
                        hideProgressBar: true,
                        type: "error"
                    });
                }

            } catch (error) {
                console.log(error);
            }
        } else {
            toast("Please fill all the fields", {
                hideProgressBar: true,
                type: "error"
            })
        }
    }

    const LoginWithExtranet = async () => {
        try {
            const response = await kernelApi.post(`${getExtranetAdminAuth}`, {
                email: userEmail,
                password: password,
                creation_mode: "WEBSITE",
                browser: browserName
            });

            if (response.data.status === 1) {
                setTimeout(() => {
                    dispatch(LoginSuccess(response.data))
                    navigate("/select-property");
                }, 2000);
            } else {
                toast(response.data.message, {
                    hideProgressBar: true,
                    type: "error"
                });
            }

        } catch (error) {
            console.log(error);
        }
    }

    const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            SignUpSubmit();
        }
    };


    useEffect(() => {
        let google_signup_script = document.createElement("script");
        google_signup_script.setAttribute("src", "https://accounts.google.com/gsi/client");
        google_signup_script.async = true;
        google_signup_script.defer = true;
        document.head.appendChild(google_signup_script);
        getClientSectionData();
    }, [])

    const getClientSectionData = async () => {
        try {
            let response = await kernelApi.get((`testimonials/${chargebeeEnvironment}`));
            if (response.data.status === 1) {
                dispatch(SaveClientSectionData({ imageUrl: response?.data?.testimonial?.image_url, userTestimonial: response?.data?.testimonial?.testimonial, userName: response?.data?.testimonial?.name, userDesignation: response?.data?.testimonial?.designation }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="div-page-wrapper">
                <div className="div__wrapper">
                    <LeftSideNav>
                        <div className="div__user__testimonials__wrapper">
                            <div
                                className="user__testimonial_image"
                                style={{ backgroundImage: `url(${client_section?.imageUrl})` }}
                            >
                                <div className="user__testimonial__message__wrapper">
                                    <div className="testimonial__icon">
                                        <i
                                            className="bi bi-chat-square-quote"
                                            style={{ fontSize: 18 }}
                                        ></i>
                                    </div>
                                    <div className="user__testimonial__text">
                                        "{client_section?.userTestimonial}”
                                    </div>
                                    <div className="user__name">{client_section?.userName}</div>
                                    <div className="user__designation">{client_section?.userDesignation}</div>
                                </div>
                            </div>
                        </div>
                    </LeftSideNav>

                    <div className="div__input__wrapper__right div__right__side__box">
                        <div className="div__inputs max--width--380px">
                            <div className="div--head--label">Signup</div>
                            <Link to="/login">
                                <div className="div--helper--text mb-3">
                                    Already have an account yet? <span className="dev--helper--signin-sign-up ps-2"> Login</span>
                                </div>
                            </Link>

                            {/* Signin with google section */}
                            <div>
                                <div id="g_id_onload" data-client_id="1098328552594-m11e5mhckb9d28fp6vddifeeb9rv24li.apps.googleusercontent.com" data-callback="handleCredentialResponse"></div>
                                {/* <div id="g_id_onload" data-client_id="1098328552594-m11e5mhckb9d28fp6vddifeeb9rv24li.apps.googleusercontent.com" data-login_uri="http://localhost:3000/select-property" data-auto_prompt="false"></div> */}
                                <div className="g_id_signin" style={{ maxWidth: "380px" }} data-text="signup_with" data-width="380" data-type="standard" data-size="large" data-theme="filled_blue" data-shape="rectangular" data-logo_alignment="left"></div>
                            </div>

                            <div className="or__wrapper">
                                <div className="hr--line"></div>
                                <div className="or--text">Or</div>
                            </div>

                            <div className="div__inputs__wrapp">
                                <div className="div--email--input">
                                    <InputTextField
                                        value={userName}
                                        label="Full Name"
                                        onKeyPress={onKeyEnter}
                                        handleChange={(e) => { setUserName(e.target.value) }}
                                    />
                                </div>

                                <div className="div--email--input">
                                    <InputTextField
                                        value={userEmail}
                                        helperText={emailHelperText}
                                        label="Email Address"
                                        handleChange={EmailOnChange}
                                        onKeyPress={onKeyEnter}
                                    />
                                </div>
                                <div className="div--email--input">
                                    <InputTextField
                                        value={companyName}
                                        label="Company/Legal Name"
                                        onKeyPress={onKeyEnter}
                                        handleChange={(e) => { setCompanyName(e.target.value) }}
                                    />
                                </div>

                                <div className="div--email--input">
                                    <div className="signup__subdomain__wrapper">
                                        <div className="subdomain__input__wrapper">
                                            <InputTextField
                                                value={subDomainName}
                                                label="Subdomain"
                                                onKeyPress={onKeyEnter}
                                                handleChange={(e) => { setSubDomainName(e.target.value) }}
                                            />
                                        </div>
                                        <div className="signup__subdomain__label"><span>.bookingjini.com</span></div>
                                    </div>
                                </div>

                                <div className="div--password--input">
                                    <InputPasswordField
                                        value={password}
                                        helperText={passwordHelperText}
                                        onEnterKey={onKeyEnter}
                                        handleFocus={(e) => {
                                            if (!e.target.value) setPasswordHelperText("");
                                        }}
                                        handleChange={PasswordOnChange}
                                    />
                                </div>

                                <div className="terms__policy__wrapper mb-4">
                                    <div className="terms__policy__checkbox me-2" onClick={() => setAcceptterms(!acceptTerms)}>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" checked={acceptTerms} />
                                        </div>
                                    </div>
                                    <div className="terms__condition__label">
                                        <text onClick={() => setAcceptterms(!acceptTerms)}> I accept the </text>
                                        <span>
                                            <a className="dev--helper--signin-sign-up" href="https://bookingjini.com/terms-conditions" target="_blank"> Terms and Conditions </a>
                                        </span>
                                    </div>
                                </div>

                                <div className="__btn__wrapper text-end">
                                    <StandardButton className="login--btn" handleClick={() => SignUpSubmit()}>Signup</StandardButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp;