import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import endPoints from "../../../../API/endPoints";
import kernelApi from "../../../../API/kernelApi";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import { RootState } from "../../../../redux/store";
import {
  CATCH_MESSAGE,
  Logout,
  UpdateSidebar,
} from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
import Loader from "../../../../views/loader/Loader";
import AirportWay from "./AirportWay";
import BusWay from "./BusWay";
import RailwayWay from "./RailwayWay";

const NearestLocation = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const travelWays = ["Airport", "Railway", "Bus"];
  UpdateSidebar("Manage Channels", " Booking Engine");
  const navigate = useNavigate();

  //   States
  const [loader, setLoader] = useState<boolean>(false);
  const [htlinformation, setHtlInformation] = useState<any>({});
  const [airPortNames, setAirPortNames] = useState<any[]>([]);
  const [distanceFrmAir, setDistanceFrmAir] = useState<any[]>([]);
  const [railwayNames, setRailWayNames] = useState<any[]>([]);
  const [distanceFrmRail, setDistanceFrmRail] = useState<any[]>([]);
  const [busStopNames, setbusStopNames] = useState<any[]>([]);
  const [distanceFrmBus, setDistanceFrmBus] = useState<any[]>([]);

  const [activeMenuTab, setActiveMenuTab] = useState<any>("Airport");

  //Endpoints Import
  const { getHotelOtherInfoApi } = endPoints.MANAGECHANNEL
  // --------------------------------

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  // const locationSaveHandler = async () => {
  //   setLoader(true);
  //   try {
  //     let saveRes = await kernelApi.post("/hotel_other_information/update", {
  //       hotel_id: current_property.hotel_id,
  //       airport_name: airPortNames,
  //       distance_from_air: distanceFrmAir,
  //       rail_station_name:railwayNames,
  //       distance_from_rail: distanceFrmRail,
  //       bus_station_name: busStopNames,
  //       distance_from_bus: distanceFrmBus,
  //       nearest_tourist_places: "",
  //       tour_info: " ",
  //     },
  //     {
  //       headers: {
  //         Authorization: "Bearer " + auth_token,
  //       },
  //     }

  //     );
  //     if (saveRes.data.status === 1) {
  //       toast.success(`${saveRes.data.message} `);
  //       getLocationInformation();
  //       setLoader(false);
  //     } else {
  //       toast.error(`${saveRes.data.message} `);
  //     }
  //   } catch (error) {
  //     toast.error("Something Went Wrong ");
  //     setLoader(false);
  //   }
  // };

  const getLocationInformation = async () => {
    setLoader(true);
    try {
      let locationRes = await kernelApi.get(
        `${getHotelOtherInfoApi}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (locationRes.data.status === 1) {
        setHtlInformation(locationRes.data.otherInfo);
        setLoader(false);
      }
      // else {
      //   toast(`${locationRes.data.message} `, {
      //     hideProgressBar: true,
      //     type: "error"
      //   });
      //   setLoader(false);
      // }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  useEffect(() => {
    getLocationInformation();
  }, [current_property]);

  return (
    <>
      <div className="property__breadcrumbs nearest__location__header mb-4">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Location" },
          ]}
        />
        <div className="location__action__btn__wrappers">
          <div className="location__diffrent__tabs me-3">
            <ul className="nav nav-tabs" id="duesTab" role="tablist">
              {travelWays.map((tab) => {
                return (
                  <li
                    className="nav-item"
                    role="presentation"
                    key={tab}
                    onClick={() => switchMenuTab(tab)}
                  >
                    <a
                      className={`nav-link ${tab === activeMenuTab ? "active" : ""
                        }`}
                      id={`${tab}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${tab}`}
                      aria-controls={`#${tab}`}
                      role="tab"
                      aria-selected="true"
                    >
                      {tab}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="location__back__btn">
            <Button
              className="back__btn"
              icon="bi bi-arrow-left"
              handleClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        </div>
      </div>

      <div className="hr--line"></div>

      <>
        {loader ? (
          <div className="loader-setup">
            <Loader />
          </div>
        ) : activeMenuTab === "Airport" ? (
          // Section For Airport
          <AirportWay
            setAirPortNames={setAirPortNames}
            setDistanceFrmAir={setDistanceFrmAir}
            htlinformation={htlinformation}
            getLocationInformation={getLocationInformation}
          />
        ) : activeMenuTab === "Railway" ? (
          // Section For Railway
          <RailwayWay
            setRailWayNames={setRailWayNames}
            setDistanceFrmRail={setDistanceFrmRail}
            htlinformation={htlinformation}
            getLocationInformation={getLocationInformation}
          />
        ) : (
          // Section For Bus
          <BusWay
            setbusStopNames={setbusStopNames}
            setDistanceFrmBus={setDistanceFrmBus}
            htlinformation={htlinformation}
            getLocationInformation={getLocationInformation}
          />
        )}
      </>

      {/* <div className="nearest__location__btn">
          <div className="save__btn__wrapper" style={{width:'9%'}}>
            <Button
              className="save__btn"
              handleClick={() => {
                locationSaveHandler();
              }}
              loader={loader}
            >
              Save
            </Button>
          </div>
          <div className="add__btn__wrapper">
            <Button
              className="add__btn"
              icon="bi bi-plus"
              handleClick={() => {
                addInputHandler();
              }}
            >
              Add
            </Button>
          </div>
        </div> */}
    </>
  );
};

export default NearestLocation;
