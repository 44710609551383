import { current } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import endPoints from "../../../../API/endPoints";
import kernelApi from "../../../../API/kernelApi";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import { RootState } from "../../../../redux/store";
import {
  CATCH_MESSAGE,
  Logout,
  validatePositiveInt,
} from "../../../../UtilityFunctions";
import SlidingPane from "react-sliding-pane";
import Button from "../../../../views/buttons/Button";
import Card from "../../../../views/cardwrapper/Card";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
import Loader from "../../../../views/loader/Loader";
import AddNewAddonCharges from "./AddNewAddonCharges";
import EditAddonCharges from "./EditAddonCharges";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../../redux/actions/PromptAction";

const AdditionalCharges = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAddonCharges, setActiveCharge, deleteCharges } =
    endPoints.MANAGECHANNEL;
  const [loader, setLoader] = useState<boolean>(false);
  const [addonData, setAddonData] = useState<any[]>([]);
  const [activateOptions, setActiveOptions] = useState<any>();
  const [newAddOnSlider, setNewAddOnSlider] = useState<boolean>(false);
  const [editAddOnSlider, setEditAddOnSlider] = useState<boolean>(false);
  const [editId, setEditId] = useState<any>();
  const ref = useRef<HTMLDivElement>(null);

  // Code to Close that active dropdown option on click of outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event?.target)) {
        setActiveOptions("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setActiveOptions]);

  // Function to Show Options of Three Dot
  const openActiveOptions = (index: any) => {
    if (activateOptions === index) {
      setActiveOptions("");
    } else {
      setActiveOptions(index);
    }
  };

  useEffect(() => {
    getAdditionalCharges();
  }, [current_property.hotel_id]);

  // Api To Get Values of Additional Charges
  const getAdditionalCharges = async () => {
    setLoader(true);
    try {
      let response = await kernelApi.get(
        `${getAddonCharges}/${current_property.hotel_id}`
      );

      if (response.data.status === 1) {
        setAddonData(response.data.Data);
        setLoader(false);
      } else {
        setAddonData([]);
        setLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Api To Activate Addon Charge
  const activateCharge = async (id: any) => {
    try {
      let activeRes = await kernelApi.get(
        `/${setActiveCharge}/${id}/${current_property.hotel_id}`
      );

      if (activeRes.data.status === 1) {
        toast.success(activeRes.data.message);
        getAdditionalCharges();
      } else {
        toast.error(activeRes.data.message);
        getAdditionalCharges();
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Api To Delete Addon Charges
  const deleteAddOnHandler = (id: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            let deleteRes = await kernelApi.get(
              `${deleteCharges}/${id}/${current_property.hotel_id}}`,
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );

            if (deleteRes.data.status === 1) {
              toast.success(`${deleteRes.data.message}`);
              getAdditionalCharges();
            } else {
              toast.error(`${deleteRes.data.message}`);
              getAdditionalCharges();
            }
          } catch (error: any) {
            setLoader(false);
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
              Logout();
            }
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };
  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Additional Charges" },
          ]}
        />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <Button
            icon="bi bi-plus-lg"
            handleClick={() => {
              setNewAddOnSlider(true);
            }}
          >
            Add
          </Button>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {addonData.length > 0 ? (
        <div className="list-view-table-wrapper_addoncharges mb-4">
          <table className="table table-borderless_addoncharges">
            <thead>
              <tr className="listview__table__heading">
                <th>Sl.No</th>
                <th className="listview__table__th">Name</th>
                <th className="listview__table__th">Percentage</th>
                <th className="listview__table__th">Tax Percentage</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={8}>
                    <div>
                      <Loader className="d-flex justify-content-center align-items-center" />
                    </div>
                  </td>
                </tr>
              ) : (
                addonData.map((item: any, idx: any) => (
                  <tr className="listview__table__data" key={item?.id}>
                    <td>
                      <div className="serial-number">{idx + 1}</div>
                    </td>
                    <td className="listview__table__data__td">
                      <div className="customer-details plan-type">
                        {item?.add_on_charges_name}
                      </div>
                    </td>
                    <td className="listview__table__data__td">
                      <div className="customer-details">
                        {item?.add_on_charges_percentage} %
                      </div>
                    </td>
                    <td className="listview__table__data__td">
                      <div className="customer-details">
                        {item?.add_on_tax_percentage} %
                      </div>
                    </td>
                    <td>
                      {item?.is_active === 1 ? (
                        <div className="active-status-div">Active</div>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className="active-option-td">
                      <div className="three-dot-option">
                        <div onClick={() => openActiveOptions(idx)}>
                          <i className="bi bi-three-dots-vertical"></i>
                        </div>
                        {activateOptions === idx && (
                          <div className="activate-options-container" ref={ref}>
                            <div
                              className={` ${
                                item?.is_active === 1
                                  ? "disabled"
                                  : "activate-options"
                              }`}
                              onClick={() => {
                                if (item.is_active !== 1) {
                                  activateCharge(item?.id);
                                }
                              }}
                            >
                              Activate
                            </div>
                            <div
                              className="activate-options"
                              onClick={() => {
                                setEditAddOnSlider(true);
                                setEditId(item?.id);
                              }}
                            >
                              Edit
                            </div>
                            <div
                              className="activate-options"
                              onClick={() => deleteAddOnHandler(item?.id)}
                            >
                              Delete
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* For Pagination */}

          {/* <div className="list--view--pagination">
    <div className="pagination__left">
      <div className="pagination__dropdown">
        <InputSelectField
          label="Select"
          value={selectPageSize}
          options={pageSize}
          handleChange={(e) => {
            setSelectPageSize(e.target.value);
            setNoOfPages(Math.ceil(couponData.length / e.target.value));
            setCurrentPageNo(1);
          }}
        />
      </div>
      <ul>
        {Array(noOfPages)
          .fill(0)
          .map((p, i) => {
            return (
              <li
                key={i}
                onClick={() => setCurrentPageNo(i + 1)}
                className={`${
                  i + 1 === currentPageNo
                    ? "active__page"
                    : "showing__page__number"
                }`}>
                {i + 1}
              </li>
            );
          })}
      </ul>
    </div>
    <div className="pagination__right">
      <div className="arrow__container me-3" onClick={leftArrowClick}>
        <i className="bi bi-chevron-left font--weight"></i>
      </div>
      <div className="arrow__container" onClick={rightArrowClick}>
        <i className="bi bi-chevron-right font--weight"></i>
      </div>
    </div>
  </div> */}
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>You don’t have any Additional Charges</div>
        </div>
      )}

      {/* Sliding Pane for Add New Add On Charges */}
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={newAddOnSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setNewAddOnSlider(false);
        }}
      >
        <AddNewAddonCharges
          onClose={() => {
            setNewAddOnSlider(false);
          }}
          getAdditionalCharges={getAdditionalCharges}
        />
      </SlidingPane>
      {/* Sliding Pane for Edit Add On Charges */}
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={editAddOnSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setEditAddOnSlider(false);
        }}
      >
        <EditAddonCharges
          onClose={() => {
            setEditAddOnSlider(false);
          }}
          getAdditionalCharges={getAdditionalCharges}
          editId={editId}
        />
      </SlidingPane>
    </>
  );
};

export default AdditionalCharges;
