import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import InventoryBtn from "../../views/buttons/Button";
import Button from "../../views/buttons/Button";
import moment from "moment";

import cmApi from "../../API/cmApi";

import Bookingjini from "../../assets/svg/bookingjini.svg";
// import Expedia from "../../assets/svg/expedia.svg";

import { CATCH_MESSAGE, validatePositiveInt } from "../../UtilityFunctions";

import { toast } from "react-toastify";

// import select feild
import InputSelectField from "../../views/inputtextfield/InputSelectField";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";

//import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import endPoints from "../../API/endPoints";

interface ISyncInventorySliderProps {
  onClose: () => void;
  selectRoomType: string;
  selectRoomType_id: string;
  allChannel: any[];
  getInventory: () => void;
  sync_loader: any;
  setSync_loader: (initialState: any) => void;
  api_data: any;
}

const SyncInventory: React.FC<ISyncInventorySliderProps> = ({
  onClose,
  selectRoomType,
  selectRoomType_id,
  allChannel,
  getInventory,
  sync_loader,
  setSync_loader,
  api_data,
}) => {
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(moment());
  const [selectedOta, setSelectedOta] = useState<any>([]);
  const [syncDuration, setSyncDuration] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>([]);

  const [loader, setLoader] = useState<any>(false);

  const [allOtas, setAllOtas] = useState<any>({
    labels: [],
    ids: [],
  });
  const [selectedSourceChannel, setSelectedSourceChannel] = useState<any>("");

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  //Endpoints Import
  const { cmSyncInv } = endPoints.INVENTORY
  // --------------------------------

  const onDateChange = (date: any) => {
    setFromDate(moment(date));
  };

  const getAllChannels = () => {
    let otas: string[] = [api_data?.name];
    let ota_ids: any[] = [-1];
    allChannel?.map((channel, i) => {
      otas.push(channel.ota_name);
      ota_ids.push(channel.ota_id);
    });
    setAllOtas({
      labels: otas,
      ids: ota_ids,
    });
  };
  useEffect(() => {
    getAllChannels();
  }, []);
  const changeChannel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSourceChannel(e.target.value);
    setSelectedOta([]);
    setErrorMessage([]);
  };
  const handleOtaCode = (e: any, ota_id: any, ota_name: any) => {
    let newArray = [...selectedOta];
    if (e.target.checked && selectedSourceChannel !== ota_name) {
      newArray.push({ id: ota_id, name: ota_name });
    } else {
      newArray = newArray.filter((selected_otacode) => {
        return selected_otacode.id !== ota_id;
      });
    }

    setSelectedOta(newArray);
  };

  // for all channels select
  const selectAllChannels = (e: any) => {
    let newArray: any[] = [];
    if (
      selectedSourceChannel !== "Bookingjini" ||
      selectedSourceChannel !== "Hotelkite"
    ) {
      newArray = [{ id: -1, name: "Bookingjini" }];
    }
    if (e.target.checked) {
      allChannel.forEach((channel) => {
        if (selectedSourceChannel !== channel.ota_name) {
          newArray.push({ id: channel.ota_id, name: channel.ota_name });
        }
      });
    } else {
      newArray = [];
    }
    setSelectedOta(newArray);
  };

  // calling this function when click on apply

  const updateSyncInventory = async () => {
    // onClose();
    //setLoader(true);
    setSync_loader(true);
    if (
      syncDuration &&
      selectedSourceChannel &&
      fromDate &&
      selectedOta.length > 0
    ) {
      let allChannels: any = [];
      selectedOta?.map((ota: any, i: number) => {
        allChannels.push(ota.id);
      });

      try {
        let payload = {
          hotel_id: current_property.hotel_id,
          room_type_id: selectRoomType_id,
          admin_id: admin_id,
          ota_id: allChannels,
          duration: syncDuration,
          from_date: moment(fromDate).format("DD-MM-YYYY"),
          source_ota_name:
            selectedSourceChannel === "Hotelkite"
              ? "Bookingjini"
              : selectedSourceChannel,
        };


        let res = await cmApi.post(cmSyncInv, payload, {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        });

        if (res.data) {
          getInventory();
        }
        // setLoader(false);
        setSync_loader(false);
        setErrorMessage(res.data);
      }
      catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
      // setLoader(false);
      setSync_loader(false);
    }
  };

  const sync_Duration = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (validatePositiveInt(value) || !value) {
      if (parseInt(value) > 30) {
        setSyncDuration("30");
      } else {
        setSyncDuration(value);
      }
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">
          Sync Inventory ({selectRoomType})
        </div>
      </div>

      <div className="height-64px"></div>

      <div className="inventory__update__wrapper">
        <div className="datepicker__wrapper mui__input__fieldset__legends__unset">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label=""
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              value={fromDate}
              onChange={onDateChange}
              minDate={new Date()}
              inputFormat="dd MMM yyyy"
              InputAdornmentProps={{ position: "start" }}
              disableMaskedInput
              renderInput={(params) => (
                <TextField
                  {...params}
                  onClick={(e) => setOpen(true)}
                  onKeyDown={(e) => e.preventDefault()}
                />
              )}
            />
          </LocalizationProvider>
        </div>

        <div className="inventory__number__wrapper mt-3 pt-3">
          <div className="__default--input--field">
            <InputTextField
              label="Sync Duration (30 Days Max)"
              value={syncDuration}
              handleChange={sync_Duration}
            />
          </div>
        </div>

        <div className="standard-page--label mb-4 ">Choose Source Channel</div>
        <div className="inventory__choose__channel__source__select">
          <InputSelectField
            label="Choose Source Channel"
            values={allOtas.labels}
            value={selectedSourceChannel}
            options={allOtas.labels}
            handleChange={changeChannel}
          />
        </div>

        <div className="inventory__sync__channel__wrapper mt-3 pt-2">
          <div className="select__all__checkbox__wrapper mb-4">
            <div className="standard-page--label">Choose Target Channels</div>
            <div className="select__all__checkbox">
              <input
                className="form-check-input custom-size me-3"
                type="checkbox"
                id="flexCheckDefault"
                onChange={(e) => {
                  selectAllChannels(e);
                }}
                //disabled={allOtas.labels.includes(selectedSourceChannel)}
                checked={
                  selectedOta.length ===
                  (selectedSourceChannel
                    ? allOtas.labels.length - 1
                    : allOtas.labels.length)
                }
              />
            </div>
          </div>

          {selectedSourceChannel !== "Bookingjini" &&
            selectedSourceChannel !== "Hotelkite" && (
              <div className="mb-4">
                {errorMessage?.some(
                  (ota: any) => ota.ota_name === "bookingjini"
                ) &&
                  //<div className="ota_error_message">Roomtype should be sync</div>
                  errorMessage
                    .filter(
                      (task: any) =>
                        task.ota_name.toLowerCase() === "bookingjini"
                    )
                    ?.map((mes: any) => (
                      <div className="ota_error_message">
                        {mes.response_msg}
                      </div>
                    ))}
                {/* 
                <div
                  className={`channel__wrapper  ${errorMessage?.some(
                    (ota: any) => ota.ota_name === "bookingjini"
                  )
                    ? "error--border"
                    : errorMessage?.some(
                      (ota: any) =>
                        ota.ota_name !== "bookingjini" &&
                        selectedOta.some(
                          (source: any) => source.name === "Bookingjini"
                        )
                    )
                      ? "success--border"
                      : ""
                    }`}
                  onClick={() =>
                    document.getElementById(`flexCheckDefault${-1}`)?.click()
                  }
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="channel__image__content">
                      <img src={api_data?.ota_icon_path} />
                    </div>
                    {errorMessage?.some(
                      (ota: any) =>
                        ota.ota_name?.toLowerCase() === "bookingengine" &&
                        ota.status == 1
                    ) && (
                        <div className="success__checkmark">
                          <i className="bi bi-check"></i>
                        </div>
                      )}
                  </div>
                  <div className="channel__select__checkbox">
                    <div className="form-check">
                      <input
                        className="form-check-input unclickable"
                        type="checkbox"
                        id={`flexCheckDefault${-1}`}
                        onChange={(e) => handleOtaCode(e, -1, "Bookingjini")}
                        checked={selectedOta.some((ota: any) => ota.id === -1)}
                      />
                    </div>
                  </div>
                </div> */}

              </div>
            )}

          {allChannel?.map((channel, id) => {
            if (
              selectedSourceChannel?.toLowerCase() !==
              channel.ota_name?.toLowerCase()
            ) {
              return (
                <div className="mb-4">
                  {errorMessage?.some(
                    (ota: any) =>
                      ota.ota_name === channel.ota_name.toLowerCase() &&
                      ota.status === 0
                  ) &&
                    errorMessage
                      .filter(
                        (task: any) =>
                          task.ota_name?.toLowerCase() ===
                          channel.ota_name?.toLowerCase()
                      )
                      ?.map((filteredPerson: any) => (
                        <div className="ota_error_message">
                          {filteredPerson.response_msg}
                        </div>
                      ))}

                  <div
                    className={`channel__wrapper  ${errorMessage?.some(
                      (ota: any) =>
                        ota.ota_name === channel.ota_name?.toLowerCase() &&
                        ota.status == 0
                    )
                      ? "error--border"
                      : errorMessage?.some(
                        (ota: any) =>
                          ota.ota_name == channel.ota_name?.toLowerCase() &&
                          ota.status == 1 &&
                          selectedOta.some(
                            (source: any) =>
                              source.name === channel.ota_name
                          )
                      )
                        ? "success--border"
                        : ""
                      }`}
                    key={id}
                    onClick={() =>
                      document.getElementById(`flexCheckDefault${id}`)?.click()
                    }
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="channel__image__content">
                        <img src={channel.ota_logo_path} />
                      </div>

                      {errorMessage?.some(
                        (ota: any) => {
                          if (ota.ota_name === 'Bookingjini' || ota.ota_name === 'BookingEngine') {
                            if (
                              (channel.ota_name === "Bookingjini" || channel.ota_name === "BookingEngine")
                              && ota.status == 1) return ota
                          }
                          if (ota.ota_name !== 'BookingEngine' || ota.ota_name !== 'BookingEngine') {
                            if (ota.ota_name?.toLowerCase() ===
                              channel.ota_name?.toLowerCase() && ota.status == 1) return ota
                          }
                        }
                      ) && (
                          <div className="success__checkmark">
                            <i className="bi bi-check"></i>
                          </div>
                        )}
                    </div>
                    <div className="channel__select__checkbox">
                      <div className="form-check">
                        <input
                          className="form-check-input unclickable"
                          type="checkbox"
                          id={`flexCheckDefault${id}`}
                          onChange={(e) => {
                            handleOtaCode(e, channel.ota_id, channel.ota_name);
                          }}
                          checked={selectedOta.some(
                            (ota: any) => ota.id === channel.ota_id
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      >
      </div>

      {!sync_loader ? (
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3" handleClick={onClose}>
              Cancel
            </Button>
            <InventoryBtn handleClick={updateSyncInventory}>Save</InventoryBtn>
          </div>
        </div>
      ) : (
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3">Cancel</Button>
            <InventoryBtn>
              Save{" "}
              <span
                className="spinner-border spinner-border-sm pd-4"
                role="status"
                aria-hidden="true"
              ></span>
            </InventoryBtn>
          </div>
        </div>
      )}
    </>
  );
};

export default SyncInventory;
