// global api endpoints
export default {
  // Login with google
  LOGIN_AUTH: {
    getExtranetAdminAuth: `extranet-admin/auth`,
  },
  // Property Setup
  PROPERTY_SETUP: {
    getPropertyCountDetails: "property_count_details",
    getPropertyScorePercentage: "property_score_percentage/",
  },

  // Property Setup -> Basic Details
  PROPERTY_BASIC_DETAILS: {
    hotelkiteCountryDetails: "hotelkite-countrydetails/getall", // hotelkite country details
    bookingjiniCountryDetails: "getAllCountries", // bookingjini country details
    getAllHotelsById: "hotel_admin/get_all_hotels_by_id/", // get hotel details by id
    updateNewProperty: "update_new_property/", // update the basic details property
    getStatesByCountryId: "statedetails/getById/", // get states from country id
    updatePropertyAddress: "update_property_address/", // call to update address
  },

  // Property Setup -> Rate Plan
  PROPERTY_RATE_PLAN: {
    masterRatePlanDetails: "master-rate-plan-details", // get master plan
    masterRatePlanAll: "master_rate_plan/all/", // get all master plan and plan data
    deleteRoomRatePlanApi: "master_rate_plan/", // delete rate plan
    ratePlanCreation: "master_rate_plan/add", // rate plan creation
    ratePlanUpdation: "master_rate_plan/update/", // rate plan updation
  },

  // Property Setup -> Room Types
  PROPERTY_ROOM_TYPE: {
    hotelMasterRoomType: "hotel_master_room_type/all/", // details for the selected room type
    getRatePlansByRoomtypeID: "getRatePlansByRoomtypeID", // rate plan details for the selected room type
    getAllRoomTypeAmenities: "getAllRoomtypeamenities", // get all room type amenities
    getRoomTypeRatePlans: "getRoomtypeRateplans", // get room type rate plans
    updateBasicDetail: "update-basic-detail", // update room type basic details
    updateRoomTypeAmenities: "update-room-type-amenities", // update room type amenities
    saveUploadedRoomTypeImage:
      "hotel_master_new_room_type/upload_room_type_images/", // save uploaded room type images
    updateOccupancy: "update-occupancy", // save room type occupancy details
    deleteRoomTypeApi: "hotel_master_new_room_type/delete/", // delete room type
    deleteRoomTypeImageApi:
      "hotel_master_new_room_type/delete_single_roomtype_image/", // delete room type image
    updateRoomRatePlan: "update-room-rate-plan", // save room type rate plan details
    createNewRoomTypeApi: "hotel_master_new_room_type/add", // new room type creation
  },

  // Property Setup -> Floors
  PROPERTY_FLOORS: {
    getFloors: "getFloors", // get all floors
    saveFloors: "saveFloors", // save floors
  },

  // Property Setup -> Rooms
  PROPERTY_ROOMS: {
    getFloorsRoomsApi: "getRooms2", // all the floors and their room details
    addRoomApi: "addRoom", // room creations
    deleteRoomApi: `DeleteRoom`, // delete a room
    updateRoomApi: `UpdateRoom`, // room update
  },

  // Property Setup -> Amenities
  PROPERTY_AMENITIES: {
    getAllPropertyAmenities: "getAllPropertyAmenities", // get property amenities
    getHotelAmenities: "hotel_amenities/hotelAmenity/", // get hotel amenities
    removePropertyAmenities: "remove_property_amenities", // remove property amenities
    updatePropertyAmenities: "update_property_amenities", // update property amenities
  },

  // Property Setup -> Images
  PROPERTY_IMAGES: {
    propertyImagesApi: "get_Images/", // get property images
    uploadPropertyImagesApi: "uploadhotelimages", // upload the property images
    deletePropertyImagesApi: "delete_property_image/", // delete the property images
  },

  // Property Setup -> Terms and Policy
  PROPERTY_TERMS_POLICY: {
    hotelTermsPolicy: "hotel_policies/", // get terms and policy
    updateTermsPolicies: "hotel_policies/update", // update terms and policies
  },

  // Property Setup -> Cancellation Rules
  PROPERTY_CANCELLATION_RULES: {
    cancellationPolicy: "be_cancellation_policy/fetch_cancellation_policy/", // cancellation rules
    updateCancellationPolicy:
      "be_cancellation_policy/update_cancellation_policy", // update cancellation policy
  },

  // Property Setup -> Financial Details
  PROPERTY_FINANCIAL_DETAILS: {
    getBankAccountDetails: "hotel_bank_account_details/", // API called for bank details
  },

  // Property Setup -> Locale Info
  PROPERTY_LOCALE_INFO: {
    getLocaleDetails: "locale-details/", // property locale info
  },

  // Property Setup -> Dynamic Pricing Setup
  PROPERTY_SETUP_DYNAMIC_PRICING: {
    dynamicPriceDataGet: `dynamic-pricing/all/`, // Api Call For To Get Dynamic Pricing Table Data
    allRoomTypesApi: `/hotel_master_room_type/room_types/`, // Api Call For To Get All Room Types fOR Options Selecttion
    dynamicPriceDelete: `dynamic-pricing/delete/`, // Api Call For To Delete Dynamic Pricing
    dynamicPriceUpdate: `dynamic-pricing/update`, // Api Call For To Update Dynamic Pricing Table Data
    dynamicPriceDetailsOfSpecificRoom: `dynamic-pricing/one/`, // Api Call For To get Specific Pricing On Basis Of Room Types
    dynamicPriceAdd: `dynamic-pricing/add`, // Api Call For To Add Dynamic Pricing Table Data
  },

  INVENTORY: {
    //BE
    //property-update
    beSingleUpdate: "manage_inventory/update-inv", //specific date inventory update
    beInvUpdate: "manage_inventory/inventory_update", //bulk inventory update

    //block
    beBlockSpecificDates: "block-specific-dates", //specific date block inventory
    beBlockBulkInventory: "manage_inventory/block_inventory", //bulk block inventory

    //unblock
    beUnblockDateRange: "unblock-daterange", //bulk unblock inventory
    beUnblockSpecificDates: "unblock-specific-dates", //specific date unblock inventory

    //property-blockout
    beBlockProperty: "block-property", //block entire property
    beUnblockProperty: "unblock-property", //unblock entire property

    // ------------------------
    //KERNEL
    hotelMasterRoomApi: "hotel_master_room_type/all/", //fetching all the available room types
    // ------------------------

    //CM
    getInventoryApi: "/getInventory/", //fetching All inventory data

    calenderUpdateApi: "/calender-update", //fupdates the selected inventories

    //property-update
    cmSingleUpdate: "manage_inventory/update-inv", //specific date inventory update
    cmInvUpdate: "manage_inventory/inventory_update", //bulk inventory update

    //block
    cmBlockSpecificDates: "block-specific-dates", //specific date block inventory
    cmBlockBulkInventory: "manage_inventory/block_inventory", //bulk block inventory

    //unblock
    cmUnblockDateRange: "unblock-daterange", //bulk unblock inventory
    cmUnblockSpecificDates: "unblock-specific-dates", //specific date unblock inventory

    //property-blockout
    cmBlockSpecificProperty: "block_specific_property", //block entire property
    cmUnblockSpecificProperty: "unblock_specific_property", //unblock entire property

    //sync
    cmSyncInv: "manage_inventory/sync-inv", //sync inventory

    // soldout dates
    getSoldoutDates: "get-block-dates",
  },

  MANAGECHANNEL: {
    //CM
    getAddedChannelsApi: "get_added_channels", // fetches all added otas
    getAllAvailableOTAs: "getallchannel", //fetches all available otas

    //BE
    checkAllSetup: "check-all-setup",

    //-----Other OTAs-----
    //KERNEL
    airBnbSave: `air-bnb/save-code`,

    //CM
    addOTAhotelDetails: `add-ota-hotel-details`,
    specificOTAdetailsApi: `cm_ota_hotel_details`, //fetches specific ota details
    syncHistoryDataApi: `get_unmapped_room_types_n_last_sync`, //fetches sync hstory data
    addOTAdetails: "cm_ota_details/add", //add ota details
    setSync: "get-all-airbnb-listing",
    otaDetailsSync: "cm_ota_details/sync", // syncs ota details
    updateOTAdetails: "update-ota-hotel-details", //updates ota details
    cmUpdateOTAdetails: "cm_ota_details/update",
    cmUpdateOTAdetailsToggle: "cm_ota_details/toggle",
    getAllOTAratePlanAPI: "cm_ota_rateplan_sync_new/ota_room_type",
    saveOTAratePlan: `cm_ota_rateplan_sync_new/add`,
    deleteRatePlan: "cm_ota_rateplan_sync",
    roomTypeChange: "cm_ota_roomtype_sync/ota_room_type",
    getOTAratePlan: "cm_ota_roomtype_sync/ota_rate_plan",
    getRatePlanByRoomType: "master_hotel_rate_plan/rate_plan_by_room_type",
    updateRateSyncApi: "cm_ota_roomtype_sync/update",
    addRatePlanSync: `cm_ota_rateplan_sync/add`,
    getRoomMappingDataApi: "cm_ota_rateplan_sync_new/rooms",
    syncOTAroomType: `cm_ota_roomtype_sync_new/add`,

    //CM
    deleteRoomSyncApi: "/cm_ota_roomtype_sync",
    updateRoomSyncApi: "/cm_ota_roomtype_sync/update",
    addRoomSyncApi: `/cm_ota_roomtype_sync/add`,

    // -----Booking Engine-----
    //KERNEL
    getCompanyProfile: "/company_profile/get",
    getLogoBanners: "/company_profile/get-logo", //fetches logo and banners
    uploadLogoBanners: "/upload", //uploads logo and banners
    imageSend: "/company_profile/booking_page",
    deleteImageApi: "/deleteImage", //deleted image
    getAllRoomTypesApi: "/hotel_master_room_type/room_types",
    otherInfoUpdate: "/hotel_other_information/update",
    getHotelOtherInfoApi: "/hotel_other_information",
    getPromotionalSliderImages: "/fetch-be-notification-slider-images",
    uploadPromotionalImages: "/upload-be-notification-slider-images",
    deletePromotionalImages: `/delete-be-notification-slider-image`,
    allRoomTypeEndpoint: `/hotel_master_room_type/all`,
    ratePlanByRoomTypeAPI: `/master_hotel_rate_plan/room_rate_plan_by_room_type`,
    getAddonCharges: `select-all-charges`,
    setActiveCharge: `active-charges`,
    addCharges: `add-charges`,
    updateCharges: `update-charges`,
    selectCharges: `select-charges`,
    deleteCharges: `delete-charges`,

    //BE
    manageUrl: "/manage-url",
    updateUrl: "/manage-url/update",
    getOtherSettings: "/other-settings",
    otherSettingsUpdate: "/other-settings/update",
    themeSetup: "/theme-setup",
    themeSetupUpdate: "/theme-setup/update",
    allCouponTypes: `/coupons/list/type`,
    getAllCouponsApi: "/coupons/get",
    deleteCouponsApi: "/coupons",
    updateCoupons: "/coupons/update",
    addCoupons: `/coupons/add`,
    getNotificationsApi: "/be-notifications",
    notificationPost: "/be-notifications-popup",
    getPaidServicesList: "/paid_services/all",
    getPaidServicesUpdate: "paid_services/update",
    addPaidServices: `paid_services/add`,
    deletePaidServices: `paid_services/delete`,
    quickPaymentAll: `quick_payment/all`,
    quickPaymentResendEmail: `quick_payment/resend-email`,
    bePaymentGatewayDetails: `/bookingjini-pay-gate-details`,
    addQuickPayment: `/quick_payment/add`,
    quickPaymentCheckEndPoint: `/quick_payment/check`,
    userRegisterEndPoint: `/user/register`,
    beBookingsEndPoint: `/bookingEngine/bookings`,
    quickPaymentLinkEndPoint: `/quick-payment-link`,
  },

  // ---------------------------------{ Rate Api }------------------------------

  RATE: {
    // Api Call To Get Rates Information
    getrates: `getrates`,

    //  API call to get Rate Plan for the selected room types
    ratePlansOfRoom: `/master_hotel_rate_plan/room_rate_plan_by_room_type`,

    // API call to get all blockable OTA list
    blockableOTAlist: `get_supported_ota_for_rateblock/`,

    // API call to get rates info for the selected room type for the selected date range
    ratesInfoSelectedRoomAndDate: `ota_inv_rate/get_bookingengine_ota_min_rates/`,

    // API call to get rates info for the selected OTA
    ratesInfoSelectedOTA: `ota_inv_rate/get_ota_rates_by_room_type/`,

    // Api call to Block Price of BE
    blockPriceBEApi: `block-rate-specific-dates`,

    // ===> Rate (Block Update) Api

    // Api Call For Date Range Block Of BE
    blockDateRange: `manage_inventory/block_rate`,

    //   ====> Rate (Bulk Update) Api

    // Api Call For Date Range Update

    updateDateRange: `manage_inventory/room_rate_update`,

    // Api Call For Single Date  Update

    updateSingleDate: `manage_inventory/update-rates`,

    //   =====> Rate (Confirm Update) Api

    // Api Call For Calender Update
    RatesUpdateCM: `calender-rate-update`,

    //   ===> Rate (Sync Update) Api

    //Api Call For Update Sync Rate
    syncUpdateRate: `sync-rates`,

    //   ===> Rate (UnBlock Update) Api

    //Api Call For UnBlock Rate of Specific Date
    rateUnblockSpecificDate: `unblock-rate-specific-dates`,

    //Api Call For UnBlock Rate of Date Range

    rateUnblockRangeDateCm: `unblock-rate`,

    //Api Call For UnBlock Rate of Date Range BE
    rateUnblockRangeDateBe: `unblock-rate-range`,
  },

  // ---------------------------------{ Bookings Api }------------------------------

  BOOKINGS: {
    // ============>>>>>  Api Call For Bookings Front-Office-View <<<<<==============

    // Api To Check Is Gems Subscribed
    isgemsSubscribed: `isGEMSSubscribed`,

    // Api To Get Bookings Data Who Opted Gems
    gemsBookings: `frontofficeBookingCalendar`,

    //Api To Get Provisional Bookings Data
    getBookingDetails: `getBookingDetails`,

    // Api To Get Checkout Booking Details
    checkedOutBookingDetailsApi: `getCheckedOutBookingDetails`,

    // Api To Cancel Booking
    cancelBooking: `cancelTravellerBooking`,

    //   Api Call To Get All Sources

    allSources: `source-list`,

    //   Api Call To Get Room Types Data
    roomTypesData: `extranet-bookings/roomdetails`,

    //   Api Call To Get Booking Details CRS

    bookingDetailsCRSGet: `crs/crs-booking-details`,

    //   Api Call To Get Booking Details By Id

    stayDetailsByBookingIdGet: `getStayDetailsByBookingID`,

    stayDetailsByBookingIdGetV2: `getBookingDetailsByBookingidV2`,

    //   Api Call To Get Booking Details

    bookingDetailsGet: `getBookingDetails`,

    //   Api Call To Get Booking Data

    bookingsDataGet: `extranet-bookings/bookings`,

    //============>>>>>   Api Call For Bookings CRS-View <<<<<==============

    // Api Call To Get Room Types Data

    roomTypesDataGet: `extranet-bookings/roomdetails`,

    //============>>>>>   Api Call For Bookings List-View <<<<<==============

    listViewBooking: `booking-lists`,

    //============>>>>>   Api Call For Cancel Bookings <<<<<==============

    // Api Call For Cancel Bookings
    cancelBookings: `crs/crs_cancel_booking`,

    //============>>>>>   Api Call For CheckIn <<<<<==============

    // Api Call For TO GET Billing Details By Id

    billingDetailsByIdGet: `getBillingDetailsByBookingidV2`,

    //  Api Call For TO GET Room Booking Details By Id

    roomDetailsByBookingIdGet: `getRoomAndGuestDetails`,

    //   Api Call For TO GET Font Office Check In
    fontOfficeCheckInApi: `frontOfficeCheckin3`,

    // Api Call For TO GET Allocate Room Booking

    bookingAllocateRoom: `extranet-bookings/allocateroom`,

    //============>>>>>   Api Call For CheckInGuestDetails <<<<<==============

    guestDetailsFromMobileGet: `guestDetails`,

    //============>>>>>   Api Call For InvoiceDownload <<<<<==============

    pdfInvoiceGenerate: `generatePDFInvoice`,

    //============>>>>>   Api Call For ModifyBookings <<<<<==============

    // Api Call For Modify Guest Details
    modifyGuestDetails: `crs/crs-modified-guest-details`,

    //  Api Call For Modify Guest Details NeW One

    modifyGuestDetailsNew: `crs/crs_modify_bookings-new`,

    //============>>>>>   Api Call For NewBookings <<<<<==============

    // To fetch Room Available

    availableRoomGet: `get-available-rooms`,

    // To Get User Information from CrS
    userInfoCrsGet: `get-user-info-crs`,

    // To Get Company Gst

    userGstApi: `user-gst`,

    // To Get Company Api Key
    companyProfileGet: `company_profile/get`,

    // To Get Tax Details

    getLocaleDetails: `locale-details`,

    // To Get Bussiness Sources

    getBusinessSourceDetails: `get-business-source`,

    // To Resister a User

    toRegisterUser: `user/register`,

    //============>>>>>   Api Call For Stay Details <<<<<==============

    // Api Call For To CheckOut In Fontoffice

    checkOutInFontoffice: `frontOfficeCheckout`,

    // Api Call For To CheckOut In CRS

    checkOutCRS: `extranet-bookings/deallocateroom`,

    // Api Call For To Download Bill

    billDownload: `generatePDFBill`,

    //============>>>>>   Api Call For Stay Details Add Bills <<<<<==============

    // Api Call For Fetching Payment Modes

    paymentModeGet: `getPaymentModes`,

    // Api Call For Add Expenses

    expenseAdd: `addExpense`,

    // Api Call For Add Collections

    collectionAdd: `addCollection`,

    addPayment: `addPayment`,

    stayDetailsGetByBookingID: `getStayDetailsByBookingID`,
  },

  // ----------------------------------{ Dashboard }--------------------------------//
  DASHBOARD: {
    // navigate to select property page
    selectProperty: `/select-property`,

    // To check if plan is subscribed
    isPlanSubscribed: `is-plan-subscribed`,

    // Navigate to renew subscription is not subscribed
    renewSubscription: `/choose-plan/renew-subscription`,

    // Get the details of currency -- locale Info
    localeDetails: `/locale-details`,
  },
  // -----------------------------------
  ADDNEWPROPERTY: {
    //KERNEL
    getAllSelectedPropAmenities: "/getAllSelectedPropertyAmenities",
    addNewPropertyPropImage: "/add-new-property/property-images",
    addNewPropertyPropAddress: "/add-new-property/property-address",
    addNewPropertyPropSubType: "/add-new-property/property-subtype",
    addNewPropertyPropOverview: "/add-new-property/property-overview",
    addNewPropertyPropAmenities: "/add-new-property/property-amenities",
    addNewPropertyPropDetails: "/add-new-property/property-details",
    getAllPropertyTypes: "/getAllPropertyTypes",
    getAllAmenitiesApi: "/getAllPropertyAmenities",
    getAllHotelsByCompanies: "/hotel_admin/get_all_hotels_by_company",
    addNewPropertySuccess: "/add-new-property/success",
    addNewPropertyNew: "/add_new_property_new",
    addNewProperty: "/add-new-property",
    uploadHotelImages: "/uploadhotelimages",
    getAllPropertiesSubtypes: "/getAllPropertySubTypes/",
    addNewPropertyType: "/add-new-property/property-type",
  },

  //   stayDetailsGetByBookingID: `getStayDetailsByBookingID`,
  // },

  DASHBOARDDATA: {
    // hotelier-today-overview
    hotelierTodayOverview: `hotelier-today-overview`,

    // hotelier summary
    hotelierSummary: `hotelier-summary`,

    //roomtype-wise-booked-room-nights
    roomtypeWiseBookedRoomNights: `roomtype-wise-booked-room-nights`,

    //channel-wise-booked-room-nights-revenue
    channelWiseBookedRoomNightsRevenue: `channel-wise-booked-room-nights-revenue`,

    // booking-details
    bookingDetails: `booking-details`,

    // Get Trend Graph Data
    getTrendGraphDta: `https://staging.bookingjini.com/api/rateshopper/gettrendgraph`,
  },

  MANAGEUSER: {
    // Get hotel list : admin access
    getHotelList: `/hotel_admin/get_hotel_list`,

    // Manageuser Add
    manageUserAdd: `/manage_user/add`,

    //-----------Edit Access ----------------
    // Get user access
    getUserAccess: `/get-user-access`,

    // Get Access Functionality
    getAccessFunctionality: `/get-access-functionality`,

    // user access updation or creation
    userAccessUpdationOrCreation: `/user-access-updation-or-creation`,

    ///----------Edit User -----------------
    // manage User
    manageUser: `/manage_user`,

    // manageUser update
    manageUserUpdate: `/manage_user/update`,

    //-----------Manage Uesrs --------------
    // Manage User Delete User
    manageUserDeleteUser: `/manage_user/delete-user`,

    // manage user All
    manageUserAll: `/manage_user/all`,
  },

  MANAGESUBSCRIPTION: {
    // get checkout URL for Chargebee
    getCheckoutUrlForChargebee: `/get-checkout-url`,

    // get checkout URL for zoho
    getCheckoutUrl: `/get-zoho-checkout-url`,

    // get tiers
    getTiersPlan: `/get-tiers`,

    // get plans
    getPlans: `/get-plans`,

    // get zoho plan
    getZohoPlans: `/get-zoho-plans`,

    // my subscription
    mySubscription: `/my-subscription`,

    // cancel subscription
    cancelSubscription: `/cancel-subscription`,

    // cancel subscription
    cancelZohoSubscription: `/cancel-zoho-subscription`,

    // get checkout url for update
    getCheckoutUrlForUpgrade: `/get-checkout-url-for-upgrade`,

    // get checkout url for update
    getCheckoutUrlForZohoUpgrade: `/get-zoho-checkout-url-for-upgrade`,

    // get all upgrade plans
    getAllUpgradePlans: `/get-all-upgradable-plans`,

    // get checkout url for update
    getCheckoutUrlForRenewal: `/get-zoho-checkout-url-for-renewal`,

    // get checkout url for update
    getCheckoutUrlForRenewalForChargebee: `/get-checkout-url-for-renewal`,

    // get subscription sucess details
    mySubscribedPlan: `/my-subscribed-plan`,

    // get zoho payment method
    getZohoPaymentMethodUpdateUrl: `/get-zoho-payment-method-update-url`,

    // change payment method
    getZohoSavedCards: `/get-zoho-saved-cards`,
  },

  MANAGEBASICPROMOTION: {
    // get all the active promotions
    getAllActiveBasicPromotions: `/get-all-promotion`,

    // get all the channels
    getAllChannels: `/cm_ota_details/get-promotion`,

    // deactivate a basic promotion
    deactivateBasicPromotion: `/deactivate-promotion-test`,

    // get all the aroom type rate plans
    getRoomTypeRatePlans: `/get_room_type_rate_plans`,

    // add a basic promotions
    insertBasicPromotion: `/insert-hotel-promotion-test`,

    // get all the inactive promotions
    getAllInactiveBasicPromotions: `/get-all-inactive-promotion`,

    // activate a basic promotion
    activateBasicPromotion: `/activate-promotion-test`,

    // fetch a basic promotion details
    getBasicPromotion: `/get-hotel-promotion`,

    // update a basic promotion
    updateBasicPromotion: `/update-hotel-promotion-test`,

    // view a basic promotion
    viewBasicPromotion: `/view-promotion`,
  },
};
