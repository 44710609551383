import { CHOOSE_PROPERTY, GET_PROPERTIES, SAVE_PROPERTY_INFO } from "../actionTypes"

export const AllProperties = (data: any) => {
    return ({
        type: GET_PROPERTIES,
        payload: data
    })
}

export const CurrentProperty = (prop: any) => {
    return ({
        type: CHOOSE_PROPERTY,
        payload: prop
    })
}

export const SavePropertyInfo = (info: any) => {
    return ({
        type: SAVE_PROPERTY_INFO,
        payload: info
    })
}
