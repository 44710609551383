import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

// import google map react
import GoogleMapReact from "google-map-react";

import kernelApi from "../../API/kernelApi";
import GetHelp from "../../components/gethelp/GetHelp";
import BackButton from "../../views/buttons/Button";
import { NewPropertyLocation } from "../../redux/actions/AddPropertyAction";
import PropertyAddressForm from "../../components/property/PropertyAddressForm";
import { Logout } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

const PropertyMapAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const COORDS = window.location.origin.includes("hotelkite") ? { lat: 37, lng: -95.7 } : { lat: 26, lng: 78 }
  const ZOOM = window.location.origin.includes("hotelkite") ? 4 : 5;

  const fullAddress = useSelector((state: RootState) => state.add_property.new_property_address.address);
  const [mapCenter, setMapCenter] = useState(COORDS);
  const [mapZoom, setMapZoom] = useState(ZOOM);
  const [dragging, setDragging] = useState(false);

  const [mapSuggestions, setMapSuggestions] = useState([]);

  const [showUseLocation, setShowUseLocation] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [showMarkerLabel, setShowMarkerLabel] = useState(false);
  const [showSubmitAddress, setShowSubmitAddress] = useState(false);

  //Endpoints Import
  const { addNewPropertyPropAmenities, addNewPropertyPropDetails } = endPoints.ADDNEWPROPERTY
  // --------------------------------

  const [addressForm, setAddressForm] = useState({
    flat: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pin: "",
  });

  // get suggested address based on user input
  const getMapSuggestions = async (query: string) => {
    if (query.trim()) {
      const URL = "google-ads-autofill";
      // const URL = window.location.origin.includes("hotelkite") ? "google-ads-autofill-for-kite": "google-ads-autofill";
      const response = await kernelApi.get(`${URL}/${query}`);
      setMapSuggestions(response.data);
    } else {
      setMapSuggestions([]);
    }
  };

  // get location details of device's current location
  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        let GeoCoder = new google.maps.Geocoder();
        let LatLng = new google.maps.LatLng(latitude, longitude);
        GeoCoder.geocode({ location: LatLng }, function (results: any, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              let address = results[0];
              getPropertyAddressDetails(address);
              setShowAddressForm(true);
              setIsManualAddress(false);
            }
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
          }
        });
        setMapCenter({ lat: latitude, lng: longitude });
        setMapZoom(18);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // gets city, country, state, pin from address_components returned by google maps api
  const getPropertyAddressDetails = (address: any) => {
    let components: any = address.address_components;

    let flat: any = [];
    let street: any = [];
    let country;
    let state;
    let city;
    let pin;

    for (let i = 0; i < components.length; i++) {
      if (components[i].types[0] === "country") {
        country = components[i].long_name;
      } else if (components[i].types[0] === "postal_code") {
        pin = components[i].long_name;
      } else if (components[i].types[0] === "administrative_area_level_1") {
        state = components[i].long_name;
      } else if (components[i].types[0] === "locality") {
        city = components[i].long_name;
      } else if (
        components[i].types.includes("sublocality") ||
        components[i].types.includes("plus_code")
      ) {
        street.push(components[i].long_name);
      } else if (
        components[i].types.includes("premise") ||
        components[i].types.includes("neighborhood")
      ) {
        flat.push(components[i].long_name);
      }
    }
    setAddressForm({
      country: country,
      state: state,
      city: city,
      street: street.join(", "),
      pin: pin,
      flat: flat.join(", "),
    });
  };

  // get location details from address string
  const getLocationFromAddress = async (address: string) => {
    let GeoCoder = new google.maps.Geocoder();
    GeoCoder.geocode({ address: address }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK && results) {
        let LatLng = results[0].geometry.location;
        let latitude = LatLng.toJSON().lat;
        let longitude = LatLng.toJSON().lng;
        GeoCoder.geocode({ location: LatLng }, function (results: any, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              let address = results[0];
              getPropertyAddressDetails(address);
            }
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
          }
        });
        setMapCenter({ lat: latitude, lng: longitude });
        setMapZoom(18);
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  };

  const addressFormNext = () => {
    setShowSubmitAddress(true);
    setShowMarkerLabel(true);
    setShowAddressForm(false);
    getLocationFromAddress(fullAddress);
  };

  const addressFormBack = () => {
    setShowAddressForm(false);
    setShowUseLocation(false);
    setMapSuggestions([]);
  };

  const SubmitAddress = () => {
    if (fullAddress && mapCenter) {
      dispatch(NewPropertyLocation(mapCenter));
      setTimeout(() => {
        navigate(addNewPropertyPropAmenities);
      }, 500);
    }
  };

  const backButtonClick = () => {
    navigate(addNewPropertyPropDetails);
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <div className="div__input__wrapper__left div__left__side__box">
          <div className="logo__wrapper"></div>
          <div className="div__user__testimonials__wrapper">
            <div className="select__property__wrapper">
              <div className="select__property__text">
                Where is your property located?
              </div>
              <div className="select__property__sub__text">
                Search and Pin your Property Location.
              </div>
            </div>
          </div>

          <div className="progress-bar-wrapper">
            <div className="progress-bar-text">3/5</div>
            <div className="progress-bar-status">
              <ProgressBar percent={60} filledBackground="#ffffff" />
            </div>
          </div>
        </div>

        <div className="div__input__wrapper__right div__right__side__box m-0 ps-0 google__map__margin">
          <BackButton
            className="logout__btn"
            handleClick={Logout}
          >
            Logout
          </BackButton>
          <div className="google__map__wrapper">
            <div className="google__map__content">
              {!showAddressForm && !showSubmitAddress && (
                <>
                  <div
                    className={`map-input-wrapper ${showUseLocation ? "bg-white" : ""
                      }`}
                  >
                    <i
                      className="bi bi-chevron-left chevron__back"
                      onClick={backButtonClick}
                    ></i>
                    <i className="bi bi-geo-alt location-icon-position"></i>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Enter your Address"
                      onFocus={() => setShowUseLocation(true)}
                      className="address__capture__new__property"
                      onKeyUp={(e) => getMapSuggestions(e.currentTarget.value)}
                    />
                  </div>

                  {showUseLocation && (
                    <div className="map-dropdown-wrapper pb-0">
                      {!mapSuggestions.length && (
                        <div
                          className="map-dropdown-items"
                          onClick={getCurrentLocation}
                        >
                          <div>
                            <i className="bi bi-cursor map-dropdown-icons"></i>
                          </div>
                          Use Current Location
                        </div>
                      )}
                      {mapSuggestions.map((item: any, index: number) => {
                        return (
                          <div
                            className="map-dropdown-items"
                            key={index}
                            onClick={() => {
                              getLocationFromAddress(item.description);
                              setShowAddressForm(true);
                              setIsManualAddress(false);
                            }}
                          >
                            <span className="building__icon__wrapper">
                              <i className="bi bi-pin-map"></i>
                            </span>
                            {item.description}
                          </div>
                        );
                      })}
                      <div
                        className="enter__address__manually"
                        onClick={() => {
                          setShowAddressForm(true);
                          setIsManualAddress(true);
                        }}
                      >
                        Enter address manually
                      </div>
                    </div>
                  )}
                </>
              )}

              <div
                className={`map-input-wrapper bg-white ${showAddressForm ? "" : "d-none"
                  }`}
              >
                <PropertyAddressForm
                  isManual={isManualAddress}
                  data={addressForm}
                  onNext={addressFormNext}
                  onBack={addressFormBack}
                />
              </div>

              {showSubmitAddress && (
                <div className="map-input-wrapper">
                  <i
                    className="bi bi-chevron-left chevron__back"
                    onClick={backButtonClick}
                  ></i>
                  <i className="bi bi-geo-alt location-icon-position"></i>
                  <input
                    readOnly
                    type="text"
                    autoComplete="off"
                    value={fullAddress}
                    className="address__capture__new__property"
                  />
                  <span onClick={SubmitAddress}>
                    <i className="bi bi-arrow-right-circle arrow-icon-position"></i>
                  </span>
                </div>
              )}
            </div>
            <div className="google__map__react__wrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBceMBt5GputUmynEHqVn0L8rRByrIFU5M",
                }}
                options={{ mapTypeId: "roadmap", fullscreenControl: false }}
                // layerTypes={["TrafficLayer", "TransitLayer"]}
                center={mapCenter}
                zoom={mapZoom}
                onDrag={() => {
                  setDragging(true);
                  setShowMarkerLabel(false);
                }}
                onDragEnd={() => setDragging(false)}
                onClick={() => setShowUseLocation(false)}
                onChange={(e) => setMapCenter(e.center)}
              ></GoogleMapReact>
              {showSubmitAddress && (
                <>
                  <div
                    className={`google__map__marker${dragging ? "__dragged" : ""
                      }`}
                  ></div>
                  {showMarkerLabel && (
                    <div className="google__map__label">
                      Drag the map to reposition the pin
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <GetHelp />
        </div>
      </div>
    </div>
  );
};

export default PropertyMapAddress;
