import {
  DASHBOARD_HOTILER_DATA_TODAY,
  DASHBOARD_HOTILER_DATA_MTD,
  DASHBOARD_HOTILER_DATA_YTD,
} from "../actionTypes";
const initialState = {
  today_data: {},
  mtd_data: {},
  ytd_data: {},
};

const DashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DASHBOARD_HOTILER_DATA_TODAY:
      return {
        ...state,
        today_data: action.payload,
      };
    case DASHBOARD_HOTILER_DATA_MTD:
      return {
        ...state,
        mtd_data: action.payload,
      };
    case DASHBOARD_HOTILER_DATA_YTD:
      return {
        ...state,
        ytd_data: action.payload,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
