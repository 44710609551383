import React, { useState } from 'react';

// import user image
import UserImage from "../assets/img/userImage.png"
import InputPasswordField from '../views/inputtextfield/InputPasswordField';
import { validatePassword } from '../UtilityFunctions';

import StandardButton from '../views/buttons/Button';

import GetHelp from '../components/gethelp/GetHelp';

const EnterNewPassword = () => {
    const [password, setPassword] = useState('');
    const [passwordHelperText, setPasswordHelperText] = useState(" ");

    const PasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        if (!validatePassword(e.target.value) && e.target.value) {
            setPasswordHelperText("Invalid password")
        } else {
            setPasswordHelperText(" ")
        }
    }

    return (
        <div className="div-page-wrapper">
            <div className="div__wrapper">
                <div className="div__input__wrapper__left div__left__side__box">
                    <div className="logo__wrapper"></div>
                    <div className="div__user__testimonials__wrapper">
                        <div className="user__testimonial_image" style={{ backgroundImage: `url(${UserImage})` }}>
                            <div className="user__testimonial__message__wrapper">
                                <div className="testimonial__icon">
                                    <i className="bi bi-chat-square-quote" style={{ fontSize: 18 }}></i>
                                </div>
                                <div className="user__testimonial__text">"Bookingjini is definitely one of the best time saving softwares we have used since a very long time.”</div>
                                <div className="user__name">James Wong</div>
                                <div className="user__designation">CEO | Lotus Hotel</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="div__input__wrapper__right div__right__side__box">
                    <div className="div__inputs">
                        <div className="div--head--label">Reset Password</div>
                        <div className="div--helper--text">Please enter the new password</div>

                        <div className="div__inputs__wrapp">
                            <div className="div--password--input">
                                <InputPasswordField
                                    value={password}
                                    helperText={passwordHelperText}
                                    handleChange={(e) => PasswordOnChange(e)}
                                />
                            </div>

                            <div className="div--email--input">
                                <InputPasswordField
                                    value={password}
                                    helperText={passwordHelperText}
                                    handleChange={(e) => PasswordOnChange(e)}
                                />
                            </div>

                            <div className="__btn__wrapper text-end">
                                <StandardButton className="login--btn" handleClick={() => { }}>Reset</StandardButton>
                            </div>
                        </div>

                        <GetHelp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnterNewPassword;