import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import cmApi from "../../../API/cmApi";
import endPoints from "../../../API/endPoints";
import kernelApi from "../../../API/kernelApi";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import Card from "../../../views/cardwrapper/Card";
import Loader from "../../../views/loader/Loader";

const AirBnbCodeGet = () => {
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const currentUrl = window.location.href;

  const [loader, setLoader] = useState<boolean>(false);
  const [saveCodeApiRes, setSaveCodeApiRes] = useState<any>();
  const [addCodeApiRes, setAddCodeApiRes] = useState<any>();
  const [addCodeApiMessage, setAddCodeApiMessage] = useState<any>();
  const [addCodeApiRefreshMessage, setAddCodeApiRefreshMessage] =
    useState<any>();

  let code = currentUrl;
  //   "https://kernel.bookingjini.com/?code=3vuqj655dl0g34nq6whqb1sq9&scope=messages_read+property_management&state=";
  let code1 = code?.split("=");
  let RequiredCode = code1[1]?.split("&");

  useEffect(() => {
    AirBnbCodeSend();
  }, [current_property]);

  //Endpoints Import
  const { airBnbSave, addOTAhotelDetails } = endPoints.MANAGECHANNEL
  // --------------------------------

  // Send AirBnb Code to API
  const AirBnbCodeSend = async () => {
    setLoader(true);
    try {
      let codeReq = await kernelApi.post(
        airBnbSave,
        {
          code: RequiredCode[0],
          hotel_id: current_property && current_property.hotel_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (codeReq.data.status === 1) {
        AddAirBnbCode();
        setSaveCodeApiRes(codeReq.data.status);
        setLoader(false);
      } else {
        setSaveCodeApiRes(codeReq.data.status);
        setLoader(false);
      }
    } catch (err) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // AirBnB Code Add
  const AddAirBnbCode = async () => {
    setLoader(true);
    try {
      let AddcodeReq = await cmApi.post(
        addOTAhotelDetails,
        {
          commision: 10,
          ota_name: "Airbnb",
          auth_parameter: "noAuth",
          hotel_id: current_property && current_property.hotel_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (AddcodeReq.data.status === 1) {
        setAddCodeApiRes(AddcodeReq.data.status);
        setAddCodeApiMessage(AddcodeReq?.data?.message);
        setAddCodeApiRefreshMessage(AddcodeReq?.data?.refresh_msg);
        setLoader(false);
      } else {
        setAddCodeApiRes(AddcodeReq.data.status);
        setAddCodeApiMessage(AddcodeReq?.data?.message);
        setLoader(false);
      }
    } catch (err) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  return (
    <>
      {/* <div className="air-bnb-code-wrapper">
        <Card className="air-bnb-code-card">
          <input
            type="text"
            value={"anb code hahdcbbhbhdhbdsahb sdvshvhsdvhjvdshv"}
            className="air-bnb-code"
            readOnly
          />

          <div className="air-bnb-code-copy-btn">
            <Button>Copy</Button>
          </div>
        </Card>
      </div> */}
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <div className="air-bnb-code-wrapper">
          <Card className="air-bnb-code-card">
            {addCodeApiRes === 1 ? (
              <>
                <div className="air-bnb-sucess-message">
                  {addCodeApiMessage}
                </div>
                <br />
                <div>{addCodeApiRefreshMessage}</div>
              </>
            ) : (
              <div className="air-bnb-failed-message">{addCodeApiMessage}</div>
            )}
          </Card>
        </div>
      )}
    </>
  );
};

export default AirBnbCodeGet;
