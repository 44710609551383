import React, { RefObject, useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import InventoryBtn from "../../views/buttons/Button";
import Button from "../../views/buttons/Button";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";

import { toast } from "react-toastify";

import cmApi from "../../API/cmApi";
import beApi from "../../API/beApi";
import Loader from "../../views/loader/Loader";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

interface IPropertySoldout {
  onClose: () => void;
  selectRoomType_id: string;
  getInventory: () => void;
}

const PropertySoldout: React.FC<IPropertySoldout> = ({
  onClose,
  selectRoomType_id,
  getInventory,
}) => {
  const [loader, setLoader] = useState(false);
  const [displayloader, setDisplayLoader] = useState(false);
  const [blockProperty, setBlockProperty] = useState<any>(true);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [BeRes, setBeRes] = useState<any>({});
  const [CmRes, setCmRes] = useState<any>({});
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const listInnerRef: any = useRef();

  //   const closePropertySlider = () => {
  //     setOpenSlidePropertySoldout(false);
  //     setStartDate(null);
  //     setEndDate(null);
  //   };

  //Endpoints Import
  const { beBlockProperty,
    cmBlockSpecificProperty,
    beUnblockProperty,
    cmUnblockSpecificProperty } = endPoints.INVENTORY
  // --------------------------------

  const block_mode = () => {
    setBlockProperty(!blockProperty);
    setStartDate(null);
    setEndDate(null);
    setBeRes(null);
    setCmRes(null);
  };

  const unblock_mode = () => {
    setBlockProperty(!blockProperty);
    setStartDate(null);
    setEndDate(null);
    setBeRes(null);
    setCmRes(null);
  };

  const propertyBlockBe = async () => {
    try {
      let resbe = await beApi.post(
        beBlockProperty,
        {
          hotel_id: current_property.hotel_id,
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (resbe.status === 200) {
        setBeRes(resbe.data);
        setLoader(false);
        getInventory();
        setDisplayLoader(false);
      }
    }
    catch (error) {
      toast("Some Thing Went Wrong", {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      setDisplayLoader(false);
    }
  };

  const propertyBlockCm = async () => {
    try {
      let rescm = await cmApi.post(
        cmBlockSpecificProperty,
        {
          hotel_id: current_property.hotel_id,
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
          user_id: admin_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (rescm.status === 200) {
        setCmRes(rescm.data);
        setLoader(false);
        getInventory();
        setDisplayLoader(false);
      }
    }
    catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      setDisplayLoader(false);
    }
  };

  const propertyUnblockBe = async () => {
    try {
      let resbe = await beApi.post(
        beUnblockProperty,
        {
          hotel_id: current_property.hotel_id,
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (resbe.status === 200) {
        setBeRes(resbe.data);
        setLoader(false);
        getInventory();
        setDisplayLoader(false);
      }
    }
    catch (error) {
      toast("Some Thing Went Wrong", {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      setDisplayLoader(false);
    }
  };

  const propertyUnblockCm = async () => {
    try {
      let rescm = await cmApi.post(
        cmUnblockSpecificProperty,
        {
          hotel_id: current_property.hotel_id,
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
          user_id: admin_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (rescm.status === 200) {
        setCmRes(rescm.data);
        setLoader(false);
        getInventory();
        setDisplayLoader(false);
      }
    }
    catch (error) {
      toast("Some Thing Went Wrong", {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      setDisplayLoader(false);
    }
  };

  const saveHandler = () => {
    setLoader(true);
    setDisplayLoader(true);
    const dateDuration = endDate.diff(startDate, "days") + 1;

    if (dateDuration <= 7) {
      if (blockProperty) {
        propertyBlockBe();
        propertyBlockCm();
      }
      else {
        propertyUnblockBe();
        propertyUnblockCm();
      }
    }

    else {
      toast("Date should not be greater than 7 days", {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      setDisplayLoader(false);
    }
  };

  const bekeys = BeRes && Object?.keys(BeRes);
  const cmkeys = CmRes && Object?.keys(CmRes);
  const allResKey = bekeys && cmkeys ? [...bekeys, ...cmkeys] : null;

  const uniqResKeys = allResKey && [...new Set(allResKey)];
  const updatBlcResArray = uniqResKeys?.map((x) => {
    let value = [BeRes[x]];
    const arr = CmRes[x];
    if (value !== undefined && arr !== undefined) {
      value = [...value, ...arr];
    }
    return { key: x, value };
  });

  // window.onscroll = function (ev) {
  //   if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
  //     onClose();
  //   }
  // };
  // const handleScroll = (e:React.UIEvent<HTMLDivElement, UIEvent>) => {
  //   const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (bottom) { ... }
  // }

  // const onScroll = (e: any) => {
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       // TO SOMETHING HERE
  //       alert("Reached bottom");
  //     }
  //   }
  // };

  // const el = document.querySelector(".your-element");
  // const atBottom =// Math.ceil(el.scrollTop + el.offsetHeight) === el.scrollHeight;





  return (
    <div
      // onScroll={onScroll}
      ref={listInnerRef}
      style={{ overflowY: "auto", height: "700px" }}
    >
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Property Soldout</div>
      </div>

      <div className="height-64px"></div>
      <div>
        <div className="inventory__update__wrapper">
          <div className="div__select__inputs__wrapper">
            <div
              className={
                blockProperty
                  ? "me-4 select__input__div__active"
                  : "me-4 select__input__div"
              }
              onClick={() => block_mode()}
            >
              {blockProperty ? <i className="bi bi-check-lg"></i> : null}
              Block
            </div>
            <div
              className={
                !blockProperty
                  ? "me-4 select__input__div__active"
                  : "me-4 select__input__div"
              }
              onClick={() => unblock_mode()}
            >
              {!blockProperty ? <i className="bi bi-check-lg"></i> : null}
              Unblock
            </div>
          </div>
          <div className="standard-page--label mb-4 ">Select Date Range</div>

          <div
            className="inventory__select__date__wrapper my-4 pro-sold"
            onClick={() => setFocusedInput("startDate")}
          >
            <div className="date__picker__icon">
              <i className="bi bi-calendar"></i>
            </div>

            <div className="date__label">
              {!startDate && !endDate
                ? "Select Date Range"
                : (startDate ? moment(startDate).format("DD MMM YYYY") : "") +
                " - " +
                (endDate ? moment(endDate).format("DD MMM YYYY") : "")}
            </div>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              focusedInput={focusedInput}
              preventPastDates={true}
              setFocusedInput={setFocusedInput}
            />
          </div>
        </div>
        <div>
          {displayloader ? (
            <Loader className="display-loader" />
          ) : (
            updatBlcResArray?.map((item, idx) => {
              return (
                <div key={idx} className=" row blck-unblck-res">
                  <div className="col-4 res-roomtype">
                    {item?.value[0]?.room_type?.toUpperCase()}
                  </div>
                  <div className=" row res-card-container">
                    {item?.value?.map((itm, index) => {
                      return (
                        <div
                          className="col- channel-type-container"
                          key={index}
                        >
                          <div>
                            <img
                              src={itm?.channel_logo}
                              alt="Channnel Image"
                              className="res-channel"
                            />
                          </div>

                          {/* <div key={index} className="res-channel">
                        {itm?.channel_name}
                      </div> */}
                          <div
                            className={`res-type ${itm?.status === 1
                              ? "sucess"
                              : itm?.status === 0
                                ? "failed"
                                : itm?.status === -1
                                  ? "partial-sucess"
                                  : ""
                              }`}
                          >
                            <i
                              className="bi bi-check"
                              style={{ color: "white" }}
                            ></i>
                          </div>
                          {/* <div className={`res-type ${itm?.status===0 ? 'failed':'sucess'}`}>{itm?.response_msg}</div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })
          )}
        </div>



        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3" handleClick={onClose}>
              Cancel
            </Button>
            {loader ?
              <InventoryBtn>
                Save
                <span
                  className="spinner-border spinner-border-sm pd-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              </InventoryBtn>
              :
              <InventoryBtn
                handleClick={() => {
                  saveHandler();
                }}
              >
                Save
              </InventoryBtn>
            }
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
      </div>
    </div>
  );
};

export default PropertySoldout;
