import { SET_CURRENT_PAYMENT_GATEWAY_DETAILS, SHOW_SETUP_PAYMENT_SLIDER, HIDE_SETUP_PAYMENT_SLIDER, SET_NEW_PAYMENT_MODE_ADDED, SHOW_ADD_PAYMENT_SLIDER, HIDE_ADD_PAYMENT_SLIDER } from "../actionTypes";

const initialState = { data: {}, isOpenSlider_setup: false, value: "", isOpenSlider_add: false }

const PaymentGatewayReducer = (state = initialState, action: any) => {
    if (action.type === SET_CURRENT_PAYMENT_GATEWAY_DETAILS) {
        return {
            ...state,
            data: { ...action.payload }
        }
    }

    else if (action.type === SHOW_ADD_PAYMENT_SLIDER) {
        return {
            ...state,
            isOpenSlider_add: true
        }
    }
    else if (action.type === HIDE_ADD_PAYMENT_SLIDER) {
        return {
            ...state,
            isOpenSlider_add: false
        }
    }
    else if (action.type === SHOW_SETUP_PAYMENT_SLIDER) {
        return {
            ...state,
            isOpenSlider_setup: true
        }
    }
    else if (action.type === HIDE_SETUP_PAYMENT_SLIDER) {
        return {
            ...state,
            isOpenSlider_setup: false
        }
    }

    else if (action.type === SET_NEW_PAYMENT_MODE_ADDED) {
        return {
            ...state,
            value: action.payload
        }
    }

    else return state

}

export default PaymentGatewayReducer