import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Button from "../../views/buttons/Button";
import endPoints from "../../API/endPoints";
import cmApi from "../../API/cmApi";
import Loader from "../../views/loader/Loader";

interface ISoldoutDatesSliderProps {
    onClose: () => void;
}

const SoldoutDates: React.FC<ISoldoutDatesSliderProps> = ({
    onClose,
}) => {

    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );

    const [soldoutDatesData, setSoldoutDatesData] = useState<any[]>([]);

    const [noDataAvailableMessage, setNoDataAvailableMessage] = useState<any>('');

    const [loaderFetch, setLoaderFetch] = useState<boolean>(false);

    //Endpoints Import
    const { getSoldoutDates } = endPoints.INVENTORY
    // --------------------------------


    useEffect(() => {

        fetchSoldoutDates();

    }, [current_property])

    const fetchSoldoutDates = async () => {
        setLoaderFetch(true);

        try {

            let get_all_soldout_dates = await cmApi.get(`${getSoldoutDates}/${current_property.hotel_id}`)

            if (get_all_soldout_dates.data.status === 1) {
                setSoldoutDatesData(get_all_soldout_dates.data.data);
                setLoaderFetch(false);
            }
            else {
                setSoldoutDatesData([]);
                setNoDataAvailableMessage(get_all_soldout_dates.data.message)
                setLoaderFetch(false);
            }

        }
        catch (error: any) {
            setLoaderFetch(false);
        }
    }

    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">
                    View Soldout Dates
                </div>
            </div>
            <div className="height-64px"></div>


            {loaderFetch ? (
                <div className="loader-setup">
                    <Loader />
                </div>
            ) : (<div className="inventory__update__wrapper">

                {soldoutDatesData?.map((items: any, id: any) => {
                    return (
                        <React.Fragment key={id}>
                            <div className="standard-page--label mb-2">{items.month}</div>
                            <div className="sold__out__dates__wrapper mb-3">
                                <div className="soldout__dates">
                                    {
                                        items.date?.map((dates_value: any, i: any) => {
                                            return (
                                                <div className="soldout__dates__list mb-2" key={i}>{dates_value}</div>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </React.Fragment>
                    )
                })}



                {noDataAvailableMessage && <div className="table">
                    <div className="empty__room__div mt-5">
                        <i className="bi bi-x-circle mb-3"></i>
                        <div>{noDataAvailableMessage}</div>
                    </div>
                </div>}
            </div>)}

            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>
            <div className="side__slider__panel__btn">
                <div className="__btn__wrapper mt-3">
                    <Button className="cancel__btn me-3" handleClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </>
    );
};

export default SoldoutDates;
