import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../../../../views/buttons/Button";
import Card from "../../../../views/cardwrapper/Card";
import Img from "../../../../assets/img/hotel-image.png";
import { toast } from "react-toastify";
import { dispatch } from "d3";
import { useDispatch, useSelector } from "react-redux";
import kernelApi from "../../../../API/kernelApi";
import {
  ShowConfirmationPrompt,
  CancelConfirmationPrompt,
  DisableSwitchPropertyScreen,
} from "../../../../redux/actions/PromptAction";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router";
import beApi from "../../../../API/beApi";
import Loader from "../../../../views/loader/Loader";
import { CATCH_MESSAGE, Logout } from "../../../../UtilityFunctions";
import endPoints from "../../../../API/endPoints";
import { nanoid } from "@reduxjs/toolkit";

const Banner = () => {
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );
  // private readonly inputLogo : RefObject<HTMLInputElement>;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputLogo: RefObject<HTMLInputElement> = React.createRef();
  const inputBanner: RefObject<HTMLInputElement> = React.createRef();

  const [loader, setLoader] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>();
  const [banner, setBanner] = useState<any>();
  const [logoBannerImages, setLogoBannerImages] = useState<any[]>([]);
  const [newImages, setNewImages] = useState<any[]>([]);
  const [access, setAccess] = useState(true);
  const [uploadedImage, setUploadedImage] = useState('');
  const [fetchedBannersId, setFetchedBannersId] = useState([])

  // const uploadImageFile = () => {
  //   document.getElementById("upload-images")?.click();
  // };

  //Endpoints Import
  const { getLogoBanners, uploadLogoBanners, imageSend, deleteImageApi } = endPoints.MANAGECHANNEL
  // --------------------------------

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  const getSrcFromImage = (image: any, index: number) => {
    try {
      return URL.createObjectURL(image);
    }
    catch (error) {
      // let images = [...newImages];
      // images.splice(index, 1);
      // setNewImages(images);
    }
  };

  // const getIMG = async () => {
  //   try {
  //     const resp = await kernelApi.get(`/company_profile/banner/${company_id}`)
  //     if (resp.data.status === 1) {
  //     }
  //   }
  //   catch { }
  // }

  const onImageSelect = (event: any) => {
    let Images = [...newImages];
    let length = 5 - (logoBannerImages?.length + newImages.length);

    for (
      let file = 0;
      file < event.target.files.length && file < length;
      file++
    ) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        // file size is less than 1 MB
        Images.push(image);
      } else {
        toast("Image size should be less than 1 MB", {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    // setNewImages([...newImages, ...Images.slice(0, length)]);
    setNewImages(Images);

  };

  // Get Logo and Banner Images
  const getLogoBannerImages = async () => {
    setLoader(true);
    try {
      // let res = await kernelApi.get(
      //   // "/hotel_admin/get_exterior_images/" + current_property.hotel_id
      //   `/company_profile/get-logo/${company_id}`
      // );
      let res = await kernelApi.get(`/company_profile/banner/${company_id}`)
      if (res.data.status === 1) {
        setLogoBannerImages(res.data.data);
        const fetchedBanners = res.data.data.map(obj => {
          return obj.image_id
        })
        setFetchedBannersId(fetchedBanners)
        // setNewImages([...newImages])
        setNewImages([])
        setLoader(false);
      } else {
        setLogoBannerImages([]);
        setLoader(false);
      }
    } catch (error: any) {
      setLogoBannerImages([]);
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  useEffect(() => {
    getLogoBannerImages();
  }, [current_property, uploadedImage]);

  // Upload Image Handler
  const uploadLogoBannerImages = async () => {
    if (newImages.length > 0) {
      let payload = new FormData();
      payload.append("hotel_id", current_property.hotel_id);
      newImages.forEach((image: any) => {
        payload.append("uploadFile[]", image);
      });
      try {
        setLoader(true);
        let response = await kernelApi.post(
          `${uploadLogoBanners}/${current_property.hotel_id}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (response.data.status === 1) {
          setUploadedImage(nanoid());
          setTimeout(getLogoBannerImages, 0); // sohuld be delayed
          const { message, image_ids, home_url } = response.data;
          toast(message, {
            hideProgressBar: true,
            type: "success",
          });
          setNewImages([]);
          const logoId = '';
          const bannerId = image_ids;
          imageIdSendHandler(logoId, bannerId, home_url);
          setLoader(false);
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          setLoader(false);
        }
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    }
  };

  // Api Call For Image Id Send
  const imageIdSendHandler = async (
    logoId: any,
    bannerId: any,
    home_url: any
  ) => {
    setLoader(true);
    const allBannersId = [...fetchedBannersId, ...bannerId]

    const obj = {
      home_url: home_url,
      // logo: logoId.toString(), 
      logo: '',
      // banner: bannerId.toString(),
      banner: allBannersId.toString(),
    };
    const payload = new FormData();
    const blob = new Blob([JSON.stringify(obj, null, 2)], {
      type: "application/json",
    });
    payload.append("data", blob);
    try {
      let req = await kernelApi.post(
        `${imageSend}/${company_id}`,
        obj,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (req.data.status === 1) {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const deleteImage = (image_id: number) => {
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to delete this image?",
        "DELETE",
        async () => {
          setLoader(true);
          try {
            let res = await kernelApi.post(`${deleteImageApi}`, {
              imageId: image_id,
              hotel_id: current_property.hotel_id,
              type: "exterior_image",
            });
            if (res.data.status === 1) {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "success",
              });
              getLogoBannerImages();
              setLoader(false);
            } else {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "error",
              });
              setLoader(false);
            }
          } catch (error: any) {
            toast(CATCH_MESSAGE, {
              hideProgressBar: true,
              type: "error",
            });
            setLoader(false);
            if (error.response.status === 400) {
              Logout();
            }
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  const logoUploadHandler = () => {
    const { current } = inputLogo;
    (current || { click: () => { } }).click();
  };

  const bannerUploadHandler = useCallback(() => {
    if (logoBannerImages?.length === 5 || logoBannerImages?.length + newImages.length === 5) {
      toast("Maximum number of banners can be 5.", {
        hideProgressBar: true,
        type: "error",
      });
    }
    else {
      const { current } = inputBanner;
      (current || { click: () => { } }).click();
    }
  }, [inputBanner]);

  return (
    <>
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <>
          <Card className="mt-4">
            <div>
              <div className="property__setup__room__types">
                <input
                  id="upload-images"
                  ref={inputBanner}
                  type="file"
                  accept="image/png, image/jpeg"
                  // className="d-none"
                  style={{ visibility: "hidden" }}
                  onChange={onImageSelect}
                  multiple
                />
                {
                  logoBannerImages?.length > 0 ?
                    <>
                      <div className="manage__room__type__wrapper">
                        {logoBannerImages?.map((image, index) => {
                          return (
                            <div
                              className="manage__room__type__card"
                              key={image.image_id}
                            >
                              <div className="manage__room__type ms-0">
                                <div className="image--container">
                                  <img
                                    className="property--image--radius"
                                    src={image.image_name}
                                    alt={image.image_id}
                                  />
                                  <div className="overlay overlay-border-radius">
                                    <div
                                      className="button__container"
                                      onClick={() => {
                                        deleteImage(image.image_id);
                                      }}
                                    >
                                      <div className="edit-button">
                                        <i className="bi bi-trash"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                    :
                    <></>
                }
                {
                  newImages?.length > 0 ?
                    <>
                      <div className="manage__room__type__wrapper">
                        {newImages?.map((image, index) => {
                          return (
                            <div className="manage__room__type__card" key={index}>
                              <div className="manage__room__type ms-0">
                                <div className="image--container">
                                  <img
                                    className="property--image--radius"
                                    src={getSrcFromImage(image, index)}
                                    alt={""}
                                  />
                                  <div className="overlay overlay-border-radius">
                                    <div
                                      className="button__container"
                                      onClick={() => {
                                        let images = [...newImages];
                                        images.splice(index, 1);
                                        setNewImages(images);
                                      }}
                                    >
                                      <div className="edit-button">
                                        <i className="bi bi-trash"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                    :
                    <></>
                }
                {
                  newImages.length === 0 && logoBannerImages.length === 0 ?
                    <div className="empty__room__div mt-5">
                      <i className="bi bi-x-circle mb-3"></i>
                      <div>You don’t have any images</div>
                    </div>
                    : <></>
                }
              </div>
            </div>

            <div className="drg-upload-btn">
              <Button
                icon="bi bi-upload"
                handleClick={bannerUploadHandler}
                isAccessible={access}
              >
                Browse
              </Button>
            </div>
          </Card>
          <div className="save-btn mt-4 pt-2">
            <Button
              handleClick={() => {
                uploadLogoBannerImages();
              }}
              loader={loader}
              isAccessible={access}
            >
              Upload
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default Banner;

