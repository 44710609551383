import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import {
  CATCH_MESSAGE,
  getImage,
  Logout,
  UpdateSidebar,
} from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import Card from "../../../../views/cardwrapper/Card";
import Img from "../../../../assets/img/hotel-image.png";
import beApi from "../../../../API/beApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../../redux/store";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../../redux/actions/PromptAction";
import kernelApi from "../../../../API/kernelApi";
import Loader from "../../../../views/loader/Loader";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import endPoints from "../../../../API/endPoints";

const PromotionSlider = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [promotionalImages, setpromotionalImages] = useState<any[]>([]);
  const [newImages, setNewImages] = useState<any[]>([]);
  const [access, setAccess] = useState(true);
  const [nodataMessage, setNoDataMessage] = useState<string>("");

  UpdateSidebar("Manage Channels", "Booking Engine");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Endpoints Import
  const { getPromotionalSliderImages, uploadPromotionalImages, deletePromotionalImages } = endPoints.MANAGECHANNEL
  // --------------------------------

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  const uploadImageFile = () => {
    document.getElementById("upload-images")?.click();
  };

  // For Preview
  const getSrcFromImage = (image: any, index: number) => {
    try {
      return URL.createObjectURL(image);
    } catch (error) {
      let images = [...newImages];
      images.splice(index, 1);
      setNewImages(images);
    }
  };

  // Image Capturing
  const onImageSelect = (event: any) => {
    let Images = [...newImages];
    // let length = 10 - currentRoomTypeDetails.image.length;

    for (
      let file = 0;
      file < event.target.files.length;
      //  &&file < length

      file++
    ) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        // file size is less than 1 MB
        Images.push(image);
      } else {
        toast("Image size should be less than 1 MB", {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    setNewImages(Images);
  };

  // Get PrormotionalSlider Details
  const getPrormotionalSliderImage = async () => {
    setLoader(true);
    try {
      let promotionaSliderRes = await kernelApi.get(
        `${getPromotionalSliderImages}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (promotionaSliderRes?.data?.status === 1) {
        setpromotionalImages(
          promotionaSliderRes?.data?.notification_slider_details?.images ?? []
        );
        setLoader(false);
      } else {
        setpromotionalImages([]);
        setNoDataMessage(promotionaSliderRes?.data?.message);
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };
  useEffect(() => {
    getPrormotionalSliderImage();
  }, []);

  // Save Promotional Images
  const savePromotionalImages = async () => {
    setLoader(true);
    if (newImages.length > 0) {
      let payload = new FormData();
      payload.append("hotel_id", current_property.hotel_id);
      // payload.append(
      //   "existing_images_ids",
      //   currentRoomTypeDetails.room_type_images_ids.join(",")
      // );
      newImages.forEach((image: any) => {
        payload.append("uploadFile[]", image);
      });
      try {
        let response = await kernelApi.post(
          `${uploadPromotionalImages}/${current_property.hotel_id}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (response.data.status === 1) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          setNewImages([]);
          getPrormotionalSliderImage();
          setLoader(false);
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          setLoader(false);
        }
        // setOpenSlideEditRoomType(false);
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    }
  };

  // const uploadPromotionalImages = async (event: any) => {
  //   try {
  //     let payload = new FormData();
  //     let images = event.target.files;
  //     let image_count = 0;

  //     if (images.length > 5) {
  //       toast.error("Can not upload more than 5 images at a time");
  //     } else {
  //       Array.from(images).forEach((image: any) => {
  //         if (image.size < 1024 * 1024) {
  //           payload.append("uploadFile[]", image);
  //           image_count++;
  //         } else {
  //           toast.error("Image size should be less than 1 MB");
  //         }
  //       });

  //       if (image_count > 0) {
  //         payload.append("hotel_id", current_property.hotel_id);

  //         let res = await beApi.post(
  //           `/upload-be-notification-slider-images/${current_property.hotel_id}`,
  //           payload
  //         );
  //         if (res.data.status === 1) {
  //           // getPropertyImages();
  //           toast.success(res.data.message);
  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   }
  // };

  // Delete Image
  const deleteImage = (image_id: number) => {
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to delete this image?",
        "DELETE",
        async () => {
          setLoader(true);
          try {
            let res = await kernelApi.post(
              deletePromotionalImages,
              {
                property_image_id: image_id,
                hotel_id: current_property.hotel_id,
              }
            );
            if (res.data.status === 1) {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "success",
              });
              getPrormotionalSliderImage();
              setLoader(false);
            } else {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "error",
              });
              getPrormotionalSliderImage();
              setLoader(false);
            }
          } catch (error: any) {
            toast(CATCH_MESSAGE, {
              hideProgressBar: true,
              type: "error",
            });
            if (error.response.status === 400) {
              Logout();
            }
            setLoader(false);
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  // Update Promotional Images
  // const updatePromotion = async () => {
  //   try {
  //     let updateRes = await beApi.post(
  //       "/insert-update-be-notification-slider-images",
  //       {}
  //     );
  //   } catch (error: any) {
  //     toast(CATCH_MESSAGE, {
  //       hideProgressBar: true,
  //       type: "error",
  //     });
  //     if (error.response.status === 400) {
  //       Logout();
  //     }
  //   }
  // };

  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Promotional Slider" },
          ]}
        />
        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>

      <div className="hr--line"></div>

      {/* --------------------------- */}

      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <>
          <Card className="mt-4">
            <div className="mt-4">
              <div className="property__setup__room__types">
                {newImages?.length > 0 && (
                  <div className="manage__room__type__wrapper">
                    {newImages?.map((image, index) => {
                      return (
                        <div className="manage__room__type__card" key={index}>
                          <div className="manage__room__type ms-0">
                            <div className="image--container">
                              <img
                                className="property--image--radius"
                                src={getSrcFromImage(image, index)}
                                alt="Promotional Images"
                              />
                              <div className="overlay overlay-border-radius">
                                <div
                                  className="button__container"
                                  onClick={() => {
                                    let images = [...newImages];
                                    images.splice(index, 1);
                                    setNewImages(images);
                                  }}
                                >
                                  <div className="edit-button">
                                    <i className="bi bi-trash"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {promotionalImages?.length > 0 && (
                  <div className="manage__room__type__wrapper">
                    {promotionalImages?.map((image, index) => {
                      return (
                        <div
                          className="manage__room__type__card"
                          key={image?.image_id}
                        >
                          <div className="manage__room__type ms-0">
                            <div className="image--container">
                              <img
                                className="property--image--radius"
                                src={getImage(image?.image_name)}
                                alt="Promotional Images"
                              />
                              {access && (
                                <div className="overlay overlay-border-radius">
                                  <div
                                    className="button__container"
                                    onClick={() => {
                                      deleteImage(image?.image_id);
                                    }}
                                  >
                                    <div className="edit-button">
                                      <i className="bi bi-trash"></i>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {newImages?.length === 0 && promotionalImages?.length === 0 && (
                  <div className="empty__room__div mt-5">
                    <i className="bi bi-x-circle mb-3"></i>
                    <div>{nodataMessage}</div>
                  </div>
                )}

                <input
                  id="upload-images"
                  type="file"
                  accept="image/png, image/jpeg"
                  className="d-none"
                  onChange={(e) => {
                    onImageSelect(e);
                  }}
                  multiple
                />
              </div>
            </div>
            <div className="drg-upload-btn">
              <Button
                icon="bi bi-upload"
                handleClick={() => {
                  uploadImageFile();
                }}
                isAccessible={access}
              >
                Drag or Upload
              </Button>
            </div>
          </Card>
          <div className="save-btn">
            <Button
              handleClick={() => {
                savePromotionalImages();
              }}
              loader={loader}
              isAccessible={access}
            >
              Save
            </Button>
          </div>
          <div className="col-5 bkng-engn-logo">
            <div className="imp-notes">Important Notes</div>
            <div className="mt-4">
              <p className="img-condn">
                1. Logo dimensions should not be exceed more than 500 &#x2715;
                500 pixels{" "}
              </p>
              <p className="img-condn">
                2. Logo size should not be exceed more than 1MB
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PromotionSlider;
