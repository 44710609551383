import moment from "moment";
import React, { useState } from "react";
import InputTextField from "../../../../views/inputtextfield/InputTextField";

interface IViewPaymentProps {
  onClose: () => void;
  paymentDetails: any;
}

const ViewPayment: React.FC<IViewPaymentProps> = ({
  onClose,
  paymentDetails,
}) => {
  const noOfDays = () => {
    const checkIn = moment(paymentDetails?.check_in, "YYYY-MM-DD");
    const checkOut = moment(paymentDetails?.check_out, "YYYY-MM-DD");
    let days = moment.duration(checkOut.diff(checkIn)).asDays();
    return `${days}`;
  };

  // useState
  const [comment, setComment] = useState<any>(paymentDetails?.comment);

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Quick Payment</div>
      </div>
      <div className="height-64px"></div>
      <div className="page-heading-label mt-4">Booking Detail</div>

      {paymentDetails ? (
        <div className="payment__details__guest__wrapper">
          <div className="guest__detail__card">
            <div className="guest__name__wrapper">
              <div className="guest__name">{paymentDetails?.name}</div>
              <div className="booking__channel__icon__quick__payment">
                <img
                  className="booking__source__image"
                  src={paymentDetails?.bookingjini_icon}
                  alt="Booking Source Icon"
                />
              </div>
            </div>

            <div className="guest__booking__date mt-2">
              Booking Date -{" "}
              {moment(paymentDetails?.created_date).format(`DD MMM YYYY`)}
            </div>
            <div className="guest__email__contact__details mt-2">
              <div className="guest__number">
                <i className="bi bi-telephone"></i> {paymentDetails?.phone}{" "}
              </div>
              <div className="guest__email__id">
                <i className="bi bi-envelope"></i> {paymentDetails?.email}
              </div>
            </div>

            <div className="checkin__checkout__wrapper mt-3">
              <div className="checkinout__content">
                <div className="check__inout__details check-in-border">
                  <div className="check__inout__icon check-in-color">
                    <i className="bi bi-arrow-down-right-circle"></i>
                  </div>
                  <div className="check__inout__date">
                    <div className="check__inout check-in-color">
                      {paymentDetails?.check_in}
                    </div>
                    <div className="check__inout__label">Check-in</div>
                  </div>
                </div>
              </div>

              <div className="number__of__nights__wrapper">
                <div className="night__icon">
                  <i className="bi bi-moon-fill"></i>
                </div>
                <div className="night__number">
                  {noOfDays()}
                  {/* {moment(paymentDetails?.check_in).diff(
                    paymentDetails?.check_out
                  )} */}
                </div>
              </div>

              <div className="checkinout__content">
                <div className="check__inout__details check-out-border">
                  <div className="check__inout__icon check-out-color">
                    <i className="bi bi-arrow-up-left-circle"></i>
                  </div>
                  <div className="check__inout__date">
                    <div className="check__inout check-out-color">
                      {paymentDetails?.check_out}
                    </div>
                    <div className="check__inout__label">Check-out</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="standard-page--label my-3">Room Details</div>

          {/* {paymentDetails?.map((room: any, i: number) => {
              return ( */}
          {/* // <div key={i} className="booking__room__detail__wrapper mb-4"> */}
          <div className="booking__room__detail__wrapper mb-4">
            <div className="booking__room__image">
              <img src={paymentDetails.image} alt={paymentDetails.room_type} />
            </div>
            <div className="booking__room__details">
              <div className="room__type__meal__plan mb-3">
                <div className="room__type__name">
                  <i className="bi bi-door-open me-2"></i>
                  {paymentDetails.room_type}
                </div>
              </div>
              <div className="room__type__meal__plan mb-3">
                <div className="room__type__meal__plan">
                  <i className="bi bi-cup-straw"></i>
                  {`${paymentDetails.plan_type}`}
                </div>
              </div>
              {/* <div className="booking__room__type__rate mb-3">
                <div className="room__type__meal__plan">
                  <i className="bi bi-cup-straw"></i> {paymentDetails.plan_type}
                </div>
              </div> */}
              <div className="booking__room__nights mb-3">
                <i className="bi bi-question-circle me-2"></i> Rooms x{" "}
                {paymentDetails.no_of_rooms}
              </div>
            </div>
          </div>
          {/* );
            })} */}

          <div className="payment__details__price__card">
            <div className="price__label">Total Price</div>
            <div className="price__value">
              &#x20B9; {paymentDetails?.amount}
            </div>
          </div>

          <div className="mt-4">
            <InputTextField
              label="Remarks"
              value={comment}
              handleChange={(e: any) => setComment(e.target.value)}
              disabled
              multiline
            />
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          ></div>
          {/* <div className="side__slider__panel__btn">
              <div className="__btn__wrapper booking__btn__alignment">
                {booking_details?.is_modify !== 0 && (
                  <Button
                    handleClick={onModifyBookingClick}
                    className="modify__bookinhg__btn"
                    isAccessible={access}
                  >
                    Modify Booking
                  </Button>
                )}
                {/* {booking_details?.is_checkin !== 0 && */}
          {/*  <Button handleClick={onCheckinClick} className="checkin__bookinhg__btn" isAccessible={access}>
                    Check-in
                  </Button>
                } */}
          {/* {booking_details?.is_cancel !== 0 && (
                  <Button
                    handleClick={onCancelBookingClick}
                    className="cancel__bookinhg__btn"
                    isAccessible={access}
                  >
                    Cancel booking
                  </Button>
                )}
              </div>
            </div> */}
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>Booking details not found</div>
        </div>
      )}
    </>
  );
};

export default ViewPayment;
