import { LOGIN_CREDENTAILS, LOGIN_SUCCESS, SAVE_SIGNUP_INFO, SAVE_SUBSCRPTION_PLAN, GET_CLIENT_SECTION_DATA, GET_CHARGEBEE_SUBSCRIPTION_ID, GET_SUBSCRIPTION_DAYS_LEFT, SINGLE_SIGN_ON } from "../actionTypes";

const initialState = {
  admin_id: null,
  auth_token: null,
  company_id: null,
  email: null,
  password: null,
  full_name: null,
  subscription_customer_id: null,
  client_section: null,
  chargebee_subscription_id: null,
  subscription_days_left: null,
  single_sign_on_status: null,
};

const LoginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        admin_id: action.payload.admin_id,
        auth_token: action.payload.auth_token,
        company_id: action.payload.company_id,
        full_name: action.payload.full_name,
        chargebee_subscription_id: action.payload.chargebee_subscription_id
      };
    case LOGIN_CREDENTAILS:
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
      };
    case SAVE_SIGNUP_INFO:
      return {
        ...state,
        subscription_customer_id: action.payload
      }
    case GET_CLIENT_SECTION_DATA:
      return {
        ...state,
        client_section: action.payload
      }
    case GET_CHARGEBEE_SUBSCRIPTION_ID:
      return {
        ...state,
        chargebee_subscription_id: action.payload
      }
    case GET_SUBSCRIPTION_DAYS_LEFT:
      return {
        ...state,
        subscription_days_left: action.payload
      }
    case SINGLE_SIGN_ON:
      return {
        ...state,
        single_sign_on_status: action.payload
      }
    default:
      return state;
  }
};

export default LoginReducer;
