import React, { RefObject, useCallback, useEffect, useState } from "react";
import Button from "../../../../views/buttons/Button";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import InputTextField from "../../../../views/inputtextfield/InputTextField";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import beApi from "../../../../API/beApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../../redux/store";
import moment from "moment";
import kernelApi from "../../../../API/kernelApi";
// import of DatePicker";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import img from "../../../../assets/img/hotel-image.png";
import { CATCH_MESSAGE, Logout } from "../../../../UtilityFunctions";
import { nanoid } from "@reduxjs/toolkit";

export interface IAddPackageSlider {
  onClose: () => void;
  getPackageData: () => void;
  allPackage: any[];
  pckgId: any;
}

const AddPackage: React.FC<IAddPackageSlider> = ({
  onClose,
  getPackageData,
  allPackage,
}) => {
  // Data From Redux
  const { admin_id, auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property, property_data } = useSelector(
    (state: RootState) => state.properties
  );
  const { allRoomType } = useSelector(
    (state: RootState) => state.manage_channels
  );

  const steps = ["Package Details", "Package Rates"];

  // States
  const [newImagesMapProp, setNewImagesMap] = useState<any>([])

  const [tab, setTab] = useState(0);

  const [loader, setLoader] = useState<boolean>(false);
  const [pkcgName, setPkcgName] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [inclusion, setInclusion] = useState<any>("");
  const [exclusion, setExclusion] = useState<any>("");
  const [price, setPrice] = useState<any>("");
  const [discount, setDiscount] = useState<any>("");
  const [nights, setNights] = useState<any>("");
  const [adult, setAdult] = useState<any>("");
  const [child, setChild] = useState<any>("");
  const [extraPrson, setExtraPrson] = useState<any>("");
  const [extraPrsonPrice, setExtraPrsonPrice] = useState<any>("");
  const [xtraChld, setXtraChld] = useState<any>("");
  const [xtraChildPrice, setXtraChildPrice] = useState<any>("");
  const [allRoomOptn, setAllRoomOptn] = useState<any>({
    all_rooms: [],
    room_ids: [],
  });
  const [selectedRoomtype, setSelectedRoomtype] = useState<any>("");
  const [fromDate, setFromDate] = useState<any>(moment());
  const [toDate, setToDate] = useState<any>(moment().add(10, "d"));
  const [openFromDate, setFromDateOpen] = useState(false);
  const [openToDate, setToDateOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [singleDate, setSingleDate] = useState<any>([]);
  const [blackOutArray, setBlackOutArray] = useState<any>([]);
  const [newImages, setNewImages] = useState<any[]>([]);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const inputPackage: RefObject<HTMLInputElement> = React.createRef();

  useEffect(() => {
    let allRooms: string[] = [];
    let allRoomsId: any[] = [];
    allRoomType.map((room: any) => {
      allRooms.push(room?.room_type);
      allRoomsId.push(room?.room_type_id);
    });

    setAllRoomOptn({
      all_rooms: allRooms,
      room_ids: allRoomsId,
    });

    // const packageData = allPackage.filter((iteam) => {
    //   return iteam.package_id === pckgId;
    // });
  }, [allRoomType]);

  const getSrcFromImage = (image: any, index: number) => {
    try {
      return URL.createObjectURL(image);
    } catch (error) {
      let images = [...newImages];
      images.splice(index, 1);
      setNewImages(images);
    }
  };

  const onImageSelect = (event: any) => {
    let Images = [...newImages];
    let length = 3;

    for (
      let file = 0;
      file < event.target.files.length && file < length;
      file++
    ) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        // file size is less than 1 MB
        Images.push(image);
      } else {
        toast("Image size should be less than 1 MB", {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    setNewImages(Images.slice(0, length));
  };

  const next = () => {
    setTab((tab + 1) % 5);
  };

  const onNextButton = () => {
    if (selectedRoomtype && pkcgName && newImages.length > 0) {
      next();
    } else {
      toast("Please Enter Package Name", {
        hideProgressBar: true,
        type: "error",
      });
      toast("Please Enter Room Type", {
        hideProgressBar: true,
        type: "error",
      });
      toast("Please Select at least one Image", {
        hideProgressBar: true,
        type: "error",
      });
    }
    // next();
  };

  // Date Picker Handler
  const onDateChangeFrom = (date: any) => {
    setFromDate(moment(date));
  };
  const onDateChangeTo = (date: any) => {
    setToDate(moment(date));
  };

  const onDateChange = (date: any) => {
    let datesArray = [...singleDate];
    let inv = [...blackOutArray];
    var index = datesArray.findIndex(
      (x) => x.date == moment(date).format("DD-MM-YYYY")
    );
    index === -1 &&
      datesArray.push({ date: moment(date).format("DD-MM-YYYY") });
    setFromDate(moment(date));
    setSingleDate(datesArray);
    inv.push({
      date: moment(date).format("DD-MM-YYYY"),
    });
    setBlackOutArray(inv);
  };

  const RemoveDate = (date: any) => {
    let removeDate = [...singleDate];
    let removeInv = [...blackOutArray];
    setSingleDate(removeDate.filter((Date: any) => Date.date !== date.date));
    setBlackOutArray(removeDate.filter((Date: any) => Date.date !== date.date));
  };

  // Image Upload Handler

  const uploadPackageImages = async () => {
    setLoader(true);
    if (newImages.length > 0) {
      let payload = new FormData();
      payload.append("hotel_id", current_property.hotel_id);
      newImages.forEach((image: any) => {
        payload.append("uploadFile[]", image);
      });
      try {
        let response = await kernelApi.post(
          `/upload/${current_property.hotel_id}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (response.data.status === 1) {
          const { message, image_ids, home_url } = response.data;
          toast(message, {
            hideProgressBar: true,
            type: "success",
          });
          await addPackgSaveHandler(image_ids);
          setNewImages([]);
          setLoader(false);
          onClose();
        } else {
          await addPackgSaveHandler("");
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          setLoader(false);
          onClose();
        }
      } catch (error: any) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
        onClose();
        if (error.response.status === 400) {
          Logout();
        }
      }
    }
  };

  // Package Data Post

  const addPackgSaveHandler = async (imageId: any) => {
    let roomTypeId =
      allRoomOptn.all_rooms[allRoomOptn.room_ids.indexOf(selectedRoomtype)];
    try {
      let postRes = await kernelApi.post(
        "/packages/add",
        {
          hotel_id: current_property.hotel_id ?? "",
          company_id: company_id ?? "",
          room_type_id: roomTypeId ?? "",
          package_name: pkcgName ?? "",
          package_image: imageId.toString() ?? "",
          amount: price ?? "",
          discounted_amount: discount ?? "",
          nights: nights ?? "",
          date_from: fromDate ?? "",
          date_to: toDate ?? "",
          package_description: description ?? "",
          adults: adult ?? "",
          max_child: child ?? "",
          extra_person: extraPrson ?? "",
          extra_person_price: extraPrsonPrice ?? "",
          extra_child: xtraChld ?? "",
          extra_child_price: xtraChildPrice ?? "",
          blackout_dates: blackOutArray ?? [],
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (postRes.data.status === 1) {
        toast(postRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getPackageData();
        setLoader(false);
        onClose();
      } else {
        toast(`${postRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
        onClose();
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      onClose();
    }
  };

  const packageUploadHandler =
    // useCallback(
    (e) => {
      const clickFunc = () => {
        const { current } = inputPackage;
        (current || { click: () => { } }).click();
      }
      const classListsArr = ['imageSelect', 'property--image--radius', 'overlay-border-radius', 'button__container action__btn__container', 'delete-button', 'bi-trash']

      const arr = classListsArr.map(i => {
        if ([...e.target.classList].includes(i)) return true
      })
      arr.includes(true) ? <></> : clickFunc()
    }
  // , [inputPackage]);

  // -------------------------------------------------------------
  const newImagesMap = newImages.length > 0 ?
    newImages?.map((image: any, index: number) => {
      return (
        <div className="package__list__image padding imageSelect" key={nanoid()}>
          <img
            src={getSrcFromImage(image, index)}
            alt=""
            className="property--image--radius"
          />
          {/* ------Overlay------ */}
          <div className="overlay-border-radius overlay">
            <div
              className="button__container action__btn__container"
              onClick={(e) => {
                let images = [...newImages];
                images.splice(index, 1);
                setNewImages(images);
              }}
            >
              <div className="edit-button delete-button">
                <i className="bi bi-trash "></i>
              </div>
            </div>
          </div>
        </div>
      );
    })
    :
    <div
      className="drag__package__image__box"
    // className="add__package__upload__section"
    // onClick={(e) => packageUploadHandler(e)}
    >
      <div className="drag__icon">
        <i className="bi bi-images"></i>
      </div>
      <div className="drag__text__label">
        Drag your photos or click here
        <input
          id="upload-images"
          ref={inputPackage}
          type="file"
          accept="image/png, image/jpeg"
          // className="d-none"
          style={{ visibility: "hidden" }}
          onChange={onImageSelect}
          multiple
        />
      </div>
    </div>

  useEffect(() => {
    setNewImagesMap(newImagesMap)
  }, [newImages])



  return (
    <div>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i
            onClick={() => {
              onClose();
            }}
            className="bi bi-x"
          ></i>
        </div>
        <div className="standard-page--label">Add Package</div>
      </div>

      <div className="height-64px"></div>

      <div className="add__package__wrapper">
        <div className="add__package__progress__stepper">
          {steps.map((step, index) => {
            return (
              <div className="label__progress__wrapper" key={index}>
                {index < tab ? (
                  <div
                    className="progress__status status--completed"
                    onClick={() => setTab(index)}
                  >
                    <i className="bi bi-check2"></i>
                  </div>
                ) : (
                  <div
                    className={`progress__status status--${index === tab ? "in--progress" : "pending"
                      }`}
                  >
                    {index + 1}
                  </div>
                )}
                <div className="progress__status__label__text">{step}</div>
              </div>
            );
          })}
        </div>
        {/* Section For Package Details */}
        {tab === 0 && (
          <>
            <div className="add__package__content__section">
              <div className="add__package__select__room__type mb-5">
                <InputSelectField
                  label="Select Room Type"
                  options={allRoomOptn?.all_rooms}
                  value={selectedRoomtype}
                  values={allRoomOptn?.room_ids}
                  handleChange={(e) => {
                    setSelectedRoomtype(e.target.value);
                  }}
                />
              </div>

              <div className="add__package__name__input mb-3">
                <InputTextField
                  label="Package Name"
                  value={pkcgName}
                  handleChange={(e) => {
                    setPkcgName(e.target.value);
                  }}
                />
              </div>

              <div className="add__package__upload__image mb-3">
                <div className="standard-page--label mb-3">
                  Upload Package Image
                </div>

                <div
                  className="add__package__upload__section"
                  // onClick={packageUploadHandler}
                  onClick={
                    (e) => {
                      packageUploadHandler(e);
                    }}
                >
                  <input
                    id="upload-images"
                    ref={inputPackage}
                    type="file"
                    accept="image/png, image/jpeg"
                    className="d-none"
                    onChange={onImageSelect}
                    multiple
                  />

                  {newImagesMapProp}

                  {
                    // newImages.length > 0 ?
                    // newImages?.map((image: any, index: number) => {
                    // return (
                    //     <div className="package__list__image padding imageSelect" key={nanoid()}>
                    //       <img
                    //         src={getSrcFromImage(image, index)}
                    //         alt=""
                    //         className="property--image--radius"
                    //       />
                    //       {/* ------Overlay------ */}
                    //       <div className="overlay-border-radius overlay">
                    //         <div
                    //           className="button__container action__btn__container"
                    //           onClick={(e) => {
                    //             let images = [...newImages];
                    //             images.splice(index, 1);
                    //             setNewImages(images);
                    //           }}
                    //         >
                    //           <div className="edit-button delete-button">
                    //             <i className="bi bi-trash "></i>
                    //           </div>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   );
                    // })
                    // :
                    // <div
                    //   className="drag__package__image__box"
                    //   onClick={packageUploadHandler}
                    // >
                    //   <div className="drag__icon">
                    //     <i className="bi bi-images"></i>
                    //   </div>
                    //   <div className="drag__text__label">
                    //     Drag your photos or click here
                    //     <input
                    //       id="upload-images"
                    //       ref={inputPackage}
                    //       type="file"
                    //       accept="image/png, image/jpeg"
                    //       // className="d-none"
                    //       style={{ visibility: "hidden" }}
                    //       onChange={onImageSelect}
                    //       multiple
                    //     />
                    //   </div>
                    // </div>
                  }

                </div>
              </div>

              {/* Package Description Editor */}
              <div className="package__description__wrapper mt-5">
                <div className=" standard-page--label mb-3">
                  Package Description
                </div>

                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  config={{
                    removePlugins: [
                      "EasyImage",
                      "ImageUpload",
                      "MediaEmbed",
                      "Table",
                    ],
                  }}
                  onChange={(event: string, editor: any) => {
                    const data = editor.getData();
                    setDescription(data);
                    setCharCount({
                      ...charCount,
                      terms_cond: removeTags(data).length,
                    });
                  }}
                />
              </div>

              {/* Inclusion Editor */}
              <div className="package__description__wrapper mt-5">
                <div className=" standard-page--label mb-3">Inclusion</div>

                <CKEditor
                  editor={ClassicEditor}
                  data={inclusion}
                  config={{
                    removePlugins: [
                      "EasyImage",
                      "ImageUpload",
                      "MediaEmbed",
                      "Table",
                    ],
                  }}
                  onChange={(event: string, editor: any) => {
                    const data = editor.getData();
                    setInclusion(data);
                    setCharCount({
                      ...charCount,
                      terms_cond: removeTags(data).length,
                    });
                  }}
                />
              </div>

              {/* Exclusion Editor */}
              <div className="package__description__wrapper mt-5">
                <div className=" standard-page--label mb-3">Exclusion</div>

                <CKEditor
                  editor={ClassicEditor}
                  data={exclusion}
                  config={{
                    removePlugins: [
                      "EasyImage",
                      "ImageUpload",
                      "MediaEmbed",
                      "Table",
                    ],
                  }}
                  onChange={(event: string, editor: any) => {
                    const data = editor.getData();
                    setExclusion(data);
                    setCharCount({
                      ...charCount,
                      terms_cond: removeTags(data).length,
                    });
                  }}
                />
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="add__package__next__btn">
              <Button
                className="primary-btn"
                handleClick={() => {
                  onNextButton();
                }}
              >
                Next
              </Button>
            </div>
          </>
        )}

        {/* Section For Package Rate */}

        {tab === 1 && (
          <>
            <div className="add__package__content__section">
              <div className="add__price__wrapper">
                <div className="add__price me-3">
                  <InputTextField
                    label="Price"
                    value={price}
                    handleChange={(e) => {
                      setPrice(e.target.value);
                    }}
                  />
                </div>

                <div className="add__siscount">
                  <InputTextField
                    label="Discount Price"
                    value={discount}
                    handleChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* Form Date Picker */}
              <div className="standard-page--label my-4">From Date</div>

              <div className="edit-package-date mb-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    open={openFromDate}
                    onOpen={() => setFromDateOpen(true)}
                    onClose={() => setFromDateOpen(false)}
                    value={fromDate}
                    onChange={onDateChangeFrom}
                    minDate={new Date()}
                    inputFormat="dd MMM yyyy"
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={(e) => setFromDateOpen(true)}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              {/* To Date Picker */}
              <div className="standard-page--label my-4">To Date</div>

              <div className="edit-package-date mb-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    open={openToDate}
                    onOpen={() => setToDateOpen(true)}
                    onClose={() => setToDateOpen(false)}
                    value={toDate}
                    onChange={onDateChangeTo}
                    minDate={new Date()}
                    inputFormat="dd MMM yyyy"
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={(e) => setToDateOpen(true)}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              {/* Black Out Day Date Picker */}

              <div className="standard-page--label my-4">Blackout Days</div>

              <div className="edit-package-date mb-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={fromDate}
                    onChange={onDateChange}
                    minDate={new Date()}
                    inputFormat="dd MMM yyyy"
                    InputAdornmentProps={{ position: "start" }}
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={(e) => setOpen(true)}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className="inventory__update__wrapper">
                {singleDate &&
                  singleDate.map((date: any) => {
                    return (
                      <div className="individual_dates">
                        {date.date}{" "}
                        <i
                          className="bi bi-x icon--close"
                          onClick={() => RemoveDate(date)}
                        ></i>
                      </div>
                    );
                  })}
              </div>

              <div className="add__package__input__wrapper mb-4">
                <div className="input__wrapper me-3">
                  <InputTextField
                    label="Nights"
                    value={nights}
                    handleChange={(e) => {
                      setNights(e.target.value);
                    }}
                  />
                </div>
                <div className="input__wrapper">
                  <InputTextField
                    label="Adult"
                    value={adult}
                    handleChange={(e) => {
                      setAdult(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="add__package__input__wrapper mb-4">
                <div className="input__wrapper me-3">
                  <InputTextField
                    label="Child"
                    value={child}
                    handleChange={(e) => {
                      setChild(e.target.value);
                    }}
                  />
                </div>
                <div className="input__wrapper">
                  <InputTextField
                    label="Extra Person"
                    value={extraPrson}
                    handleChange={(e) => {
                      setExtraPrson(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="add__package__input__wrapper mb-4">
                <div className="input__wrapper me-3">
                  <InputTextField
                    label="Price / Extra Person"
                    value={extraPrsonPrice}
                    handleChange={(e) => {
                      setExtraPrsonPrice(e.target.value);
                    }}
                  />
                </div>
                <div className="input__wrapper">
                  <InputTextField
                    label="Extra Child (6 Years to 12 Years)"
                    value={xtraChld}
                    handleChange={(e) => {
                      setXtraChld(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="add__package__input__wrapper">
                <div className="input__wrapper">
                  <InputTextField
                    label="Price / Extra Child"
                    value={xtraChildPrice}
                    handleChange={(e) => {
                      setXtraChildPrice(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="add__package__next__btn">
              <Button
                className="primary-btn"
                handleClick={() => {
                  uploadPackageImages();
                }}
                loader={loader}
              >
                Save
              </Button>
              <Button className="cancel__btn " handleClick={onClose}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddPackage;
