import { ADD_PROPERTY_ADDRESS, ADD_PROPERTY_AMENITIES, ADD_PROPERTY_DETAILS, ADD_PROPERTY_IMAGES, ADD_PROPERTY_LOCATION, ADD_PROPERTY_RESET, ADD_PROPERTY_SUBTYPE, ADD_PROPERTY_TYPE } from "../actionTypes"

const initialState = {
    new_property_type: null,
    new_property_subtype: null,
    new_property_details: {
        name: null,
        mobile: null,
        email: null,
        landline: null,
    },
    new_property_address: {
        flat: null,
        street: null,
        city: null,
        state: null,
        pin: null,
        country: null,
        address: null
    },
    new_property_location: null,
    new_property_amenities: null,
    new_property_images: [],
}

const AddPropertyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_PROPERTY_TYPE:
            return {
                ...state,
                new_property_type: action.payload
            }
        case ADD_PROPERTY_SUBTYPE:
            return {
                ...state,
                new_property_subtype: action.payload
            }
        case ADD_PROPERTY_DETAILS:
            return {
                ...state,
                new_property_details: action.payload
            }
        case ADD_PROPERTY_ADDRESS:
            return {
                ...state,
                new_property_address: action.payload
            }
        case ADD_PROPERTY_LOCATION:
            return {
                ...state,
                new_property_location: action.payload
            }
        case ADD_PROPERTY_AMENITIES:
            return {
                ...state,
                new_property_amenities: action.payload
            }
        case ADD_PROPERTY_IMAGES:
            return {
                ...state,
                new_property_images: action.payload
            }
        case ADD_PROPERTY_RESET:
            return initialState
        default: return state
    }
}

export default AddPropertyReducer;