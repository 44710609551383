import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";
import InventoryBtn from "../../views/buttons/Button";
import CustomDateRangePicker from "../daterangepicker/DateRangePicker";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import InputTextField from "../../views/inputtextfield/InputTextField";
import { CATCH_MESSAGE } from "../../UtilityFunctions";

interface IApplySeasonalPlan {
  onClose: () => void;
  specificPlan: any;
}

const ApplySeasonalPlan: React.FC<IApplySeasonalPlan> = ({
  onClose,
  specificPlan,
}) => {
  // Data From Redux
  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [planName, setPlanName] = useState<any>("");
  const [planId, setPlanId] = useState<any>();
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [saveLoader, setSaveLoader] = useState<any>(false);

  const handlePlanName = (e: React.ChangeEvent<HTMLInputElement>) => {
    let first = e.target.value;
    setPlanName(first);
  };

  // get Initial data for edit option - WIP
  const intialData = () => {
    setPlanName(specificPlan?.plan_name);
    setPlanId(specificPlan?.seasonal_plan_id)
  };
  useEffect(() => {
    intialData();
  }, []);

  // Handeler function to Apply Seasonal Plan
  const apply_plan = async () => {
    if (planName && startDate && endDate) {
      try {
        setSaveLoader(true);
        let res = await kernelApi.post(
          `active-seasonalplan/${planId}`,
          {
            hotel_id: current_property.hotel_id,
            date_from: moment(startDate).format("YYYY-MM-DD"),
            date_to: moment(endDate).format("YYYY-MM-DD"),
            seasonal_plan_id: planId,
            plan_name: planName,
            is_applied: 1
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (res.data.status === 1) {
          setSaveLoader(false);
          toast(`${res.data.message}`, {
            hideProgressBar: true,
            type: "success",
          });
        } else {
          setSaveLoader(false);
          toast(`${res.data.message}`, {
            hideProgressBar: true,
            type: "error",
          });
        }
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
        setSaveLoader(false);
      }
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Apply PLan</div>
      </div>

      <div className="height-64px"></div>
      <div className="seasonal__update__wrapper my-4 pt-2">
        {/* ----------------------------------------- */}
        <div className="inventory__number__wrapper mt-1 pt-3 mb-2">
          <div className="__default--input--field">
            <InputTextField
              label="Plan Name"
              value={planName}
              handleChange={handlePlanName}
            />
          </div>
        </div>

        <div
          className="inventory__select__date__wrapper my-4"
          onClick={() => setFocusedInput("startDate")}
        >
          <div className="date__picker__icon">
            <i className="bi bi-calendar"></i>
          </div>

          <div className="date__label">
            {!startDate && !endDate
              ? "Select Date Range"
              : (startDate ? moment(startDate).format("DD MMM YYYY") : "") +
              " - " +
              (endDate ? moment(endDate).format("DD MMM YYYY") : "")}
          </div>
          <CustomDateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            focusedInput={focusedInput}
            preventPastDates={true}
            setFocusedInput={setFocusedInput}
          />
        </div>
        <div className="important__note">
          <h5>Important Note</h5>
          <p>
            The Blocked meal plans feature is limited to only selected OTAs, it
            may not work on OTAs which doesn’t support rate plan block feature.
          </p>
        </div>
      </div>

      {/* --------------------SAVE SECTION--------------------- */}
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-1">
          <InventoryBtn handleClick={apply_plan} loader={saveLoader}>
            Save
          </InventoryBtn>
        </div>
      </div>
    </>
  );
};

export default ApplySeasonalPlan;
