import { GET_PROPERTY_EMAILS } from "../actionTypes"

const initialState = []
const save_initialState = []

const AddPropertyMailReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PROPERTY_EMAILS:
            return [...action.payload]
        default: return state
    }
}

export default AddPropertyMailReducer;