import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { UpdateSidebar } from "../../../UtilityFunctions";

import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "../../../views/buttons/Button";

import cmApi from "../../../API/cmApi";
import { toast } from "react-toastify";
import {
    CancelConfirmationPrompt,
    ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";

import InputSelectField from "../../../views/inputtextfield/InputSelectField";
import Loader from "../../../views/loader/Loader";
import endPoints from "../../../API/endPoints";

const InactiveBasicPromotion = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();

    UpdateSidebar("Promotion");

    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );

    const { getAllInactiveBasicPromotions,insertBasicPromotion } = endPoints.MANAGEBASICPROMOTION;

    
    const [loader, setLoader] = useState<any>(true);
    const [allInactivePromotions, setAllInactivePromotions] = useState([]);
    const [activeStatus, setActiveStatus] = useState<any>([]);


    const [selectPageSize, setSelectPageSize] = useState(10);
    const [noOfPages, setNoOfPages] = useState(1);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const pageSize = [10, 15, 20, 25, 30];

    const [allInactivePromotionsMessage, setAllInactivePromotionsMessage] = useState('');

    const {activateBasicPromotion} = endPoints.MANAGEBASICPROMOTION;

    useEffect(() => {
        fetchAllInactivePromotions();
    }, [current_property])


    const fetchAllInactivePromotions = async () => {
        let active_status: any = [];

        try {
            let allInactivePromotions = await cmApi.get(
                `${getAllInactiveBasicPromotions}/${current_property.hotel_id}`
            );

            if (allInactivePromotions.data.status === 1) {

                for (let i = 0; i < allInactivePromotions.data.all_inactive_promotion.length; i++) {
                    active_status.push(false);
                }

                setAllInactivePromotions(allInactivePromotions.data.all_inactive_promotion);
                setActiveStatus(active_status);
                setNoOfPages(Math.ceil(allInactivePromotions.data.all_inactive_promotion.length / selectPageSize))
                setAllInactivePromotionsMessage(allInactivePromotions.data.message);
                setLoader(false);
            }

            else {
                setAllInactivePromotionsMessage(allInactivePromotions.data.message);
                setLoader(false);
            }
        }
        catch (error: any) {
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
                Logout();
            }
        }
    }



    const activatePromotion = (index: any, promotion_id: any) => {

        dispatch(
            ShowConfirmationPrompt(
                `Are you sure want to activate this ?`,
                "DELETE",
                async () => {
                    try {
                        let activePromotion = await cmApi.post(activateBasicPromotion,
                            {
                                hotel_id: current_property.hotel_id,
                                id: promotion_id,
                            }
                        );

                        if (activePromotion.data.status === 1) {
                            toast.success(`${activePromotion.data.message}`);
                            fetchAllInactivePromotions();
                        } else {
                            toast.error(`${activePromotion.data.message}`);
                            fetchAllInactivePromotions();
                        }
                    } catch (error: any) {
                        toast.error(CATCH_MESSAGE);
                        if (error.response.status === 400) {
                            Logout();
                        }
                    }
                    dispatch(CancelConfirmationPrompt());
                },
                ["No", "Yes"]
            )
        );
    }


    const rightArrowClick = () => {
        if (currentPageNo < noOfPages) {
            setCurrentPageNo(currentPageNo + 1);
        }
    };

    const leftArrowClick = () => {
        if (currentPageNo > 1) {
            setCurrentPageNo(currentPageNo - 1);
        }
    };


    return (
        <>
            <div className="basic__promotion__wrapper">
                <div className="promotions__breadcrumbs">
                    <Breadcrumbs />
                    <div
                        className="promotions__action__right">
                        <Button
                            handleClick={() => navigate('/promotion/basic-promotion')}
                        >
                            Active Promotion
                        </Button>

                        <Button
                            className="back__btn"
                            icon="bi bi-arrow-left"
                            handleClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    </div>
                </div>
                <div className="hr--line"></div>

                {loader ? (
                    <Loader className="ineventory--loader" />
                ) : allInactivePromotions.length > 0 ? <div className="promotional__wrapper">
                    <div className="promotional__main__content mb-4">
                        {allInactivePromotions.map((item: any, i: any) => {
                            return (
                                <div className="promotional__card" key={item.id}>
                                    <div className="card__property me-3">
                                        <div className="promotional__block mb-3">
                                            <div className="promotional__sub__level">Name</div>
                                            <div className="promotional__text__label">{item.promotion_name}</div>
                                        </div>

                                        <div className="promotional__block mb-3">
                                            <div className="promotional__sub__level">Booking Date</div>
                                            <div className="booking__stay__date__label">{item.booking_start_date} - {item.booking_end_date}</div>
                                        </div>

                                        <div className="promotional__block mb-3">
                                            <div className="promotional__sub__level">Stay Date</div>
                                            <div className="booking__stay__date__label">{item.stay_start_date} - {item.stay_end_date}</div>
                                        </div>

                                        <div className="promotional__block mb-3">
                                            <div className="promotional__sub__level">Discount</div>
                                            <div className="promotional__discount__wrapper">
                                                <i className="bi bi-patch-check-fill fill-green-color me-2"></i>
                                                <div>{item.discount}</div>
                                            </div>
                                        </div>

                                        <div className="promotional__block mb-3">
                                            <div className="promotional__sub__level mb-2">Applicable Room Type</div>
                                            <div className="promotion__room__type">
                                                {item.selected_room_rateplan.map((item_room_type: any, id) => {
                                                    return (
                                                        <div className="promotion__room__names mb-2" key={id}>{item_room_type.shortcode}</div>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                        <div className="hr--line"></div>

                                        <div className="promotion__btn__wrappers">

                                            <div className="promotion__btn__right">
                                                <div className="me-2">Active</div>
                                                <div className="toogle__switch">
                                                    <div className="form-check form-switch custom-switch">
                                                        <input type="checkbox" className="form-check-input" checked={activeStatus[i]} onChange={() => { activatePromotion(i, item.id) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="basic--promotion--pagination">
                        <div className="pagination__left">
                            <div className="pagination__dropdown">
                                <InputSelectField
                                    label="Select"
                                    value={selectPageSize}
                                    options={pageSize}
                                    handleChange={(e) => {
                                        setSelectPageSize(e.target.value);
                                        setNoOfPages(Math.ceil(allInactivePromotions?.length / e.target.value));
                                        setCurrentPageNo(1);
                                    }}
                                />
                            </div>
                            <ul>
                                {Array(noOfPages)
                                    .fill(0)
                                    .map((p, i) => {
                                        return (
                                            <li
                                                key={i}
                                                onClick={() => setCurrentPageNo(i + 1)}
                                                className={`${i + 1 === currentPageNo
                                                    ? "active__page"
                                                    : "showing__page__number"
                                                    }`}
                                            >
                                                {i + 1}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                        <div className="pagination__right">
                            <div className="arrow__container me-3" onClick={leftArrowClick}>
                                <i className="bi bi-chevron-left font--weight"></i>
                            </div>
                            <div className="arrow__container" onClick={rightArrowClick}>
                                <i className="bi bi-chevron-right font--weight"></i>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <div className="empty__room__div mt-5">
                        <i className="bi bi-x-circle mb-3"></i>
                        <div>{allInactivePromotionsMessage}</div>
                    </div>
                }


            </div>
        </>
    );
};

export default InactiveBasicPromotion;
