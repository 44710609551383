import Button from "../../../../views/buttons/Button";
import React, { useEffect, useRef, useState } from "react";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import { useNavigate } from "react-router";
import UploadBtn from "../../../../views/buttons/Button"
import beApi from "../../../../API/beApi";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, Logout } from "../../../../UtilityFunctions";
import Loader from "../../../../views/loader/Loader";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import moment from "moment";




function UnpaidBookings() {

    const { auth_token } = useSelector(
        (state: RootState) => state.auth
    );

    const { hotel_id } = useSelector(
        (state: RootState) => state.properties.current_property
    );

    const navigate = useNavigate()

    const [dataArr, setDataArr] = useState([])
    const [defaultMsg, setDefaultMsg] = useState('Unable to fetch data')
    const [loader, setLoader] = useState(true)

    const [selectPageSize, setSelectPageSize] = useState(4);
    const [noOfPages, setNoOfPages] = useState(1);
    const [currentPageNo, setCurrentPageNo] = useState(1);

    const [price, setPrice] = useState<any>(null)
    const [index, setIndex] = useState<any>(null)
    const pageSize = [4, 8, 12, 16, 20];

    // const [DATA_ARR_MAP, set_DATA_ARR_MAP] = useState<any>([])


    // Pagination
    const rightArrowClick = () => {
        if (currentPageNo < noOfPages) {
            setCurrentPageNo(currentPageNo + 1);
        }
    };

    const leftArrowClick = () => {
        if (currentPageNo > 1) {
            setCurrentPageNo(currentPageNo - 1);
        }
    };

    useEffect(() => {
        if (dataArr?.length > 0) {
            setNoOfPages(Math.ceil(dataArr?.length / selectPageSize));
        }
    }, [selectPageSize, dataArr]);
    // --------------------------


    // --------------------------
    // API
    const getAllData = async () => {
        try {
            // const response = await beApi.get(`/mailInvoice/details/${1953}`,
            const response = await beApi.get(`/mailInvoice/details/${hotel_id}`,
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            )
            if (response.data.status === 1) {
                setDataArr(response.data.details.data)
                setLoader(false)
            }
            else {
                setDataArr([])
                setLoader(false)
                setDefaultMsg(response.data.message)
            }
        }
        catch (error: any) {
            if (error.response.status === 400) {
                Logout()
            }
            setLoader(false)
            toast.error(CATCH_MESSAGE)
        }
    }

    useEffect(() => { getAllData() }, [])

    const amount_PostApi = async (enteredPrice, amount, invoice_id) => {
        if (!enteredPrice) {
            toast.error('Enter valid amount')
            return
        }
        else {
            if (enteredPrice <= amount) {
                try {
                    // https://be.bookingjini.com/mailInvoice/mail
                    const response = await beApi.post(`/mailInvoice/mail/${hotel_id}`,
                        { "invoice_id": invoice_id, "paid_amount": enteredPrice, "round_off": false },
                        {
                            headers: {
                                Authorization: "Bearer " + auth_token,
                            },
                        }
                    )
                    if (response.data.status === 1) toast.success(response.data.message)
                    else toast.error(response.data.message)
                    setIndex(null)
                    setPrice(null)
                }
                catch {
                    toast.error(CATCH_MESSAGE)
                }
            }
            else toast.error('Price should be less than total amount')
        }
    }

    const novalueEntered = () => { toast.error('Please enter specified amount') }

    const DownloadSheet = () => {
        try {
            const res = beApi.get(`download-report/${hotel_id}`)
        }
        catch { }
    }
    // --------------------------


    // --------------------------
    // MAPPING
    const DATA_ARR_MAP =
        // useEffect(() => {
        // const arr = 
        dataArr
            .slice(
                selectPageSize * (currentPageNo - 1),
                selectPageSize * currentPageNo
            )
            .map((obj: any, ind) => {
                const { booking_date, invoice_id,
                    check_in_out, email_id,
                    first_name, last_name, mobile, room_type,
                    total_amount, payment_link_status } = obj

                const [bookingDate, bookingTime] = booking_date?.split(' ')

                const roomType = room_type.split('"')

                const checkOut = check_in_out.replace(/\[|\]/g, '').split(',').toString()

                const ckOUT = checkOut.split('-')

                const amount = parseInt(total_amount)

                var given = moment(`${ckOUT[3]}-${ckOUT[4]}-${ckOUT[5]}`, "YYYY-MM-DD");
                // var given = moment(`${2022}-${8}-${3}`, "YYYY-MM-DD");
                var current = moment().startOf('day');

                //Difference in number of days
                const diff = moment.duration(given.diff(current)).asDays();




                return (
                    <div className="allPaymentdataContainer" key={ind}>
                        <div className="serialNum data">
                            <div className="number">{ind + 1}</div>
                        </div>

                        <div className="userInfo data">
                            <div className="ui_name">{first_name + last_name}</div>
                            <div className="ui_mail">{email_id}</div>
                            {/* <div className="ui_mail">{'vikrantMessi1234567@gmail.com'}</div> */}
                            <div className="ui_phone">{mobile}</div>
                        </div>

                        <div className="dateofbooking data">
                            <div className="dob_date">{bookingDate}</div>
                            <div className="dob_time">{bookingTime}</div>
                        </div>

                        <div className="periodofbooking data">{checkOut}</div>

                        <div className="roomtype data">{roomType[1]}</div>

                        <div className="total_amount data">{total_amount}</div>

                        {
                            payment_link_status ?
                                index === ind ?
                                    <div className="paid_amount data">
                                        <div className="paidamtcontainer" >
                                            <input className="amt" value={price ? price : ''} type='number' autoFocus
                                                onChange={(e) => {
                                                    setPrice(e.target.value)
                                                }}
                                            />
                                            <div className="iconBox">
                                                <i className="bi bi-envelope" onClick={() => amount_PostApi(parseInt(price), amount, invoice_id)}></i>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="paid_amount data">
                                        <div className="paidamtcontainer">
                                            <div style={{ cursor: 'text' }} className="amt"
                                                onClick={() => {
                                                    setIndex(ind)
                                                    setPrice(null)
                                                }}
                                            ></div>
                                            <div className="iconBox">
                                                <i className="bi bi-envelope" onClick={() => novalueEntered()}></i>
                                            </div>
                                        </div>
                                    </div>
                                :
                                <div className="paid_amount data expired">Expired!</div>
                        }
                    </div>
                )
            })
    // set_DATA_ARR_MAP(arr)
    // }, [price, dataArr])




    return (
        <>
            <div className="property__breadcrumbs">
                <CustomBreadcrumbs content={[{ label: "Manage Channels", href: "/manage-channels" }, { label: "Booking Engine", href: "/manage-channels/booking-engine" }, { label: "Unpaid Bookings" }]} />
                <div
                    className="manage__property__action__right"
                    style={{ alignItems: "center" }}
                >

                    <a className="inventory__update__btn"
                        // onClick={() => DownloadSheet()}
                        href={`https://be.bookingjini.com/extranetv4/download-report/${hotel_id}`}
                    >
                        <UploadBtn
                            className="update__btn"
                            icon="bi bi-file-arrow-down"
                        >
                            Download
                        </UploadBtn>
                    </a>

                    <Button className="back__btn"
                        icon="bi bi-arrow-left"
                        handleClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                </div>
            </div>

            <div className="hr--line"></div>

            {/* -------------------------- */}

            <div className="unpaidbookings_wrapper">

                {
                    loader ? <div style={emptyDivStyles}> <Loader /></div> :
                        DATA_ARR_MAP.length > 0 ?
                            <>
                                <div className="titlesContainer">
                                    <div className="slno titles">Sl.no</div>
                                    <div className="user_info titles">User Information</div>
                                    <div className="dob titles">Date of Booking</div>
                                    <div className="pob titles">Period of Booking</div>
                                    <div className="roomtype titles">Room Type</div>
                                    <div className="t_am titles">Total Amount</div>
                                    <div className="p_am titles">Paid Amount</div>
                                </div>

                                {DATA_ARR_MAP}

                                <div className="list--view--pagination" style={{ marginTop: '30px' }}>
                                    <div className="pagination__left">
                                        <div className="pagination__dropdown">
                                            <InputSelectField
                                                label="Select"
                                                value={selectPageSize}
                                                options={pageSize}
                                                handleChange={(e) => {
                                                    setSelectPageSize(e.target.value);
                                                    setNoOfPages(Math.ceil(dataArr.length / e.target.value));
                                                    setCurrentPageNo(1);
                                                }}
                                            />
                                        </div>
                                        <ul>
                                            {Array(noOfPages)
                                                .fill(0)
                                                .map((p, i) => {
                                                    return (
                                                        <li
                                                            key={i}
                                                            onClick={() => setCurrentPageNo(i + 1)}
                                                            className={`${i + 1 === currentPageNo
                                                                ? "active__page"
                                                                : "showing__page__number"
                                                                }`}
                                                        >
                                                            {i + 1}
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                    <div className="pagination__right">
                                        <div className="arrow__container me-3" onClick={leftArrowClick}>
                                            <i className="bi bi-chevron-left font--weight"></i>
                                        </div>
                                        <div className="arrow__container" onClick={rightArrowClick}>
                                            <i className="bi bi-chevron-right font--weight"></i>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div style={emptyDivStyles}>{defaultMsg}</div>
                }

            </div>
        </>
    )
}


const emptyDivStyles = {
    width: '100%',
    height: "500px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    color: '#b3b3b3'
}

export default UnpaidBookings