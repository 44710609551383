import { CHOOSE_PROPERTY, GET_PROPERTIES, SAVE_PROPERTY_INFO } from "../actionTypes"

const initialState = {
    property_data: null,
    current_property: null,
    current_property_info: null,
}

const PropertiesReducer = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case GET_PROPERTIES:
            return {
                ...state,
                property_data: action.payload
            }
        case CHOOSE_PROPERTY:
            return {
                ...state,
                current_property: action.payload
            }
        case SAVE_PROPERTY_INFO:
            return {
                ...state,
                current_property_info: action.payload
            }

        default: return state
    }
}

export default PropertiesReducer;