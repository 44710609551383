import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import InputTextField from "../../views/inputtextfield/InputTextField";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import BackButton from "../../views/buttons/Button";
import NextButton from "../../views/buttons/NextButton";

import { AddRoomTypeEPprice, AddRoomTypePrice } from "../../redux/actions/AddRoomTypeAction";
import { toast } from "react-toastify";

export interface IPriceRate {
  onNext?: () => void;
}

const RoomPriceDetails: React.FC<IPriceRate> = ({ onNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { price } = useSelector((state: RootState) => state.add_room_type);

  // Input field value and validation check
  const [maxRoomPrice, setMaxRoomPrice] = useState(price.max_room_price);
  const [minRoomPrice, setMinRoomPrice] = useState(price.min_room_price);
  /* 
  const [extraAdultPrice, setExtraAdultPrice] = useState(
    price.extra_adult_price
  );
  const [extraChildPrice, setExtraChildPrice] = useState(
    price.extra_child_price
  );
  */

  const MaxPriceOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.match(/^[0-9\b]+$/) || val === "") {
      setMaxRoomPrice(val);
    }
  };

  const MinPriceOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.match(/^[0-9\b]+$/) || val === "") {
      setMinRoomPrice(val);
    }
  };

  /*
  const ExtraAdultOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.match(/^[0-9\b]+$/) || val === "") {
      setExtraAdultPrice(val);
    }
  };

  const ExtraChildOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.match(/^[0-9\b]+$/) || val === "") {
      setExtraChildPrice(val);
    }
  };
  */

  const SubmitRoomTypePrice = () => {
    if (
      maxRoomPrice &&
      minRoomPrice
    ) {
      dispatch(
        AddRoomTypePrice({
          max_room_price: maxRoomPrice,
          min_room_price: minRoomPrice,
        })
      );
      dispatch(AddRoomTypeEPprice(maxRoomPrice))
      document.getElementById("button-id")?.click();
    } else {
      toast("Please fill all the details", {
        hideProgressBar: true,
        type: "error"
      });
    }
  };

  const backButtonClick = () => {
    navigate("/add-room-type/room-type-occupancy");
  };

  return (
    <>
      <div className="property_details_input__wrapper standard--margin--top">
        <div className="__default--input--field">
          <InputTextField
            label="Maximum Room Price"
            value={maxRoomPrice}
            handleChange={MaxPriceOnChange}
          />
        </div>
      </div>

      <div className="property_details_input__wrapper standard--margin--top">
        <div className="__default--input--field">
          <InputTextField
            label="Minimum Room Price"
            value={minRoomPrice}
            handleChange={MinPriceOnChange}
          />
        </div>
      </div>

      {/*
      <div className="property_details_input__wrapper">
        <div className="__default--input--field">
          <InputTextField
            label="Extra Adult Price"
            value={extraAdultPrice}
            handleChange={ExtraAdultOnChange}
          />
        </div>

        <div className="__default--input--field">
          <InputTextField
            label="Extra Child Price"
            value={extraChildPrice}
            handleChange={ExtraChildOnChange}
          />
        </div> 
      </div>
      */}

      <button className="d-none" id="button-id" onClick={onNext}></button>
      <div className="__btn__wrapper mt-4">
        <BackButton className="back__btn" handleClick={backButtonClick}>
          Back
        </BackButton>
        <NextButton handleClick={SubmitRoomTypePrice} />
      </div>
    </>
  );
};

export default RoomPriceDetails;
