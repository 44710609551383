import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import moment from "moment";

import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import {
  CATCH_MESSAGE,
  Logout,
  validateEmail,
  validateGstNo,
  validateMobile,
  validatePositiveInt,
} from "../../UtilityFunctions";

import Button from "../../views/buttons/Button";
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import InputTextField from "../../views/inputtextfield/InputTextField";
import { toast } from "react-toastify";
import gemsApi from "../../API/gemsApi";
import {
  RemoveBooking,
  SaveBooking,
  SaveBookingCRS,
} from "../../redux/actions/BookingsAction";
import beApi from "../../API/beApi";
import kernelApi from "../../API/kernelApi";
import endPoints from "../../API/endPoints";

export interface IModifyBookingProps {
  onClose: () => void;
  trigger: boolean;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModifyBooking: React.FC<IModifyBookingProps> = ({
  onClose,
  trigger,
  setTrigger,
}) => {
  const dispatch = useDispatch();

  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { booking_details } = useSelector((state: RootState) => state.bookings);

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);

  const [roomTypeData, setRoomTypeData] = useState<any[]>([]);
  const [allRatePlans, setAllRatePlans] = useState<any[]>([]);

  const [includesTax, setIncludesTax] = useState(false);
  const {
    bookingDetailsGet,
    bookingDetailsCRSGet,
    modifyGuestDetails,
    modifyGuestDetailsNew,
  } = endPoints.BOOKINGS;

  const { masterRatePlanAll } = endPoints.PROPERTY_RATE_PLAN;

  const [guestName, setGuestName] = useState(booking_details?.guest_name ?? "");
  const [mobileNum, setMobileNum] = useState(booking_details?.mobile ?? "");
  const [emailAddress, setEmailAddress] = useState(
    booking_details?.email_id ?? ""
  );
  const [guestAddress, setGuestAddress] = useState(
    booking_details?.user_address ?? ""
  );
  const [isBusinessBooking, setIsBusinessBooking] = useState(
    booking_details?.business_booking ? true : false
  );
  const [companyName, setCompanyName] = useState(
    booking_details?.company_name ?? ""
  );
  const [companyAddress, setCompanyAddress] = useState(
    booking_details?.address ?? ""
  );
  const [gstNumber, setGstNumber] = useState(booking_details?.GSTIN ?? "");

  const [mobileNumError, setMobileNumError] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");

  useEffect(() => {
    getAllRatePlans();
  }, [current_property]);

  useEffect(() => {
    assignRoomTypes();
  }, [current_property, booking_details]);

  const assignRoomTypes = () => {
    let roomData: any[] = [];
    booking_details?.room_details?.forEach((room: any) =>
      roomData.push({
        room_type: room.room_type,
        room_type_id: room.room_type_id,
        rate_plan_id: room.meal_plan,
        no_of_rooms: room.no_of_rooms,
        price: room.room_rate ?? "",
      })
    );
    setRoomTypeData(roomData);
  };

  const onMealPlanChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    let roomData = [...roomTypeData];
    roomData[index].rate_plan_id = e.target.value;
    setRoomTypeData(roomData);
  };

  const onSellingPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let val = e.target.value;
    if ((validatePositiveInt(val) && val.length < 9) || val === "") {
      let roomData = [...roomTypeData];
      roomData[index].price = val;
      setRoomTypeData(roomData);
    }
  };

  const onMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value.slice(0, 10);
    if (!validateMobile(val) && val) {
      setMobileNumError("Invalid mobile number");
    } else {
      setMobileNumError("");
    }
    setMobileNum(val);
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (!validateEmail(val) && val) {
      setEmailAddressError("Invalid email address");
    } else {
      setEmailAddressError("");
    }
    setEmailAddress(val);
  };

  const onGSTChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.length < 25) setGstNumber(val);
    if (!validateGstNo(val) && val) {
      setGstNumberError("Enter a valid GST no eg.10AABCU9603R1Z2");
    } else {
      setGstNumberError("");
    }
  };

  const getAllRatePlans = async () => {
    try {
      const response = await kernelApi.get(
        `${masterRatePlanAll}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        setAllRatePlans(response.data.data);
      } else {
        setAllRatePlans([]);
      }
    } catch (error: any) {
      setAllRatePlans([]);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const getBookingDetails = async () => {
    dispatch(RemoveBooking());
    try {
      const response = await gemsApi.post(`${bookingDetailsGet}`, {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
        booking_id: booking_details?.booking_details?.booking_id,
      });

      if (response.data.status === 1) {
        dispatch(SaveBooking(response.data));
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  const getBookingDetailsCRS = async () => {
    try {
      const response = await beApi.post(
        `${bookingDetailsCRSGet}`,
        {
          booking_id: booking_details?.booking_details?.booking_id,
          booking_source: booking_details?.booking_details?.channel,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(SaveBookingCRS(response.data.data));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  const saveGuestDetails = async () => {
    if (
      guestName &&
      guestAddress &&
      mobileNum &&
      emailAddress &&
      ((companyName && gstNumber && !gstNumberError) || !isBusinessBooking)
    ) {
      try {
        let payload = {
          guest_name: guestName,
          guest_address: guestAddress,
          mobile: mobileNum,
          email_id: emailAddress,
          business_booking: isBusinessBooking ? "1" : "0",
          gstin: isBusinessBooking ? gstNumber : "",
          company_name: isBusinessBooking ? companyName : "",
          company_address: isBusinessBooking ? companyAddress : "",
        };

        const response = await beApi.post(`${modifyGuestDetails}`, payload);

        if (response.data.status === 1) {
          closeSlider();
          toast.success(response.data.message);
          getBookingDetails();
          getBookingDetailsCRS();
          setTrigger(!trigger);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const saveRoomTypeDetails = async () => {
    if (roomTypeData.every((room: any) => room.price && room.rate_plan_id)) {
      try {
        let room_type_data: any[] = [];

        roomTypeData.forEach((room: any) => {
          room_type_data.push({
            room_type_id: room.room_type_id,
            rate_plan_id: room.rate_plan_id,
            no_of_rooms: room.no_of_rooms,
            room_type: room.room_type,
            room_price: room.price,
            meal_plan: allRatePlans?.filter(
              (plan: any) => plan.rate_plan_id === room.rate_plan_id
            )[0]?.plan_type,
          });
        });

        let payload = {
          booking_id: booking_details?.bookingid,
          hotel_id: current_property.hotel_id,
          modify_type: "rooms",
          including_tax: includesTax ? "1" : "0",
          room_type_data,
        };

        const response = await beApi.post(`${modifyGuestDetailsNew}`, payload);

        if (response.data.status === 1) {
          closeSlider();
          toast.success(response.data.message);
          getBookingDetails();
          getBookingDetailsCRS();
          setTrigger(!trigger);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const saveCheckInOutDates = async () => {
    if (startDate && endDate) {
      try {
        let payload = {
          modify_type: "date",
          hotel_id: current_property.hotel_id,
          booking_id: booking_details?.bookingid,
          check_in: moment(startDate).format("YYYY-MM-DD"),
          check_out: moment(endDate).format("YYYY-MM-DD"),
        };

        const response = await beApi.post(`${modifyGuestDetailsNew}`, payload);

        if (response.data.status === 1) {
          closeSlider();
          getBookingDetails();
          getBookingDetailsCRS();
          setTrigger(!trigger);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Please select check in and check out dates");
    }
  };

  const closeSlider = () => {
    document.getElementById("close-slider")?.click();
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i id="close-slider" onClick={onClose} className="bi bi-x"></i>
        </div>
        <div className="standard-page--label">Modify Booking</div>
        <div className="side__slider__sub__heading me-3">
          ID : {booking_details?.booking_details?.booking_id}
        </div>
      </div>
      <div className="height-64px"></div>

      <div className="modify__booking__nav__tab">
        <ul className="nav nav-tabs mb-3" id="modifyBooking" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab--link--text active"
              id="check-in-out-date"
              data-bs-toggle="tab"
              data-bs-target="#check-in-out"
              type="button"
              role="tab"
              aria-controls="check-in-out"
              aria-selected="true"
            >
              Check-in/out Date
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab--link--text"
              id="modify-room-tab"
              data-bs-toggle="tab"
              data-bs-target="#room-tab"
              type="button"
              role="tab"
              aria-controls="room-tab"
              aria-selected="true"
            >
              Room
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab--link--text"
              id="modify-booking-details"
              data-bs-toggle="tab"
              data-bs-target="#booking-details"
              type="button"
              role="tab"
              aria-controls="booking-details"
              aria-selected="true"
            >
              Booking Details
            </button>
          </li>
        </ul>

        <div
          className="tab-content modify__booking__content"
          id="modifyBookingContent"
        >
          <div
            className="tab-pane fade show active"
            id="check-in-out"
            role="tabpanel"
            aria-labelledby="check-in-out-date"
          >
            <div className="check__in__out__date my-4">
              <div className="modify">Current Check-in/out Date</div>
              <div className="checkin__checkout__wrapper mt-3">
                <div className="checkinout__content">
                  <div className="check__inout__details check-in-border">
                    <div className="check__inout__icon check-in-color">
                      <i className="bi bi-arrow-down-right-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-in-color">
                        {booking_details?.check_in}
                      </div>
                      <div className="check__inout__label">Check-in</div>
                    </div>
                  </div>
                </div>

                <div className="number__of__nights__wrapper">
                  <div className="night__icon">
                    <i className="bi bi-moon-fill"></i>
                  </div>
                  <div className="night__number">
                    {booking_details?.no_of_nights}
                  </div>
                </div>

                <div className="checkinout__content">
                  <div className="check__inout__details check-out-border">
                    <div className="check__inout__icon check-out-color">
                      <i className="bi bi-arrow-up-left-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-out-color">
                        {booking_details?.check_out}
                      </div>
                      <div className="check__inout__label">Check-out</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="standard-page--label my-4">
              Choose New Check-in/out Date
            </div>

            <div className="inventory__update__wrapper px-0 mx-0">
              <div
                className="inventory__select__date__wrapper my-4"
                onClick={() => setFocusedInput("startDate")}
              >
                <div className="date__picker__icon">
                  <i className="bi bi-calendar"></i>
                </div>
                <div className="date__label">
                  {!startDate && !endDate
                    ? "Select Date Range"
                    : (startDate ? startDate.format("DD MMM, YYYY") : "") +
                    " - " +
                    (endDate ? endDate.format("DD MMM, YYYY") : "")}
                </div>
                <CustomDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  focusedInput={focusedInput}
                  setFocusedInput={setFocusedInput}
                  preventPastDates={true}
                />
              </div>
            </div>

            <div style={{ height: "250px" }}></div>

            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper justify-content-between w-100">
                {booking_details.can_edit_booking_dates ? (
                  <Button
                    handleClick={saveCheckInOutDates}
                    className="primary-btn"
                  >
                    Save
                  </Button>
                ) : (
                  <div>Check in/out Date Can not be Changed</div>
                )}

                <Button
                  handleClick={onClose}
                  className="primary-btn delete__btn__wrapper"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="room-tab"
            role="tabpanel"
            aria-labelledby="modify-room-tab"
          >
            <div className="modify__booking__rooms__wrapper my-4">
              <div className="modify__rooms__wrapper">
                {roomTypeData?.map((room: any, index: number) => {
                  return (
                    <div key={index} className="room__type__wrapper">
                      <div className="modify__room__type me-4">
                        <InputSelectField
                          label="Room Type"
                          value={room.room_type_id}
                          options={[room.room_type]}
                          values={[room.room_type_id]}
                        />
                      </div>
                      <div className="modify__rate__plan me-4">
                        <InputSelectField
                          label="Meal Plan"
                          value={room.rate_plan_id}
                          handleChange={(e) => onMealPlanChange(e, index)}
                          values={allRatePlans?.map(
                            (rp: any) => rp.rate_plan_id
                          )}
                          options={allRatePlans?.map((rp: any) => rp.plan_type)}
                        />
                      </div>
                      <div className="modify__selling__rate">
                        <InputTextField
                          label="Selling Rate"
                          value={room.price}
                          handleChange={(e) => onSellingPriceChange(e, index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="including__tax__wrapper">
                <div className="standard-page--label mt-0">Including Tax</div>
                <div className="form-check form-switch custom-switch check--box--width">
                  <input
                    type="checkbox"
                    checked={includesTax}
                    className="form-check-input"
                    onChange={() => setIncludesTax(!includesTax)}
                  />
                </div>
              </div>
            </div>

            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper justify-content-between w-100">
                {booking_details.can_edit_room_type ? (
                  <Button
                    handleClick={saveRoomTypeDetails}
                    className="primary-btn"
                  >
                    Save
                  </Button>
                ) : (
                  <div>Room Type Can not be Changed</div>
                )}

                <Button
                  handleClick={onClose}
                  className="primary-btn delete__btn__wrapper"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="booking-details"
            role="tabpanel"
            aria-labelledby="modify-booking-details"
          >
            <div className="modify__guest__booking__data__wrapper my-4">
              <div className="modify__guest__name mb-3">
                <InputTextField
                  label="Guest Name"
                  value={guestName}
                  handleChange={(e) => setGuestName(e.target.value)}
                />
              </div>

              <div className="modify__mobile__number mb-3">
                <InputTextField
                  label="Mobile Number"
                  value={mobileNum}
                  handleChange={onMobileChange}
                  helperText={mobileNumError}
                />
              </div>

              <div className="modify__booking__guest__email mb-3">
                <InputTextField
                  label="Email Address"
                  value={emailAddress}
                  handleChange={onEmailChange}
                  helperText={emailAddressError}
                />
              </div>

              <div className="modify__booking__guest__address mb-3">
                <InputTextField
                  label="Guest Address"
                  value={guestAddress}
                  handleChange={(e) => setGuestAddress(e.target.value)}
                />
              </div>

              <div className="business__booking__wrapper mb-4">
                <div className="standard-page--label">Business Booking</div>
                <div className="form-check form-switch custom-switch check--box--width">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isBusinessBooking}
                    onChange={() => setIsBusinessBooking(!isBusinessBooking)}
                  />
                </div>
              </div>

              {isBusinessBooking && (
                <>
                  <div className="company__name__address mt-3">
                    <div className="company__name me-3">
                      <InputTextField
                        label="Company Name"
                        value={companyName}
                        handleChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="company__address">
                      <InputTextField
                        label="Company Address"
                        value={companyAddress}
                        handleChange={(e) => setCompanyAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="gstin__wrapper mt-4">
                    <InputTextField
                      label="GSTIN"
                      value={gstNumber}
                      handleChange={onGSTChange}
                      helperText={gstNumberError}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper justify-content-between w-100">
                {booking_details.can_edit_booker_details ? (
                  <Button
                    handleClick={saveGuestDetails}
                    className="primary-btn"
                  >
                    Save
                  </Button>
                ) : (
                  <div>Booker Information Can not be Changed</div>
                )}

                <Button
                  handleClick={onClose}
                  className="primary-btn delete__btn__wrapper"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
      </div>
    </>
  );
};

export default ModifyBooking;
