import React from "react";
import DataLoader from "../loader/DataLoader";
import ReactTooltip from "react-tooltip";

export interface IDataCardProps {
  icon?: string;
  data: {
    title?: string;
    subtitle?: string;
    value?: any;
    toolTipMsg?: string;
  };
  cardType?: string;
  isLoading?: boolean;

}

const DataCard: React.FC<IDataCardProps> = ({
  icon,
  data,
  cardType,
  isLoading,

}) => {
  return (
    <div className="data-card text-start">
      <div
        className="data__card__heading mb-1"
        data-for="toolTipTitle"
        data-tip={data.toolTipMsg}
        data-place="top"
      >
        {data.title}
      </div>
      <ReactTooltip id="toolTipTitle" />

      {!isLoading ? (
        <div className="data__card__value">{data.value || 0}</div>
      ) : (
        <DataLoader />
      )}
      <div className="data__card__footer">
        <div className="data__card__footer__text">{data.subtitle}</div>
        <div className={`data__card__icon__wrapper ${cardType}`}>
          <i className={`bi bi-${icon} data--card--icon`}></i>
        </div>
      </div>
    </div>
  );
};

export default DataCard;
