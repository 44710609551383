import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import gemsApi from "../../API/gemsApi";
import { RootState } from "../../redux/store";
import {
  FileToBase64,
  getImage,
  validateEmail,
  validateMobile,
  validatePositiveInt,
} from "../../UtilityFunctions";

import Button from "../../views/buttons/Button";
import InputTextField from "../../views/inputtextfield/InputTextField";

import imageCompression from "browser-image-compression";
import endPoints from "../../API/endPoints";

export interface ICheckinGuestDetailsProps {
  roomSeqNo: any;
  onClose: () => void;
  guestDetails: any[];
  isSliderSaveCliked: (x: boolean) => void;
  setGuestDetails: React.Dispatch<React.SetStateAction<any[]>>;
}

const CheckinGuestDetails: React.FC<ICheckinGuestDetailsProps> = ({
  onClose,
  guestDetails,
  setGuestDetails,
  roomSeqNo,
  isSliderSaveCliked,
}) => {
  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { current_booking } = useSelector((state: RootState) => state.bookings);

  const { guestDetailsFromMobileGet } = endPoints.BOOKINGS;

  const UPLOAD_TYPES = ["Document", "Image"];

  const GENDERS = [
    { id: "male", label: "Male" },
    { id: "female", label: "Female" },
    { id: "others", label: "Others" },
  ];

  const CompressOptions = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1920,
  };

  const [uploadTab, setUploadTab] = useState(UPLOAD_TYPES[0]);

  const uploadDocs = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const Files = e.target.files;
    if (Files && Files.length > 0) {
      if (Files[0].size > 1024 * 1024 * 2) {
        toast.error("Image size can not be more than 2MB");
      } else {
        let FILE = await imageCompression(Files[0], CompressOptions);

        let guests = { ...guestDetails };
        FileToBase64(FILE).then((res) => {
          const val = res;
          guests[roomSeqNo][index].doc = val;
          setGuestDetails(guests);
        });
      }
    }
  };

  const uploadImages = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const Files = e.target.files;
    if (Files && Files.length > 0) {
      if (Files[0].size > 1024 * 1024 * 2) {
        toast.error("Image size can not be more than 2MB");
      } else {
        let FILE = await imageCompression(Files[0], CompressOptions);

        let guests = { ...guestDetails };

        FileToBase64(FILE).then((res) => {
          const val = res;
          guests[roomSeqNo][index].photo = val;
          setGuestDetails(guests);
        });
      }
    }
  };

  const getSrcFromImageFile = (image: any) => {
    if (typeof image === "string") {
      return getImage(image);
    }
    try {
      return URL.createObjectURL(image);
    } catch (error) {
      return "";
    }
  };

  const onNameChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let guests = { ...guestDetails };
    guests[roomSeqNo][index].name = e.target.value;
    setGuestDetails(guests);
  };

  const onEmailChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let val = e.target.value;

    let guests = { ...guestDetails };
    guests[roomSeqNo][index].email = val;

    if (validateEmail(val) || !val) {
      guests[roomSeqNo][index].validMail = true;
    } else {
      guests[roomSeqNo][index].validMail = false;
    }
    setGuestDetails(guests);
  };

  const onMobileChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let val = e.target.value.slice(0, 10);

    let guests = { ...guestDetails };
    guests[roomSeqNo][index].mobile = val;

    if (validateMobile(val) || !val) {
      guests[roomSeqNo][index].validMobile = true;
    } else {
      guests[roomSeqNo][index].validMobile = false;
    }
    setGuestDetails(guests);
  };

  const onAgeChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let val = e.target.value.slice(0, 3);

    if (validatePositiveInt(val) || !val) {
      let guests = { ...guestDetails };
      guests[roomSeqNo][index].age = val;
      setGuestDetails(guests);
    }
  };

  const onAddressChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let guests = { ...guestDetails };
    guests[roomSeqNo][index].address = e.target.value;
    setGuestDetails(guests);
  };

  const onGenderChange = (index: number, id: string) => {
    let guests = { ...guestDetails };
    guests[roomSeqNo][index].gender = id;
    setGuestDetails(guests);
  };

  const getGuestDetailsFromMobile = async (index: number) => {
    let guestInfo = guestDetails[roomSeqNo][index];
    if (guestInfo.validMobile && guestInfo.mobile) {
      try {
        const response = await gemsApi.post(`${guestDetailsFromMobileGet}`, {
          user_id: admin_id,
          mobile_no: guestInfo.mobile,
        });

        if (response.data.status === 1) {
          let result = response.data.guest_details;
          let guests = { ...guestDetails };

          guests[roomSeqNo][index].name = result.name;
          guests[roomSeqNo][index].email = result.email;
          guests[roomSeqNo][index].doc = result.doc;
          guests[roomSeqNo][index].age = result.age;
          guests[roomSeqNo][index].address = result.address;
          guests[roomSeqNo][index].gender = result.gender;
          guests[roomSeqNo][index].validMail = true;
          guests[roomSeqNo][index].validMobile = true;

          setGuestDetails(guests);
        }
      } catch (error) {
        toast("Error fetching guest details", {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i onClick={onClose} className="bi bi-x"></i>
        </div>
        <div className="standard-page--label">Add Guest Details</div>
        <div className="side__slider__sub__heading me-3">
          ID : {current_booking?.booking_details?.booking_id}
        </div>
      </div>
      <div className="height-64px"></div>

      <div className="slider__card__guest__details__wrapper">
        {guestDetails[roomSeqNo]?.map((guest: any, index: number) => {
          return (
            <div key={index} className="slider__guest__details mb-5">
              <div className="standard-page--label mt-1 mb-4">
                Guest {index + 1}
              </div>
              <div className="mobile__number__search__wrapper mb-3">
                <div className="mobile__number__input me-3">
                  <InputTextField
                    label="Mobile Number"
                    value={guest?.mobile}
                    handleChange={(e) => onMobileChange(index, e)}
                    helperText={
                      guest?.validMobile ? "" : "Invalid mobile number"
                    }
                  />
                </div>
                <div
                  onClick={() => getGuestDetailsFromMobile(index)}
                  className="mobile__search__input"
                >
                  <i className="bi bi-search"></i>
                </div>
              </div>

              <div className="guest__name__input mb-3">
                <InputTextField
                  label="Name"
                  value={guest?.name}
                  handleChange={(e) => onNameChange(index, e)}
                />
              </div>

              <div className="guest__email__age__wrapper mb-3">
                <div className="guest__email__address__input me-3">
                  <InputTextField
                    label="Email Address"
                    value={guest?.email}
                    handleChange={(e) => onEmailChange(index, e)}
                    helperText={guest?.validMail ? "" : "Invalid email address"}
                  />
                </div>
                <div className="guest__age__input">
                  <InputTextField
                    label="Age"
                    value={guest?.age}
                    handleChange={(e) => onAgeChange(index, e)}
                  />
                </div>
              </div>

              <div className="guest__address__wrapper mb-0">
                <InputTextField
                  label="Address (Optional)"
                  value={guest?.address}
                  handleChange={(e) => onAddressChange(index, e)}
                />
              </div>

              <div className="standard-page--label mb-3">Gender</div>

              <div className="select__input__label">
                {GENDERS.map((gender: typeof GENDERS[0]) => {
                  return (
                    <div
                      key={gender.id}
                      onClick={() => onGenderChange(index, gender.id)}
                      className={`me-4 mb-3 select__input__div${
                        gender.id === guest?.gender ? "__active" : ""
                      }`}
                    >
                      {gender.id === guest.gender && (
                        <i className="bi bi-check-lg icon--check"></i>
                      )}
                      {gender.label}
                    </div>
                  );
                })}
              </div>

              <div className="standard-page--label my-3">Upload Document</div>

              <div className="room__checkin__doc__upload mb-3">
                <div className="calendar__view__tab">
                  <ul
                    className="nav nav-tabs"
                    id={`uploadTabs${index}`}
                    role="tablist"
                  >
                    {UPLOAD_TYPES.map((type: string) => {
                      return (
                        <li
                          key={type}
                          className="nav-item"
                          role="presentation"
                          onClick={() => setUploadTab(type)}
                        >
                          <a
                            className={`nav-link ${
                              uploadTab === type ? "active" : ""
                            }`}
                            id={`${type}-tab-${index}`}
                            role="tab"
                          >
                            {type}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div
                className={`doc__upload__wrapper ${
                  uploadTab === "Document" ? "" : "d-none"
                }`}
              >
                <div
                  className="div_image_upload__checkin"
                  onClick={() =>
                    document.getElementById(`doc-upload${index}`)?.click()
                  }
                >
                  {guest?.doc ? (
                    <img
                      className="checkin__image"
                      src={getSrcFromImageFile(guest.doc)}
                      alt=""
                    />
                  ) : (
                    <div className="image_drag_area">
                      <i className="bi bi-images"></i>
                      <div className="text-area">Upload Document</div>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  id={`doc-upload${index}`}
                  className="d-none"
                  accept="image/png"
                  onChange={(e) => uploadDocs(index, e)}
                />
              </div>

              <div
                className={`doc__upload__wrapper ${
                  uploadTab === "Image" ? "" : "d-none"
                }`}
              >
                <div
                  className="div_image_upload__checkin"
                  onClick={() =>
                    document.getElementById(`image-upload${index}`)?.click()
                  }
                >
                  {guest?.photo ? (
                    <img
                      className="checkin__image"
                      src={getSrcFromImageFile(guest.photo)}
                      alt=""
                    />
                  ) : (
                    <div className="image_drag_area">
                      <i className="bi bi-images"></i>
                      <div className="text-area">Upload Image</div>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  id={`image-upload${index}`}
                  className="d-none"
                  accept="image/png"
                  onChange={(e) => uploadImages(index, e)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper justify-content-between w-100">
          <Button
            handleClick={() => {
              onClose();
              isSliderSaveCliked(true);
            }}
            className="primary-btn"
          >
            Save
          </Button>
          {/* <Button className="primary-btn delete__btn__wrapper">Cancel</Button> */}
        </div>
      </div>
    </>
  );
};

export default CheckinGuestDetails;
