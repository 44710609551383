import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useDispatch } from "react-redux";

import moment from "moment";

// import button
import InventoryBtn from "../../views/buttons/Button";
import Button from "../../views/buttons/Button";
import Loader from "../../views/loader/Loader";

// import select feild
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import { CATCH_MESSAGE, UpdateSidebar } from "../../UtilityFunctions";

// ota images
import Bookingjini from "../../assets/svg/bookingjini.svg";
import cmApi from "../../API/cmApi";
import beApi from "../../API/beApi";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { toast } from "react-toastify";

import {
    CancelConfirmationPrompt,
    ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";

//import update inventory sliding component
import UpdateInventory from "./UpdateInventory";
import SyncInventory from "./SyncInventory";
import BlockInventory from "./BlockInventory";
import UnblockInventory from "./UnblockInventory";
import ConfirmUpdateInventory from "./ConfirmUpdateInventory";
import PropertySoldout from "./PropertySoldout";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

//import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import kernelApi from "../../API/kernelApi";
import { nanoid } from "@reduxjs/toolkit";
import { Logout } from "../../UtilityFunctions";
import endpoints from "../../API/endPoints"
import SoldoutDates from "./SoldoutDates";




const Inventory = () => {
    const dispatch = useDispatch();
    UpdateSidebar("Inventory");

    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );
    const { admin_id, auth_token } = useSelector(
        (state: RootState) => state.auth
    );
    const { accessData, adminAcess } = useSelector(
        (state: RootState) => state.userAcess
    );

    // const { bulk_cm_loader, bulk_be_loader } = useSelector(
    //   (state: RootState) => state.sidebar
    // );
    const [openConfirmInventoryUpdate, setOpenConfirmInventoryUpdate] =
        useState(false);
    const [openSlideInventoryUpdate, setOpenSlideInventoryUpdate] =
        useState(false);
    const [openSlideInventorySync, setOpenSlideInventorySync] = useState(false);
    const [openSlideInventoryBlock, setOpenSlideInventoryBlock] = useState(false);
    const [openSlideInventoryUnblock, setOpenSlideInventoryUnblock] =
        useState(false);
    const [openSlidePropertySoldout, setOpenSlidePropertySoldout] =
        useState(false);

    const [openSlideSoldoutDates, setOpenSlideSoldoutDates] = useState(false);



    const [allRoomTypes, setAllRoomTypes] = useState<any>({
        labels: [],
        ids: [],
    });
    const [inventoryData, setInventoryData] = useState<any[]>([]);
    const [bookingEngineData, setBookingEngineData] = useState<any>([]);
    const [fromDate, setFromDate] = useState<any>(moment());
    const [toDate, setToDate] = useState<any>(moment().add(10, "d"));
    const [selectedRoomtype, setSelectedRoomtype] = useState<string>("");
    const [roomNights, setRoomNights] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [invLoading, setInvLoading] = useState(true);
    const [editableValue, setEditableValue] = useState<any>([]);
    const [applyButton, setApplyButton] = useState(true);
    const [editInventory, setEditInventory] = useState<any>();
    const [inventory, setInventory] = useState<any>([]);
    const [ota_Id, setOta_Id] = useState<any>([]);
    const [inventory_structure, setInventory_structure] = useState<any>({});
    const [updatedInventory, setUpdatedInventory] = useState<any>({});
    const [updatedIndex, setUpdatedIndex] = useState<any>([]);
    const [updateAllOta, setUpdateAllOta] = useState<any>(false);
    const [beIndex, setBeIndex] = useState<any>([]);
    const [cmIndex, setCmIndex] = useState<any>([]);
    const [cmOta_name, SetCmOta_name] = useState<any>([]);
    const [inv_data, setInvData] = useState<any>([]);
    const [invUnblockdata, setInvUnblockdata] = useState<any>([]);
    const [invblockdata, setInvblockdata] = useState<any>([]);
    const [beupdateResponse, setBeupdateResponse] = useState<any>();
    const [cmUpdateResponse, setCmUpdateResponse] = useState<any>([]);
    const [beSingleUpdatePayload, setBeSingleUpdatePayload] = useState<any>([]);

    const [be_loader, setBe_loader] = useState<any>(false);
    const [cm_loader, setCm_loader] = useState<any>(false);

    const [cm_singleUpdateLoader, setCm_singleUpdateLoader] = useState(false);
    const [be_singleUpdateLoader, setBe_singleUpdateLoader] = useState(false);

    const [syncLoader, setSyncLoader] = useState(false);

    const [cm_blockLoader, setCm_blockLoader] = useState(false);
    const [be_blockLoader, setBe_blockLoader] = useState(false);

    const [cm_unblockLoader, setCm_unblockLoader] = useState(false);
    const [be_unblockLoader, setBe_unblockLoader] = useState(false);

    const [allota_id, setAllota_id] = useState<any>([]);
    const [access, setAccess] = useState(true);

    const [dataAvailable, setDataAvailable] = useState<any>(null);

    const [updated, setUpdated] = useState('')
    const [selected, setSelected] = useState('')

    const [allChannels, setAllChannels] = useState<any>([]);

    const defaultMsg = { msg: "Inventory details not available" };
    const delay = 1500;

    //Endpoints Import
    const { getInventoryApi,
        hotelMasterRoomApi,
        beSingleUpdate,
        calenderUpdateApi,
        cmUnblockSpecificDates,
        beUnblockSpecificDates,
        cmBlockSpecificDates,
        beBlockSpecificDates } = endpoints.INVENTORY
    // --------------------------------

    // Check if user has access to this page
    useEffect(() => {
        const status = accessData.filter((iteam) => {
            return iteam?.code === "INVENTORY";
        })[0]?.access_value;
        adminAcess === 911
            ? setAccess(true)
            : status === 1
                ? setAccess(true)
                : setAccess(false);
    }, [accessData, adminAcess]);

    useEffect(() => {
        getAllRoomTypes();


        getAllChannels();
    }, [current_property]);

    // API call to get all room types
    const getAllRoomTypes = async () => {
        try {
            let res = await kernelApi.get(
                hotelMasterRoomApi + current_property.hotel_id
            );

            let room_types: string[] = [];
            let room_type_ids: any[] = [];
            res.data?.data?.map((item: any) => {
                room_types.push(item.room_type + " (" + item.room_type_id + ")");
                room_type_ids.push(item.room_type_id);
            });
            if (res.data.status == 1 && room_type_ids.length > 0) {
                setSelectedRoomtype(room_type_ids[0]);

                setAllRoomTypes({
                    labels: room_types,
                    ids: room_type_ids,
                });

                setDataAvailable(true);
                getInventory(room_type_ids[0], fromDate, toDate);
            }
            else {
                setTimeout(() => {
                    setIsLoading(false);
                    setInvLoading(false);
                    setDataAvailable(false);
                }, delay);
            }
        }
        catch {
            toast.error(CATCH_MESSAGE)
            setIsLoading(false);
            setInvLoading(false);
            setDataAvailable(false);
        }
    };


    const getAllChannels = async () => {
        setAllChannels([]);
        try {

            let fetch_all_channels = await cmApi.get(`/all-otas/${current_property.hotel_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth_token}`
                    }
                })

            if (fetch_all_channels.data.status == 1) {
                setAllChannels(fetch_all_channels.data.data);
            }
        }
        catch (error: any) {
            console.log(error);
        }

    }


    let modify_inv_structure: any = {};

    // API call to get inventory data
    const getInventory = async (
        roomtypeId: string,
        fromDate: any,
        toDate: any
    ) => {
        setRoomNights([]);
        try {
            let res = await cmApi.get(
                // '/getInventory_new/2211/2022-07-16/2022-07-25/6925'
                `/getInventory_new/${current_property.hotel_id}/${fromDate.format("YYYY-MM-DD")}/${toDate.format("YYYY-MM-DD")}/${roomtypeId}`
                ,
                // getInventoryApi +
                // current_property.hotel_id +
                // "/" +
                // fromDate.format("YYYY-MM-DD") +
                // "/" +
                // toDate.format("YYYY-MM-DD") +
                // "/" +
                // roomtypeId,
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            );


            // modify_inv_structure["bookingEngine"] = {
            //     inv: res.data.bookingengine_inventory,
            //     bookings: res.data.bookingengine_data_booking,
            //     ota_id: -1,
            //     ota_icon_path: res.data.bookingengine_logopath,
            //     ota_logo_path: res.data.bookingengine_logopath,
            //     ota_name: "bookingEngine",
            //     name: res.data.bookingengine_name,
            // };


            res.data.data?.map((inv: any, i: number) => {
                return (modify_inv_structure[inv.ota_name] = {
                    inv: inv.inv,
                    bookings: inv.bookings,
                    ota_id: inv.ota_id,
                    ota_icon_path: inv.ota_icon_path,
                    ota_logo_path: inv.ota_logo_path,
                    ota_name: inv.ota_name,
                    // name: inv.name,
                });
            });

            if (Object.keys(modify_inv_structure).length > 0) {
                setInvLoading(false);
            }
            setBookingEngineData({ ...res.data, bookingengine_inventory: res.data.data[res.data.data.length - 1].inv });
            setInventory_structure(modify_inv_structure);
            setUpdatedInventory(modify_inv_structure);

            setInventoryData(res.data.data);
            setIsLoading(false);
        } catch {
            toast.error(CATCH_MESSAGE)
            setIsLoading(false)
        }
    };



    const changeRoomtype = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setSelectedRoomtype(e.target.value);
        getInventory(e.target.value, fromDate, toDate);
    };

    const onDateChange = (date: any) => {
        setIsLoading(true);
        setFromDate(moment(date));
        setToDate(moment(date).add(10, "d"));
        getInventory(selectedRoomtype, moment(date), moment(date).add(10, "days"));
    };

    let label = allRoomTypes.labels[allRoomTypes.ids.indexOf(selectedRoomtype)];

    const upDateInventory = (
        i: any,
        ota_name: any,
        date: any,
        ota_id: any,
        no_of_rooms: any,
        block_status: any,
        room_type_id: any,
        inv: any,
        e: any,
        ota_logo_path: any
    ) => {
        // if (inv.block_status == 0 && no_of_rooms != e.target.textContent) {
        //   setApplyButton(true);
        // }
        // if (no_of_rooms == e.target.textContent) {
        //   setApplyButton(false);
        // }

        let no_rooms = [...editableValue];
        var index = no_rooms.findIndex(
            (x) => x.ota_name == ota_name && x.date == date
        );
        index === -1 && no_of_rooms != e.target.textContent
            ? no_rooms.push({
                ota_name: ota_name,
                date: date,
                new_inv: e.target.textContent,
                old_inv: no_of_rooms,
                logo: ota_logo_path,
            })
            : no_of_rooms != e.target.textContent &&
            (no_rooms[index] = {
                ota_name: ota_name,
                date: date,
                new_inv: e.target.textContent,
                old_inv: no_of_rooms,
                logo: ota_logo_path,
            });
        setEditableValue(no_rooms);

        // for filtering no_rooms array
        // var index = no_rooms.findIndex(
        //   (x) => x.ota_name == ota_name && x.date == date
        // );
        // index === -1 &&
        //   no_rooms.push({
        //     ota_name: ota_name,
        //     date: date,
        //     new_inv: e.target.textContent,
        //     old_inv: no_of_rooms,
        //     logo: ota_logo_path,
        //   });

        var digits = i.toString().split("");
        var realDigits = digits?.map(Number);

        // Storing index
        let inv_index = [...updatedIndex];
        if (!inv_index.includes(i)) {
            inv_index.push(i);
        }
        setUpdatedIndex(inv_index);
        //booking engine index
        let be_index = [...beIndex];
        if (ota_id == -1) {
            if (!be_index.includes(i)) {
                be_index.push(i);
            }
        }
        setBeIndex(be_index);

        //storing cm index
        let cm_index = [...cmIndex];
        let cm_ota_name = [...cmOta_name];
        if (ota_id !== -1) {
            if (!cm_index.includes(i)) {
                cm_index.push(i);
            }
            if (!cm_ota_name.includes(ota_name)) {
                cm_ota_name.push(ota_name);
            }
        }
        setCmIndex(cm_index);
        SetCmOta_name(cm_ota_name);

        //all ota ids
        let allota = [...allota_id];
        if (!allota.includes(ota_id)) {
            allota.push(ota_id);
        }
        setAllota_id(allota);

        let inventories = { ...updatedInventory };
        inventories[ota_name].inv[realDigits[1]].no_of_rooms =
            e.currentTarget.textContent;
        setUpdatedInventory(inventories);

        inv = { ...inv, no_of_rooms: parseInt(e.currentTarget.textContent) };
        if (ota_id !== -1 && no_of_rooms != e.target.textContent) {
            if (inv_data.some((data: any) => data.ota_id === ota_id)) {
                for (let i = 0; i < inv_data.length; i++) {
                    if (inv_data[i].ota_id === ota_id) {
                        inv_data[i].inv.push({
                            no_of_rooms: inv.no_of_rooms,
                            date: inv.date,
                            block_status: inv.block_status,
                            los: inv.los,
                        });
                    }
                }
            } else {
                inv_data.push({
                    ota_id: ota_id,
                    channel_name: ota_name,
                    inv: [
                        {
                            no_of_rooms: inv.no_of_rooms,
                            date: inv.date,
                            block_status: inv.block_status,
                            los: inv.los,
                        },
                    ],
                });
            }
            if (inv_data.length > 0) {
                setApplyButton(true);
            }
            setInvData([...inv_data]);
        }

        let val = e.currentTarget.textContent;

        // setRoomNights([...roomNights, i]);

        setEditInventory(val);
        // let val = e.target.innerHTML;
        // if (val.match(/^[0-9\b]+$/) || val === "") {
        //   setEditableValue([...editableValue, val]);
        // }
        // addInventory_Prompt(i, e);
    };

    const selectInventory = (i: any, inv: any, ota_name: any, ota_id: number) => {
        if (inv.block_status == 1 && access) {
            unblock_Prompt(i, inv, ota_name, ota_id);
        }

        // if (inv.block_status == 0) {
        //   setApplyButton(true);
        // }
        if (!roomNights.includes(i)) setRoomNights([...roomNights, i]);
    };

    const singleUpdate = async () => {
        setUpdatedInventory({});
        setInventory_structure({});

        //  setApplyButton(false);

        if (allota_id.includes(-1)) {
            setBe_loader(true);
            be_singleUpdate();
        }

        else {
            let removeBookingEngine = allota_id.map((item: any) => item);

            if (removeBookingEngine.length > 0) {
                setCm_loader(true);
                cm_singleUpdate();
            }
        }
        //  getInventory(selectedRoomtype, fromDate, toDate);
        // setOpenConfirmInventoryUpdate(false);
    };

    // --------------------------------------------------------
    // confirmation message to Unblock
    const unblock_Prompt = (i: any, inv: any, ota_name: any, ota_id: number) => {
        // let newIndex: any[] = [];
        // setInvUnblockdata([]);

        dispatch(
            ShowConfirmationPrompt(
                "Are you sure you want to unblock the inventory for selected date?",
                "DELETE",
                () => sendUnblockInvDataToAPI(i, inv, ota_name, ota_id),
                ["Cancel", "Unblock"]
            )
        );


    };

    const sendUnblockInvDataToAPI = (i, inv, ota_name, ota_id) => {
        const someArr: any[] = []
        if (ota_id !== -1) {
            // if (invUnblockdata.some((data: any) => data.ota_id === ota_id)) {
            //   for (let i = 0; i < invUnblockdata.length; i++) {
            //     if (invUnblockdata[i].ota_id === ota_id) {
            //       // invUnblockdata[i].inv.push({
            //       //   date: inv.date,
            //       //   block_status: inv.block_status,
            //       // });

            //     }
            //   }
            // }
            // else {

            someArr.push({
                ota_id: ota_id,
                channel_name: ota_name,
                inv: [
                    {
                        date: inv.date,
                        block_status: inv.block_status,
                    },
                ],
            });
            // setInvUnblockdata(prev => [...prev, ...someArr])
            // }
            // setInvUnblockdata(someArr);
            // setInvUnblockdata(invUnblockdata);
        }

        if (ota_id == -1) {
            be_unblockUpdate(inv.date);
        }
        else {
            cm_unblockUpdate(someArr);
        }

        dispatch(CancelConfirmationPrompt());
    }

    // confirmation message to block
    const block_Prompt = (i: any, inv: any, ota_name: any, ota_id: number) => {
        // setInvblockdata([]);
        dispatch(
            ShowConfirmationPrompt(
                "Are you sure you want to block the inventory for selected date",
                "DELETE",
                () => sendBlockDataToApi(i, inv, ota_name, ota_id),
                ["Cancel", "Block"]
            )
        );
    };


    const sendBlockDataToApi = (i, inv, ota_name, ota_id) => {
        const someArr: any[] = []
        if (ota_id !== -1) {
            // if (invblockdata.some((data: any) => data.ota_id === ota_id)) {
            //   for (let i = 0; i < invblockdata.length; i++) {
            //     if (invblockdata[i].ota_id === ota_id) {
            //       invblockdata[i].inv.push({
            //         date: inv.date,
            //         block_status: inv.block_status,
            //       });
            //     }
            //   }
            // }
            // else {
            //   invblockdata.push({
            //     ota_id: ota_id,
            //     channel_name: ota_name,
            //     inv: [
            //       {
            //         date: inv.date,
            //         block_status: inv.block_status,
            //       },
            //     ],
            //   });
            // }

            someArr.push({
                ota_id: ota_id,
                channel_name: ota_name,
                inv: [
                    {
                        date: inv.date,
                        block_status: inv.block_status,
                    },
                ],
            });
            // setInvblockdata([...invblockdata]);
            setInvblockdata([...invblockdata, ...someArr]);
        }

        if (ota_id == -1) {
            be_blockUpdate(inv.date);
        }
        else {
            cm_blockUpdate(someArr);
        }

        dispatch(CancelConfirmationPrompt());
    }
    // --------------------------------------------------------

    // validation for inventory cell
    const validateNumber = (e: any) => {
        if (
            !String(e.key).match(/^[0-9]$/) ||
            !window.getSelection() ||
            e.target.textContent.length > 2
        ) {
            e.preventDefault();
        }
    };

    const onSelectText = (e: React.FocusEvent<HTMLDivElement, Element>) => {
        if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(e.currentTarget);
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    };

    // single update for channel manager
    const be_singleUpdate = async () => {
        let be_array: any = [];
        beIndex?.map((x: any) => {
            let last = parseInt(x.charAt(x.length - 1));
            be_array.push(last);
        });
        let be_inv: any = [];
        updatedInventory["Bookingjini"].inv.forEach((ota: any, i: any) => {

            if (be_array.includes(i)) {
                be_inv.push({
                    no_of_rooms: ota.no_of_rooms,
                    date: ota.date,
                    block_status: ota.block_status,
                    los: ota.los,
                });
            }
        });

        try {
            let res = await beApi.post(
                beSingleUpdate,
                {
                    hotel_id: current_property.hotel_id,
                    inv: {
                        room_type_id: selectedRoomtype,
                        room_type: label,
                        inv: be_inv,
                    },
                    admin_id: admin_id,
                    ota_id: [-1],
                },
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            );
            setUpdated(res.data)
            setBe_loader(false);
            setBeupdateResponse(res.data);
            // getInventory(selectedRoomtype, fromDate, toDate);
            // getInventory(selectedRoomtype, fromDate, toDate);
            // if (res.data.status === 1) {
            //   getInventory(selectedRoomtype, fromDate, toDate);
            //   toast.success(res.data.message);
            // } else {
            //   toast.error(res.data.message);
            // }
        }
        catch (error) {
            toast.error("Something went wrong");
            setBe_loader(false);
        }
    };

    // single update for channel manager
    const cm_singleUpdate = async () => {

        try {
            let res = await cmApi.post(
                calenderUpdateApi,
                {
                    hotel_id: current_property.hotel_id,
                    room_type_id: selectedRoomtype,
                    room_type: label,
                    inv: inv_data,
                    admin_id: admin_id,
                },
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            );
            // res.data.map((ota: any, i: any) => {
            //   if (ota.error.status === 0) {
            //     err.push({
            //       ota: {
            //         ota_name: ota.error.ota_name,
            //         message: ota.error.response_msg,
            //       },
            //     });
            //   }
            // });

            setUpdated(res.data)
            setCm_loader(false);
            setCmUpdateResponse(res.data);
            // getInventory(selectedRoomtype, fromDate, toDate);
        }
        catch (error) {
            setCm_loader(false);
            closeConfirm();
            toast(CATCH_MESSAGE, {
                hideProgressBar: true,
                type: "error",
            });
        }
        // let cm_inv: any = [];
        // let cmUpdated_Inventory = { ...updatedInventory };

        // cmUpdated_Inventory = Object.keys(cmUpdated_Inventory).reduce(
        //   (object: any, key: any) => {
        //     if (key !== "bookingEngine") {
        //       object[key] = cmUpdated_Inventory[key];
        //     }
        //     return object;
        //   },
        //   {}
        // );
        // let inv_array: any = [];
        // let cm_array: any = [];
        // cmIndex.map((x: any) => {
        //   let last = parseInt(x.charAt(x.length - 1));
        //   cm_array.push(last);
        // });
        // Object.keys(cmUpdated_Inventory).forEach((ota: any, i: any) => {
        //   if (cmOta_name.includes(ota)) {
        //     cmUpdated_Inventory[ota].inv.forEach((data: any, id: any) => {
        //       if (cm_array.includes(id)) {
        //         inv_array.push({
        //           no_of_rooms: data.no_of_rooms,
        //           date: data.date,
        //           block_status: data.block_status,
        //           los: data.los,
        //         });
        //       }
        //     });
        //     cm_inv.push({
        //       ota_id: cmUpdated_Inventory[ota].ota_id,
        //       channel_name: cmUpdated_Inventory[ota].ota_name,
        //       inv: inv_array,
        //     });
        //   }
        // });
    };

    const cm_unblockUpdate = async (someArr) => {
        try {
            let res = await cmApi.post(
                cmUnblockSpecificDates,
                {
                    hotel_id: current_property.hotel_id,
                    room_type_id: selectedRoomtype,
                    room_type: label,
                    // inv: invUnblockdata,
                    inv: someArr,
                    admin_id: admin_id,
                },
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            );
            if (res.data[0].status === 1) {
                toast(res.data[0].response_msg, {
                    hideProgressBar: true,
                    type: "success",
                });
            }
            else {
                toast(res.data[0].response_msg, {
                    hideProgressBar: true,
                    type: "error",
                });
            }
            getInventory(selectedRoomtype, fromDate, toDate);
        }
        catch (error) {
            toast(CATCH_MESSAGE, {
                hideProgressBar: true,
                type: "error",
            });
        }
    };

    const be_unblockUpdate = async (date: any) => {
        let dates: any[] = [];
        dates.push({ date: date });
        try {
            let res = await beApi.post(
                beUnblockSpecificDates,
                {
                    hotel_id: current_property.hotel_id,
                    room_type_id: selectedRoomtype,
                    inv: dates,
                    admin_id: admin_id,
                    ota_id: [-1],
                },
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            );
            if (res.data.status === 1) {
                toast(res.data.response_msg, {
                    hideProgressBar: true,
                    type: "success",
                });
            } else {
                toast(res.data.response_msg, {
                    hideProgressBar: true,
                    type: "error",
                });
            }
            getInventory(selectedRoomtype, fromDate, toDate);
        }
        catch (error) {
            toast(CATCH_MESSAGE, {
                hideProgressBar: true,
                type: "error",
            });
        }
    };

    const cm_blockUpdate = async (someArr) => {
        try {

            let res = await cmApi.post(
                cmBlockSpecificDates,
                {
                    hotel_id: current_property.hotel_id,
                    room_type_id: selectedRoomtype,
                    room_type: label,
                    // inv: invblockdata,
                    inv: someArr,
                    admin_id: admin_id,
                },
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            );
            // setInvblockdata([]);
            // getInventory(selectedRoomtype, fromDate, toDate);
            if (res.data.status === 0 && res.data.error_status == 403) {
                toast.error(res.data.error)
                setTimeout(() => {
                    Logout()
                }, 1500);
            }
            if (res.data[0].status === 1) {
                getInventory(selectedRoomtype, fromDate, toDate);
                toast(res.data[0].response_msg, {
                    hideProgressBar: true,
                    type: "success",
                });
            }
            else {
                toast(res.data[0].response_msg, {
                    hideProgressBar: true,
                    type: "error",
                });
            }
        }
        catch (error) {
            toast.error("Something went wrong");
        }
    };

    const be_blockUpdate = async (date: any) => {
        let dates: any[] = [];
        dates.push({ date: date });
        try {
            let res = await beApi.post(
                beBlockSpecificDates,
                {
                    hotel_id: current_property.hotel_id,
                    room_type_id: selectedRoomtype,
                    inv: dates,
                    admin_id: admin_id,
                    ota_id: [-1],
                },
                {
                    headers: {
                        Authorization: "Bearer " + auth_token,
                    },
                }
            );
            if (res.data.status === 1) {
                getInventory(selectedRoomtype, fromDate, toDate);
                toast(res.data.response_msg, {
                    hideProgressBar: true,
                    type: "success",
                });
            } else {
                toast(res.data.response_msg, {
                    hideProgressBar: true,
                    type: "error",
                });
            }
        }
        catch (error) {
            toast(CATCH_MESSAGE, {
                hideProgressBar: true,
                type: "error",
            });
        }
    };

    const blockInventory = (i: any, inv: any, ota_name: any, ota_id: number) => {
        if (inv.block_status == 0) {
            // setApplyButton(false);
            block_Prompt(i, inv, ota_name, ota_id);
        }
    };

    useEffect(() => {
        if (!cm_loader || !be_loader) {
            selectedRoomtype && getInventory(selectedRoomtype, fromDate, toDate);
        }
    }, [cm_loader, be_loader]);

    const closeConfirm = () => {
        //  setApplyButton(false);
        if (!be_loader && !cm_loader) {
            setOpenConfirmInventoryUpdate(false);
            setCmUpdateResponse([]);


            if (updated) setTimeout(() => {
                setEditableValue([])
            }, 1000);;
        }
        // setUpdatedInventory({});
        // setInventory_structure({});
        // getInventory(selectedRoomtype, fromDate, toDate);
        setBeIndex([]);

        // setCmUpdateResponse([]);
        setBeupdateResponse(null);
        setInvData([]);
    };


    const updateInventory = () => {

        if (editableValue.length > 0) {
            if (
                editableValue.every(
                    (data: any) =>
                        parseInt(data.new_inv) <= parseInt(bookingEngineData.total_rooms)
                )
            ) {
                setOpenConfirmInventoryUpdate(true);
            } else {
                toast(
                    `Inventory cannot give more than
            ${bookingEngineData.total_rooms}`,
                    {
                        hideProgressBar: true,
                        type: "error",
                    }
                );
            }
        } else {
            toast("Change Atleast One Inventory", {
                hideProgressBar: true,
                type: "error",
            });
        }
    };

    const validate = (e: any) => {
        if (
            parseInt(e.currentTarget.textContent) >
            parseInt(bookingEngineData.total_rooms)
        ) {
            e.preventDefault();
        }
    };




    return (
        <>
            <div className="manage__inventory__action__wrapper inventory__sticky__operations__btn"
            // onClick={(e) => unselectInv(e)}
            >
                <div className="inventory__breadcrumbs">
                    <Breadcrumbs />
                    <div className="manage__inventory__action__left">

                        <div className="inventory__update__btn">
                            <InventoryBtn
                                className="update__btn"
                                icon="bi bi-eye"
                                handleClick={() => setOpenSlideSoldoutDates(true)}
                                isAccessible={access}
                            >
                                Soldout dates
                            </InventoryBtn>
                        </div>


                        <div className="inventory__update__btn">
                            <InventoryBtn
                                className="update__btn"
                                icon="bi bi-calendar2-x"
                                handleClick={() => setOpenSlidePropertySoldout(true)}
                                isAccessible={access}
                            >
                                Property Soldout
                            </InventoryBtn>
                        </div>

                        <div className="inventory__update__btn">
                            <InventoryBtn
                                className="update__btn"
                                icon="bi bi-file-arrow-up"
                                handleClick={() => setOpenSlideInventoryUpdate(true)}
                                isAccessible={access}
                            >
                                Update
                            </InventoryBtn>
                        </div>

                        <div className="inventory__sync__btn">
                            <InventoryBtn
                                className="sync__btn"
                                icon="bi bi-arrow-repeat"
                                handleClick={() => setOpenSlideInventorySync(true)}
                                isAccessible={access}
                            >
                                Sync
                            </InventoryBtn>
                        </div>
                        <div className="inventory__block__btn">
                            <InventoryBtn
                                className="block__btn"
                                icon="bi bi-lock"
                                handleClick={() => setOpenSlideInventoryBlock(true)}
                                isAccessible={access}
                            >
                                Block
                            </InventoryBtn>
                        </div>
                        <div className="inventory__unblock__btn">
                            <InventoryBtn
                                className="unblock__btn"
                                icon="bi bi-unlock"
                                handleClick={() => setOpenSlideInventoryUnblock(true)}
                                isAccessible={access}
                            >
                                Unblock
                            </InventoryBtn>
                        </div>
                    </div>
                </div>
                <div className="hr--line"></div>

                <div className="manage__inventory__action__right">
                    {
                        // applyButton && editableValue.length > 0 
                        selected ?
                            <InventoryBtn
                                className="inventory__apply__changes__btn"
                                icon="bi bi-check2"
                                //  handleClick={() => setOpenConfirmInventoryUpdate(true)}
                                handleClick={updateInventory}
                                isAccessible={access}
                            >
                                Apply
                            </InventoryBtn>
                            : <></>
                    }
                    <div className="mui__inputs">
                        <div className="inventory__date__select__btn mui__datepicker__input__padding__reset mui__input__fieldset__legends__unset">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label=""
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    value={fromDate}
                                    onChange={onDateChange}
                                    minDate={new Date()}
                                    inputFormat="dd MMM yyyy"
                                    disableMaskedInput
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onClick={(e) => setOpen(true)}
                                            onKeyDown={(e) => e.preventDefault()}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>

                        {allRoomTypes?.labels.length > 0 && (
                            <div className="inventory__room__type__select mui__input__fieldset__legends__unset">
                                <InputSelectField
                                    label=""
                                    options={allRoomTypes.labels}
                                    values={allRoomTypes.ids}
                                    value={selectedRoomtype}
                                    handleChange={changeRoomtype}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="manage__inventory__table__wrapper mb-5"
            // onClick={(e) => unselectInv(e)}
            >
                <div className="inventory__data__wrapper">
                    {isLoading ? (
                        <Loader className="ineventory--loader" />
                    ) : (
                        <>
                            <div className="inventory__sticky">
                                <div className="inventory__data__row">
                                    {bookingEngineData && bookingEngineData.total_rooms && (
                                        <div className="inventory__lable__header inventory__border__right inventory__border__bottom">
                                            <div className="inventory__label__text pb-1">
                                                TOTAL ROOMS
                                            </div>
                                            <div className="inventory__total__number mt-0">
                                                {bookingEngineData.total_rooms}
                                            </div>
                                        </div>
                                    )}

                                    {/* -------------------------- */}
                                    {bookingEngineData?.bookingengine_inventory?.map(
                                        (data: any, i: number) => {
                                            return (
                                                <div className="inventory__label__dates" key={i}>
                                                    <div
                                                        className={`inventory__dates__wrapper inventory__border__right inventory__border__bottom ${i ===
                                                            bookingEngineData.bookingengine_inventory.length -
                                                            1
                                                            ? "border-end-0"
                                                            : ""
                                                            }`}
                                                    >
                                                        <div className="inventory__date__month">
                                                            {moment(data.date).format("DD MMM")}
                                                        </div>
                                                        <div className="inventory__date__week">
                                                            {moment(data.date).format("ddd")}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}

                                </div>

                                {/* <div className="inventory__data__row">
                                    <div className="inventory__lable__header inventory__border__right inventory__border__bottom">
                                        <div className="inventory__label__booked">BOOKED</div>
                                    </div>

                                    <div className="inventory__label__dates">
                                        {bookingEngineData?.count?.map((data: number, i: any) => (
                                            <div
                                                className={`inventory__dates__wrapper inventory__border__right inventory__border__bottom ${i === bookingEngineData.count.length - 1
                                                    ? "border-end-0"
                                                    : ""
                                                    }`}
                                            >
                                                <div className="inventory__number">{data}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div> */}
                            </div>

                            {/* ----------------- */}
                            {invLoading ? (
                                <Loader className="ineventory--loader" />
                            ) : dataAvailable === false ? (
                                <div className="empty__room__div">
                                    <i className="bi bi-x-circle mb-3"></i>
                                    <div>{defaultMsg.msg}</div>
                                </div>
                            ) : (
                                inventory_structure &&
                                [...Object.keys(inventory_structure)].reverse()?.map((data: any, i: any) => {
                                    return (
                                        <div className="inventory__data__row">
                                            <div
                                                className={`inventory__lable__header inventory__border__right inventory__border__bottom `}
                                            >
                                                {inventory_structure ? (
                                                    <div className="inventory__ota__image">
                                                        <img
                                                            src={inventory_structure[data].ota_logo_path}
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="inventory__label__dates">
                                                {inventory_structure &&
                                                    inventory_structure[data]?.inv?.map(
                                                        (inv: any, id: any) => {
                                                            return (
                                                                <div
                                                                    className={`inventory__dates__wrapper inventory__border__right inventory__border__bottom ${id ===
                                                                        inventory_structure[data].inv.length - 1
                                                                        ? "border-end-0"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <div className="inventory__ota__number">
                                                                        <div
                                                                            contentEditable={access}
                                                                            suppressContentEditableWarning={true}
                                                                            // onDrop={(e) => e.preventDefault()}
                                                                            className={
                                                                                roomNights.includes("" + i + id) ?
                                                                                    `${inv.block_status === 1 ? "inventory__block__label mb-2" : "inventory__available__select mb-2"
                                                                                    }`
                                                                                    : `${inv.block_status === 1 ? "inventory__block__label mb-2" : "inventory__available mb-2"
                                                                                    }`
                                                                            }
                                                                            onKeyPress={(event) => {
                                                                                if (event.target === event.currentTarget) {

                                                                                    validateNumber(event);
                                                                                }
                                                                            }}
                                                                            onInput={(event) => {
                                                                                if (event.target === event.currentTarget) {

                                                                                    setSelected(nanoid())
                                                                                    validate(event);
                                                                                }
                                                                            }}
                                                                            onBlur={(event) => {
                                                                                if (event.target === event.currentTarget) {

                                                                                    upDateInventory(
                                                                                        "" + i + id,
                                                                                        inventory_structure[data].ota_name,
                                                                                        inv.date,
                                                                                        inventory_structure[data].ota_id,
                                                                                        inv.no_of_rooms,
                                                                                        inv.block_status,
                                                                                        inv.room_type_id,
                                                                                        inv,
                                                                                        event,
                                                                                        inventory_structure[data]
                                                                                            .ota_logo_path
                                                                                    );
                                                                                }
                                                                            }}
                                                                            onClick={(event) => {

                                                                                if (event.target === event.currentTarget) {
                                                                                    // unselectInv(event)
                                                                                    selectInventory(
                                                                                        "" + i + id,
                                                                                        inv,
                                                                                        inventory_structure[data].ota_name,
                                                                                        inventory_structure[data].ota_id,
                                                                                    )
                                                                                }
                                                                            }}


                                                                        // onFocus={(event) => {
                                                                        //   if (event.target === event.currentTarget)
                                                                        //     onSelectText(event);
                                                                        // }}

                                                                        >
                                                                            {inv.no_of_rooms.toString().length < 2
                                                                                ? "0" + inv.no_of_rooms
                                                                                : // ? "NA"
                                                                                inv.no_of_rooms}
                                                                            <div
                                                                                className="block_inventory"
                                                                                onClick={(event) =>
                                                                                    blockInventory(
                                                                                        "" + i + id,
                                                                                        inv,
                                                                                        inventory_structure[data]
                                                                                            .ota_name,
                                                                                        inventory_structure[data].ota_id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="bi bi-lock bi-lock-size"></i>
                                                                            </div>
                                                                        </div>


                                                                        {/* Disable the booked inventory for channels wise section */}
                                                                        {/* <div className="hr--line m-0 mb-2"></div>

                                                                        <div className="inventory__booked my-2">
                                                                            <i className="bi bi-calendar-check"></i>
                                                                            {inventory_structure[data].bookings[id]
                                                                                .booking > 9
                                                                                ? inventory_structure[data].bookings[id]
                                                                                    .booking
                                                                                : "0" +
                                                                                inventory_structure[data].bookings[id]
                                                                                    .booking}
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </>
                    )}
                </div>
            </div>

            {/*Inventory update Confirmation */}
            <SlidingPane
                hideHeader
                from="right"
                width="30%"
                isOpen={openConfirmInventoryUpdate}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={closeConfirm}
            >
                <ConfirmUpdateInventory
                    onClose={closeConfirm}
                    selectRoomType={
                        allRoomTypes.labels[allRoomTypes.ids.indexOf(selectedRoomtype)]
                    }
                    updatedinventory={updatedInventory}
                    inventory={inventory_structure}
                    index={updatedIndex}
                    allChannel={allChannels}
                    room_type={selectedRoomtype}
                    singleUpdate={() => singleUpdate()}
                    cmResponse={cmUpdateResponse}
                    beResponse={beupdateResponse}
                    beLoader={be_loader}
                    cmLoader={cm_loader}
                    old_new_inventory={editableValue}
                />
            </SlidingPane>

            {/* Inventory Update */}
            <SlidingPane
                hideHeader
                from="right"
                width="45%"
                isOpen={openSlideInventoryUpdate}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => {
                    //  closeSlider();
                    if (!cm_singleUpdateLoader && !be_singleUpdateLoader) {
                        setOpenSlideInventoryUpdate(false);
                    }
                }}
            //  onRequestClose={() => setOpenSlideInventoryUpdate(false)}
            >
                <UpdateInventory
                    // onClose={() => {
                    //   closeSlider();
                    // }}
                    onClose={() => {
                        if (!cm_singleUpdateLoader && !be_singleUpdateLoader) {
                            setOpenSlideInventoryUpdate(false);
                        }
                    }}
                    selectRoomType={
                        allRoomTypes.labels[allRoomTypes.ids.indexOf(selectedRoomtype)]
                    }
                    allChannel={allChannels}
                    room_type={selectedRoomtype}
                    inventory={inventory_structure}
                    getInventory={() => getInventory(selectedRoomtype, fromDate, toDate)}
                    close_update_slider={setOpenSlideInventoryUpdate}
                    open_update_slider={openSlideInventoryUpdate}
                    cmloader={cm_singleUpdateLoader}
                    beloader={be_singleUpdateLoader}
                    setLoader_cm={setCm_singleUpdateLoader}
                    setLoader_be={setBe_singleUpdateLoader}
                    totalRooms={bookingEngineData.total_rooms}
                    api_data={inventory_structure?.bookingEngine}
                />
            </SlidingPane>

            {/* Inventory Sync */}
            <SlidingPane
                hideHeader
                from="right"
                width="40%"
                isOpen={openSlideInventorySync}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => {
                    if (!syncLoader) {
                        setOpenSlideInventorySync(false);
                    }
                }}
            // onRequestClose={() => setOpenSlideInventorySync(false)}
            >
                <SyncInventory
                    onClose={() => {
                        if (!syncLoader) {
                            setOpenSlideInventorySync(false);
                        }
                    }}
                    selectRoomType={
                        allRoomTypes.labels[allRoomTypes.ids.indexOf(selectedRoomtype)]
                    }
                    selectRoomType_id={selectedRoomtype}
                    allChannel={allChannels}
                    getInventory={() => getInventory(selectedRoomtype, fromDate, toDate)}
                    sync_loader={syncLoader}
                    setSync_loader={setSyncLoader}
                    api_data={inventory_structure?.bookingEngine}
                />
            </SlidingPane>

            {/* Block Inventory */}
            <SlidingPane
                hideHeader
                from="right"
                width="45%"
                isOpen={openSlideInventoryBlock}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => {
                    if (!cm_blockLoader && !be_blockLoader) {
                        setOpenSlideInventoryBlock(false);
                    }
                }}
            >
                <BlockInventory
                    onClose={() => {
                        if (!cm_blockLoader && !be_blockLoader) {
                            setOpenSlideInventoryBlock(false);
                        }
                    }}
                    selectRoomType={
                        allRoomTypes.labels[allRoomTypes.ids.indexOf(selectedRoomtype)]
                    }
                    selectRoomType_id={selectedRoomtype}
                    allChannel={allChannels}
                    getInventory={() => getInventory(selectedRoomtype, fromDate, toDate)}
                    cmBlockLoader={cm_blockLoader}
                    beBlockLoader={be_blockLoader}
                    setBlockLoader_cm={setCm_blockLoader}
                    setBlockLoader_be={setBe_blockLoader}
                    api_data={inventory_structure?.bookingEngine}
                />
            </SlidingPane>

            {/* Unblock Inventory */}
            <SlidingPane
                hideHeader
                from="right"
                width="45%"
                isOpen={openSlideInventoryUnblock}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => {
                    if (!cm_unblockLoader && !be_unblockLoader) {
                        setOpenSlideInventoryUnblock(false);
                    }
                }}
            >
                <UnblockInventory
                    onClose={() => {
                        if (!cm_unblockLoader && !be_unblockLoader) {
                            setOpenSlideInventoryUnblock(false);
                        }
                    }}
                    selectRoomType={
                        allRoomTypes.labels[allRoomTypes.ids.indexOf(selectedRoomtype)]
                    }
                    selectRoomType_id={selectedRoomtype}
                    allChannel={allChannels}
                    getInventory={() => getInventory(selectedRoomtype, fromDate, toDate)}
                    cmUnblockLoader={cm_unblockLoader}
                    beUnblockLoader={be_unblockLoader}
                    setUnblockLoader_cm={setCm_unblockLoader}
                    setUnblockLoader_be={setBe_unblockLoader}
                    api_data={inventory_structure?.bookingEngine}
                />
            </SlidingPane>

            {/* property Soldout */}
            <SlidingPane
                hideHeader
                from="right"
                width="40%"
                isOpen={openSlidePropertySoldout}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setOpenSlidePropertySoldout(false)}
            >
                <PropertySoldout
                    onClose={() => setOpenSlidePropertySoldout(false)}
                    selectRoomType_id={selectedRoomtype}
                    getInventory={() => getInventory(selectedRoomtype, fromDate, toDate)}
                />
            </SlidingPane>


            {/* Soldout Dates*/}
            <SlidingPane
                hideHeader
                from="right"
                width="30%"
                isOpen={openSlideSoldoutDates}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setOpenSlideSoldoutDates(false)}
            >
                <SoldoutDates
                    onClose={() => setOpenSlideSoldoutDates(false)}
                />
            </SlidingPane>
        </>
    );
};

export default Inventory;
