import React, { useEffect, useState } from "react";
import {
  CATCH_MESSAGE,
  getImage,
  Logout,
  UpdateSidebar,
} from "../../UtilityFunctions";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../views/buttons/Button";
// Sliding Pane Import
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import AddChannelSlider from "./AddChannelSlider";
import { useNavigate } from "react-router";
import kernelApi from "../../API/kernelApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import beApi from "../../API/beApi";
import cmApi from "../../API/cmApi";
import { OTAManageAction } from "../../redux/actions/ManageChannell";
import Loader from "../../views/loader/Loader";
import endPoints from "../../API/endPoints";

const ManageChannel = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  UpdateSidebar("Manage Channels");

  // States
  const [openAddChannel, setOpenAddChannel] = useState(false);

  const [allActiveChannels, setAllActiveChannels] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [allSetup, setAllSetUp] = useState<any>();
  const [showChannels, setShowChannels] = useState<any[]>([]);

  //Endpoints Import
  const { getAddedChannelsApi, checkAllSetup } = endPoints.MANAGECHANNEL;
  // --------------------------------

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Active Channell List New One
  const getAllActiveChannels = async () => {
    setLoader(true);
    try {
      let allActiveChnlRes = await cmApi.get(
        `${getAddedChannelsApi}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (allActiveChnlRes.data.status === 1) {
        setShowChannels(allActiveChnlRes.data.data);
        setAllActiveChannels(allActiveChnlRes.data.data);
        setLoader(false);
      } else {
        // toast.error(`${allActiveChnlRes.data.message} `);
        setAllActiveChannels([]);
        setLoader(false);
      }
    } catch (error: any) {
      setAllActiveChannels([]);
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Check All Set Up For Exclamation Mark
  const getAllSetUpChannels = async () => {
    // setLoader(true);
    try {
      let allSetUpChnlRes = await beApi.get(
        `${checkAllSetup}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (allSetUpChnlRes.data.status === 1) {
        setAllSetUp(allSetUpChnlRes.data.status);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      } else {
        setAllSetUp(allSetUpChnlRes.data.status);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        // toast.error(`${allSetUpChnlRes.data.message} `);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      if (error.response.status === 401) {
        toast.error(error.response.message);
        Logout();
      }
    }
  };

  useEffect(() => {
    getAllActiveChannels();
    getAllSetUpChannels();
  }, [current_property]);

  return (
    <>
      <div className="property__setup__room__types">
        <div className="property__breadcrumbs">
          <Breadcrumbs />
          <div className="manage__property__action__right">
            <Button
              icon="bi bi-plus"
              handleClick={() => setOpenAddChannel(true)}
            >
              Add Channel
            </Button>
          </div>
        </div>
        <div className="hr--line"></div>
        {loader ? (
          <div className="loader-setup">
            <Loader />
          </div>
        ) : showChannels.length > 0 ? (
          <div className="manage__room__type__wrapper mt-4">
            {showChannels.map((itm) => {
              return (
                <div className="manage__room__type__card mb-4" key={itm.id}>
                  <div className="manage__room__type ms-0">
                    <div className="image--container mng-chnl">
                      <>
                        <div className="connection_status_wrapper">
                          {itm.ota_id === -1 ? (
                            <div className={`connection-status connected`}>
                              <div className="connection-circle">
                                <i className="bi bi-circle-fill"></i>
                              </div>
                              <p>Connected</p>
                            </div>
                          ) : (
                            <div
                              className={`connection-status ${itm.is_connected === 1
                                  ? "connected"
                                  : "disconnected"
                                }`}
                            >
                              <div className="connection-circle">
                                <i className="bi bi-circle-fill"></i>
                              </div>
                              {itm.is_connected === 1 ? (
                                <p>Connected</p>
                              ) : (
                                <p>Disconnected</p>
                              )}
                            </div>
                          )}
                        </div>
                        {itm.ota_id === -1 ? (
                          <>
                            {allSetup === 0 && (
                              <div className="info__alert">
                                <i className="bi bi-exclamation-circle"></i>
                              </div>
                            )}
                            <img
                              src={itm.logo}
                              alt={itm.ota_name}
                              className="chnl-name"
                            />
                          </>
                        ) : (
                          <img
                            src={itm.logo}
                            alt={itm.ota_name}
                            className="chnl-name"
                          />
                        )}
                      </>
                    </div>

                    <div className="hr--line mb-0"></div>

                    <div className="room__type__name__details  justify-content-center">
                      {itm.ota_name === "BookingEngine" ? (
                        <div style={{ width: "100%" }}>
                          <Button
                            handleClick={() => {
                              navigate("booking-engine");
                            }}
                          >
                            Manage
                          </Button>
                        </div>
                      ) : (
                        <div
                          className="room--type-label"
                          style={{ width: "100%" }}
                        >
                          <Button
                            handleClick={() => {
                              navigate("ota-manage");
                              dispatch(OTAManageAction(itm));
                            }}
                          >
                            Manage
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>You don’t have any channels yet</div>
          </div>
        )}
      </div>

      {/* Sliding Pane For Adding Channels */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openAddChannel}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOpenAddChannel(false);
        }}
      >
        <AddChannelSlider
          onClose={() => {
            setOpenAddChannel(false);
          }}
          allActiveChannels={allActiveChannels}
          getAllActiveChannels={getAllActiveChannels}
        />
      </SlidingPane>
    </>
  );
};

export default ManageChannel;
