import { ADD_NUMBER_OF_FLOORS, ADD_SERVICEABLE_FLOORS, RESET_FLOOR_SELECTION } from "../actionTypes"

const initialState = {
    number_of_floors: 1,
    serviceable_floors: []
}

const AddFloorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_NUMBER_OF_FLOORS:
            return {
                ...state,
                number_of_floors: action.payload
            }
        case ADD_SERVICEABLE_FLOORS:
            return {
                ...state,
                serviceable_floors: action.payload
            }
        case RESET_FLOOR_SELECTION:
            return initialState
        default: return state
    }
}

export default AddFloorsReducer;