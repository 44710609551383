import React from 'react'

export interface ILeftSideNav {
  children?: JSX.Element;
}

const LeftSideNav: React.FC<ILeftSideNav> = ({ children }) => {
  return (
    <div className="div__input__wrapper__left div__left__side__box">
      <div className="logo__wrapper"></div>
      {children}
    </div>
  )
}

export default LeftSideNav;