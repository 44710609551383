import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import InputTextField from '../../views/inputtextfield/InputTextField';
import Button from '../../views/buttons/Button';
import beApi from '../../API/beApi';
import { toast } from "react-toastify";

interface IResendMail {
    onClose: () => void;
    bookingId: any;
    // customerEmailId: customerEmailId; 
}

const ResendMail: React.FC<IResendMail> = ({ onClose, bookingId }) => {
    // Redux 
    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );
    const { auth_token } = useSelector(
        (state: RootState) => state.auth
    );

    // State
    const [emailId, setEmailId] = useState<any>("");
    const [loader, setLoader] = useState<boolean>(false);

    // send email handler
    const sendEmail = async () => {
        setLoader(true);
        try {
            const response = await beApi.post(`/send-mail-manual`,
                {
                    email: emailId,
                    booking_id: bookingId
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth_token}`
                    }
                }
            );
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setLoader(false);
            } 
        } catch {
            setLoader(false);
            toast.error("Something went wrong!")
        }
    }

    return (
        <>
            {console.log("*bookingId : ", bookingId)}
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Send a mail</div>
            </div>
            <div className="height-64px"></div>

            <div className="fetch__booking__wrapper">
                <div className="booking__id__wrapper">
                    <InputTextField
                        label="Email Address"
                        value={emailId}
                        handleChange={(e) => { setEmailId(e.target.value) }}
                    />
                </div>
            </div>

            <div className="side__slider__panel__btn">
                <div className="button__wrapper ">
                    <Button
                        className="me-3"
                        handleClick={ () => sendEmail() }
                        loader={loader}
                    >
                        Send
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ResendMail