import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import ManagePropertyDropdown from '../manageproperty/ManagePropertyDropdown';

const AppHeader = () => {

    const { subscription_days_left } = useSelector(
        (state: RootState) => state.auth
    );

    return (
        <>
            <div className="header-wrapper header-bg-color header-position">
                <div className="header__content__wrapper">
                    {subscription_days_left && subscription_days_left <= 7 ? <div className="subscription__days__left me-2">{subscription_days_left} Days remains for auto renewal</div> : ""}

                    <ManagePropertyDropdown />
                </div>
            </div>
        </>
    )
}

export default AppHeader;
