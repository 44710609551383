import React, { useState, useEffect } from "react";
import axios from "axios";

// import Nivo Charts
import { ResponsivePie } from "@nivo/pie";
import Loader from "../../views/loader/Loader";
import { Logout } from "../../UtilityFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import kernelApi from "../../API/kernelApi";
import endPoints from "../../API/endPoints";

const RoomNightsRevenueData = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const pieRoomNightRevenueData = ["Today", "MTD", "YTD"];
  const [activeMenuTab, setActiveMenuTab] = useState<any>("Today");
  const [pieTodayChartData, setTodayPieChartData] = useState<any>();
  const [pieMTDChartData, setPieMTDChartData] = useState<any>();
  const [pieYTDChartData, setPieYTDChartData] = useState<any>();
  const [loadingTodayData, setLoadingTodayData] = useState(true);
  const [loadingMTDData, setLoadingMTDData] = useState(true);
  const [loadingYTDData, setLoadingYTDData] = useState(true);

  const { channelWiseBookedRoomNightsRevenue } = endPoints.DASHBOARDDATA;

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  useEffect(() => {
    getTodayPieChartData();
    getMTDPieChartData();
    getYTDPieChartData();
  }, [current_property]);
  // useEffect(() => {
  //     if (activeMenuTab === "Today") {
  //         getTodayPieChartData();
  //     } else if (activeMenuTab === "MTD") {
  //         getMTDPieChartData();
  //     } else if (activeMenuTab === "YTD") {
  //         getYTDPieChartData();
  //     }
  // }, [activeMenuTab]);

  const getTodayPieChartData = async () => {
    // setLoadingTodayData(true);
    try {
      const res = await kernelApi.get(
        `${channelWiseBookedRoomNightsRevenue}/${current_property.hotel_id}/TODAY/null/null`
        // `https://kernel.bookingjini.com/channel-wise-booked-room-nights-revenue/1993/TODAY/null/null`
      );

      if (res.data.status === 1) {
        setTodayPieChartData(res.data);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    // setLoadingTodayData(false);
  };

  const getMTDPieChartData = async () => {
    // setLoadingMTDData(true);
    try {
      const res = await kernelApi.get(
        `${channelWiseBookedRoomNightsRevenue}/${current_property.hotel_id}/MTD/null/null`
        // `https://kernel.bookingjini.com/channel-wise-booked-room-nights-revenue/1993/MTD/null/null`
      );
      if (res.data.status === 1) {
        setPieMTDChartData(res.data);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    // setLoadingMTDData(false);
  };

  const getYTDPieChartData = async () => {
    // setLoadingYTDData(true);
    try {
      const res = await kernelApi.get(
        `${channelWiseBookedRoomNightsRevenue}/${current_property.hotel_id}/YTD/null/null`
        // `https://kernel.bookingjini.com/channel-wise-booked-room-nights-revenue/1993/YTD/null/null`
      );
      if (res.data.status === 1) {
        setPieYTDChartData(res.data);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    // setLoadingYTDData(false);
  };
  return (
    <>
      <div className="dashboard__standard__header pt-1 mb-4">
        <div className="dashboard__standard__label">
          Room Nights and Revenue
        </div>
        <div className="dashboard__standard__action__btns">
          <ul className="nav nav-tabs" id="duesTab" role="tablist">
            {pieRoomNightRevenueData.map((tab) => {
              return (
                <li
                  className="nav-item"
                  role="presentation"
                  key={tab}
                  onClick={() => switchMenuTab(tab)}
                >
                  <a
                    className={`nav-link ${tab === activeMenuTab ? "active" : ""
                      }`}
                    id={`${tab}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab}`}
                    aria-controls={`#${tab}`}
                    role="tab"
                    aria-selected="true"
                  >
                    {tab}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {activeMenuTab === "Today" ? (
        <div className="dashborad__standard__chart__wrapper">
          {/* {!loadingTodayData ? */}
          {pieTodayChartData?.roomNightsPieChartData?.length > 0 &&
            pieTodayChartData?.revenuePieChartData?.length > 0 ? (
            <>
              <div className="dashboard__pie__chart">
                <div className="pie__chart__wrapper">
                  <div className="center__data__inside__pie">
                    <div className="pie__label">Room Nights</div>
                    <div className="pie__data">
                      {pieTodayChartData?.totalRoomNights}
                    </div>
                  </div>

                  {pieTodayChartData && (
                    <ResponsivePie
                      data={pieTodayChartData?.roomNightsPieChartData}
                      // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                      margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      enableArcLabels={true}
                      enableArcLinkLabels={false}
                      animate={true}
                      colors={pieTodayChartData.colors}
                      arcLabelsTextColor={{
                        theme: "labels.text.fill",
                        from: "color",
                        modifiers: [["darker", 20]],
                      }}
                    />
                  )}
                </div>
                <div className="pie__chart__wrapper">
                  <div className="center__data__inside__pie">
                    <div className="pie__label">Revenue</div>
                    <div className="pie__data">
                      {pieTodayChartData?.totalRevenue}
                    </div>
                  </div>
                  {pieTodayChartData && (
                    <ResponsivePie
                      data={pieTodayChartData?.revenuePieChartData}
                      // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                      margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      arcLabelsTextColor={{
                        theme: "labels.text.fill",
                        from: "color",
                        modifiers: [["darker", 20]],
                      }}
                      enableArcLabels={true}
                      enableArcLinkLabels={false}
                      animate={true}
                      colors={pieTodayChartData.colors}
                    />
                  )}
                </div>
              </div>
              <div className="dashboard__pie__legends">
                <div className="ota__item__wrapper">
                  {pieTodayChartData?.roomNightsPieChartData?.map(
                    (labelData: any, index: number) => {
                      return (
                        <div
                          key={labelData.id}
                          className="ota__name__list me-4 mb-3"
                        >
                          <div
                            className="ota__color__box me-2"
                            style={{
                              backgroundColor: pieTodayChartData.colors[index],
                            }}
                          ></div>
                          <div className="ota__label">{labelData.label}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="pie-nodata-container">
              <div className="no-data-label-container">
                <div className="icon-container">
                  <i className="bi bi-x-circle mb-3"></i>
                </div>

                <div className="no-data-label">
                  No Data on Room Nights and Revenue
                </div>
              </div>
            </div>
          )}

          {/* } */}
        </div>
      ) : activeMenuTab === "MTD" ? (
        <div className="dashborad__standard__chart__wrapper">
          {/* {!loadingMTDData ? */}
          {pieMTDChartData?.roomNightsPieChartData?.length > 0 &&
            pieMTDChartData?.revenuePieChartData?.length > 0 ? (
            <>
              <div className="dashboard__pie__chart">
                <div className="pie__chart__wrapper">
                  <div className="center__data__inside__pie">
                    <div className="pie__label">Room Nights</div>
                    <div className="pie__data">
                      {pieMTDChartData?.totalRoomNights}
                    </div>
                  </div>
                  {pieMTDChartData && (
                    <ResponsivePie
                      data={pieMTDChartData?.roomNightsPieChartData}
                      // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                      margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      enableArcLabels={true}
                      enableArcLinkLabels={false}
                      animate={true}
                      colors={pieMTDChartData.colors}
                      arcLabelsTextColor={{
                        theme: "labels.text.fill",
                        from: "color",
                        modifiers: [["darker", 20]],
                      }}
                    />
                  )}
                </div>
                <div className="pie__chart__wrapper">
                  <div className="center__data__inside__pie">
                    <div className="pie__label">Revenue</div>
                    <div className="pie__data">
                      {pieMTDChartData?.totalRevenue}
                    </div>
                  </div>
                  {pieMTDChartData && (
                    <ResponsivePie
                      data={pieMTDChartData?.revenuePieChartData}
                      // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                      margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      enableArcLabels={true}
                      enableArcLinkLabels={false}
                      animate={true}
                      colors={pieMTDChartData.colors}
                      arcLabelsTextColor={{
                        theme: "labels.text.fill",
                        from: "color",
                        modifiers: [["darker", 20]],
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="dashboard__pie__legends">
                <div className="ota__item__wrapper">
                  {pieMTDChartData?.roomNightsPieChartData?.map(
                    (labelData: any, index: number) => {
                      return (
                        <div
                          key={labelData.id}
                          className="ota__name__list me-4 mb-3"
                        >
                          <div
                            className="ota__color__box me-2"
                            style={{
                              backgroundColor: pieMTDChartData.colors[index],
                            }}
                          ></div>
                          <div className="ota__label">{labelData.label}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="pie-nodata-container">
              <div className="no-data-label-container">
                <div className="icon-container">
                  <i className="bi bi-x-circle mb-3"></i>
                </div>

                <div className="no-data-label">
                  No Data on Room Nights and Revenue
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="dashborad__standard__chart__wrapper">
          {/* {!loadingYTDData ? */}
          {pieYTDChartData?.roomNightsPieChartData?.length > 0 &&
            pieYTDChartData?.revenuePieChartData?.length > 0 ? (
            <>
              <div className="dashboard__pie__chart">
                <div className="pie__chart__wrapper">
                  <div className="center__data__inside__pie">
                    <div className="pie__label">Room Nights</div>
                    <div className="pie__data">
                      {pieYTDChartData?.totalRoomNights}
                    </div>
                  </div>
                  {pieYTDChartData && (
                    <ResponsivePie
                      data={pieYTDChartData?.roomNightsPieChartData}
                      // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                      margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      enableArcLabels={true}
                      enableArcLinkLabels={false}
                      animate={true}
                      colors={pieYTDChartData.colors}
                      arcLabelsTextColor={{
                        theme: "labels.text.fill",
                        from: "color",
                        modifiers: [["darker", 20]],
                      }}
                    />
                  )}
                </div>
                <div className="pie__chart__wrapper">
                  <div className="center__data__inside__pie">
                    <div className="pie__label">Revenue</div>
                    <div className="pie__data">
                      {pieYTDChartData?.totalRevenue}
                    </div>
                  </div>
                  {pieYTDChartData && (
                    <ResponsivePie
                      data={pieYTDChartData?.revenuePieChartData}
                      // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                      margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      enableArcLabels={true}
                      enableArcLinkLabels={false}
                      animate={true}
                      colors={pieYTDChartData.colors}
                      arcLabelsTextColor={{
                        theme: "labels.text.fill",
                        from: "color",
                        modifiers: [["darker", 20]],
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="dashboard__pie__legends">
                <div className="ota__item__wrapper">
                  {pieYTDChartData?.roomNightsPieChartData?.map(
                    (labelData: any, index: number) => {
                      return (
                        <div
                          key={labelData.id}
                          className="ota__name__list me-4 mb-3"
                        >
                          <div
                            className="ota__color__box me-2"
                            style={{
                              backgroundColor: pieYTDChartData.colors[index],
                            }}
                          ></div>
                          <div className="ota__label">{labelData.label}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="pie-nodata-container">
              <div className="no-data-label-container">
                <div className="icon-container">
                  <i className="bi bi-x-circle mb-3"></i>
                </div>

                <div className="no-data-label">
                  No Data on Room Nights and Revenue
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RoomNightsRevenueData;
