import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

// import buttons
import NextButton from "../../views/buttons/NextButton";
import BackButton from "../../views/buttons/Button";

//import utility functions

// import animate css
import "animate.css";

import Tree from "../../assets/img/tree.svg";
import GroundFloor from "../../assets/img/ground_floor.svg";
import FloorBlock from "../../assets/img/floor_block.svg";
import MoreFloor from "../../assets/img/more_floor.svg";

import { RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AddNumberOfFloors } from "../../redux/actions/AddFloorsAction";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";

const CreateFloors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { property_data } = useSelector((state: RootState) => state.properties);

  const [floors, setFloors] = useState(
    store.getState().add_floors.number_of_floors
  );
  const [move, setMove] = useState("");

  let animateUp = "animate__animated animate__bounceOutUp";
  let animateDown = "animate__animated animate__bounceInDown";

  const GetClassName = (floor: number) => {
    if (floors === floor && move === "down") {
      return animateDown;
    } else if (floors === floor - 1) {
      if (move === "up") {
        return animateUp;
      } else {
        return "d-none";
      }
    } else if (floors < floor - 1) {
      return "d-none";
    } else {
      return "";
    }
  };

  /* const BackButtonClick = () => {
    navigate("/add-room-type/success");
  };
  */

  const SubmitFloors = () => {
    dispatch(AddNumberOfFloors(floors));
    setTimeout(() => {
      navigate("/add-floors/serviceable-floors");
    }, 500);
  };

  return (
    <>
      <div className="div-page-wrapper">
        <div className="div__wrapper">
          <LeftSideNav>
            <>
              <div className="div__user__testimonials__wrapper">
                <div className="screen__progress__wrapper">
                  <div className="progress__status__wrapper">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">
                      Create {property_data.data.length === 1 ? "First" : ""}{" "}
                      Property
                    </div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">
                      Select Subscription Plan
                    </div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">Create Room Type</div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper">
                      <i className="bi bi-play-fill progress--icon--play"></i>
                    </div>
                    <div className="status__label">Create Floors</div>
                  </div>
                  <div className="progress__sub__list__wrapper">
                    <div className="progress__sub__listitems progress--sub--item--text">
                      &nbsp;- Create Floors
                    </div>
                    <div className="progress__sub__listitems">
                      Select Serviceable Floors
                    </div>
                  </div>
                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper"></div>
                    <div className="status__label">Add Rooms</div>
                  </div>
                </div>
              </div>

              <div className="progress-bar-wrapper">
                <div className="progress-bar-text">1/2</div>
                <div className="progress-bar-status">
                  <ProgressBar percent={50} filledBackground="#FFFFFF" />
                </div>
              </div>
            </>
          </LeftSideNav>

          <RightSideContent>
            <>
              <CurrentPropertyLabel />
              <div className="div--head--label">Create Floors</div>
              <div className="div--helper--text">Use the buttons to create</div>
              <div className="standard-page--label my-3">
                How many floors are there in your property?
              </div>
              <div className="increment__decrement__btn">
                <div
                  className="decrement__btn"
                  onClick={() => {
                    if (floors > 1) {
                      setFloors(floors - 1);
                      setMove("up");
                    }
                  }}
                >
                  <i className="bi bi-dash changing--icon"></i>
                </div>
                <div className="changing__value">
                  {floors < 10 ? "0" + floors : floors}
                </div>
                <div
                  className="increment__btn"
                  onClick={() => {
                    setFloors(floors + 1);
                    setMove("down");
                  }}
                >
                  <i className="bi bi-plus-lg changing--icon"></i>
                </div>
              </div>

              <div className="__btn__wrapper mt-5">
                {/*<BackButton className="back__btn" handleClick={BackButtonClick}>
                  Back
                </BackButton>
                */}
                <NextButton className="mx-0" handleClick={SubmitFloors} />
              </div>

              <div className="floor__wrapper">
                <div className="floor__float__wrapper">
                  <div
                    className={`fifth_floor position-absolute ${GetClassName(
                      6
                    )}`}
                  >
                    <img src={floors > 6 ? MoreFloor : FloorBlock} alt="" />
                  </div>

                  <div
                    className={`fourth__floor position-absolute ${GetClassName(
                      5
                    )}`}
                  >
                    <img src={FloorBlock} alt="" />
                  </div>

                  <div
                    className={`third__floor position-absolute ${GetClassName(
                      4
                    )}`}
                  >
                    <img src={FloorBlock} alt="" />
                  </div>

                  <div
                    className={`second__floor position-absolute ${GetClassName(
                      3
                    )}`}
                  >
                    <img src={FloorBlock} alt="" />
                  </div>

                  <div
                    className={`first__floor position-absolute ${GetClassName(
                      2
                    )}`}
                  >
                    <img src={FloorBlock} alt="" />
                  </div>

                  <div className="ground__floor">
                    <img
                      src={GroundFloor}
                      className="ground__floor__hover"
                      alt=""
                    />
                  </div>
                </div>
                <div className="first__tree__wrapper">
                  <img src={Tree} className="tree__dimensions" alt="" />
                </div>

                <div className="second__tree__wrapper">
                  <img src={Tree} className="tree__dimensions" alt="" />
                </div>
              </div>
            </>
          </RightSideContent>
        </div>
      </div>
    </>
  );
};

export default CreateFloors;
