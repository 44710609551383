import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { CATCH_MESSAGE, Logout, UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import Button from "../../views/buttons/Button";
import InputSelectField from "../../views/inputtextfield/InputSelectField";

import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";

// import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

import Bookingjini from "../../assets/svg/bookingjini.svg";

import moment from "moment";
import BulkUpdate from "./BulkUpdate";
import SyncUpdate from "./SyncUpdate";
import BlockUpdate from "./BlockUpdate";
import UnBlockUpdate from "./UnBlockUpdate";
import ConfirmUpdateRate from "./ConfirmUpdateRate";

import SlidingPane from "react-sliding-pane";
import kernelApi from "../../API/kernelApi";
import cmApi from "../../API/cmApi";
// import Loader from "../../views/loader/Loader";

import CalendarSkeleton from "../../views/loader/CalendarSkeleton";
import beApi from "../../API/beApi";
import { toast } from "react-toastify";
import endPoints from "../../API/endPoints";

const Rate = () => {
  UpdateSidebar("Rates");
  const dispatch = useDispatch();

  const { auth_token, admin_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const { getrates } = endPoints.RATE;
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [fromDate, setFromDate] = useState<any>(moment().format("YYYY-MM-DD"));

  const [openSlideBulkUpdate, setOpenSlideBulkUpdate] = useState(false);
  const [openSlideSyncUpdate, setOpenSlideSyncUpdate] = useState(false);
  const [openSlideBlockUpdate, setOpenSlideBlockUpdate] = useState(false);
  const [openSlideUnBlockUpdate, setOpenSlideUnBlockUpdate] = useState(false);
  const [openConfirmUpdateRate, setOpenConfirmUpdateRate] = useState(false);
  const [access, setAccess] = useState(true);

  const [allRoomTypes, setAllRoomTypes] = useState<{
    ids: string[];
    labels: string[];
  }>({ ids: [], labels: [] });
  const [allRoomTypeDetails, setAllRoomTypeDetails] = useState<any[]>([]);
  const [roomTypeId, setRoomTypeId] = useState<string>("");
  const [baseAdult, setBaseAdult] = useState<number>(0);
  const [blockableOTAs, setBlockableOTAs] = useState<any[]>([]);
  const [ratePlans, setRatePlans] = useState<any[]>([]);
  const [ratesInfo, setRatesInfo] = useState<any[]>([]);
  const [ratesInfoCopy, setRatesInfoCopy] = useState<any[]>([]);

  const [dropdownStates, setDropdownStates] = useState<any[]>([]);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [otasWithData, setOtasWithData] = useState<string[]>([]);

  const [updatedCells, setUpdatedCells] = useState<any[]>([]);
  const [trigger, setTrigger] = useState(false);

  // loader states
  const [confirmRateLoaderBE, setConfirmRateLoaderBE] = useState(false);
  const [confirmRateLoaderCM, setConfirmRateLoaderCM] = useState(false);

  const [unblockLoaderBE, setUnblockLoaderBE] = useState(false);
  const [unblockLoaderCM, setUnblockLoaderCM] = useState(false);

  const [blockLoaderBE, setBlockLoaderBE] = useState(false);
  const [blockLoaderCM, setBlockLoaderCM] = useState(false);

  const [updateLoaderBE, setUpdateLoaderBE] = useState(false);
  const [updateLoaderCM, setUpdateLoaderCM] = useState(false);

  const [syncLoader, setSyncLoader] = useState(false);

  const [allChannels, setAllChannels] = useState<any>([]);

  const [allChannelsForBlockUnblock, setAllChannelsForBlockUnblock] = useState<any>([]);


  const defaultMsg = { msg: "No Rates data available" };
  // loader states

  useEffect(() => {
    getAllRoomTypes();
    getAllRatePlans();
    getBlockableOTAList();
  }, [current_property]);

  // Check if the user has access to this page.

  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "RATE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    refreshAllRatesInfo();
  }, [fromDate, roomTypeId, trigger]);

  useEffect(() => {
    if (roomTypeId) {
      // getRatesInfo(roomTypeId);
    }
  }, [fromDate, roomTypeId]);


  useEffect(() => {
    getAllChannels();
    getChannelsForBlockAndUnblock();
  }, [current_property])

  const getAllChannels = async () => {
    setAllChannels([]);
    try {

      let fetch_all_channels = await cmApi.get(`/all-otas/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth_token}`
          }
        })

      if (fetch_all_channels.data.status == 1) {
        setAllChannels(fetch_all_channels.data.data);
      }
    }
    catch (error: any) {
      console.log(error);
    }

  }


  const getChannelsForBlockAndUnblock = async () => {
    setAllChannelsForBlockUnblock([]);

    try {
      let fetch_channels_for_block_unblock = await cmApi.get(`/get_supported_ota_for_rateblock/${current_property.hotel_id}`)

      if (fetch_channels_for_block_unblock.data.status == 1) {
        setAllChannelsForBlockUnblock(fetch_channels_for_block_unblock.data.data);
      }

    }
    catch (error: any) {
      console.log(error);
    }
  }

  // API call to get all room types
  const getAllRoomTypes = async () => {
    const response = await kernelApi.get(
      "/hotel_master_room_type/all/" + current_property.hotel_id
    );
    let ids: string[] = [];
    let labels: string[] = [];
    response.data?.data?.map((roomType: any) => {
      labels.push(roomType.room_type + " (" + roomType.room_type_id + ")");
      ids.push(roomType.room_type_id);
    });
    setRoomTypeId(ids[0]);
    setAllRoomTypes({
      ids: ids,
      labels: labels,
    });
    setAllRoomTypeDetails(response.data?.data);
    setBaseAdult(response.data?.data[0].max_people || 0);
  };

  // API call to get all rate plans
  const getAllRatePlans = async () => {
    try {
      const response = await kernelApi.get(
        "/master_rate_plan/all/" + current_property.hotel_id,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      setRatePlans(response.data?.data);
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // refresh the rates calendar data
  const refreshAllRatesInfo = () => {
    setTimeout(() => {
      if (roomTypeId) {
        getRatesInfo(roomTypeId);
        setUpdatedCells([]);
      }
    }, 500);
  };

  // API call to get all blockable OTA list
  const getBlockableOTAList = async () => {
    const response = await cmApi.get(
      `/get_supported_ota_for_rateblock/${current_property.hotel_id}`
    );

    if (response.data.status.toString() === "1") {
      let ota_list = response.data?.data;
      ota_list.push = {
        ota_id: "-1",
        ota_name: "BookingEngine",
      };
      setBlockableOTAs(ota_list);
    }
  };

  // API call to get rates info for the selected room type for the selected date range
  const getRatesInfo = async (roomtype_id: string) => {
    setIsCalendarLoading(true);
    try {
      const response = await cmApi.get(
        `${getrates}/${current_property.hotel_id}/${roomtype_id}/${moment(
          fromDate
        ).format("YYYY-MM-DD")}/${moment(fromDate)
          .add(10, "d")
          .format("YYYY-MM-DD")}`
        // {
        //   headers: {
        //     Authorization: "Bearer " + auth_token,
        //   },
        // }
      );

      if (response.data.status === 1) {
        setRatesInfo(response.data.channel_rates);
      } else {
        setRatesInfo([]);
      }
    }
    catch (error: any) {
      console.log(error);
      setRatesInfo([]);
      if (error.response.status === 400) {
        Logout();
      }
    }
    setIsCalendarLoading(false);
  };

  // Date Change Handler

  const onDateChange = (date: any) => {
    setFromDate(moment(date).format("YYYY-MM-DD"));
  };

  // Room Type Change Handler

  const onRoomTypeChange = (e: any) => {
    setRoomTypeId(e.target.value);
    setBaseAdult(
      allRoomTypeDetails.filter(
        (roomType: any) =>
          roomType.room_type_id.toString() === e.target.value.toString()
      )[0].max_people || 0
    );
  };

  // adds or removes the selected div from the dropdown array
  const toggleDropdownState = (id: string) => {
    let dropdownCopy = [...dropdownStates];
    if (dropdownCopy.includes(id)) {
      dropdownCopy.splice(dropdownCopy.indexOf(id), 1);
    } else {
      dropdownCopy.push(id);
    }
    setDropdownStates(dropdownCopy);
  };

  // toggles the dropdown for the selected channel
  const toggleDropdownChannel = (id: string) => {
    if (!otasWithData.includes(id)) {
      setOtasWithData([...otasWithData, id]);
    }
    toggleDropdownState(id);
  };

  // validation for updating the rates value
  const upDateRateValue = (
    event: React.KeyboardEvent<HTMLDivElement>,
    id: string
  ) => {
    let element = document.getElementById(id);
    let elValue = element?.innerText;
    if (
      !String(event.key).match(/^[0-9]$/) ||
      (elValue && elValue.length > 6)
    ) {
      event.preventDefault();
    }
  };

  // selects the text of the div on focus automatically
  const onSelectText = (
    e: React.FocusEvent<HTMLDivElement, Element>,
    Id: string
  ) => {
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(e.currentTarget);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }

    let updatedCellsCopy = [...updatedCells];
    if (!updatedCellsCopy.includes(Id)) {
      updatedCellsCopy.push(Id);
    }
    setUpdatedCells(updatedCellsCopy);
  };

  // removes the id from the updated cells array if cell value is unchanged
  const onBlurText = (id: string, value: string) => {
    let cell = document.getElementById(id);
    let updatedCellsCopy = [...updatedCells];
    if (cell && cell.innerText === value.toString().trim()) {
      updatedCellsCopy.splice(updatedCellsCopy.indexOf(id), 1);
      setUpdatedCells(updatedCellsCopy);
    }
  };

  const onOpenConfirmationSlider = () => {
    let ratesData = [...allChannels];
    setRatesInfoCopy(ratesData);
  };

  const onPriceBlock = (ota_id: string, rateplan: number, date: string) => {
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to block the rate for the selected date?",
        "DELETE",
        () => {
          if (ota_id.toString() === "-1") {
            blockPriceBE(rateplan, date);
          } else if (ota_id.toString() !== "-1") {
            blockPriceCM(ota_id, rateplan, date);
          }
        },
        ["Cancel", "Block"]
      )
    );
  };

  const blockPriceBE = async (rateplan: number, date: string) => {
    try {
      const payload = {
        hotel_id: current_property.hotel_id,
        room_type_id: roomTypeId,
        admin_id: admin_id,
        room_type: allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
        ota_id: [-1],
        inv: [
          {
            date: date,
            block_status: 1,
            rate_plan_id: rateplan,
            plan_type: ratePlans.filter(
              (rate: any) => rate.rate_plan_id === rateplan
            )[0].plan_type,
          },
        ],
      };

      const response = await beApi.post("/block-rate-specific-dates", payload, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      if (response.data.status === 1) {
        toast(response.data.response_msg, {
          hideProgressBar: true,
          type: "success",
        });
        setTrigger(!trigger);
      } else {
        toast(response.data.response_msg, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
    dispatch(CancelConfirmationPrompt());
  };

  const blockPriceCM = async (
    ota_id: string,
    rateplan: number,
    date: string
  ) => {
    try {
      const payload = {
        hotel_id: current_property.hotel_id,
        room_type_id: roomTypeId,
        room_type: allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
        rate: [
          {
            ota_id: ota_id,
            channel_name: ratesInfo.filter(
              (channel: any) => channel.ota_id === ota_id
            )[0].ota_name,
            rate: [
              {
                date: date,
                block_status: 1,
                rate_plan_id: rateplan,
                plan_type: ratePlans.filter(
                  (rate: any) => rate.rate_plan_id === rateplan
                )[0].plan_type,
              },
            ],
          },
        ],
      };

      const response = await cmApi.post("/block-rate-specific-dates", payload, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      if (response.data[0].status === 1) {
        toast(response.data[0].response_msg, {
          hideProgressBar: true,
          type: "success",
        });
        setTrigger(!trigger);
      } else {
        toast(response.data[0].response_msg, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      console.log(error);
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
    dispatch(CancelConfirmationPrompt());
  };

  const onPriceUnblock = (
    ota_id: string,
    rateplan: number,
    date: string,
    blockStatus: number
  ) => {
    if (blockStatus === 1) {
      dispatch(
        ShowConfirmationPrompt(
          "Are you sure you want to unblock the rate for the selected date?",
          "DELETE",
          () => {
            if (ota_id.toString() === "-1") {
              unblockPriceBE(rateplan, date);
            } else if (ota_id.toString() !== "-1") {
              unblockPriceCM(ota_id, rateplan, date);
            } else {
              dispatch(CancelConfirmationPrompt());
            }
          },
          ["Cancel", "Unblock"]
        )
      );
    }
  };

  const unblockPriceBE = async (rateplan: number, date: string) => {
    try {
      const payload = {
        hotel_id: current_property.hotel_id,
        room_type_id: roomTypeId,
        admin_id: admin_id,
        room_type: allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
        ota_id: [-1],
        inv: [
          {
            date: date,
            block_status: 0,
            rate_plan_id: rateplan,
            plan_type: ratePlans.filter(
              (rate: any) =>
                rate.rate_plan_id.toString() === rateplan.toString()
            )[0].plan_type,
          },
        ],
      };

      const response = await beApi.post(
        "/unblock-rate-specific-dates",
        payload,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        toast(response.data.response_msg, {
          hideProgressBar: true,
          type: "success",
        });
        setTrigger(!trigger);
      } else {
        toast(response.data.response_msg, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    dispatch(CancelConfirmationPrompt());
  };

  const unblockPriceCM = async (
    ota_id: string,
    rateplan: number,
    date: string
  ) => {
    try {
      const payload = {
        hotel_id: current_property.hotel_id,
        room_type_id: roomTypeId,
        room_type: allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)],
        rate: [
          {
            ota_id: ota_id,
            channel_name: ratesInfo.filter(
              (channel) => channel.ota_id === ota_id
            )[0].ota_name,
            rate: [
              {
                date: date,
                block_status: 0,
                rate_plan_id: rateplan,
                plan_type: ratePlans.filter(
                  (rate: any) =>
                    rate.rate_plan_id.toString() === rateplan.toString()
                )[0].plan_type,
              },
            ],
          },
        ],
      };

      const response = await cmApi.post(
        "/unblock-rate-specific-dates",
        payload,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data[0].status === 1) {
        toast(response.data[0].response_msg, {
          hideProgressBar: true,
          type: "success",
        });
        setTrigger(!trigger);
      } else {
        toast(response.data[0].response_msg, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
    dispatch(CancelConfirmationPrompt());
  };

  return (
    <>
      <div className="manage__inventory__action__wrapper rate__sticky__operations__btn">
        <div className="inventory__breadcrumbs">
          <Breadcrumbs />
          <div className="manage__inventory__action__left">
            <div className="inventory__update__btn">
              <Button
                className="update__btn"
                icon="bi bi-file-arrow-up"
                handleClick={() => setOpenSlideBulkUpdate(!openSlideBulkUpdate)}
                isAccessible={access}
              >
                Update
              </Button>
            </div>
            <div className="inventory__sync__btn">
              <Button
                className="sync__btn"
                icon="bi bi-arrow-repeat"
                handleClick={() => setOpenSlideSyncUpdate(!openSlideSyncUpdate)}
                isAccessible={access}
              >
                Sync
              </Button>
            </div>
            <div className="inventory__block__btn">
              <Button
                className="block__btn"
                icon="bi bi-lock"
                handleClick={() =>
                  setOpenSlideBlockUpdate(!openSlideBlockUpdate)
                }
                isAccessible={access}
              >
                Block
              </Button>
            </div>
            <div className="inventory__unblock__btn">
              <Button
                className="unblock__btn"
                icon="bi bi-unlock"
                handleClick={() =>
                  setOpenSlideUnBlockUpdate(!openSlideUnBlockUpdate)
                }
                isAccessible={access}
              >
                Unblock
              </Button>
            </div>
          </div>
        </div>
        <div className="hr--line"></div>

        <div className="manage__inventory__action__right">
          {updatedCells.length > 0 && (
            <Button
              className="inventory__apply__changes__btn"
              icon="bi bi-check2"
              handleClick={() => setOpenConfirmUpdateRate(true)}
              isAccessible={access}
            >
              Apply
            </Button>
          )}
          <div className="mui__inputs">
            <div className="inventory__date__select__btn mui__datepicker__input__padding__reset mui__input__fieldset__legends__unset">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label=""
                  open={openDatePicker}
                  onOpen={() => setOpenDatePicker(true)}
                  onClose={() => setOpenDatePicker(false)}
                  value={new Date(fromDate)}
                  onChange={onDateChange}
                  minDate={new Date()}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onClick={() => setOpenDatePicker(true)}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>

            <div className="inventory__room__type__select mui__input__fieldset__legends__unset">
              <InputSelectField
                label=""
                options={allRoomTypes?.labels}
                values={allRoomTypes?.ids}
                value={roomTypeId}
                handleChange={onRoomTypeChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="manage__rates__table__wrapper mb-5">
        <div className="rates__data__wrapper">
          {!isCalendarLoading ? (
            ratesInfo.length > 0 ? (
              <>
                <div className="rates__sticky">
                  {/*--------------- table heading------------ */}
                  <div className="rates__data__row">
                    <div className="rates__lable__header rates__border__right rates__border__bottom">
                      <div className="rates__label__text">CHANNELS</div>
                    </div>

                    {ratesInfo[0]?.minimum_price?.map((bar: any, i: number) => {
                      return (
                        <div
                          className={`rates__label__dates ${moment(bar.rate_date).isoWeekday() > 5
                            ? "background__color"
                            : ""
                            }`}
                          key={i}
                        >
                          <div
                            className={`rates__dates__wrapper rates__border__right rates__border__bottom ${ratesInfo[0]?.rates?.length - 1 === i
                              ? "border-end-0"
                              : ""
                              }`}
                          >
                            <div className="rates__date__month">
                              {moment(bar.rate_date).format("DD MMM")}
                            </div>
                            <div className="rates__date__week">
                              {moment(bar.rate_date).format("ddd")}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*---------------- table body------------------------- */}
                {ratesInfo?.map((ota, index: number) => {
                  return (
                    <div
                      key={index}
                      className="rates__data__row rates__data__flex rates__border__bottom"
                    >
                      <div className="all__channel__prices">
                        <div
                          className={`rates__image__header rates__border__right`}
                        >
                          <div
                            className="rates__ota__image"
                            onClick={() => toggleDropdownChannel("" + index)}
                          >
                            <img src={ota?.logo} alt={ota?.name} />
                            <div className="rates__ota__icon">
                              <i
                                className={`bi bi-chevron-${dropdownStates.includes("" + index)
                                  ? "down"
                                  : "right"
                                  }`}
                              ></i>
                            </div>
                          </div>
                        </div>

                        {/*------------------ rate prices table-------------------- */}
                        <div className="rates__label__datas">
                          {ota?.minimum_price?.map((bar: any, ix: number) => {
                            return (
                              <div
                                key={ix}
                                className={`rates__dates__wrapper rates__border__right ${moment(bar?.rate_date)?.isoWeekday() > 5
                                  ? "background__color"
                                  : ""
                                  } ${ota.minimum_price?.length - 1 === ix
                                    ? "border-end-0"
                                    : ""
                                  }`}
                              >
                                <div className="rates__ota__number">
                                  <div className="mealplan__bar__price">
                                    {bar?.min_price}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {/*----------------- meal plans---------------------- */}
                      <div className="mealplans__content__wrapper">
                        {ota?.plans?.length > 0 ? (
                          ota?.plans?.map((rateplan: any, idx: number) => {
                            return (
                              <div
                                key={idx}
                                className={`rates__mealplan__wrapper ${dropdownStates.includes("" + index)
                                  ? "rateDropShow rates__border__top"
                                  : "dropHide"
                                  }`}
                              >
                                <div className="d-flex">
                                  <div
                                    className="mealplans__label__wrapper rates__border__right"
                                    onClick={() =>
                                      toggleDropdownState("" + index + idx)
                                    }
                                  >
                                    <span className="mealplans__label">
                                      {rateplan?.plan_type}
                                    </span>
                                    <i
                                      className={`bi bi-chevron-${dropdownStates.includes(
                                        "" + index + idx
                                      )
                                        ? "down"
                                        : "right"
                                        } mealplan__icon`}
                                    ></i>
                                  </div>
                                  {/* Meal Plan Price */}
                                  <div className="mealplan__price__wrapper">
                                    {rateplan?.day_rates?.map(
                                      (planRates: any, n: number) => {
                                        return (
                                          <div
                                            key={n}
                                            className={`occupancy__bar__price rates__border__right ${moment(
                                              planRates.rate_date
                                            ).isoWeekday() > 5
                                              ? "background__color"
                                              : ""
                                              } ${rateplan.day_rates.length - 1 ===
                                                n
                                                ? "border-end-0"
                                                : ""
                                              }`}
                                          >
                                            <div
                                              onClick={
                                                access
                                                  ? () =>
                                                    onPriceUnblock(
                                                      ota.ota_id,
                                                      rateplan.rate_plan_id,
                                                      planRates.rate_date,
                                                      planRates.block_status
                                                    )
                                                  : () => { }
                                              }
                                              className={`mealplan__bar__price rates__${ota.is_blockable === 1
                                                ? planRates.block_status !== 1
                                                  ? "unblock__bar__price"
                                                  : "block__bar__price"
                                                : ""
                                                }`}
                                            >
                                              {planRates.bar_price}
                                              {planRates.block_status === 0 &&
                                                ota.is_blockable === 1 &&
                                                access && (
                                                  <div
                                                    className="block__rates__icon"
                                                    onClick={() =>
                                                      onPriceBlock(
                                                        ota.ota_id,
                                                        rateplan.rate_plan_id,
                                                        planRates.rate_date
                                                      )
                                                    }
                                                  >
                                                    <i className="bi bi-lock bi-lock-size"></i>
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div
                                  className={`rates__occupancy__wrapper ${dropdownStates.includes("" + index + idx)
                                    ? "rateDropShow"
                                    : "dropHide"
                                    }`}
                                >
                                  {baseAdult > 0 && (
                                    <div className="d-flex">
                                      <div className="rates__occupancy__item rates__border__top rates__border__right">
                                        <i className="bi bi-person"></i>x 1
                                      </div>

                                      <div className="individual__mealplan__price__wrapper rates__border__top">
                                        {rateplan?.day_rates?.map(
                                          (rate: any, j: number) => {
                                            const ID = `${index}|${rateplan.rate_plan_id
                                              }|Single|${moment(
                                                rate.rate_date
                                              ).format("YYYY-MM-DD")}`;
                                            const VALUE =
                                              baseAdult === 1
                                                ? rate.bar_price
                                                : rate.single_occupancy_price
                                                  ? rate?.single_occupancy_price ||
                                                  0
                                                  : 0;
                                            return (
                                              <div
                                                key={j}
                                                className={`individual__occupancy__bar__price rates__border__right
                                          ${moment(
                                                  rate.rate_date
                                                ).isoWeekday() > 5
                                                    ? "background__color"
                                                    : ""
                                                  }
                                          ${rateplan.day_rates.length - 1 === j
                                                    ? "border-end-0"
                                                    : ""
                                                  }`}
                                              >
                                                <div
                                                  id={ID}
                                                  className={
                                                    rate.block_status === 1
                                                      ? "rates__block__price"
                                                      : `${updatedCells.includes(
                                                        ID
                                                      )
                                                        ? "rates__bar__price__selected"
                                                        : "rates__bar__price"
                                                      }`
                                                  }
                                                  contentEditable={
                                                    rate.block_status === 1 &&
                                                      access
                                                      ? false
                                                      : true
                                                  }
                                                  suppressContentEditableWarning={
                                                    true
                                                  }
                                                  onDrop={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  onKeyPress={(e) =>
                                                    upDateRateValue(e, ID)
                                                  }
                                                  onFocus={(e) =>
                                                    onSelectText(e, ID)
                                                  }
                                                  onBlur={() =>
                                                    onBlurText(ID, VALUE)
                                                  }
                                                >
                                                  {VALUE}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {baseAdult > 1 && (
                                    <div className="d-flex">
                                      <div className="rates__occupancy__item rates__border__top rates__border__right">
                                        <i className="bi bi-person"></i>x 2
                                      </div>
                                      <div className="individual__mealplan__price__wrapper rates__border__top">
                                        {rateplan?.day_rates?.map(
                                          (rate: any, j: number) => {
                                            const ID = `${index}|${rateplan.rate_plan_id
                                              }|Double|${moment(
                                                rate.rate_date
                                              ).format("YYYY-MM-DD")}`;
                                            const VALUE =
                                              baseAdult === 2
                                                ? rate.bar_price
                                                : rate.double_occupancy_price
                                                  ? rate.double_occupancy_price ||
                                                  0
                                                  : 0;
                                            return (
                                              <div
                                                key={j}
                                                className={`individual__occupancy__bar__price rates__border__right ${moment(
                                                  rate.rate_date
                                                ).isoWeekday() > 5
                                                  ? "background__color"
                                                  : ""
                                                  } ${rateplan.day_rates.length -
                                                    1 ===
                                                    j
                                                    ? "border-end-0"
                                                    : ""
                                                  }`}
                                              >
                                                <div
                                                  id={ID}
                                                  className={
                                                    rate.block_status === 1
                                                      ? "rates__block__price"
                                                      : `${updatedCells.includes(
                                                        ID
                                                      )
                                                        ? "rates__bar__price__selected"
                                                        : "rates__bar__price"
                                                      }`
                                                  }
                                                  contentEditable={
                                                    rate.block_status === 1 &&
                                                      access
                                                      ? false
                                                      : true
                                                  }
                                                  suppressContentEditableWarning={
                                                    true
                                                  }
                                                  onDrop={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  onKeyPress={(e) =>
                                                    upDateRateValue(e, ID)
                                                  }
                                                  onFocus={(e) =>
                                                    onSelectText(e, ID)
                                                  }
                                                  onBlur={() =>
                                                    onBlurText(ID, VALUE)
                                                  }
                                                >
                                                  {VALUE}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {baseAdult > 2 && (
                                    <div className="d-flex">
                                      <div className="rates__occupancy__item rates__border__top rates__border__right">
                                        <i className="bi bi-person"></i>x 3
                                      </div>
                                      <div className="individual__mealplan__price__wrapper rates__border__top">
                                        {rateplan?.day_rates?.map(
                                          (rate: any, j: number) => {
                                            const ID = `${index}|${rateplan.rate_plan_id
                                              }|Triple|${moment(
                                                rate.rate_date
                                              ).format("YYYY-MM-DD")}`;
                                            const VALUE =
                                              baseAdult === 3
                                                ? rate.bar_price
                                                : rate.multiple_occupancy
                                                  ? rate.multiple_occupancy[2] ||
                                                  0
                                                  : 0;
                                            return (
                                              <div
                                                key={j}
                                                className={`individual__occupancy__bar__price rates__border__right ${moment(
                                                  rate.rate_date
                                                ).isoWeekday() > 5
                                                  ? "background__color"
                                                  : ""
                                                  } ${rateplan.day_rates.length -
                                                    1 ===
                                                    j
                                                    ? "border-end-0"
                                                    : ""
                                                  }`}
                                              >
                                                <div
                                                  id={ID}
                                                  className={
                                                    rate.block_status === 1
                                                      ? "rates__block__price"
                                                      : `${updatedCells.includes(
                                                        ID
                                                      )
                                                        ? "rates__bar__price__selected"
                                                        : "rates__bar__price"
                                                      }`
                                                  }
                                                  contentEditable={
                                                    rate.block_status === 1 &&
                                                      access
                                                      ? false
                                                      : true
                                                  }
                                                  suppressContentEditableWarning={
                                                    true
                                                  }
                                                  onDrop={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  onKeyPress={(e) =>
                                                    upDateRateValue(e, ID)
                                                  }
                                                  onFocus={(e) =>
                                                    onSelectText(e, ID)
                                                  }
                                                  onBlur={() =>
                                                    onBlurText(ID, VALUE)
                                                  }
                                                >
                                                  {VALUE}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {baseAdult > 3 && (
                                    <div className="d-flex">
                                      <div className="rates__occupancy__item rates__border__top rates__border__right">
                                        <i className="bi bi-person"></i>x 4
                                      </div>
                                      <div className="individual__mealplan__price__wrapper rates__border__top">
                                        {rateplan?.day_rates?.map(
                                          (rate: any, j: number) => {
                                            const ID = `${index}|${rateplan.rate_plan_id
                                              }|Quadruple|${moment(
                                                rate.rate_date
                                              ).format("YYYY-MM-DD")}`;
                                            const VALUE =
                                              baseAdult === 4
                                                ? rate.bar_price
                                                : rate.multiple_occupancy
                                                  ? rate.multiple_occupancy[3] ||
                                                  0
                                                  : 0;
                                            return (
                                              <div
                                                key={j}
                                                className={`individual__occupancy__bar__price rates__border__right ${moment(
                                                  rate.rate_date
                                                ).isoWeekday() > 5
                                                  ? "background__color"
                                                  : ""
                                                  } ${rateplan.day_rates.length -
                                                    1 ===
                                                    j
                                                    ? "border-end-0"
                                                    : ""
                                                  }`}
                                              >
                                                <div
                                                  id={ID}
                                                  className={
                                                    rate.block_status === 1
                                                      ? "rates__block__price"
                                                      : `${updatedCells.includes(
                                                        ID
                                                      )
                                                        ? "rates__bar__price__selected"
                                                        : "rates__bar__price"
                                                      }`
                                                  }
                                                  contentEditable={
                                                    rate.block_status === 1 &&
                                                      access
                                                      ? false
                                                      : true
                                                  }
                                                  suppressContentEditableWarning={
                                                    true
                                                  }
                                                  onDrop={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  onKeyPress={(e) =>
                                                    upDateRateValue(e, ID)
                                                  }
                                                  onFocus={(e) =>
                                                    onSelectText(e, ID)
                                                  }
                                                  onBlur={() =>
                                                    onBlurText(ID, VALUE)
                                                  }
                                                >
                                                  {VALUE}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  <div className="d-flex">
                                    <div className="rates__occupancy__item rates__border__top rates__border__right">
                                      <i className="bi bi-person"></i>+ 1 Adult
                                    </div>
                                    <div className="individual__mealplan__price__wrapper rates__border__top">
                                      {rateplan?.day_rates?.map(
                                        (rate: any, j: number) => {
                                          const ID = `${index}|${rateplan.rate_plan_id
                                            }|Extra Adult|${moment(
                                              rate.rate_date
                                            ).format("YYYY-MM-DD")}`;
                                          const VALUE =
                                            rate.extra_adult_price || 0;
                                          return (
                                            <div
                                              key={j}
                                              className={`individual__occupancy__bar__price rates__border__right ${moment(
                                                rate.rate_date
                                              ).isoWeekday() > 5
                                                ? "background__color"
                                                : ""
                                                } ${rateplan.day_rates.length -
                                                  1 ===
                                                  j
                                                  ? "border-end-0"
                                                  : ""
                                                }`}
                                            >
                                              <div
                                                id={ID}
                                                className={
                                                  rate.block_status === 1
                                                    ? "rates__block__price"
                                                    : `${updatedCells.includes(
                                                      ID
                                                    )
                                                      ? "rates__bar__price__selected"
                                                      : "rates__bar__price"
                                                    }`
                                                }
                                                contentEditable={
                                                  rate.block_status === 1 &&
                                                    access
                                                    ? false
                                                    : true
                                                }
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                                onDrop={(e) =>
                                                  e.preventDefault()
                                                }
                                                onKeyPress={(e) =>
                                                  upDateRateValue(e, ID)
                                                }
                                                onFocus={(e) =>
                                                  onSelectText(e, ID)
                                                }
                                                onBlur={() =>
                                                  onBlurText(ID, VALUE)
                                                }
                                              >
                                                {VALUE}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className="d-flex">
                                    <div className="rates__occupancy__item rates__border__top rates__border__right">
                                      <i className="bi bi-person"></i>+ 1 Child
                                    </div>
                                    <div className="individual__mealplan__price__wrapper rates__border__top">
                                      {rateplan?.day_rates?.map(
                                        (rate: any, j: number) => {
                                          const ID = `${index}|${rateplan.rate_plan_id
                                            }|Extra Child|${moment(
                                              rate.rate_date
                                            ).format("YYYY-MM-DD")}`;
                                          const VALUE =
                                            rate.extra_child_price || 0;
                                          return (
                                            <div
                                              key={j}
                                              className={`individual__occupancy__bar__price rates__border__right ${moment(
                                                rate.rate_date
                                              ).isoWeekday() > 5
                                                ? "background__color"
                                                : ""
                                                } ${rateplan.day_rates.length -
                                                  1 ===
                                                  j
                                                  ? "border-end-0"
                                                  : ""
                                                }`}
                                            >
                                              <div
                                                id={ID}
                                                className={
                                                  rate.block_status === 1
                                                    ? "rates__block__price"
                                                    : `${updatedCells.includes(
                                                      ID
                                                    )
                                                      ? "rates__bar__price__selected"
                                                      : "rates__bar__price"
                                                    }`
                                                }
                                                contentEditable={
                                                  rate.block_status === 1 &&
                                                    access
                                                    ? false
                                                    : true
                                                }
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                                onDrop={(e) =>
                                                  e.preventDefault()
                                                }
                                                onKeyPress={(e) =>
                                                  upDateRateValue(e, ID)
                                                }
                                                onFocus={(e) =>
                                                  onSelectText(e, ID)
                                                }
                                                onBlur={() =>
                                                  onBlurText(ID, VALUE)
                                                }
                                              >
                                                {VALUE}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className="d-flex">
                                    <div className="rates__occupancy__item rates__border__top rates__border__right">
                                      LOS
                                    </div>
                                    <div className="individual__mealplan__price__wrapper rates__border__top">
                                      {rateplan?.day_rates?.map(
                                        (rate: any, j: number) => {
                                          const ID = `${index}|${rateplan.rate_plan_id
                                            }|LOS|${moment(rate.rate_date).format(
                                              "YYYY-MM-DD"
                                            )}`;
                                          const VALUE = rate.los || 0;
                                          return (
                                            <div
                                              key={j}
                                              className={`individual__occupancy__bar__price rates__border__right ${moment(
                                                rate.rate_date
                                              ).isoWeekday() > 5
                                                ? "background__color"
                                                : ""
                                                } ${rateplan.day_rates.length -
                                                  1 ===
                                                  j
                                                  ? "border-end-0"
                                                  : ""
                                                }`}
                                            >
                                              <div
                                                id={ID}
                                                className={
                                                  rate.block_status === 1
                                                    ? "rates__block__price"
                                                    : `${updatedCells.includes(
                                                      ID
                                                    )
                                                      ? "rates__bar__price__selected"
                                                      : "rates__bar__price"
                                                    }`
                                                }
                                                contentEditable={
                                                  rate.block_status === 1 &&
                                                    access
                                                    ? false
                                                    : true
                                                }
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                                onDrop={(e) =>
                                                  e.preventDefault()
                                                }
                                                onKeyPress={(e) =>
                                                  upDateRateValue(e, ID)
                                                }
                                                onFocus={(e) =>
                                                  onSelectText(e, ID)
                                                }
                                                onBlur={() =>
                                                  onBlurText(ID, VALUE)
                                                }
                                              >
                                                {VALUE}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className={`rates__mealplan__wrapper ${dropdownStates.includes("" + index)
                              ? "rateDropShow rates__border__top"
                              : "dropHide"
                              }`}
                          >
                            <CalendarSkeleton />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="empty__room__div">
                <i className="bi bi-x-circle mb-3"></i>
                <div>{defaultMsg.msg}</div>
              </div>
            )
          ) : (
            <CalendarSkeleton rows={7} />
          )}
        </div>
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={openConfirmUpdateRate}
        onAfterOpen={onOpenConfirmationSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          if (!confirmRateLoaderBE && !confirmRateLoaderCM) {
            setOpenConfirmUpdateRate(false);
            setUpdatedCells([]);
          }
        }}
      >
        <ConfirmUpdateRate
          ratesInfo={ratesInfoCopy}
          roomTypeId={roomTypeId}
          ratePlans={ratePlans}
          baseAdult={baseAdult}
          allRoomTypes={allRoomTypes}
          loaderBE={confirmRateLoaderBE}
          loaderCM={confirmRateLoaderCM}
          setLoaderBE={setConfirmRateLoaderBE}
          setLoaderCM={setConfirmRateLoaderCM}
          updatedCellIds={updatedCells}
          refreshRates={refreshAllRatesInfo}
          onClose={() => {
            if (!confirmRateLoaderBE && !confirmRateLoaderCM) {
              setOpenConfirmUpdateRate(false);
              setUpdatedCells([]);
            }
          }}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openSlideBulkUpdate}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          if (!updateLoaderBE && !updateLoaderCM) {
            setOpenSlideBulkUpdate(false);
            setUpdatedCells([]);
          }
        }}
      >
        <BulkUpdate
          ratesInfo={allChannels}
          ratePlans={ratePlans}
          baseAdult={baseAdult}
          roomTypeId={roomTypeId}
          loaderBE={updateLoaderBE}
          setLoaderBE={setUpdateLoaderBE}
          loaderCM={updateLoaderCM}
          setLoaderCM={setUpdateLoaderCM}
          allRoomTypes={allRoomTypes}
          refreshRates={refreshAllRatesInfo}
          onClose={() => {
            if (!updateLoaderBE && !updateLoaderCM) {
              setOpenSlideBulkUpdate(false);
              setUpdatedCells([]);
            }
          }}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openSlideSyncUpdate}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          if (!syncLoader) {
            setOpenSlideSyncUpdate(false);
            setUpdatedCells([]);
          }
        }}
      >
        <SyncUpdate
          ratesInfo={allChannels}
          ratePlans={ratePlans}
          roomTypeId={roomTypeId}
          loader={syncLoader}
          setLoader={setSyncLoader}
          allRoomTypes={allRoomTypes}
          refreshRates={refreshAllRatesInfo}
          onClose={() => {
            if (!syncLoader) {
              setOpenSlideSyncUpdate(false);
              setUpdatedCells([]);
            }
          }}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openSlideBlockUpdate}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          if (!blockLoaderBE && !blockLoaderCM) {
            setOpenSlideBlockUpdate(false);
            setUpdatedCells([]);
          }
        }}
      >
        <BlockUpdate
          ratesInfo={allChannelsForBlockUnblock}
          ratePlans={ratePlans}
          roomTypeId={roomTypeId}
          loaderBE={blockLoaderBE}
          setLoaderBE={setBlockLoaderBE}
          loaderCM={blockLoaderCM}
          setLoaderCM={setBlockLoaderCM}
          allRoomTypes={allRoomTypes}
          blockableOTAs={blockableOTAs}
          refreshRates={refreshAllRatesInfo}
          onClose={() => {
            if (!blockLoaderBE && !blockLoaderCM) {
              setOpenSlideBlockUpdate(false);
              setUpdatedCells([]);
            }
          }}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openSlideUnBlockUpdate}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          if (!unblockLoaderBE && !unblockLoaderCM) {
            setOpenSlideUnBlockUpdate(false);
            setUpdatedCells([]);
          }
        }}
      >
        <UnBlockUpdate
          ratesInfo={allChannelsForBlockUnblock}
          ratePlans={ratePlans}
          roomTypeId={roomTypeId}
          loaderBE={unblockLoaderBE}
          setLoaderBE={setUnblockLoaderBE}
          loaderCM={unblockLoaderCM}
          setLoaderCM={setUnblockLoaderCM}
          allRoomTypes={allRoomTypes}
          blockableOTAs={blockableOTAs}
          refreshRates={refreshAllRatesInfo}
          onClose={() => {
            if (!unblockLoaderBE && !unblockLoaderCM) {
              setOpenSlideUnBlockUpdate(false);
              setUpdatedCells([]);
            }
          }}
        />
      </SlidingPane>
    </>
  );
};

export default Rate;
