import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { UpdateSidebar } from "../../../UtilityFunctions";

import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "../../../views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import AddBasicPromotion from "./AddBasicPromotion";
import ModifyBasicPromotion from "./ModifyBasicPromotion";
import ViewBasicPromotion from "./ViewBasicPromotion";
import cmApi from "../../../API/cmApi";
import { toast } from "react-toastify";
import {
    CancelConfirmationPrompt,
    ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import InputSelectField from "../../../views/inputtextfield/InputSelectField";
import Loader from "../../../views/loader/Loader";
import endPoints from "../../../API/endPoints";

const BasicPromotion = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    UpdateSidebar("Promotion");

    const { auth_token } = useSelector((state: RootState) => state.auth)
    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );

    const { getAllActiveBasicPromotions, getAllChannels,deactivateBasicPromotion } = endPoints.MANAGEBASICPROMOTION;


    const [loader, setLoader] = useState<any>(true);
    const [allPromotions, setAllPromotions] = useState([]);
    const [activeStatus, setActiveStatus] = useState<any>([]);

    const [allChannels, setAllChannels] = useState([]);


    const [addBasicPromotionPanel, setAddBasicPromotionPanel] = useState(false);
    const [modifyBasicPromotionPanel, setModifyBasicPromotionPanel] = useState(false);
    const [viewBasicPromotionPanel, setViewBasicPromotionPanel] = useState(false);
    const [viewPromotionId, setViewPromotionId] = useState<any>();
    const [viewPromotionName, setViewPromotionName] = useState<any>();


    const [modifyPromotionId, setModifyPromotionId] = useState<any>();
    const [allActivePromotionsMessage, setAllActivePromotionsMessage] = useState('');


    const [selectPageSize, setSelectPageSize] = useState(10);
    const [noOfPages, setNoOfPages] = useState(1);
    const [currentPageNo, setCurrentPageNo] = useState(1);

    const pageSize = [10, 15, 20, 25, 30];


    useEffect(() => {
        fetchAllactivePromotions();
        fetchAllChannels();
    }, [current_property])


    const fetchAllactivePromotions = async () => {
        setLoader(true);
        let active_status: any = [];

        try {
            let allActivePromotions = await cmApi.get(
                `${getAllActiveBasicPromotions}/${current_property.hotel_id}`
            );

            if (allActivePromotions.data.status === 1) {

                for (let i = 0; i < allActivePromotions.data.all_promotion.length; i++) {
                    active_status.push(true);
                }

                setAllPromotions(allActivePromotions.data.all_promotion);
                setActiveStatus(active_status);
                setNoOfPages(Math.ceil(allActivePromotions.data.all_promotion.length / selectPageSize))
                setAllActivePromotionsMessage(allActivePromotions.data.message);
                setLoader(false);

            }
            else {
                setAllActivePromotionsMessage(allActivePromotions.data.message);
                setLoader(false);

            }
        }
        catch (error: any) {
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
                Logout();
            }
        }

    }

    const deactivatePromotion = (index: any, promotion_id: any) => {

        dispatch(
            ShowConfirmationPrompt(
                `Are you sure want to deactivate this ?`,
                "DELETE",
                async () => {
                    try {
                        let inactivePromotion = await cmApi.post(deactivateBasicPromotion,
                            {
                                hotel_id: current_property.hotel_id,
                                id: promotion_id,
                            }
                        );

                        if (inactivePromotion.data.status === 1) {
                            toast.success(`${inactivePromotion.data.message}`);
                            fetchAllactivePromotions();
                        } else {
                            toast.error(`${inactivePromotion.data.message}`);
                            fetchAllactivePromotions();
                        }
                    } catch (error: any) {
                        toast.error(CATCH_MESSAGE);
                        if (error.response.status === 400) {
                            Logout();
                        }
                    }
                    dispatch(CancelConfirmationPrompt());
                },
                ["No", "Yes"]
            )
        );
    }


    const fetchAllChannels = async () => {

        try {
            let all_channels = await cmApi.get(`${getAllChannels}/${current_property.hotel_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth_token}`
                    }
                });

            if (all_channels.data.status === 1) {
                setAllChannels(all_channels.data.data);
            }

        }

        catch (error: any) {
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
                Logout();
            }

        }

    }


    const rightArrowClick = () => {
        if (currentPageNo < noOfPages) {
            setCurrentPageNo(currentPageNo + 1);
        }
    };

    const leftArrowClick = () => {
        if (currentPageNo > 1) {
            setCurrentPageNo(currentPageNo - 1);
        }
    };


    return (
        <>
            <div className="basic__promotion__wrapper">
                <div className="promotions__breadcrumbs">
                    <Breadcrumbs />
                    <div
                        className="promotions__action__right">
                        <Button
                            icon="bi bi-plus-lg"
                            handleClick={() => {
                                setAddBasicPromotionPanel(true);
                            }}
                        >
                            Create Promotion
                        </Button>

                        <Button
                            handleClick={() => navigate('/promotion/inactive-basic-promotion')}
                        >
                            Inactive Promotion
                        </Button>

                        <Button
                            className="back__btn"
                            icon="bi bi-arrow-left"
                            handleClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    </div>
                </div>
                <div className="hr--line"></div>
                {loader ? (
                    <Loader className="ineventory--loader" />
                ) : allPromotions.length > 0 ? <div className="promotional__wrapper">
                    <div className="promotional__main__content mb-4">
                        {allPromotions &&
                            allPromotions?.length > 0 &&
                            allPromotions?.slice(
                                selectPageSize * (currentPageNo - 1),
                                selectPageSize * currentPageNo
                            )
                                ?.map((item: any, i: any) => {
                                    return (
                                        <div className="promotional__card" key={item.id}>
                                            <div className="card__property me-3">
                                                <div className="promotional__block mb-3">
                                                    <div className="promotional__sub__level">Name</div>
                                                    <div className="promotional__text__label">{item.promotion_name}</div>
                                                </div>

                                                <div className="promotional__block mb-3">
                                                    <div className="promotional__sub__level">Booking Date</div>
                                                    <div className="booking__stay__date__label">{item.booking_start_date} - {item.booking_end_date}</div>
                                                </div>

                                                <div className="promotional__block mb-3">
                                                    <div className="promotional__sub__level">Stay Date</div>
                                                    <div className="booking__stay__date__label">{item.stay_start_date} - {item.stay_end_date}</div>
                                                </div>

                                                <div className="promotional__block mb-3">
                                                    <div className="promotional__sub__level">Discount</div>
                                                    <div className="promotional__discount__wrapper">
                                                        <i className="bi bi-patch-check-fill fill-green-color me-2"></i>
                                                        <div>{item.discount}</div>
                                                    </div>
                                                </div>

                                                <div className="promotional__block mb-3">
                                                    <div className="promotional__sub__level mb-2">Applicable Room Type</div>
                                                    <div className="promotion__room__type">

                                                        {item.selected_room_rateplan.map((item_room_type: any, id) => {
                                                            return (
                                                                <div className="promotion__room__names mb-2" key={id}>{item_room_type.shortcode}</div>
                                                            )
                                                        })}

                                                    </div>
                                                </div>

                                                <div className="hr--line"></div>

                                                <div className="promotion__btn__wrappers">
                                                    <div className="promotion__btn__left">
                                                        <div className="view__btn me-3" onClick={() => {
                                                            setViewPromotionId(item.id);
                                                            setViewBasicPromotionPanel(true);
                                                            setViewPromotionName(item.promotion_name)
                                                        }}>View</div>

                                                        <div className="modify__btn" onClick={() => {
                                                            setModifyPromotionId(item.id);
                                                            setModifyBasicPromotionPanel(true)
                                                        }}>Modify</div>
                                                    </div>

                                                    <div className="promotion__btn__right">
                                                        <div className="me-2">Active</div>
                                                        <div className="toogle__switch">
                                                            <div className="form-check form-switch custom-switch">
                                                                <input type="checkbox" className="form-check-input" checked={activeStatus[i]} onChange={() => { deactivatePromotion(i, item.id) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                    </div>


                    <div className="basic--promotion--pagination">
                        <div className="pagination__left">
                            <div className="pagination__dropdown">
                                <InputSelectField
                                    label="Select"
                                    value={selectPageSize}
                                    options={pageSize}
                                    handleChange={(e) => {
                                        setSelectPageSize(e.target.value);
                                        setNoOfPages(Math.ceil(allPromotions?.length / e.target.value));
                                        setCurrentPageNo(1);
                                    }}
                                />
                            </div>
                            <ul>
                                {Array(noOfPages)
                                    .fill(0)
                                    .map((p, i) => {
                                        return (
                                            <li
                                                key={i}
                                                onClick={() => setCurrentPageNo(i + 1)}
                                                className={`${i + 1 === currentPageNo
                                                    ? "active__page"
                                                    : "showing__page__number"
                                                    }`}
                                            >
                                                {i + 1}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                        <div className="pagination__right">
                            <div className="arrow__container me-3" onClick={leftArrowClick}>
                                <i className="bi bi-chevron-left font--weight"></i>
                            </div>
                            <div className="arrow__container" onClick={rightArrowClick}>
                                <i className="bi bi-chevron-right font--weight"></i>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <div className="empty__room__div mt-5">
                        <i className="bi bi-x-circle mb-3"></i>
                        <div>{allActivePromotionsMessage}</div>
                    </div>
                }
            </div>


            <SlidingPane
                hideHeader
                from="right"
                width="35%"
                isOpen={addBasicPromotionPanel}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => {
                    setAddBasicPromotionPanel(false);
                }}
            >
                <AddBasicPromotion
                    onClose={() => {
                        setAddBasicPromotionPanel(false);
                    }}
                    allChannels={allChannels}
                    refreshPromotions = {fetchAllactivePromotions}
                />
            </SlidingPane>


            <SlidingPane
                hideHeader
                from="right"
                width="35%"
                isOpen={modifyBasicPromotionPanel}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => {
                    setModifyBasicPromotionPanel(false);
                }}
            >
                <ModifyBasicPromotion
                    onClose={() => {
                        setModifyBasicPromotionPanel(false);
                    }}
                    allChannels={allChannels}
                    promotionId={modifyPromotionId}
                    refreshPromotions = {fetchAllactivePromotions}

                />
            </SlidingPane>


            <SlidingPane
                hideHeader
                from="right"
                width="35%"
                isOpen={viewBasicPromotionPanel}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => {
                    setViewBasicPromotionPanel(false);
                }}
            >
                <ViewBasicPromotion
                    onClose={() => {
                        setViewBasicPromotionPanel(false);
                    }}
                    promotionId={viewPromotionId}
                    promotionName={viewPromotionName}
                />
            </SlidingPane>



        </>
    );
};

export default BasicPromotion;
