import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// import select feild
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../views/inputtextfield/InputMultiSelectField";
import InputPasswordField from "../../views/inputtextfield/InputPasswordField";
import endPoints from "../../API/endPoints";

import InventoryBtn from "../../views/buttons/Button";

import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";
import {
  CATCH_MESSAGE,
  validateEmail,
  validatePassword,
} from "../../UtilityFunctions";

import Toast from "../../components/toaster/Toast";

interface IAddUser {
  onClose: () => void;
  getAllUsers: () => void;
}

const AddUsers: React.FC<IAddUser> = ({ onClose, getAllUsers }) => {
  const { company_id, admin_id, auth_token, email, password } = useSelector(
    (state: RootState) => state.auth
  );

  const [hotels, setHotels] = useState<any>([]);
  const [allFetchedHotels, setAllFetchedHotels] = useState<any>([]);
  const [selectedUserRole, setSelectedUserRole] = useState<any>("");
  const [selectedHotel, setSelectedHotel] = useState<any[]>([]);
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>();
  const [emailAddress, setEmailAddress] = useState<any>("");
  const [userPassword, setUserPassword] = useState<any>("");
  const [passwordHelperText, setpasswordHelperText] = useState<any>("");
  const [emailHelperText, setEmailHelperText] = useState<any>("");

  const { getHotelList, manageUserAdd } = endPoints.MANAGEUSER;

  const getHotels = async () => {
    try {
      let all_hotels: string[] = [];
      let all_hotel_ids: any[] = [];
      let response = await kernelApi.get(
        `${getHotelList}/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      response?.data?.data?.map((hotel: any) => {
        all_hotels.push(hotel.hotel_name);
        all_hotel_ids.push(hotel.hotel_id);
      });
      setAllFetchedHotels(response.data.data);
      setHotels({
        all_hotels: all_hotels,
        hotel_ids: all_hotel_ids,
      });
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    getHotels();
  }, []);

  const handleUserRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    let role = e.target.value;
    setSelectedUserRole(role);
  };

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    let first = e.target.value;
    setFirstName(first);
  };

  // const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let last = e.target.value;
  //   setLastName(last);
  // };
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email_address = e.target.value;
    if (validateEmail(email_address)) {
      setEmailHelperText("");
    } else {
      setEmailHelperText("Please Enter a valid Email ");
    }
    setEmailAddress(email_address);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    let user_password = e.target.value;
    if (validatePassword(e.target.value)) {
      setpasswordHelperText("");
    } else {
      setpasswordHelperText(
        "Password Should Contain one Upper Case and one Special Character"
      );
    }
    setUserPassword(user_password);
  };

  // selected hotels values
  const isAllSelected =
    hotels?.all_hotels?.length > 0 &&
    selectedHotel?.length === hotels?.all_hotels?.length;

  const handleSelectedHotel = (e: any) => {
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedHotel(
        selectedHotel.length === hotels.all_hotels.length
          ? []
          : hotels.all_hotels
      );
      return;
    }
    setSelectedHotel(value);
  };

  const addUserDetails = async () => {
    let arr: any[] = [];
    allFetchedHotels.forEach((hotel: any) => {
      if (selectedHotel.includes(hotel.hotel_name)) {
        arr.push(hotel.hotel_id);
      }
    });

    if (
      company_id &&
      firstName &&
      emailAddress &&
      userPassword &&
      arr.length > 0
    ) {
      onClose();
      try {
        let response = await kernelApi.post(
          manageUserAdd,
          {
            company_id: company_id,
            full_name: firstName,
            username: emailAddress,
            password: userPassword,
            role_id: "2",
            hotel_id: arr,
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        getAllUsers();
        if (response.data.status === 1) {
          // toast(response.data.message, {
          //   hideProgressBar: true,
          //   type: "success"
          // });
          // ------TOAST FUNCTION--------
          Toast(response.data.message);
          // --------------
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add User</div>
      </div>

      <div className="height-64px"></div>
      <div className="inventory__update__wrapper my-4 pt-2">
        {/* ----------------------------------------- */}
        <div className="inventory__choose__channel__source__select mb-3">
          <InputSelectField
            label="Select User Type"
            values={["Hotel Manager"]}
            value={selectedUserRole}
            options={["Hotel Manager"]}
            handleChange={handleUserRole}
          />
        </div>

        <div className="inventory__number__wrapper mt-1 pt-3 mb-2">
          <div className="__default--input--field">
            <InputTextField
              label="Name"
              value={firstName}
              //   value={syncDuration}
              handleChange={handleFirstName}
            />
          </div>
        </div>

        <div className="inventory__number__wrapper mb-2">
          <div className="__default--input--field">
            <InputTextField
              label="Email Address"
              value={emailAddress}
              helperText={emailHelperText}
              //   value={syncDuration}
              handleChange={handleEmail}
            />
          </div>
        </div>

        <div className="inventory__number__wrapper mb-2">
          {/* <div className="__default--input--field">
            <InputPasswordField
              label="Password"
              value={userPassword}
              helperText={passwordHelperText}
              //   value={syncDuration}
              handleChange={handlePassword}
            />
          </div> */}
          <div className="__default--input--field">
            <InputTextField
              label="Password"
              value={userPassword}
              helperText={passwordHelperText}
              //   value={syncDuration}
              handleChange={handlePassword}
            />
          </div>
        </div>

        <div className="status__dropdown__wrapper dropdown-manage-users me-3 custom__width mb-3">
          {hotels && hotels?.all_hotels?.length > 0 ? (
            <InputMultiSelectField
              label="select Hotels"
              value={selectedHotel}
              handleChange={(evnt) => handleSelectedHotel(evnt)}
              options={hotels.all_hotels}
              isAllSelected={isAllSelected}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {/* ----------------------------------------- */}

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-1">
          <InventoryBtn handleClick={addUserDetails}>Save</InventoryBtn>
        </div>
      </div>
    </>
  );
};

export default AddUsers;
