import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProgressBar } from "react-step-progress-bar";

import { store } from "../../redux/store";
import { useDispatch } from "react-redux";
import { NewPropertyAmenities } from "../../redux/actions/AddPropertyAction";

import AmenityCard from "../../views/datacard/AmenityCard";
import GetHelp from "../../components/gethelp/GetHelp";
import NextButton from "../../views/buttons/NextButton";

import BackButton from "../../views/buttons/Button";
import Loader from "../../views/loader/Loader";
import kernelApi from "../../API/kernelApi";
import { Logout } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

const AddAmenities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const amenity_list = store.getState().add_property.new_property_amenities;

  const [slidePage, setSlidePage] = useState(0);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>(
    amenity_list ? amenity_list : []
  );
  const [allAmenities, setAllAmenities] = useState<any[]>([]);
  const [numOfPages, setNumOfPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  // Sliding of page with more no of amenities
  const size = 12;

  //Endpoints Import
  const { getAllSelectedPropAmenities, addNewPropertyPropImage, addNewPropertyPropAddress } = endPoints.ADDNEWPROPERTY
  // --------------------------------

  useEffect(() => {
    GetAllAmenities();
  }, []);

  const GetAllAmenities = async () => {
    let response = await kernelApi(getAllSelectedPropAmenities);
    if (response.data.status === 1) {
      let result = response.data.all_amenities_category;
      setAllAmenities(result);
      setNumOfPages(Math.ceil(result.length / size));
    }
    setIsLoading(false);
  };

  const LeftClick = () => {
    if (slidePage > 0) {
      setSlidePage(slidePage - 1);
    }
  };

  const RightClick = () => {
    if (slidePage < numOfPages - 1) {
      setSlidePage(slidePage + 1);
    }
  };

  const onSelectAmenities = (id: any) => {
    let amenities = [...selectedAmenities];
    if (!amenities.includes(id)) {
      amenities.push(id);
    } else {
      let index = amenities.indexOf(id);
      amenities.splice(index, 1);
    }
    setSelectedAmenities(amenities);
  };

  // Next button functionality
  const SubmitPropertyAmenities = () => {
    dispatch(NewPropertyAmenities(selectedAmenities));
    setTimeout(() => {
      navigate(addNewPropertyPropImage);
    }, 500);
  };

  const BackButtonClick = () => {
    navigate(addNewPropertyPropAddress);
  };

  return (
    <>
      <div className="div-page-wrapper">
        <div className="div__wrapper">
          <div className="div__input__wrapper__left div__left__side__box">
            <div className="logo__wrapper"></div>
            <div className="div__user__testimonials__wrapper">
              <div className="select__property__wrapper">
                <div className="select__property__text">
                  Choose the amenities of your property
                </div>
                <div className="select__property__sub__text">
                  This details will be seen by guests when they search for a
                  place to stay.
                </div>
              </div>
            </div>
            <div className="progress-bar-wrapper">
              <div className="progress-bar-text">4/5</div>
              <div className="progress-bar-status">
                <ProgressBar percent={80} filledBackground="#ffffff" />
              </div>
            </div>
          </div>
          <div className="div__input__wrapper__right div__right__side__box">
            <BackButton
              className="logout__btn"
              handleClick={() => {
                Logout();
              }}
            >
              logout
            </BackButton>
            <div className="div__inputs">
              <div className="div__amenities__container">
                <div className="div--head--label">
                  Do you have any special amenities?
                </div>
                {numOfPages > 1 && (
                  <div className="arrow-main-box">
                    <div className="div--helper--text">Select below</div>
                    <div className="arrow-wrapper">
                      <div className="arrow-left" onClick={LeftClick}>
                        <i className="gg-arrow-left"></i>
                      </div>
                      <div className="arrow-right" onClick={RightClick}>
                        <i className="gg-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                )}
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="amenities__card__container">
                    {allAmenities
                      .slice(slidePage * size, (slidePage + 1) * size)
                      .map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <AmenityCard
                              icon={`bi bi-${item.category_icon}`}
                              label={item.label}
                              aminitesAccepted={() =>
                                onSelectAmenities(item.id)
                              }
                              active={selectedAmenities?.includes(item.id)}
                            />
                          </React.Fragment>
                        );
                      })}
                  </div>
                )}
              </div>
              {!isLoading && (
                <div className="__btn__wrapper mt-3">
                  <BackButton
                    className="back__btn"
                    handleClick={BackButtonClick}
                  >
                    Back
                  </BackButton>
                  <NextButton
                    handleClick={SubmitPropertyAmenities}
                    className="resize_btn"
                  />
                </div>
              )}
              <GetHelp />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAmenities;
