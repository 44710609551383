import React from "react";
import { Link } from "react-router-dom";

export interface IGethelp { }

const GetHelp = () => {
  return (
    <>
      {/* <div className="support__help__text">
        Having Troubles?
        <Link to="//helpdesk.bookingjini.com/" className="get__help__link">
          {" "}Get Help
        </Link>
      </div> */}
    </>
  );
};

export default GetHelp;
