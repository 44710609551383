import { USER_ACCESS_DATA, USER_ADMIN_ACCESS, USER_FULL_NAME_ACCESS } from "../actionTypes";

export const UserAcessDetails = (auth: any) => {
  return {
    type: USER_ACCESS_DATA,
    payload: auth,
  };
};

export const UserAdminAcess = (auth: any) => {
  return {
    type: USER_ADMIN_ACCESS,
    payload: auth,
  };
};

export const SaveUserFullName = (data: any) => {
  return {
    type: USER_FULL_NAME_ACCESS,
    payload: data,
  };
};
