import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import {
  CATCH_MESSAGE,
  getImage,
  Logout,
  UpdateSidebar,
} from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import PackageImage from "../../../../assets/img/hotel-image.png";
import React, { useEffect, useState } from "react";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// import input field
import InputTextField from "../../../../views/inputtextfield/InputTextField";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import { useNavigate } from "react-router-dom";
import kernelApi from "../../../../API/kernelApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../../redux/store";
import Loader from "../../../../views/loader/Loader";

import EditPackage from "./EditPackage";
import { AxiosError } from "axios";
import AddPackage from "./AddPackage";
import { SetAllRoomTypes } from "../../../../redux/actions/ManageChannell";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import { nanoid } from "@reduxjs/toolkit";

const PackageList = () => {
  // Data From Redux
  const { admin_id, auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  UpdateSidebar("Manage Channels", " Booking Engine");
  const [loader, setLoader] = useState<boolean>(false);
  const [openAddPackageSlider, setopenAddPackageSlider] = useState(false);
  const [openEditPackageSlider, setopenEditPackageSlider] = useState(false);
  const [allPackage, setAllPackage] = useState<any[]>([]);
  const [pckgId, setPckgId] = useState<any>("");
  const [allRoomType, setAllRoomType] = useState<any[]>([]);
  const [access, setAccess] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nodataMessage, setNoDataMessage] = useState<string>("");
  const [deleted, setDeleted] = useState('')

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  // Get All Room Types
  const getAllRoomTypes = async () => {
    try {
      const allRoomTypeRes = await kernelApi.get(
        `/hotel_master_room_type/room_types/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (allRoomTypeRes.data.status === 1) {
        setAllRoomType(allRoomTypeRes.data.data);
        dispatch(SetAllRoomTypes(allRoomTypeRes.data.data));
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  // Package Data Get
  const getPackageData = async () => {
    setLoader(true);
    try {
      const pckgDataRes = await kernelApi.get(
        `/packages/all/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (pckgDataRes.data.status === 1) {
        setAllPackage(pckgDataRes.data.data);
        setLoader(false);
      } else {
        setAllPackage([]);
        setNoDataMessage(pckgDataRes.data.message);
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  useEffect(() => {
    getPackageData();
    getAllRoomTypes();
  }, [current_property, deleted]);

  // Delete Package
  const deletPackageHandler = async (pckgId: any) => {
    setLoader(true);
    try {
      let dltReq = await kernelApi.delete(
        `/packages/${pckgId}`,

        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (dltReq.data.status === 1) {
        toast(dltReq.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        setDeleted(nanoid())
        setLoader(false);
      } else {
        toast(dltReq.data.message, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Packages" },
          ]}
        />
        <div className="manage__property__action__right">
          <Button
            className="add__packages__btn"
            icon="bi bi-plus"
            handleClick={() => {
              setopenAddPackageSlider(true);
            }}
            isAccessible={access}
          >
            Add Package
          </Button>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : allPackage.length > 0 ? (
        <>
          <div className="package__list__card__wrappers">
            {allPackage.map((iteam) => {
              return (
                <div
                  className="package__list__cards mb-4"
                  key={iteam.package_id}
                >
                  <div className="package__list__content ms-0">
                    <div className="package__list__image" style={{ margin: '0' }}>
                      <img
                        src={getImage(iteam.image_name)}
                        alt="Package list images"
                      />
                      {/* ------Overlay------ */}
                      <div className="overlay">
                        <div className="action__btn__container">
                          <div
                            className="edit-button me-3"
                            onClick={() => {
                              setopenEditPackageSlider(true);
                              setPckgId(iteam.package_id);
                            }}
                          >
                            <i className="bi bi bi-pencil"></i>
                          </div>
                          {access && (
                            <div
                              className="delete-button"
                              onClick={() => {
                                deletPackageHandler(iteam.package_id);
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="package__list__details__wrapper">
                      <div className="package__list__label mb-3">
                        {iteam.package_name}
                      </div>
                      <div className="package__list__night__dates">
                        <div className="package__list__night">
                          <i className="bi bi-moon-fill"></i> {iteam.nights}
                        </div>
                        <div className="package__list__date">
                          {iteam.date_from}-{iteam.date_to}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="empty__room__div">
          <i className="bi bi-x-circle mb-3"></i>
          <div>{nodataMessage}</div>
        </div>
      )}

      {/* Slider for Package Detail */}
      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openAddPackageSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenAddPackageSlider(false);
        }}
      >
        <AddPackage
          onClose={() => {
            setopenAddPackageSlider(false);
          }}
          getPackageData={getPackageData}
          allPackage={allPackage}
          pckgId={pckgId}
        />
      </SlidingPane>

      {/* Slider for add package tabs */}
      {/* Slider for Package Rates */}
      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openEditPackageSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenEditPackageSlider(false)}
      >
        <EditPackage
          onClose={() => {
            setopenEditPackageSlider(false);
          }}
          getPackageData={getPackageData}
          allPackage={allPackage}
          pckgId={pckgId}
        />
      </SlidingPane>
    </>
  );
};

export default PackageList;
