import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import {
  CATCH_MESSAGE,
  getImage,
  Logout,
  UpdateSidebar,
} from "../../UtilityFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import cmApi from "../../API/cmApi";

// import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

// import button
import Button from "../../views/buttons/Button";

// import select field
import InputSelectField from "../../views/inputtextfield/InputSelectField";

import beApi from "../../API/beApi";
import InputMultiSelectField from "../../views/inputtextfield/InputMultiSelectField";
import CalendarSkeleton from "../../views/loader/CalendarSkeleton";
import gemsApi from "../../API/gemsApi";

import { toast } from "react-toastify";
import {
  SaveBooking,
  SaveBookingCRS,
  SaveBookingDetailedInfo,
  SaveStayDetails,
} from "../../redux/actions/BookingsAction";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import ModifyBooking from "./ModifyBooking";
import CancelBooking from "./CancelBooking";
import NewBookings from "./NewBookings";

import { Tooltip } from "@mui/material";
import InvoiceDownload from "./InvoiceDownload";
import endPoints from "../../API/endPoints";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";
import FetchBooking from "./FetchBookings";

const FrontofficeViewGems = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  UpdateSidebar("Bookings", "Frontoffice View");

  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const { getFloors } = endPoints.PROPERTY_FLOORS;

  const { booking_details } = useSelector((state: RootState) => state.bookings);

  const [currentMonth, setCurrentMonth] = useState(moment().format("MMM"));
  const [currentYear, setCurrentYear] = useState(
    parseInt(moment().format("YYYY"))
  );
  const [firstDate, setFirstDate] = useState(moment().format("YYYY-MM-DD"));

  const [searchInput, setSearchInput] = useState("");
  const [allSource, setAllSource] = useState<any[]>([]);
  const [allSourcesName, setAllSourcesName] = useState<any[]>([]);
  const [selectedSource, setSelectedSource] = useState<any[]>([]);
  const [allocatedRooms, setAllocatedRooms] = useState<any[]>([]);

  const [allFloors, setAllFloors] = useState<{
    ids: string[];
    labels: string[];
  }>({ ids: [], labels: [] });
  const [activeFloorNo, setActiveFloorNo] = useState<string>("-1");

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [allRooms, setAllRooms] = useState<any>([]);

  const [inventoryData, setInventoryData] = useState<any>();
  const [bookingsData, setBookingsData] = useState<any>([]);

  const [selectedRoomTypeIndex, setSelectedRoomTypeIndex] = useState(0);

  const [showRooms, setShowRooms] = useState<boolean>(true);
  const [showBookings, setShowBookings] = useState<boolean>(true);
  const [currentRoom, setCurrentRoom] = useState("");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reRender, setRerender] = useState<boolean>(false);
  const [bookings, setBookings] = useState<any>();
  const [openSlideFetchBooking, setOpenSlideFetchBooking] = useState(false);

  const [openBookingDetailsSlider, setopenBookingDetailsSlider] =
    useState(false);
  const [openModifyBookingSlider, setopenModifyBookingSlider] =
    useState<boolean>(false);
  const [openCancelBookingSlider, setopenCancelBookingSlider] =
    useState<boolean>(false);
  const [openInvoiceDownloadSlider, setopenInvoiceDownloadSlider] =
    useState(false);

  const [openSlideNewBooking, setOpenSlideNewBooking] =
    useState<boolean>(false);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

  const numOfColumns = 7;
  const blockHeight = 50;
  const leftWidth = 120; // 160
  const [gap, setGap] = useState(55);

  const [refreshDataNewBooking, setRefreshDataNewBooking] =
    useState<boolean>(true);

  const {
    gemsBookings,
    getBookingDetails,
    cancelBooking,
    allSources,
    bookingsDataGet,
    roomTypesData,
    bookingDetailsCRSGet,
    stayDetailsByBookingIdGet,
    stayDetailsByBookingIdGetV2,
    bookingDetailsGet,
  } = endPoints.BOOKINGS;

  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const [access, setAccess] = useState(true);

  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;

    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData]);

  useEffect(() => {
    let width =
      (document.getElementById("calendar-header-div")?.clientWidth || 1600) -
      (leftWidth + 4);
    setGap(width / numOfColumns);
  }, [current_property, gap.toFixed(0)]);

  useEffect(() => {
    setRerender(!reRender);
  }, [selectedRoomTypeIndex]);

  // useEffect(() => {
  //   getRoomTypesData(selectedRoomTypeIndex);
  // }, [firstDate]);

  // useEffect(() => {
  //   getAllFloors();
  //   getAllSources();
  // }, [current_property]);

  // render bookings data on selected room type change
  // useEffect(() => {
  //   setShowBookings(bookingsData.length);
  //   setTimeout(() => {
  //     setShowBookings(true);
  //   }, 100);
  // }, [selectedRoomTypeIndex, bookingsData, current_property]);

  // useEffect(() => {
  //   if (selectedRoomTypeIndex > -1 && inventoryData?.rooms.length > 0) {
  //     let source_ids: any[] = [];
  //     allSource.forEach((source: any) => {
  //       if (selectedSource.indexOf(source.ota_name) > -1) {
  //         source_ids.push(source.ota_id);
  //       }
  //     });
  //     getBookingsDataOfGems();
  //   }
  // }, [
  //   firstDate,
  //   selectedRoomTypeIndex,
  //   current_property,
  //   refreshDataNewBooking,
  //   refreshTrigger,
  // ]);

  // API call to get all floors
  // const getAllFloors = async () => {
  //   try {
  //     const response = await gemsApi.post(`${getFloors}`, {
  //       hotel_id: current_property.hotel_id,
  //       user_id: admin_id,
  //     });

  //     let ids: any[] = ["-1"];
  //     let labels: any[] = ["All Floors"];

  //     response.data.allFloors.forEach((floor: any) => {
  //       if (floor.planned_for_room) {
  //         ids.push(floor.floor_no);
  //         labels.push(floor.floor_name);
  //       }
  //     });

  //     setAllFloors({ ids, labels });
  //   } catch (error) {
  //   }
  // };

  // API call for getting all the source
  // const getAllSources = async () => {
  //   let res = await beApi.get(`${allSources}/${current_property.hotel_id}`);
  //   if (res.data.status === 1) {
  //     let sources = res.data.data;
  //     setAllSource(sources);
  //     // sources.unshift("All Sources");
  //     let otaname: string[] = [];
  //     let otaid: number[] = [];
  //     sources.forEach((source: any) => {
  //       otaname.push(source.ota_name);
  //       otaid.push(source.ota_id);
  //     });
  //     setAllSourcesName(otaname);
  //     setSelectedSource(otaname);
  //   }
  // };

  // const onFloorChange = (event: any) => {
  //   setActiveFloorNo(event.target.value);

  //   let source_ids: any[] = [];
  //   allSource.forEach((source: any) => {
  //     if (selectedSource.indexOf(source.ota_name) > -1) {
  //       source_ids.push(source.ota_id);
  //     }
  //   });
  //   let active_room_type_id =
  //   inventoryData?.rooms[selectedRoomTypeIndex].room_type_id;
  //   getBookingsData(active_room_type_id, event.target.value, source_ids);
  // };

  // const sourceOnchange = (e: any) => {
  //   const value = e.target.value;
  //   if (value[value.length - 1] === "all") {
  //     setSelectedSource(
  //       selectedSource.length === allSourcesName.length ? [] : allSourcesName
  //     );
  //     return;
  //   }
  //   setSelectedSource(value);

  //   let room_type = inventoryData?.rooms[selectedRoomTypeIndex].room_type_id;

  //   let source_ids: any[] = [];
  //   allSource.forEach((source: any) => {
  //     if (value.includes(source.ota_name)) {
  //       source_ids.push(source.ota_id);
  //     }
  //   });
  //   setActiveFloorNo("-1");
  //   getBookingsData(room_type, "-1", source_ids);
  // };

  // const getBookingsData = async (
  //   roomTypeId: string,
  //   floor_no?: string,
  //   sources?: string[]
  // ) => {
  //   setIsLoading(true);
  //   try {
  //     let payload: any = {
  //       hotel_id: current_property.hotel_id,
  //       from_date: firstDate,
  //       to_date: moment(firstDate).add(numOfColumns, "d").format("YYYY-MM-DD"),
  //       room_type_id: roomTypeId,
  //     };

  //     if (floor_no && floor_no !== "-1" && sources && sources.length > 0) {
  //       payload.search_fields = {};
  //       payload.search_fields.floors = [parseInt(floor_no)];
  //       payload.search_fields.source = sources;
  //     } else if (floor_no && floor_no !== "-1") {
  //       payload.search_fields = {};
  //       payload.search_fields.floors = [parseInt(floor_no)];
  //       payload.search_fields.source = sources;
  //     } else if (sources && sources.length > 0) {
  //       payload.search_fields = {};
  //       payload.search_fields.floors = allFloors.ids
  //         .filter((floor: string) => floor !== "-1")
  //         .map(Number);
  //       payload.search_fields.source = sources;
  //     }

  //     const response = await cmApi.post(`${bookingsDataGet}`, payload, {
  //       headers: {
  //         Authorization: "Bearer " + auth_token,
  //       },
  //     });

  //     if (response.data.status === 1) {
  //       setBookingsData(response.data.data || []);
  //       setAllRooms(response.data.resource || []);
  //       setAllocatedRooms(response.data.alocated_resources || []);
  //     } else {
  //       setBookingsData([]);
  //       setAllRooms([]);
  //     }
  //   } catch (error) {
  //     setBookingsData([]);
  //     setAllRooms([]);
  //   }
  //   setIsLoading(false);
  // };

  // const getRoomTypesData = async (roomTypeIndex: number) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await cmApi.get(
  //       `${roomTypesData}/${current_property.hotel_id}/${firstDate}/${moment(
  //         firstDate
  //       )
  //         .add(numOfColumns, "d")
  //         .format("YYYY-MM-DD")}/0`
  //     );
  //     if (response.data.status === 1) {
  //       setInventoryData(response.data.data);
  //       let room_type_id =
  //         response.data.data.rooms[roomTypeIndex || 0]?.room_type_id ?? 0;
  //       if (room_type_id) {
  //         getBookingsData(room_type_id);
  //         setActiveFloorNo("-1");
  //       }
  //     } else {
  //       setInventoryData([]);
  //     }
  //   } catch (error) {
  //   }
  //   setIsLoading(false);
  // };

  const getProvisionalBookingDetails = async (booking: any) => {
    try {
      const response = await gemsApi.post(
        `${getBookingDetails}`,
        {
          hotel_id: current_property.hotel_id,
          booking_id: booking.booking_id,
          user_id: admin_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(SaveBookingCRS(response.data));
        setopenBookingDetailsSlider(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  const getStayDetailsByBookingId = async (booking: any) => {
    try {
      let payload = {
        hotel_id: current_property.hotel_id,
        booking_id: booking.booking_id,
        user_id: admin_id,
        room_id: booking.room_id,
      };

      const response = await gemsApi.post(
        `${stayDetailsByBookingIdGet}`,
        payload
      );

      if (response.data.status === 1) {
        dispatch(SaveStayDetails(response.data));
        setTimeout(() => {
          navigate("/bookings/stay-details", {
            state: { room_id: booking.room_id },
          });
        }, 300);
      }
    } catch (error) {
      toast.error("Could not fetch stay details");
    }
  };

  const getBookingDetailsById = async (bookingId: string) => {
    try {
      const response = await gemsApi.post(`${stayDetailsByBookingIdGetV2}`, {
        user_id: admin_id,
        booking_id: bookingId,
        hotel_id: current_property.hotel_id,
      });

      if (response.data.status) {
        dispatch(SaveBookingDetailedInfo(response.data));
      }
    } catch (error) {
      toast.error("Booking details not found");
    }
  };

  const getBookingDetailsForCheckIn = async (bookingId: any) => {
    try {
      const response = await gemsApi.post(`${bookingDetailsGet}`, {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
        booking_id: bookingId,
      });

      if (response.data.status === 1) {
        dispatch(SaveBooking(response.data));
        // getBookingDetailsById(bookingId);
        setTimeout(() => {
          navigate("/bookings/check-in", {
            state: { allocated_rooms: allocatedRooms },
          });
        }, 250);
      }
    } catch (error) {
      toast.error("Booking details not found");
    }
  };

  // Function Will Call On Click Upon Bookings Details
  const onBookingClick = (booking: any) => {
    const allocationStatus = booking.allocation;

    setCurrentRoom(booking.resource);
    if (allocationStatus === 0) {
      getProvisionalBookingDetails(booking);
    } else if (allocationStatus === 1) {
      getStayDetailsByBookingId(booking);
    } else if (allocationStatus === 4) {
      setBookings(booking);
      setopenInvoiceDownloadSlider(true);
    }
  };

  // Positioning The CalendarSkeleton
  const getPosition = (book: any) => {
    let lastDate = moment(firstDate)
      .add(numOfColumns, "d")
      .format("YYYY-MM-DD");
    let first_date = document.getElementById(firstDate);
    let last_date = document.getElementById(lastDate);
    let str_date = document.getElementById(book.check_in);
    let end_date = document.getElementById(book.check_out);
    let room = document.getElementById(book?.room_no);

    let x0 = document
      .getElementById("calendar-header")
      ?.getBoundingClientRect().left;
    let x1 = str_date
      ? str_date.getBoundingClientRect().left
      : (first_date?.getBoundingClientRect().left || leftWidth) - gap;
    let x2 = end_date
      ? end_date.getBoundingClientRect().left
      : (last_date?.getBoundingClientRect().left || 2500) + gap;

    let y0 = document
      .getElementById("calendar-body")
      ?.getBoundingClientRect().top;
    let y1 = room?.getBoundingClientRect().top;

    if (x0 && x1 && x2 && y0 && y1 && (str_date || end_date)) {
      return {
        x: x1 - x0 + 20,
        y: y1 - y0 + 5 - selectedRoomTypeIndex * blockHeight,
        width: x2 - x1,
      };
    } else {
      return {
        x: 0,
        y: 0,
        width: 0,
      };
    }
  };

  const onMonthSelect = (Month: string, monthIndex: number) => {
    setCurrentMonth(Month);
    let month = (monthIndex + 1).toString();
    setFirstDate(
      currentYear.toString() + (month.length === 1 ? "-0" : "-") + month + "-01"
    );
  };

  const onYearChange = (change: number) => {
    let year = currentYear + change;
    if (year <= moment().year()) {
      setCurrentYear(year);
      setFirstDate(
        year.toString() +
        "-" +
        moment().month(currentMonth).format("MM") +
        "-01"
      );
    }
  };

  const getPrevDate = () => {
    let date = moment(firstDate)
      .subtract(numOfColumns, "d")
      .format("YYYY-MM-DD");
    setFirstDate(date);
    setCurrentYear(moment(date).year());
    setCurrentMonth(moment(date).format("MMM"));
  };

  const getNextDate = () => {
    let date = moment(firstDate).add(numOfColumns, "d").format("YYYY-MM-DD");
    setFirstDate(date);
    setCurrentYear(moment(date).year());
    setCurrentMonth(moment(date).format("MMM"));
  };

  const getCurrentDate = () => {
    setFirstDate(moment().format("YYYY-MM-DD"));
    setCurrentYear(moment().year());
    setCurrentMonth(moment().format("MMM"));
  };

  const onDateChange = (date: any) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    setFirstDate(date_str);
    setCurrentYear(moment(date_str).year());
    setCurrentMonth(moment(date_str).format("MMM"));
  };

  const toggleViewRooms = (index: number) => {
    setSelectedRoomTypeIndex(index);
    if (selectedRoomTypeIndex === index) {
      setSelectedRoomTypeIndex(-1);
      setShowRooms(true);
    } else {
      setSelectedRoomTypeIndex(index);
      setShowRooms(true);
    }
  };

  const onModifyBookingClick = () => {
    setopenBookingDetailsSlider(false);
    setopenModifyBookingSlider(true);
  };

  const onCheckinClick = () => {
    setopenBookingDetailsSlider(false);
    getBookingDetailsForCheckIn(booking_details?.booking_details?.booking_id);
    getBookingDetailsById(booking_details?.booking_details?.booking_id);
  };

  // Api Call For Cancel Booking
  const onCancelBookingClick = (booking) => {
    setopenBookingDetailsSlider(false);
    // setopenCancelBookingSlider(true);
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to cancel this booking ?`,
        "DELETE",
        async () => {
          try {
            let cancelBookingRes = await gemsApi.post(
              `${cancelBooking}`,
              {
                hotel_id: current_property.hotel_id,
                booking_id: booking_details?.booking_details?.booking_id,
                user_id: admin_id,
              },
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );

            if (cancelBookingRes.data.status === 1) {
              toast.success(`${cancelBookingRes.data.message}`);
              getBookingsDataOfGems();
            } else {
              toast.error(`${cancelBookingRes.data.message}`);
            }
          } catch (error: any) {
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
              Logout();
            }
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };

  useEffect(() => {
    getBookingsDataOfGems();
  }, [firstDate, current_property, refreshDataNewBooking, refreshTrigger]);

  // Booking Data Get Api Who Have Taken Gems Option
  const getBookingsDataOfGems = async () => {
    setIsLoading(true);
    try {
      let bookingsDataRes = await gemsApi.post(`/${gemsBookings}`, {
        hotel_id: current_property.hotel_id,
        start_date: firstDate,
        end_date: moment(firstDate).add(numOfColumns, "d").format("YYYY-MM-DD"),
      });

      if (bookingsDataRes?.data?.status === 1) {
        setBookingsData(bookingsDataRes?.data?.bookings);
        setInventoryData(bookingsDataRes?.data?.total_booked_room_count);

        setIsLoading(false);
      } else {
        setBookingsData([]);
        setInventoryData([]);

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__bookings__action__right">
          <div className="status__icon__wrapper booking__color">
            <i className="bi bi-circle-fill me-1"></i> Booking
          </div>
          <div className="status__icon__wrapper checkedin__color">
            <i className="bi bi-circle-fill me-1"></i> Checked-in
          </div>
          <div className="status__icon__wrapper checkedout__color">
            <i className="bi bi-circle-fill me-1"></i> Checked-out
          </div>
          <div className="fetch__booking__btn__wrapper">
            <Button
              className="fetch_bookings"
              icon="bi bi-download"
              handleClick={() => setOpenSlideFetchBooking(true)}
              isAccessible={access}
            >
              Fetch
            </Button>
          </div>
          <div className="new__booking__btn__wrapper">
            <Button
              className="new__bookings"
              icon="bi bi-plus-lg"
              handleClick={() => setOpenSlideNewBooking(true)}
              isAccessible={access}
            >
              New
            </Button>
          </div>
          <div className="calendar__view__wrapper">
            <div className="calendar__view__tab">
              <ul className="nav nav-tabs" id="mealPlanTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() => navigate("/bookings/crs-view")}
                    className="nav-link"
                    id="crs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="crs"
                    aria-controls="crs"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-calendar2-range"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="pms-tab"
                    data-bs-toggle="tab"
                    data-bs-target="pms"
                    aria-controls="pms"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-calendar-week"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() => navigate("/bookings/list-view")}
                    className="nav-link"
                    id="list-tab"
                    data-bs-toggle="tab"
                    data-bs-target="list"
                    aria-controls="list"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-grid-3x3-gap"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="hr--line"></div>

      <div id="frontoffice-view-wrapper">
        <div className="calendar__month__wrapper">
          <div className="calendar__year__wrapper">
            <span className="left__arrow" onClick={() => onYearChange(-1)}>
              <i className="bi bi-chevron-left"></i>
            </span>
            <span className="year__label mx-1">{currentYear}</span>
            <span className="right__arrow" onClick={() => onYearChange(1)}>
              <i className="bi bi-chevron-right"></i>
            </span>
          </div>
          <div className="calendar__month__list">
            <div className="month__wrapper">
              {moment.monthsShort().map((month, i) => {
                return (
                  <div
                    className={`month__item ${month === currentMonth ? "active--month" : ""
                      }`}
                    key={month}
                    onClick={() => onMonthSelect(month, i)}
                  >
                    {month}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="calendar__filter__wrapper">
          {/* <div className="search__booking__wrapper">
            <i className="bi bi-search"></i>
            <input
              placeholder="Search Bookings"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div className="filter__dropdown__wrapper mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={activeFloorNo}
              options={allFloors.labels}
              values={allFloors.ids}
              handleChange={onFloorChange}
            />
          </div> */}

          <div className="search__right__wrapper">
            {/* <div className="filter__dropdown__wrapper mui__input__fieldset__legends__unset">
              <InputMultiSelectField
                label=""
                value={selectedSource}
                handleChange={(evnt) => sourceOnchange(evnt)}
                options={allSourcesName}
                isAllSelected={
                  allSourcesName.length > 0 &&
                  selectedSource.length === allSourcesName.length
                }
              />
            </div> */}

            <div className="change__calendar__filter">
              <span onClick={getPrevDate} className="left__arrow">
                <i className="bi bi-chevron-left"></i>
              </span>

              <div className="booking__crs__material__input">
                <div className="inventory__date__select__btn mui__input__fieldset__legends__unset">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      open={openDatePicker}
                      onOpen={() => setOpenDatePicker(true)}
                      onClose={() => setOpenDatePicker(false)}
                      value={moment(firstDate)}
                      onChange={onDateChange}
                      inputFormat="dd MMM yyyy"
                      disableMaskedInput
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onClick={() => setOpenDatePicker(true)}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <span onClick={getNextDate} className="right__arrow">
                <i className="bi bi-chevron-right"></i>
              </span>

              <span
                onClick={getCurrentDate}
                className="text__label__bookings mx-1"
              >
                Today
              </span>
            </div>
          </div>
        </div>

        <div className="calendar__card__wrapper">
          <div className="calendar__main__content mb-5">
            {isLoading && <CalendarSkeleton rows={1} />}
            <div
              className="calendar__layout__wrapper"
              style={{ opacity: isLoading ? 0 : 1 }}
            >
              {/* Calender Header Part */}
              <div className="calendar__header__panel" id="calendar-header-div">
                <svg
                  id="calendar-header"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="120"
                >
                  <g transform="translate(0,0)">
                    <g transform="translate(0,30)">
                      <text
                        className="calendar__label__text"
                        transform="translate(17,25)"
                      >
                        ROOMS
                      </text>
                    </g>
                  </g>
                  {/* Month and Year Section of Header */}
                  <g transform="translate(0, 0)">
                    <g
                      id="current-month"
                      transform={`translate(${leftWidth}, 0)`}
                    >
                      <line
                        x1="0"
                        x2="0"
                        y1="0"
                        y2="40"
                        stroke="#808080" // "#dee2e6"
                        strokeWidth="0.5"
                      ></line>
                      <text
                        className="month__year__label"
                        transform="translate(15, 25)"
                      >
                        {moment(firstDate).format("MMM yyyy")}
                      </text>
                      <rect height="40px" opacity="0"></rect>
                    </g>
                  </g>
                  {/* Day of Weeks Section of Header */}
                  <g transform="translate(0,0)">
                    {inventoryData?.map((inv: any, idx: number) => {
                      let date = moment(firstDate)
                        .add(idx, "days")
                        .format("YYYY-MM-DD");
                      let today = moment().format("YYYY-MM-DD");
                      return (
                        <React.Fragment key={idx}>
                          <g
                            id={date}
                            transform={`translate(${leftWidth + (idx + 0.5) * gap - 20
                              },45)`}
                          >
                            <rect
                              height="40"
                              width="40"
                              rx="2"
                              ry="2"
                              fill={date === today ? "#223189" : "#F5F6F8"}
                            ></rect>
                            <text
                              className={
                                date === today
                                  ? "calendar__grid__text active__color"
                                  : "calendar__grid__text"
                              }
                              x="20"
                              y="18"
                            >
                              {moment(date).format("ddd")}
                            </text>
                            <text
                              className={
                                date === today
                                  ? "calendar__grid__text calendar__day active__color"
                                  : "calendar__grid__text calendar__day"
                              }
                              x="20"
                              y="36"
                            >
                              {moment(date).format("DD")}
                            </text>
                            <g transform="translate(0,48)">
                              <rect
                                className="calendar__grid__row_inventory"
                                height="20"
                                width="40"
                                rx="2"
                                ry="2"
                              ></rect>
                              <text
                                className="availability__inventory"
                                transform="translate(20,15)"
                              >
                                {inv.booked_room < 10
                                  ? `0${inv.booked_room}`
                                  : inv.booked_room}
                              </text>
                            </g>
                          </g>
                          {date === today ? (
                            <line
                              x1={leftWidth + gap * idx}
                              x2={leftWidth + gap * idx}
                              y1="40"
                              y2="120"
                              strokeWidth="0.5"
                              stroke="white" //"#414141"//"#dee2e6"
                            ></line>
                          ) : (
                            <line
                              x1={leftWidth + gap * idx}
                              x2={leftWidth + gap * idx}
                              y1="40"
                              y2="120"
                              strokeWidth="0.5"
                              stroke="#808080" //"#414141"//"#dee2e6"
                            ></line>
                          )}
                        </React.Fragment>
                      );
                    })}
                    <line
                      x1="0"
                      x2="100%"
                      y1="120"
                      y2="120"
                      strokeWidth="0.5"
                      stroke="#808080" //"#dee2e6"
                    ></line>
                    <line
                      x1={leftWidth}
                      x2="100%"
                      y1="40"
                      y2="40"
                      strokeWidth="0.5"
                      stroke="#808080" //"#dee2e6"
                    ></line>
                    <line
                      x1={leftWidth}
                      x2={leftWidth}
                      y1="40"
                      y2="120"
                      strokeWidth="0.5"
                      stroke="#808080" //"#dee2e6"
                    ></line>
                  </g>
                </svg>
              </div>
              {/* Calender Body Part */}
              <div className="calendar__room__types__section">
                <svg
                  id="calendar-body"
                  height={
                    (bookingsData?.length +
                      bookingsData[selectedRoomTypeIndex]?.rooms?.length) *
                    blockHeight || 500
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                >
                  <g transform="translate(0, 0)">
                    {bookingsData?.map((rmtype: any, i: number) => {
                      const RoomTypeName =
                        rmtype.short_code || rmtype.room_type_name;
                      return (
                        <g
                          key={rmtype?.room_type_id}
                          transform={`translate(0,${
                            // rmtype?.rooms?.length * blockHeight
                            i <= selectedRoomTypeIndex || !showRooms
                              ? "0"
                              : bookingsData[selectedRoomTypeIndex]?.rooms
                                ?.length * blockHeight
                            })`}
                        >
                          <rect
                            className="calendar__grid__row"
                            y={i * blockHeight}
                            height={blockHeight}
                            width="100%"
                          ></rect>
                          <g onClick={() => toggleViewRooms(i)}>
                            <rect
                              className="room__type__bg room__type__bg__Front"
                              y={i * blockHeight}
                              height={blockHeight}
                              width={leftWidth}
                            ></rect>
                            {/* Material Ui Tool Tip */}
                            <Tooltip
                              title={rmtype.room_type_name}
                              placement="top"
                              arrow
                            >
                              <text
                                className="room__typename__bg"
                                y={i * blockHeight}
                                transform={`translate(17,${blockHeight / 2 + 5
                                  })`}
                              >
                                {RoomTypeName.length > 5
                                  ? RoomTypeName.substring(0, 4) + "..."
                                  : RoomTypeName}
                              </text>
                            </Tooltip>

                            <g
                              style={{ cursor: "pointer" }}
                              transform={`translate(100,${22 + i * blockHeight
                                })`}
                            >
                              <path
                                className={`${showRooms && selectedRoomTypeIndex === i
                                  ? "rotate-180"
                                  : ""
                                  }`}
                                d="m -10,5 5,-5 5,5 z"
                              ></path>
                            </g>
                          </g>
                          <line
                            x1="0"
                            x2="100%"
                            y1={(i + 1) * blockHeight}
                            y2={(i + 1) * blockHeight}
                            stroke="#808080" // "#dee2e6"
                            strokeWidth="0.5"
                          ></line>
                          {rmtype?.room_type_booked_room_count?.map(
                            (inv: any, j: number) => {
                              let date = moment(firstDate)
                                .add(j, "days")
                                .format("YYYY-MM-DD");

                              return (
                                <React.Fragment key={j}>
                                  <g
                                    key={j}
                                    transform={`translate(${leftWidth + (j + 0.5) * gap - 20
                                      },${15 + blockHeight * i})`}
                                  >
                                    <rect
                                      className="calendar__grid__row_inventory"
                                      rx="2"
                                      ry="2"
                                      width="40"
                                      height="20"
                                    ></rect>
                                    <text
                                      className="availability__inventory"
                                      transform="translate(20,15)"
                                    >
                                      {inv.date === date && inv.room_count < 10
                                        ? `0${inv.room_count}`
                                        : inv.room_count}
                                    </text>
                                  </g>
                                  <g transform={`translate(${leftWidth},0)`}>
                                    <line
                                      x1={j * gap}
                                      x2={j * gap}
                                      y1={i * blockHeight}
                                      y2={(i + 1) * blockHeight}
                                      stroke="#808080" //"#dee2e6"
                                      strokeWidth="0.5"
                                    ></line>
                                  </g>
                                </React.Fragment>
                              );
                            }
                          )}
                        </g>
                      );
                    })}
                  </g>

                  {/* Toggle View Bookings */}

                  {bookingsData[selectedRoomTypeIndex]?.rooms?.map(
                    (room: any, index: number) => {
                      return (
                        <g
                          key={index}
                          transform={`translate(0, ${selectedRoomTypeIndex * blockHeight
                            })`}
                        >
                          <g>
                            {room?.bookings?.map(
                              (booking: any, idx: number) => {
                                let X = getPosition(booking).x;
                                let Y = getPosition(booking).y;
                                let Width = getPosition(booking).width;

                                if (
                                  booking?.room_no ===
                                  room?.room_no?.toString() &&
                                  Y &&
                                  Width
                                ) {
                                  return (
                                    <g
                                      key={idx}
                                      onClick={() => onBookingClick(booking)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <g transform={`translate(${X}, ${Y})`}>
                                        <rect
                                          width={Width}
                                          height="40"
                                          rx="5"
                                          ry="5"
                                          fill="#ffffff"
                                        ></rect>
                                        <rect
                                          width={Width - 4}
                                          height="40"
                                          rx="5"
                                          ry="5"
                                          fill={`${booking.colour}`}
                                        ></rect>
                                        <g transform="translate(8, 8)">
                                          <image
                                            href={booking.channel_logo}
                                            height="25"
                                            width="25"
                                          />
                                        </g>

                                        <Tooltip
                                          title={booking.guest_name}
                                          placement="top"
                                          arrow
                                        >
                                          <g
                                            transform="translate(40, 0)"
                                            clipPath={`url(#clipRect${idx})`}
                                          >
                                            <defs>
                                              <clipPath id={`clipRect${idx}`}>
                                                <rect
                                                  width={Width - 50}
                                                  height="40"
                                                />
                                              </clipPath>
                                            </defs>
                                            <g transform="translate(10, 25)">
                                              <text
                                                className="guest__name"
                                                fill="#000000"
                                              >
                                                {booking.guest_name}
                                              </text>
                                            </g>
                                            <path
                                              d="m0 3c0-3 0-3 3-3h55c3 0 3 0 3 3v32c0 3 0 3-3 3h-55c-3 0-3 0-3-3z"
                                              fillOpacity="0"
                                            />
                                          </g>
                                        </Tooltip>
                                      </g>
                                    </g>
                                  );
                                }
                              }
                            )}
                          </g>
                          {/* This Section for Room Numbers Showing In leftside of View */}
                          <g
                            id={room.room_no}
                            transform={`translate(0, ${(index + 1) * blockHeight
                              })`}
                          >
                            <rect
                              x="0"
                              y="0"
                              width={leftWidth}
                              height={blockHeight}
                              fill="#ffffff"
                            ></rect>
                            <g transform="translate(0, 0)">
                              <g>
                                <g transform="translate(18, 13)">
                                  <circle
                                    cx="3"
                                    cy="14"
                                    r="7"
                                    fill="#2AB930"
                                  ></circle>
                                </g>
                                <text transform="translate(36, 32)">
                                  {room?.room_no}
                                </text>
                              </g>
                              <rect
                                x="0"
                                y="0"
                                opacity="0"
                                height={blockHeight}
                                width="135"
                              ></rect>
                              <line
                                x1="0"
                                x2="100%"
                                y1={blockHeight}
                                y2={blockHeight}
                                stroke="#808080" // "#dee2e6"
                                strokeWidth="0.5"
                              ></line>
                              <line
                                x1={leftWidth}
                                x2={leftWidth}
                                y1="0"
                                y2={blockHeight}
                                stroke="#808080" //"#dee2e6", 7373737, 808080
                                strokeWidth="0.5"
                              ></line>
                            </g>
                          </g>
                        </g>
                      );
                    }
                  )}
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Booking Detail Slider */}

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openBookingDetailsSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenBookingDetailsSlider(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setopenBookingDetailsSlider(false)}
              className="bi bi-x"
            ></i>
          </div>
          {booking_details && (
            <>
              <div className="standard-page--label">Booking Detail</div>
              <div className="side__slider__sub__heading me-3">
                ID : {booking_details?.booking_details?.booking_id}
              </div>
            </>
          )}
        </div>
        <div className="height-64px"></div>
        {booking_details ? (
          <div className="booking__details__guest__wrapper">
            <div className="guest__detail__card">
              <div className="guest__name__wrapper">
                <div className="guest__name">
                  {booking_details?.booking_details?.guest_name}
                </div>
                <div className="booking__channel__icon">
                  <img
                    className="booking__source__image"
                    src={booking_details?.booking_details?.channel_logo}
                    alt="Booking Source Icon"
                  />
                </div>
              </div>

              <div className="guest__booking__date mt-2">
                Booking Date - {booking_details?.booking_details?.booking_date}
              </div>
              <div className="guest__email__contact__details mt-2">
                <div className="guest__number">
                  <i className="bi bi-telephone"></i>{" "}
                  {booking_details?.booking_details?.contact_number}{" "}
                </div>
                <div className="guest__email__id">
                  <i className="bi bi-envelope"></i>{" "}
                  {booking_details?.booking_details?.email}
                </div>
              </div>

              <div className="checkin__checkout__wrapper mt-3">
                <div className="checkinout__content">
                  <div className="check__inout__details check-in-border">
                    <div className="check__inout__icon check-in-color">
                      <i className="bi bi-arrow-down-right-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-in-color">
                        {booking_details?.check_in}
                      </div>
                      <div className="check__inout__label">Check-in</div>
                    </div>
                  </div>
                </div>

                <div className="number__of__nights__wrapper">
                  <div className="night__icon">
                    <i className="bi bi-moon-fill"></i>
                  </div>
                  <div className="night__number">
                    {booking_details?.no_of_nights}
                  </div>
                </div>

                <div className="checkinout__content">
                  <div className="check__inout__details check-out-border">
                    <div className="check__inout__icon check-out-color">
                      <i className="bi bi-arrow-up-left-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-out-color">
                        {booking_details?.check_out}
                      </div>
                      <div className="check__inout__label">Check-out</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="standard-page--label my-3">Room Details</div>
            {booking_details?.room_details?.map((room: any, i: number) => {
              return (
                <div key={i} className="booking__room__detail__wrapper mb-4">
                  <div className="booking__room__image">
                    <img src={getImage(room.room_image)} alt={room.room_type} />
                  </div>
                  <div className="booking__room__details">
                    <div className="room__type__meal__plan mb-3">
                      <div className="room__type__name">
                        <i className="bi bi-door-open me-2"></i>
                        {room.room_type}
                      </div>

                      {/* <div className="room__type__meal__plan">
                       
                      </div> */}
                    </div>
                    <div className="room__type__meal__plan mb-3">
                      <div className="room__type__name">
                        <i className="bi bi-cup-straw me-2"></i>
                        {room.meal_plan}
                      </div>
                    </div>
                    <div className="booking__room__type__rate mb-3">
                      <i className="bi bi-people me-2"></i>{" "}
                      <div className="adult__child__wrapper">
                        <div className="adult__wrapper me-2">
                          {room.adult} Adult
                        </div>
                        <div className="child__wrrapper">
                          {room.child} Child
                        </div>
                      </div>
                    </div>
                    <div className="booking__room__nights mb-3">
                      <i className="bi bi-question-circle me-2"></i> Rooms x{" "}
                      {room.no_of_rooms}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="booking__details__price__wrapper">
              <div className="standard-page--label my-3">Price Breakup</div>
              <div className="booking__details__price__card">
                <div className="booking__detaila__price__input__wrapper me-3">
                  <div>
                    <div className="price__input__label mb-2">Total Price</div>
                    <div className="price__input">
                      <div className="price__label">
                        {booking_details?.price_details?.currency_name}
                      </div>
                      <div className="price__amount">
                        {booking_details?.price_details?.total_price ?? " "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="booking__detaila__price__input__wrapper">
                  <div>
                    <div className="price__input__label mb-2">Tax</div>
                    <div className="price__input">
                      <div className="price__label">
                        {booking_details?.price_details?.tax}
                      </div>
                      <div className="price__amount">
                        {booking_details?.price_details?.gst_price ?? " "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper booking__btn__alignment">
                {booking_details?.can_modify && (
                  <Button
                    handleClick={onModifyBookingClick}
                    className="modify__bookinhg__btn"
                    isAccessible={access}
                  >
                    Modify Booking
                  </Button>
                )}
                {booking_details?.can_cancel && (
                  <Button
                    handleClick={() => onCancelBookingClick(booking_details)}
                    className="cancel__bookinhg__btn"
                    isAccessible={access}
                  >
                    Cancel Booking
                  </Button>
                )}
                {booking_details?.can_checkin && (
                  <Button
                    handleClick={onCheckinClick}
                    className="checkin__bookinhg__btn"
                    isAccessible={access}
                  >
                    Check-in
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>Booking details not found</div>
          </div>
        )}
      </SlidingPane>

      {/* Fetching Booking Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openSlideFetchBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideFetchBooking(false)}
      >
        <FetchBooking onClose={() => setOpenSlideFetchBooking(false)} />
      </SlidingPane>

      {/* Modify Booking Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={openModifyBookingSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenModifyBookingSlider(false);
          setopenBookingDetailsSlider(true);
        }}
      >
        {booking_details ? (
          <ModifyBooking
            onClose={() => {
              setopenModifyBookingSlider(false);
              setopenBookingDetailsSlider(true);
            }}
            trigger={refreshTrigger}
            setTrigger={setRefreshTrigger}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      {/* Cancel Booking Slider */}

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openCancelBookingSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenCancelBookingSlider(false);
          setopenBookingDetailsSlider(true);
        }}
      >
        {booking_details ? (
          <CancelBooking
            onClose={() => {
              setopenCancelBookingSlider(false);
              setopenBookingDetailsSlider(true);
            }}
            sliderState={setopenCancelBookingSlider}
            trigger={refreshTrigger}
            setTrigger={setRefreshTrigger}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      {/* New Booking Slider */}

      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={openSlideNewBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideNewBooking(false)}
      >
        <NewBookings
          onClose={() => setOpenSlideNewBooking(false)}
          refreshData={() => setRefreshDataNewBooking(!refreshDataNewBooking)}
        />
      </SlidingPane>

      {/* Invoice Download Slider */}

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openInvoiceDownloadSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenInvoiceDownloadSlider(false)}
      >
        {booking_details ? (
          <InvoiceDownload
            roomNo={currentRoom}
            onClose={() => setopenInvoiceDownloadSlider(false)}
            bookings={bookings}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </>
  );
};

export default FrontofficeViewGems;
