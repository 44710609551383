import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import LeftSideNav from '../../components/onboarding/LeftSidenav';
import NextButton from "../../views/buttons/NextButton";

// import lottie
import Lottie from 'lottie-react-web'
import Confetti from '../../assets/lottie/confetti.json';
import Congratulations from '../../assets/lottie/congratulations.json';
import CurrentPropertyLabel from '../../components/property/CurrentPropertyLabel';

const AddPropertySuccess = () => {
    const navigate = useNavigate();

    const { property_data } = useSelector((state: RootState) => state.properties);

    const NextButtonClick = () => {
        navigate("/choose-plan", { state: { redirect: "/add-room-type" } });
    }

    return (
        <div className="div-page-wrapper">
            <div className="div__wrapper">
                <LeftSideNav>
                    <>
                        <div className="div__user__testimonials__wrapper">
                            <div className="screen__progress__wrapper">
                                <div className="progress__status__wrapper">
                                    <div className="status__icon__wrapper bg-white">
                                        <i className="bi bi-check progress--icon--check"></i>
                                    </div>
                                    <div className="status__label">Create {property_data.data.length === 1 ? "First" : ""} Property</div>
                                </div>

                                <div className="progress__status__wrapper mt-5">
                                    <div className="status__icon__wrapper">
                                    </div>
                                    <div className="status__label">Select Subscription Plan</div>
                                </div>

                                <div className="progress__status__wrapper mt-5">
                                    <div className="status__icon__wrapper">
                                    </div>
                                    <div className="status__label">Create Room Type</div>
                                </div>
                                <div className="progress__sub__list__wrapper">
                                    <div className="progress__sub__listitems">Enter Room Deails</div>
                                    {/* <div className="progress__sub__listitems">Choose Amenities</div> */}
                                    <div className="progress__sub__listitems">Define Occupancy</div>
                                    <div className="progress__sub__listitems">Add Rates</div>
                                    <div className="progress__sub__listitems">Upload Images</div>
                                </div>
                                <div className="progress__status__wrapper mt-5">
                                    <div className="status__icon__wrapper"></div>
                                    <div className="status__label">Create Floors</div>
                                </div>
                                <div className="progress__status__wrapper mt-5">
                                    <div className="status__icon__wrapper"></div>
                                    <div className="status__label">Add Rooms</div>
                                </div>
                            </div>
                        </div>

                        <div className="progress-bar-wrapper">
                            <div className="progress-bar-text">1/5</div>
                            <div className="progress-bar-status">
                                <ProgressBar percent={20} filledBackground="#FFFFFF" />
                            </div>
                        </div>
                    </>
                </LeftSideNav>

                <div className="div__input__wrapper__right div__right__side__box justify-content-center ps-0">
                    <CurrentPropertyLabel />
                    <div className="congratulations__message__wrapper">
                        <div className="lottie__wrapper">
                            <Lottie
                                options={{
                                    animationData: Congratulations
                                }} />
                        </div>
                        <div className="congratulation--text">Congratulations,</div>
                        <div className="congratulation--sub--text mb-4">You have successfully added your first property.</div>
                        <NextButton handleClick={NextButtonClick} />
                    </div>
                    <div className="confetti__wrapper">
                        <Lottie
                            options={{
                                animationData: Confetti,
                                loop: false
                            }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPropertySuccess;