import moment from "moment";
import React, { RefObject, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import beApi from "../../../../API/beApi";
import { RootState } from "../../../../redux/store";
import Button from "../../../../views/buttons/Button";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
// import of DatePicker";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import kernelApi from "../../../../API/kernelApi";
import { CATCH_MESSAGE, getImage, Logout } from "../../../../UtilityFunctions";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../../redux/actions/PromptAction";
import { nanoid } from "@reduxjs/toolkit";

export interface IEditPackageSlider {
  onClose: () => void;
  getPackageData: () => void;
  allPackage: any[];
  pckgId: any;
}

const EditPackage: React.FC<IEditPackageSlider> = ({
  onClose,
  getPackageData,
  allPackage,
  pckgId,
}) => {
  // Data From Redux
  const { admin_id, auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property, property_data } = useSelector(
    (state: RootState) => state.properties
  );
  const { allRoomType } = useSelector(
    (state: RootState) => state.manage_channels
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // States
  const [access, setAccess] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [pkcgName, setPkcgName] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [inclusion, setInclusion] = useState<any>("");
  const [exclusion, setExclusion] = useState<any>("");
  const [price, setPrice] = useState<any>("");
  const [discount, setDiscount] = useState<any>("");
  const [nights, setNights] = useState<any>("");
  const [adult, setAdult] = useState<any>("");
  const [child, setChild] = useState<any>("");
  const [extraPrson, setExtraPrson] = useState<any>("");
  const [extraPrsonPrice, setExtraPrsonPrice] = useState<any>("");
  const [xtraChld, setXtraChld] = useState<any>("");
  const [xtraChildPrice, setXtraChildPrice] = useState<any>("");
  const [allRoomOptn, setAllRoomOptn] = useState<any>({
    all_rooms: [],
    room_ids: [],
  });
  const [selectedRoomtype, setSelectedRoomtype] = useState<any>("");
  const [fromDate, setFromDate] = useState<any>(moment());
  const [toDate, setToDate] = useState<any>(moment().add(10, "d"));
  const [openFromDate, setFromDateOpen] = useState(false);
  const [openToDate, setToDateOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [singleDate, setSingleDate] = useState<any>([]);
  const [blackOutArray, setBlackOutArray] = useState<any>([]);
  const [newImages, setNewImages] = useState<any[]>([]);
  const [edtPackageImage, setEdtPackageImage] = useState<any>("");
  // const [edtPackageImageId, setEdtPackageImageId] = useState<any>("");
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const inputPackage: RefObject<HTMLInputElement> = React.createRef();
  const dispatch = useDispatch();

  useEffect(() => {
    let allRooms: string[] = [];
    let allRoomsId: any[] = [];
    allRoomType.map((room: any) => {
      allRooms.push(room?.room_type);
      allRoomsId.push(room?.room_type_id);
    });

    setAllRoomOptn({
      all_rooms: allRooms,
      room_ids: allRoomsId,
    });

    const packageData = allPackage.filter((iteam) => {
      return iteam.package_id === pckgId;
    });

    const [x] = packageData;
  }, [allRoomType, allPackage]);

  useEffect(() => {
    getEditPackageData();
  }, []);

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  // Get Package Data
  const getEditPackageData = async () => {
    setLoader(true);
    try {
      const pckgDataRes = await kernelApi.get(`/packages/${pckgId}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (pckgDataRes.data.status === 1) {
        const roomType = allRoomType.filter((x: any) => {
          return x.room_type_id === pckgDataRes?.data.data.room_type_id;
        });

        setBlackOutArray(pckgDataRes?.data.data.blackout_dates);
        setSelectedRoomtype(pckgDataRes?.data.data.room_type_id);
        setEdtPackageImage(pckgDataRes?.data.img_details);
        setDescription(pckgDataRes?.data.data.package_description ?? "");
        setInclusion(pckgDataRes?.data.data.inclusion ?? "");
        setExclusion(pckgDataRes?.data.data.exclusion ?? "");
        setXtraChildPrice(pckgDataRes?.data.data.extra_child_price);
        setXtraChld(pckgDataRes?.data.data.extra_child);
        setExtraPrsonPrice(pckgDataRes?.data.data.extra_person_price);
        setExtraPrson(pckgDataRes?.data.data.extra_person);
        setChild(pckgDataRes?.data.data.max_child);
        setAdult(pckgDataRes?.data.data.adults);
        setDiscount(pckgDataRes?.data.data.discounted_amount);
        setPrice(pckgDataRes?.data.data.amount);
        setFromDate(pckgDataRes?.data.data.date_from);
        setToDate(pckgDataRes?.data.data.date_to);
        setPkcgName(pckgDataRes?.data.data.package_name ?? "");
        setNights(pckgDataRes?.data.data.nights);
        setLoader(false);
      } else {
        toast(`${pckgDataRes?.data?.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error?.response?.status === 400) {
        Logout();
      }
    }
  };

  const getSrcFromImage = (image: any, index: number) => {
    try {
      return URL.createObjectURL(image);
    } catch (error) {
      let images = [...newImages];
      images.splice(index, 1);
      setNewImages(images);
    }
  };

  const onImageSelect = (event: any) => {
    let Images = [...newImages];
    let length = 3 - edtPackageImage.length;

    for (
      let file = 0;
      file < event.target.files.length && file < length;
      file++
    ) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        // file size is less than 1 MB
        Images.push(image);
      } else {
        toast("Image size should be less than 1 MB", {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    setNewImages(Images.slice(0, length));
  };

  // Date Picker Handler
  const onDateChangeFrom = (date: any) => {
    setFromDate(moment(date));
  };
  const onDateChangeTo = (date: any) => {
    setToDate(moment(date));
  };

  const onDateChange = (date: any) => {
    let datesArray = [...singleDate];
    let inv = [...blackOutArray];
    var index = datesArray.findIndex(
      (x) => x.date == moment(date).format("DD-MM-YYYY")
    );
    index === -1 &&
      datesArray.push({ date: moment(date).format("DD-MM-YYYY") });
    setFromDate(moment(date));
    setSingleDate(datesArray);
    inv.push({
      date: moment(date).format("DD-MM-YYYY"),
    });
    setBlackOutArray(inv);
  };

  const RemoveDate = (date: any) => {
    let removeDate = [...singleDate];
    let removeInv = [...blackOutArray];
    setSingleDate(removeDate.filter((Date: any) => Date.date !== date.date));
    setBlackOutArray(removeDate.filter((Date: any) => Date.date !== date.date));
  };

  // Image Upload Handler

  const uploadPackageImages = async () => {
    setLoader(true);
    if (newImages.length > 0) {
      let payload = new FormData();
      payload.append("hotel_id", current_property.hotel_id);
      // payload.append(
      //   "existing_images_ids",
      //   currentRoomTypeDetails.room_type_images_ids.join(",")
      // );
      newImages.forEach((image: any) => {
        payload.append("uploadFile[]", image);
      });
      try {
        let response = await kernelApi.post(
          `/upload/${current_property.hotel_id}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (response.data.status === 1) {
          const { message, image_ids, home_url } = response.data;
          toast(message, {
            hideProgressBar: true,
            type: "success",
          });

          await editPackgSaveHandler(image_ids);
          setNewImages([]);
          setLoader(false);
          onClose();
        } else {
          await editPackgSaveHandler("");
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          setLoader(false);
          onClose();
        }
      } catch (error: any) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
        onClose();
        if (error.response.status === 400) {
          Logout();
        }
      }
    } else {
      await editPackgSaveHandler("");
    }
  };

  // Package Data Post

  const editPackgSaveHandler = async (imageId: any) => {
    try {
      let postRes = await kernelApi.post(
        `/packages/update/${pckgId}`,
        {
          hotel_id: current_property.hotel_id ?? "",
          company_id: company_id ?? "",
          room_type_id: selectedRoomtype ?? "",
          package_name: pkcgName ?? "",
          package_image: imageId.toString() ?? "",
          amount: price ?? "",
          discounted_amount: discount ?? "",
          nights: nights ?? "",
          date_from: fromDate ?? "",
          date_to: toDate ?? "",
          package_description: description ?? "",
          adults: adult ?? "",
          max_child: child ?? "",
          extra_person: extraPrson ?? "",
          extra_person_price: extraPrsonPrice ?? "",
          extra_child: xtraChld ?? "",
          extra_child_price: xtraChildPrice ?? "",
          blackout_dates: blackOutArray ?? [],
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (postRes.data.status === 1) {
        toast(postRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getPackageData();
        setLoader(false);
        onClose();
      } else {
        toast(`${postRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
        onClose();
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      onClose();
    }
  };

  // const packageUploadHandler = useCallback(() => {
  //   const { current } = inputPackage;
  //   (current || { click: () => {} }).click();
  // }, [inputPackage]);
  const packageUploadHandler =
    (e) => {
      const clickFunc = () => {
        const { current } = inputPackage;
        (current || { click: () => { } }).click();
      }
      const classListsArr = ['imageSelect', 'property--image--radius', 'overlay-border-radius', 'button__container action__btn__container', 'delete-button', 'bi-trash']

      const arr = classListsArr.map(i => {
        if ([...e.target.classList].includes(i)) return true
      })
      arr.includes(true) ? <></> : clickFunc()
    }

  // Delete Image
  const deleteImage = (image_id: number) => {
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to delete this image?",
        "DELETE",
        async () => {
          try {
            let res = await kernelApi.post(
              `/packages/delete`,
              {
                imageId: image_id,
                package_id: pckgId,
              },
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );
            if (res.data.status === 1) {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "success",
              });
              getEditPackageData();
              setLoader(false);
            } else {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "error",
              });
              getEditPackageData();
              setLoader(false);
            }
          } catch (error: any) {
            toast(CATCH_MESSAGE, {
              hideProgressBar: true,
              type: "error",
            });
            if (error.response.status === 400) {
              Logout();
            }
            setLoader(false);
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  return (
    <div>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i
            onClick={() => {
              onClose();
            }}
            className="bi bi-x"
          ></i>
        </div>
        <div className="standard-page--label">Edit Package</div>
      </div>

      <div className="height-64px"></div>

      <div className="edit__package__tab__wrapper">
        <div className="edit__package__card">
          <ul className="nav nav-tabs mb-3" id="editPackage" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link tab--link--text active"
                id="package-detail-tabs"
                data-bs-toggle="tab"
                data-bs-target="#package-detail"
                type="button"
                role="tab"
                aria-controls="package-detail"
                aria-selected="true"
              >
                Package Detail
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link tab--link--text"
                id="package-rates-tab"
                data-bs-toggle="tab"
                data-bs-target="#package-rates"
                type="button"
                role="tab"
                aria-controls="package-rates"
                aria-selected="false"
              >
                Package Rates
              </button>
            </li>
          </ul>

          <div className="tab-content mt-4" id="editNearbyPlacesContent">
            <div
              className="tab-pane fade show active"
              id="package-detail"
              role="tabpanel"
              aria-labelledby="package-detail-tabs"
            >
              <div className="add__packages__tab">
                <div className="add__package__content__section p-0 m-0">
                  <div className="add__package__select__room__type mb-5">
                    <InputSelectField
                      label="Select Room Type"
                      options={allRoomOptn?.all_rooms}
                      value={selectedRoomtype}
                      values={allRoomOptn?.room_ids}
                      handleChange={(e) => {
                        setSelectedRoomtype(e.target.value);
                      }}
                    />
                  </div>

                  <div className="add__package__name__input mb-3">
                    <InputTextField
                      label="Package Name"
                      value={pkcgName}
                      handleChange={(e) => {
                        setPkcgName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="add__package__upload__image mb-3">
                    <div className="standard-page--label mb-3">
                      Upload Package Image
                    </div>
                    <div
                      className="add__package__upload__section"
                      onClick={(e) => packageUploadHandler(e)}
                    >
                      <input
                        id="upload-images"
                        ref={inputPackage}
                        type="file"
                        accept="image/png, image/jpeg"
                        className="d-none"
                        style={{ visibility: "hidden" }}
                        onChange={onImageSelect}
                        multiple
                      />
                      {
                        edtPackageImage.length > 0 ?
                          // <div className="layInRowClass">
                          edtPackageImage?.map((image: any, index: number) => {
                            return (
                              <div
                                className=" package__list__image"
                                key={image.image_id}
                              >
                                <img
                                  src={getImage(image.image_name)}
                                  alt=""
                                  className="property--image--radius"
                                // style={{ height: "100%" }}
                                />
                                <div className="overlay-border-radius overlay">
                                  <div
                                    className="button__container action__btn__container"
                                    onClick={() => {
                                      deleteImage(image.image_id);
                                    }}
                                  >
                                    <div className="edit-button delete-button">
                                      <i className="bi bi-trash"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          // </div>
                          :
                          <></>
                      }
                      {
                        newImages.length > 0 ?
                          newImages?.map((image: any, index: number) => {
                            return (
                              <div className="package__list__image padding imageSelect" key={nanoid()}>
                                <img
                                  src={getSrcFromImage(image, index)}
                                  alt=""
                                  className="property--image--radius"
                                />
                                <div className="overlay overlay-border-radius">
                                  <div
                                    className="button__container action__btn__container"
                                    onClick={() => {
                                      let images = [...newImages];
                                      images.splice(index, 1);
                                      setNewImages(images);
                                    }}
                                  >
                                    <div className="edit-button">
                                      <i className="bi bi-trash"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          :
                          <></>
                      }


                      {newImages.length === 0 && edtPackageImage.length === 0 && (
                        <div
                          className="drag__package__image__box"
                          onClick={(e) => packageUploadHandler(e)}
                        >
                          <div className="drag__icon">
                            <i className="bi bi-images"></i>
                          </div>
                          <div className="drag__text__label">
                            Drag your photos or click here
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Package Description Edtor */}
                  <div className="package__description__wrapper mt-5">
                    <div className=" standard-page--label mb-3">
                      Package Description
                    </div>

                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setDescription(data);
                        setCharCount({
                          ...charCount,
                          terms_cond: removeTags(data).length,
                        });
                      }}
                    />
                  </div>
                  {/* Inclusion Editor */}
                  <div className="package__description__wrapper mt-5">
                    <div className=" standard-page--label mb-3">Inclusion</div>

                    <CKEditor
                      editor={ClassicEditor}
                      data={inclusion}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setInclusion(data);
                        setCharCount({
                          ...charCount,
                          terms_cond: removeTags(data).length,
                        });
                      }}
                    />
                  </div>
                  {/* Exclusion Editor */}
                  <div className="package__description__wrapper mt-5">
                    <div className=" standard-page--label mb-3">Exclusion</div>

                    <CKEditor
                      editor={ClassicEditor}
                      data={exclusion}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setExclusion(data);
                        setCharCount({
                          ...charCount,
                          terms_cond: removeTags(data).length,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade show"
              id="package-rates"
              role="tabpanel"
              aria-labelledby="package-rates-tabs"
            >
              <div className="add__packages__tab">
                <div className="add__package__content__section p-0 m-0">
                  <div className="add__price__wrapper">
                    <div className="add__price me-3">
                      <InputTextField
                        label="Price"
                        type="number"
                        placeholder={"Enter Price in Number"}
                        value={price}
                        handleChange={(e) => {
                          setPrice(e.target.value);
                        }}
                      />
                    </div>

                    <div className="add__siscount">
                      <InputTextField
                        label="Discount Price"
                        type="number"
                        placeholder={"Enter Discount in Number"}
                        value={discount}
                        handleChange={(e) => {
                          setDiscount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* Form Date Picker */}
                  <div className="standard-page--label my-4">From Date</div>

                  <div className="edit-package-date mb-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        open={openFromDate}
                        onOpen={() => setFromDateOpen(true)}
                        onClose={() => setFromDateOpen(false)}
                        value={fromDate}
                        onChange={onDateChangeFrom}
                        minDate={new Date()}
                        inputFormat="dd MMM yyyy"
                        disableMaskedInput
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={(e) => setFromDateOpen(true)}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* To Date Picker */}
                  <div className="standard-page--label my-4">To Date</div>

                  <div className="edit-package-date mb-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        open={openToDate}
                        onOpen={() => setToDateOpen(true)}
                        onClose={() => setToDateOpen(false)}
                        value={toDate}
                        onChange={onDateChangeTo}
                        minDate={new Date()}
                        inputFormat="dd MMM yyyy"
                        disableMaskedInput
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={(e) => setToDateOpen(true)}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* Black Out Days Picker */}
                  <div className="standard-page--label my-4">Blackout Days</div>

                  <div className="edit-package-date mb-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        value={fromDate}
                        onChange={onDateChange}
                        minDate={new Date()}
                        inputFormat="dd MMM yyyy"
                        InputAdornmentProps={{ position: "start" }}
                        disableMaskedInput
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={(e) => setOpen(true)}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="inventory__update__wrapper">
                    {singleDate &&
                      singleDate.map((date: any) => {
                        return (
                          <div className="individual_dates">
                            {date.date}{" "}
                            <i
                              className="bi bi-x icon--close"
                              onClick={() => RemoveDate(date)}
                            ></i>
                          </div>
                        );
                      })}
                  </div>

                  <div className="add__package__input__wrapper mb-4">
                    <div className="input__wrapper me-3">
                      <InputTextField
                        label="Nights"
                        type="number"
                        placeholder={"Enter Number of Nights"}
                        value={nights}
                        handleChange={(e) => {
                          setNights(e.target.value);
                        }}
                      />
                    </div>
                    <div className="input__wrapper">
                      <InputTextField
                        label="Adult"
                        type="number"
                        placeholder={"Enter Number of Adult"}
                        value={adult}
                        handleChange={(e) => {
                          setAdult(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="add__package__input__wrapper mb-4">
                    <div className="input__wrapper me-3">
                      <InputTextField
                        label="Child"
                        type="number"
                        placeholder={"Enter Number of Child"}
                        value={child}
                        handleChange={(e) => {
                          setChild(e.target.value);
                        }}
                      />
                    </div>
                    <div className="input__wrapper">
                      <InputTextField
                        label="Extra Person"
                        type="number"
                        placeholder={"Enter Number of Extra Person"}
                        value={extraPrson}
                        handleChange={(e) => {
                          setExtraPrson(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="add__package__input__wrapper mb-4">
                    <div className="input__wrapper me-3">
                      <InputTextField
                        label="Price / Extra Person"
                        type="number"
                        placeholder={"Enter Price For Extra Person"}
                        value={extraPrsonPrice}
                        handleChange={(e) => {
                          setExtraPrsonPrice(e.target.value);
                        }}
                      />
                    </div>
                    <div className="input__wrapper">
                      <InputTextField
                        label="Extra Child (6 Years to 12 Years)"
                        type="number"
                        placeholder={"Enter Number of Child"}
                        value={xtraChld}
                        handleChange={(e) => {
                          setXtraChld(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="add__package__input__wrapper">
                    <div className="input__wrapper">
                      <InputTextField
                        label="Price / Extra Child"
                        type="number"
                        placeholder={"Enter Price for Extra Child"}
                        value={xtraChildPrice}
                        handleChange={(e) => {
                          setXtraChildPrice(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="add__package__next__btn">
        <Button
          className="primary-btn"
          handleClick={() => {
            uploadPackageImages();
          }}
          loader={loader}
          isAccessible={access}
        >
          Save
        </Button>
        <Button
          className="cancel__btn "
          handleClick={onClose}
          isAccessible={access}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditPackage;
