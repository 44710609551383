import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import Loader from "../../../views/loader/Loader";
import cmApi from "../../../API/cmApi";
import endPoints from "../../../API/endPoints";


export interface IViewBasicPromotionProps {
    onClose: () => void;
    promotionId: any;
    promotionName: any;
}

const ViewBasicPromotion: React.FC<IViewBasicPromotionProps> = ({
    onClose,
    promotionId,
    promotionName
}) => {

    // Data From Redux
    const { current_property } = useSelector(
        (state: RootState) => state.properties
    );

    const { viewBasicPromotion } = endPoints.MANAGEBASICPROMOTION;

    const [loader, setLoader] = useState<boolean>(false);
    const [viewPromotionData, setViewPromotionData] = useState<any[]>([])
    const [viewPromotionNotFoundMessage, setViewPromotionNotFoundMessage] = useState<any[]>([])

    useEffect(() => {
        fetchPromotionStatus();
    }, [current_property, promotionId]);

    const fetchPromotionStatus = async () => {
        setLoader(true);
        setViewPromotionData([]);
        try {

            let view_promotion_data = await cmApi.get(`${viewBasicPromotion}/${promotionId}`);
            if (view_promotion_data.data.status === 1) {
                setViewPromotionData(view_promotion_data.data.data);
                setLoader(false);
            }
            else {
                setViewPromotionNotFoundMessage(view_promotion_data.data.message)
                setLoader(false);
            }
        }
        catch (error: any) {
            setLoader(false);
            toast.error(CATCH_MESSAGE);
        }
    }

    return (

        <div>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">{promotionName}</div>
            </div>
            <div className="height-64px"></div>
            {loader ? (
                <div className="loader-setup">
                    <Loader />
                </div>
            ) : (
                <div className="slider-content-wrapper">
                    {viewPromotionData?.length > 0 ? <div className="">

                        {viewPromotionData?.map((item: any, idx: any) => {

                            return (
                                <div
                                    className="inventory__update__wrapper"
                                    style={{ margin: "0", padding: "0" }}
                                    key={idx}>
                                    <div className="add-channel__wrapper inventory__sync__channel__wrapper mb-4">
                                        <div
                                            className="channel__wrapper"
                                            style={{ border: "none" }}
                                        >
                                            <div className="channel__image__content">
                                                <img
                                                    src={item?.channel_logo}
                                                    alt={item?.ota_name}
                                                    style={{ width: "100px", height: "50px" }}
                                                />
                                            </div>
                                        </div>

                                        {item.status === 'Success' ? <div className="channel__select__checkbox">
                                            <div className=" addchnl-form-check">
                                                <div className="form-check">
                                                    <div className="view-basic-promotion-active-wrapper">
                                                        <div className="add-channel-status">
                                                            Active
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            :
                                            <div className="channel__select__checkbox">
                                                <div className=" addchnl-form-check">
                                                    <div className="form-check">
                                                        <div className="view-basic-promotion-inactive-wrapper">
                                                            <div className="add-channel-status">
                                                                Inactive
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                    </div>
                                </div>
                            );
                        })}
                    </div> :
                        <div className="empty__room__div mt-5">
                            <i className="bi bi-x-circle mb-3"></i>
                            <div>{viewPromotionNotFoundMessage}</div>
                        </div>
                    }
                </div>
            )}

            <div className="side__slider__panel__btn">
                <Button className="cancel__btn " handleClick={onClose}>
                    Close
                </Button>
            </div>
        </div>



    );
};

export default ViewBasicPromotion;
