import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";

import Button from "../../views/buttons/Button";
import { UpdateInventoryNos } from "../../redux/actions/UpdateInventoryState";
import { nanoid } from "@reduxjs/toolkit";

interface IConfirmInventorySliderProps {
  onClose: () => void;
  selectRoomType: string;
  updatedinventory: any;
  inventory: any;
  index: string[];
  allChannel: any[];
  room_type: string;
  singleUpdate: () => void;
  cmResponse: any[];
  beResponse: any;
  beLoader: any;
  cmLoader: any;
  old_new_inventory: any[];
}

const ConfirmUpdateInventory: React.FC<IConfirmInventorySliderProps> = ({
  onClose,
  selectRoomType,
  updatedinventory,
  inventory,
  index,
  allChannel,
  room_type,
  singleUpdate,
  cmResponse,
  beResponse,
  beLoader,
  cmLoader,
  old_new_inventory,
}) => {
  const [firstIndex, setFirstIndex] = useState<any>([]);
  const [secondIndex, setSecondIndex] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<any>([]);

  let arr = [...cmResponse, beResponse];
  const otaIndex = () => {
    let firstindex = [...firstIndex];
    let secondindex = [...secondIndex];
    index.map((data, i) => {
      let num = data.charAt(0);
      let sec_num = data.charAt(1);
      firstindex.push(parseInt(num));
      secondindex.push(parseInt(sec_num));
      // firstindex.push(data.charAt(0));
      setFirstIndex(firstindex);
      setSecondIndex(secondindex);
    });
  };

  //let all_responses = [...cmResponse, beResponse];
  // if (cmResponse.length >= 0) {
  //   all_responses = [...cmResponse, beResponse];
  //   //all_responses.push(beResponse);
  // }
  // } else if (beResponse && Object.keys(beResponse).length > 0) {
  //   all_responses = [...beResponse];
  // }
  const dispatch = useDispatch()

  useEffect(() => {
    otaIndex();
  }, []);

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">
          Update Confirmation ({selectRoomType})
        </div>
      </div>

      <div className="height-64px"></div>
      {old_new_inventory &&
        old_new_inventory.length > 0 &&
        old_new_inventory.map((card) => {
          return (
            <div className="inventory__update__wrapper">
              <div>
                <div className="card__wrapper">
                  {arr &&
                    arr.length > 0 &&
                    arr.some(
                      (ota: any) =>
                        ota?.ota_name?.toLowerCase() ===
                        card.ota_name?.toLowerCase() && ota.status === 0
                    ) &&
                    arr
                      .filter(
                        (task: any) =>
                          task?.ota_name?.toLowerCase() ===
                          card.ota_name?.toLowerCase()
                      )
                      .map((filtered: any) => (
                        <div className="error_message">
                          {filtered.response_msg}
                        </div>
                      ))}

                  <div
                    className={`card__wrapper__container ${arr &&
                      arr.length > 0 &&
                      arr?.some(
                        (ota: any) =>
                          ota?.ota_name?.toLowerCase() ===
                          card.ota_name?.toLowerCase() && ota.status === 0
                      )
                      ? "error_border"
                      : arr?.some(
                        (ota: any) =>
                          ota?.ota_name?.toLowerCase() ===
                          card.ota_name?.toLowerCase() && ota.status === 1
                      ) && "success_border"
                      }`}
                  >
                    <div className="date__logo__container">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="image__container">
                          <img src={card.logo} />
                        </div>
                        {arr &&
                          arr.length > 0 &&
                          arr.some(
                            (ota: any) =>
                              ota?.ota_name?.toLowerCase() ===
                              card.ota_name?.toLowerCase() && ota.status === 1
                          ) && (
                            <div className="success_checkmark">
                              <i className="bi bi-check"></i>
                            </div>
                          )}
                      </div>
                      <div className="updated__date ms-3">
                        {moment(card.date).format("DD MMM YYYY")}
                      </div>
                    </div>
                    <div className="display__editprice">
                      <div className="inventory old__inventory">
                        {/* {card.old_inv > 9 ? card.old_inv : "0" + card.old_inv} */}
                        {card.old_inv.toString().length < 2
                          ? "0" + card.old_inv
                          : card.old_inv}
                      </div>
                      <div className="inventory  ms-auto">
                        {" "}
                        {card.new_inv.toString().length < 2
                          ? "0" + card.new_inv
                          : card.new_inv}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}



      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      {old_new_inventory.length > 0 && (
        <div className="side__slider__panel__btn">
          <div className="__btn__wrapper mt-3">
            <Button className="cancel__btn me-3" handleClick={onClose}>
              Cancel
            </Button>
            {!beLoader && !cmLoader ? (
              <Button handleClick={() => {
                // dispatch(UpdateInventoryNos(nanoid()))
                singleUpdate()
              }}>Apply</Button>
            ) : (
              <Button>
                Apply
                <span
                  className="spinner-border spinner-border-sm pd-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ConfirmUpdateInventory;
