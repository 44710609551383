import { REMOVE_BOOKING_DETAILS, SAVE_BILLING_DETAILS, SAVE_BOOKING_CRS, SAVE_BOOKING_DETAILS, SAVE_BOOKING_INFO, SAVE_BOOKING_ROOM_DETAILS, SAVE_STAY_DETAILS } from "../actionTypes";

const initialState = {
    current_booking: null,
    booking_info: null,
    billing_details: null,
    stay_details: null,
    room_details: null,
    booking_details: null,
}

const BookingsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_BOOKING_DETAILS:
            return {
                ...state,
                current_booking: action.payload,
            }
        case SAVE_BOOKING_CRS:
            return {
                ...state,
                booking_details: action.payload,
            }
        case SAVE_STAY_DETAILS:
            return {
                ...state,
                stay_details: action.payload,
            }
        case SAVE_BOOKING_INFO:
            return {
                ...state,
                booking_info: action.payload,
            }
        case SAVE_BOOKING_ROOM_DETAILS:
            return {
                ...state,
                room_details: action.payload,
            }
        case SAVE_BILLING_DETAILS:
            return {
                ...state,
                billing_details: action.payload,
            }
        case REMOVE_BOOKING_DETAILS:
            return initialState
        default: return state
    }
}

export default BookingsReducer;