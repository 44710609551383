import { ADD_PROPERTY_ADDRESS, ADD_PROPERTY_AMENITIES, ADD_PROPERTY_DETAILS, ADD_PROPERTY_IMAGES, ADD_PROPERTY_LOCATION, ADD_PROPERTY_RESET, ADD_PROPERTY_SUBTYPE, ADD_PROPERTY_TYPE } from "../actionTypes"
import { IAddressData } from "../../components/property/PropertyAddressForm"

export interface IPropertyBasicData {
    name: string;
    mobile: string;
    email: string;
    landline: string;
}

export const NewPropertyType = (value: string) => {
    return {
        type: ADD_PROPERTY_TYPE,
        payload: value
    }
}

export const NewPropertySubType = (value: string) => {
    return {
        type: ADD_PROPERTY_SUBTYPE,
        payload: value
    }
}

export const NewPropertyDetails = (value: IPropertyBasicData) => {
    return {
        type: ADD_PROPERTY_DETAILS,
        payload: value
    }
}

export const NewPropertyAddress = (address: IAddressData["data"]) => {
    return {
        type: ADD_PROPERTY_ADDRESS,
        payload: address
    }
}

export const NewPropertyLocation = (location: any) => {
    return {
        type: ADD_PROPERTY_LOCATION,
        payload: location
    }
}

export const NewPropertyAmenities = (amenities: string[]) => {
    return {
        type: ADD_PROPERTY_AMENITIES,
        payload: amenities
    }
}

export const NewPropertyImages = (images: any[]) => {
    return {
        type: ADD_PROPERTY_IMAGES,
        payload: images
    }
}

export const ResetNewProperty = () => {
    return {
        type: ADD_PROPERTY_RESET
    }
}