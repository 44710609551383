import React, { useEffect, useState } from "react";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import Button from "../../views/buttons/Button";
import SaveButton from "../../views/buttons/Button";
import DeleteButton from "../../views/buttons/Button";
import ImageUploadButton from "../../views/buttons/Button";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import {
  CATCH_MESSAGE,
  getImage,
  UpdateSidebar,
  validatePositiveInt,
} from "../../UtilityFunctions";

import kernelApi from "../../API/kernelApi";

// import sliding pane
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import moment from "moment";
import { toast } from "react-toastify";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";

import AddNewRoomTypeSlider from "./AddNewRoomTypeSlider";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import endPoints from "../../API/endPoints";

const PropertyRoomTypes = () => {
  const dispatch = useDispatch();
  UpdateSidebar("Property Setup", "Room Types");
  const navigate = useNavigate();

  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { img_placeholder } = useSelector(
    (state: RootState) => state.add_room_type
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const { masterRatePlanAll } = endPoints.PROPERTY_RATE_PLAN;

  const {
    hotelMasterRoomType,
    getRatePlansByRoomtypeID,
    getAllRoomTypeAmenities,
    getRoomTypeRatePlans,
    updateBasicDetail,
    updateRoomTypeAmenities,
    saveUploadedRoomTypeImage,
    updateOccupancy,
    deleteRoomTypeApi,
    deleteRoomTypeImageApi,
    updateRoomRatePlan,
  } = endPoints.PROPERTY_ROOM_TYPE;

  const { auth_token } = useSelector((state: RootState) => state.auth);
  const [shrtCodeHelperText, setshrtCodeHelperText] = useState("");
  const [shrtRoomHelperText, setshrtRoomHelperText] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [access, setAccess] = useState(true);
  const [roomtypeDescription, setRoomtypeDescription] = useState("");

  const [allAmenities, setAllAmenities] = useState<any>({});
  const [amenityAllCategories, setAmenityAllCategories] = useState<any>({});
  const [selectedAmenities, setSelectedAmenities] = useState<any[]>([]);

  const [allRoomTypeDetails, setAllRoomTypeDetails] = useState<any[]>([]);
  const [currentRoomTypeDetails, setCurrentRoomTypeDetails] =
    useState<any>(null);

  const [ratePlanDetails, setRatePlanDetails] = useState<any[]>([]);
  const [currentPlanInfo, setCurrentPlanInfo] = useState<any>(null);
  const [currentRatePlanId, setCurrentRatePlanId] = useState("");
  const [activeRateplanTab, setActiveRateplanTab] = useState("ep");

  const [newImages, setNewImages] = useState<any[]>([]);

  const [maxOccupancy, setMaxOccupancy] = useState(0);
  const [maxAdult, setMaxAdult] = useState(0);
  const [maxChild, setMaxChild] = useState(0);
  const [baseAdult, setBaseAdult] = useState(0);
  const [baseChild, setBaseChild] = useState(0);
  const [allRatePlans, setAllRatePlans] = useState<any[]>([]);

  const [multiOccupancyInput, setMultiOccupancyInput] = useState("");
  const [multiOccupancyRates, setMultiOccupancyRates] = useState<string[]>([]);
  const [multiOccupancyLimit, setMultiOccupancyLimit] = useState(3);

  const [openSlideNewRoomType, setOpenSlideNewRoomType] = useState(false);
  const [openSlideEditRoomType, setOpenSlideEditRoomType] = useState(false);
  const [RatePlanTabs, setRatePlanTabs] = useState<any[]>([]);
  const [otherRatePlans, setotherRatePlans] = useState<any[]>([]);
  const [mealplanId, setMealPlanId] = useState<any[]>([]);
  const [enableOthers, setEnableOthers] = useState(false);
  // const RatePlanTabs = ["ep", "cp", "map", "ap"];

  const EditRoomTypeTabs = [
    { id: "room-details-tabs", target: "room-details", label: "Room Details" },
    { id: "amenities-tab", target: "amenities", label: "Amenities" },
    { id: "images-tab", target: "images", label: "Images" },
    { id: "occupancy-tab", target: "occupancy", label: "Occupancy" },
    { id: "rates-tab", target: "rates", label: "Rates" },
  ];

  useEffect(() => {
    getAllAmenities();
    getMasterPlans();
  }, []);

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "ROOM";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    getAllRoomTypeDetails();
  }, [current_property]);

  // Get All Rate Plans
  const getMasterPlans = async () => {
    try {
      let getMasterPlansres = await kernelApi.get(
        `${masterRatePlanAll}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (getMasterPlansres?.data?.status === 1) {
        const allPlans = getMasterPlansres?.data?.data.filter((iteam: any) => {
          return iteam.plan_type !== "OTHERS";
        });
        setAllRatePlans(allPlans);

        const othrPln = getMasterPlansres?.data?.data.filter((iteam: any) => {
          return iteam.plan_type === "OTHERS";
        });
        setotherRatePlans(othrPln);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Meal Plan Selection Handler

  const mealPlanSelectionHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    type?: string
  ) => {
    const planIds: any[] = [...mealplanId];
    const { id, name, value } = e.target ?? {};

    planIds.includes(id)
      ? planIds.splice(planIds.indexOf(id), 1)
      : planIds.push(id);

    setMealPlanId(planIds);
  };

  // ShortCode Change Handler
  const handleShortCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 4) {
      setshrtCodeHelperText("Short Code Length Could Not Be Greater Than 4");
    } else {
      setshrtCodeHelperText(" ");
      setCurrentRoomTypeDetails({
        ...currentRoomTypeDetails,
        room_type_shortcode: value,
      });
    }
  };

  // ShortRoom Change Handler
  const handleShortRoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.includes(",") || value.includes("(") || value.includes(")")) {
      setshrtRoomHelperText(`Room Name Should Not Contain "," or "( )"`);
    } else {
      if (value && value.trim().length < 4) {
        setshrtRoomHelperText(
          "Room Type Name Could Not Be less Than 4 character"
        );
      } else {
        setshrtRoomHelperText("");
      }
    }
    setCurrentRoomTypeDetails({
      ...currentRoomTypeDetails,
      room_type: value,
    });
  };

  // API call to get all the details for the selected room type
  const getAllRoomTypeDetails = async () => {
    try {
      let response = await kernelApi.get(
        `${hotelMasterRoomType}` + current_property.hotel_id
      );
      if (response.data.status === 1) {
        setAllRoomTypeDetails(response.data.data);
        setRoomtypeDescription(response.data.data?.description);
      } else {
        setAllRoomTypeDetails([]);
      }
    } catch (error) {
      console.log(error);
      setAllRoomTypeDetails([]);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  // API call to get all the rate plan details for the selected room type
  const getRoomtypeRatePlans = async (roomtype_id: string) => {
    try {
      let response = await kernelApi.post(`${getRatePlansByRoomtypeID}`, {
        hotel_id: current_property.hotel_id,
        room_type_id: roomtype_id,
        user_id: admin_id,
      });

      if (response.data.status === 1) {
        setRatePlanDetails(response.data.rate_plans);
        const planIds = response.data.rate_plans.map((iteam: any) => {
          return iteam.rate_plan_id;
        });
        setMealPlanId(planIds.map(String));
        if (response.data.rate_plans.length > 0) {
          setCurrentRatePlanId(response.data.rate_plans[0].rate_plan_id);
          getRateplanInfo(
            response.data.rate_plans[0].rate_plan_id,
            roomtype_id
          );
        } else {
          setCurrentPlanInfo(null);
          setStartDate(null);
          setEndDate(null);
          setMultiOccupancyInput("");
          setMultiOccupancyRates([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // API call to get the total list of room type amenities from the database
  const getAllAmenities = async () => {
    let response = await kernelApi.get(`${getAllRoomTypeAmenities}`);
    if (response.data.status === 1) {
      setAllAmenities(response.data.all_amenities);

      let amenity_categories: any = {};
      response.data.all_amenities.forEach((amenity: any) => {
        if (amenity.category_name in amenity_categories) {
          amenity_categories[amenity.category_name].push(amenity.id);
        } else {
          amenity_categories[amenity.category_name] = [amenity.id];
        }
      });
      setAmenityAllCategories(amenity_categories);
    } else {
      toast.error(response.data.message);
    }
  };

  const onSelectAmenities = (amenity: any) => {
    if (!selectedAmenities.includes(amenity.id)) {
      setSelectedAmenities([...selectedAmenities, amenity.id]);
    } else {
      let amenities = [...selectedAmenities];
      let index = amenities.indexOf(amenity.id);
      if (index > -1) {
        amenities.splice(index, 1);
      }
      setSelectedAmenities(amenities);
    }
  };

  const editRoomTypeDetails = (room_type: any) => {
    setNewImages([]);
    setActiveRateplanTab("ep");
    setOpenSlideEditRoomType(true);
    setCurrentRoomTypeDetails(room_type);

    getRoomtypeRatePlans(room_type.room_type_id);

    // set room type amenities
    let amenities: number[] = [];
    if (room_type.room_amenities) {
      room_type.room_amenities.split(",").map((amenity: any) => {
        amenities.push(parseInt(amenity));
      });
    }
    setSelectedAmenities(amenities);

    // set room type occupancy details
    setMaxOccupancy(room_type.max_occupancy);
    setBaseAdult(room_type.max_people);
    setBaseChild(room_type.max_child);
    setMaxAdult(room_type.max_people + room_type.extra_person);
    setMaxChild(room_type.max_child + room_type.extra_child);
  };

  const switchRatePlanTab = (tab: string) => {
    setActiveRateplanTab(tab);
    let currentPlan = ratePlanDetails.filter(
      (rate_plan: any) => rate_plan.plan_type === tab.toUpperCase()
    );
    if (currentPlan.length > 0) {
      setCurrentRatePlanId(currentPlan[0].rate_plan_id);
      getRateplanInfo(
        currentPlan[0].rate_plan_id,
        currentRoomTypeDetails.room_type_id
      );
    } else {
      setCurrentPlanInfo(null);
      setStartDate(null);
      setEndDate(null);
      setMultiOccupancyInput("");
      setMultiOccupancyRates([]);
    }
  };

  const getRateplanInfo = async (rateplan_id: string, roomtype_id: string) => {
    try {
      let response = await kernelApi.post(`${getRoomTypeRatePlans}`, {
        user_id: admin_id,
        rate_plan_id: rateplan_id,
        hotel_id: current_property.hotel_id,
        room_type_id: roomtype_id,
      });
      if (response.data.status === 1) {
        let rate_plan_info = response.data.room_rate_plan_details;
        setCurrentPlanInfo(rate_plan_info);
        setEndDate(moment(rate_plan_info.to_date));
        setStartDate(moment(rate_plan_info.from_date));
        // setMultiOccupancyRates(rate_plan_info.multiple_occupancy ? rate_plan_info.multiple_occupancy.toString().split(",") : []);
        setMultiOccupancyRates(rate_plan_info?.multiple_occupancy);
        setMultiOccupancyLimit(rate_plan_info?.multiple_occupancy_count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImages = () => {
    document.getElementById("upload-images")?.click();
  };

  const getSrcFromImage = (image: any, index: number) => {
    try {
      return URL.createObjectURL(image);
    } catch (error) {
      let images = [...newImages];
      images.splice(index, 1);
      setNewImages(images);
    }
  };

  const onImageSelect = (event: any) => {
    let Images = [...newImages];
    let length = 10 - currentRoomTypeDetails.image.length;

    for (
      let file = 0;
      file < event.target.files.length && file < length;
      file++
    ) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        // file size is less than 1 MB
        Images.push(image);
      } else {
        toast("Image size should be less than 1 MB", {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    setNewImages(Images.slice(0, length));
  };

  const decrement = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (state > 0) {
      setState(state - 1);
    }
  };

  const increment = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setState(state + 1);
  };

  const onEditSliderClose = () => {
    setOpenSlideEditRoomType(false);
    getAllRoomTypeDetails();
  };

  // on Enter key press function for the multiple occupancy input
  const onMultiOccupancyEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      if (
        multiOccupancyInput &&
        multiOccupancyRates.length < multiOccupancyLimit
      ) {
        setMultiOccupancyRates([...multiOccupancyRates, multiOccupancyInput]);
        setMultiOccupancyInput("");
      } else if (multiOccupancyInput) {
        setMultiOccupancyInput("");
        toast(
          "You can only add " +
          multiOccupancyLimit +
          " multiple occupancy rates",
          {
            hideProgressBar: true,
            type: "error",
          }
        );
      }
    }
  };

  // API call to update room type basic details
  const saveRoomBasicDetails = async () => {
    if (
      currentRoomTypeDetails.room_type &&
      !shrtRoomHelperText &&
      currentRoomTypeDetails.total_rooms &&
      currentRoomTypeDetails.room_type_shortcode &&
      currentRoomTypeDetails.description.length
    ) {
      try {
        let response = await kernelApi.post(`${updateBasicDetail}`, {
          hotel_id: current_property.hotel_id,
          room_type_name: currentRoomTypeDetails.room_type,
          no_of_rooms: currentRoomTypeDetails.total_rooms,
          room_type_id: currentRoomTypeDetails.room_type_id,
          room_type_shortcode: currentRoomTypeDetails.room_type_shortcode,
          description: currentRoomTypeDetails.description,
        });

        if (response.data.status === 1) {
          getAllRoomTypeDetails();
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
        setOpenSlideEditRoomType(false);
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } else if (shrtRoomHelperText) {
      return;
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // API call to update room type amenities
  const saveRoomTypeAmenities = async () => {
    try {
      let response = await kernelApi.post(`${updateRoomTypeAmenities}`, {
        hotel_id: current_property.hotel_id,
        room_type_id: currentRoomTypeDetails.room_type_id,
        amenities: selectedAmenities.join(","),
      });

      if (response.data.status === 1) {
        getAllRoomTypeDetails();
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
      setOpenSlideEditRoomType(false);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // API call to save the uploaded room type images
  const saveRoomTypeImages = async () => {
    if (newImages.length > 0) {
      let payload = new FormData();
      payload.append("hotel_id", current_property.hotel_id);
      payload.append(
        "existing_images_ids",
        currentRoomTypeDetails.room_type_images_ids.join(",")
      );
      newImages.forEach((image: any) => {
        payload.append("images[]", image);
      });
      try {
        let response = await kernelApi.post(
          `${saveUploadedRoomTypeImage}` + currentRoomTypeDetails.room_type_id,
          payload
        );
        if (response.data.status === 1) {
          getAllRoomTypeDetails();
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
        setOpenSlideEditRoomType(false);
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  // API call to save room type occupancy details
  const saveRoomTypeOccupancy = async () => {
    if (!maxOccupancy) {
      toast("Maximum occupancy cannot be zero", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (!baseAdult) {
      toast("Base adult cannot be zero", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (!maxAdult) {
      toast("Max adult cannot be zero", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseAdult > maxOccupancy) {
      toast("Base adult cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (maxAdult > maxOccupancy) {
      toast("Max adult cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseChild > maxOccupancy) {
      toast("Base child cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (maxChild > maxOccupancy) {
      toast("Max child cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseAdult > maxAdult) {
      toast("Base adult cannot be greater than max adult", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (baseChild > maxChild) {
      toast("Base child cannot be greater than max child", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (maxOccupancy > maxAdult + maxChild) {
      toast(
        "Maximum occupancy cannot be greater than the sum of max adult and max child",
        {
          hideProgressBar: true,
          type: "error",
        }
      );
    } else {
      try {
        let response = await kernelApi.post(`${updateOccupancy}`, {
          hotel_id: current_property.hotel_id,
          room_type_id: currentRoomTypeDetails.room_type_id,
          base_adult: baseAdult,
          base_child: baseChild,
          max_adult: maxAdult,
          max_child: maxChild,
          max_occupancy: maxOccupancy,
        });

        if (response.data.status === 1) {
          getAllRoomTypeDetails();
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
        setOpenSlideEditRoomType(false);
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  // delete room type with confirmation prompt
  const deleteRoomType = () => {
    // ShowConfirmationPrompt(message, action , onAccept())
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to delete this room type?",
        "DELETE",
        async () => {
          try {
            let response = await kernelApi.delete(
              `${deleteRoomTypeApi}` + currentRoomTypeDetails.room_type_id
            );
            if (response.data.status === 1) {
              getAllRoomTypeDetails();
              toast(response.data.message, {
                hideProgressBar: true,
                type: "success",
              });
            } else {
              toast(response.data.message, {
                hideProgressBar: true,
                type: "error",
              });
            }
            setOpenSlideEditRoomType(false);
          } catch (error) {
            toast(CATCH_MESSAGE, {
              hideProgressBar: true,
              type: "error",
            });
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  // API call to delete room type image
  const deleteRoomTypeImage = async (image_id: number) => {
    try {
      let response = await kernelApi.post(
        `${deleteRoomTypeImageApi}${currentRoomTypeDetails.room_type_id}`,
        {
          hotel_id: current_property.hotel_id,
          room_type_image_id: image_id.toString(),
        }
      );
      if (response.data.status === 1) {
        getAllRoomTypeDetails();
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
      setOpenSlideEditRoomType(false);
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // API call to save room type rate plan details
  const saveRoomTypeRatePlan = async () => {
    // validations before calling the API
    if (!currentPlanInfo?.min_price) {
      toast("Please enter minimum price", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (!currentPlanInfo?.max_price) {
      toast("Please enter maximum price", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (mealplanId.length === 0) {
      toast("Please select meal plan", {
        hideProgressBar: true,
        type: "error",
      });
    } else {
      // API call to save room type rate plan details
      try {
        let response = await kernelApi.post(`${updateRoomRatePlan}`, {
          hotel_id: current_property.hotel_id,
          room_type_id: currentRoomTypeDetails.room_type_id,
          max_price: parseInt(currentPlanInfo.max_price),
          min_price: parseInt(currentPlanInfo.min_price),
          rate_plan_info: mealplanId.toString(),
          new_room_status: "1",
        });
        if (response.data.status === 1) {
          getAllRoomTypeDetails();
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
      setOpenSlideEditRoomType(false);
    }
  };


  return (
    <>
      <div className="property__setup__room__types">
        <div className="property__breadcrumbs">
          <Breadcrumbs />
          <div className="manage__property__action__right">
            <Button
              icon="bi bi-plus"
              handleClick={() => setOpenSlideNewRoomType(true)}
              isAccessible={access}
            >
              Add
            </Button>
            <Button
              className="back__btn"
              icon="bi bi-arrow-left"
              handleClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="hr--line"></div>
        {allRoomTypeDetails.length > 0 ? (
          <div className="manage__room__type__wrapper mt-4">
            {allRoomTypeDetails.map((roomType: any) => {
              return (
                <div
                  className="manage__room__type__card mb-4"
                  key={roomType.room_type_id}
                >
                  <div className="manage__room__type ms-0">
                    <div className="image--container">
                      {roomType.info_status === 0 && (
                        <div className="info__alert">
                          <i className="bi bi-exclamation-circle"></i>
                        </div>
                      )}
                      <img
                        src={
                          roomType.image.length > 0
                            ? getImage(roomType.image[0])
                            : img_placeholder
                        }
                        alt=""
                      />
                      <div className="overlay">
                        <div className="button__container">
                          <div
                            className="edit-button"
                            onClick={() => editRoomTypeDetails(roomType)}
                          >
                            <i className="bi bi-pencil"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="room__type__name__details">
                      <div className="room--type-label">
                        {roomType.room_type}
                      </div>
                      <div className="d-flex justify-content-between mt-1">
                        <span className="reset-font-size">{`${roomType.total_rooms
                          } ${roomType.total_rooms === 1 ? "Room" : "Rooms"
                          }`}</span>
                        {/* <span className="reset-font-size">
                          {roomType.max_room_price
                            ? roomType.max_room_price + "/Night"
                            : ""}
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>You don’t have any room types yet</div>
          </div>
        )}
      </div>

      {/* Separate component for adding new room type */}
      <AddNewRoomTypeSlider
        isOpen={openSlideNewRoomType}
        amenities={allAmenities}
        refresh={getAllRoomTypeDetails}
        amenityCategories={amenityAllCategories}
        onClose={() => setOpenSlideNewRoomType(false)}
      />

      {/* Sliding Panel for Editing Room Type Details */}
      <SlidingPane
        hideHeader
        width="50%"
        from="right"
        isOpen={openSlideEditRoomType}
        className="sliding-pane-custom"
        onRequestClose={onEditSliderClose}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i onClick={onEditSliderClose} className="bi bi-x"></i>
          </div>
          <div className="standard-page--label">Edit Room Type</div>
        </div>

        <div className="height-64px"></div>

        <div className="edit__room__type__nav__tabs custom-margin-padding">
          <div className="edit__room__type__nav__wrapper">
            <ul className="nav nav-tabs mb-3" id="editRoomType" role="tablist">
              {EditRoomTypeTabs.map(
                (tab: typeof EditRoomTypeTabs[0], index: number) => {
                  return (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        id={tab.id}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab.target}`}
                        type="button"
                        role="tab"
                        aria-controls={tab.target}
                        aria-selected="true"
                        className={`nav-link tab--link--text ${index === 0 ? "active" : ""
                          }`}
                      >
                        {tab.label}
                      </button>
                    </li>
                  );
                }
              )}
            </ul>
            <div className="tab-content" id="editRoomTypeContent">
              {/* Tab section for room details */}
              <div
                className="tab-pane fade show active"
                id="room-details"
                role="tabpanel"
                aria-labelledby="room-details-tabs"
              >
                <div className="tab-content__room__details mt-4">
                  <div className="__default--input--field">
                    <InputTextField
                      label="Room Type Name"
                      value={currentRoomTypeDetails?.room_type}
                      handleChange={(e) => handleShortRoomChange(e)}
                      helperText={shrtRoomHelperText}
                    />
                  </div>
                </div>

                <div className="tab-content__room__details">
                  <div className="no_of_rooms_inputfield ">
                    <InputTextField
                      label="Number of Rooms"
                      value={currentRoomTypeDetails?.total_rooms}
                      handleChange={(e) => {
                        if (
                          validatePositiveInt(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setCurrentRoomTypeDetails({
                            ...currentRoomTypeDetails,
                            total_rooms: e.target.value,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="__default--input--field ">
                    <InputTextField
                      label="Room Type Shortcode"
                      value={currentRoomTypeDetails?.room_type_shortcode}
                      handleChange={(e) => handleShortCodeChange(e)}
                      helperText={shrtCodeHelperText}
                    />
                  </div>
                </div>

                {/* Roomtype description section  */}
                <div className="manage__roomtype__description__wrapper pb-0">
                  <div className="description__label">
                    Room Type Description
                  </div>
                  <div id="editor">
                    <CKEditor
                      editor={ClassicEditor}
                      // data={roomtypeDescription}
                      data={currentRoomTypeDetails?.description}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setCurrentRoomTypeDetails({
                          ...currentRoomTypeDetails,
                          description: data,
                        });
                        // setCharCount({
                        // ...charCount,
                        // terms_cond: removeTags(data).length,
                        // });
                      }}
                    />
                  </div>
                  {/* <div className="character__count__number">
                    <div className="character__count__label">
                      Used
                      <span>{charCount.terms_cond}</span>
                    </div>
                  </div> */}
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                  }}
                ></div>
                <div className="side__slider__panel__btn">
                  <SaveButton
                    className={
                      !shrtRoomHelperText ? "bi bi-arrow-right" : "inactiveBtn"
                    }
                    handleClick={saveRoomBasicDetails}
                    isAccessible={access}
                  >
                    Save
                  </SaveButton>
                  <DeleteButton
                    className="delete__btn__wrapper"
                    handleClick={deleteRoomType}
                    isAccessible={access}
                  >
                    <i className="bi bi-trash me-1"></i> Delete
                  </DeleteButton>
                </div>
              </div>

              {/* Tab section for amenities */}
              <div
                id="amenities"
                role="tabpanel"
                className="tab-pane fade"
                aria-labelledby="amenities-tab"
              >
                <div className="property__setup__amenities__details px-0 mx-0">
                  <div className="amenities__list__wrapper">
                    <div className="accordion" id="accordionRoomAmenities">
                      {Object.keys(amenityAllCategories).map(
                        (category: string, index) => {
                          return (
                            <div className="accordion-item" key={index}>
                              <div
                                className="accordion-header"
                                id={`accordionHeading${index}`}
                              >
                                <button
                                  type="button"
                                  aria-expanded="true"
                                  data-bs-toggle="collapse"
                                  aria-controls={`#collapse${index}`}
                                  data-bs-target={`#collapse${index}`}
                                  className="accordion-button collapsed accordion__custom__heading"
                                >
                                  {category}
                                  <span className="amenities__list__item__number">
                                    {`${selectedAmenities.filter((amenity) =>
                                      amenityAllCategories[category].includes(
                                        amenity
                                      )
                                    ).length
                                      }
                                  ${selectedAmenities.filter((amenity) =>
                                        amenityAllCategories[category].includes(
                                          amenity
                                        )
                                      ).length === 1
                                        ? "Amenity"
                                        : "Amenities"
                                      } Selected`}
                                  </span>
                                </button>
                              </div>
                              <div
                                id={`collapse${index}`}
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionRoomAmenities"
                                aria-labelledby={`accordionHeading${index}`}
                              >
                                <div className="accordion-body">
                                  {allAmenities
                                    .filter(
                                      (amenity: any) =>
                                        amenity.category_name === category
                                    )
                                    .map((amenity: any, id: number) => {
                                      return (
                                        <div
                                          className="amenities__categories__wrapper my-2"
                                          key={id}
                                        >
                                          <div className="amenities__categories__list">
                                            <div
                                              className="amenities__name"
                                              onClick={() =>
                                                onSelectAmenities(amenity)
                                              }
                                            >
                                              {amenity.label}
                                            </div>
                                            <div className="amenities__checkbox">
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox${id}`}
                                                  className="form-check-input"
                                                  onChange={() =>
                                                    onSelectAmenities(amenity)
                                                  }
                                                  checked={selectedAmenities.includes(
                                                    amenity.id
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                  }}
                ></div>
                <div className="side__slider__panel__btn">
                  <SaveButton
                    className="bi bi-arrow-right"
                    handleClick={saveRoomTypeAmenities}
                    isAccessible={access}
                  >
                    Save
                  </SaveButton>
                </div>
              </div>

              {/* Tab section for room type images */}
              <div
                className="tab-pane fade"
                id="images"
                role="tabpanel"
                aria-labelledby="images-tab"
              >
                <div className="edit__room__type__image__upload">
                  <div className="image__upload__btn__wrapper">
                    <ImageUploadButton
                      className="image__upload__btn"
                      handleClick={uploadImages}
                    >
                      <i className="bi bi-box-arrow-up me-1"></i> Upload
                    </ImageUploadButton>
                  </div>

                  <div className="uploaded__image__container">
                    <div className="image__upload_box">
                      {newImages.map((image: any, index: number) => {
                        return (
                          <div className="uploaded__image__wrapper" key={index}>
                            <div className="uploaded__image">
                              <img src={getSrcFromImage(image, index)} alt="" />
                              <div className="overlay">
                                <div
                                  className="button__container"
                                  onClick={() => {
                                    let images = [...newImages];
                                    images.splice(index, 1);
                                    setNewImages(images);
                                  }}
                                >
                                  <div className="edit-button">
                                    <i className="bi bi-trash"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {currentRoomTypeDetails?.image
                        .slice(0)
                        .reverse()
                        .map((image: any, index: number) => {
                          return (
                            <div
                              className="uploaded__image__wrapper"
                              key={index}
                            >
                              <div className="uploaded__image">
                                <img src={getImage(image)} alt="" />
                                <div className="overlay">
                                  <div
                                    className="button__container"
                                    onClick={() =>
                                      deleteRoomTypeImage(
                                        currentRoomTypeDetails.images_ids[index]
                                      )
                                    }
                                  >
                                    <div className="edit-button">
                                      <i className="bi bi-trash"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  {newImages.length === 0 &&
                    currentRoomTypeDetails?.image.length === 0 && (
                      <div className="empty__room__div mt-5">
                        <i className="bi bi-x-circle mb-3"></i>
                        <div>You don’t have any images</div>
                      </div>
                    )}

                  <input
                    id="upload-images"
                    type="file"
                    accept="image/png, image/jpeg"
                    className="d-none"
                    onChange={onImageSelect}
                    multiple
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                  }}
                ></div>
                <div className="side__slider__panel__btn">
                  <SaveButton
                    className="bi bi-arrow-right"
                    handleClick={saveRoomTypeImages}
                    isAccessible={access}
                  >
                    Save
                  </SaveButton>
                </div>
              </div>

              {/* Tab section for room occupancy */}
              <div
                className="tab-pane fade"
                id="occupancy"
                role="tabpanel"
                aria-labelledby="occupancy-tab"
              >
                <div className="property__setup__room__type__ocuupancy">
                  <div className="standard-page--label my-3">
                    Maximum Number of Guests
                  </div>
                  <div className="increment__decrement__btn mb-4">
                    <div
                      className="decrement__btn"
                      onClick={() => decrement(maxOccupancy, setMaxOccupancy)}
                    >
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">{maxOccupancy}</div>
                    <div
                      className="increment__btn"
                      onClick={() => increment(maxOccupancy, setMaxOccupancy)}
                    >
                      <i className="bi bi-plus changing--icon"></i>
                    </div>
                  </div>
                  <div className="max__occupancy__wrapper">
                    <div className="guest__wrapper">
                      <div className="standard-page--label my-3">
                        Base Adult
                      </div>
                      <div className="increment__decrement__btn">
                        <div
                          className="decrement__btn"
                          onClick={() => decrement(baseAdult, setBaseAdult)}
                        >
                          <i className="bi bi-dash changing--icon"></i>
                        </div>
                        <div className="changing__value">{baseAdult}</div>
                        <div
                          className="increment__btn"
                          onClick={() => increment(baseAdult, setBaseAdult)}
                        >
                          <i className="bi bi-plus changing--icon"></i>
                        </div>
                      </div>
                    </div>

                    <div className="guest__wrapper">
                      <div className="standard-page--label my-3">Max Adult</div>
                      <div className="increment__decrement__btn">
                        <div
                          className="decrement__btn"
                          onClick={() => decrement(maxAdult, setMaxAdult)}
                        >
                          <i className="bi bi-dash changing--icon"></i>
                        </div>
                        <div className="changing__value">{maxAdult}</div>
                        <div
                          className="increment__btn"
                          onClick={() => increment(maxAdult, setMaxAdult)}
                        >
                          <i className="bi bi-plus changing--icon"></i>
                        </div>
                      </div>
                    </div>

                    <div className="guest__wrapper">
                      <div className="standard-page--label my-3">
                        Base Child
                      </div>
                      <div className="increment__decrement__btn">
                        <div
                          className="decrement__btn"
                          onClick={() => decrement(baseChild, setBaseChild)}
                        >
                          <i className="bi bi-dash changing--icon"></i>
                        </div>
                        <div className="changing__value">{baseChild}</div>
                        <div
                          className="increment__btn"
                          onClick={() => increment(baseChild, setBaseChild)}
                        >
                          <i className="bi bi-plus changing--icon"></i>
                        </div>
                      </div>
                    </div>

                    <div className="guest__wrapper">
                      <div className="standard-page--label my-3">Max Child</div>
                      <div className="increment__decrement__btn">
                        <div
                          className="decrement__btn"
                          onClick={() => decrement(maxChild, setMaxChild)}
                        >
                          <i className="bi bi-dash changing--icon"></i>
                        </div>
                        <div className="changing__value">{maxChild}</div>
                        <div
                          className="increment__btn"
                          onClick={() => increment(maxChild, setMaxChild)}
                        >
                          <i className="bi bi-plus changing--icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                  }}
                ></div>
                <div className="side__slider__panel__btn">
                  <SaveButton
                    className="bi bi-arrow-right"
                    handleClick={saveRoomTypeOccupancy}
                    isAccessible={access}
                  >
                    Save
                  </SaveButton>
                </div>
              </div>

              {/* Tab section for room rates */}
              <div
                id="rates"
                role="tabpanel"
                className="tab-pane fade"
                aria-labelledby="rates-tab"
              >
                <div className="add__room__type__meal__plan__wrapper m-0 p-0">
                  <div className="meal__plan__room__type">
                    <div className="select_meal_label">Select Meal Plans</div>
                  </div>

                  <div className="select__input__label my-4">
                    {allRatePlans.length > 0 &&
                      allRatePlans?.map((plans: any, idx: any) => {
                        return (
                          <div
                            className="me-4 days__label"
                            key={plans.rate_plan_id}
                          >
                            <input
                              className="form-check-input me-3"
                              type="checkbox"
                              name={plans.plan_type}
                              id={plans.rate_plan_id}
                              onChange={(e) => mealPlanSelectionHandler(e)}
                              checked={mealplanId.includes(
                                plans.rate_plan_id.toString()
                              )}
                            />
                            {plans.plan_type}
                          </div>
                        );
                      })}
                    {otherRatePlans.length > 0 && (
                      <div className="me-4 days__label">
                        <input
                          className="form-check-input me-3"
                          type="checkbox"
                          id="weekdayCheck"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setEnableOthers(!enableOthers);
                            if (enableOthers) {
                              const planIds = [...mealplanId];
                              otherRatePlans.forEach((rate: any) => {
                                if (
                                  planIds.includes(rate.rate_plan_id.toString())
                                ) {
                                  planIds.splice(
                                    planIds.indexOf(rate.rate_plan_id),
                                    1
                                  );
                                }
                              });
                              setMealPlanId(planIds);
                            }
                          }}
                        />
                        Others
                      </div>
                    )}
                  </div>

                  {enableOthers && (
                    <>
                      <div className="meal__plan__room__type">
                        <div className="select_meal_label">
                          Other Meal Plans
                        </div>
                      </div>

                      <div className="select__input__label my-4">
                        {otherRatePlans.length > 0 &&
                          otherRatePlans?.map((plans: any, idx: any) => (
                            <div
                              className="me-4 days__label"
                              key={plans.rate_plan_id}
                            >
                              <input
                                className="form-check-input me-3"
                                type="checkbox"
                                id={plans.rate_plan_id}
                                name={plans.plan_name}
                                onChange={(e) =>
                                  mealPlanSelectionHandler(e, "others")
                                }
                                checked={mealplanId.includes(
                                  plans.rate_plan_id.toString()
                                )}
                              />
                              {plans.plan_name}
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>

                <div className="add__room__type__meal__plan__wrapper px-0 mx-0">
                  {/* <div
                    className="room__rates__edit__date__range__picker"
                    onClick={() => setFocusedInput("startDate")}
                  >
                    <div className="date__range__picker__icon">
                      <i className="bi bi-calendar"></i>
                    </div>
                    <div className="date__range__label">
                      {!startDate && !endDate
                        ? "Select Dates"
                        : (startDate ? startDate.format("DD MMM, YYYY") : "") +
                          " - " +
                          (endDate ? endDate.format("DD MMM, YYYY") : "")}
                    </div>
                    <CustomDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      focusedInput={focusedInput}
                      setFocusedInput={setFocusedInput}
                    />
                  </div> */}

                  <div className="row edit__room__type__mt">
                    <div className="col-md-6">
                      <InputTextField
                        label="Enter Minimum Price"
                        value={currentPlanInfo?.min_price || ""}
                        handleChange={(e) => {
                          if (
                            validatePositiveInt(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setCurrentPlanInfo({
                              ...currentPlanInfo,
                              min_price: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <InputTextField
                        label="Enter Maximum Price"
                        value={currentPlanInfo?.max_price || ""}
                        handleChange={(e) => {
                          if (
                            validatePositiveInt(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setCurrentPlanInfo({
                              ...currentPlanInfo,
                              max_price: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="row mt-3">
                    <div className="col-md-6">
                      <InputTextField
                        label="Rack Price"
                        value={currentPlanInfo?.rack_price || ""}
                        handleChange={(e) => {
                          if (
                            validatePositiveInt(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setCurrentPlanInfo({
                              ...currentPlanInfo,
                              rack_price: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <InputTextField
                        label="Bar Price"
                        value={currentPlanInfo?.bar_price || ""}
                        handleChange={(e) => {
                          if (
                            validatePositiveInt(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setCurrentPlanInfo({
                              ...currentPlanInfo,
                              bar_price: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div> */}

                  {/* <div className="div__select__inputs__wrapper custom__wrapper__outlinedinput mt-3">
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="add-rooms-input">
                        Multiple Occupancy
                      </InputLabel>
                      <OutlinedInput
                        id="add-rooms-input"
                        label="Multiple Occupancy"
                        style={{ backgroundColor: "white" }}
                        value={multiOccupancyInput}
                        multiline
                        onKeyPress={onMultiOccupancyEnterPress}
                        onChange={(e) => {
                          if (
                            validatePositiveInt(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setMultiOccupancyInput(e.target.value.slice(0, 5));
                          }
                        }}
                        startAdornment={
                          multiOccupancyRates?.length > 0 && (
                            <div className="rooms__input__container">
                              {multiOccupancyRates.map((rate, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="room__number__div"
                                  >
                                    {rate}
                                    <i
                                      className="bi bi-x icon--close"
                                      onClick={() => {
                                        let rates = [...multiOccupancyRates];
                                        rates.splice(index, 1);
                                        setMultiOccupancyRates(rates);
                                      }}
                                    ></i>
                                  </div>
                                );
                              })}
                            </div>
                          )
                        }
                      />
                    </FormControl>
                  </div> */}

                  {/* <div className="row edit__room__type__mt">
                    <div className="col-md-6">
                      <InputTextField
                        label="Enter extra adult price"
                        value={currentPlanInfo?.extra_adult_price || ""}
                        handleChange={(e) => {
                          if (
                            validatePositiveInt(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setCurrentPlanInfo({
                              ...currentPlanInfo,
                              extra_adult_price: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <InputTextField
                        label="Enter extra child price"
                        value={currentPlanInfo?.extra_child_price || ""}
                        handleChange={(e) => {
                          if (
                            validatePositiveInt(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setCurrentPlanInfo({
                              ...currentPlanInfo,
                              extra_child_price: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div> */}
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                  }}
                ></div>
                <div className="side__slider__panel__btn">
                  <SaveButton
                    className="bi bi-arrow-right"
                    handleClick={saveRoomTypeRatePlan}
                    isAccessible={access}
                  >
                    Save
                  </SaveButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlidingPane>
    </>
  );
};

export default PropertyRoomTypes;
