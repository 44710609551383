import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import LoginReducer from "./reducers/LoginReducer";
import SidebarReducer from "./reducers/SidebarReducer";
import PropertiesReducer from "./reducers/PropertiesReducer";
import AddPropertyReducer from "./reducers/AddPropertyReducer";
import AddRoomTypeReducer from "./reducers/AddRoomTypeReducer";
import AddFloorsReducer from "./reducers/AddFloorsReducer";
import PromptReducer from "./reducers/PromptReducer";
import ManageChannelReducer from "./reducers/ManageChannelReducer";
import BookingsReducer from "./reducers/BookingsReducer";
import BEUrlReducer from "./reducers/BeUrlReducer";
import DashboardReducer from "./reducers/DashboardReducer";
import UserAcessReducer from "./reducers/UserAcessReducer";
import PaymentGatewayReducer from "./reducers/PaymentGatewayReducer"
import PreviewBoxVisibilityReducer from "./reducers/PreviewBoxVisibilityReducer";
import UpdateInventoryNosReducer from "./reducers/UpdateInventoryReducers";
import AddPropertyMobileReducer from "./reducers/AddPropertyMobileReducer";
import AddPropertyMailReducer from "./reducers/AddPropertyMailReducer";
import SavePropertyNewMailReducer from "./reducers/SavePropertyNewMailReducer";
import SavePropertyNewMobileReducer from "./reducers/SavePropertyNewMobileReducer";


const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "properties",
    "sidebar",
    "add_property",
    "add_room_type",
    "add_floors",
    "manage_channels",
    "bookings",
    "beUrl",
    "dashBoardData",
    "userAcess",
    // "gateWay"
  ],
};

const rootReducer = combineReducers({
  auth: LoginReducer,
  properties: PropertiesReducer,
  sidebar: SidebarReducer,
  add_property: AddPropertyReducer,
  add_room_type: AddRoomTypeReducer,
  add_floors: AddFloorsReducer,
  prompt: PromptReducer,
  manage_channels: ManageChannelReducer,
  bookings: BookingsReducer,
  beUrl: BEUrlReducer,
  dashBoardData: DashboardReducer,
  userAcess: UserAcessReducer,
  gateWay: PaymentGatewayReducer,
  preview: PreviewBoxVisibilityReducer,
  stateUpdateReducers: UpdateInventoryNosReducer,

  addPropertyMail: AddPropertyMailReducer,
  addPropertyMobile: AddPropertyMobileReducer,
  savePropertyNewMail: SavePropertyNewMailReducer,
  savePropertyNewMobile: SavePropertyNewMobileReducer,
});

export default persistReducer(persistConfig, rootReducer);
