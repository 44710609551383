import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";

import InputTextField from "../../views/inputtextfield/InputTextField";

import kernelApi from "../../API/kernelApi";

import beApi from "../../API/beApi";
import Button from "../../views/buttons/Button";

// import react-toaster
import { toast } from "react-toastify";
import { UpdateSidebar, Logout } from "../../UtilityFunctions";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Loader from "../../views/loader/Loader";
import endPoints from "../../API/endPoints";

const PropertyCancellationRules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  UpdateSidebar("Property Setup", "Cancellation Rules");

  // endPoints Imports
  const {
    cancellationPolicy,
    updateCancellationPolicy
  } = endPoints.PROPERTY_CANCELLATION_RULES;

  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] = useState("0-1");
  const [cancellationRules, setCancellationRules] = useState<any[]>([]);
  const [refundInput, setRefundInput] = useState("");
  const [definedId, setDefinedId] = useState("");
  const [access, setAccess] = useState(true);

  const refundDaysTabs = [
    { id: "0-1", label: "0-1 Day" },
    { id: "2-5", label: "2-5 Days" },
    { id: "6-10", label: "6-10 Days" },
    { id: "11-20", label: "11-20 Days" },
    { id: "21-30", label: "21-30 Days" },
  ];

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "HOTEL";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    getCancellationRules();
  }, [current_property]);

  useEffect(() => {
    if (cancellationRules.length > 0) {
      setRefundInput(
        cancellationRules.filter(
          (item: any) => item.days_before_checkin === selectedTabId
        )[0]?.refund_percentage
      );
      selectTab(refundDaysTabs[0].id);
    } else {
      setRefundInput("");
    }
  }, [cancellationRules]);

  // API call for cancellation rules
  const getCancellationRules = async () => {
    setLoader(true);
    try {
      let response = await beApi.get(
        `${cancellationPolicy}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        let result = response.data.cancellation_policy;
        setCancellationRules(result.policy_data_array);
        setDefinedId(result?.id);
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.status === 400) {
        Logout();
      }
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  const selectTab = (id: string) => {
    let refund = cancellationRules.filter((rule: any) => {
      return id === rule.days_before_checkin;
    });
    setSelectedTabId(id);

    if (refund.length > 0) {
      setRefundInput(refund[0].refund_percentage);
    } else {
      setRefundInput("");
    }
  };

  // Handle input change
  const handleInputChange = (e: any) => {
    let val = e.target.value;
    if (
      val.match(/^100$|^100.00$|^([0-9]{0,2}(?:[\.][0-9]*)?|\.[0-9]+)$/) &&
      (val.split(".").length !== 1 ? val.split(".")[1].length < 3 : true)
    ) {
      setRefundInput(val);
    }
  };

  // Post API call for update the cancellation refund
  const updateCancellationRule = async (
    selectedTabId: string,
    refundInput: any
  ) => {
    setLoader(true);

    const updatedCanRules = [...cancellationRules];

    const ruleIndex = updatedCanRules.findIndex(
      (canRule: any) => canRule.days_before_checkin === selectedTabId
    );

    const DuplicateCheck =
      updatedCanRules.filter(
        (canRule: any) => canRule.days_before_checkin === selectedTabId
      ).length > 0;

    if (DuplicateCheck) {
      updatedCanRules[ruleIndex].refund_percentage = refundInput;
    } else {
      updatedCanRules.splice(ruleIndex, 0, {
        days_before_checkin: selectedTabId,
        refund_percentage: refundInput,
      });
    }

    let policy_arr: any[] = [];

    for (let i = 0; i < updatedCanRules.length; i++) {
      policy_arr[i] =
        updatedCanRules[i].days_before_checkin +
        ":" +
        updatedCanRules[i].refund_percentage;
    }

    try {
      let response = await beApi.post(
        `${updateCancellationPolicy}`,
        {
          hotel_id: current_property.hotel_id,
          policy_data: policy_arr,
          id: parseInt(definedId),
        }
      );

      if (response.data.status === 1) {
        getCancellationRules();
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        setLoader(false);
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
      setLoader(false);
    }
  };

  const sumbitCancellation = () => {
    if (refundInput) {
      updateCancellationRule(selectedTabId, refundInput);
    } else {
      toast("Please enter refund percentage!", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>
      <div className="property__setup__financial__details__wrapper">
        <div className="address-form-container edit-financialform-container ">
          <div className="edit__room__type__nav__tabs">
            <div className="edit__room__type__nav__wrapper remove__margin__padding">
              <ul
                className="nav nav-tabs mb-3"
                id="editRoomType"
                role="tablist"
              >
                {refundDaysTabs.map((tab) => {
                  return (
                    <li
                      className="nav-item"
                      role="presentation"
                      key={tab.id}
                      onClick={() => selectTab(tab.id)}
                    >
                      <button
                        className={`nav-link tab--link--text ${selectedTabId === tab.id ? "active" : ""
                          }`}
                        id="room-details-tabs"
                        data-bs-toggle="tab"
                        data-bs-target="#room-details"
                        type="button"
                        role="tab"
                        aria-controls="room-details"
                        aria-selected="true"
                      >
                        {tab.label}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="tab-content mt-3" id="editRoomTypeContent">
                <div
                  className="tab-pane fade show active"
                  id="room-details"
                  role="tabpanel"
                  aria-labelledby="room-details-tabs"
                >
                  <div className="tab-content__room__details">
                    <div className="__default--input--field">
                      <InputTextField
                        label="Refund Percentage"
                        value={refundInput}
                        handleChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="address-form-container edit-canel-container ms-0">
                  <div className="__btn__wrapper">
                    <Button
                      className="cancel__btn me-3"
                      handleClick={() => navigate("/property-setup")}
                      isAccessible={access}
                    >
                      Cancel
                    </Button>
                    <Button
                      loader={loader}
                      className="save__btn"
                      handleClick={sumbitCancellation}
                      isAccessible={access}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // loader ? (
  //   <div className="loader-setup">
  //     <Loader />
  //   </div>
  // ) : (
  //   <>
  //     <div className="property__breadcrumbs">
  //       <Breadcrumbs />
  //       <div className="manage__property__action__right">
  //         <Button
  //           className="back__btn"
  //           icon="bi bi-arrow-left"
  //           handleClick={() => navigate(-1)}
  //         >
  //           Back
  //         </Button>
  //       </div>
  //     </div>
  //     <div className="hr--line"></div>
  //     <div className="property__setup__financial__details__wrapper">
  //       <div className="address-form-container edit-financialform-container ">
  //         <div className="edit__room__type__nav__tabs">
  //           <div className="edit__room__type__nav__wrapper remove__margin__padding">
  //             <ul
  //               className="nav nav-tabs mb-3"
  //               id="editRoomType"
  //               role="tablist"
  //             >
  //               {refundDaysTabs.map((tab) => {
  //                 return (
  //                   <li
  //                     className="nav-item"
  //                     role="presentation"
  //                     key={tab.id}
  //                     onClick={() => selectTab(tab.id)}
  //                   >
  //                     <button
  //                       className={`nav-link tab--link--text ${
  //                         selectedTabId === tab.id ? "active" : ""
  //                       }`}
  //                       id="room-details-tabs"
  //                       data-bs-toggle="tab"
  //                       data-bs-target="#room-details"
  //                       type="button"
  //                       role="tab"
  //                       aria-controls="room-details"
  //                       aria-selected="true"
  //                     >
  //                       {tab.label}
  //                     </button>
  //                   </li>
  //                 );
  //               })}
  //             </ul>
  //           </div>
  //         </div>

  //         <div className="row">
  //           <div className="col-md-6">
  //             <div className="tab-content mt-3" id="editRoomTypeContent">
  //               <div
  //                 className="tab-pane fade show active"
  //                 id="room-details"
  //                 role="tabpanel"
  //                 aria-labelledby="room-details-tabs"
  //               >
  //                 <div className="tab-content__room__details">
  //                   <div className="__default--input--field">
  //                     <InputTextField
  //                       label="Refund Percentage"
  //                       value={refundInput}
  //                       handleChange={handleInputChange}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="address-form-container edit-canel-container ms-0">
  //                 <div className="__btn__wrapper">
  //                   <Button
  //                     className="cancel__btn me-3"
  //                     handleClick={() => navigate("/property-setup")}
  //                     isAccessible={access}
  //                   >
  //                     Cancel
  //                   </Button>
  //                   <Button
  //                     loader={loader}
  //                     className="save__btn"
  //                     handleClick={sumbitCancellation}
  //                     isAccessible={access}
  //                   >
  //                     Save
  //                   </Button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default PropertyCancellationRules;
