import { nanoid } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import beApi from "../../API/beApi";
import endPoints from "../../API/endPoints";
import { RootState } from "../../redux/store";
import { Capitalize } from "../../UtilityFunctions";

function RecentBookings(setDataFetched, setDefaultMsg, defaultMsg) {
  // function RecentBookings(setDataFetched) {
  const [recentBookingsData, setRecentBookingsData] = useState([]);

  const { bookingDetails } = endPoints.DASHBOARDDATA;

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const getRecentBookingsData = async () => {
    try {
      const Response = await beApi.get(
        `${bookingDetails}/${current_property.hotel_id}`
      );

      if (Response.data.status === 1) {
        setDataFetched(1);
        setRecentBookingsData(Response.data.data);
      } else if (Response.data.status === 0) {
        setDataFetched(0);
        setDefaultMsg({ msg: Response.data.message });
      }
    } catch {
      setDataFetched(-1);
      setDefaultMsg({ msg: "Unable to fetch recent bookings details" });
    }
  };

  useEffect(() => {
    getRecentBookingsData();
  }, [current_property?.hotel_id]);

  const RECENT_BOOKINGS_MAP = recentBookingsData.map((obj) => {
    const {
      channel_name,
      checkin_at,
      checkout_at,
      customer_name,
      customer_phone,
      paid_amount,
      logo,
      display_booking_date,
      booking_days,
      check_in_out_at
    } = obj;

    const checkIn = moment(checkin_at).format("MMMM Do");
    const checkOut = moment(checkout_at).format("MMMM Do");

    const key = nanoid();

    return (
      <div className="someBox" style={{ position: "relative" }} key={key}>
        <div className="left">
          <div className="leftItems booking-date">
            <div>{display_booking_date}</div>
            <small>{booking_days}</small>
          </div>

          <div className="leftItems customerName">
            <i className="bi bi-person"></i>
            <div>{Capitalize(customer_name)}</div>
          </div>

          <div className="leftItems customerPhone">
            <i className="bi bi-telephone"></i>
            <div>
              <strong>{customer_phone}</strong>
            </div>
          </div>

          <div className="leftItems checkin-checkout">
            <i className="bi bi-calendar-week"></i>
            <div>{`${check_in_out_at}`}</div>
          </div>
        </div>

        <div className="right">
          <div className="channel_name">
            <img src={logo} alt="logo" />
          </div>
          <div className="paid-amt">
            <div>Rs.{paid_amount ? paid_amount : 0}</div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      {RECENT_BOOKINGS_MAP.length > 0 ? (
        RECENT_BOOKINGS_MAP
      ) : (
        <div className="emptyBodyContainer">{defaultMsg.msg}</div>
      )}
    </>
  );
}

export default RecentBookings;
