import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import BackButton from "../../views/buttons/Button";
import GetHelp from "../../components/gethelp/GetHelp";
import NextButton from "../../views/buttons/NextButton";

import { RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import { AddRoomTypeOccupancy } from "../../redux/actions/AddRoomTypeAction";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";
import { toast } from "react-toastify";

const RoomTypeOccupancy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { property_data } = useSelector((state: RootState) => state.properties);

  const [maxGuest, setMaxGuest] = useState(store.getState().add_room_type.occupancy.max_occupancy);
  const [baseAdult, setBaseAdult] = useState(store.getState().add_room_type.occupancy.base_adult);
  const [maxAdult, setMaxAdult] = useState(store.getState().add_room_type.occupancy.max_adult);
  const [baseChild, setBaseChild] = useState(store.getState().add_room_type.occupancy.base_child);
  const [maxChild, setMaxChild] = useState(store.getState().add_room_type.occupancy.max_child);

  const Decrement = (state: number, setState: React.Dispatch<React.SetStateAction<number>>) => {
    if (state > 0) {
      setState(state - 1);
    }
  };

  const Increment = (state: number, setState: React.Dispatch<React.SetStateAction<number>>) => {
    setState(state + 1);
  };

  const SubmitOccupancyDetails = () => {
    if (!maxGuest) {
      toast("Maximum occupancy cannot be zero", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (!baseAdult) {
      toast("Base adult cannot be zero", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (!maxAdult) {
      toast("Max adult cannot be zero", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (baseAdult > maxGuest) {
      toast("Base adult cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (maxAdult > maxGuest) {
      toast("Max adult cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (baseChild > maxGuest) {
      toast("Base child cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (maxChild > maxGuest) {
      toast("Max child cannot be greater than max occupancy", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (baseAdult > maxAdult) {
      toast("Base adult cannot be greater than max adult", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (baseChild > maxChild) {
      toast("Base child cannot be greater than max child", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (maxGuest > maxAdult + maxChild) {
      toast("Maximum occupancy cannot be greater than the sum of max adult and max child", {
        hideProgressBar: true,
        type: "error"
      });
    }
    else {
      const occupancy = { max_occupancy: maxGuest, max_adult: maxAdult, max_child: maxChild, base_adult: baseAdult, base_child: baseChild }
      dispatch(AddRoomTypeOccupancy(occupancy))

      setTimeout(() => {
        navigate("/add-room-type/room-type-price");
      }, 500);
    }
  };

  const BackButtonClick = () => {
    navigate("/add-room-type/enter-room-details");
  };

  return (
    <>
      <div className="div-page-wrapper">
        <div className="div__wrapper">
          <LeftSideNav>
            <>
              <div className="div__user__testimonials__wrapper">
                <div className="screen__progress__wrapper">
                  <div className="progress__status__wrapper">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">Create {property_data.data.length === 1 ? "First" : ""} Property</div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">Select Subscription Plan</div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper">
                      <i className="bi bi-play-fill progress--icon--play"></i>
                    </div>
                    <div className="status__label">Create Room Type</div>
                  </div>
                  <div className="progress__sub__list__wrapper">
                    <div className="progress__sub__listitems">
                      Enter Room Deails
                    </div>
                    <div className="progress__sub__listitems progress--sub--item--text">
                      &nbsp;- Define Occupancy
                    </div>
                    <div className="progress__sub__listitems">Add Rates</div>
                    <div className="progress__sub__listitems">
                      Upload Images
                    </div>
                  </div>
                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper"></div>
                    <div className="status__label">Create Floors</div>
                  </div>
                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper"></div>
                    <div className="status__label">Add Rooms</div>
                  </div>
                </div>
              </div>

              <div className="progress-bar-wrapper">
                <div className="progress-bar-text">2/4</div>
                <div className="progress-bar-status">
                  <ProgressBar percent={50} filledBackground="#FFFFFF" />
                </div>
              </div>
            </>
          </LeftSideNav>

          <RightSideContent>
            <>
              <CurrentPropertyLabel />
              <div className="div--head--label">Define Occupancy details</div>
              <div className="div--helper--text pb-3">
                Define the required details below
              </div>


              <div className="standard-page--label my-3">
                Maximum Number of Guests
              </div>
              <div className="increment__decrement__btn mb-4">
                <div
                  className="decrement__btn"
                  onClick={() => Decrement(maxGuest, setMaxGuest)}>
                  <i className="bi bi-dash changing--icon"></i>
                </div>
                <div className="changing__value">
                  {maxGuest < 10 ? "0" + maxGuest : maxGuest}
                </div>
                <div
                  className="increment__btn"
                  onClick={() => Increment(maxGuest, setMaxGuest)}>
                  <i className="bi bi-plus-lg changing--icon"></i>
                </div>
              </div>
              <div className="max__occupancy__wrapper">
                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Base Adult</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => Decrement(baseAdult, setBaseAdult)}>
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">
                      {baseAdult < 10 ? "0" + baseAdult : baseAdult}
                    </div>
                    <div
                      className="increment__btn"
                      onClick={() => Increment(baseAdult, setBaseAdult)}>
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>

                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Max Adult</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => Decrement(maxAdult, setMaxAdult)}>
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">
                      {maxAdult < 10 ? "0" + maxAdult : maxAdult}
                    </div>
                    <div
                      className="increment__btn"
                      onClick={() => Increment(maxAdult, setMaxAdult)}>
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>

                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Base Child</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => Decrement(baseChild, setBaseChild)}>
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">
                      {baseChild < 10 ? "0" + baseChild : baseChild}
                    </div>
                    <div
                      className="increment__btn"
                      onClick={() => Increment(baseChild, setBaseChild)}>
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>

                <div className="guest__wrapper">
                  <div className="standard-page--label my-3">Max Child</div>
                  <div className="increment__decrement__btn">
                    <div
                      className="decrement__btn"
                      onClick={() => Decrement(maxChild, setMaxChild)}>
                      <i className="bi bi-dash changing--icon"></i>
                    </div>
                    <div className="changing__value">
                      {maxChild < 10 ? "0" + maxChild : maxChild}
                    </div>
                    <div
                      className="increment__btn"
                      onClick={() => Increment(maxChild, setMaxChild)}>
                      <i className="bi bi-plus-lg changing--icon"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="__btn__wrapper mt-5">
                <BackButton className="back__btn" handleClick={BackButtonClick}>
                  Back
                </BackButton>
                <NextButton handleClick={SubmitOccupancyDetails} />
              </div>
              <GetHelp />
            </>
          </RightSideContent>
        </div>
      </div>
    </>
  );
};

export default RoomTypeOccupancy;
