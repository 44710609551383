import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../views/buttons/Button";
import { CATCH_MESSAGE, UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";

import { toast } from "react-toastify";
import gemsApi from "../../API/gemsApi";
import endPoints from "../../API/endPoints";

const PropertyFloors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  UpdateSidebar("Property Setup", "Floors");

  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  // endPoints Imports
  const {
    getFloors,
    saveFloors
  } = endPoints.PROPERTY_FLOORS;

  const [numOfFloors, setNumOfFloors] = useState(0);
  const [allFloors, setAllFloors] = useState<any[]>([]);

  useEffect(() => {
    getFloorDetails();
  }, [current_property]);

  const getFloorDetails = async () => {
    try {
      let response = await gemsApi.post(`${getFloors}`, {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
      });
      if (response.data.status === 1) {
        setAllFloors(response.data.allFloors);
        setNumOfFloors(response.data.floors);
      }
    } catch (error) {
      setAllFloors([]);
      console.log(error);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  const saveFloorDetails = async (payload: any) => {
    try {
      let response = await gemsApi.post(`${saveFloors}`, payload);

      if (response.data.status === 1) {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getFloorDetails();
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const changeFloorStatus = async (floorNo: number, numOfRooms: number) => {
    if (numOfRooms === 0) {
      let floor_plan: any[] = [];
      allFloors.forEach((floor: any) => {
        floor_plan.push({
          floor_no: floor.floor_no,
          planned_for_room:
            floor.floor_no !== floorNo.toString()
              ? floor.planned_for_room
                ? "TRUE"
                : "FALSE"
              : floor.planned_for_room
                ? "FALSE"
                : "TRUE",
        });
      });

      let payload = {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
        floors: numOfFloors,
        floor_plan: floor_plan,
      };

      saveFloorDetails(payload);
    } else {
      toast(
        "Floor status can't be changed as there are active rooms assigned to it",
        {
          hideProgressBar: true,
          type: "error",
        }
      );
    }
  };

  const addFloor = () => {
    let floor_plan: any[] = [];
    allFloors.forEach((floor: any) => {
      floor_plan.push({
        floor_no: floor.floor_no,
        planned_for_room: floor.planned_for_room ? "TRUE" : "FALSE",
      });
    });

    floor_plan.push({
      floor_no: numOfFloors.toString(),
      planned_for_room: "FALSE",
    });

    let payload = {
      hotel_id: current_property.hotel_id,
      user_id: admin_id,
      floors: numOfFloors + 1,
      floor_plan: floor_plan,
    };

    saveFloorDetails(payload);
  };

  // const deleteFloor = (floorNo: number) => {
  //   dispatch(ShowConfirmationPrompt(
  //     "Are you sure you want to delete this floor?",
  //     "DELETE",
  //     () => {
  //       let floor_plan: any[] = [];
  //       allFloors.forEach((floor: any) => {
  //         if (floor.floor_no !== floorNo.toString()) {
  //           floor_plan.push({
  //             floor_no: floor.floor_no,
  //             planned_for_room: floor.planned_for_room ? "TRUE" : "FALSE",
  //           })
  //         }
  //       })

  //       let payload = {
  //         hotel_id: current_property.hotel_id,
  //         user_id: admin_id,
  //         floors: numOfFloors - 1,
  //         floor_plan: floor_plan,
  //       }

  //       saveFloorDetails(payload);
  //       dispatch(CancelConfirmationPrompt());
  //     }
  //   ))
  // }

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <div className="add__floor__btn_container">
            <Button icon="bi bi-plus" handleClick={addFloor}>
              Add
            </Button>
          </div>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      <div className="property__setup__manage__floors">
        {allFloors.length > 0 ? (
          <div className="manage__floor__wrapper">
            {allFloors
              .slice(0)
              .reverse()
              .map((floor: any, index: number) => {
                return (
                  <div className="manage__floor__card" key={index}>
                    <div className="manage__floor ms-0">
                      <div className="image--container">
                        <div className="floor__number">{`${floor.floor_name}`}</div>
                        <div className="floor__room__number">{`${floor.rooms_count
                          } Room${floor.rooms_count === 1 ? "" : "s"}`}</div>
                        {/* <div className="overlay">
                      <div className="button__container" onClick={() => deleteFloor(floor.floor_no)}>
                        <div className="delete-button">
                          <i className="bi bi-trash"></i>
                        </div>
                      </div>
                    </div> */}
                      </div>
                      <div className="room__type__name__details pt-0">
                        <div className="serviceable__label">
                          Serviceable Floor
                        </div>
                        <div className="toogle__switch">
                          <div className="form-check form-switch custom-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`flexSwitchCheckDefault${index}`}
                              checked={floor.planned_for_room}
                              onChange={() =>
                                changeFloorStatus(
                                  floor.floor_no,
                                  floor.rooms_count
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="empty__room__div my-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>This property doesn't have any floors yet</div>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertyFloors;
