import React from 'react';
import { UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

// import mart images
import MartHeader from "../../assets/img/mart__header.png";
import MartImage from "../../assets/img/mart_image.png";

const Mart = () => {
    UpdateSidebar("Mart");
    return (
        <>
            <div className="property__breadcrumbs">
                <Breadcrumbs />
            </div>

            <div className="hr--line"></div>

            <div className="mart__wrapper">
                <div className="mart__menu__header mb-1">
                    <img src={MartHeader} alt="Mart menu" />
                </div>
                <div className="mart__image__wrapper">
                    <img src={MartImage} alt="Mart section image" />
                </div>
                <div className="overlay">
                    <div className="mart__overlay">Mart Launching Soon 🚀</div>
                </div>
            </div>
        </>
    )
}

export default Mart;