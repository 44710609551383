import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";

import { useDispatch, useSelector } from "react-redux";
import { LoginSuccess, LoginCredentails, SaveSignupInfo, SaveClientSectionData } from "../redux/actions/LoginAction";

import StandardButton from "../views/buttons/Button";
import Loader from "../views/loader/Loader";

// import lottie
import Lottie from "lottie-react-web";

import { validateEmail, validateMobile } from "../UtilityFunctions";
import InputTextField from "../views/inputtextfield/InputTextField";
import InputPasswordField from "../views/inputtextfield/InputPasswordField";
import GetHelp from "../components/gethelp/GetHelp";
import LeftSideNav from "../components/onboarding/LeftSidenav";
import { toast } from "react-toastify";
import kernelApi from "../API/kernelApi";
import jwt_decode from "jwt-decode";
import endPoints from "../API/endPoints";
import { RootState } from "../redux/store";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  window.handleCredentialResponse = handleCredentialResponse;

  const { client_section }: any = useSelector((state: RootState) => state.auth);
  const { chargebeeEnvironment } = useSelector((state: RootState) => state.userAcess);

  const {
    getExtranetAdminAuth
  } = endPoints.LOGIN_AUTH;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailHelperText, setEmailHelperText] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [festiveBanner, setfestiveBanner] = useState("");

  const [isloading, setIsLoading] = useState(false);

  const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (
      validateEmail(e.target.value) ||
      validateMobile(e.target.value) ||
      !e.target.value
    ) {
      setEmailHelperText("");
    } else {
      setEmailHelperText("Invalid email or mobile");
    }
  };

  const PasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length < 4 && e.target.value) {
      setPasswordHelperText("Invalid password");
    } else {
      setPasswordHelperText(" ");
    }
  };

  const LoginFormSubmit = async () => {
    if (!email) {
      toast("Enter your email or mobile", {
        hideProgressBar: true,
        type: "error"
      });
    } else if (!password) {
      toast("Please enter your password", {
        hideProgressBar: true,
        type: "error"
      });
    } else {
      setIsLoading(true);
      let response = await kernelApi.post("/admin/auth", {
        email: email,
        password: password,
        browser: browserName,
        creation_mode: "WEBSITE",
      });

      setTimeout(() => {
        let credentails_data = { email: email, password: password };
        if (response.data.status === 1) {
          dispatch(LoginSuccess(response.data));
          dispatch(LoginCredentails(credentails_data));
          dispatch(SaveSignupInfo(response.data.subscription_customer_id))

          navigate("/select-property");
        } else if (response.data.status === 2) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error"
          });
        } else {
          toast("Invalid Credentials", {
            hideProgressBar: true,
            type: "error"
          });
        }
        setEmail("");
        setPassword("");
        setIsLoading(false);
      }, 600);
    }
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      LoginFormSubmit();
    }
  };

  function handleCredentialResponse(response: any) {
    const responsePayload: any = jwt_decode(response.credential);
    LoginWithGoogle(responsePayload);
  }

  const LoginWithGoogle = async (payload: any) => {
    try {
      const response = await kernelApi.post(`${getExtranetAdminAuth}`, {
        email: payload.email,
        name: payload.name,
        creation_mode: "Google",
        browser: browserName,
      });

      if (response.data.status === 1) {
        dispatch(LoginSuccess(response.data));
        dispatch(SaveSignupInfo(response.data.subscription_customer_id))
      } else {
        navigate("/signup-form", {
          state: { email: payload.email, name: payload.name },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let google_login_script = document.createElement("script");
    google_login_script.setAttribute(
      "src",
      "https://accounts.google.com/gsi/client"
    );
    google_login_script.async = true;
    google_login_script.defer = true;
    document.head.appendChild(google_login_script);
    getClientSectionData();
    getFestiveBanner();
  }, [isloading]);


  const getClientSectionData = async () => {
    try {
      let response = await kernelApi.get(`testimonials/${chargebeeEnvironment}`);
      if (response.data.status === 1) {
        dispatch(SaveClientSectionData({ imageUrl: response?.data?.testimonial?.image_url, userTestimonial: response?.data?.testimonial?.testimonial, userName: response?.data?.testimonial?.name, userDesignation: response?.data?.testimonial?.designation }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getFestiveBanner = async () => {
    try {
      let response = await kernelApi.get(`getfestivebanner`);
      if (response.data.status === 1) {
        setfestiveBanner((JSON.parse(response?.data?.lotte_code)));
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <LeftSideNav>
          <div className="div__user__testimonials__wrapper">
            <div
              className="user__testimonial_image"
              style={{ backgroundImage: `url(${client_section?.imageUrl})` }}
            >
              <div className="user__testimonial__message__wrapper">
                <div className="testimonial__icon">
                  <i
                    className="bi bi-chat-square-quote"
                    style={{ fontSize: 18 }}
                  ></i>
                </div>
                <div className="user__testimonial__text">
                  "{client_section?.userTestimonial}"
                </div>
                <div className="user__name">{client_section?.userName}</div>
                <div className="user__designation">{client_section?.userDesignation}</div>
              </div>
            </div>
          </div>
        </LeftSideNav>

        <div className="div__input__wrapper__right div__right__side__box align-items-center flex-nowrap">
          <div className="div__inputs">
            {!isloading ? (
              <>
                <div className="div--head--label">Log in</div>
                {chargebeeEnvironment === "HOTELKITEWEB" ?
                  <Link to="/signup">
                    <div className="div--helper--text mb-3">
                      Don't have an account yet?{" "}
                      <span className="dev--helper--signin-sign-up ps-2">
                        Sign up here
                      </span>
                    </div>
                  </Link> :
                  <Link to="/signup-validation">
                    <div className="div--helper--text mb-3">
                      Don't have an account yet?{" "}
                      <span className="dev--helper--signin-sign-up ps-2">
                        Sign up here
                      </span>
                    </div>
                  </Link>
                }

                {/* Signin with google section */}
                <div>
                  <div
                    id="g_id_onload"
                    data-client_id="1098328552594-m11e5mhckb9d28fp6vddifeeb9rv24li.apps.googleusercontent.com"
                    data-callback="handleCredentialResponse"
                  ></div>
                  {/* <div id="g_id_onload" data-client_id="1098328552594-m11e5mhckb9d28fp6vddifeeb9rv24li.apps.googleusercontent.com" data-login_uri="http://localhost:3000/select-property" data-auto_prompt="false"></div> */}
                  <div
                    className="g_id_signin"
                    style={{ maxWidth: "380px" }}
                    data-width="380"
                    data-type="standard"
                    data-size="large"
                    data-theme="filled_blue"
                    data-text="sign_in_with"
                    data-shape="rectangular"
                    data-logo_alignment="left"
                    onClick={LoginWithGoogle}
                  ></div>
                </div>

                <div className="or__wrapper">
                  <div className="hr--line"></div>
                  <div className="or--text">Or</div>
                </div>

                <div className="div__inputs__wrapp">
                  <div className="div--email--input">
                    <InputTextField
                      value={email}
                      helperText={emailHelperText}
                      label="Email or Mobile"
                      handleChange={EmailOnChange}
                      onKeyPress={onKeyEnter}
                      handleFocus={(e) => {
                        if (!e.target.value) setEmailHelperText("");
                      }}
                    />
                  </div>

                  <div className="div--password--input">
                    <InputPasswordField
                      value={password}
                      helperText={passwordHelperText}
                      onEnterKey={onKeyEnter}
                      handleFocus={(e) => {
                        if (!e.target.value) setPasswordHelperText("");
                      }}
                      handleChange={PasswordOnChange}
                    />
                  </div>

                  <div className="__btn__wrapper text-end">
                    <StandardButton
                      className="login--btn"
                      handleClick={() => LoginFormSubmit()}
                    >
                      Login
                    </StandardButton>
                  </div>

                  <div className="forgot__passowrd__wrapper">
                    <div className="hr--line"></div>
                    <div className="forgot--text">Forgot Password?</div>
                  </div>

                  <div className="reset__btn__wrapper">
                    <Link to="/reset-password">
                      <StandardButton className="reset--btn">
                        Reset Password
                      </StandardButton>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="div--head--label">Logging you in..</div>
                <div className="div--helper--text">
                  Please wait while we are logging you in
                </div>
                <Loader />
              </>
            )}

            <GetHelp />
          </div>

          {festiveBanner && <div className="festive__banner__wrapper">
            <div className="festive__banner__inner__wrapper">
              <Lottie
                options={{
                  animationData: festiveBanner,
                }}
              />
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Login;
