import { LocalizationProvider, DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { RootState } from "../../redux/store";
import { UpdateSidebar } from "../../UtilityFunctions";
import Button from "../../views/buttons/Button";
import FetchBooking from "./FetchBookings";
import NewBookings from "./NewBookings";
// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

const NoFrontOfficeView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const numOfColumns = 15;

  UpdateSidebar("Bookings", "Frontoffice View");

  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { booking_details } = useSelector((state: RootState) => state.bookings);
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const [access, setAccess] = useState(true);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment().format("MMM"));
  const [currentYear, setCurrentYear] = useState(
    parseInt(moment().format("YYYY"))
  );
  const [firstDate, setFirstDate] = useState(moment().format("YYYY-MM-DD"));
  const [openSlideFetchBooking, setOpenSlideFetchBooking] = useState(false);
  const [openSlideNewBooking, setOpenSlideNewBooking] = useState(false);
  const [refreshDataNewBooking, setRefreshDataNewBooking] = useState(true);
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;

    adminAcess === 911
      ? setAccess(true)
      : status === 1
      ? setAccess(true)
      : setAccess(false);
  }, [accessData]);

  const onMonthSelect = (Month: string, monthIndex: number) => {
    setCurrentMonth(Month);
    let month = (monthIndex + 1).toString();
    setFirstDate(
      currentYear.toString() + (month.length === 1 ? "-0" : "-") + month + "-01"
    );
  };

  const onYearChange = (change: number) => {
    let year = currentYear + change;
    if (year <= moment().year()) {
      setCurrentYear(year);
      setFirstDate(
        year.toString() +
          "-" +
          moment().month(currentMonth).format("MM") +
          "-01"
      );
    }
  };

  const getPrevDate = () => {
    let date = moment(firstDate)
      .subtract(numOfColumns, "d")
      .format("YYYY-MM-DD");
    setFirstDate(date);
    setCurrentYear(moment(date).year());
    setCurrentMonth(moment(date).format("MMM"));
  };

  const getNextDate = () => {
    let date = moment(firstDate).add(numOfColumns, "d").format("YYYY-MM-DD");
    setFirstDate(date);
    setCurrentYear(moment(date).year());
    setCurrentMonth(moment(date).format("MMM"));
  };

  const getCurrentDate = () => {
    setFirstDate(moment().format("YYYY-MM-DD"));
    setCurrentYear(moment().year());
    setCurrentMonth(moment().format("MMM"));
  };
  const onDateChange = (date: any) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    setFirstDate(date_str);
    setCurrentYear(moment(date_str).year());
    setCurrentMonth(moment(date_str).format("MMM"));
  };
  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__bookings__action__right">
          <div className="status__icon__wrapper booking__color">
            <i className="bi bi-circle-fill me-1"></i> Booking
          </div>
          <div className="status__icon__wrapper checkedin__color">
            <i className="bi bi-circle-fill me-1"></i> Checked-in
          </div>
          <div className="status__icon__wrapper checkedout__color">
            <i className="bi bi-circle-fill me-1"></i> Checked-out
          </div>

          <div className="fetch__booking__btn__wrapper">
            <Button
              className="fetch_bookings"
              icon="bi bi-download"
              handleClick={() => setOpenSlideFetchBooking(true)}
              isAccessible={access}
            >
              Fetch
            </Button>
          </div>

          <div className="new__booking__btn__wrapper">
            <Button
              className="new__bookings"
              icon="bi bi-plus-lg"
              handleClick={() => setOpenSlideNewBooking(true)}
              isAccessible={access}
            >
              New
            </Button>
          </div>
          <div className="calendar__view__wrapper">
            <div className="calendar__view__tab">
              <ul className="nav nav-tabs" id="mealPlanTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() => navigate("/bookings/crs-view")}
                    className="nav-link"
                    id="crs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="crs"
                    aria-controls="crs"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-calendar2-range"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="pms-tab"
                    data-bs-toggle="tab"
                    data-bs-target="pms"
                    aria-controls="pms"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-calendar-week"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() => navigate("/bookings/list-view")}
                    className="nav-link"
                    id="list-tab"
                    data-bs-toggle="tab"
                    data-bs-target="list"
                    aria-controls="list"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-grid-3x3-gap"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="hr--line"></div>

      <div id="frontoffice-view-wrapper">
        {/* <div className="calendar__month__wrapper">
          <div className="calendar__year__wrapper">
            <span className="left__arrow" onClick={() => onYearChange(-1)}>
              <i className="bi bi-chevron-left"></i>
            </span>
            <span className="year__label mx-1">{currentYear}</span>
            <span className="right__arrow" onClick={() => onYearChange(1)}>
              <i className="bi bi-chevron-right"></i>
            </span>
          </div>
          <div className="calendar__month__list">
            <div className="month__wrapper">
              {moment.monthsShort().map((month, i) => {
                return (
                  <div
                    className={`month__item ${
                      month === currentMonth ? "active--month" : ""
                    }`}
                    key={month}
                    onClick={() => onMonthSelect(month, i)}
                  >
                    {month}
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}

        {/* <div className="calendar__filter__wrapper">
          <div className="search__right__wrapper">
            <div className="change__calendar__filter">
              <span onClick={getPrevDate} className="left__arrow">
                <i className="bi bi-chevron-left"></i>
              </span>
              <div className="booking__crs__material__input">
                <div className="inventory__date__select__btn">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      open={openDatePicker}
                      onOpen={() => setOpenDatePicker(true)}
                      onClose={() => setOpenDatePicker(false)}
                      value={moment(firstDate)}
                      onChange={onDateChange}
                      inputFormat="dd MMM yyyy"
                      disableMaskedInput
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onClick={() => setOpenDatePicker(true)}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <span onClick={getNextDate} className="right__arrow">
                <i className="bi bi-chevron-right"></i>
              </span>

              <span
                onClick={getCurrentDate}
                className="text__label__bookings mx-1"
              >
                Today
              </span>
            </div>
          </div>
        </div> */}

        <div className="calendar__card__wrapper">
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>Frontoffice View Not Available</div>
          </div>
          {/* <div className="font-office-view-not-available">
            Frontoffice View Not Available
          </div> */}
        </div>
      </div>
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openSlideFetchBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideFetchBooking(false)}
      >
        <FetchBooking onClose={() => setOpenSlideFetchBooking(false)} />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={openSlideNewBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideNewBooking(false)}
      >
        <NewBookings
          onClose={() => setOpenSlideNewBooking(false)}
          refreshData={() => setRefreshDataNewBooking(!refreshDataNewBooking)}
        />
      </SlidingPane>
    </>
  );
};

export default NoFrontOfficeView;
