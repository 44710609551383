import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";

const Breadcrumbs = () => {
  const { breadcrumbs }: any = useSelector((state: RootState) => state.sidebar);

  return (
    <div className="section__heading">
      {breadcrumbs?.map((breadcrumb: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <Link to={breadcrumb.href ? breadcrumb.href : "#"}>
              <div
                className={`${index !== breadcrumbs.length - 1
                  ? "breadcrumbs__labels"
                  : "breadcrumbs__labels_second"
                  }`}>
                {breadcrumb?.label}
              </div>
            </Link>
            {index !== breadcrumbs.length - 1 && (
              <div className="breadcrumbs__arrows">
                <i className="bi bi-chevron-right"></i>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
