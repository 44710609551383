import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../views/buttons/Button";
import { CATCH_MESSAGE, getImage, UpdateSidebar } from "../../UtilityFunctions";

// import react-toaster
import { toast } from "react-toastify";

import kernelApi from "../../API/kernelApi";

import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import endPoints from "../../API/endPoints";

const PropertyImages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  UpdateSidebar("Property Setup", "Images");

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // endPoints Imports
  const {
    propertyImagesApi,
    uploadPropertyImagesApi,
    deletePropertyImagesApi,
  } = endPoints.PROPERTY_IMAGES;

  const [propertyImages, setPropertyImages] = useState<any[]>([]);
  const [access, setAccess] = useState(true);

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "HOTEL";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    getPropertyImages();
  }, [current_property]);

  const uploadImageFile = () => {
    document.getElementById("upload-images")?.click();
  };

  const getPropertyImages = async () => {
    try {
      let res = await kernelApi.get(`${propertyImagesApi}` + current_property.hotel_id);

      if (res.data.status === 1) {
        setPropertyImages(res.data.images);
      } else {
        setPropertyImages([]);
      }
    } catch (error) {
      setPropertyImages([]);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  const uploadPropertyImages = async (event: any) => {
    try {
      let payload = new FormData();
      let images = event.target.files;
      let image_count = 0;

      if (images.length > 5) {
        toast("Can not upload more than 5 images at a time", {
          hideProgressBar: true,
          type: "error",
        });
      } else {
        Array.from(images).forEach((image: any) => {
          if (image.size < 1024 * 1024) {
            payload.append("uploadFile[]", image);
            image_count++;
          } else {
            toast("Image size should be less than 1 MB", {
              hideProgressBar: true,
              type: "error",
            });
          }
        });

        if (image_count > 0) {
          payload.append("hotel_id", current_property.hotel_id);

          let res = await kernelApi.post(`${uploadPropertyImagesApi}`, payload);
          if (res.data.status === 1) {
            getPropertyImages();
            toast(res.data.message, {
              hideProgressBar: true,
              type: "success",
            });
          } else {
            toast(res.data.message, {
              hideProgressBar: true,
              type: "error",
            });
          }
        }
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const deleteImage = (image_id: number) => {
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to delete this image?",
        "DELETE",
        async () => {
          try {
            let res = await kernelApi.post(
              `${deletePropertyImagesApi}${current_property.hotel_id}`,
              {
                property_image_id: image_id,
              }
            );
            if (res.data.status === 1) {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "success",
              });
              getPropertyImages();
            } else {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "error",
              });
            }
          } catch (error) {
            toast(CATCH_MESSAGE, {
              hideProgressBar: true,
              type: "error",
            });
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  return (
    <>
      <div className="property__setup__room__types">
        <div className="property__breadcrumbs">
          <Breadcrumbs />
          <div className="manage__property__action__right">
            <Button
              icon="bi bi-plus-lg"
              handleClick={uploadImageFile}
              isAccessible={access}
            >
              Add
            </Button>
            <Button
              className="back__btn"
              icon="bi bi-arrow-left"
              handleClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="hr--line"></div>
        <div className="manage-room-type-container mt-4"></div>

        {propertyImages.length > 0 ? (
          <div className="manage__room__type__wrapper">
            {propertyImages.map((image, index) => {
              return (
                <div className="manage__room__type__card" key={index}>
                  <div className="manage__room__type ms-0">
                    <div className="image--container">
                      <img
                        className="property--image--radius"
                        src={getImage(image.image_name)}
                        alt=""
                      />
                      {access && (
                        <div className="overlay overlay-border-radius">
                          <div
                            className="button__container"
                            onClick={() => deleteImage(image.image_id)}
                          >
                            <div className="edit-button">
                              <i className="bi bi-trash"></i>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>You don’t have any images</div>
          </div>
        )}

        <input
          id="upload-images"
          type="file"
          accept="image/png, image/jpeg"
          className="d-none"
          onChange={uploadPropertyImages}
          multiple
        />
      </div>
    </>
  );
};

export default PropertyImages;
