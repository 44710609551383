import { GET_PROPERTY_MOBILES, SAVE_PROPERTY_NEW_MOBILES } from "../actionTypes"

const initialState = []

const AddPropertyMobileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PROPERTY_MOBILES:
            return [...action.payload]
        default: return state
    }
}

export default AddPropertyMobileReducer;