import React, { useEffect, useState } from "react";

import { RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";

import {
  validatePositiveInt,
  Logout,
  UpdateSidebar,
} from "../../../../UtilityFunctions";
import PaidServiceButton from "../../../../views/buttons/Button";

import SlidingPane from "react-sliding-pane";

import InputTextField from "../../../../views/inputtextfield/InputTextField";

import SaveButton from "../../../../views/buttons/Button";
import Button from "../../../../views/buttons/Button";

import DeleteButton from "../../../../views/buttons/Button";

import beApi from "../../../../API/beApi";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
  DisableSwitchPropertyScreen,
} from "../../../../redux/actions/PromptAction";

import { toast } from "react-toastify";

import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";

import Loader from "../../../../views/loader/Loader";
import endPoints from "../../../../API/endPoints";

const PropertyPaidServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // UpdateSidebar("Property Setup", "Paid Services")
  UpdateSidebar("Manage Channels", "Booking Engine");

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { auth_token, admin_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [nodataMessage, setNoDataMessage] = useState<string>("");
  const [openAddSlidePaidServices, setOpenAddSlidePaidServices] =
    useState(false);
  const [openEditSlidePaidServices, setOpenEditSlidePaidServices] =
    useState(false);
  const [access, setAccess] = useState(true);
  const [addSrvcdescription, setaddSrvcdescription] = useState<any>("");
  const [inclusion, setInclusion] = useState<any>("");
  const [exclusion, setExclusion] = useState<any>("");
  const [paidServices, setPaidServices] = useState<any[]>([]);
  const [editServices, setEditServices] = useState({
    id: "",
    name: "",
    amount: "",
    tax: "",
    description: "",
  });
  const [addServices, setAddServices] = useState({
    name: "",
    amount: "",
    tax: "",
  });
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  //Endpoints Import
  const { getPaidServicesList, getPaidServicesUpdate, addPaidServices, deletePaidServices } = endPoints.MANAGECHANNEL
  // --------------------------------

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    paidServicesList();
  }, [current_property]);

  // Get API called for paid services list
  const paidServicesList = async () => {
    setLoader(true);
    try {
      let response = await beApi.get(
        `${getPaidServicesList}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        setLoader(false);
        setPaidServices(response.data.paidServices);
      } else {
        setLoader(false);
        setPaidServices([]);
        setNoDataMessage(response.data.message);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
      setLoader(false);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  const updateServices = (service: any) => {
    setOpenEditSlidePaidServices(true);
    setEditServices({
      id: service?.paid_service_id,
      name: service?.service_name,
      amount: service?.service_amount,
      tax: service?.service_tax,
      description: service?.paid_service_desc,
    });
  };

  const updatePaidServices = async () => {
    setLoader(true);
    if (editServices.name && editServices.amount && editServices.tax) {
      try {
        let response = await beApi.post(
          `${getPaidServicesUpdate}/${editServices.id}`,
          {
            service_name: editServices.name,
            service_amount: editServices.amount,
            service_tax: editServices.tax,
            user_id: admin_id,
            hotel_id: current_property.hotel_id,
            paid_service_desc: editServices.description,
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );

        if (response.data.status === 1) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          paidServicesList();
          setLoader(false);
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          setLoader(false);
        }
        setOpenEditSlidePaidServices(false);
      } catch (error: any) {
        if (error.response.status === 400) {
          Logout();
        }
      }
      setLoader(false);
    } else {
      toast("Please fill all the fields!", {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  // API integration for add new services
  const submitAddServices = async () => {
    setLoader(true);
    if (addServices.name && addServices.amount && addServices.tax) {
      try {
        let response = await beApi.post(
          addPaidServices,
          {
            service_name: addServices.name,
            service_amount: addServices.amount,
            service_tax: addServices.tax,
            user_id: admin_id,
            hotel_id: current_property.hotel_id,
            paid_service_desc: addSrvcdescription,
            // inclusion: inclusion,
            // exclusion: exclusion,
          },
          {
            headers: { Authorization: "Bearer " + auth_token },
          }
        );
        if (response.data.status === 1) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          paidServicesList();
          setAddServices({ name: "", amount: "", tax: "" });
          setLoader(false);
        } else {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
          setLoader(false);
        }
        setOpenAddSlidePaidServices(false);
      } catch (error: any) {
        if (error.response.status === 400) {
          Logout();
        }
        setLoader(false);
      }
    } else {
      toast("Please fill all the fields!", {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  const DeleteService = () => {
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to delete this paid service?",
        "DELETE",
        async () => {
          setLoader(true);
          try {
            let res = await beApi.delete(
              `${deletePaidServices}/${editServices.id}`,
              {
                headers: { Authorization: "Bearer " + auth_token },
              }
            );
            if (res.data.status === 1) {
              paidServicesList();
              toast(res.data.message, {
                hideProgressBar: true,
                type: "success",
              });
              setLoader(false);
            } else {
              toast(res.data.message, {
                hideProgressBar: true,
                type: "error",
              });
              setLoader(false);
            }
            setOpenEditSlidePaidServices(false);
          } catch (error: any) {
            if (error.response.status === 400) {
              Logout();
            }
            setLoader(false);
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Paid Services" },
          ]}
        />
        <div className="manage__property__action__right">
          <PaidServiceButton
            icon="bi bi-plus-lg"
            handleClick={() => setOpenAddSlidePaidServices(true)}
            isAccessible={access}
          >
            Add
          </PaidServiceButton>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <div className="property__setup__room__types">
          <div className="manage-room-type-container"></div>

          {paidServices.length > 0 ? (
            <div className="manage__room__type__wrapper mt-3">
              {paidServices.map((service, idx) => {
                return (
                  <div className="manage__room__type__card" key={idx}>
                    <div className="manage__room__type ms-0">
                      <div className="room__type__name__details">
                        <div className="offer__discount__div">
                          <div>
                            <div className="room--type-label">
                              {service.service_name.length < 18
                                ? service.service_name
                                : service.service_name.slice(0, 18) + "..."}
                            </div>
                            <div className="mt-1">
                              <span className="reset-font-size">
                                {service.service_amount}
                              </span>
                            </div>
                            <div className="offer__percentage mt-2">
                              <span className="reset-font-size">
                                {service.service_tax}%
                              </span>
                            </div>
                          </div>
                          <div
                            className="edit__paid__services"
                            onClick={() => updateServices(service)}
                          >
                            <div className="button__container">
                              <div className="edit-button">
                                <i className="bi bi-pencil"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="empty__room__div mt-5">
              <i className="bi bi-x-circle mb-3"></i>
              <div>{nodataMessage}</div>
            </div>
          )}
        </div>
      )}

      {/* -------------Edit sliding for paid services------------------ */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openEditSlidePaidServices}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenEditSlidePaidServices(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setOpenEditSlidePaidServices(false)}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">Edit Paid Service</div>
        </div>

        <div className="height-64px"></div>
        <div className="address-form-container edit-financialform-container m-3">
          <div className="div__inputs__wrapp">
            <div className="div--email--input">
              <InputTextField
                label="Service Name"
                value={editServices?.name}
                handleChange={(e) =>
                  setEditServices({ ...editServices, name: e.target.value })
                }
              />
            </div>
          </div>
          <div className="div__inputs__wrapp">
            <div className="div__input__align__horizontal">
              <div className="div--email--input me-1">
                <InputTextField
                  label="Service Amount (Without tax)"
                  value={editServices?.amount}
                  handleChange={(e) => {
                    if (
                      validatePositiveInt(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setEditServices({
                        ...editServices,
                        amount: e.target.value,
                      });
                    }
                  }}
                />
              </div>

              <div className="div--email--input ms-3">
                <InputTextField
                  label="Tax (In %)"
                  value={editServices?.tax}
                  handleChange={(e) => {
                    let val = e.target.value;
                    if (
                      val.match(
                        /^100$|^100.00$|^([0-9]{0,2}(?:[\.][0-9]*)?|\.[0-9]+)$/
                      ) &&
                      (val.split(".").length !== 1
                        ? val.split(".")[1].length < 3
                        : true)
                    ) {
                      setEditServices({
                        ...editServices,
                        tax: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* For Description */}
          <div className="div__inputs__wrapp">
            <div className=" standard-page--label mb-3">Description</div>

            <CKEditor
              editor={ClassicEditor}
              data={editServices.description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                // setDescription(data);
                setEditServices({
                  ...editServices,
                  description: data,
                });
                setCharCount({
                  ...charCount,
                  terms_cond: removeTags(data).length,
                });
              }}
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
        <div className="side__slider__panel__btn">
          <SaveButton
            className="bi bi-arrow-right"
            handleClick={updatePaidServices}
            loader={loader}
          >
            Save
          </SaveButton>
          <DeleteButton
            className="delete__btn__wrapper"
            handleClick={DeleteService}
          >
            <i className="bi bi-trash me-1"></i> Delete
          </DeleteButton>
        </div>
      </SlidingPane>

      {/*----------- ADD sliding page for paid services---------- */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openAddSlidePaidServices}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenAddSlidePaidServices(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setOpenAddSlidePaidServices(false)}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">Add Paid Service</div>
        </div>

        <div className="height-64px"></div>
        <div className="address-form-container edit-financialform-container m-3">
          <div className="div__inputs__wrapp">
            <div className="div--email--input">
              <InputTextField
                label="Service Name"
                value={addServices.name}
                handleChange={(e) =>
                  setAddServices({ ...addServices, name: e.target.value })
                }
              />
            </div>
          </div>
          <div className="div__inputs__wrapp">
            <div className="div__input__align__horizontal">
              <div className="div--email--input me-1">
                <InputTextField
                  label="Service Amount (Without tax)"
                  value={addServices.amount}
                  handleChange={(e) => {
                    if (
                      validatePositiveInt(e.target.value) ||
                      e.target.value == ""
                    ) {
                      setAddServices({
                        ...addServices,
                        amount: e.target.value,
                      });
                    }
                  }}
                />
              </div>

              <div className="div--email--input ms-3">
                <InputTextField
                  label="Tax (In %)"
                  value={addServices.tax}
                  handleChange={(e) => {
                    let val = e.target.value;
                    if (
                      val.match(
                        /^100$|^100.00$|^([0-9]{0,2}(?:[\.][0-9]*)?|\.[0-9]+)$/
                      ) &&
                      (val.split(".").length !== 1
                        ? val.split(".")[1].length < 3
                        : true)
                    ) {
                      setAddServices({ ...addServices, tax: e.target.value });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* For Description */}
          <div className="div__inputs__wrapp">
            <div className=" standard-page--label mb-3">Description</div>

            <CKEditor
              editor={ClassicEditor}
              data={addSrvcdescription}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setaddSrvcdescription(data);
                setCharCount({
                  ...charCount,
                  terms_cond: removeTags(data).length,
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
        <div className="side__slider__panel__btn">
          <SaveButton
            className="bi bi-arrow-right"
            handleClick={submitAddServices}
            loader={loader}
          >
            Save
          </SaveButton>
        </div>
      </SlidingPane>
    </>
  );
};

export default PropertyPaidServices;
