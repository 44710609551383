import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NextButton from "../../views/buttons/NextButton";
import BackButton from "../../views/buttons/Button";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AddRoomTypeMealPlan } from "../../redux/actions/AddRoomTypeAction";

import InputTextField from "../../views/inputtextfield/InputTextField";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import { CATCH_MESSAGE, Logout } from "../../UtilityFunctions";

export interface IRoomRate {
  onBack?: () => void;
}

const RoomRate: React.FC<IRoomRate> = ({ onBack }) => {
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disableRatePlan, setDisableRatePlan] = useState(true);

  const { meal_plan } = useSelector((state: RootState) => state.add_room_type);

  //Input field value getting states
  const [allPlanTypeOptn, setAllPlanTypeOptn] = useState<any[]>([]);
  const [roomRate, setRoomRate] = useState(meal_plan.ep_price);
  const [roomBreakfast, setRoomBreakfast] = useState(meal_plan.cp_price);
  const [roomBreakfastLunch, setRoomBreakfastLunch] = useState(
    meal_plan.map_price
  );
  const [roomBreakfastLunchDinner, setRoomBreakfastLunchDinner] = useState(
    meal_plan.ap_price
  );
  // useEffect(()=>{
  //   getAllMasterPlan()

  // },[])

  useEffect(() => {
    if (
      meal_plan.ep_price &&
      meal_plan.cp_price &&
      meal_plan.map_price &&
      meal_plan.ap_price
    ) {
      setDisableRatePlan(false);
    }
  }, [meal_plan]);

  const RoomRateOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.match(/^[0-9\b]+$/) || val === "") {
      setRoomRate(val);
    }
  };

  const RoomBreakfastOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.match(/^[0-9\b]+$/) || val === "") {
      setRoomBreakfast(val);
    }
  };

  const RoomBreakfastLunchOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let val = e.target.value;
    if (val.match(/^[0-9\b]+$/) || val === "") {
      setRoomBreakfastLunch(val);
    }
  };

  const RoomBreakfastLunchDinnerOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let val = e.target.value;

    if (val.match(/^[0-9\b]+$/) || val === "") {
      setRoomBreakfastLunchDinner(val);
    }
  };

  const ToggleRatePlan = () => {
    setDisableRatePlan(!disableRatePlan);
  };

  const SubmitRatePlans = () => {
    if (
      disableRatePlan ||
      (roomRate &&
        roomBreakfast &&
        roomBreakfastLunch &&
        roomBreakfastLunchDinner)
    ) {
      dispatch(
        AddRoomTypeMealPlan({
          ep_price: roomRate,
          cp_price: roomBreakfast,
          map_price: roomBreakfastLunch,
          ap_price: roomBreakfastLunchDinner,
        })
      );
      setTimeout(() => {
        navigate("/add-room-type/room-type-images");
      }, 500);
    } else {
      toast("Please fill all the details!", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // Get All Plans
  const getAllMasterPlan = async () => {
    try {
      let allMasterPlanRes = await kernelApi.get(`/master-rate-plan-details`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (allMasterPlanRes?.data?.status === 1) {
        const allPlans = allMasterPlanRes?.data?.data.map((iteam: any) => {
          return iteam.m_rate_plan;
        });
        setAllPlanTypeOptn(allPlans);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <div className="div__amenities__container">
      <div className="property_details_input__wrapper standard--margin--top rate-paln-wrapper">
        <div className={`__default--input--field override-input-filed`}>
          <InputTextField
            label="(EP) Room Only Rate"
            value={roomRate}
            handleChange={(e) => RoomRateOnChange(e)}
          />
        </div>
        <div className="meal-plan-label">
          <div className="standard-page--label mb-3">Do you provide meals?</div>
          <div className="form-check form-switch custom-switch">
            <input
              className="form-check-input mb-3"
              type="checkbox"
              id="flexSwitchCheckDefault"
              checked={!disableRatePlan}
              onClick={ToggleRatePlan}
            />
          </div>
        </div>

        {!disableRatePlan && (
          <>
            <div
              className={`__default--input--field override-input-filed ${
                disableRatePlan ? "custom-opacity" : ""
              }`}
            >
              <InputTextField
                label="(CP) Room + Breakfast"
                value={roomBreakfast}
                handleChange={RoomBreakfastOnChange}
                disabled={disableRatePlan}
              />
            </div>
            <div
              className={`__default--input--field override-input-filed ${
                disableRatePlan ? "custom-opacity" : ""
              }`}
            >
              <InputTextField
                label="(MAP) Room + Breakfast + Lunch / Dinner"
                value={roomBreakfastLunch}
                handleChange={RoomBreakfastLunchOnChange}
                disabled={disableRatePlan}
              />
            </div>
            <div
              className={`__default--input--field override-input-filed ${
                disableRatePlan ? "custom-opacity" : ""
              }`}
            >
              <InputTextField
                label="(AP) Room + Breakfast + Lunch + Dinner"
                value={roomBreakfastLunchDinner}
                handleChange={RoomBreakfastLunchDinnerOnChange}
                disabled={disableRatePlan}
              />
            </div>
          </>
        )}
      </div>
      <div className="__btn__wrapper mt-4">
        <BackButton className="back__btn" handleClick={onBack}>
          Back
        </BackButton>
        <NextButton handleClick={SubmitRatePlans} />
      </div>
    </div>
  );
};

export default RoomRate;
