import React, { useState, useEffect } from "react";

import { useNavigate } from 'react-router';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { Logout, UpdateSidebar } from "../../UtilityFunctions";
import Button from "../../views/buttons/Button";
import kernelApi from '../../API/kernelApi';
import Loader from "../../views/loader/Loader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CancelConfirmationPrompt, ShowConfirmationPrompt } from "../../redux/actions/PromptAction";
import endPoints from "../../API/endPoints";

const ManageSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  UpdateSidebar("Manage Subscriptions");
  const { current_property } = useSelector((state: RootState) => state.properties);
  const { chargebeeEnvironment } = useSelector((state: RootState) => state.userAcess);

  const { getCheckoutUrlForUpgrade, getZohoPaymentMethodUpdateUrl, mySubscription, cancelZohoSubscription, cancelSubscription, getZohoSavedCards } = endPoints.MANAGESUBSCRIPTION;

  const [planBillingData, setPlanBillingData] = useState<any>();
  const [appDetails, setAppDetails] = useState<any>([]);
  const [activeStatus, setActiveStatus] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [transactionsDetails, setTransactionsDetails] = useState<any>([]);
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [noSubscription, setNoSubscription] = useState<any>();
  const [listOutPaymentMethod, setListPaymentMethod] = useState<any>([]);

  useEffect(() => {
    getPlanBillingData();
    listPaymentMethods();
  }, [])

  const getPlanBillingData = async () => {
    setIsLoading(true);
    try {
      const response = await kernelApi.get(`${mySubscription}/${current_property.hotel_id}`);
      if (response.data.status === 1) {
        setPlanBillingData(response.data.subscription);
        setAppDetails(response.data.apps);
        setTransactionsDetails(response.data.transactions);
        setShowUpgrade(response.data.is_upgradable);
        switch (response.data.subscription.status) {
          case "in_trial":
            setActiveStatus({ status: "In Trial", message: `Your trial plan will expire in next ${response.data.subscription.trial_info.days_left}` });
            break;
          case "active":
            setActiveStatus({ status: "Active", message: `Your next billing date is on ${response.data.subscription.next_billing_date}` });
            break;
          case "cancelled":
            setActiveStatus({ status: "Cancelled", message: `Your plan has expired on ${response.data.subscription.trial_info.ends} kindly renew` });
            break;
          case "paused":
            setActiveStatus({ status: "Paused", message: `Your plan has been paused, Kindly contact support for details` });
            break;
          default:
            break;
        }
      } else {
        setNoSubscription(response.data.status);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  const downloadInvoice = (invoice_value: any) => {
    window.open(invoice_value);
  }

  const CancelSubscription = () => {
    if (chargebeeEnvironment === "HOTELKITEWEB") {
      CancelChargebeeSubscription();
    } else {
      CancelZohoSubscription();
    }
  }

  const CancelChargebeeSubscription = () => {
    dispatch(
      ShowConfirmationPrompt(
        "Your subscription will be ended with respect to the current term. Are you sure you want to cancel your subscription?",
        "DELETE",
        async () => {
          try {
            const response = await kernelApi.post(cancelSubscription, {
              hotel_id: current_property.hotel_id
            })
            if (response.data.status === 1) {
              toast.success(response.data.message);
              Logout();
            } else {
              toast.error(response.data.message);
              Logout();
            }
          } catch (error) {
            console.log(error);
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["Close", "Cancel Subscription"]
      )
    );
  }

  const CancelZohoSubscription = () => {
    dispatch(
      ShowConfirmationPrompt(
        "Your subscription will be ended with respect to the current term. Are you sure you want to cancel your subscription?",
        "DELETE",
        async () => {
          try {
            const response = await kernelApi.post(cancelZohoSubscription, {
              hotel_id: current_property.hotel_id
            })
            if (response.data.status === 1) {
              toast.success(response.data.message);
              Logout();
            } else {
              toast.error(response.data.message);
              Logout();
            }
          } catch (error) {
            console.log(error);
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["Close", "Cancel Subscription"]
      )
    );
  }

  // change payment method
  // const openSubscriptionPopup = {
  //   cbInstance: window.Chargebee.init({
  //     site: "bookingjini-test"
  //   })
  // }

  const paymentMethod = async () => {
    try {
      const response = await kernelApi.post(getZohoPaymentMethodUpdateUrl, {
        hotel_id: current_property.hotel_id,
        app_name: chargebeeEnvironment
      })
      if (response.data.status === 1) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const listPaymentMethods = async () => {
    try {
      const response = await kernelApi.post(getZohoSavedCards, {
        hotel_id: current_property.hotel_id
      })
      if (response.data.status === 1) {
        setListPaymentMethod(response.data.cards);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
      </div>
      <div className="hr--line"></div>
      {noSubscription !== 0 ? (
        <>
          <div className="manage__subscription__wrapper">

            <div className="manage__current__plan__wrapper">
              <div className="manage__current__plan__heading mb-3">Current Plan</div>
              {isLoading ? <div className="no__data__current__plan"><Loader /></div>
                :
                <div className="manage__current__plan__card">
                  <div className="current__plan__header">
                    <div className="plan__name__status__wrapper mb-2">
                      <div className="plan__name">{planBillingData?.subscription_plan_name}</div>
                      <div className={`status__wrapper ${planBillingData?.status}`}>{activeStatus?.status}</div>
                    </div>
                    <div className="price__expiration__wrapper">
                      <div className="price">{`${planBillingData?.subscription_currency} ${planBillingData?.subscription_price} per ${planBillingData?.subscription_period}`}</div>
                      <div className="expiration__date">{activeStatus?.message}</div>
                    </div>
                  </div>

                  <div className="current__plan__feature__list pb-0">
                    {appDetails?.map((data: any, index: number) => {
                      return (
                        <div className="feature__items" key={index}>{data?.app_name}</div>
                      )
                    })}
                  </div>

                  <div className="hr--line my-0"></div>

                  <div className="current__plan__btn__wrapper">
                    <div className="cancel__btn__wrapper me-3">
                      <Button icon="bi bi-x" handleClick={() => { CancelSubscription() }}> Cancel</Button>
                    </div>
                    <div className="upgrade__btn__wrapper">
                      {!showUpgrade ? <div className="already__on__highest__plan">You are already on highest plan.</div> :
                        <Button icon="bi bi-plus" handleClick={() => navigate("upgrade-subscription")}>Upgrade</Button>
                      }
                    </div>

                  </div>
                </div>
              }
            </div>
            <div className="manage__billing__history__wrapper">
              <div className="manage__billing__history__heading mb-3">Billing History </div>
              {isLoading ? <div className="no__data__current__plan"><Loader /></div>
                :
                <>
                  <div className="manage__billing__history__card">
                    {planBillingData?.status === "in_trial" ? <div className="trial__plan__wrapper">
                      <div className="trail__plan__content">
                        <div className="trail__icon mb-1"><i className="bi bi-x-circle"></i></div>
                        <div>You are currently on Trial Period</div>
                      </div>
                    </div>
                      : <table className="table table-bordered billing__table">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Plan</th>
                            <th scope="col">Payment Method</th>
                            <th scope="col">Invoice</th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0">
                          {transactionsDetails?.map((transactions: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{transactions?.date}</td>
                                <td>{transactions?.currency_code} {transactions?.amount}</td>
                                <td>{transactions?.plan_name}</td>
                                <td>{transactions?.masked_card_number}</td>
                                <td className="text-center"><i className="bi bi-file-arrow-down download__file__hover" onClick={() => downloadInvoice(transactions.invoice_url)}></i></td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    }
                  </div>
                </>
              }
            </div>

          </div>

          <div className="manage__subscription__wrapper mt-5 mb-3">
            <div className="manage__current__plan__wrapper">
              <div className="manage__current__plan__heading mb-3">Payment Method</div>
              <div className="manage__current__plan__card mapyment__method__wrapper">
                {isLoading ? <div className="no__data__current__plan"><Loader /></div> :
                  <>
                    <div className="current__plan__header">
                      <div className="payment__method__header">
                        <div className="payment__method__header__label">Card Number</div>
                        <div className="payment__method__header__label">Expiry</div>
                        <div className="payment__method__header__label">Status</div>
                      </div>
                    </div>
                    {!listOutPaymentMethod.length ? <div className="no__payment__method__added">No saved card information found</div> :
                      listOutPaymentMethod?.map((items: any, index: number) => {
                        return (
                          <div key={index}>
                            <div className="manage__card__wrapper pb-0">
                              <div className="masked__card__number">{items?.card_no}</div>
                              <div className="expiry__dates">{items?.card_expiry}</div>
                              <div className="card__status"><span>{items?.card_status}</span></div>
                            </div>
                          </div>
                        )
                      })}

                    <div className="hr--line"></div>
                    <div className="change__payment__method__btn">
                      <Button handleClick={() => paymentMethod()}>Update</Button>
                    </div>
                  </>
                }
              </div>
            </div>
          </div >
        </>
      ) :
        <>
          <div className="no__active__plan__subscribed__wrapper">
            <div>
              <div className="mb-3">You do not have an active subscription</div>
              <Button handleClick={() => { navigate('/choose-plan/renew-subscription') }}>Subscribe</Button>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default ManageSubscription;
