import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import endPoints from "../../../API/endPoints";
import kernelApi from "../../../API/kernelApi";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { BeurlAction } from "../../../redux/actions/BEUrlAction";
import { RootState } from "../../../redux/store";
import {
  CATCH_MESSAGE,
  Logout,
  UpdateSidebar,
} from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import { CreateRipple } from "../../../views/buttons/NextButton";

const BookingEngine = () => {
  const { auth_token, admin_id, company_id } = useSelector(
    (state: RootState) => state.auth
  );

  const [beUrl, setBeUrl] = useState<any>("");
  UpdateSidebar("Manage Channels", "Booking Engine");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Endpoints Import
  const { getCompanyProfile } = endPoints.MANAGECHANNEL;
  // --------------------------------

  const getBePrevewUrl = async () => {
    try {
      let res = await kernelApi.get(`${getCompanyProfile}/${company_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (res.data.status === 1) {
        dispatch(BeurlAction(res.data.data.booking_engine_url));
        setBeUrl(res.data.data.booking_engine_url);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  useEffect(() => {
    getBePrevewUrl();
  }, []);

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <div className="profile-manage-wrapper">
            <a href={beUrl} target="_blank">
              <div className="preview-btn" onClick={CreateRipple}>
                <i className="bi bi-play hotel__building__icon"></i>
                <div className="hotel--name--header">Preview BE</div>
              </div>
            </a>
          </div>

          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      <div className="manage__property__setup__left__panel">
        <div className="property-setup-wrapper">
          <div className="property__manage__row">
            <Link to="basic-setup">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-brush property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification">1</div> */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Basic Setup
                  </div>
                </div>
              </div>
            </Link>

            <Link to="paid-service">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-x-diamond property--setup--icon--style"></i>
                    </div>
                    {/* {roomTypesCount > -1 && (
                      <div className="property--setup--notification">
                        {roomTypesCount}
                      </div>
                    )} */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Paid Services
                  </div>
                </div>
              </div>
            </Link>

            <Link to="notification-popup">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-bell property--setup--icon--style"></i>
                    </div>
                    {/* {floorCount > -1 && (
                      <div className="property--setup--notification">
                        {floorCount}
                      </div>
                    )} */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Notification PopUp
                  </div>
                </div>
              </div>
            </Link>

            <Link to="promotional-slider">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-layout-sidebar-inset-reverse property--setup--icon--style"></i>
                    </div>
                    {/* {roomCount > -1 && (
                      <div className="property--setup--notification">
                        {roomCount}
                      </div>
                    )} */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Promotional Slider
                  </div>
                </div>
              </div>
            </Link>

            <Link to="coupon">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-ticket-perforated property--setup--icon--style"></i>
                    </div>
                    {/* {amenityCount > -1 && (
                      <div className="property--setup--notification">
                        {amenityCount}
                      </div>
                    )} */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Coupon
                  </div>
                </div>
              </div>
            </Link>

            <Link to="package-list">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-gift property--setup--icon--style"></i>
                    </div>
                    {/* {imagesCount > -1 && (
                      <div className="property--setup--notification">
                        {imagesCount}
                      </div>
                    )} */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Packages
                  </div>
                </div>
              </div>
            </Link>

            <Link to="nearest-location">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-geo-alt property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification">2</div> */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Location
                  </div>
                </div>
              </div>
            </Link>

            <Link to="nearest-places">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-pin-map-fill property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Places
                  </div>
                </div>
              </div>
            </Link>

            <Link to="payment-options">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-cash-coin property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Payment Gateway
                  </div>
                </div>
              </div>
            </Link>

            {/* Quick Payment Link  */}
            <Link to="quick-payment-link">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-link-45deg property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Quick Payment Link
                  </div>
                </div>
              </div>
            </Link>

            {/* Unpaid Booking */}
            <Link to="unpaid-bookings">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-clipboard-x property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Unpaid Booking
                  </div>
                </div>
              </div>
            </Link>

            {/* Additional Charges */}
            <Link to="additional-charges">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-cash-stack property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
                  </div>
                  <div className="basic--details--label be--bookingjini--label--width">
                    Additional Charges
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingEngine;
