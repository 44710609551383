import { url } from "inspector";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import beApi from "../../../../API/beApi";
import endPoints from "../../../../API/endPoints";
import kernelApi from "../../../../API/kernelApi";
import { BeurlAction } from "../../../../redux/actions/BEUrlAction";
import { RootState } from "../../../../redux/store";
import {
  CATCH_MESSAGE,
  Logout,
  validateUrl,
} from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import Card from "../../../../views/cardwrapper/Card";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
import Loader from "../../../../views/loader/Loader";

const ManageURLsSettings = () => {
  // Data From Redux
  const { admin_id, auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [webSiteUrl, setWebsiteUrl] = useState<any>("");
  const [subDomainUrl, setSubDomainUrl] = useState<any>("");
  const [urlType, setUrlType] = useState<any>();
  const [subDomain, setSubDomain] = useState<any>();
  const [urlHelperText, setUrlHelperText] = useState("");
  const [beUrl, setBeUrl] = useState<any>("");
  const [access, setAccess] = useState(true);

  const dispatch = useDispatch();

  //Endpoints Import
  const { getCompanyProfile, manageUrl, updateUrl } = endPoints.MANAGECHANNEL
  // --------------------------------

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  const getBePrevewUrl = async () => {
    try {
      let res = await kernelApi.get(`${getCompanyProfile}/${company_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (res.data.status === 1) {
        dispatch(BeurlAction(res.data.data.booking_engine_url));
        setBeUrl(res.data.data.booking_engine_url);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  useEffect(() => {
    getBePrevewUrl();
  }, []);

  // ManageUrl Data Get
  const getManageData = async () => {
    setLoader(true);
    try {
      const mngUrlDataRes = await beApi.get(`${manageUrl}/${company_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (mngUrlDataRes.data.status === 1) {
        setWebsiteUrl(mngUrlDataRes.data.data.home_url);
        setSubDomainUrl(mngUrlDataRes.data.data.subdomain_name.split(".")[0]);
        if (
          mngUrlDataRes?.data?.data?.subdomain_name
            ?.toLowerCase()
            ?.includes("bookingjini.com")
        ) {
          setUrlType("Bookingjini-URL");
        } else {
          setUrlType("Custom-URL");
        }

        setLoader(false);
      } else {
        toast(`${mngUrlDataRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    getManageData();
  }, []);

  // Theme Data Post
  const mngUrlSaveHandler = async () => {
    setLoader(true);
    try {
      let postRes = await beApi.post(
        updateUrl,
        {
          home_url: webSiteUrl,
          subdomain_name: `${subDomainUrl}${subDomain}`,
          company_id: company_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (postRes.data.status === 1) {
        toast(postRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getManageData();
        getBePrevewUrl();
        setLoader(false);
      } else {
        toast(`${postRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        getBePrevewUrl();
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };
  const urlTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setUrlType(e.target.name);
    }
  };

  const webSiteUrlHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWebsiteUrl(e.target.value);
    if (validateUrl(e.target.value) && e.target.value) {
      setUrlHelperText("");
    } else {
      setUrlHelperText("Please Enter a valid Url");
    }
  };

  useEffect(() => {
    if (urlType === "Bookingjini-URL") {
      setSubDomain(".bookingjini.com");
    } else if (urlHelperText === "") {
      const sdomain = webSiteUrl?.includes("https")
        ? webSiteUrl.split("https://")
        : webSiteUrl.split("http://");

      const dom = sdomain[1]?.split(".");
      const DOMAIN = dom?.includes("www") ? dom[1] : dom?.[0];

      setSubDomain(`.${DOMAIN}.com`);
    }
  }, [urlType, webSiteUrl]);

  return (
    <>
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : (
        <div style={{ width: "50%" }}>
          <Card>
            <InputTextField
              label="Website URL"
              helperText={urlHelperText}
              value={webSiteUrl}
              handleChange={(e) => {
                webSiteUrlHandler(e);
              }}
            />
            <div className="mb-4">
              <div className="checkbox_container mng-url-chkbox">
                <div className="form-check mng-url-chkbox  ">
                  <input
                    className="form-check-input"
                    style={{ height: "1.5rem", width: "1.5rem" }}
                    type="checkbox"
                    value=""
                    checked={urlType === "Bookingjini-URL"}
                    name="Bookingjini-URL"
                    id="setDefault1"
                    onChange={(e) => {
                      urlTypeHandler(e);
                    }}
                  ></input>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    BookingJini URL
                  </label>
                </div>
                <div className="form-check mng-url-chkbox ">
                  <input
                    className="form-check-input"
                    style={{ height: "1.5rem", width: "1.5rem" }}
                    type="checkbox"
                    value=""
                    checked={urlType === "Custom-URL"}
                    name="Custom-URL"
                    id="setDefault2"
                    onChange={(e) => {
                      urlTypeHandler(e);
                    }}
                  ></input>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Custom URL
                  </label>
                </div>
              </div>
            </div>
            <div className="bkng-eng-url">
              <InputTextField
                label={`${urlType}`}
                value={subDomainUrl}
                handleChange={(e) => {
                  setSubDomainUrl(e.target.value);
                }}
              />
              {webSiteUrl ? subDomain : ""}
            </div>
            <div>
              <p className="imp-notes">Important Notes</p>
              <p>
                In order use custom booking engine URL please create an{" "}
                <strong>A</strong> record in your domain management panel and
                point it to <strong>52.22.222.45</strong>
              </p>
            </div>
          </Card>
          <div className="save-btn mt-4 pt-2">
            <Button
              handleClick={() => {
                mngUrlSaveHandler();
              }}
              isAccessible={access}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageURLsSettings;
