import React, { useState } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

//import other add rate component
import RoomPriceDetails from "./RoomPriceDetails";
import RoomRatePlan from "./RoomRatePlan";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";

const RoomTypePrice = () => {

  const { property_data } = useSelector((state: RootState) => state.properties)

  const [showRatePlans, setShowRatePlans] = useState(false);

  return (
    <>
      <div className="div-page-wrapper">
        <div className="div__wrapper">
          <LeftSideNav>
            <>
              <div className="div__user__testimonials__wrapper">
                <div className="screen__progress__wrapper">
                  <div className="progress__status__wrapper">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">Create {property_data.data.length === 1 ? "First" : ""} Property</div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">Select Subscription Plan</div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper">
                      <i className="bi bi-play-fill progress--icon--play"></i>
                    </div>
                    <div className="status__label">Create Room Type</div>
                  </div>
                  <div className="progress__sub__list__wrapper">
                    <div className="progress__sub__listitems">
                      Enter Room Deails
                    </div>
                    <div className="progress__sub__listitems">
                      Define Occupancy
                    </div>
                    <div className="progress__sub__listitems progress--sub--item--text">
                      &nbsp;- Add Rates
                    </div>
                    <div className="progress__sub__listitems">
                      Upload Images
                    </div>
                  </div>
                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper"></div>
                    <div className="status__label">Create Floors</div>
                  </div>
                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper"></div>
                    <div className="status__label">Add Rooms</div>
                  </div>
                </div>
              </div>

              <div className="progress-bar-wrapper">
                <div className="progress-bar-text">3/4</div>
                <div className="progress-bar-status">
                  <ProgressBar percent={75} filledBackground="#FFFFFF" />
                </div>
              </div>
            </>
          </LeftSideNav>

          <RightSideContent>
            <>
              <CurrentPropertyLabel />
              <div className="div--head--label">Add Room Rates</div>
              <div className="div--helper--text">Add details below</div>

              {showRatePlans ?
                <RoomRatePlan onBack={() => setShowRatePlans(false)} />
                :
                <RoomPriceDetails onNext={() => setShowRatePlans(true)} />
              }
            </>
          </RightSideContent>
        </div>
      </div>
    </>
  );
};

export default RoomTypePrice;
