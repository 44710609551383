import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import endPoints from "../../../API/endPoints";
import kernelApi from "../../../API/kernelApi";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import InputSelectField from "../../../views/inputtextfield/InputSelectField";
import InputTextField from "../../../views/inputtextfield/InputTextField";

export interface IEditRatePlanProps {
  onClose: () => void;
  planId: string;
  editPlanData: any;
  allPlanTypeOptn: any[];
  fetchRatePlan: () => void;
}

const EditRatePlan: React.FC<IEditRatePlanProps> = ({
  onClose,
  planId,
  editPlanData,
  allPlanTypeOptn,
  fetchRatePlan,
}) => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  // endPoints Imports
  const {
    ratePlanUpdation
  } = endPoints.PROPERTY_RATE_PLAN;

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedRatePlan, setSelectedRatePlan] = useState("");
  const [ratePlanName, setRatePlanName] = useState("");

  //   const allRatePlans = ["EP", "CP", "MAP", "AP", "OTHERS"];

  useEffect(() => {
    const { plan_type, plan_name } = editPlanData ?? {};
    setRatePlanName(plan_name);
    setSelectedRatePlan(plan_type);
  }, [editPlanData]);

  //   Rate Plan Data Post For Creation
  const saveRatePlanHandler = async () => {
    setLoader(true);
    try {
      let createRateReq = await kernelApi.post(
        `${ratePlanUpdation}${planId}`,
        {
          hotel_id: current_property.hotel_id ?? " ",
          plan_type: selectedRatePlan ?? " ",
          plan_name: ratePlanName ?? "",
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (createRateReq?.data?.status === 1) {
        toast(createRateReq?.data?.message, {
          hideProgressBar: true,
          type: "success",
        });
        fetchRatePlan();
        onClose();
        setLoader(false);
      } else {
        toast(createRateReq?.data?.message, {
          hideProgressBar: true,
          type: "error",
        });
        fetchRatePlan();
        onClose();
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      onClose();
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit Rate Plan</div>
      </div>
      <div className="height-64px"></div>
      <div className="slider-content-wrapper">
        <div className="mb-4">
          <InputSelectField
            label={"Plan Type"}
            options={allPlanTypeOptn}
            // values={allRoomOptn?.room_ids}
            value={selectedRatePlan}
            handleChange={(e) => {
              setSelectedRatePlan(e.target.value);
            }}
          />
        </div>

        <div className="mb-2">
          <InputTextField
            label={"Plan Name"}
            value={ratePlanName}
            handleChange={(e) => {
              setRatePlanName(e.target.value);
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <Button
          handleClick={() => {
            saveRatePlanHandler();
          }}
          loader={loader}
        >
          Save
        </Button>
        <Button className="cancel__btn " handleClick={onClose}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default EditRatePlan;
