import React, { useEffect, useState } from "react";
import { UpdateSidebar } from "../UtilityFunctions";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import LearningImage from "../assets/img/learning__bg__image.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import ConfirmedBookings from "./DashboardData/ConfirmedBookings";
import RoomNights from "./DashboardData/RoomNights";
import FrontOfficeData from "./DashboardData/FrontOfficeData";
import RoomNightsRevenueData from "./DashboardData/RoomNightsRevenueData";
import RoomTypesComparison from "./DashboardData/RoomTypesComparison";
import { useNavigate } from "react-router-dom";
import kernelApi from "../API/kernelApi";
import { SavePropertyInfo } from "../redux/actions/PropertiesAction";
import beApi from "../API/beApi";
import { nanoid } from "@reduxjs/toolkit";
import PreviewBox from "../components/previewBox/PreviewBox";
import { Tooltip } from "@mui/material";
import ReactTooltip from "react-tooltip";
import {
  HidePreviewBox,
  ShowPreviewBox,
} from "../redux/actions/PreviewBoxVisibilityAction";
import DataLoader from "../views/loader/DataLoader";
import moment from "moment";
import RecentBookings from "./DashboardData/RecentBookings";
import LineChart from "./DashboardData/LineChart";
import endPoints from "../API/endPoints";
import { handleSingleSignOn } from "../redux/actions/LoginAction";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  UpdateSidebar("Dashboard");

  // const [recentBookingsData, setRecentBookingsData] = useState([])
  const [dataFetched, setDataFetched] = useState<any>("unset");
  const [defaultMsg, setDefaultMsg] = useState({
    msg: "No recent bookings data available",
  });

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const { isPlanSubscribed, renewSubscription, selectProperty, localeDetails } =
    endPoints.DASHBOARD;

  useEffect(() => {
    checkHotelSubscription();
    getPropertyDetails();

    dispatch(handleSingleSignOn(1));

    if (!current_property) {
      navigate(selectProperty);
    }
  }, [current_property]);

  const checkHotelSubscription = async () => {
    try {
      const response = await kernelApi.get(
        `${isPlanSubscribed}/${current_property.hotel_id}`
      );
      if (response.data.status === 0) {
        navigate(renewSubscription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get the details of currency
  const getPropertyDetails = async () => {
    try {
      const response = await kernelApi.get(
        `${localeDetails}/${current_property.hotel_id}`
      );
      if (response.data.status === 1) {
        dispatch(SavePropertyInfo(response.data.states));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // -----------------------Recent Bookings---------------------------

  // const getRecentBookingsData = async () => {
  //   try {
  //     const Response = await beApi.get(`booking-details/${current_property.hotel_id}`)

  //     if (Response.data.status === 1) {
  //       setDataFetched(1)
  //       setRecentBookingsData(Response.data.data)
  //     }
  //     else if (Response.data.status === 0) {
  //       setDataFetched(0)
  //       setDefaultMsg({ msg: Response.data.message })
  //     }
  //   }
  //   catch {
  //     setDataFetched(-1)
  //     setDefaultMsg({ msg: 'Unable to fetch recent bookings details' })
  //   }
  // }

  // useEffect(() => {
  //   getRecentBookingsData()
  // }, [current_property.hotel_id])

  // const RECENT_BOOKINGS_MAP =
  //   recentBookingsData.map(obj => {
  //     const {
  //       channel_name,
  //       checkin_at,
  //       checkout_at,
  //       customer_name,
  //       customer_phone,
  //       paid_amount,
  //       logo,
  //       display_booking_date,
  //       booking_days
  //     } = obj

  //     const checkIn = (moment(checkin_at).format('MMMM Do'))
  //     const checkOut = (moment(checkout_at).format('MMMM Do'))

  //     const key = nanoid()

  //     return (
  //       <div className="someBox" style={{ position: "relative" }} key={key}>
  //         <div className="left">
  //           <div className="booking-date">
  //             <p>{display_booking_date}</p>
  //             <small>{booking_days}</small>
  //           </div>

  //           <div className="customerName">
  //             <i className="bi bi-person"></i>
  //             <p>{customer_name}</p>
  //           </div>
  //           <div className="customerPhone">
  //             <i className="bi bi-telephone"></i>
  //             <p><strong>{customer_phone}</strong></p>
  //           </div>

  //           <div className="checkin-checkout">
  //             <i className="bi bi-calendar-week"></i>
  //             <p>{`${checkIn} - ${checkOut}`}</p>
  //           </div>
  //         </div>

  //         <div className="right">
  //           <div className="channel_name">
  //             <img src={logo} alt="logo" />
  //           </div>
  //           <div className="paid-amt">
  //             <p>Rs.{paid_amount ? paid_amount : 0}</p>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   })

  // console.log(RecentBookings())

  const RECENT_BOOKINGS = RecentBookings(
    setDataFetched,
    setDefaultMsg,
    defaultMsg
  );

  // -----------------------Recent Bookings---------------------------

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
      </div>

      <div className="hr--line"></div>

      <div className="dashboard__main__content__wrapper">
        <div className="dashboard__confirmed__bookings__wrapper">
          <ConfirmedBookings />
        </div>
      </div>

      <div className="dashboard__main__content__wrapper">
        <div className="dashboard__left__content mb-5 sm-r">
          <div>
            <RoomNights />
          </div>

          <div>
            <RoomNightsRevenueData />
          </div>

          <div>
            <RoomTypesComparison />
          </div>
          {/* <div className="mt-5">
            <LineChart />
          </div> */}
        </div>

        <div className="dashboard__right__content mb-5">
          <div className="dashboard__front__office__wrapper">
            <FrontOfficeData />
          </div>

          <div className="dashboard__recent__activity__wrapper">
            {/* -------------------------------------- */}
            <div className="dashboard__standard__label mb-4">
              Recent Bookings
            </div>

            <div className="dashboard__recent__activity__log">
              <div className="recent__log__wrapper">
                {/* --------------------RECENT_BOOKINGS------------------- */}
                {
                  // dataFetched === 1 ?
                  //   RECENT_BOOKINGS_MAP.length > 0 ? RECENT_BOOKINGS_MAP : <div style={{ color: "#a1a1a1" }}>{defaultMsg.msg}</div>
                  //   :
                  //   dataFetched === 'unset' ? <DataLoader /> : <div style={{ color: "#a1a1a1" }}>{defaultMsg.msg}</div>

                  dataFetched === 1 ? (
                    RECENT_BOOKINGS
                  ) : dataFetched === "unset" ? (
                    <DataLoader />
                  ) : (
                    <div className="emptyBodyContainer">{defaultMsg.msg}</div>
                  )
                }
                {/* --------------------RECENT_BOOKINGS------------------- */}
              </div>
            </div>
          </div>

          <div className="dashboard__learning__wrapper my-4">
            <div className="dashboard__learning__header mb-2">
              <div className="bookingjini__logo me-3"></div>
              <div className="learning__heading__wrapper">
                <div className="learning__main__heading">
                  Bookingjini Learning
                </div>
                <div className="learning__sub__heading">
                  Hotel Tips, Learnings & Trends
                </div>
              </div>
            </div>

            <div className="learning__text mb-3">
              Are you doing enough to draw in families to your hotel? How are
              you able to promote your hotel to face out from the noise on the
              online and be a true family-friendly contender?
            </div>
            <div className="learning__image__bg">
              <img src={LearningImage} alt="Learning Image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
