import {
  ACTIVE_SIDEBAR_ITEM,
  ACTIVE_SIDEBAR_SUBITEM,
  UPDATE_BREADCRUMBS,
  UPDATE_INV_BE_LOADER,
  UPDATE_INV_BE_LOADER_DISABLE,
  UPDATE_INV_CM_LOADER,
  UPDATE_INV_CM_LOADER_DISABLE,
} from "../actionTypes";
import Sidebar_Menu from "../../components/sidebar/SidebarMenu.json";

const initialState = {
  active_sidebar_item: Sidebar_Menu[0].label,
  active_sidebar_subitem: "",
  breadcrumbs: [],
  // breadcrumps: [],

  bulk_cm_loader: false,
  bulk_be_loader: false,
};

const SidebarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        active_sidebar_item: action.payload,
      };
    case ACTIVE_SIDEBAR_SUBITEM:
      return {
        ...state,
        active_sidebar_subitem: action.payload,
      };
    case UPDATE_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case UPDATE_INV_CM_LOADER:
      return {
        ...state,
        bulk_cm_loader: true,
      };
    case UPDATE_INV_BE_LOADER:
      return {
        ...state,
        bulk_be_loader: true,
      };
    case UPDATE_INV_CM_LOADER_DISABLE:
      return {
        ...state,
        bulk_cm_loader: false,
      };
    case UPDATE_INV_BE_LOADER_DISABLE:
      return {
        ...state,
        bulk_be_loader: false,
      };

    default:
      return state;
  }
};

export default SidebarReducer;
