import { ADD_ROOM_TYPE_BASIC_DETAILS, ADD_ROOM_TYPE_EP_PRICE_UPDATE, ADD_ROOM_TYPE_IMAGES, ADD_ROOM_TYPE_MEAL_PLAN, ADD_ROOM_TYPE_OCCUPANCY, ADD_ROOM_TYPE_PRICE, ADD_ROOM_TYPE_RESET } from "../actionTypes"

export const AddRoomTypeBasicDetails = (value: any) => {
    return {
        type: ADD_ROOM_TYPE_BASIC_DETAILS,
        payload: value
    }
}

export const AddRoomTypeOccupancy = (value: any) => {
    return {
        type: ADD_ROOM_TYPE_OCCUPANCY,
        payload: value
    }
}

export const AddRoomTypePrice = (value: any) => {
    return {
        type: ADD_ROOM_TYPE_PRICE,
        payload: value
    }
}

export const AddRoomTypeEPprice = (value: string) => {
    return {
        type: ADD_ROOM_TYPE_EP_PRICE_UPDATE,
        payload: value
    }
}

export const AddRoomTypeMealPlan = (value: any) => {
    return {
        type: ADD_ROOM_TYPE_MEAL_PLAN,
        payload: value
    }
}

export const AddRoomTypeImages = (value: any) => {
    return {
        type: ADD_ROOM_TYPE_IMAGES,
        payload: value
    }
}

export const ResetRoomType = () => {
    return {
        type: ADD_ROOM_TYPE_RESET
    }
}
