import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../views/buttons/Button";
import Loader from "../../views/loader/Loader";
import { CATCH_MESSAGE, Logout, UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";

const SetupSeasonalPLan = () => {
  const dispatch = useDispatch();
  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  // useState
  const [loader, setLoader] = useState<any>(true);
  const [allRatePlans, setAllRatePlans] = useState<any>([]);
  const [modifiedRatePlans, setModifiedRatePlans] = useState<any>([]);
  const [allMaxOccupancy, setAllMaxOccupancy] = useState([]);
  const [maxOccupancy, setMaxOccupancy] = useState<any>();
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  const navigate = useNavigate();
  const { roomTypeId, seasonalPlanId } = useParams();
  UpdateSidebar("Property Setup", `Seasonal Plan > Plan Setup (${roomTypeId})`);

  // API call to retrieve all rate plans associated with the room type
  const ratePlansCall = async (roomTypeId: any, seasonalPlanId: any) => {
    try {
      let response = await kernelApi.get(
        `/select-all-seasonal-rate/${current_property?.hotel_id}/${roomTypeId}/${seasonalPlanId}`,
        {
          headers: {
            Authorization: auth_token,
          },
        }
      );
      if (response.data.status === 1) {
        let maxPeopleArr: any = [];
        let maxPeopleArrCount : any = [];
        if (response.data.Data.length > 0) {
          setLoader(false);
          if (response.data.Data[0]?.max_people) {
            setMaxOccupancy(response.data.Data[0]?.max_people);
            let n = response.data.Data[0]?.max_people;
            for (let i = 0; i < n - 1; i++) {
              maxPeopleArr.push(0);
            }
            for (let i = 0; i < n - 1; i++) {
              maxPeopleArrCount.push(i + 1);
            }
            let noDataArr = response.data.Data;
            let newArr : any = [];
            noDataArr.map((plan : {}) => {
              plan['multiple_occupancy'] = [...maxPeopleArr];
              plan['bar_price'] = 0;
              plan['extra_adult_price'] = 0;
              plan['extra_child_price'] = 0;
              plan['los'] = 0;
              newArr.push(plan);
            })

            setMaxOccupancy(n);
            setAllRatePlans(newArr);
            setAllMaxOccupancy(maxPeopleArrCount);
            
          } else {
            maxPeopleArr = response.data.Data[0]?.multiple_occupancy;
            let m = maxPeopleArr.length;
            setMaxOccupancy(m + 1);
            setAllRatePlans(response.data.Data);
            checkBlankStatus(response.data.Data);
            for (let i = 0; i < m; i++) {
              maxPeopleArrCount.push(i + 1);
            }
            setAllMaxOccupancy(maxPeopleArrCount);
          }          
        } else {
          setLoader(false);
          setAllRatePlans([]);
        }
      }
    } catch {
      setLoader(false);
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    ratePlansCall(roomTypeId, seasonalPlanId);
  }, []);

  useEffect(() => {
    setAllRatePlans(modifiedRatePlans);
  }, [modifiedRatePlans]);

  // construction of Rate plan Payload from allRatePLans and modifiedRatePlans
  let rate_plan_payload = {
    hotel_id: current_property.hotel_id,
    room_type_id: roomTypeId,
    seasonal_plan_id: seasonalPlanId,
    rate_plan: [...allRatePlans],
  };

  // Handler function to save all the rate plans
  const saveRatePlans = async () => {
    let blankStatus = checkBlankStatus(rate_plan_payload.rate_plan)
    if (!blankStatus) {
      setSaveLoader(true);
      try {
        let response = await kernelApi.post(
          `/add-seasonal-rateplan`,
          rate_plan_payload,
          {
            headers: {
              Authorization: auth_token,
            },
          }
        );
        if (response.data.status ===1) {
          toast(`${response.data.message}`, {
            hideProgressBar: true,
            type: "success",
          });
          setSaveLoader(false);
        } else {
          toast(`${response.data.message}`, {
            hideProgressBar: true,
            type: "error",
          });
          setSaveLoader(false);
        }
      } catch (error : any) {
        toast.error(CATCH_MESSAGE);
        setSaveLoader(false);
        ratePlansCall(roomTypeId, seasonalPlanId);
        if (error.response.status === 400) {
          Logout();
        }
      }
    } else {
      toast("Bar-price & LOS can't be 0!", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // Handler function to block/unblock the rate plan
  const blockUnblockPlan = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    let activeInactiveStatus = e.target.checked;
    let alteredStatus = activeInactiveStatus ? 1 : 0;
    let plan = item;
    let index = allRatePlans.findIndex((arp:any) => arp.rate_plan_id===plan.rate_plan_id)
    let modifiedRatePlansWithStatus = [...allRatePlans];
    modifiedRatePlansWithStatus[index].block_status = alteredStatus;
    setAllRatePlans(modifiedRatePlansWithStatus);
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to ${
          activeInactiveStatus ? "unlock" : "block"
        } this ?`,
        "DELETE",
        async () => {  
          try {
            let activeInactiveRes = await kernelApi.post(
              `/add-seasonal-rateplan`,
              rate_plan_payload,
              {
                headers: {
                  Authorization: auth_token,
                },
              }
            );
            if (activeInactiveRes.data.status === 1) {
              toast.success(`${activeInactiveRes.data.message}`);
              ratePlansCall(roomTypeId, seasonalPlanId);
            } else {
              toast.error(`${activeInactiveRes.data.message}`);
              ratePlansCall(roomTypeId, seasonalPlanId);
            }
          } catch (error: any) {
            toast.error(CATCH_MESSAGE);
            setLoader(false);
            ratePlansCall(roomTypeId, seasonalPlanId);
            if (error.response.status === 400) {
              Logout();
            }
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  }

  // Handler function to check blank field
  const checkBlankStatus = (allRate : any) => {
    let conditional = true;
    for (let i=0; i < allRate.length; i++) {
      if (parseInt(allRate[i].block_status) === 0) {
        if (parseInt(allRate[i].bar_price) && parseInt(allRate[i].los)) {
          conditional = false;
        } else {
          conditional = true;
          break;
        }
      } 
    }
    return conditional;
  }

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <div className="add__floor__btn_container">
            <Button
              icon="bi bi-check"
              className="new__bookings"
              handleClick={() => saveRatePlans()}
              loader={saveLoader}
            >
              save
            </Button>
          </div>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {loader ? (
        <div className="ineventory--loader">
          <Loader />
        </div>
      ) : (
        <div className="seasonal__rate__plan__container">
          {allRatePlans?.map((plan : any) => {
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              let Id : any = e.target.id;
              let first = e.target.value;
              let index = allRatePlans.findIndex((arp:any) => arp.rate_plan_id === plan.rate_plan_id);
              let modifiedRatePlan = [...allRatePlans];
              if (Id.includes("dynamicRow")) {
                let index2 = Id.split("_").pop();
                modifiedRatePlan[index].multiple_occupancy[index2] = first;
              } else {
                modifiedRatePlan[index][Id] = first;
              }
              setModifiedRatePlans(modifiedRatePlan);
            };
            return (
              <div
                className="rate__plan__box"
                id={`rate__plan__box-${plan.rate_plan_id}`}
              >
                <div className={`rate__plan__box__heading ${plan.block_status ? "blocked": ""}`}>
                  <div className="rate__plan__heading">
                    <i
                      className="bi bi-cup-straw"
                      style={{ marginRight: "10px" }}
                    ></i>
                    <div>{plan?.plan_type}</div>
                  </div>

                  <div className="toggle__block">
                    <div style={{ marginRight: "10px" }}>Block</div>
                    <div className="form-check form-switch custom-switch check--box--width">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={plan?.block_status}
                        id="flexCheckDefault"
                        onChange={(e) => blockUnblockPlan(e, plan)} 
                      />
                    </div>
                  </div>
                </div>
                <div className="rate__plan__box__content">
                  {/* --------xn-1---DYNAMIC INPUTS MAP------------ */}
                  {allMaxOccupancy?.map((occupancy) => (
                    <div className="content__row">
                      <div className={`${plan.block_status ? "content__row__head__blocked" : "content__row__head"}`}>
                        <i className="bi bi-person"></i>
                        <div>x {occupancy}</div>
                      </div>
                      <div className="content__row__body">
                      {plan.block_status ?
                        <input
                          type="number"
                          value={
                            plan?.multiple_occupancy
                            ? parseInt(plan?.multiple_occupancy[occupancy - 1]) 
                            : 0
                          }
                          id={`dynamicRow_${occupancy - 1}`}
                          onChange={handleChange}
                          readOnly
                        /> :
                        <input
                          type="number"
                          value={
                            plan?.multiple_occupancy
                            ? parseInt(plan?.multiple_occupancy[occupancy - 1]) 
                            : 0
                          }
                          id={`dynamicRow_${occupancy - 1}`}
                          onChange={handleChange}
                        /> 
                      }
                      </div>
                    </div>
                  ))}

                  {/* --------------------x n-th---STATIC INPUTS---------------- */}
                  <div className="content__row">
                    <div className={`${plan.block_status ? "content__row__head__blocked" : "content__row__head"}`}>
                      <i className="bi bi-person"></i>
                      <div>x {maxOccupancy}</div>
                    </div>
                    <div className="content__row__body">
                      {plan.block_status ? 
                      <input
                        type="number"
                        value={parseInt(plan?.bar_price !== undefined ? plan?.bar_price : 0)}
                        id={`bar_price`}
                        onChange={handleChange} 
                        readOnly
                      /> : 
                      <input
                        type="number"
                        value={parseInt(plan?.bar_price !== undefined ? plan?.bar_price : 0)}
                        id={`bar_price`}
                        onChange={handleChange} 
                      />}                      
                    </div>
                  </div>

                  {/* --------------------+ 1A------------------- */}
                  <div className="content__row">
                    <div className={`${plan.block_status ? "content__row__head__blocked" : "content__row__head"}`}>
                      <i className="bi bi-person"></i>
                      <div>+ 1A</div>
                    </div>
                    <div className="content__row__body">
                      {plan.block_status ? 
                      <input
                        type="number"
                        value={
                          parseInt(plan?.extra_adult_price !== undefined ? plan?.extra_adult_price : 0)       
                        }
                        id={`extra_adult_price`}
                        onChange={handleChange}
                        readOnly
                      /> : 
                      <input
                        type="number"
                        value={
                          parseInt(plan?.extra_adult_price !== undefined ? plan?.extra_adult_price : 0)       
                        }
                        id={`extra_adult_price`}
                        onChange={handleChange}
                      />}
                    </div>
                  </div>

                  {/* ---------------------+ 1C------------------- */}
                  <div className="content__row">
                    <div className={`${plan.block_status ? "content__row__head__blocked" : "content__row__head"}`}>
                      <i className="bi bi-person"></i>
                      <div>+ 1C</div>
                    </div>
                    <div className="content__row__body">
                      {plan.block_status ? 
                      <input
                        type="number"
                        value={
                          parseInt(plan?.extra_child_price !== undefined ? plan?.extra_child_price : 0)                    
                        }
                        id={`extra_child_price`}
                        onChange={handleChange}
                        readOnly
                      /> : 
                      <input
                        type="number"
                        value={
                          parseInt(plan?.extra_child_price !== undefined ? plan?.extra_child_price : 0)                    
                        }
                        id={`extra_child_price`}
                        onChange={handleChange}
                      />}
                    </div>
                  </div>

                  {/* -----------------------LOS--------------------- */}
                  <div className="content__row">
                    <div className={`${plan.block_status ? "content__row__head__blocked" : "content__row__head"}`}>
                      <i className="bi bi-person"></i>
                      <div>LOS</div>
                    </div>
                    <div className="content__row__body">
                      {plan.block_status ? 
                        <input type="number" value={parseInt(plan?.los !== undefined ? plan?.los : 0)} id={`los`} onChange={handleChange} readOnly/>
                      : <input type="number" value={parseInt(plan?.los !== undefined ? plan?.los : 0)} id={`los`} onChange={handleChange}/>}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SetupSeasonalPLan;
