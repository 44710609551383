import { DASHBOARD_HOTILER_DATA_TODAY,DASHBOARD_HOTILER_DATA_MTD,DASHBOARD_HOTILER_DATA_YTD } from "../actionTypes";

export const TodayHotilerData = (res: any) => {
  return {
    type: DASHBOARD_HOTILER_DATA_TODAY,
    payload: res,
  };
};
export const MTDHotilerData = (res: any) => {
    return {
      type: DASHBOARD_HOTILER_DATA_MTD,
      payload: res,
    };
  };
  export const YTDHotilerData = (res: any) => {
    return {
      type: DASHBOARD_HOTILER_DATA_YTD,
      payload: res,
    };
  };