import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProgressBar } from "react-step-progress-bar";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";
import ImageUploadArea from "../../components/property/propertyimageupload/ImageUploadArea";
import ImageViewArea from "../../components/property/propertyimageupload/ImageViewArea";

//import buttons
import NextButton from "../../views/buttons/NextButton";
import BackButton from "../../views/buttons/Button";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  AddRoomTypeImages,
  ResetRoomType,
} from "../../redux/actions/AddRoomTypeAction";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";

const RoomTypeImages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property, property_data } = useSelector(
    (state: RootState) => state.properties
  );
  const { basic_details, occupancy, price, meal_plan } = useSelector(
    (state: RootState) => state.add_room_type
  );

  const [images, setImages] = useState<any[]>(
    useSelector((state: RootState) => state.add_room_type.images)
  );

  const UploadImages = () => {
    document.getElementById("image-input")?.click();
  };

  const SaveImages = (event: any) => {
    let roomimages = [...images];
    for (let file = 0; event.target.files[file]; file++) {
      let image = event.target.files[file];
      if (image.size < 1024 * 1024) {
        roomimages.push(image);
      } else {
        toast("Image size should be less than 1MB", {
          hideProgressBar: true,
          type: "error",
        });
      }
      setImages(roomimages.slice(0, 7));
    }
  };

  const RemoveImage = (index: number) => {
    let img = [...images];
    img.splice(index, 1);
    setImages(img);
  };

  const BackButtonClick = () => {
    navigate("/add-room-type/room-type-price");
  };

  const SubmitRoomTypeImages = () => {
    if (images.length > 0) {
      dispatch(AddRoomTypeImages(images));
      setTimeout(() => {
        CreateNewRoomType();
      }, 500);
    } else {
      toast("Please select atleast one image!", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const {
    ratePlanCreation
  } = endPoints.PROPERTY_RATE_PLAN;

  const {
    createNewRoomTypeApi
  } = endPoints.PROPERTY_ROOM_TYPE;

  const CreateNewRoomType = async () => {
    let payload = new FormData();
    payload.append("hotel_id", current_property.hotel_id);
    payload.append("room_type", basic_details.room_type_name);
    payload.append("total_rooms", basic_details.number_of_rooms);
    payload.append("room_type_shortcode", basic_details.room_type_shortcut);
    payload.append("max_occupancy", occupancy.max_occupancy);
    payload.append("max_adult", occupancy.max_adult);
    payload.append("max_child", occupancy.max_child);
    payload.append("base_adult", occupancy.base_adult);
    payload.append("base_child", occupancy.base_child);
    payload.append("max_room_price", price.max_room_price);
    payload.append("min_room_price", price.min_room_price);

    // payload.append("extra_adult_price", price.extra_adult_price);
    // payload.append("extra_child_price", price.extra_child_price);
    payload.append("bar_price_ep", meal_plan.ep_price);
    payload.append("bar_price_cp", meal_plan.cp_price);
    payload.append("bar_price_map", meal_plan.map_price);
    payload.append("bar_price_ap", meal_plan.ap_price);
    images.forEach((image: any) => {
      payload.append("images[]", image);
    });

    if (meal_plan.ep_price) {
      createRatePlan("EP", "Room Only Rate");
    }
    if (meal_plan.cp_price) {
      createRatePlan("CP", "Room + Breakfast");
    }
    if (meal_plan.map_price) {
      createRatePlan("MAP", "Room + Breakfast + Lunch / Dinner");
    }
    if (meal_plan.ap_price) {
      createRatePlan("MAP", "Room + Breakfast + Lunch + Dinner");
    }

    try {
      let response = await kernelApi.post(
        `${createNewRoomTypeApi}`,
        payload
      );
      if (response.data.status === 1) {
        dispatch(ResetRoomType());
        setTimeout(() => {
          navigate("/add-room-type/success");
        }, 200);
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error)
    }
  };

  // Create Rate Plan
  const createRatePlan = async (planType: string, planName: string) => {
    try {
      let createRateReq = await kernelApi.post(
        `${ratePlanCreation}`,
        {
          hotel_id: current_property.hotel_id ?? " ",
          plan_type: planType ?? " ",
          plan_name: planName ?? "",
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <LeftSideNav>
          <>
            <div className="div__user__testimonials__wrapper">
              <div className="screen__progress__wrapper">
                <div className="progress__status__wrapper">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">
                    Create {property_data.data.length === 1 ? "First" : ""}{" "}
                    Property
                  </div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper bg-white">
                    <i className="bi bi-check progress--icon--check"></i>
                  </div>
                  <div className="status__label">Select Subscription Plan</div>
                </div>

                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper">
                    <i className="bi bi-play-fill progress--icon--play"></i>
                  </div>
                  <div className="status__label">Create Room Type</div>
                </div>
                <div className="progress__sub__list__wrapper">
                  <div className="progress__sub__listitems">
                    Enter Room Deails
                  </div>
                  <div className="progress__sub__listitems">
                    Define Occupancy
                  </div>
                  <div className="progress__sub__listitems">Add Rates</div>
                  <div className="progress__sub__listitems progress--sub--item--text">
                    &nbsp;- Upload Images
                  </div>
                </div>
                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper"></div>
                  <div className="status__label">Create Floors</div>
                </div>
                <div className="progress__status__wrapper mt-5">
                  <div className="status__icon__wrapper"></div>
                  <div className="status__label">Add Rooms</div>
                </div>
              </div>
            </div>

            <div className="progress-bar-wrapper">
              <div className="progress-bar-text">4/4</div>
              <div className="progress-bar-status">
                <ProgressBar percent={100} filledBackground="#FFFFFF" />
              </div>
            </div>
          </>
        </LeftSideNav>

        <RightSideContent>
          <>
            <div className="div__amenities__container">
              <CurrentPropertyLabel />
              <div className="div--head--label">Upload room images 📸</div>
              <div className="div--helper--text">
                You can select upto 7 Images
              </div>

              <div className="div_image_upload">
                {images.length === 0 ? (
                  <ImageUploadArea upload={UploadImages}></ImageUploadArea>
                ) : (
                  <ImageViewArea
                    images={images}
                    RemoveImage={RemoveImage}
                    upload={UploadImages}
                  ></ImageViewArea>
                )}
              </div>
              <input
                id="image-input"
                name="image-input"
                type="file"
                accept="image/png, image/jpeg"
                className="d-none"
                multiple
                onChange={SaveImages}
              />
            </div>
            <div className="__btn__wrapper mt-5">
              <BackButton className="back__btn" handleClick={BackButtonClick}>
                Back
              </BackButton>
              <NextButton label="Submit" handleClick={SubmitRoomTypeImages} />
            </div>
          </>
        </RightSideContent>
      </div>
    </div>
  );
};

export default RoomTypeImages;
