import React, { useEffect, useRef, useState } from "react";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import Button from "../../../../views/buttons/Button";
import NewLinkButton from "../../../../views/buttons/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import SlidingPane from "react-sliding-pane";
import Loader from "../../../../views/loader/Loader";
import AddNewLink from "./AddNewLink";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, Logout } from "../../../../UtilityFunctions";
import beApi from "../../../../API/beApi";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import ViewPayment from "./ViewPayment";
import endPoints from "../../../../API/endPoints";
import kernelApi from "../../../../API/kernelApi";
import ReactTooltip from "react-tooltip";

const QuickPaymentLink = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token, company_id, full_name } = useSelector(
    (state: RootState) => state.auth
  );
  const { be_url } = useSelector((state: RootState) => state.beUrl);
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  //endPoints import
  const {
    quickPaymentAll,
    quickPaymentResendEmail,
    quickPaymentCheckEndPoint,
    userRegisterEndPoint,
    beBookingsEndPoint,
    quickPaymentLinkEndPoint,
    getCompanyProfile,
  } = endPoints.MANAGECHANNEL;
  // states
  const [openAddNewLink, setOpenAddNewLink] = useState<boolean>(false);
  const [openViewPayment, setOpenViewPayment] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [access, setAccess] = useState(true);
  const [nodataMessage, setNoDataMessage] = useState<string>("N0 Data Found");
  const pageSize = [10, 15, 20, 25, 30];
  const [allQuickPaymentLink, setAllQuickPaymentLink] = useState<any>([]);
  const [specificPayment, setSpecificPayment] = useState<any>([]);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [companyUrl, setCompanyUrl] = useState<any>();
  const [companyApiKey, setCompanyApiKey] = useState<any>();
  const [checkLoader, setCheckLoader] = useState<boolean>(false);
  const [selectedCheckItem, setSelectedCheckItem] = useState<any>();
  // const textAreaRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };
  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  useEffect(() => {
    if (allQuickPaymentLink?.length > 0) {
      setNoOfPages(Math.ceil(allQuickPaymentLink?.length / selectPageSize));
    }
  }, [selectPageSize, allQuickPaymentLink]);

  //   API call to retieve all the Quick Payment Links Handler
  const getAllQuickPaymentLink = async () => {
    setLoader(true);
    try {
      const allQuickPaymentLinkRes = await beApi.get(
        `${quickPaymentAll}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (allQuickPaymentLinkRes.data.status === 1) {
        setAllQuickPaymentLink(allQuickPaymentLinkRes.data.data);
        setTimeout(() => setLoader(false), 1000);
      } else {
        setAllQuickPaymentLink([]);
        setTimeout(() => setLoader(false), 1000);
      }
    } catch (error: any) {
      setLoader(false);
      if (error.response.status === 401 || error.response.status === 400) {
        toast.error(error.response.message);
        Logout();
      } else {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    getAllQuickPaymentLink();
    getApiKey(current_property.company_id);
  }, []);

  useEffect(() => {
    if (openAddNewLink === false) {
      getAllQuickPaymentLink();
    }
  }, [openAddNewLink]);

  // Handler function to View a specific Payment
  const view__payment = (data: any) => {
    setSpecificPayment(data);
    setOpenViewPayment(true);
  };

  // Handler function to copy data/url to clipboard
  const copyToClipBoard = async (url: any) => {
    if ("clipboard" in navigator) {
      toast("Copied to clipboard", {
        hideProgressBar: true,
        type: "success",
      });
      return await navigator.clipboard.writeText(url);
    } else {
      toast("Copied to clipboard", {
        hideProgressBar: true,
        type: "success",
      });
      return document.execCommand("copy", true, url);
    }
  };

  // Handler function to get API Key
  // company_profile/get
  const getApiKey = async (company_id: any) => {
    try {
      let response = await kernelApi.get(`${getCompanyProfile}/${company_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      if (response.data.status === 1) {
        setCompanyUrl(response.data.data.company_url);
        setCompanyApiKey(response.data.data.api_key);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  // Handler function to check payment status
  // https://be.bookingjini.com/quick_payment/check/1862
  const checkPaymentStatus = async (
    id: any,
    email: any,
    phone: any,
    name: any,
    check_in: any,
    check_out: any
  ) => {
    setSelectedCheckItem(id);
    setCheckLoader(true);
    try {
      const res = await beApi.get(`${quickPaymentCheckEndPoint}/${id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      let nameArr = name.split(" ");
      let first__Name = nameArr[0];
      let last__Name = "";
      if (nameArr.length > 1) {
        for (let i = 1; i < nameArr.length; i++) {
          last__Name += nameArr[i] + " ";
          // lastName = " " + nameArr[i];
        }
      } else {
        last__Name = "NA";
      }

      if (res.data.status === 1) {
        const registerRes = await beApi.post(
          `${userRegisterEndPoint}`,
          {
            company_url: companyUrl,
            email_id: email,
            mobile: phone,
            first_name: first__Name,
            last_name: last__Name,
            company_name: "",
            GST_IN: "",
            address: "",
            zip_code: "",
            country: "",
            state: "",
            city: "",
            identity: "",
            identity_no: "",
            expiry_date: "",
            date_of_birth: "",
            GSTIN: "",
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (registerRes.data.status === 1) {
          let bookingPayload: any = {
            hotel_id: current_property?.hotel_id,
            from_date: check_in,
            to_date: check_out,
            coupon: [],
            cart: [
              {
                room_type: res.data?.qpdata?.room_details?.room_type,
                plan_type: res.data?.qpdata?.room_details?.plan_type,
                room_type_id: res.data?.qpdata?.room_type,
                discounted_price: 0,
                paid_amount: res.data?.qpdata?.amount,
                paid_amount_per: "",
                partial_amount_per: 0,
                rates_for_coupons: [],
                added_to_cart: true,
                add_room: false,
                rate_plan_id: res.data?.qpdata?.rate_plan,
                max_room_capacity: 1,
                max_child: res.data?.qpdata?.room_details?.max_child,
                max_people: res.data?.qpdata?.room_details?.max_people,
                extra_person: 0,
                plan_name: res.data.qpdata?.room_details?.plan_name,
                display_price: res.data?.qpdata?.amount,
                price: res.data?.qpdata?.amount,
                rooms: [],
                tax: [
                  {
                    gst_price: 0,
                    other_tax: [],
                  },
                ],
              },
            ],
            paid_service: [],
            visitors_ip: "10.10.10.10",
            reference: null,
          };
          for (
            let room_no: number = 0;
            room_no < res.data?.qpdata?.no_of_rooms;
            room_no++
          ) {
            bookingPayload.cart[0]?.rooms.push({
              room: "Room" + room_no,
              adults: [
                { option: 1, value: 1 },
                { option: 2, value: 2 },
                { option: 3, value: 3 },
              ],
              selected_adult: res.data?.qpdata?.room_details?.max_people,
              childs: [
                { option: 0, value: 0 },
                { option: 1, value: 1 },
                { option: 2, value: 2 },
              ],
              selected_child: res.data?.qpdata?.room_details?.max_child,
              rate_plan_id: res.data?.qpdata?.rate_plan,
              extra_adult_price: 0,
              extra_child_price: 0,
              bar_price: Math.round(
                res.data?.qpdata?.amount / res.data?.qpdata?.no_of_rooms
              ),
              selected_infant: 0,
            });
          }
          const bookingRes = await beApi.post(
            `${beBookingsEndPoint}/${companyApiKey}`,
            bookingPayload,
            {
              headers: {
                Authorization: "Bearer " + registerRes.data.auth_token,
              },
            }
          );
          if (bookingRes.data.status === 1) {
            const invoiceRes = await beApi.get(
              `${quickPaymentLinkEndPoint}/${bookingRes.data.invoice_id}/quickpayment`,
              {
                headers: {
                  Authorization: "Bearer " + registerRes.data.auth_token,
                },
              }
            );
            if (invoiceRes.data.status === 1) {
              toast(`${invoiceRes.data.mesage}`, {
                hideProgressBar: true,
                type: "success",
              });
              setCheckLoader(false);
              getAllQuickPaymentLink();
            } else {
              setCheckLoader(false);
              toast(`Booking Process Failed! ${invoiceRes.data.message}`, {
                hideProgressBar: true,
                type: "error",
              });
            }
          } else {
            setCheckLoader(false);
            toast(`Booking Failed! ${bookingRes.data.message}`, {
              hideProgressBar: true,
              type: "error",
            });
          }
        } else {
          setCheckLoader(false);
          toast(`Account Creation Failed! ${registerRes.data.message}`, {
            hideProgressBar: true,
            type: "error",
          });
        }
        //
      } else if (res.data.status === 0) {
        setCheckLoader(false);
        toast(`${res.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      setCheckLoader(false);
      // if (error.response.status === 400) {
      //   toast.error(error.response.message);
      //   Logout();
      // } else {
      toast(`Internal server error,Please try after some time.`, {
        hideProgressBar: true,
        type: "error",
      });
      // }
      // toast(CATCH_MESSAGE, {
      //   hideProgressBar: true,
      //   type: "error",
      // });
    }
  };

  // Handler funcition to resend email
  const resendEmail = async (id: any, txnId: any) => {
    setSelectedItem(id);
    setSaveLoader(true);
    try {
      const res = await beApi.get(`${quickPaymentResendEmail}/${id}/${txnId}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (res.data.status === 1) {
        setSaveLoader(false);
        toast(res.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        setSaveLoader(false);
        toast(res.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      setSaveLoader(false);
      // if (error?.response.status === 400) {
      //   toast.error(error.response.message);
      //   Logout();
      // } else {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      // }
    }
  };

  return (
    <div>
      {/* ------------------------ */}
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Quick Payment Link" },
          ]}
        />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <NewLinkButton
            icon="bi bi-plus-lg"
            handleClick={() => setOpenAddNewLink(true)}
            isAccessible={access}
          >
            New Link
          </NewLinkButton>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>
      {/* ------------------------ */}

      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : allQuickPaymentLink?.length > 0 ? (
        <div className="list-view-table-wrapper-quick-payment mb-4">
          <table className="table table-borderless">
            <thead>
              <tr className="listview__table__heading">
                <th>S.No</th>
                <th>Customer Name</th>
                <th>Booking Amount</th>
                <th>Payment URL</th>
                <th>Payment Gateway</th>
                <th>View</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={8}>
                    <div>
                      <Loader className="d-flex justify-content-center align-items-center" />
                    </div>
                  </td>
                </tr>
              ) : (
                allQuickPaymentLink
                  .slice(
                    selectPageSize * (currentPageNo - 1),
                    selectPageSize * currentPageNo
                  )
                  .map((item: any, idx: any) => (
                    <tr
                      className="listview__table__data__payment__link"
                      key={item.id}
                    >
                      <td>
                        <div className="serial-number">{idx + 1}</div>
                      </td>
                      <td>
                        <div
                          className="customer-details customer-name"
                        // onClick={() => {
                        //   navigate("customer-data");
                        // }}
                        >
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <div className="customer-details">{item.amount}</div>
                      </td>
                      <td>
                        <div
                          className="customer-details payment-url"
                          onClick={() => {
                            copyToClipBoard(item.url);
                          }}
                        >
                          {"Copy Payment URL"}
                          <i className="bi bi-files"></i>
                        </div>
                      </td>
                      <td>
                        <div className="customer-details">{item.pg_name}</div>
                      </td>
                      <td>
                        <div className="customer-details">
                          <h4 onClick={() => view__payment(item)}>
                            <i
                              style={{ color: `#8ab9f6` }}
                              className="bi bi-info-circle-fill"
                            ></i>
                          </h4>
                          {/* {"view"} */}
                        </div>
                      </td>
                      <td>
                        {item.payment_status === "captured" ? (
                          <div className="customer-details payment-status-success">
                            <h4
                              data-for="toolTipPaid"
                              data-tip="Paid"
                              data-place="bottom"
                            ><i className="bi bi-check"></i></h4>
                            <ReactTooltip id="toolTipPaid" />
                          </div>
                        ) : (
                          <div
                            className={`customer-details ${selectedCheckItem === item.id && checkLoader
                                ? "payment-status-checking"
                                : "payment-status-pending"
                              }`}
                            onClick={() => {
                              checkPaymentStatus(
                                item.id,
                                item.email,
                                item.phone,
                                item.name,
                                item.check_in,
                                item.check_out
                              );
                            }}
                          >
                            <h5
                              data-for="toolTipCheck"
                              data-tip="Check"
                              data-place="bottom"
                            ><i className="bi bi-eye-fill"></i></h5>
                            <ReactTooltip id="toolTipCheck" /> 
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="d-flex">
                          {/* <div
                            className="customer-view-button"
                            onClick={() => {
                              resendEmail(item.id, item.txn_id);
                            }}
                          >
                            Resend Mail
                          </div> */}
                          <Button
                            className="resend-email-button"
                            loader={selectedItem === item.id && saveLoader}
                            handleClick={() => {
                              resendEmail(item.id, item.txn_id);
                            }}
                          >
                            Resend Email
                          </Button>

                          {/* {access && (
                            <div
                              className="customer-delete-button"
                              onClick={() => {
                                deletCoupon(item.coupon_id);
                              }}
                            >
                              Delete
                            </div>
                          )} */}
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>

          <div className="list--view--pagination">
            <div className="pagination__left">
              <div className="pagination__dropdown">
                <InputSelectField
                  label="Select"
                  value={selectPageSize}
                  options={pageSize}
                  handleChange={(e) => {
                    setSelectPageSize(e.target.value);
                    setNoOfPages(
                      Math.ceil(allQuickPaymentLink.length / e.target.value)
                    );
                    setCurrentPageNo(1);
                  }}
                />
              </div>
              <ul>
                {Array(noOfPages)
                  .fill(0)
                  .map((p, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => setCurrentPageNo(i + 1)}
                        className={`${i + 1 === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                          }`}
                      >
                        {i + 1}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="pagination__right">
              <div className="arrow__container me-3" onClick={leftArrowClick}>
                <i className="bi bi-chevron-left font--weight"></i>
              </div>
              <div className="arrow__container" onClick={rightArrowClick}>
                <i className="bi bi-chevron-right font--weight"></i>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty__room__div">
          <i className="bi bi-x-circle mb-3"></i>
          <div>{nodataMessage}</div>
        </div>
      )}

      {/* Sliding Pane for Adding New Link  */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openAddNewLink}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOpenAddNewLink(false);
        }}
      >
        <AddNewLink
          onClose={() => {
            setOpenAddNewLink(false);
          }}
        />
        {/* <OneHotelCoupon
          onClose={() => {
            setOneHtlPanel(false);
          }}
          getAllCoupons={getAllCoupons}
          allRoomType={allRoomType}
          allCouponType={allCouponType}
        /> */}
      </SlidingPane>

      {/* Sliding Pane for View Payment  */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openViewPayment}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOpenViewPayment(false);
        }}
      >
        <ViewPayment
          onClose={() => {
            setOpenViewPayment(false);
          }}
          paymentDetails={specificPayment}
        />
        {/* <OneHotelCoupon
          onClose={() => {
            setOneHtlPanel(false);
          }}
          getAllCoupons={getAllCoupons}
          allRoomType={allRoomType}
          allCouponType={allCouponType}
        /> */}
      </SlidingPane>
    </div>
  );
};

export default QuickPaymentLink;
