import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import beApi from '../../../../API/beApi';
import Button from "../../../../views/buttons/Button";
import { RootState } from "../../../../redux/store";
import Toast from "../../../../components/toaster/Toast"
import Loader from '../../../../views/loader/Loader';
import { nanoid } from '@reduxjs/toolkit';
import { HideAddPaymentSlider, SetUpNewPaymodeMode } from '../../../../redux/actions/SetGatewayDetailsAction';
import { CATCH_MESSAGE } from '../../../../UtilityFunctions';


export interface IAddPaymentProps {
  onClose: () => void;
}


const AddPayment: React.FC<IAddPaymentProps>
  = ({
    onClose,
  }) => {

    const [paymentMediumsArr, setPaymentMediumsArr] = useState([])

    const [dataFetchedStatus, setDataFetchedStatus] = useState<any>('unset')

    const [addedPaymentMethods, setAddedPaymentMethods] = useState([])

    const [added, setAdded] = useState('')

    const [loader, setLoader] = useState(false)

    const [clickedId, setClikedId] = useState<any>(null);

    const [PAYMENT_MEDIUM_OPTIONS, set_PAYMENT_MEDIUM_OPTIONS] = useState<any>([])

    const [defaultMsg, setDefaultMsg] = useState({ msg: 'No payment gateway available' })

    const doNothing = () => { }

    const { auth_token, admin_id } = useSelector(
      (state: RootState) => state.auth
    );

    const newModeAdded = useSelector((store: RootState) => store.gateWay.value)

    const { current_property } = useSelector(
      (state: RootState) => state.properties
    );

    const dispatch = useDispatch()


    // ----------------------------------
    const fetchAllAvailablePaymentMethods = async () => {
      try {
        const Response = await beApi.get(`select-all-paymentgateway/${current_property.hotel_id}`,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          })
        if (Response.data.status === 1) {
          setDataFetchedStatus(Response.data.status)
          setPaymentMediumsArr(Response.data['Data 2'])
          setAddedPaymentMethods(Response.data['Data'].map(obj => obj.provider_name))
        }
        else {
          setDataFetchedStatus(Response.data.status)
          setDefaultMsg({ msg: Response.data.message })
          Toast(Response.data.message, 'error')
        }

      }
      catch (error) {
        setDataFetchedStatus(-1)
        Toast(CATCH_MESSAGE, "error");
        setDefaultMsg({ msg: 'Unable to fetch payment gateways' })
      }
    }

    const addPaymentGatewayProvider = async (mode) => {
      setLoader(true);
      try {
        const response = await beApi.post(
          `add-paymentgateway-setup`,
          {
            "hotel_id": current_property.hotel_id,
            "provider_name": mode.pg_name,
            "user_id": admin_id,
            "client_ip": "45.251.37.79"
          }
        )
        if (response.data.status === 1) {
          dispatch(SetUpNewPaymodeMode(nanoid()))
          setAdded(nanoid())
          Toast(response.data.message)
          setLoader(false);
        }
        else {
          Toast(response.data.message, 'error')
          setLoader(false);
        }
      }
      catch (error) {
        Toast(CATCH_MESSAGE, "error");
        setLoader(false);
      }
    }

    useEffect(() => { fetchAllAvailablePaymentMethods() }, [newModeAdded])

    useEffect(() => {
      const arr = paymentMediumsArr?.map((mode, ind) => {
        const { pg_name, payment_gateway_logos, pg_id } = mode


        const runFunc = () => {
          setClikedId(pg_id)
          addPaymentGatewayProvider(mode)
        }



        return (
          <div className="payment__option__cards mb-4 __Slider__payment__option" key={nanoid()}>
            <div className="payment_option_content ms-0 __Slider__payment_option_content">
              <div className="payment_medium_image_container __Slider__payment_medium_image_container">
                <img src={payment_gateway_logos} alt={pg_name} />
              </div>
              <div className="payment_button __Slider__payment_button">

                {addedPaymentMethods.includes(pg_name)
                  ?
                  <button className={`small__btn__round`}
                  >
                    Added
                  </button>
                  :

                  clickedId === pg_id ?
                    <Button className={`add__btn small__btn`}
                      loader={loader}
                    >
                      Add
                    </Button>
                    :
                    <Button className={`add__btn small__btn`}
                      handleClick={() => {
                        addedPaymentMethods.includes(pg_name) ? doNothing() : runFunc()
                      }}
                    >
                      Add
                      <div style={{ width: "100%", height: "100%", backgroundColor: "rgb(180, 180, 180, 0.4)", position: "absolute", top: "0", left: "0", display: loader ? 'block' : "none" }}></div>
                    </Button>
                }

              </div>
            </div>
          </div>
        )
      })

      set_PAYMENT_MEDIUM_OPTIONS(arr)
    }, [paymentMediumsArr, addedPaymentMethods, clickedId, loader])


    useEffect(() => {
      if (added === '') return
      dispatch(HideAddPaymentSlider())
    }, [added])




    return (
      <div style={{ position: 'relative' }}>

        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => {
                onClose();
              }}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">Add Payment Gateway</div>
        </div>

        <div className="payment_options_wrapper " style={{ position: 'absolute', top: "88px" }}>
          {/* ---------------------- */}

          {
            dataFetchedStatus === 1 ?
              PAYMENT_MEDIUM_OPTIONS.length > 0 ? PAYMENT_MEDIUM_OPTIONS : <div className="loadercontainer">
                <div style={{ color: "#a1a1a1" }}>{defaultMsg.msg}</div>
              </div>
              :
              dataFetchedStatus === 'unset' ? <Loader className='loaderClass' /> : <div className="loadercontainer">
                <div style={{ color: "#a1a1a1" }}>{defaultMsg.msg}</div>
              </div>
          }

          {/* ---------------------- */}
        </div>
      </div>
    )
  }

export default AddPayment