import {
  ALL_CHANNELS,
  COUPON_ID,
  ALL_ROOM_TYPES,
  OTA_MANAGE,
} from "../actionTypes";

const initialState = {
  allChannels: [],
  couponId: "",
  allRoomType: [],
  ota: {},
};

const ManageChannelReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ALL_CHANNELS:
      return {
        ...state,
        allChannels: action.payload,
      };
    case COUPON_ID:
      return {
        ...state,
        couponId: action.payload,
      };
    case ALL_ROOM_TYPES:
      return {
        ...state,
        allRoomType: action.payload,
      };
    case OTA_MANAGE:
      return {
        ...state,
        ota: action.payload,
      };
    default:
      return state;
  }
};

export default ManageChannelReducer;
