import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../views/buttons/Button";
import { CATCH_MESSAGE, UpdateSidebar } from "../../UtilityFunctions";
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import Loader from "../../views/loader/Loader";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import AddUsers from "./AddUsers";
import EditUsers from "./EditUsers";

import kernelApi from "../../API/kernelApi";

import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";
import EditAccess from "./EditAccess";
import { toast } from "react-toastify";
import { nanoid } from "@reduxjs/toolkit";
import Toast from "../../components/toaster/Toast";
import endPoints from "../../API/endPoints";

const ManageUsers = () => {
  const dispatch = useDispatch();
  const { company_id, admin_id, auth_token, email, password } = useSelector(
    (state: RootState) => state.auth
  );
  const [addUser, setAddUser] = useState(false);
  const [noDataMsg, setNoDataMsg] = useState<string>("");
  const [editUser, setEditUser] = useState(false);
  const [manageAccess, setManageAccess] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [accessUserid, setAccessUserid] = useState<any>();
  const [editUserid, setEditUserid] = useState<any>();
  const [editSelectedHotels, setEditSelectedHotels] = useState<any>();
  const [loader, setLoader] = useState<any>(true);

  const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  const { manageUserDeleteUser, manageUserAll } = endPoints.MANAGEUSER;

  const noUsersAvailable = {
    message: "Currently you dont have any users available",
  };

  const pageSize = [10, 15, 20, 25, 30];
  UpdateSidebar("Manage Users");

  useEffect(() => {
    if (allUsers?.length > 0) {
      setNoOfPages(Math.ceil(allUsers?.length / selectPageSize));
    }
  }, [selectPageSize, allUsers]);

  const edit_User = (user_id: any, hotel_id: any) => {
    setEditUser(true);
    setEditUserid(user_id);
    let arr: any[] = [];
    hotel_id.map((data: any) => {
      arr.push(parseInt(data));
    });

    setEditSelectedHotels(arr);
  };

  const edit_Access = (userid: any) => {
    setManageAccess(true);
    setAccessUserid(userid);
  };
  // --------Delete User---------
  const deleteUser = (admin_id: any, first: any, last: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure you want to delete ${first} ${""} ${last}?`,
        "DELETE",
        () => {
          deleteUserApi(admin_id);
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  const deleteUserApi = async (id: any) => {
    try {
      let response = await kernelApi.get(`${manageUserDeleteUser}/${id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });


      if (response.data.status === 1) {
        //If type success no need to pass any 2nd parameter
        Toast(response.data.message);
        getAllUsers();
        // If type error pass as 2nd parameter
      } else {
        Toast(response.data.message, "error");
      }
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };
  // --------------- Get All Users --------------- //

  const getAllUsers = async () => {
    try {
      let response = await kernelApi.get(`${manageUserAll}/${company_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (response.data.status === 1) {
        if (response.data.data && response.data.data?.length) {
          for (let u_data of response.data.data) {
            u_data.hotel_id = u_data.hotel_id.split(",");

            if (u_data.hotel_id.includes("0")) {
              u_data.hotel_id = [];
            }
          }
        }
        setAllUsers(response.data.data);
        setLoader(false);
      } else {
        setLoader(false);
        setAllUsers([]);
        setNoDataMsg(response.data.message);
      }
    } catch {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };
  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };
  const Capitalize = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <Button
            className="new__bookings"
            icon="bi bi-plus-lg"
            handleClick={() => setAddUser(true)}
          >
            Add User
          </Button>
        </div>
      </div>

      <div className="hr--line"></div>

      {loader ? (
        <Loader className="ineventory--loader" />
      ) : allUsers.length > 0 ? (
        <div>
          <div className="list-view-table-wrapper mb-4">
            <table className="table table-borderless">
              <thead>
                <tr className="listview__table__heading">
                  <th>
                    S.No
                    {/* <div className="checkbox_header"></div> */}
                  </th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>User Type</th>
                  <th>Hotels </th>
                  <th>Manage</th>
                  <th>Access</th>
                </tr>
              </thead>
              <tbody>
                {allUsers &&
                  allUsers?.length > 0 &&
                  allUsers
                    ?.slice(
                      selectPageSize * (currentPageNo - 1),
                      selectPageSize * currentPageNo
                    )
                    ?.map((data: any, id: any) => (
                      <tr className="listview__table__data">
                        <td>
                          {/* <input className="form-check-input" type="checkbox" /> */}
                          <div className="serial_no">{id + 1}</div>
                        </td>
                        <td>
                          <div
                            className="customer-details customer-name"
                          // onClick={() => {
                          //   navigate("customer-data");
                          // }}
                          >
                            {Capitalize(data?.first_name)} {""}
                            {Capitalize(data?.last_name)}
                          </div>
                        </td>
                        <td>
                          <div className="customer-details">
                            {data?.username}
                          </div>
                        </td>
                        <td>
                          <div className="customer-details">Hotel Manager</div>
                        </td>
                        <td>
                          <div className="customer-details">
                            {parseInt(data?.hotel_id?.length)}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            {/* ---------------- */}
                            <div
                              className="customer-view-button"
                              onClick={() =>
                                edit_User(data?.admin_id, data?.hotel_id)
                              }
                            >
                              Edit
                            </div>
                            {/* ---------------- */}
                            <div
                              className="customer-delete-button"
                              onClick={() =>
                                deleteUser(
                                  data?.admin_id,
                                  data?.first_name,
                                  data?.last_name
                                )
                              }
                            >
                              Delete
                            </div>
                            {/* ---------------- */}
                          </div>
                        </td>
                        <td>
                          <div
                            className="customer-access-button"
                            onClick={() => edit_Access(data.admin_id)}
                          >
                            Manage Access
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          <div className="list--view--pagination">
            <div className="pagination__left">
              <div className="pagination__dropdown">
                <InputSelectField
                  label="Select"
                  value={selectPageSize}
                  options={pageSize}
                  handleChange={(e) => {
                    setSelectPageSize(e.target.value);
                    setNoOfPages(Math.ceil(allUsers?.length / e.target.value));
                    setCurrentPageNo(1);
                  }}
                />
              </div>
              <ul>
                {Array(noOfPages)
                  .fill(0)
                  .map((p, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => setCurrentPageNo(i + 1)}
                        className={`${i + 1 === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                          }`}
                      >
                        {i + 1}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="pagination__right">
              <div className="arrow__container me-3" onClick={leftArrowClick}>
                <i className="bi bi-chevron-left font--weight"></i>
              </div>
              <div className="arrow__container" onClick={rightArrowClick}>
                <i className="bi bi-chevron-right font--weight"></i>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>{noUsersAvailable.message}</div>
        </div>
      )}

      {/* add users */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={addUser}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddUser(false)}
      >
        <AddUsers
          onClose={() => setAddUser(false)}
          getAllUsers={() => getAllUsers()}
        />
      </SlidingPane>

      {/* edit users */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={editUser}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditUser(false)}
      >
        <EditUsers
          user_id={editUserid}
          selectedHotels={editSelectedHotels}
          onClose={() => setEditUser(false)}
          getAllUsers={() => getAllUsers()}
        />
      </SlidingPane>

      {/* manage access */}
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={manageAccess}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setManageAccess(false)}
      >
        <EditAccess
          onClose={() => setManageAccess(false)}
          user_id={accessUserid}
        />
      </SlidingPane>
      {/* <div className="list--view--pagination">
        <div className="pagination__left">
          <div className="pagination__dropdown">
            <InputSelectField label="" value={"45"} options={[]} />
          </div>
          <ul>
            <li className={`active__page`}></li>
          </ul>
        </div>
        <div className="pagination__right">
          <div className="arrow__container me-3">
            <i className="bi bi-chevron-left font--weight"></i>
          </div>
          <div className="arrow__container">
            <i className="bi bi-chevron-right font--weight"></i>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ManageUsers;
