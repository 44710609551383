import React, { useEffect, useState } from "react";
import Button from "../../views/buttons/Button";

import moment from "moment";

// import select feild
import InputSelectField from "../../views/inputtextfield/InputSelectField";

// import input field
import InputTextField from "../../views/inputtextfield/InputTextField";

//import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

import { CATCH_MESSAGE, validatePositiveInt } from "../../UtilityFunctions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import cmApi from "../../API/cmApi";
import endPoints from "../../API/endPoints";
import kernelApi from "../../API/kernelApi";

interface ISyncSliderProps {
  ratesInfo: any[];
  ratePlans: any[];
  loader: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  roomTypeId: string;
  onClose: () => void;
  refreshRates: () => void;
  allRoomTypes: { ids: string[]; labels: string[] };
}

const SyncUpdate: React.FC<ISyncSliderProps> = ({
  ratesInfo,
  ratePlans,
  roomTypeId,
  allRoomTypes,
  loader,
  setLoader,
  refreshRates,
  onClose,
}) => {
  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { ratePlansOfRoom } = endPoints.RATE;

  const [ratePlanSpecficRoom, setRatePlanSpecficRoom] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>("");
  const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
  const [sourceOTAs, setSourceOTAs] = useState<{
    ids: string[];
    labels: string[];
  }>({ ids: [], labels: [] });
  const [activeSource, setActiveSource] = useState<string>("");
  const [activeSourceId, setActiveSourceId] = useState<string>("");
  const [activeRatePlan, setActiveRatePlan] = useState<string>("");
  const [syncDuration, setSyncDuration] = useState<string>("");
  const { syncUpdateRate } = endPoints.RATE;

  // const [isLoading, setIsLoading] = useState(false);
  const [errorResponses, setErrorResponses] = useState<any[]>([]);

  useEffect(() => {
    getRatePlansOfRoomTypes();
  }, []);

  const getRatePlansOfRoomTypes = async () => {
    try {
      let ratePlanRes = await kernelApi.get(
        `${ratePlansOfRoom}/${current_property.hotel_id}/${roomTypeId}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (ratePlanRes?.data?.status === 1) {
        setRatePlanSpecficRoom(ratePlanRes?.data?.data);
      } else {
        setRatePlanSpecficRoom([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    getSourceOTAs();
  }, [ratesInfo]);

  const getSourceOTAs = () => {
    let ids: any[] = [];
    let labels: any[] = [];

    ratesInfo.forEach((ota) => {
      ids.push(ota.ota_id);
      labels.push(ota.name);
    });

    setSourceOTAs({ ids, labels });
  };

  const onDateChange = (date: any) => {
    setFromDate(moment(date));
  };

  const onSourceChange = (e: any) => {
    // let activSource = e.target.value;
    // if (e.target.value === "Hotelkite" || e.target.value === "Bookingjini") {
    //   activSource = "Bookingjini";
    // } else {
    //   activSource = e.target.value;
    // }
    setActiveSource(e.target.value);
    setActiveSourceId(
      sourceOTAs.ids[sourceOTAs.labels.indexOf(e.target.value)].toString()
    );
    setSelectedChannels([]);
    setErrorResponses([]);
  };

  const refresh = () => {
    document.getElementById("refresh-button")?.click();
  };

  const onSyncDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (validatePositiveInt(value) || !value) {
      if (parseInt(value) > 30) {
        setSyncDuration("30");
      } else {
        setSyncDuration(value);
      }
    }
  };

  const onSelectChannel = (id: string) => {
    let channels = [...selectedChannels];
    if (channels.includes(id.toString())) {
      channels = channels.filter((channel) => channel !== id.toString());
    } else if (id.toString() !== activeSourceId) {
      channels.push(id.toString());
    }
    setSelectedChannels(channels);
  };

  const onSelectAllChannels = (e: any) => {
    let channels: any[] = [];
    if (e.target.checked) {
      ratesInfo.forEach((ota) => {
        if (ota.ota_id.toString() !== activeSourceId) {
          channels.push(ota.ota_id.toString());
        }
      });
    }
    setSelectedChannels(channels);
  };

  const submitSyncUpdate = async () => {
    setErrorResponses([]);
    if (
      activeSource &&
      activeRatePlan &&
      syncDuration &&
      fromDate &&
      selectedChannels.length > 0
    ) {
      setLoader(true);
      // let sourceOtaName = "";
      // if (activeSource === "Bookingjini" || activeSource === "Hotelkite") {
      //   sourceOtaName = "BookingEngine";
      // }
      let payload = {
        hotel_id: current_property.hotel_id,
        room_type_id: roomTypeId,
        rate_plan_id: activeRatePlan,
        ota_id: selectedChannels.map(Number),
        duration: syncDuration,
        from_date: fromDate.format("DD-MM-YYYY"),
        source_ota_name: activeSource,
      };

      try {
        const response = await cmApi.post(`${syncUpdateRate}`, payload, {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        });

        if (response.data.status === 0) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        } else {
          let errors = [...errorResponses];
          response.data.forEach((res: any) => {
            errors.push(res);
          });
          setErrorResponses(errors);
        }
      } catch (error) {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }

      setLoader(false);
      refresh();
    } else {
      toast("Please fill all the fields", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">
          Sync{" "}
          {roomTypeId
            ? "(" +
            allRoomTypes.labels[allRoomTypes.ids.indexOf(roomTypeId)] +
            ")"
            : ""}
        </div>
      </div>
      <div className="height-64px"></div>
      <div className="rates__update__wrapper">
        <div className="standard-page--label mb-4">Select Meal Plan</div>
        <div className="select__input__label">
          {ratePlanSpecficRoom?.map((plan: any, i: number) => (
            <div
              key={i}
              onClick={() => setActiveRatePlan(plan.rate_plan_id)}
              className={`me-4 mb-3 select__input__div${activeRatePlan === plan.rate_plan_id ? "__active" : ""
                }  `}
            >
              {activeRatePlan === plan.rate_plan_id && (
                <i className="bi bi-check-lg icon--check"></i>
              )}
              {plan.plan_type}
            </div>
          ))}
        </div>
        <div className="datepicker__wrapper my-3">
          <div className="individual__rate__update">
            <div className="individual__rate__updates__input mui__input__fieldset__legends__unset">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label=""
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  value={fromDate}
                  onChange={onDateChange}
                  minDate={new Date()}
                  inputFormat="dd MMM yyyy"
                  InputAdornmentProps={{ position: "start" }}
                  disableMaskedInput
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onClick={() => setOpen(true)}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className="inventory__number__wrapper mt-3 pt-3">
          <div className="__default--input--field">
            <InputTextField
              label="Sync Duration (30 Days Max)"
              value={syncDuration}
              handleChange={onSyncDurationChange}
            />
          </div>
        </div>

        <div className="standard-page--label mb-4 ">Choose Source Channel</div>
        <div className="inventory__choose__channel__source__select">
          <InputSelectField
            label="Choose Source Channel"
            value={activeSource}
            handleChange={onSourceChange}
            // values={sourceOTAs.ids}
            options={sourceOTAs.labels}
          />
        </div>

        <div className="rates__channel__wrapper mt-3 pt-2">
          <div className="d-flex">
            <div className="standard-page--label mb-4 pt-3">
              Select Target Channels
            </div>
            <div className="input__position pt-3">
              <input
                className="form-check-input custom-size me-3"
                type="checkbox"
                id="channelsCheck"
                onChange={onSelectAllChannels}
                checked={
                  selectedChannels.length ===
                  (activeSourceId ? ratesInfo.length - 1 : ratesInfo.length)
                }
              />
            </div>
          </div>

          {ratesInfo.map((ota: any, id: number) => {
            let Status = errorResponses.some(
              (error: any) =>
                error.ota_name?.toLowerCase() === ota.ota_name.toLowerCase() &&
                error.status === 0
            );
            let ErrorLength = errorResponses.length ? true : false;
            if (ota.ota_id.toString() !== activeSourceId || !activeSourceId) {
              return (
                <React.Fragment key={id}>
                  {Status && (
                    <div className="ota_error_message">
                      {
                        errorResponses.filter(
                          (error: any) =>
                            error.ota_name?.toLowerCase() ===
                            ota.ota_name?.toLowerCase()
                        )[0]?.response_msg
                      }
                    </div>
                  )}
                  <div
                    onClick={() =>
                      document.getElementById(`channelsCheck${id}`)?.click()
                    }
                    className={`channel__wrapper ${Status
                        ? "error--border"
                        : selectedChannels.includes(ota.ota_id.toString()) &&
                          ErrorLength
                          ? "success--border"
                          : ""
                      } mb-4`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="channel__image__content">
                        <img src={ota.logo} alt="" />
                      </div>
                      {!Status &&
                        ErrorLength &&
                        selectedChannels.includes(ota.ota_id.toString()) && (
                          <div className="success__checkmark">
                            <i className="bi bi-check"></i>
                          </div>
                        )}
                    </div>
                    <div className="channel__select__checkbox">
                      <div className="form-check">
                        <input
                          className="form-check-input unclickable"
                          type="checkbox"
                          id={`channelsCheck${id}`}
                          onChange={() => onSelectChannel(ota.ota_id)}
                          checked={selectedChannels.includes(
                            ota.ota_id.toString()
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>

      <div id="refresh-button" className="d-none" onClick={refreshRates}></div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper mt-3">
          <Button className="cancel__btn me-3" handleClick={onClose}>
            Cancel
          </Button>
          {!loader ? (
            <Button handleClick={submitSyncUpdate}>Save</Button>
          ) : (
            <>
              <Button>
                Save{" "}
                <span
                  className="spinner-border spinner-border-sm pd-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              </Button>
              <div className="full--page--overlay"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SyncUpdate;
