import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import endPoints from "../../API/endPoints";
import gemsApi from "../../API/gemsApi";
import { RootState } from "../../redux/store";
import { browsePDF, Capitalize } from "../../UtilityFunctions";
import Button from "../../views/buttons/Button";
import InputTextField from "../../views/inputtextfield/InputTextField";
import Loader from "../../views/loader/Loader";

interface IInvoiceDownloadProps {
  roomNo: string;
  onClose: () => void;
  bookings?: any;
}

const InvoiceDownload: React.FC<IInvoiceDownloadProps> = ({
  roomNo,
  onClose,
  bookings,
}) => {
  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { booking_details } = useSelector((state: RootState) => state.bookings);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const { pdfInvoiceGenerate, checkedOutBookingDetailsApi } =
    endPoints.BOOKINGS;

  const [isGstInvoice, setIsGstInvoice] = useState(false);
  const [intialLoading, setInitialLoading] = useState<boolean>(false);
  const [gstNumber, setGstNumber] = useState("");
  const [companyNameAddress, setCompanyNameAddress] = useState("");
  const [bookingDetails, setBookingDetails] = useState<any>();

  useEffect(() => {
    getCheckOutBookingDetails(bookings);
  }, []);

  const getCheckOutBookingDetails = async (booking: any) => {
    setInitialLoading(true);
    try {
      let payload = {
        hotel_id: current_property.hotel_id,
        booking_id: booking.booking_id,
        user_id: admin_id,
        room_id: booking.room_id,
      };

      const response = await gemsApi.post(
        `${checkedOutBookingDetailsApi}`,
        payload
      );

      if (response.data.status === 1) {
        setBookingDetails(response.data);
        setInitialLoading(false);
      } else {
        setBookingDetails({});
        setInitialLoading(false);
      }
    } catch (error) {
      toast.error("Could not fetch stay details");
      setInitialLoading(false);
    }
  };

  const onDownloadInvoice = async (option: number) => {
    try {
      const response = await gemsApi.post(`${pdfInvoiceGenerate}`, {
        booking_id: bookingDetails?.booking_data?.booking_id ?? "",
        hotel_id: current_property?.hotel_id,
        option: option,
        is_gst_invoice: isGstInvoice ? 1 : 0,
        gstin: gstNumber,
        company_name_address: companyNameAddress,
        user_id: admin_id,
      });

      if (response.data.status === 1) {
        const fileName = response.data.invoice_no;
        browsePDF(response.data.content, fileName);
      }
    } catch (error) {
      toast.error("Failed to download invoice");
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i id="close-slider" onClick={onClose} className="bi bi-x"></i>
        </div>
        <div className="standard-page--label">Checkout Details</div>
        <div className="side__slider__sub__heading me-3">
          ID : {bookingDetails?.booking_data?.booking_id}
        </div>
      </div>
      <div className="height-64px"></div>

      {intialLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <div className="slider__content__wrapper">
          <div className="checkout__details__wrapper">
            <div className="checkout__details__label">
              <div className="checkout__guest__name">
                {Capitalize(bookingDetails?.guest_data[0]?.name)}
                {`${
                  bookingDetails?.guest_data?.length > 1
                    ? ` + ${bookingDetails?.guest_data?.length - 1}`
                    : ""
                }`}
              </div>
              <div className="booking__channel__icon">
                <img
                  className="booking__source__image"
                  src={bookings?.channel_logo}
                  alt="Channel Source Image"
                />
              </div>
            </div>
            <div className="checkout__date__label">
              Checkout Date - {bookingDetails?.booking_data?.check_out}
            </div>
            <div className="guest__email__contact__details mt-2">
              <div className="guest__number">
                <i className="bi bi-telephone"></i>{" "}
                {bookingDetails?.guest_data[0]?.mobile}
              </div>
              <div className="guest__email__id ms-3">
                <i className="bi bi-envelope"></i>{" "}
                {bookingDetails?.guest_data[0]?.email}
              </div>
            </div>
            <div className="room__number__label mt-3">
              {bookingDetails?.room_data?.room_no || "NA"}
            </div>
          </div>

          <div className="standard-page--label gray--label my-3">
            Generate Invoice
          </div>

          <div className="checkout__inputs__wrapper mb-4">
            <div className="standard-page--label">Business Booking</div>
            <div className="form-check form-switch custom-switch check--box--width pe-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isGstInvoice}
                onChange={() => setIsGstInvoice(!isGstInvoice)}
              />
            </div>
          </div>

          {isGstInvoice && (
            <>
              <div className="company__name me-4">
                <InputTextField
                  label="GSTIN"
                  value={gstNumber}
                  handleChange={(e) => setGstNumber(e.target.value)}
                />
              </div>
              <div className="company__address me-4">
                <InputTextField
                  label="Company Name and Address"
                  value={companyNameAddress}
                  handleChange={(e) => setCompanyNameAddress(e.target.value)}
                />
              </div>
            </>
          )}

          <div className="button__group__wrapper">
            <Button
              className="half--button"
              handleClick={() => onDownloadInvoice(2)}
            >
              Room Only
            </Button>
            <Button
              className="half--button"
              handleClick={() => onDownloadInvoice(3)}
            >
              Food Only
            </Button>
            <Button
              className="full--button"
              handleClick={() => onDownloadInvoice(1)}
            >
              Food + Room + Others
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceDownload;
