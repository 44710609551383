import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { NewPropertyAddress } from "../../redux/actions/AddPropertyAction";

import InputTextField from "../../views/inputtextfield/InputTextField";
import InputSelectField from "../../views/inputtextfield/InputSelectField";
import NextButton from "../../views/buttons/NextButton";

import { Logout, validatePinCode } from "../../UtilityFunctions";
import kernelApi from "../../API/kernelApi";
import { toast } from "react-toastify";
import { RootState } from "../../redux/store";

export interface IAddressData {
  data: {
    country: string;
    state: string;
    city: string;
    street: string;
    pin: string;
    flat: string;
    address?: string;
  };
  onBack: () => void;
  onNext: () => void;
  isManual: boolean;
}

const PropertyAddressForm: React.FC<IAddressData> = ({
  data,
  onBack,
  onNext,
  isManual,
}) => {
  const dispatch = useDispatch();

  const { auth_token } = useSelector((state: RootState) => state.auth);

  const [postCodeError, setPostCodeError] = useState("");
  const [allCountries, setAllCountries] = useState<any[]>([]);
  const [allStates, setAllStates] = useState<any[]>([]);

  const [formAddress, setFormAddress] = useState({
    flat: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pin: "",
  });

  useEffect(() => {
    getAllCountries();
    setFormAddress(data);
  }, [data]);

  useEffect(() => {
    getStatesFromCountryId(formAddress.country);
  }, [data, formAddress.country]);

  // API call to get all country details
  const getAllCountries = async () => {
    try {
      const url = window.location.origin.includes("hotelkite")
        ? "hotelkite-countrydetails/getall"
        : "getAllCountries";
      const response = await kernelApi.get(url);
      if (response.data.status === 1) {
        setAllCountries(response.data.all_countries);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // API call to get all states details from country id
  const getStatesFromCountryId = async (country: string) => {
    try {
      const countryID =
        allCountries.find((item: any) => item.country_name === country)
          .country_id || 0;
      if (countryID) {
        const response = await kernelApi.get(
          `statedetails/getById/${countryID}`,
          {
            headers: {
              Authorization: `Bearer ${auth_token}`,
            },
          }
        );

        if (response.data.status === 1) {
          setAllStates(response.data.states);
        } else {
          setAllStates([]);
        }
      }
    } catch (error: any) {
      setAllStates([]);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const onPostCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormAddress({ ...formAddress, pin: e.target.value });
    if (!validatePinCode(e.target.value) && e.target.value) {
      setPostCodeError("Invalid post code");
    } else {
      setPostCodeError("");
    }
  };

  const SubmitPropertyAddress = () => {
    let street = formAddress.street;
    let flat = formAddress.flat;
    let city = formAddress.city;
    let state = formAddress.state;
    let country = formAddress.country;
    let pin = formAddress.pin;
    let address = `${
      flat ? flat + ", " : ""
    }${street}, ${city}, ${state} ${pin}, ${country}`;

    let data = { flat, street, city, state, country, pin, address };

    if (!postCodeError && street && city && state && country && pin) {
      dispatch(NewPropertyAddress(data));
      setTimeout(() => {
        document.getElementById("next")?.click();
      }, 500);
    } else {
      toast.error("Please fill all the mandatory fields");
    }
  };

  return (
    <div className="address-form-container">
      <div className="address__form__label mb-4">
        <i
          className="bi bi-chevron-left me-3 chevron--go--back--size"
          onClick={onBack}
        ></i>
        <span>Confirm your address</span>
      </div>
      <div className="div__inputs__wrapp mb-2">
        <div className="div--email--input">
          <InputTextField
            label="Street Address"
            value={formAddress.street}
            handleChange={(e) =>
              setFormAddress({ ...formAddress, street: e.target.value })
            }
          />
        </div>
      </div>

      <div className="div__inputs__wrapp mb-2">
        <div className="div--email--input">
          <InputTextField
            label="Flat, Suite, etc. (optional)"
            value={formAddress.flat}
            handleChange={(e) =>
              setFormAddress({ ...formAddress, flat: e.target.value })
            }
          />
        </div>
      </div>

      <div className="div__inputs__wrapp mb-2">
        <div className="div__input__align__horizontal">
          <div className="div--email--input me-2">
            <InputTextField
              label="City"
              value={formAddress.city}
              handleChange={(e) =>
                setFormAddress({ ...formAddress, city: e.target.value })
              }
            />
          </div>

          <div className="div--email--input me-2">
            <InputTextField
              label="Post Code"
              value={formAddress.pin}
              helperText={postCodeError}
              handleChange={onPostCodeChange}
            />
          </div>
        </div>
      </div>

      <div className="div__inputs__wrapp mb-2">
        <div className="div__input__align__horizontal">
          <div
            className={`div--email--input ${
              isManual ? "" : "address-select-position"
            }`}
          >
            <InputSelectField
              label="Choose Country"
              readOnly={!isManual}
              value={formAddress.country}
              handleChange={(e) =>
                setFormAddress({ ...formAddress, country: e.target.value })
              }
              options={allCountries.map((country) => country.country_name)}
            />
          </div>

          <div className="div--email--input ms-2">
            {isManual ? (
              <InputSelectField
                label="Choose State"
                value={formAddress.state}
                handleChange={(e) =>
                  setFormAddress({ ...formAddress, state: e.target.value })
                }
                options={allStates.map((state) => state.state_name)}
              />
            ) : (
              <InputTextField
                label="State"
                value={formAddress.state}
                readonly
                handleChange={(e) =>
                  setFormAddress({ ...formAddress, state: e.target.value })
                }
              />
            )}
          </div>
        </div>
      </div>

      <NextButton
        handleClick={SubmitPropertyAddress}
        className="reset_position mt-3"
      />

      <div id="next" className="d-none" onClick={onNext}></div>
    </div>
  );
};

export default PropertyAddressForm;
