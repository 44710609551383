import React, { useState, useEffect } from "react";
import axios from "axios";
import DataCard from "../../views/datacard/DataCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  MTDHotilerData,
  TodayHotilerData,
  YTDHotilerData,
} from "../../redux/actions/DashboardAction";
import { Logout } from "../../UtilityFunctions";
import kernelApi from "../../API/kernelApi";
import endPoints from "../../API/endPoints";


const ConfirmedBookings = () => {
  // Data From Redux
  const { current_property, current_property_info } = useSelector(
    (state: RootState) => state.properties
  );

  const hotelierData = ["Today", "MTD", "YTD"];
  const [overViewData, setOverViewData] = useState<any>("");
  const [activeMenuTab, setActiveMenuTab] = useState<any>("Today");
  const [hotelMTDData, sethotelMTDData] = useState<any>("");
  const [hotelYTDData, sethotelYTDData] = useState<any>("");
  const [loadingTodayData, setLoadingTodayData] = useState(true);
  const [loadingMTDData, setLoadingMTDData] = useState(true);
  const [loadingYTDData, setLoadingYTDData] = useState(true);

  const { hotelierTodayOverview, hotelierSummary } = endPoints.DASHBOARDDATA;

  const dispatch = useDispatch();

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  useEffect(() => {
    if (activeMenuTab === "Today") {
      getHotelOverViewData();
    } else if (activeMenuTab === "MTD") {
      getHotelMTDData();
    } else if (activeMenuTab === "YTD") {
      getHotelYTDData();
    }
    // getHotelOverViewData();
    // getHotelMTDData();
    // getHotelYTDData();
  }, [activeMenuTab, current_property]);

  const getHotelOverViewData = async () => {
    setLoadingTodayData(true);
    try {
      const res = await kernelApi.get(
        `${hotelierTodayOverview}/${current_property.hotel_id}`
      );

      if (res.data.status === 1) {
        setOverViewData(res.data);
        dispatch(TodayHotilerData(res.data));
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    setLoadingTodayData(false);
  };

  const getHotelMTDData = async () => {
    setLoadingMTDData(true);
    try {
      const res = await kernelApi.get(
        `${hotelierSummary}/${current_property.hotel_id}/MTD/null/null`
      );

      if (res.data.status === 1) {
        sethotelMTDData(res.data);
        dispatch(MTDHotilerData(res.data));
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    setLoadingMTDData(false);
  };

  const getHotelYTDData = async () => {
    setLoadingYTDData(true);
    try {
      const res = await kernelApi.get(
        `${hotelierSummary}/${current_property.hotel_id}/YTD/null/null`
      );

      if (res.data.status === 1) {
        sethotelYTDData(res.data);
        dispatch(YTDHotilerData(res.data));
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    setLoadingYTDData(false);
  };

  return (
    <>
      <div className="dashboard__standard__header mb-4">
        <div className="dashboard__standard__label">Confirmed</div>
        <div className="dashboard__standard__action__btns">
          <ul className="nav nav-tabs" id="duesTab" role="tablist">
            {hotelierData.map((tab) => {
              return (
                <li
                  className="nav-item"
                  role="presentation"
                  key={tab}
                  onClick={() => switchMenuTab(tab)}
                >
                  <a
                    className={`nav-link ${tab === activeMenuTab ? "active" : ""
                      }`}
                    id={`${tab}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab}`}
                    aria-controls={`#${tab}`}
                    role="tab"
                    aria-selected="true"
                  >
                    {tab}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {activeMenuTab === "Today" ? (
        // Section For Today Data
        <div className="dashboard__confirm__cards mb-4">
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingTodayData}
              icon="calendar-check"
              cardType={"bookings"}
              data={{
                title: "Bookings",
                subtitle: "Today",
                value: `${overViewData.confirmed_booking || "0"}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingTodayData}
              icon="cash-stack"
              cardType={"revenue"}
              data={{
                title: "Revenue",
                subtitle: "Today",
                value: `${current_property_info && String.fromCharCode(parseInt(current_property_info.currency_symbol, 16))} ${overViewData.revenue || "0"}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingTodayData}
              icon="moon-fill"
              cardType={"roomNights"}
              data={{
                title: "Room Nights",
                subtitle: "Today",
                value: `${overViewData.confirmed_room_nights || "0"}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingTodayData}
              icon="cash-stack"
              cardType={"adr"}
              data={{
                title: "ADR",
                subtitle: "Today",
                value: `${current_property_info && String.fromCharCode(parseInt(current_property_info.currency_symbol, 16))} ${overViewData.adr || "0"}`,
                toolTipMsg: "Average Daily Rate",
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingTodayData}
              icon="moon-fill"
              cardType={"avgLos"}
              data={{
                title: "Avg LOS",
                subtitle: "Today",
                value: `${overViewData.avg_los || "0"}`,
                toolTipMsg: "Average Length of Stay",
              }}
            />
          </div>
          <div className="dashboard__card__box">
            <DataCard
              isLoading={loadingTodayData}
              icon="moon-fill"
              cardType={"abd"}
              data={{
                title: "ABD",
                subtitle: "Today",
                value: `${overViewData.avg_abd || "0"}`,
                toolTipMsg: "Advance Booking Days",
              }}
            />
          </div>
        </div>
      ) : activeMenuTab === "MTD" ? (
        // Section For MTD Data
        <div className="dashboard__confirm__cards mb-4">
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingMTDData}
              icon="calendar-check"
              cardType={"bookings"}
              data={{
                title: "Bookings",
                subtitle: "MTD",
                value: `${hotelMTDData.confirmed_bookings || "0"}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingMTDData}
              icon="cash-stack"
              cardType={"revenue"}
              data={{
                title: "Revenue",
                subtitle: "MTD",
                value: `${current_property_info && String.fromCharCode(parseInt(current_property_info.currency_symbol, 16))} ${hotelMTDData.total_revenue || "0"}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingMTDData}
              icon="moon-fill"
              cardType={"roomNights"}
              data={{
                title: "Room Nights",
                subtitle: "MTD",
                value: `${hotelMTDData.booked_room_nights || "0"}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingMTDData}
              icon="cash-stack"
              cardType={"adr"}
              data={{
                title: "ADR",
                subtitle: "MTD",
                value: `${current_property_info && String.fromCharCode(parseInt(current_property_info.currency_symbol, 16))} ${hotelMTDData.adr || "0"}`,
                toolTipMsg: "Average Daily Rate",
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingMTDData}
              icon="moon-fill"
              cardType={"avgLos"}
              data={{
                title: "Avg LOS",
                subtitle: "MTD",
                value: `${hotelMTDData.avg_los || "0"}`,
                toolTipMsg: "Average Length of Stay",
              }}
            />
          </div>
          <div className="dashboard__card__box">
            <DataCard
              isLoading={loadingMTDData}
              icon="moon-fill"
              cardType={"abd"}
              data={{
                title: "ABD",
                subtitle: "MTD",
                value: `${hotelMTDData.avg_abd || "0"}`,
                toolTipMsg: "Advance Booking Days",
              }}
            />
          </div>
        </div>
      ) : (
        // Section For YTD Data
        <div className="dashboard__confirm__cards mb-4">
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingYTDData}
              icon="calendar-check"
              cardType={"bookings"}
              data={{
                title: "Bookings",
                subtitle: "YTD",
                value: `${hotelYTDData.confirmed_bookings}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingYTDData}
              icon="cash-stack"
              cardType={"revenue"}
              data={{
                title: "Revenue",
                subtitle: "YTD",
                value: `${current_property_info && String.fromCharCode(parseInt(current_property_info.currency_symbol, 16))} ${hotelYTDData.total_revenue}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingYTDData}
              icon="moon-fill"
              cardType={"roomNights"}
              data={{
                title: "Room Nights",
                subtitle: "YTD",
                value: `${hotelYTDData.booked_room_nights}`,
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingYTDData}
              icon="cash-stack"
              cardType={"adr"}
              data={{
                title: "ADR",
                subtitle: "YTD",
                value: `${current_property_info && String.fromCharCode(parseInt(current_property_info.currency_symbol, 16))} ${hotelYTDData.adr}`,
                toolTipMsg: "Average Daily Rate",
              }}
            />
          </div>
          <div className="dashboard__card__box me-3">
            <DataCard
              isLoading={loadingYTDData}
              icon="moon-fill"
              cardType={"avgLos"}
              data={{
                title: "Avg LOS",
                subtitle: "YTD",
                value: `${hotelYTDData.avg_los}`,
                toolTipMsg: "Average Length of Stay",
              }}
            />
          </div>
          <div className="dashboard__card__box">
            <DataCard
              isLoading={loadingYTDData}
              icon="moon-fill"
              cardType={"abd"}
              data={{
                title: "ABD",
                subtitle: "YTD",
                value: `${hotelYTDData.avg_abd || "0"}`,
                toolTipMsg: "Advance Booking Days",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmedBookings;
