import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../../../components/breadcrumbs/CustomBreadcrumbs";
import Button from "../../../views/buttons/Button";
import Loader from "../../../views/loader/Loader";
import SlidingPane from "react-sliding-pane";
import AddDynamicPricing from "./AddDynamicPricing";
import EditDynamicPricing from "./EditDynamicPricing";
import kernelApi from "../../../API/kernelApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import endPoints from "../../../API/endPoints";

const DynamicPricingSetup = () => {
  const { dynamicPriceDelete, dynamicPriceDataGet, allRoomTypesApi } =
    endPoints.PROPERTY_SETUP_DYNAMIC_PRICING;
  const [loader, setLoader] = useState<boolean>(false);
  const [addDynamicSlider, setAddDynamicSlider] = useState<boolean>(false);
  const [editDynamicSlider, seEditDynamicSlider] = useState<boolean>(false);
  const [allRoomTypes, setAllRoomTypes] = useState<any[]>([]);
  const [allDynamicPriceData, setDynamicPriceData] = useState<any[]>([]);
  const [NoDataMessage, setNoDataMessage] = useState<any>("");
  const [editPricingId, setEditPricingId] = useState<any>("");
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Api Call to get Table View Data
  const getDynamicPricingAll = async () => {
    setLoader(true);
    try {
      const dynamicPriceRes = await kernelApi.get(
        `${dynamicPriceDataGet}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (dynamicPriceRes?.data?.status === 1) {
        setDynamicPriceData(dynamicPriceRes?.data?.data);
        setLoader(false);
      } else {
        setDynamicPriceData([]);
        setNoDataMessage(dynamicPriceRes?.data?.message);
        setLoader(false);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      if (error.response.status === 401) {
        toast.error(error.response.message);
        Logout();
      }
    }
  };

  // Api Call To Get All Room Types
  const getAllRoomTypes = async () => {
    try {
      const allRoomResponse = await kernelApi.get(
        `${allRoomTypesApi}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (allRoomResponse?.data?.status === 1) {
        setAllRoomTypes(allRoomResponse.data.data);
      } else {
        setAllRoomTypes([]);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      if (error.response.status === 401) {
        toast.error(error.response.message);
        Logout();
      }
    }
  };

  useEffect(() => {
    getDynamicPricingAll();
    getAllRoomTypes();
  }, [current_property]);

  // Api Call For Deleting

  const deletePricing = (id: number) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            let deletePricingRes = await kernelApi.delete(
              `/${dynamicPriceDelete}${id}`,

              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );

            if (deletePricingRes.data.status === 1) {
              toast.success(`${deletePricingRes.data.message}`);
              getDynamicPricingAll();
            } else {
              toast.error(`${deletePricingRes.data.message}`);
              getDynamicPricingAll();
            }
          } catch (error: any) {
            setLoader(false);
            toast.error(CATCH_MESSAGE);
            if (error.response.status === 400) {
              Logout();
            }
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Property Setup", href: "/property-setup" },
            {
              label: " Dynamic Pricing Setup ",
            },
          ]}
        />
        <div className="manage__property__action__right">
          <Button
            icon="bi bi-plus-lg"
            handleClick={() => setAddDynamicSlider(true)}
          // isAccessible={access}
          >
            Add Rules
          </Button>
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      {/* Main content*/}
      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : allDynamicPriceData.length > 0 ? (
        <div className="list-view-table-wrapper_dynamicPricing mb-4">
          <table className="table table-borderless_dynamicpricing">
            <thead>
              <tr className="listview__table__heading">
                <th>Sl.No</th>
                <th>Room Type</th>
                <th>From Date</th>
                <th>To Date</th>
                <th className="xtra-adult-child">Extra Adult</th>
                <th className="xtra-adult-child">Extra Child</th>
                <th className="edit_rate_dynamicpricing">Edit</th>
                <th className="edit_rate_dynamicpricing">Delete</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={8}>
                    <div>
                      <Loader className="d-flex justify-content-center align-items-center" />
                    </div>
                  </td>
                </tr>
              ) : (
                allDynamicPriceData?.map((item: any, idx: any) => (
                  <tr className="listview__table__data" key={item.id}>
                    <td>
                      <div className="serial-number">{idx + 1}</div>
                    </td>
                    <td>
                      <div className="customer-details plan-type">
                        {item.room_type.toUpperCase()}
                      </div>
                    </td>
                    <td>
                      <div className="customer-details">{item.from_date}</div>
                    </td>
                    <td>
                      <div className="customer-details">{item.to_date}</div>
                    </td>
                    <td>
                      {item.extra_adult.toLowerCase() === "yes" ? (
                        <div className="xtra-adult-child-icon-wrapper">
                          <i className="bi bi-check-lg check-icon"></i>
                        </div>
                      ) : (
                        <div className="xtra-adult-child-icon-wrapper">
                          <i className="bi bi-x-lg cross-icon"></i>
                        </div>
                      )}
                    </td>
                    <td>
                      {item.extra_child.toLowerCase() === "yes" ? (
                        <div className="xtra-adult-child-icon-wrapper">
                          <i className="bi bi-check-lg check-icon"></i>
                        </div>
                      ) : (
                        <div className="xtra-adult-child-icon-wrapper">
                          <i className="bi bi-x-lg cross-icon"></i>
                        </div>
                      )}
                    </td>

                    <td>
                      <div className="d-flex justify-content-center">
                        <div
                          className="customer-view-button"
                          onClick={() => {
                            seEditDynamicSlider(true);
                            setEditPricingId(item.room_type_id);
                            // setEditPlanData(item);
                            //   dispatch(SetCouponId(item.coupon_id));
                          }}
                        >
                          Edit
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <div
                          className="customer-delete-button"
                          onClick={() => deletePricing(item?.id)}
                        >
                          Delete
                        </div>
                        {/* ---------------- */}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* For Pagination */}

          {/* <div className="list--view--pagination">
    <div className="pagination__left">
      <div className="pagination__dropdown">
        <InputSelectField
          label="Select"
          value={selectPageSize}
          options={pageSize}
          handleChange={(e) => {
            setSelectPageSize(e.target.value);
            setNoOfPages(Math.ceil(couponData.length / e.target.value));
            setCurrentPageNo(1);
          }}
        />
      </div>
      <ul>
        {Array(noOfPages)
          .fill(0)
          .map((p, i) => {
            return (
              <li
                key={i}
                onClick={() => setCurrentPageNo(i + 1)}
                className={`${
                  i + 1 === currentPageNo
                    ? "active__page"
                    : "showing__page__number"
                }`}>
                {i + 1}
              </li>
            );
          })}
      </ul>
    </div>
    <div className="pagination__right">
      <div className="arrow__container me-3" onClick={leftArrowClick}>
        <i className="bi bi-chevron-left font--weight"></i>
      </div>
      <div className="arrow__container" onClick={rightArrowClick}>
        <i className="bi bi-chevron-right font--weight"></i>
      </div>
    </div>
  </div> */}
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>{NoDataMessage}</div>
        </div>
      )}
      {/* Sliding Panel For Add */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={addDynamicSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddDynamicSlider(false)}
      >
        <AddDynamicPricing
          onClose={() => {
            setAddDynamicSlider(false);
          }}
          allRoomTypes={allRoomTypes}
          getDynamicPricingAll={getDynamicPricingAll}
        />
      </SlidingPane>
      {/* Sliding Panel For Edit */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={editDynamicSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => seEditDynamicSlider(false)}
      >
        <EditDynamicPricing
          onClose={() => {
            seEditDynamicSlider(false);
          }}
          allRoomTypes={allRoomTypes}
          getDynamicPricingAll={getDynamicPricingAll}
          roomTypeId={editPricingId}
        />
      </SlidingPane>
    </>
  );
};

export default DynamicPricingSetup;
