import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../redux/store";

import { UpdateSidebar, Logout, CATCH_MESSAGE } from "../../UtilityFunctions";

// import CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import WordCount from "@ckeditor/ckeditor5-word-count"

// import save button
import SaveButton from "../../views/buttons/Button";
import Button from "../../views/buttons/Button";

import kernelApi from "../../API/kernelApi";
import { toast } from "react-toastify";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import endPoints from "../../API/endPoints";

const PropertyTermsPolicy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  UpdateSidebar("Property Setup", "Terms and Policy");

  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // endPoints Imports
  const {
    hotelTermsPolicy,
    updateTermsPolicies
  } = endPoints.PROPERTY_TERMS_POLICY;

  const [termCond, setTermCond] = useState("");
  const [privacyPolicy, setPivacyPolicy] = useState("");
  const [chlidPolicy, setChildPolicy] = useState("");
  const [cancelPolicy, setCancelPolicy] = useState("");
  const [access, setAccess] = useState(true);

  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "HOTEL";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    getTermAndCond();
  }, [current_property]);

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  // Getting terms and condition from API
  const getTermAndCond = async () => {
    try {
      let response = await kernelApi.get(
        `${hotelTermsPolicy}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        let policies = response.data.policies[0];
        setTermCond(policies.terms_and_cond || "");
        setCancelPolicy(policies.cancel_policy || "");
        setChildPolicy(policies.child_policy || "");
        setPivacyPolicy(policies.hotel_policy || "");

        setCharCount({
          child: removeTags(policies.terms_and_cond).length,
          privacy: removeTags(policies.hotel_policy).length,
          cancellation: removeTags(policies.cancel_policy).length,
          terms_cond: removeTags(policies.terms_and_cond).length,
        });
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  // Posting terms and policies to API
  const submitPolicies = async () => {
    try {
      let response = await kernelApi.post(
        `${updateTermsPolicies}`,
        {
          hotel_id: current_property.hotel_id,
          child_policy: chlidPolicy,
          hotel_policy: privacyPolicy,
          cancel_policy: cancelPolicy,
          terms_and_cond: termCond,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (response.data.status === 1) {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      } else {
        toast(CATCH_MESSAGE, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__property__action__right">
          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>
      <div className="property__setup__terms__policy__wrapper">
        <div className="manage__terms__policy__wrapper">
          <div className="manage__terms__policy__content">
            <ul className="nav nav-tabs mb-3" id="editRoomType" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text active"
                  id="terms-condtions-tabs"
                  data-bs-toggle="tab"
                  data-bs-target="#terms-condtions"
                  type="button"
                  role="tab"
                  aria-controls="terms-condtions"
                  aria-selected="true"
                >
                  Terms And Condtions
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text"
                  id="privacy-policy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#privacy-policy"
                  type="button"
                  role="tab"
                  aria-controls="privacy-policy"
                  aria-selected="false"
                >
                  Privacy Policy
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text"
                  id="child-policy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#child-policy"
                  type="button"
                  role="tab"
                  aria-controls="child-policy"
                  aria-selected="false"
                >
                  Child Policy
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text"
                  id="cancellation-policy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#cancellation-policy"
                  type="button"
                  role="tab"
                  aria-controls="cancellation-policy"
                  aria-selected="false"
                >
                  Cancellation Policy
                </button>
              </li>
            </ul>

            <div className="tab-content" id="manageTermsPolicyContent">
              {/* Tab section for terms & conditions */}
              <div
                className="tab-pane fade show active"
                id="terms-condtions"
                role="tabpanel"
                aria-labelledby="terms-condtions-tabs"
              >
                <div className="manage__terms__poliy__wrapper pb-0">
                  <div id="editor">
                    <CKEditor
                      editor={ClassicEditor}
                      data={termCond}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setTermCond(data);
                        setCharCount({
                          ...charCount,
                          terms_cond: removeTags(data).length,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="character__count__number">
                  <div className="character__count__label">
                    Used <span>{charCount.terms_cond}</span>
                  </div>
                </div>
              </div>

              {/* Tab section for privacy policy */}
              <div
                className="tab-pane fade show"
                id="privacy-policy"
                role="tabpanel"
                aria-labelledby="privacy-policy-tabs"
              >
                <div className="manage__terms__poliy__wrapper pb-0">
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={privacyPolicy}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setPivacyPolicy(data);
                        setCharCount({
                          ...charCount,
                          privacy: removeTags(data).length,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="character__count__number">
                  <div className="character__count__label">
                    Used <span>{charCount.privacy}</span>
                  </div>
                </div>
              </div>

              {/* Tab section for child policy */}
              <div
                className="tab-pane fade show"
                id="child-policy"
                role="tabpanel"
                aria-labelledby="child-policy-tabs"
              >
                <div className="manage__terms__poliy__wrapper pb-0">
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={chlidPolicy}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setChildPolicy(data);
                        setCharCount({
                          ...charCount,
                          child: removeTags(data).length,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="character__count__number">
                  <div className="character__count__label">
                    Used <span>{charCount.child}</span>
                  </div>
                </div>
              </div>

              {/* Tab section for cancellation policy */}
              <div
                className="tab-pane fade show"
                id="cancellation-policy"
                role="tabpanel"
                aria-labelledby="cancellation-policy-tabs"
              >
                <div className="manage__terms__poliy__wrapper pb-0">
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={cancelPolicy}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                        ],
                      }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setCancelPolicy(data);
                        setCharCount({
                          ...charCount,
                          cancellation: removeTags(data).length,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="character__count__number">
                  <div className="character__count__label">
                    Used <span>{charCount.cancellation}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="save__btn__wrapper">
              <Button
                className="cancel__btn me-3"
                handleClick={() => {
                  navigate("/property-setup");
                }}
                isAccessible={access}
              >
                Cancel
              </Button>
              <SaveButton
                className="terms__and__policy__btn"
                handleClick={submitPolicies}
                isAccessible={access}
              >
                Save
              </SaveButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyTermsPolicy;
