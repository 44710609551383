import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataCard from '../../views/datacard/DataCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const RoomNights = () => {

    // Data From Redux
    const { current_property } = useSelector(
        (state: RootState) => state.properties
      );
      const { today_data,mtd_data,ytd_data } = useSelector(
        (state: RootState) => state.dashBoardData
      );
    const roomNightTabData = ["Today", "MTD", "YTD"];
    const [roomNights, setRoomNights] = useState<any>("");
    const [activeMenuTab, setActiveMenuTab] = useState<any>("Today");
    // const [roomNightsMTDData, setRoomNightsMTDData] = useState<any>("");
    // const [roomNightYTDData, setRoomNightYTDData] = useState<any>("");
    const [loadingTodayData, setLoadingTodayData] = useState(true);
    const [loadingMTDData, setLoadingMTDData] = useState(true);
    const [loadingYTDData, setLoadingYTDData] = useState(true);

    const switchMenuTab = (tab: string) => {
        setActiveMenuTab(tab);
    };

    // Destructuring the Today Data
    const {total_rooms,unsold_rooms,occupancy}=today_data;
    // Destructuring the MTD Data
    const {available_room_nights,unsold_room_nights,occupied_room_nights}=mtd_data;
    // Destructuring the YTD Data
    const roomNightYTDData=ytd_data;

    useEffect(()=>{
        if (total_rooms>=0){
            setLoadingTodayData(false);
        }else{
            setLoadingTodayData(true);
        }
        if (unsold_rooms>=0){
            setLoadingTodayData(false);
        }else{
            setLoadingTodayData(true);
        }
        if ((total_rooms - unsold_rooms)>=0){
            setLoadingTodayData(false);
        }else{
            setLoadingTodayData(true);
        }
        if (occupancy>=0){
            setLoadingTodayData(false);
        }else{
            setLoadingTodayData(true);
        }
        if (available_room_nights>=0){
            setLoadingMTDData(false);
        }else{
            setLoadingMTDData(true);
        }
        if (unsold_room_nights>=0){
            setLoadingMTDData(false);
        }else{
            setLoadingMTDData(true);
        }
        if (occupied_room_nights>=0){
            setLoadingMTDData(false);
        }else{
            setLoadingMTDData(true);
        }
        if (roomNightYTDData.available_room_nights>=0){
            setLoadingYTDData(false);
        }else{
            setLoadingYTDData(true);
        }
        if (roomNightYTDData.unsold_room_nights>=0){
            setLoadingYTDData(false);
        }else{
            setLoadingYTDData(true);
        }
        if (roomNightYTDData.occupied_room_nights>=0){
            setLoadingYTDData(false);
        }else{
            setLoadingYTDData(true);
        }

    },[today_data,mtd_data,ytd_data])

    // useEffect(() => {
    //     if (activeMenuTab === "Today") {

    //         if (total_rooms>=0){
    //             setLoadingTodayData(false);
    //         }else{
    //             setLoadingTodayData(true);
    //         }
    //         if (unsold_rooms>=0){
    //             setLoadingTodayData(false);
    //         }else{
    //             setLoadingTodayData(true);
    //         }
    //         if ((total_rooms - unsold_rooms)>=0){
    //             setLoadingTodayData(false);
    //         }else{
    //             setLoadingTodayData(true);
    //         }
    //         if (occupancy>=0){
    //             setLoadingTodayData(false);
    //         }else{
    //             setLoadingTodayData(true);
    //         }

    //         // getRoomNightsData();
    //     } else if (activeMenuTab === "MTD") {

    //         if (available_room_nights>=0){
    //             setLoadingMTDData(false);
    //         }else{
    //             setLoadingMTDData(true);
    //         }
    //         if (unsold_room_nights>=0){
    //             setLoadingMTDData(false);
    //         }else{
    //             setLoadingMTDData(true);
    //         }
    //         if (occupied_room_nights>=0){
    //             setLoadingMTDData(false);
    //         }else{
    //             setLoadingMTDData(true);
    //         }


    //         // getRoomNightsDataMTDData();
    //     } else if (activeMenuTab === "YTD") {

    //         if (roomNightYTDData.available_room_nights>=0){
    //             setLoadingYTDData(false);
    //         }else{
    //             setLoadingYTDData(true);
    //         }
    //         if (roomNightYTDData.unsold_room_nights>=0){
    //             setLoadingYTDData(false);
    //         }else{
    //             setLoadingYTDData(true);
    //         }
    //         if (roomNightYTDData.occupied_room_nights>=0){
    //             setLoadingYTDData(false);
    //         }else{
    //             setLoadingYTDData(true);
    //         }

    //         // getRoomNightsYTDData();
    //     }
        


       

    // }, [activeMenuTab,today_data,mtd_data,ytd_data]);




    // const getRoomNightsData = async () => {
    //     setLoadingTodayData(true);
    //     try {
    //         const res = await axios.get(`https://kernel.bookingjini.com/hotelier-today-overview/${current_property.hotel_id}`);

    //         if (res.data.status === 1) {
    //             setRoomNights(res.data);
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     setLoadingTodayData(false);
    // }

    // const getRoomNightsDataMTDData = async () => {
    //     setLoadingMTDData(true);
    //     try {
    //         const res = await axios.get(`https://kernel.bookingjini.com/hotelier-summary/${current_property.hotel_id}/MTD/null/null`);

    //         if (res.data.status === 1) {
    //             setRoomNightsMTDData(res.data);
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     setLoadingMTDData(false);
    // }

    // const getRoomNightsYTDData = async () => {
    //     setLoadingYTDData(true);
    //     try {
    //         const res = await axios.get(`https://kernel.bookingjini.com/hotelier-summary/${current_property.hotel_id}/YTD/null/null`);

    //         if (res.data.status === 1) {
    //             setRoomNightYTDData(res.data);
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     setLoadingYTDData(false);
    // }

    return (
        <>
            <div className="dashboard__standard__header pt-1 mb-4">
                <div className="dashboard__standard__label">Room Nights</div>
                <div className="dashboard__standard__action__btns">
                    <ul className="nav nav-tabs" id="duesTab" role="tablist">
                        {roomNightTabData.map((tab) => {
                            return (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={tab}
                                    onClick={() => switchMenuTab(tab)}
                                >
                                    <a
                                        className={`nav-link ${tab === activeMenuTab ? "active" : ""
                                            }`}
                                        id={`${tab}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#${tab}`}
                                        aria-controls={`#${tab}`}
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        {tab}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            {activeMenuTab === "Today" ? (
                // Section For Today Data
                <div className="dashboard__rooms__cards mb-4">
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingTodayData} cardType={"total"} data={{ title: 'Total', value: `${total_rooms}` }} />
                    </div>
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingTodayData} cardType={"unsold"} data={{ title: 'Unsold', value: `${unsold_rooms}` }} />
                    </div>
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingTodayData} cardType={"occupieds"} data={{ title: 'Occupieds', value: `${total_rooms - unsold_rooms}` }} />
                    </div>
                    <div className="dashboard__card__box">
                        <DataCard isLoading={loadingTodayData} cardType={"occupancy"} data={{ title: 'Occupancy', value: `${occupancy} %` }} />
                    </div>
                </div>
            ) : activeMenuTab === "MTD" ? (
                // Section For MTD Data
                <div className="dashboard__rooms__cards mb-4">
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingMTDData} cardType={"total"} data={{ title: 'Total', value: `${available_room_nights}` }} />
                    </div>
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingMTDData} cardType={"unsold"} data={{ title: 'Unsold', value: `${unsold_room_nights}` }} />
                    </div>
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingMTDData} cardType={"occupieds"} data={{ title: 'Occupieds', value: `${occupied_room_nights}` }} />
                    </div>
                    <div className="dashboard__card__box">
                        <DataCard isLoading={loadingMTDData} cardType={"occupancy"} data={{ title: 'Occupancy', value: `${mtd_data.occupancy} %` }} />
                    </div>
                </div>
            ) : (
                // Section For YTD Data
                <div className="dashboard__rooms__cards mb-4">
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingYTDData} cardType={"total"} data={{ title: 'Total', value: `${roomNightYTDData.available_room_nights}` }} />
                    </div>
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingYTDData} cardType={"unsold"} data={{ title: 'Unsold', value: `${roomNightYTDData.unsold_room_nights}` }} />
                    </div>
                    <div className="dashboard__card__box me-3">
                        <DataCard isLoading={loadingYTDData} cardType={"occupieds"} data={{ title: 'Occupieds', value: `${roomNightYTDData.occupied_room_nights}` }} />
                    </div>
                    <div className="dashboard__card__box">
                        <DataCard isLoading={loadingYTDData} cardType={"occupancy"} data={{ title: 'Occupancy', value: `${roomNightYTDData.occupancy} %` }} />
                    </div>
                </div>
            )}
        </>
    )
}

export default RoomNights