import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import {
  CATCH_MESSAGE,
  UpdateSidebar,
  validatePositiveInt,
} from "../../UtilityFunctions";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";

import { toast } from "react-toastify";
import SlidingPane from "react-sliding-pane";

import Button from "../../views/buttons/Button";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import InputTextField from "../../views/inputtextfield/InputTextField";
import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";

import gemsApi from "../../API/gemsApi";
import kernelApi from "../../API/kernelApi";
import endPoints from "../../API/endPoints";

const PropertyRooms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  UpdateSidebar("Property Setup", "Rooms");

  const {
    hotelMasterRoomType
  } = endPoints.PROPERTY_ROOM_TYPE;

  // endPoints Imports
  const {
    getFloorsRoomsApi,
    addRoomApi,
    deleteRoomApi,
    updateRoomApi
  } = endPoints.PROPERTY_ROOMS;

  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector((state: RootState) => state.properties);
  const { accessData, adminAcess } = useSelector((state: RootState) => state.userAcess);

  const [allRoomTypes, setAllRoomTypes] = useState<any[]>([]);
  const [allRoomNumbers, setAllRoomNumbers] = useState<any[]>([]);
  const [activeFloorDetails, setActiveFloorDetails] = useState<any[]>([]);
  const [roomTypesCount, setRoomTypesCount] = useState<any>();

  const [openSlideEditRoom, setOpenSlideEditRoom] = useState(false);
  const [openSlideAddRooms, setOpenSlideAddRooms] = useState(false);

  const [roomInput, setRoomInput] = useState("");
  const [rooms, setRooms] = useState<string[]>([]);
  const [roomInputError, setRoomInputError] = useState("");
  const [access, setAccess] = useState(true);
  const [selectedFloor, setSelectedFloor] = useState(0);
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [selectedRoomType, setSelectedRoomType] = useState(0);
  const [selectedRoomStatus, setSelectedRoomStatus] = useState(0);

  const RoomStatus = [
    { label: "Vacant & Clean", color: "green", id: 1 },
    { label: "Occupied", color: "blue", id: 2 },
    { label: "Vacant & Dirty", color: "gray", id: 3 },
    { label: "Out of Order", color: "red", id: 4 },
  ];

  useEffect(() => {
    getAllFloorDetails();
    getAllRoomTypes();
  }, [current_property]);

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((item: any) => {
      return item?.code === "ROOM";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  // API call to get all the floors and their room details
  const getAllFloorDetails = async () => {
    try {
      let response = await gemsApi.post(`${getFloorsRoomsApi}`, {
        hotel_id: current_property.hotel_id,
        user_id: admin_id,
      });
      if (response.data.status === 1) {
        setAllRoomNumbers(response.data.allRooms);
        setActiveFloorDetails(response.data.allFloors);

        // calculate the total number of rooms for each room type
        let room_types_count: any = {};

        response.data.allFloors.forEach((floor: any) => {
          floor.rooms.forEach((room: any) => {
            if (room_types_count[room.room_type]) {
              room_types_count[room.room_type]++;
            } else {
              room_types_count[room.room_type] = 1;
            }
          });
        });

        setRoomTypesCount(room_types_count);
      } else {
        setActiveFloorDetails([]);
        setRoomTypesCount(null);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  // API call to get all room types of the current property
  const getAllRoomTypes = async () => {
    try {
      const response = await kernelApi.get(
        `/${hotelMasterRoomType}` + current_property.hotel_id
      );

      if (response.data.status === 1) {
        setAllRoomTypes(response.data.data);
      }
      // else {
      // navigate("/add-room-type");
      // }
    } catch (error) {
      console.log(error);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  const addRoom = (floorNum: number) => {
    setSelectedFloor(floorNum);
    setRoomInput("");
    setRoomInputError("");
    setSelectedRoomStatus(0);
    setOpenSlideAddRooms(true);
    setSelectedRoomType(allRoomTypes[0]?.room_type_id ?? 0);
  };

  const editRoom = (room: any, floor: number) => {
    setSelectedFloor(floor);
    setRoomInput(room.room_no);
    setSelectedRoomId(room.room_id);
    setSelectedRoomStatus(room.room_status_code);
    setSelectedRoomType(
      allRoomTypes.filter(
        (room_type) => room_type.room_type === room.room_type
      )[0]?.room_type_id || -1
    );
    setOpenSlideEditRoom(true);
    setRoomInputError("");
  };

  const onRoomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let room_type =
      allRoomTypes.filter(
        (room: any) => room.room_type_id === selectedRoomType
      )[0] || null;
    let val = e.target.value.trim();

    if (rooms.includes(val) || allRoomNumbers.includes(val)) {
      setRoomInputError("Room already added");
    } else if (room_type && room_type.total_rooms <= (roomTypesCount[room_type.room_type] || 0) + rooms.length) {
      setRoomInputError("Maximum number of rooms added for this room type");
    } else {
      setRoomInputError("");
    }

    if (validatePositiveInt(val) || !val) {
      setRoomInput(val);
    }
  };

  const onRoomEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (roomInput && !roomInputError) {
        if (!rooms.includes(roomInput) && !allRoomNumbers.includes(roomInput)) {
          setRooms([...rooms, roomInput]);
          setRoomInput("");
        }
      }
    }
  };

  const removeRoomInput = (room: string) => {
    setRooms(rooms.filter((Room) => Room !== room));
  };

  // API call for room creation
  const CreateRooms = async () => {
    if (selectedRoomType && rooms.length > 0) {
      try {
        let res = await gemsApi.post(`${addRoomApi}`, {
          user_id: admin_id,
          hotel_id: current_property.hotel_id,
          room_type_id: selectedRoomType,
          floor_no: selectedFloor,
          rooms: rooms,
        });

        if (res.data.status === 1) {
          setRooms([]);
          setRoomInput("");
          getAllFloorDetails();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
      setOpenSlideAddRooms(false);
    } else {
      toast.error("Please select all the fields");
    }
  };

  // API call to delete a room
  const DeleteRoom = async () => {
    dispatch(
      ShowConfirmationPrompt(
        "Are you sure you want to delete this room?",
        "DELETE",
        async () => {
          try {
            let response = await gemsApi.post(`${deleteRoomApi}`, {
              user_id: admin_id,
              hotel_id: current_property.hotel_id,
              room_id: selectedRoomId,
              room_status: selectedRoomStatus,
            });
            if (response.data.status === 1) {
              getAllFloorDetails();
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
            setOpenSlideEditRoom(false);
          } catch (error) {
            toast.error(CATCH_MESSAGE);
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  // API call for room update
  const UpdateRooms = async () => {
    if (selectedRoomType !== -1 && roomInput && selectedRoomStatus && !roomInputError) {
      try {
        let res = await gemsApi.post(`${updateRoomApi}`, {
          user_id: admin_id,
          hotel_id: current_property.hotel_id,
          room_id: selectedRoomId,
          room_type_id: selectedRoomType,
          floor_no: selectedFloor,
          room_no: roomInput,
          room_status: selectedRoomStatus,
        });

        if (res.data.status === 1) {
          setRooms([]);
          getAllFloorDetails();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
      setOpenSlideEditRoom(false);
    } else {
      toast.error("Please select all the fields");
    }
  };

  return (
    <>
      <div className="property__setup__roomtypes__details ps-0 ms-0 me-0 pe-0">
        <div className="roomtypes__wrapper">
          <div className="property__breadcrumbs">
            <Breadcrumbs />
            <div className="manage__property__action__right">
              <div className="room-status-container d-flex">
                {RoomStatus.map((item, index) => {
                  return (
                    <div className="status__value" key={index}>
                      <span
                        className={`status--circle ${item.color}--circle`}
                      ></span>
                      <span className={`${item.color}--title`}>
                        {item.label}
                      </span>
                    </div>
                  );
                })}
              </div>
              <Button
                className="back__btn"
                icon="bi bi-arrow-left"
                handleClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="hr--line"></div>

          <div className="accordion" id="accordionRoomTypes">
            {activeFloorDetails.length === 0 ? (
              <div className="empty__room__div mt-5">
                <i className="bi bi-x-circle"></i>
                <div>You don’t have any floors yet</div>
                <Link to="/property-setup/floors">
                  <Button
                    icon="bi bi-plus"
                    className="mt-4"
                    isAccessible={access}
                  >
                    Add
                  </Button>
                </Link>
              </div>
            ) : (
              activeFloorDetails
                .slice(0)
                .reverse()
                .map((floor: any, index: number) => {
                  if (floor.planned_for_room === "TRUE") {
                    return (
                      <div
                        key={index}
                        className={`accordion-item ${index === 0 ? "" : "accordion-item-margin-top"
                          }`}
                      >
                        <div
                          className="accordion-header"
                          id={`accordionHeading${index}`}
                        >
                          <div
                            className="accordion-button accordion__custom__heading"
                            data-bs-toggle={`${floor.rooms.length > 0 ? "collapse" : ""
                              }`}
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            {floor.floor_name}
                            {floor.rooms.length > 0 && (
                              <span className="amenities__list__item__number">
                                {`${floor.rooms.length} Room${floor.rooms.length === 1 ? "" : "s"
                                  }`}
                              </span>
                            )}
                            <span className="set--margin">
                              <Button
                                icon="bi bi-plus"
                                className="custom__btn__style"
                                handleClick={() => addRoom(floor.floor_no)}
                                isAccessible={access}
                              >
                                Add Room
                              </Button>
                            </span>
                          </div>
                        </div>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${index === 0 && floor.rooms.length > 0 ? "show" : ""
                            }`}
                          aria-labelledby={`accordionHeading${index}`}
                          data-bs-parent="#accordionRoomTypes"
                        >
                          <div className="accordion-body">
                            {floor.rooms.length === 0 ? (
                              <div className="empty__room__div">
                                <i className="bi bi-x-circle"></i>
                                <div>This floor have no rooms yet</div>
                              </div>
                            ) : (
                              <div className="room-type-container">
                                {floor.rooms.map((room: any, idx: number) => {
                                  return (
                                    <div
                                      className="colorbox-container"
                                      key={idx}
                                    >
                                      <div
                                        className={`colorbox-property ${RoomStatus.filter(
                                          (status) =>
                                            status.id ===
                                            room.room_status_code
                                        )[0]?.color
                                          }__box`}
                                      >
                                        {room.room_no}
                                        <div className="overlay"></div>
                                        <div
                                          className="circular--icon"
                                          onClick={() =>
                                            editRoom(room, floor.floor_no)
                                          }
                                        >
                                          <i className="bi bi-pencil"></i>
                                        </div>
                                      </div>
                                      <div className="box--label--font">
                                        {room.room_type}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
            )}
          </div>
        </div>
      </div>

      {/*------------------ Edit rooms ----------------*/}
      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={openSlideEditRoom}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideEditRoom(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              className="bi bi-x"
              onClick={() => setOpenSlideEditRoom(false)}
            ></i>
          </div>
          <div className="standard-page--label">Edit Room</div>
        </div>

        <div className="height-64px"></div>

        <div className="edit__address__form__container">
          <div className="standard-page--label my-4 ">
            Please select the floor
          </div>
          <div className="div__select__inputs__wrapper">
            {activeFloorDetails.map((floor: any, ind: number) => {
              if (floor.planned_for_room === "TRUE") {
                return (
                  <div
                    key={ind}
                    onClick={() => setSelectedFloor(floor.floor_no)}
                    className={`me-4 select__input__div${floor.floor_no === selectedFloor ? "__active" : ""
                      }`}
                  >
                    {floor.floor_no === selectedFloor && (
                      <i className="bi bi-check-lg"></i>
                    )}
                    {floor.floor_name}
                  </div>
                );
              }
            })}
          </div>
          <div className="standard-page--label my-4 ">Select Room Type</div>
          <div className="div__select__inputs__wrapper">
            {allRoomTypes.map((roomtype: any, ind: number) => {
              return (
                <div
                  key={ind}
                  className={`me-4 select__input__div${roomtype.room_type_id === selectedRoomType ? "__active" : ""
                    }`}
                  onClick={() => setSelectedRoomType(roomtype.room_type_id)}
                >
                  {roomtype.room_type_id === selectedRoomType && (
                    <i className="bi bi-check-lg"></i>
                  )}
                  {roomtype.room_type}
                </div>
              );
            })}
          </div>
          <div className="div__select__inputs__wrapper my-4">
            <InputTextField
              label="Edit Room Number"
              value={roomInput}
              handleChange={onRoomInputChange}
              helperText={roomInputError}
            />
          </div>
          <div className="standard-page--label my-3 ">Change Room Status</div>
          <div className="div__select__inputs__wrapper">
            {RoomStatus.map((status, id: number) => {
              return (
                <div
                  key={id}
                  className={`me-4 select__input__div${status.id === selectedRoomStatus ? "__active" : ""
                    }`}
                  onClick={() => setSelectedRoomStatus(status.id)}
                >
                  {status.id === selectedRoomStatus && (
                    <i className="bi bi-check-lg"></i>
                  )}
                  {status.label}
                </div>
              );
            })}
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          ></div>
          <div className="side__slider__panel__btn">
            <div className="__btn__wrapper">
              <Button handleClick={UpdateRooms} className="save__btn ">
                Save
              </Button>
            </div>
            <Button className="delete__btn__wrapper" handleClick={DeleteRoom}>
              <i className="bi bi-trash me-1"></i> Delete
            </Button>
          </div>
        </div>
      </SlidingPane>

      {/*-------------- Add rooms------------ */}
      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={openSlideAddRooms}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideAddRooms(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              className="bi bi-x"
              onClick={() => setOpenSlideAddRooms(false)}
            ></i>
          </div>
          <div className="standard-page--label">Add Room </div>
        </div>

        <div className="height-64px"></div>

        <div className="edit__address__form__container">
          <div className="standard-page--label mb-4">
            Please select the floor
          </div>
          <div className="div__select__inputs__wrapper">
            {activeFloorDetails.map((floor: any, ind: number) => {
              if (floor.floor_no === selectedFloor) {
                return (
                  <div key={ind} className="me-4 select__input__div__active">
                    <i className="bi bi-check-lg"></i>
                    {floor.floor_name}
                  </div>
                );
              }
            })}
          </div>
          <div className="standard-page--label my-4">Select Room Type</div>
          <div className="div__select__inputs__wrapper">
            {allRoomTypes.map((roomtype: any, ind: number) => {
              return (
                <div
                  key={ind}
                  className={`me-4 select__input__div${roomtype.room_type_id === selectedRoomType ? "__active" : ""
                    }`}
                  onClick={() => {
                    setSelectedRoomType(roomtype.room_type_id);
                    setRooms([]);
                  }}
                >
                  {roomtype.room_type_id === selectedRoomType && (
                    <i className="bi bi-check-lg"></i>
                  )}
                  {roomtype.room_type}
                </div>
              );
            })}
          </div>

          <div className="div__select__inputs__wrapper custom__wrapper__outlinedinput my-4">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="add-rooms-input">
                Add Room Numbers
              </InputLabel>
              <OutlinedInput
                id="add-rooms-input"
                label="Add Room Numbers"
                style={{ backgroundColor: "white", maxWidth: "650px" }}
                value={roomInput}
                multiline
                onChange={onRoomInputChange}
                onKeyPress={onRoomEnterPress}
                startAdornment={
                  rooms.length > 0 && (
                    <div className="rooms__input__container">
                      {rooms.map((room, id) => {
                        return (
                          <div key={id} className="room__number__div">
                            {room}
                            <i
                              className="bi bi-x icon--close"
                              onClick={() => removeRoomInput(room)}
                            ></i>
                          </div>
                        );
                      })}
                    </div>
                  )
                }
              />
              <FormHelperText className="text-danger">
                {roomInputError}
              </FormHelperText>
            </FormControl>
          </div>

          <div className="standard-page--label">
            Add your room number and press the enter button
          </div>

          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
            }}
          ></div>
          <div className="side__slider__panel__btn">
            <div className="__btn__wrapper">
              <Button handleClick={CreateRooms} className="save__btn ">
                Save
              </Button>
            </div>
          </div>
        </div>
      </SlidingPane>
    </>
  );
};

export default PropertyRooms;
