import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import { ProgressBar } from "react-step-progress-bar";

// import react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loader from "../../views/loader/Loader";
import BackButton from "../../views/buttons/Button";
import GetHelp from "../../components/gethelp/GetHelp";
import NextButton from "../../views/buttons/NextButton";
import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";

import { sha512 } from "js-sha512";
import kernelApi from "../../API/kernelApi";
import {
  AllProperties,
  CurrentProperty,
} from "../../redux/actions/PropertiesAction";

import { CATCH_MESSAGE, Logout } from "../../UtilityFunctions";
import { toast } from "react-toastify";
import endPoints from "../../API/endPoints";

const PropertyOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { company_id, admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const property_type = useSelector(
    (state: RootState) => state.add_property.new_property_type
  );
  const property_subtype = useSelector(
    (state: RootState) => state.add_property.new_property_subtype
  );
  const property_basic_details = useSelector(
    (state: RootState) => state.add_property.new_property_details
  );
  const property_address = useSelector(
    (state: RootState) => state.add_property.new_property_address
  );
  const property_location = useSelector(
    (state: RootState) => state.add_property.new_property_location
  );
  const property_amenities = useSelector(
    (state: RootState) => state.add_property.new_property_amenities
  );
  const property_images = useSelector(
    (state: RootState) => state.add_property.new_property_images
  );

  const [allCountries, setAllCountries] = useState<any[]>([]);

  const [propertyType, setPropertyType] = useState("");
  const [amenities, setAmenities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  //Endpoints Import
  const { getAllPropertyTypes,
    getAllAmenitiesApi,
    getAllHotelsByCompanies,
    addNewPropertySuccess,
    addNewPropertyNew,
    addNewProperty,
    uploadHotelImages,
    addNewPropertyPropImage } = endPoints.ADDNEWPROPERTY
  // --------------------------------

  useEffect(() => {
    if (!property_type) {
      navigate("/add-new-property/property-type");
    } else if (!property_subtype) {
      navigate("/add-new-property/property-subtype");
    } else if (
      !property_basic_details.name ||
      !property_basic_details.email ||
      !property_basic_details.mobile
    ) {
      navigate("/add-new-property/property-details");
    } else if (!property_address.address) {
      navigate("/add-new-property/property-address");
    } else {
      getAllCountries();
      getAllAmenities();
      getPropertyType();
    }
  }, []);

  // API call to get all country details
  const getAllCountries = async () => {
    try {
      const url = window.location.origin.includes("hotelkite")
        ? "hotelkite-countrydetails/getall"
        : "getAllCountries";
      const response = await kernelApi.get(url);
      if (response.data.status === 1) {
        setAllCountries(response.data.all_countries);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPropertyType = async () => {
    let response = await kernelApi.get(getAllPropertyTypes);
    if (response.data.status === 1) {
      let data = response.data.all_property_types;
      let filteredData = data.filter(
        (item: any) => item.property_type_id === property_type
      );
      setPropertyType(filteredData[0].label_name);
    }
  };

  const getAllAmenities = async () => {
    let response = await kernelApi.get(getAllAmenitiesApi);
    if (response.data.status === 1) {
      let data = response.data.all_amenities_category.filter((item: any) =>
        property_amenities.includes(item.id)
      );
      setAmenities(data);
    }
  };

  const FilterCurrentHotelInfo = async (hotel_id: number) => {
    try {
      let comp_hash = sha512(company_id.toString());
      let response = await kernelApi.get(
        `${getAllHotelsByCompanies}/${comp_hash}/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        let hotel = response.data.data.filter(
          (item: any) => item.hotel_id === hotel_id
        );

        dispatch(AllProperties(response.data));
        dispatch(CurrentProperty(hotel[0]));

        setTimeout(() => {
          navigate(addNewPropertySuccess);
        }, 400);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const SubmitNewPropertyDetails = () => {
    setLoading(true);

    // Add delay to show loader
    setTimeout(() => {
      CreateProperty();
      UploadHotelImages(2905);
    }, 500);
  };

  const CreateProperty = async () => {
    try {
      let payload = {
        admin_id: admin_id,
        company_id: company_id,
        property_type_id: property_type,
        property_sub_type_id: property_subtype,
        hotel_name: property_basic_details.name,
        mobile: property_basic_details.mobile,
        email_id: property_basic_details.email,
        land_line: property_basic_details.landline
          ? property_basic_details.landline
          : null,
        hotel_address:
          property_address.street +
          "||" +
          (property_address.flat ? property_address.flat + "||" : "") +
          property_address.city +
          "||" +
          property_address.state +
          "||" +
          property_address.country +
          "||" +
          property_address.pin,
        city_name: property_address.city,
        state_name: property_address.state,
        country_id: allCountries.filter((country: any) => {
          return country.country_name === property_address.country;
        })[0].country_id,
        pin: property_address.pin,
        property_amenities_ids: property_amenities.join(","),
        hotel_geo_location: property_location.lat + "," + property_location.lng,
      };

      const response = await kernelApi.post(addNewPropertyNew, payload, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });

      if (response.data.status === 1) {
        if (property_images.length > 0) {
          UploadHotelImages(response.data.hotel_id);
        }
        FilterCurrentHotelInfo(response.data.hotel_id);
        setTimeout(() => {
          navigate(addNewPropertySuccess);
        }, 300);
      } else {
        toast(response.data.errors, {
          hideProgressBar: true,
          type: "error",
        });
        setTimeout(() => {
          navigate(addNewProperty);
        }, 2500);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setTimeout(() => {
        navigate(addNewProperty);
      }, 2500);
    }
    // dispatch(ResetNewProperty());
  };

  const UploadHotelImages = async (hotel_id: number) => {
    try {
      let images = new FormData();
      images.append("hotel_id", hotel_id.toString());
      property_images.forEach((item: any) => {
        images.append("uploadFile[]", item);
      });

      let response = await kernelApi.post(uploadHotelImages, images);
      if (response.data.status === 1) {
        // setTimeout(() => {
        //   navigate("/add-new-property/success");
        // }, 300);
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const BackButtonClick = () => {
    navigate(addNewPropertyPropImage);
  };

  const GetImageSrc = (image: any) => {
    try {
      return URL.createObjectURL(image);
    } catch (err) {
      BackButtonClick();
    }
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <LeftSideNav>
          {loading ? (
            <></>
          ) : (
            <>
              <div className="div__user__testimonials__wrapper">
                <div className="select__property__wrapper">
                  <div className="select__property__text">
                    We are almost there, please verify the details
                  </div>
                  <div className="select__property__sub__text">
                    This details will be seen by guests when they search for a
                    place to stay.
                  </div>
                </div>
              </div>
              <div className="progress-bar-wrapper">
                <div className="progress-bar-text">5/5</div>
                <div className="progress-bar-status">
                  <ProgressBar percent={100} filledBackground="#ffffff" />
                </div>
              </div>
            </>
          )}
        </LeftSideNav>

        <RightSideContent>
          {loading ? (
            <>
              <div className="div--head--label">Creating your property</div>
              <div className="arrow-main-box">
                <div className="div--helper--text">Doing some magic..</div>
              </div>
              <div className="amenities__card__container">
                <Loader />
              </div>
            </>
          ) : (
            <>
              <BackButton
                className="logout__btn"
                handleClick={() => {
                  Logout();
                }}
              >
                logout
              </BackButton>
              <div className="property__overview__wrapper">
                <div className="property__overview__image me-4">
                  <div className="property__overview__image__slider">
                    <Slider arrows={false} dots={true} autoplay={true}>
                      {property_images.length > 0 ? (
                        property_images.map((image: any, index: number) => {
                          return (
                            <img key={index} alt="" src={GetImageSrc(image)} />
                          );
                        })
                      ) : (
                        <div className="overview__blank__image">
                          <i className="bi bi-images"></i>
                        </div>
                      )}
                    </Slider>
                  </div>
                </div>

                <div className="property__details_wrapper me-4">
                  <div className="property__name mb-2">
                    {/* {property_basic_details.name} */}
                    {property_basic_details?.name?.length < 15
                      ? property_basic_details?.name
                      : property_basic_details?.name?.slice(0, 15) + "..."}
                  </div>
                  <div className="property__type mb-3">
                    Property type : {propertyType}
                  </div>
                  <div className="property__details email--text mb-3 max--width--email">
                    <i className="bi bi-envelope"></i>{" "}
                    {/* {property_basic_details.email} */}
                    {property_basic_details?.email?.length < 18
                      ? property_basic_details?.email
                      : property_basic_details?.email?.slice(0, 18) + "..."}
                  </div>
                  <div className="property__details mb-3 max--width--phone">
                    <i className="bi bi-telephone"></i>{" "}
                    {property_basic_details.mobile}
                  </div>
                  <div className="property__address">
                    <i className="bi bi-geo-alt"></i>{" "}
                    {property_address?.address?.length < 80
                      ? property_address?.address
                      : property_address?.address?.slice(0, 80) + "..."}
                  </div>
                </div>

                <div className="property__amenities_wrapper">
                  <div className="amenities__text mt-2 my-3">Amenities</div>

                  <div className="amenities__list__wrap">
                    {amenities
                      .slice(0, 5)
                      .map((amenity: any, index: number) => {
                        return (
                          <span key={index} className="amenities__items">
                            {amenity.label}
                          </span>
                        );
                      })}
                    {amenities.length > 5 && (
                      <span className="amenities__items">5+ more</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="__btn__wrapper mt-5">
                <BackButton className="back__btn" handleClick={BackButtonClick}>
                  Back
                </BackButton>
                <NextButton
                  label="Submit"
                  className="resize_btn"
                  handleClick={SubmitNewPropertyDetails}
                />
              </div>
              <GetHelp />
            </>
          )}
        </RightSideContent>
      </div>
    </div>
  );
};

export default PropertyOverview;
