import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoginSuccess, SaveSignupInfo } from "../redux/actions/LoginAction";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import endPoints from "../API/endPoints";
import { sha512 } from "js-sha512";
import kernelApi from "../API/kernelApi";
import {
  AllProperties,
  CurrentProperty,
} from "../redux/actions/PropertiesAction";
import { Logout } from "../UtilityFunctions";


const Loginwithoutcredential = () => {

  let dispatch = useDispatch();
  const navigate = useNavigate();
  let login_data: any = useParams();

  const { getAllHotelsByCompanies } = endPoints.ADDNEWPROPERTY


  let send_login_data = { admin_id: parseInt(window.atob(login_data.admin_id)), auth_token: login_data.auth_token, company_id: parseInt(window.atob(login_data.company_id)), full_name: window.atob(login_data.full_name) }

  let hotel_id = parseInt(window.atob(login_data.hotel_id));
  let subscription_customer_id = window.atob(login_data.subscription_customer_id);


  useEffect(() => {


    if (hotel_id) {
      FilterCurrentHotelInfo(hotel_id, send_login_data.company_id, send_login_data.auth_token, send_login_data, subscription_customer_id);
    }
    else {
      dispatch(LoginSuccess(send_login_data));
      dispatch(SaveSignupInfo(subscription_customer_id));
      navigate("/select-property");
    }

  }, [hotel_id])


  const FilterCurrentHotelInfo = async (hotel_id: number, company_id: number, auth_token: any, send_login_data: any, subscription_customer_id: any) => {
    try {
      let comp_hash = sha512(company_id.toString());
      let response = await kernelApi.get(
        `${getAllHotelsByCompanies}/${comp_hash}/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );


      if (response.data.status === 1) {
        let hotel = response.data.data.filter(
          (item: any) => item.hotel_id === hotel_id
        );


        dispatch(LoginSuccess(send_login_data));
        dispatch(SaveSignupInfo(subscription_customer_id));

        dispatch(AllProperties(response.data));
        dispatch(CurrentProperty(hotel[0]));
        navigate("/");

      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <></>
  );
};

export default Loginwithoutcredential;
