import React, { useEffect, useState } from "react";
import InputMultiSelectField from "../../../../views/inputtextfield/InputMultiSelectField";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
// Importing Date Picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import Button from "../../../../views/buttons/Button";
import beApi from "../../../../API/beApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  CATCH_MESSAGE,
  validatePositiveInt,
} from "../../../../UtilityFunctions";
import endPoints from "../../../../API/endPoints";

export interface IOneHotelCouponProps {
  onClose: () => void;
  getAllCoupons: () => void;
  allRoomType: any[];
  allCouponType: any;
  couponData: any[];
}

const OneHotelCoupon: React.FC<IOneHotelCouponProps> = ({
  onClose,
  getAllCoupons,
  allRoomType,
  allCouponType,
  couponData,
}) => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { couponId } = useSelector((state: RootState) => state.manage_channels);
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [openFromDate, setFromDateOpen] = useState(false);
  const [openToDate, setToDateOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(moment());
  const [toDate, setToDate] = useState<any>(moment());
  const [allRoomOptn, setAllRoomOptn] = useState<any>([]);
  const [selectedRoomtype, setSelectedRoomtype] = useState<any>("");
  const [allCouponOptn, setAllCouponOptn] = useState<any>([]);
  const [selectedCoupontype, setSelectedCoupontype] = useState<any>("");
  const [couponName, setCouponName] = useState<any>("");
  const [couponCode, setCouponCode] = useState<any>("");
  const [discount, setDiscount] = useState<any>("");
  const [edtcouponData, setedtCouponData] = useState<any>("");
  const [discountAmntHelperText, setDiscountAmntHelperText] =
    useState<string>("");
  const [access, setAccess] = useState(true);

  // useEffect(() => {
  //   setToDate(toDate);
  //   setFromDate(fromDate);
  // }, [fromDate, toDate]);

  // useEffect(() => {
  //   setFromDate(fromDate);
  // }, [fromDate]);

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    let allRooms: string[] = ["All"];
    let allRoomsId: any[] = [0];
    allRoomType.map((room: any) => {
      allRooms.push(room?.room_type);
      allRoomsId.push(room?.room_type_id);
    });

    setAllRoomOptn({
      all_rooms: allRooms,
      room_ids: allRoomsId,
    });
    let allCoupon: string[] = [];
    for (let x in allCouponType) {
      allCoupon.push(allCouponType[x]);
    }

    setAllCouponOptn(allCoupon);
    // For Letter Capitalize
    function capitalizeFirstLetter(string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const filteredArr = couponData.filter((iteam) => {
      return iteam.coupon_id === couponId;
    });
    const [editCoupon] = filteredArr;

    setCouponName(editCoupon.coupon_name);
    setCouponCode(editCoupon.coupon_code);
    setDiscount(editCoupon.discount);
    setSelectedCoupontype(capitalizeFirstLetter(editCoupon.coupon_for));
    setSelectedRoomtype(editCoupon.room_type_id);
    setFromDate(moment(editCoupon.valid_from?.split("-")?.reverse()?.join("-")));
    // let formatChanged = editCoupon.valid_from?.split("-")?.reverse()?.join("-");
    // let month = editCoupon.valid_from?.split("-")[1];
    // console.log("moment(editCoupon.valid_from) : ", moment(formatChanged));
    // console.log("moment(editCoupon.valid_from) : ", moment("2022-07-08").format('LLLL'));
    setToDate(moment(editCoupon.valid_to?.split("-")?.reverse()?.join("-")));
    setedtCouponData(editCoupon);
  }, [allRoomType, allCouponType]);

  //Endpoints Import
  const { updateCoupons } = endPoints.MANAGECHANNEL;
  // --------------------------------

  // Select Room Type Handler
  const selectRoomtype = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRoomtype(e.target.value);
  };

  // Date Picker Handler
  const onDateChangeFrom = (date: any) => {
    setFromDate(moment(date));
  };
  const onDateChangeTo = (date: any) => {
    setToDate(moment(date));
  };

  const discountAmntHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (validatePositiveInt(value) && parseInt(value) <= 100) {
      setDiscountAmntHelperText("");
      setDiscount(value);
    } else {
      setDiscountAmntHelperText("Value Should be Between 0 to 90");
      setDiscount(value);
    }
  };

  const saveHandler = () => {
    if (discountAmntHelperText) {
      toast.error("Please Enter Valid Discount Percentage");
    } else if (toDate.isBefore(fromDate, 'day') || fromDate.isSame(toDate, 'day')) {
      console.log("isBefore : ", moment(toDate).isBefore(moment(fromDate), 'day'));
      console.log("isSame : ", moment(fromDate).isSame(moment(toDate), 'day'));
      console.log("toDate", toDate);
      console.log("fromData", fromDate);
      toast.error("Valid To Date is not valid");
    } else {
      editCouponHandler();
    }
  };

  // Edit Coupon Handler
  const editCouponHandler = async () => {
    setLoader(true);
    try {
      const editCouponRes = await beApi.post(
        `${updateCoupons}/${couponId}`,
        {
          room_type_id: selectedRoomtype,
          coupon_for: selectedCoupontype,
          coupon_name: couponName,
          coupon_code: couponCode,
          valid_from: fromDate,
          valid_to: toDate,
          discount: discount,
          hotel_id: current_property.hotel_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (editCouponRes.data.status === 1) {
        toast(editCouponRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getAllCoupons();
        onClose();
        setLoader(false);
      } else {
        toast(`${editCouponRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        onClose();
        setLoader(false);
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      onClose();
      setLoader(false);
    }
  };

  return (
    <>
    {console.log("couponData :: ", couponData)}
    {console.log("fromDate :: ", fromDate)}
    {console.log("toDate :: ", toDate)}
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit Coupon</div>
      </div>
      <div className="height-64px"></div>
      <div className="slider-content-wrapper">
        <div className="mb-4">
          <InputSelectField
            label={"Select Room Type"}
            options={allRoomOptn?.all_rooms}
            values={allRoomOptn?.room_ids}
            value={selectedRoomtype}
            handleChange={(e) => {
              selectRoomtype(e);
            }}
          />
        </div>
        <div className="mb-4">
          <InputSelectField
            label={"Coupon Type"}
            value={selectedCoupontype}
            options={allCouponOptn}
            handleChange={(e) => {
              setSelectedCoupontype(e.target.value);
            }}
          />
        </div>
        <div className="mb-2">
          <InputTextField
            label={"Coupon Name"}
            value={couponName}
            handleChange={(e) => {
              setCouponName(e.target.value);
            }}
          />
        </div>
        <div className="mb-2">
          <InputTextField
            label={"Coupon Code"}
            value={couponCode}
            handleChange={(e) => {
              setCouponCode(e.target.value);
            }}
          />
        </div>
        <div className="mb-4">
          <div className="date-picker-label">Valid From</div>
          <div className="coupon-date-picker mui__input__fieldset__legends__unset">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                open={openFromDate}
                onOpen={() => setFromDateOpen(true)}
                onClose={() => setFromDateOpen(false)}
                value={moment(fromDate).toDate()}
                onChange={onDateChangeFrom}
                minDate={new Date()}
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setFromDateOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="mb-4">
          <div className="date-picker-label">Valid To</div>

          <div className="coupon-date-picker mui__input__fieldset__legends__unset">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                open={openToDate}
                onOpen={() => setToDateOpen(true)}
                onClose={() => setToDateOpen(false)}
                value={toDate}
                onChange={onDateChangeTo}
                minDate={fromDate}
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setToDateOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="mb-4">
          <InputTextField
            label={"Discount In Percentage"}
            value={discount}
            type={"number"}
            helperText={discountAmntHelperText}
            placeholder={"Enter Discount % in Number"}
            handleChange={(e) => {
              discountAmntHandler(e);
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <Button
          handleClick={() => {
            saveHandler();
          }}
          loader={loader}
          isAccessible={access}
        >
          Save
        </Button>
        <Button
          className="cancel__btn "
          handleClick={onClose}
          isAccessible={access}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default OneHotelCoupon;
