import { SHOW_PREVIEW_BOX, HIDE_PREVIEW_BOX } from "../actionTypes";

const initialState = { visible: false }

const PreviewBoxVisibilityReducer = (state = initialState, action: any) => {
    if (action.type === SHOW_PREVIEW_BOX) {
        return {
            ...state,
            visible: true
        }
    }
    else if (action.type === HIDE_PREVIEW_BOX) {
        return {
            ...state,
            visible: false
        }
    }

    else return state

}

export default PreviewBoxVisibilityReducer