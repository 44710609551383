import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "../../../views/buttons/Button";
import InputSelectField from "../../../views/inputtextfield/InputSelectField";
import Loader from "../../../views/loader/Loader";
import SlidingPane from "react-sliding-pane";
import EditRatePlan from "./EditRatePlan";
import AddRatePlan from "./AddRatePlan";
import {
  CATCH_MESSAGE,
  Logout,
  UpdateSidebar,
} from "../../../UtilityFunctions";
import kernelApi from "../../../API/kernelApi";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import {
  ShowConfirmationPrompt,
  CancelConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import Toast from "../../../components/toaster/Toast";
import endPoints from "../../../API/endPoints";

const RatePlan = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  // endPoints Imports
  const { masterRatePlanDetails, masterRatePlanAll, deleteRoomRatePlanApi } =
    endPoints.PROPERTY_RATE_PLAN;

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [editRatePlanPanel, setEditRatePlanPanel] = useState(false);
  const [addRatePlanPanel, setAddRatePlanPanel] = useState(false);
  const [planId, setplanId] = useState<any>("");
  const [editPlanData, setEditPlanData] = useState<any>("");
  const [allPlanTypeOptn, setAllPlanTypeOptn] = useState<any[]>([]);
  const [planData, setPlanData] = useState<any[]>([]);
  UpdateSidebar("Property Setup", "Rate Plan");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    getAllMasterPlan();
    fetchRatePlan();
  }, []);

  //   Get All Master Plan
  const getAllMasterPlan = async () => {
    try {
      let allMasterPlanRes = await kernelApi.get(`${masterRatePlanDetails}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      if (allMasterPlanRes?.data?.status === 1) {
        const allPlans = allMasterPlanRes?.data?.data.map((iteam: any) => {
          return iteam.m_rate_plan;
        });
        setAllPlanTypeOptn(allPlans);
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  //   Get All Master Plan || All Plans Data
  const fetchRatePlan = async () => {
    try {
      let fetchRatePlanRes = await kernelApi.get(
        `${masterRatePlanAll}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (fetchRatePlanRes?.data?.status === 1) {
        setPlanData(fetchRatePlanRes?.data?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error: any) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const deleteRatePrompt = (plan_id, item) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure you want to delete ${item}`,
        "DELETE",
        () => {
          deleteRatePlan(plan_id);
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  //Delete Rate Plan
  const deleteRatePlan = async (plan_id) => {
    try {
      let fetchRatePlanRes = await kernelApi.delete(
        `${deleteRoomRatePlanApi}${plan_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (fetchRatePlanRes?.data?.status === 1) {
        Toast(fetchRatePlanRes.data.message);
        fetchRatePlan();
      }
    } catch (error: any) {
      Toast(CATCH_MESSAGE, "error");
      setLoader(false);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <div>
      <div className="property__breadcrumbs">
        <Breadcrumbs />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          <Button
            icon="bi bi-plus-lg"
            handleClick={() => {
              setAddRatePlanPanel(true);
            }}
          >
            Add
          </Button>

          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>

      <div className="list-view-table-wrapper_rateplan mb-4">
        <table className="table table-borderless_rateplan">
          <thead>
            <tr className="listview__table__heading">
              <th>Sl.No</th>
              <th>Plan Type</th>
              <th>Plan Name</th>
              <th className="edit_rate_plan">Edit</th>
              <th className="edit_rate_plan">Delete</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={8}>
                  <div>
                    <Loader className="d-flex justify-content-center align-items-center" />
                  </div>
                </td>
              </tr>
            ) : (
              planData.map((item: any, idx: any) => (
                <tr className="listview__table__data" key={item.rate_plan_id}>
                  <td>
                    <div className="serial-number">{idx + 1}</div>
                  </td>
                  <td>
                    <div className="customer-details plan-type">
                      {item?.plan_type}
                    </div>
                  </td>
                  <td>
                    <div className="customer-details">{item?.plan_name}</div>
                  </td>

                  <td>
                    <div className="d-flex justify-content-end">
                      {/* ---------------- */}
                      <div
                        className="customer-view-button"
                        onClick={() => {
                          setEditRatePlanPanel(true);
                          setplanId(item.rate_plan_id);
                          setEditPlanData(item);
                          //   dispatch(SetCouponId(item.coupon_id));
                        }}
                      >
                        Edit
                      </div>
                      {/* ---------------- */}
                      {/* <div
                        className="customer-delete-button"
                        onClick={() =>
                          deleteRatePrompt(item?.rate_plan_id, item?.plan_name)
                        }
                      >
                        Delete
                      </div> */}
                      {/* ---------------- */}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end">
                      {/* ---------------- */}
                      {/* <div
                        className="customer-view-button"
                        onClick={() => {
                          setEditRatePlanPanel(true);
                          setplanId(item.rate_plan_id);
                          setEditPlanData(item);
                          //   dispatch(SetCouponId(item.coupon_id));
                        }}
                      >
                        Edit
                      </div> */}
                      {/* ---------------- */}
                      <div
                        className="customer-delete-button"
                        onClick={() =>
                          deleteRatePrompt(item?.rate_plan_id, item?.plan_name)
                        }
                      >
                        Delete
                      </div>
                      {/* ---------------- */}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {/* For Pagination */}

        {/* <div className="list--view--pagination">
    <div className="pagination__left">
      <div className="pagination__dropdown">
        <InputSelectField
          label="Select"
          value={selectPageSize}
          options={pageSize}
          handleChange={(e) => {
            setSelectPageSize(e.target.value);
            setNoOfPages(Math.ceil(couponData.length / e.target.value));
            setCurrentPageNo(1);
          }}
        />
      </div>
      <ul>
        {Array(noOfPages)
          .fill(0)
          .map((p, i) => {
            return (
              <li
                key={i}
                onClick={() => setCurrentPageNo(i + 1)}
                className={`${
                  i + 1 === currentPageNo
                    ? "active__page"
                    : "showing__page__number"
                }`}>
                {i + 1}
              </li>
            );
          })}
      </ul>
    </div>
    <div className="pagination__right">
      <div className="arrow__container me-3" onClick={leftArrowClick}>
        <i className="bi bi-chevron-left font--weight"></i>
      </div>
      <div className="arrow__container" onClick={rightArrowClick}>
        <i className="bi bi-chevron-right font--weight"></i>
      </div>
    </div>
  </div> */}
      </div>

      {/* Sliding Pane for Add Rate Plan */}
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={addRatePlanPanel}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setAddRatePlanPanel(false);
        }}
      >
        <AddRatePlan
          onClose={() => {
            setAddRatePlanPanel(false);
          }}
          allPlanTypeOptn={allPlanTypeOptn}
          fetchRatePlan={fetchRatePlan}
        />
      </SlidingPane>

      {/* Sliding Pane for Edit Rate Panel */}
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={editRatePlanPanel}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setEditRatePlanPanel(false);
        }}
      >
        <EditRatePlan
          onClose={() => {
            setEditRatePlanPanel(false);
          }}
          planId={planId}
          editPlanData={editPlanData}
          allPlanTypeOptn={allPlanTypeOptn}
          fetchRatePlan={fetchRatePlan}
        />
      </SlidingPane>
    </div>
  );
};

export default RatePlan;
