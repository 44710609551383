import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DisableSwitchPropertyScreen } from '../redux/actions/PromptAction';
import { RootState } from '../redux/store';
import endPoints from "../API/endPoints";
import Loader from '../views/loader/Loader';
import kernelApi from '../API/kernelApi';
import { SubscriptionDaysLeft } from "../redux/actions/LoginAction";

const SwitchProperty = () => {
    const dispatch = useDispatch();
    const { isSwitchingProperty } = useSelector((state: RootState) => state.prompt);
    const { current_property } = useSelector((state: RootState) => state.properties);
    const { isPlanSubscribed } = endPoints.DASHBOARD;

    useEffect(() => {
        let overlayEl = document.getElementById("overlay");
        if (isSwitchingProperty) {
            overlayEl?.classList.remove("d-none");
            overlayEl?.classList.add("animate__fadeIn");
            SelectProperty();

            setTimeout(() => {
                overlayEl?.classList.remove("animate__fadeIn");
                overlayEl?.classList.add("animate__fadeOut");
                setTimeout(() => {
                    overlayEl?.classList.remove("animate__fadeOut");
                    overlayEl?.classList.add("d-none");
                    dispatch(DisableSwitchPropertyScreen());
                }, 1000);
            }, 5000);

        } else {
            setTimeout(() => {
                overlayEl?.classList.remove("animate__fadeIn");
                overlayEl?.classList.add("animate__fadeOut");
                setTimeout(() => {
                    overlayEl?.classList.remove("animate__fadeOut");
                    overlayEl?.classList.add("d-none");
                }, 1000);
            }, 2000);
        }
    }, [isSwitchingProperty]);

    const SelectProperty = async () => {
        try {
            const response = await kernelApi.get(
                `${isPlanSubscribed}/${current_property.hotel_id}`
            );
            if (response.data.status === 1) {
                dispatch(SubscriptionDaysLeft(response.data.days_left))
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div id="overlay" className="standard__switch__property__wrapper animate__animated animate__faster d-none">
            <div className="switch__property__content__wrapper">
                <div className="div--head--label">Switching Property to <span>{current_property.hotel_name}</span></div>
                <Loader />
                <div className="div--helper--text mt-2">Please wait...</div>
            </div>
        </div>
    )
}

export default SwitchProperty;