import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import endPoints from "../../API/endPoints";
import gemsApi from "../../API/gemsApi";
import { SaveStayDetails } from "../../redux/actions/BookingsAction";
import { RootState } from "../../redux/store";
import { CATCH_MESSAGE, validatePositiveInt } from "../../UtilityFunctions";
import Button from "../../views/buttons/Button";
import InputTextField from "../../views/inputtextfield/InputTextField";

interface IStayDetailsAddBillsProps {
  onClose: () => void;
  room_id: string;
  billTyp: string;
  balance: any;
}

const StayDetailsAddBills: React.FC<IStayDetailsAddBillsProps> = ({
  room_id,
  onClose,
  billTyp,
  balance,
}) => {
  const dispatch = useDispatch();

  const { admin_id } = useSelector((state: RootState) => state.auth);
  const { stay_details } = useSelector((state: RootState) => state.bookings);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const BILL_TYPES = ["Due", "Collection", "Payment"];
  const ITEM_CATEGORIES = ["Food & Beverages", "Room Charges", "Others"];

  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(moment());
  const [billType, setBillType] = useState(BILL_TYPES[0]);
  const [payModes, setPayModes] = useState<any[]>([]);

  const [itemCategory, setItemCategory] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemDiscount, setItemDiscount] = useState("");
  const [itemTax, setItemTax] = useState("");
  const [paymentAmount, setPaymentAmount] = useState<any>("");
  const [collectionAmount, setCollectionAmount] = useState<any>("");
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const {
    paymentModeGet,
    expenseAdd,
    collectionAdd,
    addPayment,
    stayDetailsGetByBookingID,
  } = endPoints.BOOKINGS;

  useEffect(() => {
    if (billTyp.toLowerCase() === "dues") {
      setBillType(BILL_TYPES[0]);
    } else if (billTyp.toLowerCase() === "collections") {
      setBillType(BILL_TYPES[1]);
    } else {
      setBillType(BILL_TYPES[2]);
    }
  }, [billTyp]);

  useEffect(() => {
    if (Math.sign(balance) === -1) {
      setPaymentAmount(Math.abs(balance).toString());
    } else {
      setCollectionAmount(Math.abs(balance).toString());
    }
  }, [balance]);

  useEffect(() => {
    getPaymentModes();
  }, []);

  const getPaymentModes = async () => {
    try {
      const response = await gemsApi.get(`${paymentModeGet}`);

      if (response.status === 200) {
        setPayModes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onBillTypeChange = (bill: string) => {
    setBillType(bill);
    setItemName("");
    setItemPrice("");
    setItemQuantity("");
    setItemDiscount("");
    setItemTax("");
    setCollectionAmount("");
    setPaymentAmount("");
    setDescription("");
    setItemCategory("");
    setPaymentMode("");
  };

  const onDateChange = (date: any) => {
    setFromDate(moment(date));
  };

  const onItemPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if ((validatePositiveInt(val) && val.length < 9) || val === "") {
      setItemPrice(val);
    }
  };

  const onItemQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if ((validatePositiveInt(val) && val.length < 5) || val === "") {
      setItemQuantity(val);
    }
  };

  const onItemDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (
      val.match(/^100$|^100.00$|^([0-9]{0,2}(?:[\.][0-9]*)?|\.[0-9]+)$/) &&
      (val.split(".").length !== 1 ? val.split(".")[1].length < 3 : true)
    ) {
      setItemDiscount(val);
    }
  };

  const onItemTaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (
      val.match(/^100$|^100.00$|^([0-9]{0,2}(?:[\.][0-9]*)?|\.[0-9]+)$/) &&
      (val.split(".").length !== 1 ? val.split(".")[1].length < 3 : true)
    ) {
      setItemTax(val);
    }
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if ((validatePositiveInt(val) && val.length < 9) || val === "") {
      billType === "Collection"
        ? setCollectionAmount(val)
        : setPaymentAmount(val);
    }
  };

  const onReferenceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val.length < 25 || val === "") {
      setReferenceNumber(val);
    }
  };

  const saveDueTransaction = async () => {
    if (
      fromDate &&
      itemCategory &&
      itemName &&
      itemPrice &&
      itemQuantity &&
      itemDiscount &&
      itemTax
    ) {
      try {
        let payload = {
          hotel_id: current_property.hotel_id,
          booking_id: stay_details.booking_data.booking_id,
          room_no: stay_details.room_data.room_no,
          room_id: room_id,
          transaction_date: fromDate.format("YYYY-MM-DD"),
          item_category: itemCategory,
          transaction_item: itemName,
          transaction_amount: itemPrice,
          transaction_item_quantity: itemQuantity,
          discount_percentage: itemDiscount,
          tax_percentage: itemTax,
          user_id: admin_id,
        };

        const response = await gemsApi.post(`${expenseAdd}`, payload);

        if (response.data.status === 1) {
          toast.success(response.data.message);
          getStayDetailsByBookingId();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const saveCollectionTransaction = async () => {
    if (fromDate && collectionAmount && paymentMode) {
      try {
        let payload = {
          hotel_id: current_property.hotel_id,
          booking_id: stay_details.booking_data.booking_id,
          room_no: stay_details.room_data.room_no,
          room_id: room_id,
          transaction_date: fromDate.format("YYYY-MM-DD"),
          remark: description,
          collection_amount: collectionAmount,
          collection_mode: paymentMode,
          receipt_number: referenceNumber,
          user_id: admin_id,
        };

        const response = await gemsApi.post(`${collectionAdd}`, payload);

        if (response.data.status === 1) {
          toast.success(response.data.message);
          getStayDetailsByBookingId();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const savePaymentTransaction = async () => {
    if (fromDate && paymentAmount && paymentMode && description) {
      try {
        let payload = {
          hotel_id: current_property.hotel_id,
          booking_id: stay_details.booking_data.booking_id,
          room_no: stay_details.room_data.room_no,
          room_id: room_id,
          transaction_date: fromDate.format("YYYY-MM-DD"),
          remark: description,
          payment_amount: paymentAmount,
          payment_mode: paymentMode,
          receipt_number: referenceNumber,
          user_id: admin_id,
        };

        const response = await gemsApi.post(`${addPayment}`, payload);

        if (response.data.status === 1) {
          toast.success(response.data.message);
          getStayDetailsByBookingId();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const getStayDetailsByBookingId = async () => {
    try {
      let payload = {
        user_id: admin_id,
        hotel_id: current_property.hotel_id,
        room_id: room_id,
        booking_id: stay_details.booking_data.booking_id,
      };

      const response = await gemsApi.post(
        `${stayDetailsGetByBookingID}`,
        payload
      );

      if (response.data.status === 1) {
        dispatch(SaveStayDetails(response.data));
      }
    } catch (error) {
      toast.error("Could not fetch stay details");
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i onClick={onClose} className="bi bi-x"></i>
        </div>
        <div className="standard-page--label">Add Bill</div>
      </div>

      <div className="height-64px"></div>

      <div className="bill__clearance__bill__slider__wrapper">
        <div className="standard-page--label my-3">Bill Type</div>

        <div className="select__input__label mb-3">
          {BILL_TYPES.map((type) => {
            return (
              <div
                key={type}
                onClick={() => onBillTypeChange(type)}
                className={`me-4 mb-3 select__input__div${
                  billType === type ? "__active" : ""
                }`}
              >
                {billType === type && (
                  <i className="bi bi-check-lg icon--check"></i>
                )}
                {type}
              </div>
            );
          })}
        </div>

        <div className="standard-page--label mb-3">Select Date</div>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label=""
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={fromDate}
            onChange={onDateChange}
            minDate={new Date()}
            inputFormat="dd MMM yyyy"
            InputAdornmentProps={{ position: "start" }}
            disableMaskedInput
            renderInput={(params) => (
              <TextField
                {...params}
                onClick={() => setOpen(true)}
                onKeyDown={(e) => e.preventDefault()}
              />
            )}
          />
        </LocalizationProvider>

        {billType === "Due" && (
          <>
            <div className="standard-page--label my-3">Item Category</div>

            <div className="select__input__label mb-3">
              {ITEM_CATEGORIES.map((category) => {
                return (
                  <div
                    key={category}
                    onClick={() => setItemCategory(category)}
                    className={`me-4 mb-3 select__input__div${
                      itemCategory === category ? "__active" : ""
                    }`}
                  >
                    {itemCategory === category && (
                      <i className="bi bi-check-lg icon--check"></i>
                    )}
                    {category}
                  </div>
                );
              })}
            </div>

            <div className="due__bill__collection__item__name mt-4">
              <InputTextField
                label="Item Name"
                value={itemName}
                handleChange={(e) => setItemName(e.target.value)}
              />
            </div>

            <div className="due__bill__collection__amount__wrapper my-3">
              <div className="due__bill__colleection__amount me-3">
                <InputTextField
                  label="Item Price"
                  value={itemPrice}
                  handleChange={onItemPriceChange}
                />
              </div>

              <div className="due__bill__colleection__amount">
                <InputTextField
                  label="Item Quantity"
                  value={itemQuantity}
                  handleChange={onItemQuantityChange}
                />
              </div>
            </div>

            <div className="due__bill__collection__amount__wrapper my-3">
              <div className="due__bill__colleection__amount me-3">
                <InputTextField
                  label="Discount in %"
                  value={itemDiscount}
                  handleChange={onItemDiscountChange}
                />
              </div>

              <div className="due__bill__colleection__amount">
                <InputTextField
                  label="Tax in %"
                  value={itemTax}
                  handleChange={onItemTaxChange}
                />
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            >
              {" "}
            </div>

            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper justify-content-between w-100">
                <Button
                  handleClick={saveDueTransaction}
                  className="primary-btn"
                >
                  Save
                </Button>
                <Button
                  className="primary-btn delete__btn__wrapper"
                  handleClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}

        {billType !== "Due" && (
          <>
            <div className="bill__collection__amount pt-3 mt-4">
              <InputTextField
                label="Amount"
                value={
                  billType === "Collection" ? collectionAmount : paymentAmount
                }
                handleChange={onAmountChange}
              />
            </div>

            <div className="bill__collection__description mt-4">
              <InputTextField
                label="Description"
                value={description}
                handleChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="standard-page--label my-3">Select Mode</div>

            <div className="select__input__label mb-3">
              {payModes.map((mode) => {
                return (
                  <div
                    key={mode.id}
                    onClick={() => setPaymentMode(mode.value)}
                    className={`me-4 mb-3 select__input__div${
                      paymentMode === mode.value ? "__active" : ""
                    }`}
                  >
                    {paymentMode === mode.value && (
                      <i className="bi bi-check-lg icon--check"></i>
                    )}
                    {mode.value}
                  </div>
                );
              })}
            </div>

            <div className="bill__collection__description mt-4">
              <InputTextField
                label="Enter Reference Number"
                value={referenceNumber}
                handleChange={onReferenceNumberChange}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>

            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper justify-content-between w-100">
                <Button
                  handleClick={
                    billType === "Collection"
                      ? saveCollectionTransaction
                      : savePaymentTransaction
                  }
                  className="primary-btn"
                >
                  Save
                </Button>
                <Button
                  className="primary-btn delete__btn__wrapper"
                  handleClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StayDetailsAddBills;
