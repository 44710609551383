import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import { CATCH_MESSAGE, UpdateSidebar } from "../../../../UtilityFunctions";
import Button from "../../../../views/buttons/Button";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import SlidingPane from "react-sliding-pane";
import OneHotelCoupon from "./OneHotelCoupon";
import MultiHotelCoupon from "./MultiHotelCoupon";
import kernelApi from "../../../../API/kernelApi";
import { toast } from "react-toastify";
import beApi from "../../../../API/beApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Loader from "../../../../views/loader/Loader";
import EditCoupon from "./EditCoupon";
import {
  SetAllRoomTypes,
  SetCouponId,
} from "../../../../redux/actions/ManageChannell";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import endPoints from "../../../../API/endPoints";

const Coupon = () => {
  // Data From Redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { be_url } = useSelector((state: RootState) => state.beUrl);
  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  // States
  const [selected, setSelected] = useState("");
  const [oneHtlPanel, setOneHtlPanel] = useState(false);
  const [multiHtlPanel, setmultiHtlPanel] = useState(false);
  const [editCouponPanel, setEditCouponPanel] = useState(false);
  const [couponData, setCouponData] = useState<any[]>([]);
  const [allRoomType, setAllRoomType] = useState<any[]>([]);
  const [allCouponType, setAllCouponType] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [access, setAccess] = useState(true);
  const [nodataMessage, setNoDataMessage] = useState<string>("");
  const pageSize = [10, 15, 20, 25, 30];
  UpdateSidebar("Manage Channels", "Booking Engine");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Endpoints Import
  const {
    getAllRoomTypesApi,
    allCouponTypes,
    getAllCouponsApi,
    deleteCouponsApi,
  } = endPoints.MANAGECHANNEL;
  // --------------------------------

  // Check if user has access to this page
  useEffect(() => {
    const status = accessData.filter((iteam) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData, adminAcess]);

  useEffect(() => {
    if (selected === "One Hotel") {
      setOneHtlPanel(true);
      setmultiHtlPanel(false);
      setSelected("Add");
    } else if (selected === "Multiple Hotel") {
      setmultiHtlPanel(true);
      setOneHtlPanel(false);
      setSelected("Add");
    }
  }, [selected]);

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };
  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  useEffect(() => {
    if (couponData?.length > 0) {
      setNoOfPages(Math.ceil(couponData?.length / selectPageSize));
    }
  }, [selectPageSize, couponData]);

  // Get All Room Types
  const getAllRoomTypes = async () => {
    try {
      const allRoomTypeRes = await kernelApi.get(
        `${getAllRoomTypesApi}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (allRoomTypeRes.data.status === 1) {
        setAllRoomType(allRoomTypeRes.data.data);
        dispatch(SetAllRoomTypes(allRoomTypeRes.data.data));
        setTimeout(() => setLoader(false), 1000);
      } else {
        setAllRoomType([]);
        setTimeout(() => setLoader(false), 1000);
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  // Get All Coupon Types
  const getAllCouponTypes = async () => {
    try {
      const allCouponTypeRes = await beApi.get(allCouponTypes, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });
      if (allCouponTypeRes.data.status === 1) {
        setAllCouponType(allCouponTypeRes.data.data);
        setTimeout(() => setLoader(false), 1000);
      } else {
        setAllCouponType([]);
        setTimeout(() => setLoader(false), 1000);
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };

  //  Get All Coupon
  const getAllCoupons = async () => {
    setLoader(true);
    try {
      const couponRes = await beApi.get(
        `${getAllCouponsApi}/${current_property.hotel_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (couponRes.data.status === 1) {
        setCouponData(couponRes.data.data);
        console.log("couponRes.data.data : ", couponRes.data.data);
        setTimeout(() => setLoader(false), 1000);
      } else {
        setCouponData([]);
        setNoDataMessage(couponRes.data.message);
        setTimeout(() => setLoader(false), 1000);
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(true);
    getAllCoupons();
    getAllRoomTypes();
    getAllCouponTypes();
  }, [current_property]);

  // Delet Coupon Handler
  const deletCoupon = async (id: any) => {
    setLoader(true);
    try {
      const deletReq = await beApi.delete(
        `${deleteCouponsApi}/${id}`,

        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (deletReq.data.status === 1) {
        toast(`${deletReq.data.message} ✔`, {
          hideProgressBar: true,
          type: "success",
        });
        setLoader(false);
        getAllCoupons();
      } else {
        toast(`${deletReq.data.message} `, {
          hideProgressBar: true,
          type: "error",
        });
        setLoader(false);
        getAllCoupons();
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      setLoader(false);
      getAllCoupons();
    }
  };

  return (
    <div>
      {/* ------------------------ */}
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            {
              label: "Booking Engine",
              href: "/manage-channels/booking-engine",
            },
            { label: "Coupon" },
          ]}
        />
        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          {access && (
            <div style={{ minWidth: "180px" }}>
              <InputSelectField
                label={"Choose"}
                value=""
                options={["One Hotel", "Multiple Hotel"]}
                handleChange={(e) => {
                  setSelected(e.target.value);
                }}
              />
            </div>
          )}

          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div>
      {/* ------------------------ */}

      {loader ? (
        <div className="loader-setup">
          <Loader />
        </div>
      ) : couponData?.length > 0 ? (
        <div className="list-view-table-wrapper mb-4">
          <table className="table table-borderless">
            <thead>
              <tr className="listview__table__heading">
                <th>S.No</th>

                <th>Coupon Code</th>
                <th>Room Type</th>
                <th>Coupon Type</th>
                <th>Valid From</th>
                <th>Valid To</th>
                <th>Discount %</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={8}>
                    <div>
                      <Loader className="d-flex justify-content-center align-items-center" />
                    </div>
                  </td>
                </tr>
              ) : (
                couponData
                  .slice(
                    selectPageSize * (currentPageNo - 1),
                    selectPageSize * currentPageNo
                  )
                  .map((item: any, idx: any) => (
                    <tr className="listview__table__data" key={item.coupon_id}>
                      <td>
                        <div className="serial-number">{idx + 1}</div>
                      </td>
                      <td>
                        <div className="customer-details">
                          {item.coupon_code}
                        </div>
                      </td>
                      <td>
                        <div className="customer-details">{item.room_type}</div>
                      </td>
                      <td>
                        <div className="customer-details">
                          {item.coupon_for}
                        </div>
                      </td>
                      <td>
                        <div className="customer-details">
                          {item.valid_from_hrf?.split("-")?.reverse()?.join("-")}
                        </div>
                      </td>
                      <td>
                        <div className="customer-details">
                          {item.valid_to_hrf?.split("-")?.reverse()?.join("-")}
                        </div>
                      </td>
                      <td>
                        <div className="customer-details">{item.discount}</div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div
                            className="customer-view-button"
                            onClick={() => {
                              setEditCouponPanel(true);
                              dispatch(SetCouponId(item.coupon_id));
                            }}
                          >
                            Edit
                          </div>
                          {access && (
                            <div
                              className="customer-delete-button"
                              onClick={() => {
                                deletCoupon(item.coupon_id);
                              }}
                            >
                              Delete
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>

          <div className="list--view--pagination">
            <div className="pagination__left">
              <div className="pagination__dropdown">
                <InputSelectField
                  label="Select"
                  value={selectPageSize}
                  options={pageSize}
                  handleChange={(e) => {
                    setSelectPageSize(e.target.value);
                    setNoOfPages(Math.ceil(couponData.length / e.target.value));
                    setCurrentPageNo(1);
                  }}
                />
              </div>
              <ul>
                {Array(noOfPages)
                  .fill(0)
                  .map((p, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => setCurrentPageNo(i + 1)}
                        className={`${i + 1 === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                          }`}
                      >
                        {i + 1}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="pagination__right">
              <div className="arrow__container me-3" onClick={leftArrowClick}>
                <i className="bi bi-chevron-left font--weight"></i>
              </div>
              <div className="arrow__container" onClick={rightArrowClick}>
                <i className="bi bi-chevron-right font--weight"></i>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty__room__div">
          <i className="bi bi-x-circle mb-3"></i>
          <div>{nodataMessage}</div>
        </div>
      )}

      {/* Sliding Pane for Single Hotel */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={oneHtlPanel}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOneHtlPanel(false);
        }}
      >
        <OneHotelCoupon
          onClose={() => {
            setOneHtlPanel(false);
          }}
          getAllCoupons={getAllCoupons}
          allRoomType={allRoomType}
          allCouponType={allCouponType}
        />
      </SlidingPane>

      {/* Sliding Pane for Multi Hotel */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={multiHtlPanel}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setmultiHtlPanel(false);
        }}
      >
        <MultiHotelCoupon
          onClose={() => {
            setmultiHtlPanel(false);
          }}
          getAllCoupons={getAllCoupons}
          allRoomType={allRoomType}
          allCouponType={allCouponType}
        />
      </SlidingPane>
      {/* Sliding Pane for Edit Coupon */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editCouponPanel}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setEditCouponPanel(false);
        }}
      >
        <EditCoupon
          onClose={() => {
            setEditCouponPanel(false);
          }}
          getAllCoupons={getAllCoupons}
          allRoomType={allRoomType}
          allCouponType={allCouponType}
          couponData={couponData}
        />
      </SlidingPane>
    </div>
  );
};

export default Coupon;
