import React, { useEffect, useState } from "react";
import { UpdateSidebar } from "../../UtilityFunctions";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

// import app banner screen
import JiniBanner from "../../assets/img/jini-assist.png";
import CRSBanner from "../../assets/img/crs-banner.png";
import RateShopperBanner from "../../assets/img/rate-shopper.png";
import { useSelector } from "react-redux";
import kernelApi from "../../API/kernelApi";
import { RootState } from "../../redux/store";
import { useLocation, useNavigate } from "react-router";
import { sha512 } from "js-sha512";

const AppList = () => {
  UpdateSidebar("Apps");

  const navigate = useNavigate();
  const location = useLocation();

  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, company_id, auth_token, full_name } = useSelector(
    (state: RootState) => state.auth
  );
  const [appList, setAppList] = useState<any[]>([]);

  useEffect(() => {
    getAppList();
  }, [current_property]);

  const getAppList = async () => {
    try {
      const res = await kernelApi.get(
        `/subscription-details/${current_property.hotel_id}`
      );

      if (res.data.status === 1) {
        setAppList(res.data?.apps);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const passAppId = (appId: number) => {
    navigate("app-details", { state: { app_id: appId } });
  };

  const getIntoApp = async (app_id: string) => {
    try {
      const res = await kernelApi.get(
        `/app-details/${current_property.hotel_id}/${app_id}`
      );

      if (res.data.status === 1) {
        appLogin(res.data?.selected_app.app_url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const appLogin = async (url: string) => {
    if (url) {
      try {
        let comp_hash = sha512(company_id.toString());
        window.open(
          `${url}/${window.btoa(company_id)}/${comp_hash}/${window.btoa(
            current_property.hotel_id
          )}/${window.btoa(admin_id)}/${auth_token}/${window.btoa(full_name)}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
      </div>

      <div className="hr--line"></div>

      {/* Search Section */}
      {/* <div className="search__app__wrapper mb-4">
        <i className="bi bi-search"></i>
        <input placeholder="Search Apps"></input>
      </div> */}

      {/* App Promotion Banner Section */}
      <div className="apps__list__banner__section mt-3 pt-2 mb-3">
        <div className="jini__assist__wrapper me-3">
          <img src={JiniBanner} alt="Bookingjini jini assist banner" />
        </div>
        <div className="manage__crs__wrapper me-3">
          <img src={CRSBanner} alt="CRS app banner" />
        </div>
        <div className="rate__shopper__wrapper">
          <img src={RateShopperBanner} alt="Rate shopper banner" />
        </div>
      </div>

      {/* All App section */}
      <div className="standard-page--label mt-5 mb-4 app--heading--text">
        All Apps
      </div>

      <div className="popular__app__wrapper mb-3">
        {appList?.map((appListData: any) => {
          return (
            <div
              key={appListData.id}
              className="popular__app__card mb-3"
              onClick={() => passAppId(appListData.id)}
            >
              <div className="popular__app__card__box ms-0">
                <div className="popular__app__icon bj_app--bg--blue--color">
                  {/* {appListData?.is_subscribed === 1 && appListData?.app_url !== "#" ? ( */}
                  {appListData?.is_subscribed === 1 ? (
                    <div className="app__added">
                      <div className="app__added__icon">
                        <i className="bi bi-check"></i>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="app__image__box">
                    <img src={appListData?.app_image} />
                  </div>
                </div>

                <div className="popular__app__text__content">
                  <div className="popular__app__name__wrapper mb-2">
                    <div className="__bookingjini__icon me-4"></div>
                    <div className="popular__app__name">
                      <div className="app__name">{appListData?.app_name}</div>
                      <div className="label__from__bookingjini">
                        Bookingjini
                      </div>
                    </div>
                  </div>

                  <div className="popular__app__description">
                    {appListData?.app_info}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* My App Section */}
      <div className="app__list__label__navigation mt-5 mb-4">
        <div className="standard-page--label app--heading--text">My Apps</div>
      </div>

      <div className="app__list__latest__app__section mb-5">
        {appList?.map((appListData: any) => {
          if (appListData.is_subscribed == 1) {
            return (
              <div
                key={appListData.id}
                className="latest__app__card mb-3"
                onClick={() => getIntoApp(appListData.id)}
              >
                <div className="latest__app__content__section ms-0">
                  <div className="my__app__list__wrapper">
                    <div className="latest__app__icon bj_app--bg--blue--color">
                      <div className="app__image__box">
                        <img src={appListData?.app_image} />
                      </div>
                    </div>
                    <div className="latest__app__text__content ms-3">
                      <div className="latest__app__name">
                        {appListData?.app_name}
                      </div>
                      <div className="app__label__from__bookingjini">
                        Bookingjini
                      </div>
                      <div className="app__label__ratings">
                        {appListData?.app_rating}
                        <span className="ms-2 star__rating__wrapper">
                          {Array(5)
                            .fill(0)
                            .map((_, idx) => {
                              const star = idx + 1;
                              const rating = appListData?.app_rating || 5;
                              return (
                                <i
                                  key={idx}
                                  className={`bi bi-star${star <= rating
                                    ? "-fill"
                                    : star - rating < 1
                                      ? "-half"
                                      : ""
                                    }`}
                                ></i>
                              );
                            })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            <div className="no-data-label-container">
              <div className="icon-container">
                <i className="bi bi-x-circle mb-3"></i>
              </div>

              <div className="no-data-label">
                You Have Not Subscribed Any App
              </div>
            </div>;
          }
        })}
      </div>
    </>
  );
};

export default AppList;
