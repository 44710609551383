import React, { useEffect, useState } from "react";
import InputMultiSelectField from "../../../../views/inputtextfield/InputMultiSelectField";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import InputTextField from "../../../../views/inputtextfield/InputTextField";
// Importing Date Picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import Button from "../../../../views/buttons/Button";
import beApi from "../../../../API/beApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  CATCH_MESSAGE,
  validatePositiveInt,
} from "../../../../UtilityFunctions";
import endPoints from "../../../../API/endPoints";

export interface IOneHotelCouponProps {
  onClose: () => void;
  getAllCoupons: () => void;
  allRoomType: any[];
  allCouponType: any;
}

const OneHotelCoupon: React.FC<IOneHotelCouponProps> = ({
  onClose,
  getAllCoupons,
  allRoomType,
  allCouponType,
}) => {
  // Data From Redux
  const { current_property, property_data } = useSelector(
    (state: RootState) => state.properties
  );
  const { couponId } = useSelector((state: RootState) => state.manage_channels);
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [openFromDate, setFromDateOpen] = useState(false);
  const [openToDate, setToDateOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(moment());
  const [toDate, setToDate] = useState<any>(moment().add(10, "d"));
  const [allHotelOptn, setAllHotelOptn] = useState<any>({
    all_hotel: [],
    hotel_ids: [],
  });
  const [selectedRoomtype, setSelectedRoomtype] = useState<any>("");
  const [allCouponOptn, setAllCouponOptn] = useState<any>([]);
  const [selectedCoupontype, setSelectedCoupontype] = useState<any>("");
  const [couponName, setCouponName] = useState<any>("");
  const [couponCode, setCouponCode] = useState<any>("");
  const [discount, setDiscount] = useState<any>("");
  const [selectedSource, setSelectedSource] = useState<any[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<any[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<any[]>([]);
  const [discountAmntHelperText, setDiscountAmntHelperText] =
    useState<string>("");

  useEffect(() => {
    setToDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    let allHotel: string[] = [];
    let allHotelId: any[] = [0];
    property_data?.data?.map((htl: any) => {
      allHotel.push(htl?.hotel_name);
      allHotelId.push(htl?.hotel_id);
    });

    setAllHotelOptn({
      all_hotel: allHotel,
      hotel_ids: allHotelId,
    });
    let allCoupon: string[] = [];
    for (let x in allCouponType) {
      allCoupon.push(allCouponType[x]);
    }

    setAllCouponOptn(allCoupon);
  }, [allRoomType, allCouponType]);

  //Endpoints Import
  const { addCoupons } = endPoints.MANAGECHANNEL;
  // --------------------------------

  // Select Room Type Handler
  const selectRoomtype = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRoomtype(e.target.value);
  };

  // Input Multiselect Handler
  const isAllSelected =
    allHotelOptn?.all_hotel?.length > 0 &&
    selectedSource.length === allHotelOptn?.all_hotel?.length;

  const sourceOnchange = (e: any) => {
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedSource(
        selectedSource.length === allHotelOptn?.all_hotel?.length
          ? []
          : allHotelOptn?.all_hotel
      );
      return;
    }
    const selectedHotel = property_data?.data?.filter((itm: any) => {
      return value.includes(itm.hotel_name);
    });

    const ids = selectedHotel.map((iteam: any) => {
      return iteam.hotel_id;
    });

    setSelectedSource(value);
    setSelectedSourceId(ids);
  };

  // const sourceOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const values = e.target.value ?? [];

  //   // Spreading the values array beacuse it is showing error while appling any array method to it
  //   let selected: any[] = [...values] ?? [];

  //   const notSelectedIteam = selectedRoom?.filter(
  //     (x) => selected.indexOf(x) === -1
  //   );

  //   if (selected.includes("all")) {
  //     if (notSelectedIteam.length > 0 && notSelectedIteam[0] !== "all") {
  //       const withoutAll = selected.filter((iteam) => iteam !== "all");

  //       setSelectedRoom(withoutAll);
  //     } else {
  //       const rooms = allRoomTypes.map((roomType) => roomType.room_type);
  //       setSelectedRoom([...rooms, "all"]);
  //     }
  //   } else {
  //     if (notSelectedIteam.length > 0 && notSelectedIteam[0] === "all") {
  //       setSelectedRoom([]);
  //     } else {
  //       if (
  //         selected.length ===
  //         allRoomTypes.map((roomType) => roomType.room_type).length
  //       ) {
  //         const rooms = [...selected, "all"];

  //         setSelectedRoom(rooms);
  //       } else {
  //         const withoutAll = selected.filter((iteam) => iteam !== "all");

  //         setSelectedRoom(withoutAll);
  //       }
  //     }
  //   }
  // };

  // Date Picker Handler
  const onDateChangeFrom = (date: any) => {
    setFromDate(moment(date));
  };
  const onDateChangeTo = (date: any) => {
    setToDate(moment(date));
  };

  // Discount Handler
  const discountAmntHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (validatePositiveInt(value) && parseInt(value) <= 99) {
      setDiscountAmntHelperText("");
      setDiscount(value);
    } else {
      setDiscountAmntHelperText("Value Should be Between 1 to 99");
      setDiscount(value);
    }
  };

  // Save Handler Function
  const saveHandler = () => {
    if (discountAmntHelperText) {
      toast.error("Please Enter Valid Discount Percentage");
    } else if (toDate.isBefore(fromDate) || fromDate.isSame(toDate)) {
      toast.error("Valid To Date is not valid");
    } else {
      editCouponHandler();
    }
  };

  // Edit Coupon Handler
  const editCouponHandler = async () => {
    // let roomTypeId =
    //   allHotelOptn.all_hotel[allHotelOptn.hotel_ids.indexOf(selectedRoomtype)];
    try {
      const editCouponRes = await beApi.post(
        addCoupons,
        {
          room_type_id: "",
          coupon_for: selectedCoupontype,
          coupon_name: couponName,
          coupon_code: couponCode,
          valid_from: fromDate,
          valid_to: toDate,
          discount: discount,
          hotel_id: selectedSourceId,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (editCouponRes.data.status === 1) {
        toast(editCouponRes.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        getAllCoupons();
        onClose();
        setLoader(false);
      } else {
        toast(`${editCouponRes.data.message}`, {
          hideProgressBar: true,
          type: "error",
        });
        onClose();
        setLoader(false);
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
      onClose();
      setLoader(false);
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Coupon(Multiple Hotel)</div>
      </div>
      <div className="height-64px"></div>
      <div className="slider-content-wrapper">
        <div className="select-hotel mb-4">
          <InputMultiSelectField
            label={"Select Hotels"}
            value={selectedSource}
            options={allHotelOptn.all_hotel}
            isAllSelected={isAllSelected}
            handleChange={(evnt) => sourceOnchange(evnt)}
          />
        </div>
        <div className="mb-4">
          <InputSelectField
            label={"Coupon Type"}
            value={selectedCoupontype}
            options={allCouponOptn}
            handleChange={(e) => {
              setSelectedCoupontype(e.target.value);
            }}
          />
        </div>
        <div className="mb-2">
          <InputTextField
            label={"Coupon Name"}
            value={couponName}
            handleChange={(e) => {
              setCouponName(e.target.value);
            }}
          />
        </div>
        <div className="mb-2">
          <InputTextField
            label={"Coupon Code"}
            value={couponCode}
            handleChange={(e) => {
              setCouponCode(e.target.value);
            }}
          />
        </div>
        <div className="mb-4">
          <div className="date-picker-label">Valid From</div>
          <div className="coupon-date-picker mui__input__fieldset__legends__unset">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                open={openFromDate}
                onOpen={() => setFromDateOpen(true)}
                onClose={() => setFromDateOpen(false)}
                value={fromDate}
                onChange={onDateChangeFrom}
                minDate={new Date()}
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setFromDateOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="mb-4">
          <div className="date-picker-label">Valid To</div>

          <div className="coupon-date-picker mui__input__fieldset__legends__unset">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                open={openToDate}
                onOpen={() => setToDateOpen(true)}
                onClose={() => setToDateOpen(false)}
                value={toDate}
                onChange={onDateChangeTo}
                minDate={fromDate}
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onClick={(e) => setToDateOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="mb-4">
          <InputTextField
            label={"Discount In Percentage"}
            value={discount}
            helperText={discountAmntHelperText}
            type={"number"}
            placeholder={"Enter Discount % in Number"}
            handleChange={(e) => {
              discountAmntHandler(e);
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <Button
          handleClick={() => {
            saveHandler();
          }}
          loader={loader}
        >
          Save
        </Button>
        <Button className="cancel__btn " handleClick={onClose}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default OneHotelCoupon;
