import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LeftSideNav from "../../components/onboarding/LeftSidenav";
import RightSideContent from "../../components/onboarding/RightSideContent";

// import react step progress bar
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

// import input components
import InputTextField from "../../views/inputtextfield/InputTextField";
import NextButton from "../../views/buttons/NextButton";

import GetHelp from "../../components/gethelp/GetHelp";

import { RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AddRoomTypeBasicDetails } from "../../redux/actions/AddRoomTypeAction";
import CurrentPropertyLabel from "../../components/property/CurrentPropertyLabel";
import { toast } from "react-toastify";
import Button from "../../views/buttons/Button";

const RoomTypeDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { property_data } = useSelector((state: RootState) => state.properties);

  const [roomTypeName, setRoomTypeName] = useState(
    store.getState().add_room_type.basic_details.room_type_name
  );
  const [shrtRoomHelperText, setshrtRoomHelperText] = useState("");
  //setshrtRoomHelperText
  const [numOfRooms, setNumOfRooms] = useState(
    store.getState().add_room_type.basic_details.number_of_rooms
  );
  const [roomTypeShortCut, setRoomTypeShortCut] = useState(
    store.getState().add_room_type.basic_details.room_type_shortcut
  );

  const [shrtCodeHelperText, setshrtCodeHelperText] = useState("");

  const RoomTypeNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target ?? {}; //e.target
    if (value.includes(",") || value.includes("(") || value.includes(")")) {
      setshrtRoomHelperText(`Room Name Should Not Contain "," or "( )"`);
    } else {
      if (value && value.trim().length < 4) {
        setshrtRoomHelperText(
          "Room Type Name Could Not Be less Than 4 character"
        );
      } else {
        setshrtRoomHelperText("");
      }
    }
    setRoomTypeName(value); // e.target.value
  };

  const NumOfRoomsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value.match(/^[0-9\b]+$/) || value === "") {
      setNumOfRooms(value);
    }
  };

  const shortCodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target ?? {};
    if (value.length > 4) {
      setshrtCodeHelperText("Short Code Length Could Not Be Greater Than 4");
    } else {
      setshrtCodeHelperText("");
      setRoomTypeShortCut(value);
    }
  };

  const SubmitRoomTypeDetails = () => {
    if (roomTypeName && !shrtRoomHelperText && numOfRooms && roomTypeShortCut) {
      dispatch(
        AddRoomTypeBasicDetails({
          room_type_name: roomTypeName,
          number_of_rooms: numOfRooms,
          room_type_shortcut: roomTypeShortCut,
        })
      );
      setTimeout(() => {
        navigate("/add-room-type/room-type-occupancy");
      }, 500);
    } else if (shrtRoomHelperText) {
      return;
    } else {
      toast("Please fill all the details!", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="div-page-wrapper">
        <div className="div__wrapper">
          <LeftSideNav>
            <>
              <div className="div__user__testimonials__wrapper">
                <div className="screen__progress__wrapper">
                  <div className="progress__status__wrapper">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">
                      Create {property_data.data.length === 1 ? "First" : ""}{" "}
                      Property
                    </div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper bg-white">
                      <i className="bi bi-check progress--icon--check"></i>
                    </div>
                    <div className="status__label">
                      Select Subscription Plan
                    </div>
                  </div>

                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper">
                      <i className="bi bi-play-fill progress--icon--play"></i>
                    </div>
                    <div className="status__label">Create Room Type</div>
                  </div>
                  <div className="progress__sub__list__wrapper">
                    <div className="progress__sub__listitems progress--sub--item--text">
                      &nbsp;- Enter Room Deails
                    </div>
                    <div className="progress__sub__listitems">
                      Define Occupancy
                    </div>
                    <div className="progress__sub__listitems">Add Rates</div>
                    <div className="progress__sub__listitems">
                      Upload Images
                    </div>
                  </div>
                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper"></div>
                    <div className="status__label">Create Floors</div>
                  </div>
                  <div className="progress__status__wrapper mt-5">
                    <div className="status__icon__wrapper"></div>
                    <div className="status__label">Add Rooms</div>
                  </div>
                </div>
              </div>

              <div className="progress-bar-wrapper">
                <div className="progress-bar-text">1/4</div>
                <div className="progress-bar-status">
                  <ProgressBar percent={25} filledBackground="#FFFFFF" />
                </div>
              </div>
            </>
          </LeftSideNav>

          <RightSideContent>
            <>
              <CurrentPropertyLabel />

              <div className="div--head--label">Enter Room Type</div>
              <div className="div--helper--text">Enter details below</div>
              <div className="me-4 mt-4">
                <InputTextField
                  label="Room Type Name"
                  value={roomTypeName}
                  handleChange={RoomTypeNameOnChange}
                  helperText={shrtRoomHelperText}
                />
              </div>
              <div className="property_details_input__wrapper">
                <div className="__default--input--field">
                  <InputTextField
                    label="Number of Rooms"
                    value={numOfRooms}
                    handleChange={NumOfRoomsOnChange}
                  />
                </div>
                <div className="__default--input--field">
                  <InputTextField
                    label="Room Type Shortcode"
                    value={roomTypeShortCut}
                    // handleChange={(e) => setRoomTypeShortCut(e.target.value)}
                    handleChange={shortCodeChangeHandler}
                    helperText={shrtCodeHelperText}
                  />
                </div>
              </div>

              <div className="skip__to__dashboard__wrapper mt-4">
                <NextButton
                  className={!shrtRoomHelperText ? "ms-0" : "ms-0 inactiveBtn"}
                  handleClick={SubmitRoomTypeDetails}
                />
                <div
                  className="skip__to__dashboard_btn me-4"
                  onClick={() => navigate("/")}
                >
                  <u>Skip to Dashboard</u>
                </div>
              </div>

              <GetHelp />
            </>
          </RightSideContent>
        </div>
      </div>
    </>
  );
};

export default RoomTypeDetails;
