import React, { useState } from 'react';
// import input field
import InputTextField from "../../../views/inputtextfield/InputTextField";
import Button from "../../../views/buttons/Button";
import { toast } from 'react-toastify';
import kernelApi from '../../../API/kernelApi';
import { CATCH_MESSAGE } from '../../../UtilityFunctions';



export interface IAddDerivedPlanRoomType {
    onClose: () => void;
    roomTypeName: any;
    planTypeName: any;
    masterPlanData: any[];
    checkStatus: any;
    maxPeople: any;
    hotelId: any;
    roomType: any;
    ratePlan: any;
    roomRatePlan: any;
    refreshRatePlans: () => void

}


const AddDerivedPlanRoomType: React.FC<IAddDerivedPlanRoomType> = ({
    onClose,
    roomTypeName,
    planTypeName,
    masterPlanData,
    checkStatus,
    maxPeople,
    hotelId,
    roomType,
    ratePlan,
    roomRatePlan,
    refreshRatePlans
}) => {

    const [derivedData, setDerivedData] = useState();
    const [selectType, setSelectType] = useState();
    const [selectTypeExtraAdult, setSelectTypeExtraAdult] = useState();
    const [selectTypeExtraChild, setSelectTypeExtraChild] = useState();
    const [amountExtraAdult, setAmountExtraAdult] = useState()
    const [amountExtraChild, setAmountExtraChild] = useState();


    const [singleAmount, setSingleAmount] = useState();
    const [doubleAmount, setDoubleAmount] = useState();
    const [tripleAmount, setTripleAmount] = useState();
    const [barPriceAmount, setBarPriceAmount] = useState();

    const [loader, setLoader] = useState<boolean>(false);




    let max_occupancy: any = [];

    for (let i = 0; i < maxPeople; i++) {
        max_occupancy.push(i + 1);
    }


    const addDerivedPlanRoomType = async () => {
        let amount = [];
        if (singleAmount) {
            amount.push(singleAmount)
        }
        if (doubleAmount) {
            amount.push(doubleAmount)
        }
        if (tripleAmount) {
            amount.push(tripleAmount)
        }
        if (barPriceAmount) {
            amount.push(barPriceAmount)
        }




        if (derivedData && selectType && amount.length === max_occupancy.length && selectTypeExtraAdult && amountExtraAdult && selectTypeExtraChild && amountExtraChild) {

            try {
                setLoader(true);
                let add_derived_plan_room_type = await kernelApi.post(`/derive-plan/add-plan`,
                    {
                        hotel_id: hotelId,
                        room_type_id: roomType,
                        rate_plan_id: ratePlan,
                        room_rate_plan: roomRatePlan,
                        room_rate_plan_id: derivedData,
                        select_type: selectType,
                        amount_type: amount.join(","),
                        extra_adult_select_type: selectTypeExtraAdult,
                        extra_adult_amount: amountExtraAdult,
                        extra_child_select_type: selectTypeExtraChild,
                        extra_child_amount: amountExtraChild
                    })

                if (add_derived_plan_room_type.data.status === 1) {
                    setLoader(false);
                    refreshRatePlans();
                    onClose();
                    toast.success(add_derived_plan_room_type.data.message)
                }
                else {
                    setLoader(false);
                    refreshRatePlans();
                    onClose();
                    toast.error(add_derived_plan_room_type.data.message)
                }




            }
            catch (error: any) {
                toast.error(CATCH_MESSAGE);
            }

        }
        else {
            toast.error("Please fill all the mandatory fields");
        }

    }


    return (
        <>
            <div className="derived__plan__room__type__wrapper">
                <div className="side__slider__header__label__wrapper">
                    <div className="side__slider__close">
                        <i className="bi bi-x" onClick={onClose}></i>
                    </div>
                    <div className="standard-page--label">Derived Plan for {roomTypeName}({planTypeName})</div>
                </div>
                <div className="height-64px"></div>

                <div className="derived__plan__room__type__content mt-0">
                    <div className="derive__plan__wrapper mb-4">
                        <div className="derive__plan__label">Derived for <span>*</span> :</div>
                        <div className="derive__plan__select__wrapper mui__input__fieldset__legends__unset mt-3">
                            <select className="form-select customer-details derived__plan__room__type__padding" onChange={(e: any) => setDerivedData(e.target.value)}>
                                <option value="">Select Plan</option>
                                {masterPlanData.length > 0 && masterPlanData.map((items: any, i: any) => {
                                    return (
                                        <option key={i} value={items.room_rate_plan_id} hidden={items.master_plan_status != 1}>{items.room_type}({items.plan_type})(UMP)</option>
                                    )
                                })}

                                {masterPlanData.length > 0 && masterPlanData.map((items: any, i: any) => {
                                    return (
                                        <option hidden={items.master_plan_status != 2 && checkStatus} value={items.room_rate_plan_id} key={i}>
                                            {items.room_type}({items.plan_type})(MP)
                                        </option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>

                    <div className="derived__plan__details__wrapper mb-3">
                        <div className="derive__plan__label">Derived Plan Details <span>*</span> :</div>
                        <div className="d-flex align-items-start pt-3">
                            <div className="derived__plan__amount__type me-3">
                                <div className="derive__plan__details__amount mui__input__fieldset__legends__unset">

                                    <select className="form-select customer-details derived__plan__room__type__padding" onChange={(e: any) => setSelectType(e.target.value)}>
                                        <option value="">Select type</option>
                                        <option value="percentage">%</option>
                                        <option value="amount">Rs.</option>
                                    </select>

                                </div>
                            </div>


                            {maxPeople > 1 &&
                                <div className="derived__plan__details__type me-3">
                                    <div className="__default--input--field">
                                        <InputTextField
                                            label="Single"
                                            value={singleAmount}
                                            handleChange={(e: any) => { setSingleAmount(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            }

                            {maxPeople > 2 &&
                                <div className="derived__plan__details__type me-3">
                                    <div className="__default--input--field">
                                        <InputTextField
                                            label="Double"
                                            value={doubleAmount}
                                            handleChange={(e: any) => { setDoubleAmount(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            }

                            {maxPeople > 3 &&
                                <div className="derived__plan__details__type me-3">
                                    <div className="__default--input--field">
                                        <InputTextField
                                            label="Triple"
                                            value={tripleAmount}
                                            handleChange={(e: any) => { setTripleAmount(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            }

                            {maxPeople === max_occupancy.length &&
                                <div className="derived__plan__details__type">
                                    <div className="__default--input--field">
                                        <InputTextField
                                            label="Bar Price"
                                            value={barPriceAmount}
                                            handleChange={(e: any) => { setBarPriceAmount(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className="derived__plan__extra__adult__price__input mb-4">
                        <div className="derive__plan__label mb-3">Extra Adult price <span>*</span> :</div>

                        <div className="extra__adult__price__wrapper">
                            <div className="extra__adult__price me-3">
                                <div className="extra__adult__rs mui__input__fieldset__legends__unset">
                                    <select className="form-select customer-details derived__plan__room__type__padding" onChange={(e: any) => setSelectTypeExtraAdult(e.target.value)}>
                                        <option value="">Select type</option>
                                        <option value="percentage">%</option>
                                        <option value="amount">Rs.</option>
                                    </select>
                                </div>
                            </div>

                            <div className="extra__adult__amount__input">
                                <div className="__default--input--field">
                                    <InputTextField
                                        label="Amount"
                                        value={amountExtraAdult}
                                        handleChange={(e: any) => { setAmountExtraAdult(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="derived__plan__extra__child__price__input">
                        <div className="derive__plan__label mb-3">Extra Child Price <span>*</span> :</div>
                        <div className="extra__child__price__wrapper">
                            <div className="extra__child__price me-3">
                                <div className="extra__child__rs mui__input__fieldset__legends__unset">
                                    <select className="form-select customer-details derived__plan__room__type__padding" onChange={(e: any) => setSelectTypeExtraChild(e.target.value)}>
                                        <option value="">Select type</option>
                                        <option value="percentage">%</option>
                                        <option value="amount">Rs.</option>
                                    </select>
                                </div>
                            </div>

                            <div className="extra__child__amount__input">
                                <div className="__default--input--field">
                                    <InputTextField
                                        label="Amount"
                                        value={amountExtraChild}
                                        handleChange={(e: any) => { setAmountExtraChild(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "white",
                    }}
                ></div>

                <div className="side__slider__panel__btn">
                    <div className="button__wrapper">
                        <Button
                            handleClick={addDerivedPlanRoomType}
                            loader={loader}

                        >Submit</Button>
                    </div>
                    <div className="close__wrapper">
                        <Button handleClick={onClose}>Close</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDerivedPlanRoomType;