import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Logout, UpdateSidebar } from "../../UtilityFunctions";

import { ProgressBar } from "react-step-progress-bar";

import { DisableSwitchPropertyScreen } from "../../redux/actions/PromptAction";
import kernelApi from "../../API/kernelApi";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import endPoints from "../../API/endPoints";

const PropertySetup = () => {
  const dispatch = useDispatch();

  UpdateSidebar("Property Setup");

  const { auth_token } = useSelector((state: RootState) => state.auth);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );

  // endPoints Imports
  const { getPropertyCountDetails, getPropertyScorePercentage } =
    endPoints.PROPERTY_SETUP;

  const [countDetails, setCountDetails] = useState<any>();
  const [progressDetails, setProgressDetails] = useState<any>();

  useEffect(() => {
    getProgressBarStatus();
  }, []);

  useEffect(() => {
    getCountDetails();
  }, [current_property]);

  const getCountDetails = async () => {
    try {
      let res = await kernelApi.post(
        `${getPropertyCountDetails}`,
        {
          hotel_id: current_property.hotel_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      if (res.data.status === 1) {
        setCountDetails(res.data.count_details);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(DisableSwitchPropertyScreen());
  };

  // Get Progress Bar Status

  const getProgressBarStatus = async () => {
    try {
      let progressResult = await kernelApi.get(
        `${getPropertyScorePercentage}${current_property.hotel_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      if (progressResult.data.status === 1) {
        setProgressDetails(progressResult.data);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
      </div>
      <div className="hr--line"></div>

      <div className="manage__property__setup__left__panel">
        <div className="property-setup-wrapper">
          <div className="property__manage__row">
            <Link to="basic-details">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-building property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification">1</div> */}
                  </div>
                  <div className="basic--details--label">Basic Details</div>
                  <div
                    className="property--progress "
                    style={{
                      background: `${progressDetails?.basic_details_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.basic_details}
                      filledBackground={progressDetails?.basic_details_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            {/* Rate Plans */}

            <Link to="rate-plan">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-layout-sidebar-inset-reverse property--setup--icon--style"></i>
                    </div>
                    {countDetails?.rate_plans_count > -1 && (
                      <div className="property--setup--notification">
                        {countDetails?.rate_plans_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Rate Plan</div>
                  <div
                    className="property--progress"
                    style={{
                      background: `${progressDetails?.rate_plan_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.rate_plan}
                      filledBackground={progressDetails?.rate_plan_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="room-types">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-layout-sidebar-inset-reverse property--setup--icon--style"></i>
                    </div>
                    {countDetails?.room_type_count > -1 && (
                      <div className="property--setup--notification">
                        {countDetails?.room_type_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Room types</div>
                  <div
                    className="property--progress"
                    style={{
                      background: `${progressDetails?.roomtypes_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.roomtypes}
                      filledBackground={progressDetails?.roomtypes_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="floors">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-layout-sidebar-inset-reverse property--setup--icon--style"></i>
                    </div>
                    {countDetails?.floors_count > -1 && (
                      <div className="property--setup--notification">
                        {countDetails?.floors_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Floors</div>
                  <div
                    className="property--progress"
                    style={{
                      background: `${progressDetails?.floors_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.floors}
                      filledBackground={progressDetails?.floors_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="rooms">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-layout-sidebar-inset-reverse property--setup--icon--style"></i>
                    </div>
                    {countDetails?.rooms_count > -1 && (
                      <div className="property--setup--notification">
                        {countDetails?.rooms_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Rooms</div>
                  <div
                    className="property--progress"
                    style={{
                      background: `${progressDetails?.rooms_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.rooms}
                      filledBackground={progressDetails?.rooms_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="amenities">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-wifi property--setup--icon--style"></i>
                    </div>
                    {countDetails?.facility_count > -1 && (
                      <div className="property--setup--notification">
                        {countDetails?.facility_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Amenities</div>
                  <div
                    className="property--progress"
                    style={{
                      background: `${progressDetails?.amenities_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.amenities}
                      filledBackground={progressDetails?.amenities_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="images">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-image property--setup--icon--style"></i>
                    </div>
                    {countDetails?.property_images_count > -1 && (
                      <div className="property--setup--notification">
                        {countDetails?.property_images_count}
                      </div>
                    )}
                  </div>
                  <div className="basic--details--label">Images</div>
                  <div
                    className="property--progress"
                    style={{
                      background: `${progressDetails?.images_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.images}
                      filledBackground={progressDetails?.images_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="terms-and-policy">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-file-text property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification">2</div> */}
                  </div>
                  <div className="basic--details--label">Terms & Policy</div>
                  <div
                    className="property--progress"
                    style={{
                      background: `${progressDetails?.terms_policy_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.terms_policy}
                      filledBackground={progressDetails?.terms_policy_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="cancellation-rules">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-x-circle property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
                  </div>
                  <div className="basic--details--label">
                    Cancellation Rules
                  </div>
                  <div
                    className="property--progress "
                    style={{
                      background: `${progressDetails?.cancel_policy_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.cancel_policy}
                      filledBackground={progressDetails?.cancel_policy_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="financial-details">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-file-earmark-diff-fill property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    2
                  </div> */}
                  </div>
                  <div className="basic--details--label">Finance Details</div>
                  <div
                    className="property--progress "
                    style={{
                      background: `${progressDetails?.bank_details_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.bank_details}
                      filledBackground={progressDetails?.bank_details_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            <Link to="locale-info">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-geo-alt-fill property--setup--icon--style"></i>
                    </div>
                    {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
                  </div>
                  <div className="basic--details--label">Locale Info</div>
                  <div
                    className="property--progress "
                    style={{
                      background: `${progressDetails?.cancel_policy_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.cancel_policy}
                      filledBackground={progressDetails?.cancel_policy_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            {/* Seasonal PLan */}
            {/* <Link to="seasonal-plan">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-megaphone property--setup--icon--style"></i>
                    </div> */}
            {/* <div className="property--setup--notification property--setup--icon--style">
                    1
                  </div> */}
            {/* </div>
                  <div className="basic--details--label">Seasonal Plan</div>
                  <div
                    className="property--progress "
                    style={{
                      background: `${progressDetails?.seasonal_plan_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.seasonal_plan}
                      filledBackground={progressDetails?.seasonal_plan_color}
                    />
                  </div>
                </div>
              </div>
            </Link> */}

            {/* Dynamic Pricing */}
            <Link to="dynamic-pricing">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-bar-chart-fill property--setup--icon--style"></i>
                    </div>
                  </div>
                  <div className="basic--details--label">
                    Dynamic Pricing Setup
                  </div>
                  <div
                    className="property--progress "
                    style={{
                      background: `${progressDetails?.dynamic_pricing_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.dynamic_pricing}
                      filledBackground={progressDetails?.dynamic_pricing_color}
                    />
                  </div>
                </div>
              </div>
            </Link>

            {/* Room Rate Plan */}
            <Link to="room-rate-plan">
              <div className="property__setup__card">
                <div className="property__setup__card__container">
                  <div className="property__setup__indicator__wrapper">
                    <div className="property--setup--icon">
                      <i className="bi bi-bar-chart-fill property--setup--icon--style"></i>
                    </div>
                  </div>
                  <div className="basic--details--label">Derived Rate Plan</div>
                  <div
                    className="property--progress "
                    style={{
                      background: `${progressDetails?.room_rate_plan_bgcolor}`,
                    }}
                  >
                    <ProgressBar
                      percent={progressDetails?.room_rate_plan}
                      filledBackground={progressDetails?.room_rate_plan_color}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertySetup;
