import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Button from "../../../../views/buttons/Button";
import Loader from "../../../../views/loader/Loader";
import SlidingPane from "react-sliding-pane";
import EditRateSyncSlider from "./EditRateSyncSlider";
import AddRateSyncSlider from "./AddRateSyncSlider";
import { useDispatch, useSelector } from "react-redux";
import cmApi from "../../../../API/cmApi";
import { RootState } from "../../../../redux/store";
import kernelApi from "../../../../API/kernelApi";
import { toast } from "react-toastify";
import {
  Capitalize,
  CATCH_MESSAGE,
  Logout,
} from "../../../../UtilityFunctions";
import {
  ShowConfirmationPrompt,
  CancelConfirmationPrompt,
} from "../../../../redux/actions/PromptAction";
import Card from "../../../../views/cardwrapper/Card";
import InputSelectField from "../../../../views/inputtextfield/InputSelectField";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/CustomBreadcrumbs";
import endPoints from "../../../../API/endPoints";

const RateSync = () => {
  const { ota } = useSelector((state: RootState) => state.manage_channels);
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { admin_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const [loader, setLoader] = useState<boolean>(false);
  const [savebtnloader, setSaveBtnLoader] = useState<boolean>(false);
  const [addRateSyncSlider, setAddRateSyncSlider] = useState<boolean>(false);
  const [editRateSyncSlider, setEditRateSyncSlider] = useState<boolean>(false);
  const [rateSyncData, setRateSyncData] = useState<any[]>([]);
  const [allhotelRoomO, setAllHotelRoom] = React.useState<any[]>([]);
  const [otaRoomTypeData, setOtalRoomTypeData] = React.useState<any[]>([]);
  const [editId, setEditId] = useState<any>("");
  const [hotelRoomId, setHotelRoomId] = useState<any>();
  const [roomRatePlan, setRoomRatePlan] = useState<any>();
  const [otaRoomPlanSelected, setOtaRoomPlanSelected] = useState<any[]>([]);
  const [noDataMessage, setNoDataMessage] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Endpoints Import
  const { getAllOTAratePlanAPI, saveOTAratePlan, deleteRatePlan } =
    endPoints.MANAGECHANNEL;
  // --------------------------------

  useEffect(() => {
    if (ota.ota_id) {
      getAllOtaRatePlans();
    }
  }, [current_property, ota]);

  // OTA RATE PLAN OPTIONS NAME
  const otaRatePlanOptn = (ota_rate_type_name_n_id) => {
    let ota_rate_type__arr = ["Not Mapped"];
    Array.isArray(ota_rate_type_name_n_id) &&
      ota_rate_type_name_n_id?.map((iteam) => {
        ota_rate_type__arr.push(
          `${iteam.ota_rate_type_name}(${iteam.ota_rate_type_id})`
        );
      });
    return ota_rate_type__arr;
  };

  // OTA RATE PLAN OPTIONS ID
  const otaRatePlanOptnId = (ota_rate_type_name_n_id) => {
    let ota_rate_type__arr = [0];
    Array.isArray(ota_rate_type_name_n_id) &&
      ota_rate_type_name_n_id.map((iteam) => {
        ota_rate_type__arr.push(iteam.ota_rate_type_id);
      });
    return ota_rate_type__arr;
  };

  // OTA Room Type Selection Handler
  const OtaRoomTypeSelectionHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    ratePlan: any,
    otaRatePlanName: any,
    hotelRoomId: any,
    otaRoomTypeId: any
  ) => {
    if (
      otaRoomPlanSelected
        .filter((iteam) => iteam.hotel_room_type_id === hotelRoomId)
        .map((room) => room?.hotel_rate_plan_id)
        .includes(ratePlan?.rate_plan_id)
    ) {
      const index = otaRoomPlanSelected.findIndex(
        (item) => item.hotel_rate_plan_id === ratePlan?.rate_plan_id
      );
      otaRoomPlanSelected.splice(index, 1);
      const selected = [
        ...otaRoomPlanSelected,
        {
          hotel_room_type_id: hotelRoomId,
          hotel_rate_plan_id: ratePlan.rate_plan_id,
          ota_rate_plan_id: e.target.value,
          ota_rate_plan_name: otaRatePlanName.filter(
            (iteam) => iteam.ota_rate_type_id === e.target.value
          )[0].ota_rate_type_name,
          ota_room_type_id: otaRoomTypeId,
        },
      ];
      setOtaRoomPlanSelected(selected);
    } else {
      const selected = [
        ...otaRoomPlanSelected,
        {
          hotel_room_type_id: hotelRoomId,
          hotel_rate_plan_id: ratePlan.rate_plan_id,
          ota_rate_plan_id: e.target.value,
          ota_rate_plan_name: otaRatePlanName.filter(
            (iteam) => iteam.ota_rate_type_id === e.target.value
          )[0].ota_rate_type_name,
          ota_room_type_id: otaRoomTypeId,
        },
      ];

      setOtaRoomPlanSelected(selected);
    }
  };

  // Get All Ota Rate Plans New One
  const getAllOtaRatePlans = async () => {
    // setLoader(true);
    try {
      const otarateresponse = await cmApi.get(
        `${getAllOTAratePlanAPI}/${current_property.hotel_id}/${ota.ota_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (otarateresponse.data.status === 1) {
        setLoader(false);
        setOtalRoomTypeData(otarateresponse.data.data);
        const mappedRatePlan: any = [];
        otarateresponse.data.data.map((x: any) => {
          x.rate_sync_data.map((i: any) => {
            mappedRatePlan.push({
              hotel_room_type_id: i.hotel_room_type_id,
              hotel_rate_plan_id: i.hotel_rate_plan_id,
              ota_rate_plan_id: i.ota_rate_plan_id,
              ota_rate_plan_name: i.ota_rate_plan_name,
              ota_room_type_id: i.ota_room_type_id,
              id: i.id,
            });
          });
        });
        setOtaRoomPlanSelected(mappedRatePlan);
      } else {
        setLoader(false);
        setOtalRoomTypeData([]);
        setNoDataMessage(otarateresponse.data.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  // Post Ota Rate Plans
  const saveOtaRatePlan = async () => {
    const newArray = otaRoomPlanSelected.map((item) => {
      return {
        hotel_room_type_id: item.hotel_room_type_id,
        ota_rate_plan_id: item.ota_rate_plan_id,
        ota_room_type_id: item.ota_room_type_id,
      };
    });

    const set = new Set(newArray.map((iteam) => JSON.stringify(iteam)));

    const hasDuplicates = set.size < newArray.length;

    // const duplicateHotelRoomIds = otaRoomPlanSelected
    //   .map((v) => v.hotel_room_type_id)
    //   .filter((v, i, vIds) => vIds.indexOf(v) !== i);
    // const duplicates = otaRoomPlanSelected.filter((obj) =>
    //   duplicateHotelRoomIds.includes(obj.hotel_room_type_id)
    // );
    // const otaRatePlanIds = duplicates?.map((item) => item.ota_rate_plan_id);
    // const isDuplicate =
    //   Array.isArray(otaRatePlanIds) &&
    //   otaRatePlanIds?.some((item, idx) => {
    //     return otaRatePlanIds?.indexOf(item) !== idx;
    //   });

    if (hasDuplicates) {
      toast.error("Duplicate OTA Room Rate Plan");
      getAllOtaRatePlans();
    } else {
      // setLoader(true);
      setSaveBtnLoader(true);
      try {
        const otarateresponse = await cmApi.post(
          saveOTAratePlan,
          {
            hotel_id: current_property?.hotel_id,
            ota_type_id: ota?.ota_id,
            rate_plan_details: otaRoomPlanSelected,
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (otarateresponse.data.status === 1) {
          toast.success(otarateresponse.data.message);
          getAllOtaRatePlans();
          // setLoader(false);
          setSaveBtnLoader(false);
        } else {
          toast.error(otarateresponse.data.message);
          getAllOtaRatePlans();
          // setLoader(false);
          setSaveBtnLoader(false);
        }
      } catch (err) {
        toast.error(CATCH_MESSAGE);
        // setLoader(false);
        setSaveBtnLoader(false);
      }
    }
  };

  // Delete Rate Sync
  const deleteRoomSync = (ratePlanId: any, hotelRoomId: any) => {
    let Id = otaRoomPlanSelected
      ?.filter((item) => item?.hotel_room_type_id === hotelRoomId)
      .filter((i) => i.hotel_rate_plan_id === ratePlanId)[0].id;

    dispatch(
      ShowConfirmationPrompt(
        "Are you sure remove this mapping ? ",
        "DELETE",
        async () => {
          // setLoader(true);

          if (Id) {
            try {
              const deleteresponse = await cmApi.delete(
                `${deleteRatePlan}/${Id}`,
                {
                  headers: {
                    Authorization: "Bearer " + auth_token,
                  },
                }
              );

              if (deleteresponse.data.status === 1) {
                toast.success(deleteresponse.data.message);
                getAllOtaRatePlans();
                // setLoader(false);
              } else {
                toast.error(deleteresponse.data.message);
                getAllOtaRatePlans();
                // setLoader(false);
              }
            } catch (err: any) {
              toast.error(CATCH_MESSAGE);
              // setLoader(false);
              if (err.response.status === 400) {
                Logout();
              }
            }
          } else {
            getAllOtaRatePlans();
          }

          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };

  return (
    <>
      {/* <div className="property__breadcrumbs">
        <CustomBreadcrumbs
          content={[
            { label: "Manage Channels", href: "/manage-channels" },
            { label: ota.ota_name, href: "/manage-channels/ota-manage" },
            { label: "Rate Mapping" },
          ]}
        />

        <div
          className="manage__property__action__right"
          style={{ alignItems: "center" }}
        >
          

          <Button
            className="back__btn"
            icon="bi bi-arrow-left"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="hr--line"></div> */}

      {loader ? (
        <div>
          <Loader className="d-flex justify-content-center align-items-center" />
        </div>
      ) : otaRoomTypeData.length > 0 ? (
        <div className="rate-sync-wrapper">
          {otaRoomTypeData?.map((iteam) => {
            return (
              <Card key={iteam?.room_type_id} className="rate-sync-card">
                <div className="rate-sync-card-header-wrapper">
                  <div className="rate-sync-card-header-child">
                    <div className="rate-sync-card-header-label">
                      Hotel Room Type
                    </div>
                    <div>{`${Capitalize(iteam?.room_type)}(${
                      iteam?.room_type_id
                    })`}</div>
                  </div>
                  <div className="rate-sync-card-header-child">
                    <div className="ota-rate-sync-card-header-label">
                      {ota.ota_name.toLowerCase() === "airbnb"
                        ? `${ota.ota_name} Listing`
                        : `${ota.ota_name} Room Type `}
                    </div>
                    <div>{`${Capitalize(iteam?.ota_room_type_name)}(${
                      iteam?.ota_room_type
                    })`}</div>
                  </div>
                </div>
                <div>
                  {/* <div>For Table Layout using Div Tag</div> */}
                  {/* <div className="table table-borderless">
                    <div className="thead">
                      <div className="ota__table__heading">
                        <div className="th">Rate Plan</div>
                        <div className="th">OTA Rate Plan</div>
                        <div className="th right__align">Remove Mapping</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {iteam?.all_rate_plans?.map((ratePlan) => {
                        return (
                          <>
                            <div className="ota__table__row">
                              <div className="rate__plan">{`${ratePlan?.plan_name}(${ratePlan?.rate_plan_id})`}</div>
                              <div className="ota__rate__plan">
                                <InputSelectField
                                  label={"OTA Rate Plan"}
                                  value={
                                    otaRoomPlanSelected.filter((i: any) => {
                                      return (
                                        i?.hotel_rate_plan_id ===
                                          ratePlan?.rate_plan_id &&
                                        i?.hotel_room_type_id ===
                                          iteam?.room_type_id
                                      );
                                    })[0]?.ota_rate_plan_id ?? 0
                                  }
                                  options={otaRatePlanOptn(
                                    iteam?.ota_rate_type_name_n_id
                                  )}
                                  values={otaRatePlanOptnId(
                                    iteam?.ota_rate_type_name_n_id
                                  )}
                                  handleChange={(e) => {
                                    OtaRoomTypeSelectionHandler(
                                      e,
                                      ratePlan,
                                      iteam?.ota_rate_type_name_n_id,
                                      iteam?.room_type_id,
                                      iteam?.ota_room_type
                                    );
                                  }}
                                  disabledOptions={[0]}
                                />
                              </div>
                              <div className="td">
                                <div className="d-flex">
                                  <div
                                    className="customer-delete-button"
                                    onClick={() => {
                                      // deleteRoomSync(item.id);
                                    }}
                                  >
                                    Remove Mapping
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div> */}

                  {/* table to discard */}
                  <table className="table table-borderless">
                    <thead>
                      <tr className="ota__table__heading">
                        <th>Sl.No</th>
                        <th>Hotel Rate Plan</th>
                        <th>{`${ota.ota_name} `}Rate Plan</th>
                        <th className="right__align">Remove Mapping</th>
                      </tr>
                    </thead>
                    <tbody>
                      {iteam?.all_rate_plans?.map((ratePlan, idx) => {
                        return (
                          <tr key={idx} className="ota__table__row">
                            <td>
                              {otaRoomPlanSelected.filter((i: any) => {
                                return (
                                  i?.hotel_rate_plan_id ===
                                    ratePlan?.rate_plan_id &&
                                  i?.hotel_room_type_id === iteam?.room_type_id
                                );
                              })[0]?.ota_rate_plan_id ? (
                                <div className="serial-number-mapped">
                                  {idx + 1}
                                </div>
                              ) : (
                                <div className="serial-number">{idx + 1}</div>
                              )}
                            </td>
                            <td className="rate__plan">{`${ratePlan?.plan_name}(${ratePlan?.rate_plan_id})`}</td>
                            <td className="ota__rate__plan">
                              <InputSelectField
                                label="OTA Rate Plan"
                                value={
                                  otaRoomPlanSelected.filter((i: any) => {
                                    return (
                                      i?.hotel_rate_plan_id ===
                                        ratePlan?.rate_plan_id &&
                                      i?.hotel_room_type_id ===
                                        iteam?.room_type_id
                                    );
                                  })[0]?.ota_rate_plan_id ?? 0
                                }
                                options={otaRatePlanOptn(
                                  iteam?.ota_rate_type_name_n_id
                                )}
                                values={otaRatePlanOptnId(
                                  iteam?.ota_rate_type_name_n_id
                                )}
                                handleChange={(e) => {
                                  OtaRoomTypeSelectionHandler(
                                    e,
                                    ratePlan,
                                    iteam?.ota_rate_type_name_n_id,
                                    iteam?.room_type_id,
                                    iteam?.ota_room_type
                                  );
                                }}
                                disabledOptions={[0]}
                              />
                            </td>
                            <td>
                              <div className="d-flex">
                                {otaRoomPlanSelected.filter((i: any) => {
                                  return (
                                    i?.hotel_rate_plan_id ===
                                      ratePlan?.rate_plan_id &&
                                    i?.hotel_room_type_id ===
                                      iteam?.room_type_id
                                  );
                                })[0]?.ota_rate_plan_id ? (
                                  <div
                                    className="customer-delete-button"
                                    onClick={() => {
                                      deleteRoomSync(
                                        ratePlan?.rate_plan_id,
                                        iteam?.room_type_id
                                      );
                                    }}
                                  >
                                    Remove Mapping
                                  </div>
                                ) : (
                                  <div className="customer-delete-button-disabled">
                                    Remove Mapping
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card>
            );
          })}
          <div style={{ width: "10%", marginBottom: "10px" }}>
            <Button
              handleClick={() => {
                saveOtaRatePlan();
              }}
              loader={savebtnloader}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>{`${noDataMessage ? noDataMessage : "Ota is not Added"}`} </div>
        </div>
      )}
    </>
  );
};

export default RateSync;
